import React, { useCallback, useState, useEffect } from 'react';
import QuestionItem from './QuestionItem';
import { useDayOffAndHoliday } from 'Hooks/useWorkingEnvData';

import './DayoffHoliday.scss';
import { useSelector } from 'react-redux';
import { selectGlobal } from 'redux/global/globalSlice';

const DayoffHoliday = ({
  dayOffHolidayList,
  unitSelected,
  setDayOffHoliday,
  dayOffHoliday,
  f3Range,
  setF3Range,
  isExtend,
  setIsExtend,
}) => {
  if (!dayOffHolidayList) {
    return;
  }
  const { language } = useSelector(selectGlobal);

  const hadleUpdate = (question, answer) => {
    setDayOffHoliday((prev) => {
      return { ...prev, [question.id]: { answer, type: question.type } };
    });
  };

  const renderQuestion = useCallback(() => {
    return dayOffHolidayList.map((question, index) => {
      return (
        <QuestionItem
          question={question}
          key={index + 1}
          index={index}
          language={language}
          hadleUpdate={hadleUpdate}
          f3Range={f3Range}
          setF3Range={setF3Range}
          dayOffHoliday={dayOffHoliday}
          isExtend={isExtend}
          setIsExtend={setIsExtend}
        />
      );
    });
  }, [dayOffHolidayList, language, dayOffHoliday, f3Range]);

  return <div className="dayoff-holiday">{renderQuestion()}</div>;
};

export default DayoffHoliday;
