import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  empInfo: {
    api: {},
    colApi: {},
  },
  taskList: {
    api: {},
    colApi: {},
  },
  dashboard: {
    api: {},
    colApi: {},
  },
  empTable: {
    api: {},
    colApi: {},
  },
  hrPerWeek: {
    api: {},
    colApi: {},
  },
  summary: {
    api: {},
    colApi: {},
  },
};

export const generateTableColApiSlice = createSlice({
  name: 'generateTableColApi',
  initialState,
  reducers: {
    resetApi: () => initialState,
    updateApi: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
  },
});

export const selectGeneratorColApi = (state) => state.generateTableColApi;
export const { updateApi, resetApi } = generateTableColApiSlice.actions;
export default generateTableColApiSlice.reducer;
