/** libs */
import React from 'react';
import { Checkbox as CheckboxAnt } from 'antd';

/** styles */
import './styles.scss';

const Checkbox = ({ ...props }) => {
  return <CheckboxAnt {...props} />;
};

export default Checkbox;
