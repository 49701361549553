import {
  CheckboxCheckIcon,
  CheckboxUncheckIcon,
  CheckGreenIcon,
  CloseRedIcon,
  DownArrow,
} from 'assets/img';
import { useClickOutside, useClickOutsideV2 } from 'helper/useClickOutSide';
import { t } from 'i18next';
import React, { useCallback, useMemo, useRef, useState } from 'react';

const RoleCoverSelection = ({
  roleList,
  role = { roleCovered: [] },
  handleSelectRoleCover,
  disabled,
  selectedRole = [],
  handleUpdate,
  handleRemoveSelectedRole,
  unSelectedRole = [],
  isNewRole,
}) => {
  const selectBoxRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const { roleCovered = [] } = role;
  const title = useMemo(() => {
    return roleCovered?.length ? `${roleCovered?.length} ${t('job-categories')}` : t('none');
  }, [roleCovered, role]);

  const renderSelectBox = useCallback(() => {
    if (!isActive) return;

    return (
      <div className="role-cover__select-box">
        <div className="box-title">sykepleier kan dekke vakter for</div>
        <div className="box-action__wrap">
          <span className="selected-role">
            {t('competence')} {t('update')}{' '}
            {!isNewRole ? (
              <span className="num">{selectedRole?.length + unSelectedRole?.length}</span>
            ) : null}
          </span>
          <div className="box-action">
            <img src={CheckGreenIcon} alt="" width={18} onClick={handleUpdate} />
            <img src={CloseRedIcon} alt="" width={18} onClick={handleRemoveSelectedRole} />
          </div>
        </div>
        <div className="box-list">
          {roleList.map((roleRender) => {
            if (role.id === roleRender.id) return;
            const isChecked = roleCovered.includes(roleRender.id);
            return (
              <div
                key={`${roleRender.role}-${roleRender.id}`}
                className="box__item"
                onClick={() => {
                  if (disabled) return;
                  handleSelectRoleCover(roleRender);
                }}
              >
                <img src={isChecked ? CheckboxCheckIcon : CheckboxUncheckIcon} width={14} alt="" />
                <label htmlFor="">{roleRender.role}</label>
              </div>
            );
          })}
        </div>
      </div>
    );
  }, [isActive, roleList, roleCovered, role, disabled, selectedRole]);
  useClickOutside(selectBoxRef, () => setIsActive(false));
  return (
    <div className="role-cover__wrap" ref={selectBoxRef}>
      <div className="role-cover__status" onClick={() => setIsActive(true)}>
        <p>{title}</p>
        <img src={DownArrow} alt="" width={18} />
      </div>
      {renderSelectBox()}
    </div>
  );
};

export default RoleCoverSelection;
