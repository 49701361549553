import { t } from 'i18next';
import React, { useEffect } from 'react';
import './SingleStaffTask.scss';

const PaidBreakTypeBox = ({ paidBreakType, handleOnChange, taskWillCreate }) => {
  return (
    <select
      value={taskWillCreate.paid_break_type}
      name="paid_break_type"
      id=""
      onChange={handleOnChange}
    >
      {paidBreakType.map((item) => {
        return (
          <option key={item.key} value={item.id}>
            {t(item.key)}
          </option>
        );
      })}
    </select>
  );
};
const SingleStaffTask = ({
  renderTaskTypeOption,
  renderTaskGroupOption,
  unitSelected,
  paidBreakType,
  taskWillCreate,
  setTaskWillCreate,
}) => {
  const handleOnChange = (e) => {
    setTaskWillCreate({ ...taskWillCreate, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    document.body.style.overflow = 'hidden'; // Prevent scrolling in the background

    return () => {
      document.body.style.overflow = 'auto'; // Re-enable scrolling when the component unmounts
    };
  }, []);
  return (
    <div className="single-staff-task__wrap">
      <div className="single-staff-task__content">
        <div className="single-staff-task__group">
          <div className="single-staff-task__item">
            <label htmlFor="">{t('task-name')}</label>
            <input
              className="single-staff-task__item-input"
              onChange={handleOnChange}
              name="task_name"
              value={taskWillCreate.task_name}
              required
            />
          </div>
        </div>
        <div className="single-staff-task__group">
          <div className="single-staff-task__item">
            <label htmlFor="">{t('type')}</label>
            <select name="type_id" id="" onChange={handleOnChange} value={taskWillCreate.type_id}>
              <option value={''}>{t('choose-type')}</option>
              {renderTaskTypeOption()}
            </select>
          </div>
          {/* <div className="single-staff-task__item">
            <label htmlFor="">{t('group')}</label>
            <select name="group_id" id="" value={taskWillCreate.group_id} onChange={handleOnChange}>
              <option value={''}>{t('choose-group')}</option>
              {renderTaskGroupOption()}
            </select>
          </div> */}
        </div>
        <div className="single-staff-task__group not-space">
          <div className="single-staff-task__item">
            <label htmlFor="">{t('from')}</label>
            <input
              type="time"
              name="from_time"
              id=""
              value={taskWillCreate.from_time}
              onChange={handleOnChange}
            />
          </div>
          <div className="single-staff-task__item">
            <label htmlFor="">{t('to')}</label>
            <input
              type="time"
              name="to_time"
              id=""
              value={taskWillCreate.to_time}
              onChange={handleOnChange}
            />
          </div>
        </div>
        <div className="single-staff-task__group  not-space">
          <div className="single-staff-task__item">
            <label htmlFor="">{t('pause')}</label>
            <input
              type="time"
              name="pause"
              defaultValue={'00:30'}
              value={taskWillCreate.pause}
              id=""
              onChange={handleOnChange}
            />
          </div>
          <div className="single-staff-task__item inline">
            <div
              className="
            "
            >
              <input
                type="checkbox"
                name="is_paid_break"
                id=""
                onChange={() =>
                  setTaskWillCreate((prev) => ({ ...prev, is_paid_break: !prev.is_paid_break }))
                }
                checked={taskWillCreate.is_paid_break}
              />
              <label htmlFor="">{t('paid-break')}</label>
            </div>
            {taskWillCreate.is_paid_break ? (
              <div className="single-staff-task__item">
                <label htmlFor="">{t('applies')}</label>

                <PaidBreakTypeBox
                  paidBreakType={paidBreakType}
                  handleOnChange={handleOnChange}
                  taskWillCreate={taskWillCreate}
                />
              </div>
            ) : null}
          </div>
        </div>
        <div className="single-staff-task__group">
          <div className="single-staff-task__item">
            <label htmlFor="">{t('description')}</label>
            <input
              className="single-staff-task__item-input"
              onChange={handleOnChange}
              name="description"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleStaffTask;
