import React from 'react';

const SearchIcon = ({ color = '#656063' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.3335 2.66683C4.75617 2.66683 2.66683 4.75617 2.66683 7.3335C2.66683 9.91083 4.75617 12.0002 7.3335 12.0002C8.59079 12.0002 9.73196 11.5029 10.5711 10.6944C10.5886 10.6716 10.6079 10.6497 10.6288 10.6288C10.6497 10.6079 10.6716 10.5886 10.6944 10.5711C11.5029 9.73196 12.0002 8.59079 12.0002 7.3335C12.0002 4.75617 9.91083 2.66683 7.3335 2.66683ZM12.0214 11.0786C12.8425 10.0522 13.3335 8.75017 13.3335 7.3335C13.3335 4.01979 10.6472 1.3335 7.3335 1.3335C4.01979 1.3335 1.3335 4.01979 1.3335 7.3335C1.3335 10.6472 4.01979 13.3335 7.3335 13.3335C8.75017 13.3335 10.0522 12.8425 11.0786 12.0214L13.5288 14.4716C13.7891 14.7319 14.2112 14.7319 14.4716 14.4716C14.7319 14.2112 14.7319 13.7891 14.4716 13.5288L12.0214 11.0786Z"
        fill={color}
      />
    </svg>
  );
};

export default SearchIcon;
