import { TYPE_SURVEY_QUESTION } from 'constant';

export const getQuestionAnswerType = (parent_question_type) => {
  const parentAnswerType = {
    single: 'radio',
    text: 'text',
    'multiple-choice': 'checkbox',
    date: 'date',
    time: 'time',
    'checkbox-text': 'checkbox-text',
    'date-only': 'date-only',
    'select-week': 'select-week',
    'range-date-only': 'range-date-only',
    [TYPE_SURVEY_QUESTION.WEEKDAY_TYPE_SHIFT]: 'week-day-type-shift',
    [TYPE_SURVEY_QUESTION.HOLIDAY_TYPE_SHIFT]: TYPE_SURVEY_QUESTION.HOLIDAY_TYPE_SHIFT,
  }[parent_question_type];

  return parentAnswerType || 'radio';
};
