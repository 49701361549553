import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useRef, useState } from 'react';

import PrimaryButton from 'components/Buttons/PrimaryButton';

import { closeModal, openModal } from '_components/Modal/slice';
import { SEND_EMP_SURVEY } from '_components/CarlendarPlan/EmployeeV2/constants/modal';
import { ChevronWhiteIcon, LockIcon, SendWhiteIcon, UnlockIcon, VerticalDot } from 'assets/img';
import { useClickOutside } from 'helper/useClickOutSide';
import { Toast, confirmDelete, showLoading } from 'helper/alert';
import axiosClient from 'axios/axiosClient';
import { useEmployees } from 'Hooks/useData';
import employeeAPI from '_apis/employee';
import { selectAuth } from 'redux/auth/authSlice';

const InfoHeader = ({ data, unitSelected }) => {
  const dispatch = useDispatch();
  const { name, role, emp_id_number } = data;
  const [isLock, setIsLock] = useState(false);
  const [sendDropDown, setSendDropDown] = useState(false);
  const [verticalDotDropDown, setVerticalDotDropDown] = useState(false);
  const { refetch: refetchEmployees } = useEmployees({ unitSelected });
  const { deleteSplitEmployee } = employeeAPI();
  const { isEditabled } = useSelector(selectAuth);

  const sendBtnRef = useRef(null);
  const veritcalDotBtnRef = useRef(null);

  const handleOpenDropDown = useCallback(
    (e) => {
      e.stopPropagation();
      setSendDropDown(!sendDropDown);
    },
    [sendDropDown],
  );

  const handleOpenSendSurvey = () => {
    dispatch(closeModal());
    dispatch(openModal({ name: SEND_EMP_SURVEY, data }));
  };

  useClickOutside(sendBtnRef, () => {
    setSendDropDown(false);
  });
  useClickOutside(veritcalDotBtnRef, () => {
    setVerticalDotDropDown(false);
  });

  const handleClickFeature = () => {
    alert('Implement later after send survey new UI is ready');
  };

  const handleDelete = () => {
    confirmDelete({}).then(async (result) => {
      if (result.isConfirmed) {
        showLoading({ shouldShow: true, title: 'deleting' });
        if (data?.isSplited) {
          const splitID = data.splitID;
          await deleteSplitEmployee({ splitID });
          await refetchEmployees();
          showLoading({ shouldShow: false });
          Toast({
            icon: 'success',
            title: `${t('delete')} ${t('employee')} ${t('success')}`,
          });
          return;
        }

        const employeeInfo = data;
        axiosClient
          .post('calendar-plan/employee/delete', { employeeInfo, page: 1 })
          .then(async () => {
            await refetchEmployees();
            showLoading({ shouldShow: false });
            dispatch(closeModal());
            Toast({
              icon: 'success',
              title: `${t('delete')} ${t('employee')} ${t('success')}`,
            });
          })
          .catch((err) => {
            Toast({
              icon: 'error',
              title: `${t('delete')} ${t('employee')} ${t('unsuccess')}`,
            });
          });
      }
    });
  };

  return (
    <div className="side-menu__form-header">
      <div className="emp-name">
        <p>
          {name}
          <img
            src={isLock ? LockIcon : UnlockIcon}
            onClick={() => isEditabled && setIsLock(!isLock)}
          />
        </p>
        <div>
          <PrimaryButton
            ref={sendBtnRef}
            iconLeft={SendWhiteIcon}
            title={t('send')}
            iconRight={ChevronWhiteIcon}
            onClick={handleOpenSendSurvey}
            onImgClick={handleOpenDropDown}
            disabled={!isEditabled}
          />
          <PrimaryButton
            ref={veritcalDotBtnRef}
            iconLeft={VerticalDot}
            secondary
            onClick={() => setVerticalDotDropDown(!verticalDotDropDown)}
            disabled={!isEditabled}
          />
        </div>
      </div>
      {sendDropDown && (
        <div className="send-dropdown">
          <p onClick={handleOpenSendSurvey}>{t('send-survey')}</p>
          <p onClick={handleClickFeature}>{t('send-rotation')}</p>
        </div>
      )}
      {verticalDotDropDown && (
        <div className="verticaldot-dropdown">
          <div>
            <p onClick={handleClickFeature}>{t('download-rotation')}</p>
            <p onClick={handleClickFeature}>{t('copy-rotation')}</p>
          </div>
          <p className="delete-emp" onClick={handleDelete}>
            {t('delete-employee')}
          </p>
        </div>
      )}
      <p className="emp-role">
        {role} | {emp_id_number || 'ID'}
      </p>
    </div>
  );
};

export default InfoHeader;
