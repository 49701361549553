import { CheckBrownIcon, DeleteIconBlack } from 'assets/img';
import { weekdayKey } from 'constant';
import { t } from 'i18next';
import React, { useEffect, useId, useState } from 'react';
import './UnwantedShiiftCollectionContainer.scss';
import axiosClient from 'axios/axiosClient';
import UnwantedShiftCell from './UnwantedShiftCell';
import { Toast } from 'helper/alert';

const UnwantedShiftRow = ({
  taskList,
  isCreating,
  unitSelected,
  listData,
  setBlackList,
  setIsCreating,
  reftechBlackList,
}) => {
  const [isEditing, setIsEditing] = useState(!listData?.id);
  const [rowData, setRowData] = useState({
    comment: '',
    shiftList: {
      mon: null,
      tue: null,
      wed: null,
      thu: null,
      fri: null,
      sat: null,
      sun: null,
    },
  });
  const handleChangeTask = ({ label, taskWillSelect }) => {
    setRowData((prev) => ({
      ...prev,
      shiftList: { ...prev.shiftList, [label]: taskWillSelect.taskID },
    }));
  };
  const validateRowData = () => {
    const { shiftList } = rowData;
    for (const weekday of Object.keys(shiftList)) {
      const data = shiftList[weekday];
      if (data) return true;
    }
    return false;
  };
  const handleUpdate = () => {
    const isValidRowData = validateRowData();
    if (!isValidRowData) return;
    axiosClient
      .post('calendar-plan/black-list/update', { blackList: rowData, unitCode: unitSelected })
      .then((res) => {
        setIsEditing(false);
        reftechBlackList();
      })
      .catch((err) => {
        Toast({ icon: 'error', title: `${t('save-fail')} ` });
      });
  };
  const handleDelete = () => {
    if (!rowData.id) {
      if (setIsCreating) setIsCreating(false);
      return;
    }
    axiosClient
      .post('calendar-plan/black-list/delete', { blackListID: rowData.id })
      .then((res) => {
        setBlackList((prev) => prev.filter((item) => item.id !== rowData.id));
      })
      .catch((err) => {
        Toast({ icon: 'error', title: `${t('save-fail')} ` });
      });
  };
  useEffect(() => {
    if (listData && listData.blackListShift) {
      const { id, unitCode, blackListShift, comment } = listData;
      const shiftList = blackListShift.reduce(
        (o, day) => ({ ...o, [day.weekdayKey]: day.taskID }),
        {},
      );

      const mappedData = { comment, shiftList, id };
      setRowData(mappedData);
    }
  }, [listData]);
  return (
    <tr>
      {weekdayKey.map((key, index) => {
        const cellKey = useId();
        return (
          <td key={cellKey}>
            <UnwantedShiftCell
              taskList={taskList}
              label={key}
              handleChangeTask={handleChangeTask}
              rowData={rowData}
              isEditing={isEditing}
            />
          </td>
        );
      })}

      <td>
        <div className="unwanted-shift-comment">
          {isEditing ? (
            <input
              value={rowData.comment}
              type="text"
              onChange={(e) => {
                const { value } = e.target;
                setRowData((prev) => ({ ...prev, comment: value }));
              }}
            />
          ) : (
            <p>{rowData.comment}</p>
          )}
        </div>
      </td>
      <td>
        {isEditing ? (
          <div className="unwanted-shift-row__actions">
            <button onClick={handleUpdate}>
              <img src={CheckBrownIcon} alt="" />
            </button>
            <button onClick={handleDelete}>
              <img src={DeleteIconBlack} alt="" />
            </button>
          </div>
        ) : (
          <button className="unwanted-shift-edit" onClick={() => setIsEditing(true)}>
            {t('edit')}
          </button>
        )}
      </td>
    </tr>
  );
};

export default UnwantedShiftRow;
