import { CloseOutlined } from '@ant-design/icons';
import { CLINICSL_ENV, HOSPITAL_ENV, TYPE_SURVEY_QUESTION } from 'constant';
import { t } from 'i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DateOnlyItem from '../CalendarPlanSurveyForm/ItemInSurvey/DateOnlyItem';
import { v4 as uuidv4 } from 'uuid';
import SelectWeekItem from '../CalendarPlanSurveyForm/ItemInSurvey/SelectWeekItem';
import { selectGlobal } from 'redux/global/globalSlice';
import { useSelector } from 'react-redux';

const RenderSingleDateRange = ({ questionId, surveyRecord, setSurveyRecord }) => {
  const [survey, setSurvey] = useState({
    [questionId]: { date: [], single: {}, type: 'single-date' },
  });

  useEffect(() => {
    const surveyUpdate = { ...surveyRecord };
    if (!surveyUpdate[questionId]) {
      surveyUpdate[questionId] = { date: [], single: {}, type: 'single-date' };
    }
    setSurvey(surveyUpdate);
  }, [surveyRecord]);

  return survey[questionId].date?.map((_, index) => {
    let position = index;
    return (
      <div
        key={`${questionId}-sub-single-date-${index}`}
        className="calendar-plan-survey-form__date__wrap"
      >
        <div className="calendar-plan-survey-form__date__index">{t('range')}:</div>
        <div className="calendar-plan-survey-form__date__group">
          <div className="calendar-plan-survey-form__date__item">
            <label htmlFor="">{t('from')}</label>

            <input
              type="date"
              name=""
              id=""
              value={
                survey[questionId].date[position]?.fromDate
                  ? moment(survey[questionId].date[position]?.fromDate).format('YYYY-MM-DD')
                  : null
              }
              onChange={(e) => {
                const { value } = e.target;
                if (position) {
                  survey[questionId].date[position] = {
                    ...survey[questionId].date[position],
                    fromDate: new Date(value),
                    type: 'date',
                  };
                }
                if (moment(value).year() > 1970) {
                  setSurveyRecord(survey);
                }
              }}
            />
          </div>
          <div className="calendar-plan-survey-form__date__item">
            <label htmlFor="">{t('to')}</label>
            <input
              type="date"
              name=""
              id=""
              value={
                survey[questionId].date[position]?.toDate
                  ? moment(survey[questionId].date[position]?.toDate).format('YYYY-MM-DD')
                  : null
              }
              onChange={(e) => {
                const { value } = e.target;

                survey[questionId].date[position] = {
                  ...survey[questionId].date[position],
                  toDate: new Date(value),
                  type: 'date',
                };

                if (moment(value).year() > 1970) {
                  setSurveyRecord(survey);
                }
              }}
            />
          </div>
          <CloseOutlined
            onClick={() => {
              survey[questionId].date.splice(position, 1);
              setSurveyRecord(survey);
            }}
          />
        </div>
      </div>
    );
  });
};

const RenderNewSingleDateRange = ({ questionId, surveyRecord, setSurveyRecord }) => {
  const survey = { ...surveyRecord };

  if (!survey[questionId]) {
    survey[questionId] = { date: [], single: {}, type: 'single-date' };
  }
  return (
    <div key={`${questionId}-sub-single-date`} className="calendar-plan-survey-form__date__wrap">
      <div className="calendar-plan-survey-form__date__index">{t('new-range')}:</div>
      <div className="calendar-plan-survey-form__date__group">
        <div className="calendar-plan-survey-form__date__item">
          <label htmlFor="">{t('from')}</label>
          <input
            type="date"
            name=""
            id=""
            onChange={(e) => {
              const { value } = e.target;

              survey[questionId].date.push({
                fromDate: new Date(value),
                type: 'date',
              });
              e.target.value = null;

              if (moment(value).year() > 1970) {
                setSurveyRecord(survey);
              }
            }}
          />
        </div>
        <div className="calendar-plan-survey-form__date__item">
          <label htmlFor="">{t('to')}</label>
          <input
            type="date"
            name=""
            id=""
            onChange={(e) => {
              const { value } = e.target;

              survey[questionId].date.push({
                toDate: new Date(value),
                type: 'date',
              });
              e.target.value = null;

              if (moment(value).year() > 1970) {
                setSurveyRecord(survey);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

const ChildQuestion = ({
  parentQuestionData,
  language,
  childQuestionKey,
  renderRangeDate,
  renderNewRangeDate,
  surveyRecord,
  setSurveyRecord,
  handleOnChange,
  warningMessage,
  isExpired,
  limitSurveyStart,
  limitSurveyEnd,
  isReadOnly
}) => {
  const [childQuestionData, setChildQuestionData] = useState({});
  const childQuestion = parentQuestionData.child[childQuestionKey];
  const typeQuestionSub = childQuestion.child_question_type;
  const { kommuneTypeQuestionProperty, hospitalTypeQuestionProperty, customerType } =
    useSelector(selectGlobal);
  if (customerType === HOSPITAL_ENV) {
    if (kommuneTypeQuestionProperty.includes(childQuestion.child_question_property)) {
      return;
    }
  }

  if (customerType === CLINICSL_ENV) {
    if (hospitalTypeQuestionProperty.includes(childQuestion.child_question_property)) {
      return;
    }
  }
  const getChildAnswerType = () => {
    switch (typeQuestionSub) {
      case 'single':
      case 'single-date':
        return 'radio';
      case 'text':
        return 'text';
      case 'multiple-choice':
        return 'checkbox';
      case 'date':
        return 'date';
      case TYPE_SURVEY_QUESTION.DATE_ONLY:
        return TYPE_SURVEY_QUESTION.DATE_ONLY;
      case TYPE_SURVEY_QUESTION.SELECT_WEEK:
        return TYPE_SURVEY_QUESTION.SELECT_WEEK;
      default:
        return 'radio';
    }
  };
  useEffect(() => {
    if (childQuestion && Object.keys(childQuestion).length) {
      const data = {
        questionContent:
          language === 'NO'
            ? childQuestion.child_question_no_ver
            : childQuestion.child_question_content,
        description:
          language === 'NO'
            ? childQuestion.child_question_description_no_ver
            : childQuestion.child_question_description,
        answerList: childQuestion.child_answer_no_ver,
        questionChoice: childQuestion.child_answer_choice,
        questionType: getChildAnswerType(),
        questionId: childQuestion.child_question_id,
      };

      setChildQuestionData(data);
    }
  }, [parentQuestionData, language]);

  const isChecked = (choiceValue) => {
    const selectedValues = surveyRecord[childQuestion?.child_question_id] || {};
    if (Array.isArray(selectedValues)) {
      {
        return selectedValues.some((item) => item.en === choiceValue || item.no === choiceValue);
      }
    } else if (typeQuestionSub === 'single-date') {
      return selectedValues.single?.en === choiceValue || selectedValues.single?.no === choiceValue;
    } else {
      return selectedValues.en === choiceValue || selectedValues.no === choiceValue;
    }
  };
  const renderAnswerInput = (choice, choiceValue, value, answerChoice) => {
    const survey = { ...surveyRecord };

    return (
      <div
        key={choice}
        className={`calendar-plan-survey-form__answer-item custom-radio-selection survey-sub-question ${
          value === 'radio' ? 'auto-width' : ''
        }`}
      >
        {value === TYPE_SURVEY_QUESTION.DATE_ONLY ? (
          <div className="multiple-days">
            {Array.from({
              length: 1,
            }).map((_, index) => {
              return (
                <DateOnlyItem
                  key={uuidv4()}
                  questionId={childQuestion?.child_question_id}
                  surveyRecord={surveyRecord}
                  setSurveyRecord={setSurveyRecord}
                  index={index}
                  isExpired={isExpired || isReadOnly}
                  limitSurveyStart={limitSurveyStart}
                  limitSurveyEnd={limitSurveyEnd}
                />
              );
            })}
          </div>
        ) : null}
        {value === TYPE_SURVEY_QUESTION.SELECT_WEEK ? (
          <SelectWeekItem
            key={uuidv4()}
            questionId={childQuestion?.child_question_id}
            surveyRecord={surveyRecord}
            setSurveyRecord={setSurveyRecord}
            isExpired={isExpired || isReadOnly}
            limitSurveyStart={limitSurveyStart}
            limitSurveyEnd={limitSurveyEnd}
          />
        ) : null}
        {value === 'date' && childQuestion
          ? renderRangeDate(childQuestion?.child_question_id)
          : null}
        {value === 'date' ? renderNewRangeDate(childQuestion?.child_question_id) : null}
        {value !== 'text' &&
        value !== 'date' &&
        value !== TYPE_SURVEY_QUESTION.DATE_ONLY &&
        value !== TYPE_SURVEY_QUESTION.SELECT_WEEK ? (
          <div className="radio-answer">
            <input
              type={value}
              disabled={isExpired || isReadOnly}
              name={childQuestion?.child_question_id}
              id={`${choice}-${childQuestion?.child_question_id}`}
              value={choiceValue}
              onChange={(e) => {
                if (value === 'checkbox') {
                  if (!survey[childQuestion?.child_question_id]) {
                    survey[childQuestion?.child_question_id] = [];
                  }
                  const valueIndex = survey[childQuestion?.child_question_id].findIndex(
                    (item) => item.en === choiceValue || item.no === choiceValue,
                  );
                  if (valueIndex > -1) {
                    const valueUpdate = survey[childQuestion?.child_question_id];
                    valueUpdate.splice(valueIndex, 1);
                    setSurveyRecord({
                      ...survey,
                      [childQuestion?.child_question_id]: valueUpdate,
                    });
                  } else {
                    const valueUpdate = survey[childQuestion?.child_question_id];
                    valueUpdate.push(answerChoice);
                    setSurveyRecord({
                      ...survey,
                      [childQuestion?.child_question_id]: valueUpdate,
                    });
                  }
                } else if (typeQuestionSub === 'single-date') {
                  if (!survey[childQuestion?.child_question_id]) {
                    survey[childQuestion?.child_question_id] = {
                      date: [],
                      single: {},
                      type: 'single-date',
                    };
                  }
                  const valueUpdate = {
                    ...survey,
                    [childQuestion?.child_question_id]: {
                      ...survey[childQuestion?.child_question_id],
                      single: answerChoice,
                    },
                  };

                  setSurveyRecord(valueUpdate);
                } else {
                  setSurveyRecord({
                    ...survey,
                    [childQuestion?.child_question_id]: answerChoice,
                  });
                }
              }}
              checked={isChecked(choiceValue)}
            />
            <label htmlFor={`${choice}-${childQuestion?.child_question_id}`}>{choice}</label>
          </div>
        ) : null}
        {value === 'text' ? (
          <>
            <input
              className="survey-page-sub-input-number"
              type={'number'}
              disabled={isExpired || isReadOnly}
              name={childQuestion?.child_question_id}
              id={`${choice}-${childQuestion?.child_question_id}`}
              value={surveyRecord[childQuestion.child_question_id] || ''}
              onChange={handleOnChange}
              style={{
                borderColor: warningMessage ? 'red' : '',
                outline: 'none',
                padding: '7px 9px',
                boxSizing: 'border-box',
                borderRadius: 4,
                textAlign: 'center',
                border: '1px solid #4E1830',
              }}
            />
            <p style={{ color: 'red' }}>{warningMessage}</p>
          </>
        ) : null}
      </div>
    );
  };
  return (
    <>
      {childQuestionKey !== 'null' ? (
        <div
          key={childQuestionKey}
          className="calendar-plan-survey-form__sub-question survey-page__sub-questions"
        >
          <h4 htmlFor="" className="calendar-plan-survey-form__question">
            {language === 'NO'
              ? childQuestion?.child_question_no_ver
              : childQuestion?.child_question_content}
          </h4>
          {childQuestion.child_question_description ? (
            <p className="calendar-plan-survey-form__description">
              {language === 'NO'
                ? childQuestion.child_question_description_no_ver
                : childQuestion.child_question_description}
            </p>
          ) : null}
          <div className="calendar-plan-survey-form__answer-group auto-width survey-page-sub-wrapper">
            {language === 'NO'
              ? childQuestion?.child_answer_no_ver?.split(',').map((choice, index) => {
                  const choiceValue = childQuestion?.child_answer_choice.split(',')[index];
                  const value = {
                    en: choiceValue,
                    no: choice,
                    type: childQuestion.child_question_type,
                  };
                  return renderAnswerInput(choice, choiceValue, getChildAnswerType(), value);
                })
              : childQuestion?.child_answer_choice?.split(',').map((choice, index) => {
                  const choiceValue = childQuestion?.child_answer_no_ver.split(',')[index];
                  const value = {
                    en: choice,
                    no: choiceValue,
                    type: childQuestion.child_question_type,
                  };
                  return renderAnswerInput(choice, choiceValue, getChildAnswerType(), value);
                })}
          </div>
          {typeQuestionSub === 'single-date' ? (
            <div className="date-child">
              <RenderSingleDateRange
                questionId={childQuestion?.child_question_id}
                setSurveyRecord={setSurveyRecord}
                surveyRecord={surveyRecord}
                isExpired={isExpired || isReadOnly}
                limitSurveyStart={limitSurveyStart}
                limitSurveyEnd={limitSurveyEnd}
              />
              <RenderNewSingleDateRange
                questionId={childQuestion?.child_question_id}
                setSurveyRecord={setSurveyRecord}
                surveyRecord={surveyRecord}
                isExpired={isExpired || isReadOnly}
                limitSurveyStart={limitSurveyStart}
                limitSurveyEnd={limitSurveyEnd}
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default ChildQuestion;
