import axiosClient from 'axios/axiosClient';
import { Toast } from 'helper/alert';
import { t } from 'i18next';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectAuth } from 'redux/auth/authSlice';
import { selectGlobal } from 'redux/global/globalSlice';
import QuestionItem from './QuestionItem';
import './DefaultRotation.scss';
import { CLINICSL_ENV } from 'constant';

const DefaultRotation = ({
  unitSelected,
  questionKey,
  generSettingList,
  refetchGeneralSetting,
}) => {
  const { isEditabled } = useSelector(selectAuth);
  const { language, customerType } = useSelector(selectGlobal);

  const handleOnChange = async (e, question) => {
    const { value: optionID } = e.target;
    const updatedData = { unitCode: unitSelected, optionID, questionID: question.id };
    await axiosClient
      .post('calendar-plan/general-settings/update', updatedData)
      .then((_) => {
        refetchGeneralSetting();
        Toast({ icon: 'success', title: t('save-changes') });
      })
      .catch((err) => {
        if (err) Toast({ icon: 'error', title: t('save-fail') });
      });
  };
  return (
    <div className="general-setting">
      {generSettingList
        .filter((question) => question.question_key === questionKey)
        .map((question) => {
          const correctOption = question.options.filter((option) => {
            if (customerType === CLINICSL_ENV && option.value === '3/3/2') {
              return false;
            }
            return true;
          });
          const correctgQuestion = { ...question, options: correctOption };

          return (
            <QuestionItem
              key={question.id}
              question={correctgQuestion}
              language={language}
              onChange={(e) => {
                handleOnChange(e, question);
              }}
              disabled={!isEditabled}
            />
          );
        })}
    </div>
  );
};

export default DefaultRotation;
