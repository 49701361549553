import React from 'react';
import './TaskCleanCell.scss';
import { CancelBlue } from 'assets/img';
import { revertShiftCleaned } from '../../helper/revertShiftCleaned';
import { useSelector } from 'react-redux';
import { selectGeneratorColApi } from 'redux/generateTableApi/slice';
import { useTurnusContext } from 'components/CalendarPlan/GeneratorContent/Turnus/TurnusContext';

const TaskCleanCell = ({ data, dateInWeek, rowData, planSelected }) => {
  const dayTasks = data?.task_arr[dateInWeek];
  const listTableApi = useSelector(selectGeneratorColApi);
  const turnusData = useTurnusContext();
  const {
    summarySelectedView,
    setCleanData,
    setCleanDataAlert,
    showCleanAlert,
    cleanData: cleanDataDefault,
    workingEnvSetup,
    roleList,
  } = turnusData;
  const cleanDataSingle = {
    cleanedVakantShiftID: [dayTasks[0].cleanVakantID],
    assignList: [{ ...dayTasks[0], employee_id: data.employeeId, date: dateInWeek }],
  };
  const handleRevert = () => {
    revertShiftCleaned({
      listTableApi,
      cleanData: cleanDataSingle,
      summarySelectedView,
      setCleanData,
      cleanDataDefault,
      employeeId: data.employeeId,
      dateInWeek,
      listEmpData: rowData,
      workingEnvSetup,
      roleList,
      planSelected,
    });
  };
  return (
    <div className="task-clean-cell__wrapper" onClick={handleRevert}>
      <div className="cancel-clean">
        <img src={CancelBlue} alt="" />
      </div>

      <span>{!dayTasks[0].isDeleted ? dayTasks[0].taskName : ''}</span>
    </div>
  );
};

export default TaskCleanCell;
