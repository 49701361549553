import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import React, { useCallback, useId, useState } from 'react';

import TaskListTable from './TaskListTable';
import PrimaryButton from 'components/Buttons/PrimaryButton';

import { CloseBlackIcon, CloseBrownIcon, PlusBrownIcon } from 'assets/img';
import { closeModal } from '_components/Modal/slice';

import './styles.scss';
import { useGroups, useRoles } from 'Hooks/useData';
import NavTabs from 'components/NavTabs/NavTabs';
import TaskPropertyTable from './TaskPropertyTable';
import { Toast } from 'helper/alert';
import { getRowIDStaffPlanTable } from '../PlanDetail/helper';

const AddTaskCode = ({
  tableData,
  setTableData,
  isUpdating,
  setDeletedList,
  layerList,
  layerCreated,
  unitSelected,
}) => {
  const [layerSelected, setLayerSelected] = useState({ roleID: '', id: '', layerName: '' });
  const [selectedTask, setSelectedTask] = useState([]);
  const [unSelectedTask, setUnselectedTask] = useState([]);
  const [isShowLayerSelect, setIsShowLayerSelect] = useState(false);
  const [roleSelected, setRoleSelected] = useState([]);
  const [groupSelected, setGroupSelected] = useState({});
  const TAB_LIST = [
    {
      key: 'shift',
      value: 'shift',
    },
    {
      key: 'shift-property',
      value: 'shift-property',
      isDisabled: !selectedTask.length,
    },
  ];
  const [currentTab, setCurrentTab] = useState(TAB_LIST[0].key);
  const dispatch = useDispatch();
  const { data: roleFetchList } = useRoles();
  const { data: groupList, refetch: refetchGroup } = useGroups({ unitSelected });
  const handleSelectLayer = (e) => {
    const value = e.target.value.split('|');
    setLayerSelected({ id: value[0], roleID: value[1], layerName: value[2] });
  };

  const handleClose = () => {
    setSelectedTask([]);
    setUnselectedTask([]);
    setLayerSelected({ roleID: '', id: '' });
    setIsShowLayerSelect(false);
    setRoleSelected([]);
    dispatch(closeModal());
  };

  const renderLayer = (layerList, layerCreated) => {
    const listLayerRendered = [...layerList];
    layerCreated.forEach((item, index) => {
      listLayerRendered.push({
        id: `${item.layerName}`,
        name: item.layerName,
        hour_per_week: item.weekHr,
        role_id: item.roleID,
      });
    });
    return listLayerRendered.map((item) => {
      return (
        <option key={`${item.id}-${item.name}`} value={`${item.id}|${item.role_id}|${item.name}`}>
          {item.name}
        </option>
      );
    });
  };

  const handleAddTask = () => {
    // Remove tasks from tableData that are in unSelectedTask
    const updatedTableData = [...tableData];
    // filter(
    //   (task) => !unSelectedTask.map((unselected) => unselected.taskUUID).includes(task.taskUUID),
    // );
    // const deletedTask = tableData.filter((item) =>
    //   unSelectedTask.map((unselected) => unselected.taskUUID).includes(item.taskUUID),
    // );

    // if (isUpdating && deletedTask.length > 0) {
    //   const listDelete = deletedTask.flatMap((item) =>
    //     item.taskList.map((task) => task.planDetailUUID),
    //   );
    //   setDeletedList((prev) => [...prev, ...listDelete]);
    // }
    if (Object.keys(layerSelected).length && layerSelected.id && !roleSelected.length) {
      Toast({ icon: 'warning', title: t('select-role-warning') });
      return;
    }

    const listIDTaskSelected = updatedTableData.map((row) => getRowIDStaffPlanTable(row));
    // Add tasks from selectedTask that are not in tableData
    selectedTask
      .filter((task) => {
        const taskID = getRowIDStaffPlanTable({
          taskUUID: task.taskUUID,
          layerName: layerSelected.layerName,
          roleList: roleSelected || [],
          groupList: [groupSelected?.id],
          layerID: layerSelected.id,
        });

        return !listIDTaskSelected.includes(taskID);
      })
      .forEach((task) => {
        updatedTableData.push({
          ...task,
          layerID: layerSelected.id,
          layerName: layerSelected.layerName,
          roleList: roleSelected,
          roleInfoList: roleFetchList.filter((item) => roleSelected.includes(item.id)),
          groupID: groupSelected?.id,
          groupName: groupSelected?.name,
          groupList: [groupSelected?.id],
        });
      });

    const customSort = (a, b) => {
      if (a.isSummary && !b.isSummary) {
        return 1;
      } else if (!a.isSummary && b.isSummary) {
        return -1;
      } else {
        return new Date(b.createdAt) - new Date(a.createdAt);
      }
    };
    setTableData(updatedTableData.sort(customSort));
    handleClose();
  };

  const ModalContent = {
    [TAB_LIST[0].key]: {
      table: (
        <TaskListTable
          tableData={tableData}
          setTableData={setTableData}
          setDeletedList={setDeletedList}
          selectedTask={selectedTask}
          setSelectedTask={setSelectedTask}
          setUnselectedTask={setUnselectedTask}
        />
      ),
      submit: (
        <div className="action-btn">
          <PrimaryButton
            disabled={!selectedTask.length}
            title={t('next')}
            onClick={() => setCurrentTab(TAB_LIST[1].key)}
          />
        </div>
      ),
    },
    [TAB_LIST[1].key]: {
      table: (
        <TaskPropertyTable
          handleSelectLayer={handleSelectLayer}
          layerSelected={layerSelected}
          layerList={layerList}
          renderLayer={renderLayer}
          layerCreated={layerCreated}
          roleFetchList={roleFetchList}
          roleSelected={roleSelected}
          setRoleSelected={setRoleSelected}
          groupList={groupList}
          groupSelected={groupSelected}
          setGroupSelected={setGroupSelected}
        />
      ),
      submit: (
        <div className="action-btn">
          <PrimaryButton
            tertiary
            title={t('previous')}
            onClick={() => setCurrentTab(TAB_LIST[0].key)}
          />
          <PrimaryButton title={t('add')} onClick={handleAddTask} />
        </div>
      ),
    },
  };
  return (
    <div className="add-task-code__wrapper">
      <div className="add-task-code__background" onClick={handleClose}></div>
      <div className="add-task-code__form">
        <div className="add-task-code__form-icon">
          <img src={CloseBlackIcon} onClick={handleClose} />
        </div>
        <p>{t('add-guard-code')}</p>
        <p>{t('add-guard-code-desc')}</p>

        <NavTabs tabList={TAB_LIST} currentTab={currentTab} setCurrentTab={setCurrentTab} />

        {ModalContent[currentTab].table}
        {ModalContent[currentTab].submit}
      </div>
    </div>
  );
};

export default AddTaskCode;
