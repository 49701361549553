import {
  HARD_RULE_PROPERTY,
  DAY_SHIFT_VALUE,
  EVENING_SHIFT_VALUE,
  NIGHT_SHIFT_VALUE,
} from 'constant';
import commonChecking from './commonChecking';
import moment from 'moment';
import { getAllDateInWeek } from 'helper/dateTime';

const checkIsTooMuchShiftConsecutive = ({
  taskData,
  employeeTaskList,
  workingEnvSetup,
  prevShiftType,
  checkingPareShift,
}) => {
  const { date: taskDate, taskType } = taskData;

  if (!workingEnvSetup) return true;

  const maximumConsecutive =
    workingEnvSetup.find(
      (item) => item.questionProperty === HARD_RULE_PROPERTY.MAX_CONSECUTIVE_SHIFTS,
    )?.questionRecord || 5;

  let maximumTypeShift = maximumConsecutive;
  let consecutive = 1;
  let typeConsecutive = 1;

  if (checkingPareShift) {
    consecutive++;
  }
  if (prevShiftType === taskType) {
    typeConsecutive++;
  }
  switch (
    taskType //Gettign maximum consecutive per type
  ) {
    case DAY_SHIFT_VALUE:
      maximumTypeShift =
        workingEnvSetup.find((item) => item.questionProperty === HARD_RULE_PROPERTY.MAX_DAY_ROW)
          ?.questionRecord || 5;
      break;
    case EVENING_SHIFT_VALUE:
      maximumTypeShift =
        workingEnvSetup.find((item) => item.questionProperty === HARD_RULE_PROPERTY.MAX_AFTEN_ROW)
          ?.questionRecord || 5;
      break;
    case NIGHT_SHIFT_VALUE:
      maximumTypeShift =
        workingEnvSetup.find((item) => item.questionProperty === HARD_RULE_PROPERTY.MAX_NIGHT_ROW)
          ?.questionRecord || 5;
      break;

    default:
      break;
  }

  //Checking previous shift and count if have consecutive shift
  for (let index = 1; index < maximumConsecutive + 1; index++) {
    const prevDateChecking = moment(taskDate).clone().subtract(index, 'days').format('YYYY-MM-DD');
    const isHaveShiftType = commonChecking.isHaveShiftOnDate(
      prevDateChecking,
      employeeTaskList,
      taskType,
    );
    const isHaveShift = commonChecking.isHaveShiftOnDate(prevDateChecking, employeeTaskList);

    if (isHaveShiftType) {
      typeConsecutive++;
    }

    if (isHaveShift) {
      consecutive++;
    } else {
      break;
    }
  }

  //Checking next shift and count if have consecutive shift
  for (let index = 1; index < maximumConsecutive + 1; index++) {
    const nextDateChecking = moment(taskDate).clone().add(index, 'days').format('YYYY-MM-DD');
    const isHaveShiftType = commonChecking.isHaveShiftOnDate(
      nextDateChecking,
      employeeTaskList,
      taskType,
    );
    const isHaveShift = commonChecking.isHaveShiftOnDate(nextDateChecking, employeeTaskList);

    if (isHaveShiftType) {
      typeConsecutive++;
    }

    if (isHaveShift) {
      consecutive++;
    } else {
      break;
    }
  }

  return consecutive <= maximumConsecutive && typeConsecutive <= maximumTypeShift;
};

const checkingRoleAndGroup = ({
  taskData,
  roleList,
  employeeGroupID,
  employeeRoleID,
  employeePosition,
}) => {
  let validRole = true;
  let validGroup = true;
  const { roleID: taskRoleID, groupID: taskGroupID } = taskData;

  if (taskRoleID) {
    const taskRole = roleList.find((item) => +item.id === +taskRoleID);
    const employeeRole = roleList.find((item) => +item.id === +employeeRoleID);
    if (taskRole && employeeRole) {
      validRole =
        +taskRole.id === +employeeRoleID || employeeRole.roleCovered.includes(+taskRole.id);
    }
  }

  if (taskGroupID) {
    const listGroupID = employeePosition?.employeeGroups.map((item) => item.group_id);
    validGroup = listGroupID.includes(+taskGroupID);
  }

  return validRole && validGroup;
};

const checkingEnoughTime = ({ taskData, deviation }) => {
  const taskHourAssigned = taskData.hourAssigned;
  return deviation + taskHourAssigned <= 12;
};

const checkingTimeOffBetweenShift = ({ taskData, employeeTaskList, workingEnvSetup }) => {
  let isValid = true;
  const { date: taskDate, taskType } = taskData;

  const taskFromTime = taskData.fromTime;
  const taskEndTime = taskData.endTime;
  const prevDate = moment(taskDate).clone().add(-1, 'd').format('YYYY-MM-DD');
  const nextDate = moment(taskDate).clone().add(1, 'd').format('YYYY-MM-DD');

  const prevShift = commonChecking.getShiftByDate(prevDate, employeeTaskList);
  const nextShift = commonChecking.getShiftByDate(nextDate, employeeTaskList);
  if (prevShift && !commonChecking.isOffShift(prevShift)) {
    const prevShiftType = prevShift.taskType;
    const diffInHours = commonChecking.getTimeBetweenShift({
      dateStart: prevDate,
      endDate: taskDate,
      prevTasktype: prevShiftType,
      startTime: prevShift.endTime,
      endTime: taskFromTime,
    });
    const minimumTimeOffTwoShift = commonChecking.getMinimumTimeOffBetweenShift({
      prevType: prevShiftType,
      nextType: taskType,
      workingEnvSetup,
    });
    isValid = diffInHours >= minimumTimeOffTwoShift;
  }

  if (nextShift && !commonChecking.isOffShift(nextShift)) {
    const nextShiftType = nextShift.taskType;
    const diffInHours = commonChecking.getTimeBetweenShift({
      dateStart: taskDate,
      endDate: nextDate,
      prevTasktype: taskType,
      startTime: taskEndTime,
      endTime: nextShift.fromTime,
    });

    const minimumTimeOffTwoShift = commonChecking.getMinimumTimeOffBetweenShift({
      prevType: taskType,
      nextType: nextShiftType,
      workingEnvSetup,
    });

    isValid = isValid && diffInHours >= minimumTimeOffTwoShift;
  }

  return isValid;
};

const checkingTimeInWeek = ({ taskData, employeeTaskList, amlInformation, prevShift }) => {
  const { date: taskDate, taskType, hourAssigned } = taskData;
  const maximumTimePerWeek = amlInformation.maxWorkingHour;
  const allDateInWeek = getAllDateInWeek(taskDate);
  let workingTimeInWeek = hourAssigned;
  if (prevShift) {
    workingTimeInWeek += prevShift.hourAssigned;
  }
  for (const date of allDateInWeek) {
    if (employeeTaskList[date]) {
      const workingTime = employeeTaskList[date][0].hourAssigned;
      workingTimeInWeek += workingTime;
    }
  }
  return workingTimeInWeek <= maximumTimePerWeek;
};

const checkingSingleNightShift = ({ taskData, employeeTaskList }) => {
  let isValid = true;
  const { date: taskDate, taskType } = taskData;
  if (taskType != NIGHT_SHIFT_VALUE) return true;
  const prevDate = moment(taskDate).clone().add(-1, 'd').format('YYYY-MM-DD');
  const nextDate = moment(taskDate).clone().add(1, 'd').format('YYYY-MM-DD');

  const prevShift = commonChecking.getShiftByDate(prevDate, employeeTaskList);
  const nextShift = commonChecking.getShiftByDate(nextDate, employeeTaskList);
  if (prevShift) {
    const prevShiftType = prevShift.taskType;
    isValid = prevShiftType === NIGHT_SHIFT_VALUE;
  }
  if (nextShift) {
    const nextShiftType = nextShift.taskType;

    isValid = isValid || nextShiftType === NIGHT_SHIFT_VALUE;
  }
  return isValid;
};

const checkingNotWorkingDayShiftAfterNightShift = ({ taskData, employeeTaskList }) => {
  let isValid = true;
  const { date: taskDate, taskType } = taskData;
  if (taskType != DAY_SHIFT_VALUE) return true;
  const prevDate = moment(taskDate).clone().add(-1, 'd').format('YYYY-MM-DD');
  const prevSecondDate = moment(taskDate).clone().add(-2, 'd').format('YYYY-MM-DD');

  const prevShift = commonChecking.getShiftByDate(prevDate, employeeTaskList);
  const prevSecondShift = commonChecking.getShiftByDate(prevSecondDate, employeeTaskList);
  if (prevShift) {
    const prevShiftType = prevShift.taskType;

    isValid = prevShiftType !== NIGHT_SHIFT_VALUE;
  }
  if (prevSecondShift) {
    const prevSecondShiftType = prevSecondShift.taskType;
    isValid = isValid && prevSecondShiftType !== NIGHT_SHIFT_VALUE;
  }
  return isValid;
};

const checkValidF1 = ({
  dateOff,
  employeeTaskList,
  timeOffWeek,
  listDateOff = [],
  listPublicHoliday = [],
}) => {
  const prevDate = moment(dateOff).subtract(1, 'days');
  const nextDate = moment(dateOff).add(1, 'days');

  if (listPublicHoliday.includes(dateOff) || listDateOff.includes(dateOff)) {
    return false;
  }

  let timeOff = 24; // Assuming HOURS_IN_A_DAY is 24

  const shiftPrevExist = commonChecking.getShiftByDate(prevDate, employeeTaskList);
  if (!shiftPrevExist) {
    return true;
  } else {
    const shiftPrev = shiftPrevExist;
    if (shiftPrev.isNightShift() || shiftPrev.fromTime.isAfter(moment('21:00', 'HH:mm'))) {
      timeOff -= moment.duration(shiftPrev.endTime.diff(moment('00:00', 'HH:mm'))).asMinutes() / 60;
    } else {
      timeOff += moment.duration(moment('23:59', 'HH:mm').diff(shiftPrev.endTime)).asMinutes() / 60;
    }
  }

  const shiftNextExist = commonChecking.getShiftByDate(prevDate, employeeTaskList);

  if (nextDate.isoWeekday() !== 1) {
    // Assuming Monday is the first day of the week
    if (shiftNextExist) {
      const beginNextShift = shiftNextExist.fromTime;
      timeOff += moment.duration(beginNextShift.diff(moment('00:00', 'HH:mm'))).asMinutes() / 60;
    } else {
      timeOff += 24;
    }
  }
  return timeOff >= timeOffWeek;
};

const checkingIfHaveF1InWeek = ({
  taskData,
  employeeTaskList,
  amlInformation,
  prevShift,
  publicHoliday,
  listDateOffOfEmployee,
}) => {
  const { date: taskDate, taskType, hourAssigned } = taskData;
  const allDateInWeek = getAllDateInWeek(taskDate);
  const isHaveOffShiftInWeek = allDateInWeek.find((dateChecking) => {
    if (dateChecking === taskDate) {
      return false;
    }
    if (employeeTaskList[dateChecking] && employeeTaskList[dateChecking].length) {
      return commonChecking.isOffShift(employeeTaskList[dateChecking][0]);
    }
    return false;
  });
  if (isHaveOffShiftInWeek) {
    return true;
  } else {
    const listDateOff = allDateInWeek.filter((dateOff) => !employeeTaskList[dateOff]);

    for (const dateOff of listDateOff) {
      if (prevShift && prevShift.date === dateOff) {
        continue;
      }

      const isValidF1 = checkValidF1({
        dateOff,
        employeeTaskList,
        listDateOff: listDateOffOfEmployee,
        listPublicHoliday: publicHoliday,
        timeOffWeek: amlInformation.minimumHourOffWeek,
      });
      if (isValidF1) {
        return true;
      }
    }
    return false;
  }
};

export default {
  checkIsTooMuchShiftConsecutive,
  checkingRoleAndGroup,
  checkingEnoughTime,
  checkingTimeOffBetweenShift,
  checkingSingleNightShift,
  checkingNotWorkingDayShiftAfterNightShift,
  checkValidF1,
  checkingTimeInWeek,
  checkingIfHaveF1InWeek,
};
