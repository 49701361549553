import React from 'react';

import './styles.scss';
import Survey from './Survey/Survey';
import Comment from './Comment/Comment';

const Overview = ({ unitSelected, empInfo, handleClose }) => {
  return (
    <div className="menu-overview">
      <Survey unitSelected={unitSelected} empInfo={empInfo} handleClose={handleClose} />
      {/* <Comment /> */}
    </div>
  );
};

export default Overview;
