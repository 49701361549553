import React, { useEffect } from 'react';

import { CloseBlackIcon } from 'assets/img';

import './styles.scss';

const SideMenu = ({ children, isOpen, setIsOpen, handleClose, isSmall }) => {
  useEffect(() => {
    setIsOpen(true);
    document.body.style.overflow = 'hidden'; // Prevent scrolling in the background

    return () => {
      document.body.style.overflow = 'auto'; // Re-enable scrolling when the component unmounts
    };
  }, []);

  return (
    <div className="side-menu__wrapper">
      <div className="side-menu__background" onClick={handleClose}></div>
      <div className={`side-menu__form ${isOpen ? 'open' : ''} ${isSmall ? 'small' : ''}`}>
        <div className="side-menu__form-icon">
          <img src={CloseBlackIcon} onClick={handleClose} />
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default SideMenu;
