import { ArrowDownIcon } from 'assets/img';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './UnitSelectBox.scss';

const UnitSelectBox = ({
  departmentList,
  positionData,
  name,
  handleSelectUnitPosition,
  handleOnChange,
  index,
  positionList,
}) => {
  const unitSelectedList = positionList.map((item) => item.unit_code);
  const [departmentSelected, setDepartmentSelected] = useState({});
  const [unitSelected, setUnitSelected] = useState({});
  const [toggleDropdown, setToggleDropdown] = useState(false);

  const handleSelectDepartment = useCallback((department) => {
    setDepartmentSelected(department);
    const isUnitSelectedInDepartment = department.unit.find(
      (item) => item.unitCode === unitSelected.unitCode,
    );
    if (!Object.keys(unitSelected).length || !isUnitSelectedInDepartment) {
      setUnitSelected(department.unit[0]);
      handleOnChange({ target: { name, value: department.unit[0].unitCode } });

      handleSelectUnitPosition({
        positionID: positionData.id,
        unitCode: department.unit[0].unitCode,
      });
    }
  }, []);

  const visibleName = useMemo(() => {
    if (!Object.keys(unitSelected).length) return '';
    return departmentSelected.departmentName + ', ' + unitSelected.unitName;
  }, [departmentSelected, unitSelected]);

  const unitListRef = useRef(null);
  const titleRef = useRef(null);
  const handleToggleSelection = (e) => {
    e.stopPropagation();
    setToggleDropdown(!toggleDropdown);
  };

  const handleSelectUnit = useCallback((department, unit) => {
    setDepartmentSelected(department);
    setUnitSelected(unit);
    handleOnChange({ target: { name, value: unit.unitCode } });
    handleSelectUnitPosition({ positionID: positionData.id, unitCode: unit.unitCode });
  }, []);

  useEffect(() => {
    if (positionData && positionData[name]) {
      const departmentFound = departmentList.find((item) =>
        item.unit.find((unitInList) => unitInList.unitCode === positionData[name]),
      );
      if (departmentFound) {
        setDepartmentSelected(departmentFound);
        const unitFound = departmentFound.unit.find((item) => item.unitCode === positionData[name]);
        if (unitFound) {
          setUnitSelected(unitFound);
        }
      }
    }
  }, [positionData, departmentList]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        unitListRef.current &&
        !unitListRef.current.contains(event.target) &&
        !titleRef.current.contains(event.target)
      ) {
        setToggleDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div className="unit-select-box__wrapper">
      <div className="unit-select-box__selected" ref={titleRef} onClick={handleToggleSelection}>
        {visibleName}
      </div>
      {index > 0 ? (
        <div className={`unit-select-box__list  ${toggleDropdown ? 'show' : ''}`} ref={unitListRef}>
          {departmentList.map((department) => {
            const { departmentCode, departmentName, unit } = department;
            return (
              <div className="unit-select-box__department" key={`${departmentCode}-unitselectbox`}>
                <div className="department-select">
                  <div className="department-select__info">
                    <input
                      type="radio"
                      name=""
                      id={`radio-${departmentCode}-unitselectbox`}
                      checked={departmentCode === departmentSelected.departmentCode}
                    />
                    <label
                      htmlFor={`radio-${departmentCode}-unitselectbox`}
                      onClick={() => {
                        handleSelectDepartment(department);
                      }}
                    >
                      {departmentName}
                    </label>
                  </div>
                  <div
                    className={`department-select__arrow ${
                      departmentSelected.departmentCode === departmentCode ? 'selected' : ''
                    }`}
                  >
                    <img src={ArrowDownIcon} alt="" width={18} />
                  </div>
                </div>
                {departmentSelected.departmentCode === departmentCode ? (
                  <div className="unit-select-box__unit">
                    {unit.map((item) => {
                      const { unitCode, unitName } = item;
                      if (unitSelectedList.includes(unitCode) && unitSelected.unitCode !== unitCode)
                        return;
                      return (
                        <div
                          className="unit-select"
                          key={`${unitCode}-unitselectbox`}
                          onClick={() => {
                            handleSelectUnit(department, item);
                          }}
                        >
                          <input
                            type="radio"
                            name=""
                            id={`radio-${unitCode}-unitselectbox`}
                            checked={unitCode === unitSelected.unitCode}
                          />
                          <label htmlFor={`radio-${unitCode}-unitselectbox`}>{unitName}</label>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default UnitSelectBox;
