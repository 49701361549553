import axiosClient from 'axios/axiosClient';
import commonChecking from 'components/CalendarPlan/GeneratorContent/components/TableContent/helper/commonChecking';
import { useQuery } from 'react-query';

// {
//     "startDay": "2023-01-01T00:00:00.000Z",
//     "endDay": "2024-07-31",
//     "unitSelectedArr": [
//         431359
//     ],
//     "dayNightArr": [],
//     "departmentSelectedArr": [
//         "E073730"
//     ]
// }
// startDay, endDay, unitSelectedArr, dayNightArr, dateSelection

export const useAbsencePredction = ({ unitSelected, startDay, endDay, typeShift, chartUnit }) => {
  return useQuery(
    ['absence-prediction', unitSelected, startDay, endDay, typeShift, chartUnit],
    async () => {
      const result = await axiosClient.post('planning/predict-week', {
        startDay,
        endDay,
        unitSelectedArr: unitSelected,
        dayNightArr: typeShift,
        chartUnit,
      });
      if (chartUnit === 'weekend') {
        const predictData = result.predictData.filter((item) =>
          commonChecking.isWeekend(item.Date),
        );
        return { ...result, predictData };
      }
      return result;
    },
    {
      initialData: [],
      enabled: !!unitSelected && !!startDay && !!endDay && !!typeShift,
    },
  );
};
export const useAbsencePredctionAllType = ({ unitSelected, startDay, endDay }) => {
  return useQuery(
    ['absence-prediction-all', unitSelected, startDay, endDay],
    async () => {
      const result = axiosClient.post('planning/predict-week-all', {
        startDay,
        endDay,
        unitSelectedArr: unitSelected,
      });
      return result;
    },
    {
      initialData: {},
      enabled: !!unitSelected && !!startDay && !!endDay,
    },
  );
};

export const useEvaluaringPredction = ({
  unitSelected,
  startDay,
  endDay,
  typeShift,
  chartUnit,
}) => {
  return useQuery(
    ['eveluaring-prediction', unitSelected, startDay, endDay, typeShift, chartUnit],
    async () => {
      const dateSelection = chartUnit === 'weekend' ? 'Date' : chartUnit;
      const result = await axiosClient.post('evaluation/predict', {
        startDay,
        endDay,
        unitSelectedArr: unitSelected,
        dayNightArr: typeShift,
        dateSelection: dateSelection,
      });
      if (chartUnit === 'weekend') {
        return result.filter((item) => commonChecking.isWeekend(item.Date));
      }
      return result;
    },
    {
      initialData: [],
      enabled: !!unitSelected && !!startDay && !!endDay && !!typeShift && !!chartUnit,
    },
  );
};

export const useGetDefaultPredictRange = ({ currentNav }) => {
  return useQuery(
    ['default-range', currentNav],
    async () => {
      const result = axiosClient.post('planning/get-default-range', { tabRequest: currentNav });
      return result;
    },
    {
      initialData: {},
      enabled: !!currentNav,
    },
  );
};
