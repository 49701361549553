import { copyFeature } from 'helper/copy';
import React from 'react';

import { CopyIcon } from 'assets/img';

import './EmployeeGroupAction.scss';
import { Toast } from 'helper/alert';
import { t } from 'i18next';

const getDataCopy = (shiftData, dateRange) => {
  const data = dateRange?.map((date) => (shiftData[date] ? shiftData[date][0]['taskName'] : ' '));

  copyFeature(data, false);
};
const getDataCopyTable = (shiftData, dateRange) => {
  const result = shiftData.map((emp) => {
    const shifts = dateRange.map((date) =>
      emp.task_arr[date] ? emp.task_arr[date][0]['taskName'] : ' ',
    );
    return shifts.join('\t');
  });
  copyFeature(result, true);
};
const CopyButton = (props) => {
  const { data, isHeader, totalData, dateRange } = props;
  const handleCopy = () => {
    if (!dateRange.length) {
      Toast({ icon: 'error', title: t('copy-text-unsucess') });
      return;
    }
    if (data?.employeeId) {
      getDataCopy(data.task_arr, dateRange);
    } else {
      if (isHeader) {
        const sortedData = props.api.getModel().rowsToDisplay.map((rowNode) => rowNode.data);

        getDataCopyTable(sortedData, dateRange);
      }
    }
  };
  return (
    <div className="employee-action__wrap">
      <img src={CopyIcon} onClick={handleCopy} />
    </div>
  );
};

export default CopyButton;
