import { t } from 'i18next';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';

import { CalendarPlanGroupRow } from './CalendarPlanGroupRow';
import CalendarPlanGroupRowNew from './CalendarPlanGroupRowNew';

import { useGroups } from 'Hooks/useData';
import { closeModal } from '_components/Modal/slice';
import { PlusBrownIcon } from 'assets/img';

const RenderGroupRow = ({ groupList = [], refetchGroup }) => {
  return groupList.map((group) => {
    return <CalendarPlanGroupRow key={group.id} defaultGroup={group} refetchGroup={refetchGroup} />;
  });
};

const CalendarPlanGroup = ({ unitSelected }) => {
  const dispatch = useDispatch();
  const [isCreate, setIsCreate] = useState(false);
  const { data: groupList, refetch: refetchGroup } = useGroups({ unitSelected });

  return (
    <div className="calendar-plan-create-role__wrap">
      <div className="calendar-plan-create-role__content">
        <div className="calendar-plan-create-role__header">
          <h4>{t('group-management')}</h4>
          <CloseOutlined
            onClick={() => {
              dispatch(closeModal());
            }}
          />
        </div>
        <div className="calendar-plan-create-role__list">
          <table className="calendar-plan-create-role__table">
            <thead>
              <th className="calendar-plan-create-role__item-label">{t('name')}</th>
              <th className="calendar-plan-create-role__item-label">{t('key')}</th>
              <th className="calendar-plan-create-role__item-label" style={{ minWidth: '500px' }}>
                {t('description')}
              </th>
              <th className="calendar-plan-create-role__item-label">{t('action')}</th>
            </thead>
            <tbody>
              <RenderGroupRow groupList={groupList?.data} refetchGroup={refetchGroup} />
              {isCreate ? (
                <CalendarPlanGroupRowNew
                  refetchGroup={refetchGroup}
                  setActive={setIsCreate}
                  unitSelected={unitSelected}
                />
              ) : null}
            </tbody>
          </table>
        </div>
        <div className="calendar-plan-create-role__action">
          <button
            className="submit-btn"
            onClick={() => {
              setIsCreate(true);
            }}
          >
            <img src={PlusBrownIcon} alt="" width={14} />
            {t('add-group')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CalendarPlanGroup;
