import React from 'react';

import './DefaultFooter.scss';

export default function DefaultFooter() {
  return (
    <div className="footer__wrap">
      <div className="coppy-right">
        <span>&copy; 2024 SynPlan - Healthcare Planning System.</span>
      </div>
    </div>
  );
}
