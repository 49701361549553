import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';

import employeeAPI from '_apis/employee';
import axiosClient from 'axios/axiosClient';
import { Toast, showLoading } from 'helper/alert';
import { setEditedEmpAction } from 'redux/global/globalAction';
import { ToggleOffPinkIcon, ToggleOnPinkIcon } from 'assets/img';
import { isEmailValid, isPhoneValid } from 'helper/dateTime';
import { selectGeneratorColApi } from 'redux/generateTableApi/slice';

import {
  useEmployeeExtendedProfile,
  useEmployees,
  useExtendedProfileProperties,
  useGroups,
  useRoleTypes,
  useRoles,
} from 'Hooks/useData';

import './styles.scss';
import { selectAuth } from 'redux/auth/authSlice';
import { formatNorwayPhoneNumber } from 'components/CalendarPlan/CalendarPlanEmployee/CreateEmpModal/component/BulkCreateEmp/helper';

const FormField = ({ label, type, name, value = '', onChange, placeholder = '', disabled }) => (
  <label>
    {t(label)}
    <input
      disabled={disabled}
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  </label>
);

const Settings = ({
  unitSelected,
  data,
  refectEmpDetail,
  setData,
  refetchEmployeeWithPositionFetch,
}) => {
  const { isEditabled } = useSelector(selectAuth);

  const dispatch = useDispatch();
  const [empInfo, setEmpInfo] = useState({});
  const [extendedEmpInfo, setExtendedEmpInfo] = useState({});
  const { splitEmployee } = employeeAPI();
  const { api: empTableApi } = useSelector(selectGeneratorColApi).empInfo;

  // fetch data
  const { refetch: refetchEmployees } = useEmployees({ unitSelected });
  const { data: extendedProperties } = useExtendedProfileProperties();
  const { data: empExtendedProfile, refetch: refecthEmpExtendProfile } = useEmployeeExtendedProfile(
    { empUUID: data.id },
  );
  const { data: roleList } = useRoles();
  const {
    data: { data: groupList },
  } = useGroups({ unitSelected });
  const { data: roleTypeList } = useRoleTypes();

  const handleOnChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      const { extendedInfo } = empInfo;

      if (name === 'role_type') {
        const newRoleType = roleTypeList.find((role) => role.type === value);
        setEmpInfo({
          ...empInfo,
          role_type: newRoleType?.id,
          role_type_detail: newRoleType,
        });
        return;
      }

      if (Object.keys(extendedInfo).includes(name)) {
        setEmpInfo({
          ...empInfo,
          extendedInfo: {
            ...extendedInfo,
            [name]: value,
          },
        });
        return;
      }

      if (name === 'job_percentage') {
        setEmpInfo({
          ...empInfo,
          [name]: value > 100 ? 100 : value < 0 ? 0 : value,
        });
        return;
      }

      if (empInfo.isSplited && name === 'group') {
        const group_id = groupList?.find(
          (group) => group.name.toLowerCase() === value.toLowerCase(),
        )?.id;

        setEmpInfo({
          ...empInfo,
          [name]: value,
          group_id,
        });
        return;
      }

      if (name === 'role') {
        const role_id = roleList.find((role) => role.role === value)?.id;
        setEmpInfo({ ...empInfo, role_id, [name]: value });
        return;
      }

      setEmpInfo({
        ...empInfo,
        [name]: value,
      });
    },
    [empInfo],
  );

  useEffect(() => {
    if (extendedProperties && empExtendedProfile) {
      const extendedInfo = {};
      extendedProperties.forEach((property) => {
        const propertyValue = empExtendedProfile.find(
          (empProperty) => empProperty.property_id === property.id,
        );
        extendedInfo[property.property_key] = propertyValue?.value || '';
      });
      setExtendedEmpInfo(extendedInfo);
    }
  }, [extendedProperties, empExtendedProfile]);

  useEffect(() => {
    if (data && extendedEmpInfo) {
      const employeeName = {};
      if (data?.name && /,/.test(data?.name)) {
        employeeName.firstName = data?.name?.split(',')[0] || '';
        employeeName.lastName = data?.name?.split(',').slice(1).join(' ') || '';
      } else {
        employeeName.firstName = data?.name?.split(' ')[0] || '';
        employeeName.lastName = data?.name?.split(' ').slice(1).join(' ') || '';
      }
      setEmpInfo({
        ...data,
        extendedInfo: {
          ...extendedEmpInfo,
        },
        phone_number: data?.phone_number || '+47 ',
        ...employeeName,
      });
    }
  }, [data, empExtendedProfile, extendedEmpInfo]);

  const handleUpdateEmpInfo = useCallback(async () => {
    const {
      phone_number,
      id,
      email,
      job_percentage,
      isSplited = false,
      listGroupSplit,
      extendedInfo,
    } = empInfo;

    const propertyKeys = Object.keys(extendedInfo);
    const extendedEmployeeInfo = {};

    propertyKeys.forEach((key) => {
      extendedEmployeeInfo[key] = {
        value: extendedInfo[key],
        property_id: extendedProperties.find((prop) => prop.property_key === key).id,
      };
    });

    empInfo.name = empInfo.firstName + ' ' + empInfo.lastName;

    const showErrorToast = async (title) => {
      Toast({
        icon: 'error',
        title: t(title),
      });
    };

    if (!isSplited && listGroupSplit?.find((group) => group.jobPercentage > job_percentage)) {
      showErrorToast('job-percentage-cannot-lower-than-split');
      return;
    }

    if (!job_percentage || +job_percentage <= 0) {
      showErrorToast('job-percentage-required');
      return;
    }

    if (email && !isEmailValid(email)) {
      showErrorToast('wrong-email-format');
      return;
    }

    if (phone_number) {
      const sanitizedValue = phone_number ? phone_number.replace(/[^+\d]/g, '') : '';
      empInfo.phone_number = formatNorwayPhoneNumber(sanitizedValue);
    }

    if (isSplited) {
      const { id, group_id, job_percentage, splitID, source_job_percentage } = empInfo;

      if (job_percentage > source_job_percentage) {
        showErrorToast('splited-job-percentage-invalid');
        return;
      }

      showLoading({ shouldShow: true, title: 'update' });

      await splitEmployee({
        employeeID: id,
        groupID: group_id,
        jobPercentage: job_percentage,
        splitID,
      }).then(async (result) => {
        await refetchEmployees();
        showLoading({ shouldShow: false });
        Toast({
          icon: 'success',
          title: `${t('update')} ${t('employee')} ${t('success')}`,
        });
        dispatch(setEditedEmpAction(id));
      });
      return;
    }

    if (+empInfo.job_percentage > 0 && empInfo.name?.trim().length) {
      showLoading({ shouldShow: true, title: 'update' });
      Promise.all([
        axiosClient.post('calendar-plan/employee/update', { employeeInfo: empInfo }),
        axiosClient.put(`calendar-plan/employee/extend-property/update/${empInfo.id}`, {
          employeeInfo: extendedEmployeeInfo,
        }),
      ])
        .then(async () => {
          setData(empInfo);
          if (empInfo.isCalendarPlan) {
            const currentRow = empTableApi.getRowNode(empInfo.id);
            const updatedData = {
              ...currentRow.data,
              name: empInfo.name,
              jobPercentage: +empInfo.job_percentage,
              role: empInfo.role,
              roleId: empInfo.role_id,
              groupName: empInfo.group,
            };
            await currentRow.setData(updatedData);
            await refecthEmpExtendProfile();
            await refectEmpDetail();
            if (refetchEmployeeWithPositionFetch) {
              await refetchEmployeeWithPositionFetch();
            }
          } else {
            await refetchEmployees();
            await refecthEmpExtendProfile();
          }
          showLoading({ shouldShow: false });
          Toast({
            icon: 'success',
            title: `${t('update')} ${t('employee')} ${t('success')}`,
          });
        })
        .catch(() => {
          showLoading({ shouldShow: false });
          Toast({
            icon: 'error',
            title: `${t('update')} ${t('employee')} ${t('unsuccess')}`,
          });
        });
    }

    dispatch(setEditedEmpAction(id));
  }, [empInfo, extendedEmpInfo]);

  return (
    <div className="user-settings">
      <div>
        <p>{t('profile-info')}</p>
        <div className="form-info">
          <div className="row">
            <FormField
              label="first-name"
              type="text"
              name="firstName"
              value={empInfo.firstName}
              onChange={handleOnChange}
              disabled={!isEditabled}
            />
            <FormField
              label="last-name"
              type="text"
              name="lastName"
              value={empInfo.lastName}
              onChange={handleOnChange}
              disabled={!isEditabled}
            />
          </div>
          <div className="row">
            <FormField
              label="emp-id-number"
              type="text"
              name="emp_id_number"
              value={empInfo.emp_id_number}
              onChange={handleOnChange}
              disabled={!isEditabled}
            />
            <FormField
              label="phone-number"
              type="tel"
              name="phone_number"
              value={empInfo.phone_number}
              onChange={handleOnChange}
              disabled={!isEditabled}
            />
          </div>
          <div className="row">
            <FormField
              label="email"
              type="email"
              name="email"
              onChange={handleOnChange}
              value={empInfo.email}
              disabled={!isEditabled}
            />
          </div>
        </div>
      </div>

      <div>
        <p>{t('competence')}</p>
        <div className="form-info">
          <div className="toggle-question">
            <img
              src={empInfo.medical_delivery ? ToggleOnPinkIcon : ToggleOffPinkIcon}
              onClick={() =>
                isEditabled &&
                setEmpInfo((prev) => ({ ...prev, medical_delivery: !prev.medical_delivery }))
              }
            />
            <p>
              {t('medical-distribution')} <span>{t('medical-distribution-desc')}</span>
            </p>
          </div>
        </div>
      </div>
      <div className="update-btn">
        <button disabled={!isEditabled} onClick={handleUpdateEmpInfo}>
          {t('update')}
        </button>
      </div>
    </div>
  );
};

export default Settings;
