import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import CalendarPlan from 'views/pages/CalendarPlan';
import Profile from 'views/pages/Profile';

const Chiron = lazy(() => import('views/pages/Chiron'));
const Admin = lazy(() => import('views/pages/Admin'));
const PredictPage = lazy(() => import('views/pages/PredictPage'));

const routes = [
  {
    key: 'home',
    name: 'CalendarPlan',
    path: '/',
    component: <Navigate replace to="/calendar" />,
    roles: ['planner', 'unions', 'partner', 'admin', 'economist', 'super-admin'],
    pages: [
      'trondheim',
      'finnmarkssykehuset',
      'indre-ostfold',
      'nesodden',
      'bydel-alna',
      'diakon',
      'baerum',
      'chiron',
      'helse',
      // 'bluebirdcarers',
      // 'carersbreak',
      // 'heathlandsvillage',
      // 'falcare',
      // 'malvik',
      // 'indrefosen',
      'oslo',
      // 'hitra',
      // 'diakonhjemmet',
      'demo',
      'ahus',
      'enebakk',
      'chiron-base',
      'chiron-stg',
      'chiron-dev',
      'chiron-admin',
    ],
  },

  {
    key: 'predict',
    path: '/predict',
    name: 'Predict',
    component: <PredictPage />,
    roles: ['planner', 'unions', 'partner', 'admin', 'economist', 'super-admin'],
    pages: [
      'trondheim',
      'finnmarkssykehuset',
      'indre-ostfold',
      'nesodden',
      'bydel-alna',
      'diakon',
      'baerum',
      'chiron',
      'helse',
      'bluebirdcarers',
      'carersbreak',
      'heathlandsvillage',
      'falcare',
      'malvik',
      'indrefosen',
      'oslo',
      'hitra',
      'diakonhjemmet',
      'demo',
      'ahus',
      'enebakk',
      'chiron-base',
      'chiron-stg',
      'chiron-dev',
      'chiron-admin',
    ],
  },

  {
    key: 'calendar',
    path: '/calendar',
    name: 'CalendarPlan',
    component: <CalendarPlan />,
    roles: ['planner', 'unions', 'partner', 'admin', 'super-admin'],
    pages: [
      'trondheim',
      'finnmarkssykehuset',
      'indre-ostfold',
      'nesodden',
      'bydel-alna',
      'diakon',
      'baerum',
      'chiron',
      'helse',
      // 'bluebirdcarers',
      // 'carersbreak',
      // 'heathlandsvillage',
      // 'falcare',
      // 'malvik',
      // 'indrefosen',
      'oslo',
      // 'hitra',
      // 'diakonhjemmet',
      'demo',
      'ahus',
      'enebakk',
      'chiron-base',
      'chiron-stg',
      'chiron-dev',
      'chiron-admin',
    ],
  },
  {
    key: 'admin',
    path: '/admin',
    name: 'Admin',
    component: <Admin />,
    roles: ['admin', 'super-admin'],
    pages: [
      'trondheim',
      'finnmarkssykehuset',
      'indre-ostfold',
      'nesodden',
      'bydel-alna',
      'diakon',
      'baerum',
      'enebakk',
      'chiron',
      'helse',
      'chiron-base',
      'chiron-stg',
      'chiron-dev',
      'chiron-admin',
    ],
  },

  {
    key: 'chiron',
    path: '/chiron',
    name: 'Chiron',
    component: <Chiron />,
    roles: ['planner', 'unions', 'admin', 'super-admin'],
    pages: ['chiron', 'chiron-stg', 'chiron-dev', 'helse'],
  },

  {
    key: 'profile',
    path: '/profile',
    name: 'profile',
    component: <Profile />,
    roles: ['planner', 'unions', 'partner', 'admin', 'super-admin'],
    pages: [
      'trondheim',
      'finnmarkssykehuset',
      'indre-ostfold',
      'nesodden',
      'bydel-alna',
      'diakon',
      'baerum',
      'chiron',
      'helse',
      'bluebirdcarers',
      'carersbreak',
      'heathlandsvillage',
      'falcare',
      'malvik',
      'indrefosen',
      'oslo',
      'hitra',
      'diakonhjemmet',
      'demo',
      'ahus',
      'enebakk',
      'chiron-base',
      'chiron-stg',
      'chiron-dev',
      'chiron-admin',
    ],
  },
];

export default routes;
