import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  isLoading: false,
};

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    show(state, action) {
      state.isLoading = true;
    },
    close(state, action) {
      state.isLoading = false;
    },
    clear: () => initialState,
  },
});

export const selectLoading = (state) => state.loading;
export const { show: showLoading, close: hideLoading } = loadingSlice.actions;
export default loadingSlice.reducer;
