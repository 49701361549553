import {
  DAY_SHIFT_VALUE,
  EVENING_SHIFT_VALUE,
  LONG_SHIFT_VALUE,
  NIGHT_SHIFT_VALUE,
  offShiftReg,
} from 'constant';

export const calculateTotalTaskByType = (data) => {
  const result = {};

  for (const task of data) {
    const { taskKey, taskList } = task;
    for (const weekDay of taskList) {
      const { date, numberPerson, layerName } = weekDay;
      if (!result[date]) {
        result[date] = {
          [layerName]: { day: 0, late: 0, night: 0, 'long-shift': 0, 'other-shift': 0 },
          totalDayShift: 0,
          totalLateShift: 0,
          totalNightShift: 0,
          totalLongShift: 0,
          totalOtherShift: 0,
        };
      } else {
        if (!result[date][layerName]) {
          result[date][layerName] = {
            day: 0,
            late: 0,
            night: 0,
            'long-shift': 0,
            'other-shift': 0,
          };
        }
      }

      switch (taskKey) {
        case 'day-shift': {
          result[date].totalDayShift += numberPerson;
          result[date][layerName].day += numberPerson;
          break;
        }
        case 'aft-shift': {
          result[date].totalLateShift += numberPerson;
          result[date][layerName].late += numberPerson;

          break;
        }
        case 'evn-shift': {
          result[date].totalNightShift += numberPerson;
          result[date][layerName].night += numberPerson;

          break;
        }
        case 'long-shift': {
          result[date].totalLongShift += numberPerson;
          result[date][layerName]['long-shift'] += numberPerson;

          break;
        }
        case 'other-shift': {
          result[date].totalOtherShift += numberPerson;
          result[date][layerName]['other-shift'] += numberPerson;

          break;
        }
        default: {
          console.warn(`Unknown taskKey: ${taskKey}`);
          break;
        }
      }
    }
  }

  return result;
};

export const calculateSummaryData = (data, totalTaskCount) => {
  const result = {};
  for (const emp of data) {
    const isVakant = !emp.name;
    const taskArr = emp.task_arr;
    const listDate = Object.keys(taskArr);
    for (const date of listDate) {
      const task = taskArr[date];
      if (!task.length) {
        continue;
      }
      const isHaveRoleOrGroup = task[0].roleID || task[0].groupID;
      const taskType = task[0]?.taskType;
      const taskName = task[0]?.taskName;
      const roleName = task[0]?.roleName;
      const groupName = task[0]?.groupName;
      const layerName = task[0].layerName;
      if (offShiftReg.test(taskName)) {
        continue;
      }
      if (!result[date]) {
        result[date] = {
          [layerName]: {
            day: 0,
            late: 0,
            night: 0,
            'long-shift': 0,
            'other-shift': 0,
            total: totalTaskCount && totalTaskCount[date] ? totalTaskCount[date][layerName] : {},
          },

          dayShift: 0,
          dayShiftRequirment: new Set(),
          dayShiftHaveRoleOrGroup: 0,
          totalDayShift: totalTaskCount[date]?.totalDayShift,
          totalDayShiftHaveRoleOrGroup: 0,

          lateShift: 0,
          lateShiftRequirment: new Set(),
          lateShifHaveRoleOrGroup: 0,
          totalLateShift: totalTaskCount[date]?.totalLateShift,
          totalLateShiftHaveRoleOrGroup: 0,

          nightShift: 0,
          nightShiftRequirment: new Set(),
          nightShifHaveRoleOrGroup: 0,
          totalNightShift: totalTaskCount[date]?.totalNightShift,
          totalNightShiftHaveRoleOrGroup: 0,

          'long-shift': 0,
          longShiftRequirment: new Set(),
          longShifHaveRoleOrGroup: 0,
          totalLongShift: totalTaskCount[date]?.totalLongShift,
          totalLongShiftHaveRoleOrGroup: 0,

          otherShift: 0,
          otherShiftRequirment: new Set(),
          otherShifHaveRoleOrGroup: 0,
          totalOtherShift: totalTaskCount[date]?.totalOtherShift,
          totalOtherShiftHaveRoleOrGroup: 0,
          vakantShift: 0,
        };
      } else {
        if (!result[date][layerName]) {
          result[date][layerName] = {
            day: 0,
            late: 0,
            night: 0,
            'long-shift': 0,
            'other-shift': 0,
            total: totalTaskCount && totalTaskCount[date] ? totalTaskCount[date][layerName] : {},
          };
        }
      }

      if (isVakant) {
        result[date].vakantShift++;
      }
      switch (taskType) {
        case DAY_SHIFT_VALUE:
          if (isHaveRoleOrGroup) result[date].totalDayShiftHaveRoleOrGroup++;
          if (!isVakant) {
            result[date].dayShift++;
            if (isHaveRoleOrGroup) {
              result[date].dayShiftHaveRoleOrGroup++;
              if (roleName) {
                result[date].dayShiftRequirment.add(roleName);
              }
              if (groupName) {
                result[date].dayShiftRequirment.add(groupName);
              }
            }
            if (layerName) {
              result[date][layerName].day++;
            }
          }
          break;
        case EVENING_SHIFT_VALUE:
          if (isHaveRoleOrGroup) result[date].totalLateShiftHaveRoleOrGroup++;
          if (!isVakant) {
            result[date].lateShift++;
            if (isHaveRoleOrGroup) {
              result[date].lateShifHaveRoleOrGroup++;
              if (roleName) {
                result[date].lateShiftRequirment.add(roleName);
              }
              if (groupName) {
                result[date].lateShiftRequirment.add(groupName);
              }
            }
            if (layerName) {
              result[date][layerName].late++;
            }
          }
          break;
        case NIGHT_SHIFT_VALUE:
          if (isHaveRoleOrGroup) result[date].totalNightShiftHaveRoleOrGroup++;

          if (!isVakant) {
            result[date].nightShift++;
            if (isHaveRoleOrGroup) {
              result[date].nightShifHaveRoleOrGroup++;
              if (roleName) {
                result[date].nightShiftRequirment.add(roleName);
              }
              if (groupName) {
                result[date].nightShiftRequirment.add(groupName);
              }
            }
            if (layerName) {
              result[date][layerName].night++;
            }
          }
          break;
        case LONG_SHIFT_VALUE:
          if (isHaveRoleOrGroup) result[date].totaLongShiftHaveRoleOrGroup++;

          if (!isVakant) {
            result[date]['long-shift']++;
            if (isHaveRoleOrGroup) {
              result[date].longShifHaveRoleOrGroup++;
              if (roleName) {
                result[date].longShiftRequirment.add(roleName);
              }
              if (groupName) {
                result[date].longShiftRequirment.add(groupName);
              }
            }
            if (layerName) {
              result[date][layerName]['long-shift']++;
            }
          }
          break;

        default:
          if (isHaveRoleOrGroup) result[date].totalOtherShiftHaveRoleOrGroup++;

          if (!isVakant) {
            result[date].otherShift++;
            if (isHaveRoleOrGroup) {
              result[date].otherShifHaveRoleOrGroup++;
              if (roleName) {
                result[date].otherShiftRequirment.add(roleName);
              }
              if (groupName) {
                result[date].otherShiftRequirment.add(groupName);
              }
            }
            if (layerName) {
              result[date][layerName]['other-shift']++;
            }
          }
          break;
      }
    }
  }

  return result;
};
