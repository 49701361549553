import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectAuth } from 'redux/auth/authSlice';

const WorkingEnvAct = ({ questions, updatedData, handleUpdate }) => {
  const { i18n } = useTranslation();
  const { isEditabled } = useSelector(selectAuth);

  return (
    <div className="working-env-act">
      {questions.map((question, index) => {
        return (
          <div className="question-item" key={question.uuid}>
            <p>{index + 1}</p>
            <p className={question.question_desc ? 'question-col' : 'question-content'}>
              {i18n.language === 'NO' ? question.question_no : question.question}{' '}
              {question.question_desc && (
                <span>
                  {i18n.language === 'NO' ? question.question_no_desc : question.question_desc}
                </span>
              )}
            </p>
            <input
              type="number"
              className="answer-input"
              value={updatedData?.[question.uuid] ? updatedData?.[question.uuid]['value'] : ''}
              onWheel={(e) => e.target.blur()}
              name={question.uuid}
              onChange={handleUpdate}
              disabled={!isEditabled}
            />
          </div>
        );
      })}
    </div>
  );
};

export default WorkingEnvAct;
