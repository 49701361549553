import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'i18next';

import axiosClient from 'axios/axiosClient';
import { Toast } from 'helper/alert';
import { PlusIcon } from 'assets/img';

import {
  useDepartmentList,
  useEmployeeExtendedProfile,
  useExtendedProfileProperties,
  useGroups,
  useRoleTypes,
  useRoles,
} from 'Hooks/useData';

import './styles.scss';
import { selectAuth } from 'redux/auth/authSlice';
import AddGroup from './AddGroup/AddGroup';
import PositionInfo from './PositionInfo/PositionInfo';

const Positions = ({
  unitSelected,
  data,
  refectEmpDetail,
  setData,
  employeePosition = [],
  refetchEmployeeWithPositionFetch,
}) => {
  const { isEditabled } = useSelector(selectAuth);
  const [positionList, setPositionList] = useState([]);
  const [empInfo, setEmpInfo] = useState({});
  const [extendedEmpInfo, setExtendedEmpInfo] = useState({});
  const [isActiveGroupModal, setIsActiveGroupModal] = useState(false);
  const [addGroupData, setAddGroupData] = useState({});
  // fetch data
  const { data: departmentList } = useDepartmentList();
  const { data: extendedProperties } = useExtendedProfileProperties();
  const { data: empExtendedProfile } = useEmployeeExtendedProfile({ empUUID: data.id });
  const { data: roleList } = useRoles();
  const {
    data: { data: groupList },
  } = useGroups({ unitSelected });
  const { data: roleTypeList } = useRoleTypes();

  const handleOnChangePositionList = (e, positionID, positionIndex) => {
    const { name, value } = e.target;

    setPositionList((prev) => {
      const listUpdate = [...prev];
      let positionIndexInList = listUpdate.findIndex((item) => item.id === +positionID);
      if (positionIndexInList < 0 && positionID) return prev;

      if (!positionID) {
        positionIndexInList = positionIndex;
      }
      if (name === 'job_percentage') {
        listUpdate[positionIndexInList][name] = value > 100 ? 100 : value < 0 ? 0 : value;

        return listUpdate;
      }

      listUpdate[positionIndexInList][name] = value;
      return listUpdate;
    });
  };
  const handleSelectUnit = ({ positionID, unitCode }) => {
    setPositionList((prev) => {
      const listUpdate = [...prev];
      const positionIndex = listUpdate.findIndex((item) => item.id === +positionID);
      if (positionIndex < 0) return prev;
      listUpdate[positionIndex].unit_code = unitCode;
      return listUpdate;
    });
  };

  const handleDeleteGroup = ({ positionID, groupID, isPrimary }) => {
    setPositionList((prev) => {
      const listUpdate = [...prev];
      const positionIndex = listUpdate.findIndex((item) => item.id === +positionID);
      if (positionIndex < 0) return prev;
      const employeeGroups = listUpdate[positionIndex].employeeGroups;
      if (employeeGroups) {
        const newEmpGroup = employeeGroups.filter(
          (item) => !(item.group_id === +groupID && item.is_primary === isPrimary),
        );
        listUpdate[positionIndex].employeeGroups = newEmpGroup;
      }
      return listUpdate;
    });
  };

  const handleDeletePosition = (positionID, positionIndex) => {
    setPositionList((prev) => {
      const listUpdate = [...prev];
      if (!positionID) {
        listUpdate.splice(positionIndex, 1);
      }
      let positionIndex = listUpdate.findIndex((item) => item.id === +positionID);
      if (positionIndex < 0 && positionID) return prev;
      listUpdate[positionIndex].isDeleted = true;
      return listUpdate;
    });
  };

  useEffect(() => {
    if (employeePosition) setPositionList(employeePosition);
  }, [employeePosition]);

  useEffect(() => {
    if (extendedProperties && empExtendedProfile) {
      const extendedInfo = {};
      extendedProperties.forEach((property) => {
        const propertyValue = empExtendedProfile.find(
          (empProperty) => empProperty.property_id === property.id,
        );
        extendedInfo[property.property_key] = propertyValue?.value || '';
      });
      setExtendedEmpInfo(extendedInfo);
    }
  }, [extendedProperties, empExtendedProfile]);

  useEffect(() => {
    if (data && extendedEmpInfo) {
      setEmpInfo({
        ...data,
        extendedInfo: {
          ...extendedEmpInfo,
        },
        phone_number: data?.phone_number || '+47 ',
        firstName: data?.name?.split(' ')[0] || '',
        lastName: data?.name?.split(' ').slice(1).join(' ') || '',
      });
    }
  }, [data, empExtendedProfile, extendedEmpInfo]);

  const handleOpenAddGroup = (positionData, index) => {
    const positionID = positionData.id;
    const dataUpdate = positionList.find((item) => item.id === positionID);
    if (dataUpdate) {
      setIsActiveGroupModal(true);
      setAddGroupData({ ...dataUpdate, positionIndex: index });
    }
  };

  const handleAddGroup = (groupSelected, isSelectedPrimary) => {
    const positionID = addGroupData.id;
    setPositionList((prev) => {
      const listUpdate = [...prev];
      let positionIndex = listUpdate.findIndex((item) => item.id === +positionID);
      if (positionIndex < 0 && positionID) return prev;

      if (!positionID) {
        positionIndex = addGroupData.positionIndex;
        if (!listUpdate[positionIndex].employeeGroups) {
          listUpdate[positionIndex].employeeGroups = [];
        }
      }
      const employeeGroups = listUpdate[positionIndex].employeeGroups;

      if (employeeGroups) {
        const newGroupList = listUpdate[positionIndex].employeeGroups.filter(
          (item) => item.is_primary !== isSelectedPrimary,
        );

        for (const group of groupSelected) {
          const newGroupInList = {
            employee_id: addGroupData.employee_id,
            job_percentage: 100, //Will be remove later
            group_id: group.id,
            position_id: addGroupData.id,
            groupInformation: group,
            is_primary: group.is_primary,
          };
          newGroupList.push(newGroupInList);
        }
        listUpdate[positionIndex].employeeGroups = newGroupList;
      }
      return listUpdate;
    });
  };

  const getInValidPositionList = () => {
    const requiredKey = ['role_id', 'role_type', 'unit_code', 'job_percentage'];
    const invalidIndex = [];
    for (const positionIndex in positionList) {
      const position = positionList[positionIndex];
      for (const key of requiredKey) {
        if (!position[key]) {
          invalidIndex.push(positionIndex);
          break;
        }
      }
    }
    return invalidIndex;
  };
  const handleUpdatePositionList = () => {
    const invalidPosition = getInValidPositionList();
    if (invalidPosition.length) {
      Toast({
        icon: 'warning',
        title: `${t('checking-invalid-position')} ${invalidPosition.join(', ')}`,
      });
    } else {
      axiosClient
        .post('calendar-plan/employee-position/update', { positionList })
        .then(async (res) => {
          if (refetchEmployeeWithPositionFetch) {
            await refetchEmployeeWithPositionFetch();
          }
          Toast({ icon: 'success', title: t('save-changes') });
        })
        .catch((err) => {
          Toast({ icon: 'error', title: t('save-fail') });
        });
    }
  };
  const renderPosition = useCallback(() => {
    return positionList?.map((positionDataDefault, index) => {
      if (positionDataDefault.isDeleted) return;
      return (
        <PositionInfo
          key={`${positionDataDefault.id}-${positionDataDefault.employee_id}-position-data`}
          index={index}
          roleList={roleList}
          isEditabled={isEditabled}
          roleTypeList={roleTypeList}
          positionDataDefault={positionDataDefault}
          setPositionList={setPositionList}
          departmentList={departmentList}
          positionList={positionList}
          handleOpenAddGroup={handleOpenAddGroup}
          handleSelectUnit={handleSelectUnit}
          handleDeleteGroup={handleDeleteGroup}
          handleDeletePosition={handleDeletePosition}
          handleOnChangePositionList={handleOnChangePositionList}
        />
      );
    });
  }, [positionList, roleList, roleTypeList, departmentList]);
  return (
    <div className="user-settings">
      {renderPosition()}
      {/* 
      <div>
        <CreatePosition
          setPositionList={setPositionList}
          employeeID={empInfo.id}
          unitSelected={unitSelected}
        />
      </div> */}

      <div className="update-btn">
        <button disabled={!isEditabled} onClick={handleUpdatePositionList}>
          {t('update')}
        </button>
      </div>
      {isActiveGroupModal && isEditabled ? (
        <AddGroup
          unitSelected={unitSelected}
          setIsActive={setIsActiveGroupModal}
          addGroupData={addGroupData}
          setAddGroupData={setAddGroupData}
          handleAddGroup={handleAddGroup}
        />
      ) : null}
    </div>
  );
};

export default Positions;

const CreatePosition = ({ setPositionList, employeeID, unitSelected }) => {
  return (
    <div
      className="create-position__wrapper"
      onClick={() =>
        setPositionList((prev) => [...prev, { employee_id: employeeID, employeeGroups: [] }])
      }
    >
      <button>
        <img src={PlusIcon} alt="" width={18} />
      </button>
      <span>
        {t('create')} {t('position').toLowerCase()}
      </span>
    </div>
  );
};
