import {
  addPlanPatiensTask,
  addPlanStaffTask,
  deletePlanPatinesTask,
  deletePlanStaffTask,
  handlePasteWeekStaffPlanDetail,
  initGeneratorTable,
  initPlanPatiensTask,
  initPlanStaffTask,
  updateGeneratorDashboard,
  updateGeneratorTable,
  updatePlanDetailLocked,
  updateListLocked,
  updatePlanPatinesTask,
  updatePlanPatinesTaskDetail,
  updatePlanPatinesTaskRangeDate,
  updatePlanPatinesTaskTime,
  updatePlanSelected,
  updatePlanStaffTask,
  updatePlanStaffTaskDetail,
  updatePlanStaffTaskRangeDate,
  updatePlanStaffTaskTime,
} from './calendarPlanSlice';

export const updatePlanPatinesTaskAction = (val) => {
  return (dispatch) => {
    dispatch(updatePlanPatinesTask({ planPatinesTask: val }));
  };
};

export const initPlanPatiensTaskAction = (val) => {
  return (dispatch) => {
    dispatch(initPlanPatiensTask({ planPatinesTask: val }));
  };
};

export const updatePlanPatinesTaskTimeAction = (val) => {
  return (dispatch) => {
    dispatch(updatePlanPatinesTaskTime({ planPatinesTask: val }));
  };
};

export const updatePlanPatinesTaskDetailAction = (val) => {
  return (dispatch) => {
    dispatch(updatePlanPatinesTaskDetail({ planPatinesTask: val }));
  };
};

export const deletePlanPatinesTaskAction = (val) => {
  return (dispatch) => {
    dispatch(deletePlanPatinesTask({ planPatinesTask: val }));
  };
};

export const updatePlanPatinesTaskRangeDateAction = (val) => {
  return (dispatch) => {
    dispatch(updatePlanPatinesTaskRangeDate({ planPatinesTask: val }));
  };
};

export const addPlanPatiensTaskAction = (val) => {
  return (dispatch) => {
    dispatch(addPlanPatiensTask({ planPatinesTask: val }));
  };
};
// Staff Plan

export const updatePlanStaffTaskAction = (val) => {
  return (dispatch) => {
    dispatch(updatePlanStaffTask({ planStaffTask: val }));
  };
};

export const initPlanStaffTaskAction = (val) => {
  return (dispatch) => {
    dispatch(initPlanStaffTask({ planStaffTask: val }));
  };
};

export const updatePlanStaffTaskTimeAction = (val) => {
  return (dispatch) => {
    dispatch(updatePlanStaffTaskTime({ planStaffTask: val }));
  };
};

export const updatePlanStaffTaskDetailAction = (val) => {
  return (dispatch) => {
    dispatch(updatePlanStaffTaskDetail({ planStaffTask: val }));
  };
};
export const handlePasteWeekStaffPlanDetailAction = (val) => {
  return (dispatch) => {
    dispatch(handlePasteWeekStaffPlanDetail({ planStaffTaskWeek: val }));
  };
};

export const deletePlanStaffTaskAction = (val) => {
  return (dispatch) => {
    dispatch(deletePlanStaffTask({ planStaffTask: val }));
  };
};

export const updatePlanStaffTaskRangeDateAction = (val) => {
  return (dispatch) => {
    dispatch(updatePlanStaffTaskRangeDate({ planStaffTask: val }));
  };
};

export const addPlanStaffTaskAction = (val) => {
  return (dispatch) => {
    dispatch(addPlanStaffTask({ planStaffTask: val }));
  };
};
export const updatePlanDetailLockedAction = (val) => {
  return (dispatch) => {
    dispatch(updatePlanDetailLocked({ planDetailLocked: val }));
  };
};
export const updateListLockedAction = (val) => {
  return (dispatch) => {
    dispatch(updateListLocked({ listLocked: val }));
  };
};
export const updateGeneratorDashboardAction = (val) => {
  return (dispatch) => {
    dispatch(updateGeneratorDashboard({ generatorDashboard: val }));
  };
};
export const initGeneratorTableAction = (val) => {
  return (dispatch) => {
    dispatch(initGeneratorTable({ generatorTable: val }));
  };
};
export const updateGeneratorTableAction = (val) => {
  return (dispatch) => {
    dispatch(updateGeneratorTable({ updateGeneratorTableData: val }));
  };
};
export const updatePlanSelectedAction = (val) => {
  return (dispatch) => {
    dispatch(updatePlanSelected({ updatePlanSelectedData: val }));
  };
};
