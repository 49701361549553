import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import moment from 'moment';
import React, { useState } from 'react';

import DatePickerCustom from 'components/DatePicker/DatePickerCustom';
import { LIMIT_YEAR_IN_SURVEY } from 'constant';

const RangeDateOnly = ({
  questionId,
  surveyRecord,
  setSurveyRecord,
  className,
  isDisabled,
  limitSurveyStart,
  limitSurveyEnd,
}) => {
  const index = 0;
  const [isError, setIsError] = useState(false);
  const survey = { ...surveyRecord };
  if (!survey[questionId] || !survey[questionId][index]) {
    survey[questionId] = [{ fromDate: '', toDate: '', type: 'range-date-only' }];
  } else if (typeof survey[questionId] === 'string') {
    survey[questionId] = JSON.parse(survey[questionId]);
  }

  if (
    survey[questionId] &&
    (moment(survey[questionId][index].fromDate).year() < 2000 ||
      moment(survey[questionId][index].toDate).year() < 2000)
  ) {
    survey[questionId] = [{ fromDate: '', toDate: '', type: 'range-date-only' }];
  }

  const deleteDate = (questionId) => {
    const survey = { ...surveyRecord };

    survey[questionId] = [{ fromDate: '', toDate: '', type: 'range-date-only' }];
    setSurveyRecord(survey);
  };

  return (
    <div
      key={`${questionId}-date-survey-${index}`}
      className="calendar-plan-survey-form__date__wrap"
    >
      <div className="calendar-plan-survey-form__date__group">
        {isError && (
          <div className="error">
            <InfoCircleOutlined />{' '}
          </div>
        )}

        <div className="calendar-plan-survey-form__date__item">
          <label htmlFor="">{t('from')}</label>
          <DatePickerCustom
            className={className}
            isError={isError}
            disabled={isDisabled}
            date={
              survey[questionId][index].fromDate !== null
                ? moment(survey[questionId][index]?.fromDate).format('YYYY-MM-DD')
                : ''
            }
            setDate={(value) => {
              const newFromDate = new Date(value);
              if (
                survey[questionId][index].toDate &&
                newFromDate > survey[questionId][index].toDate
              ) {
                setIsError(true);
                return;
              }
              setIsError(false);

              survey[questionId][index] = {
                ...survey[questionId][index],
                fromDate: moment(new Date(value)).format('YYYY-MM-DD'),
                type: 'range-date-only',
              };
              setSurveyRecord(survey);
            }}
            isNotAutofocus={true}
            placeholder="dd/mm/yy"
            format="dd/MM/yy"
            minDate={new Date(limitSurveyStart)}
            maxDate={new Date(limitSurveyEnd)}
            limitYear={LIMIT_YEAR_IN_SURVEY}
          />
        </div>
        <div className="calendar-plan-survey-form__date__item">
          <label htmlFor="">{t('to')}</label>
          <DatePickerCustom
            className={className}
            isError={isError}
            disabled={isDisabled}
            date={
              survey[questionId][index].toDate !== null
                ? moment(survey[questionId][index]?.toDate).format('YYYY-MM-DD')
                : ''
            }
            setDate={(value) => {
              const newToDate = new Date(value);
              if (
                survey[questionId][index].fromDate &&
                newToDate < survey[questionId][index].fromDate
              ) {
                setIsError(true);

                return;
              }
              setIsError(false);

              survey[questionId][index] = {
                ...survey[questionId][index],
                toDate: moment(new Date(value)).format('YYYY-MM-DD'),
                type: 'range-date-only',
              };

              setSurveyRecord(survey);
            }}
            isNotAutofocus={true}
            placeholder="dd/mm/yy"
            format="dd/MM/yy"
            minDate={new Date(limitSurveyStart)}
            maxDate={new Date(limitSurveyEnd)}
            limitYear={LIMIT_YEAR_IN_SURVEY}
          />
        </div>
        <div
          className="calendar-plan-survey-form__date-close survey-reset-date"
          onClick={() => {
            if (isDisabled) return;
            deleteDate(questionId);
          }}
        >
          <CloseOutlined />
        </div>
      </div>
    </div>
  );
};

export default RangeDateOnly;
