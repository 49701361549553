import React, { useEffect, useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { t } from 'i18next';

import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import Loading from 'components/Loading/Loading';

import { useEmployees } from 'Hooks/useData';

const CustomHeader = ({ column, setSelectedRows, rowData, selectedRows }) => {
  const handleCheck = (e) => {
    let newSelectedRows = { ...selectedRows };
    if (e.target.checked) {
      const updateRow = (row, field, value) => {
        if (row[field]) {
          newSelectedRows[row.uuid] = { ...newSelectedRows[row.uuid], [field]: value };
        }
      };

      rowData.forEach((row) => {
        if (column.colId === 'name' && (row.email || row.phone_number)) {
          newSelectedRows[row.uuid] = {
            email: !!row.email,
            phone_number: !!row.phone_number,
          };
        } else if (column.colId !== 'name') {
          updateRow(row, column.colId, true);
        }
      });
    } else {
      Object.keys(newSelectedRows).forEach((key) => {
        const updateRow = (field, value) => {
          newSelectedRows[key] = { ...newSelectedRows[key], [field]: value };
        };

        const checkAllUnchecked = () => {
          const allUnchecked = Object.values(newSelectedRows).every(
            (row) => !row.email && !row.phone_number,
          );
          if (allUnchecked) {
            newSelectedRows = {};
          }
        };

        if (column.colId === 'name') {
          delete newSelectedRows[key];
        } else {
          updateRow(column.colId, false);
          checkAllUnchecked();
        }
      });
    }
    setSelectedRows(newSelectedRows);
  };

  return (
    <div className="header-checkbox">
      <input type="checkbox" onChange={handleCheck} />
      {column.colDef.headerName}
    </div>
  );
};

const EmpTable = ({ unitSelected, selectedRows, setSelectedRows }) => {
  const { data: rowData, isLoading } = useEmployees({ unitSelected });

  const defaultColDef = useMemo(
    () => ({
      filter: false,
      menuTabs: [],
      sortable: true,
      resizable: true,
    }),
    [],
  );

  const handleRowSelectAll = (rowId) => {
    const currentRow = rowData.find((item) => item.uuid === rowId);

    setSelectedRows((prev) => {
      if (prev[rowId]) {
        const { [rowId]: value, ...remaining } = prev;
        return remaining;
      } else {
        return {
          ...prev,
          [rowId]: {
            email: !!currentRow?.email,
            phone_number: !!currentRow?.phone_number,
          },
        };
      }
    });
  };

  const handleSelectRow = (rowId, key, isSelected) => {
    setSelectedRows((prevState) => {
      const updatedRow = {
        ...prevState[rowId],
        [key]: isSelected,
      };

      if (!updatedRow.email && !updatedRow.phone_number) {
        const { [rowId]: value, ...remainingRows } = prevState;
        return remainingRows;
      }

      return {
        ...prevState,
        [rowId]: updatedRow,
      };
    });
  };

  const colDefs = useMemo(
    () => [
      {
        field: 'name',
        width: 200,
        headerName: t('name'),
        headerComponent: CustomHeader,
        headerComponentParams: {
          setSelectedRows,
          rowData,
          selectedRows,
        },
        cellRenderer: ({ value, data }) => {
          return (
            <div className="cell-checkbox">
              {!!data && (data.email || data.phone_number) && (
                <input
                  type="checkbox"
                  checked={selectedRows[data.uuid] || false}
                  onChange={() => handleRowSelectAll(data.uuid)}
                />
              )}
              {value || '-'}
            </div>
          );
        },
        valueFormatter: ({ value }) => value || '-',
      },
      {
        field: 'email',
        flex: 1,
        headerName: t('email'),
        headerComponent: CustomHeader,
        headerComponentParams: {
          setSelectedRows,
          rowData,
          selectedRows,
        },
        cellRenderer: ({ value, data }) => (
          <div className="cell-checkbox">
            {!!value && (
              <input
                type="checkbox"
                onChange={(e) => handleSelectRow(data.uuid, 'email', e.target.checked)}
                checked={selectedRows[data.uuid]?.email || false}
              />
            )}
            {value || '-'}
          </div>
        ),
        valueFormatter: ({ value }) => value || '-',
      },
      {
        field: 'phone_number',
        flex: 1,
        headerName: t('phone-number'),
        headerComponent: CustomHeader,
        headerComponentParams: {
          setSelectedRows,
          rowData,
          selectedRows,
        },
        resizable: false,
        cellRenderer: ({ value, data }) => {
          return (
            <div className="cell-checkbox">
              {!!value && (
                <input
                  type="checkbox"
                  onChange={(e) => handleSelectRow(data.uuid, 'phone_number', e.target.checked)}
                  checked={selectedRows[data.uuid]?.phone_number || false}
                />
              )}
              {value || '-'}
            </div>
          );
        },
        valueFormatter: ({ value }) => value || '-',
      },
    ],
    [selectedRows, rowData, selectedRows],
  );

  if (isLoading) return <Loading />;

  return (
    <div className="ag-theme-alpine survey-form__emp-table-container">
      <AgGridReact
        rowData={rowData}
        defaultColDef={defaultColDef}
        columnDefs={colDefs}
        animateRows={true}
        rowSelection="multiple"
        suppressRowClickSelection={true}
      />
    </div>
  );
};

export default EmpTable;
