import { t } from 'i18next';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';

import CalendarPlanRoleRow from './CalendarPlanRoleRow';
import CalendarPlanRoleRowNew from './CalendarPlanRoleRowNew';

import { useRoles } from 'Hooks/useData';
import { closeModal } from '_components/Modal/slice';

import './CalendarPlanRole.scss';
import { PlusBrownIcon } from 'assets/img';
import InfoIcon from 'components/Common/InfoIcon/InfoIcon';

const RenderRoleRow = ({ roleList = [], refetchRole, refetchEmployees }) => {
  return roleList.map((role) => {
    return (
      <CalendarPlanRoleRow
        refetchRole={refetchRole}
        key={role.id}
        defaultRole={role}
        refetchEmployees={refetchEmployees}
        roleList={roleList}
      />
    );
  });
};

export default function CalendarPlanRole({ refetchEmployees }) {
  const dispatch = useDispatch();
  const { data: roleList, refetch: refetchRole } = useRoles();
  const [isCreate, setIsCreate] = useState(false);

  return (
    <div className="calendar-plan-create-role__wrap">
      <div className="calendar-plan-create-role__content">
        <div className="calendar-plan-create-role__header">
          <h4>{t('role-management')}</h4>
          <CloseOutlined onClick={() => dispatch(closeModal())} />
        </div>
        <div className="calendar-plan-create-role__list">
          <table className="calendar-plan-create-role__table">
            <thead>
              <th className="calendar-plan-create-role__item-label">{t('name')}</th>
              <th className="calendar-plan-create-role__item-label">{t('position-code')}</th>
              <th className="calendar-plan-create-role__item-label">
                <div className="calendar-plan-create-role__item-label__content">
                  {t('position-tax')}
                  <InfoIcon content={t('position-tax-info')} />
                </div>
              </th>
              <th className="calendar-plan-create-role__item-label" style={{ minWidth: '300px' }}>
                {t('description')}
              </th>
              <th className="calendar-plan-create-role__item-label">{t('action')}</th>
            </thead>
            <tbody>
              <RenderRoleRow
                roleList={roleList}
                refetchRole={refetchRole}
                refetchEmployees={refetchEmployees}
              />
              {isCreate ? (
                <CalendarPlanRoleRowNew
                  setActive={setIsCreate}
                  refetchRole={refetchRole}
                  roleList={roleList}
                />
              ) : null}
            </tbody>
          </table>
        </div>
        <div className="calendar-plan-create-role__action">
          <button
            className="submit-btn"
            onClick={() => {
              setIsCreate(true);
            }}
          >
            <img src={PlusBrownIcon} alt="" width={14} />
            {t('add-position')}
          </button>
        </div>
      </div>
    </div>
  );
}
