import { Toast } from 'helper/alert';
import commonChecking from './commonChecking';
import hardRuleChecking from './hardRuleChecking';
import { t } from 'i18next';
import surveyRuleChecking from './surveyRuleChecking';
import {
  DAY_SHIFT_VALUE,
  EVENING_SHIFT_VALUE,
  LONG_SHIFT_VALUE,
  NIGHT_SHIFT_VALUE,
  PER_POSITION_CATEGORY,
} from 'constant';
import weekendChecking from './weekendChecking';
import { calculateHrPerWeek } from 'helper/calculateTableData';
import { updateSummary } from './updateShift';
import { calculateHardAndSoftRuleData, calculateSurveyData } from './calculateData';
import { createAssignShift, updateTableData } from './generatorLogic';
import { getAllDateInWeek } from 'helper/dateTime';

export const updateEmployee = ({
  employeeMatched,
  employeeDataUpdate,
  taskData,
  vakantID,
  cleanedVakantShiftID,
  planSelected,
  assignList,
  date,
  layerName,
  vakantUpdate,
  taskType,
  summarySelectedView,
  summaryApi,
  shiftClean,
  listTableApi,
  weekRange,
  taskList,
  taskArr,
  surveyData,
  fromDate,
  endDate,
  publicHoliday,
  amlInformation,
  setCleanData,
}) => {
  if (employeeMatched) {
    const empIndex = employeeDataUpdate.findIndex(
      (item) =>
        +item.employeeId === +employeeMatched.employeeId ||
        item.employeeId === employeeMatched.employeeId,
    );
    const employeeUpdate = employeeDataUpdate[empIndex];
    const employeeTaskArr = { ...employeeUpdate.task_arr };

    //Try to checking if the cleaned shift will be remove off shift will be checking and add another F1 shift
    const oldTask = employeeTaskArr[date];
    if (oldTask && oldTask.length) {
      const oldTaskData = oldTask[0];
      if (commonChecking.isOffShift(oldTaskData)) {
        const allDateInWeek = getAllDateInWeek(date);
        const isHaveOffShiftInWeek = allDateInWeek.find((dateChecking) => {
          if (dateChecking === date) {
            return false;
          }
          if (employeeTaskArr[dateChecking] && employeeTaskArr[dateChecking].length) {
            return commonChecking.isOffShift(employeeTaskArr[dateChecking][0]);
          }
          return false;
        });
        if (!isHaveOffShiftInWeek) {
          const listDateOff = allDateInWeek.filter((dateOff) => !employeeTaskArr[dateOff]);

          for (const dateOff of listDateOff) {
            const isValidF1 = hardRuleChecking.checkValidF1({
              dateOff,
              employeeTaskList: employeeTaskArr,
              listDateOff: employeeUpdate.dateOff,
              listPublicHoliday: publicHoliday,
              timeOffWeek: amlInformation.minimumHourOffWeek,
            });
            if (isValidF1) {
              const offAssignShift = createAssignShift({
                employeeID: employeeMatched.employeeId,
                taskData: { ...oldTaskData, date: dateOff },
                planSelected,
                cleanVakantID: taskData.vakantShiftID,
                vakantID,
                date: dateOff,
                isOffShift: true,
              });
              assignList.push(offAssignShift);

              employeeTaskArr[dateOff] = [{ ...oldTaskData, date: dateOff }];
              break;
            }
          }
        }
      }
    }
    //Remove olf shift if it  is off shift
    delete employeeTaskArr[date];
    const newTask = {
      ...taskData,
      isAssignByCleanVakant: true,
      cleanVakantID: taskData.vakantShiftID,
      vakantID,
      isClean: true,
      employee_id: employeeMatched.employeeId,
    };
    cleanedVakantShiftID.push(taskData.vakantShiftID);
    const assignShift = createAssignShift({
      employeeID: employeeMatched.employeeId,
      taskData,
      planSelected,
      cleanVakantID: taskData.vakantShiftID,
      vakantID,
      isOffShift: false,
    });
    assignList.push(assignShift);
    delete newTask.vakantShiftID;
    delete newTask.reasonKey;
    employeeTaskArr[date] = [newTask];
    employeeUpdate.task_arr = employeeTaskArr;
    employeeUpdate.allocatedHours += newTask.hourAssigned;
    employeeUpdate.allocatedHoursDeviation += newTask.hourAssigned;

    vakantUpdate.allocatedHours -= newTask.hourAssigned;

    employeeUpdate.deviation += newTask.hourAssigned;
    vakantUpdate.deviation -= newTask.hourAssigned;

    switch (taskType) {
      case DAY_SHIFT_VALUE:
        employeeUpdate.dayShift++;
        vakantUpdate.dayShift && vakantUpdate.dayShift--;
        updateSummary(
          summaryApi,
          summarySelectedView === PER_POSITION_CATEGORY && layerName
            ? `day${layerName}`
            : 'dayShift',
          summarySelectedView === PER_POSITION_CATEGORY && layerName
            ? `day${layerName}-${date}`
            : `dayShift-${date}`,
          date,
          1,
        );
        break;
      case EVENING_SHIFT_VALUE:
        employeeUpdate.lateShift++;
        vakantUpdate.lateShift && vakantUpdate.lateShift--;
        updateSummary(
          summaryApi,
          summarySelectedView === PER_POSITION_CATEGORY && layerName
            ? `late${layerName}`
            : 'lateShift',
          summarySelectedView === PER_POSITION_CATEGORY && layerName
            ? `late${layerName}-${date}`
            : `lateShift-${date}`,

          date,
          1,
        );
        break;
      case NIGHT_SHIFT_VALUE:
        employeeUpdate.nightShift++;
        vakantUpdate.nightShift && vakantUpdate.nightShift--;
        updateSummary(
          summaryApi,
          summarySelectedView === PER_POSITION_CATEGORY && layerName
            ? ` night${layerName}`
            : 'nightShift',
          summarySelectedView === PER_POSITION_CATEGORY && layerName
            ? `night${layerName}-${date}`
            : `nightShift-${date}`,
          date,
          1,
        );
        break;
      case LONG_SHIFT_VALUE:
        employeeUpdate.longShift++;
        vakantUpdate.longShift && vakantUpdate.longShift--;
        updateSummary(
          summaryApi,
          summarySelectedView === PER_POSITION_CATEGORY && layerName
            ? ` long-shift${layerName}`
            : 'long-shift',
          summarySelectedView === PER_POSITION_CATEGORY && layerName
            ? `long-shift${layerName}-${date}`
            : `long-shift-${date}`,
          date,
          1,
        );
        break;

      default:
        employeeUpdate.otherShift++;
        vakantUpdate.otherShift && vakantUpdate.otherShift--;

        break;
    }
    if (vakantUpdate.task_arr[date]) {
      vakantUpdate.task_arr[date][0].isDeleted = true;
    }
    updateTableData({
      listTableApi,
      employeeDataUpdate,
      weekRange,
      taskList,
      taskArr,
      surveyData,
      fromDate,
      endDate,
      publicHoliday,
      amlInformation,
    });
    setCleanData({ cleanedVakantShiftID, assignList });
    return { cleanedVakantShiftID, assignList };
  }
};
