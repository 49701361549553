import { SendSurveyComplete } from 'assets/img';
import { t } from 'i18next';
import React from 'react';

const Receipt = ({ handleClose }) => {
  return (
    <div className="receipt">
      <img src={SendSurveyComplete} alt="" />
      <p className="title">{t('mapping-sent')}</p>
      <p className="desc">{t('mapping-sent-desc')}</p>
      <button className="custom-btn" onClick={handleClose}>
        OK
      </button>
    </div>
  );
};

export default Receipt;
