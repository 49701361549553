import React, { memo, useEffect, useRef, useState } from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import './DatePicker.scss';
import 'react-datepicker/dist/react-datepicker.css';
import nb from 'date-fns/locale/nb';
import { useSelector } from 'react-redux';
import { selectGlobal } from 'redux/global/globalSlice';
import { dateFormat, dateFormatReg } from 'constant';
import { CalenderIcon } from 'assets/img';
import moment from 'moment';
import { t } from 'i18next';
function isValidDate(dateString) {
  // Create a Date object by parsing the string
  const dateObj = new Date(dateString);

  // Check if the Date object is valid (not NaN)
  return !isNaN(dateObj.getTime());
}
const DatePickerCustom = ({
  date,
  setDate,
  disabled,
  isNotAutofocus = true,
  isError = false,
  className = '',
  placeholder = '',
  onBlur = () => {},
  minDate,
  maxDate,
  isMaxWidth,
  isRequired,
  format,
  limitYear,
}) => {
  const [dateChange, setDateChange] = useState();
  const { language } = useSelector(selectGlobal);
  const datePickerRef = useRef();
  if (language === 'NO') {
    registerLocale('nb', nb);
  }
  useEffect(() => {
    if (date instanceof Date) {
      setDateChange(date);
    } else if (dateFormatReg.test(date)) {
      setDateChange(new Date(date));
    } else {
      setDateChange('');
    }
  }, [date]);
  const getMaxDate = () => {
    if (maxDate && isValidDate(maxDate)) {
      return maxDate;
    }
    if (limitYear > 0) {
      return new Date(moment().add(limitYear, 'years').format('YYYY-MM-DD'));
    }
    return undefined;
  };
  const getMinDate = () => {
    if (minDate && isValidDate(minDate)) {
      return minDate;
    }
    if (limitYear > 0) {
      return new Date(moment().startOf('year').format('YYYY-MM-DD'));
    }
    return undefined;
  };
  return (
    <div
      className={`date-picker-custom ${isError ? 'error' : ''} ${className}`}
      style={isMaxWidth ? { width: '100%' } : {}}
      onClick={() => {
        datePickerRef.current.onInputClick();
      }}
    >
      <DatePicker
        onBlur={onBlur}
        ref={datePickerRef}
        disabled={disabled}
        selected={dateChange}
        showMonthDropdown={true}
        showYearDropdown={true}
        dropdownMode="select"
        placeholderText={t(placeholder)}
        onChange={(datePick) => {
          setDate(moment(datePick).format('YYYY-MM-DD'));
          setDateChange(datePick);
        }}
        onChangeRaw={(e) => {
          const inputDate = e.target.value;
          if (inputDate) {
            const shortYearRegExp = /^(\d{2})(\d{2})(\d{2})$/;
            if (shortYearRegExp.test(inputDate)) {
              const formattedDate = inputDate.replace(shortYearRegExp, '$1.$2.$3');
              if (inputDate.length >= 6 && !isNaN(inputDate)) {
                e.target.value = formattedDate;
              }
            } else {
              const formattedDate = inputDate.replace(/^(\d{2})(\d{2})(\d{4})$/, '$1.$2.$3');
              if (inputDate.length >= 8 && !isNaN(inputDate)) {
                e.target.value = formattedDate;
              }
            }
          }
        }}
        dateFormat={format || 'dd.MM.yyyy'}
        locale={language === 'NO' ? 'nb' : 'en'}
        calendarStartDay={1}
        autoFocus={!dateChange && !isNotAutofocus ? true : false}
        minDate={getMinDate()}
        maxDate={getMaxDate()}
        yearDropdownItemNumber={2}
        required={isRequired}
      />
      <img src={CalenderIcon} alt="" width={14} height={14} />
    </div>
  );
};
export default DatePickerCustom;
