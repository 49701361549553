import { t } from 'i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';
import axiosClient from 'axios/axiosClient';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Loading from 'components/Loading/Loading';
import AddTaskCode from './component/AddTaskCode/AddTask';
import PlanAction from './component/PlanAction/PlanAction';
import PlanDetail from './component/PlanDetail/PlanDetail';
import DuplicateWeeks from './component/DuplicateWeeks/DuplicateWeeks';

import {
  useHoliday,
  useLayerList,
  usePlanDetailData,
  usePredictPercent,
  useTaskList,
  useTaskType,
} from 'Hooks/useData';
import { Toast, confirmDelete, confirmUpdate, showLoading } from 'helper/alert';
import { selectModal } from '_components/Modal/slice';
import { CloseIcon, LeftArrowIcon } from 'assets/img';
import { saveSession, clearSession } from 'helper/localStorage';

import { selectAuth } from 'redux/auth/authSlice';
import {
  ADD_LAYER,
  ADD_TASK_CODE,
  DUPLICATE_WEEKS,
} from '_components/CarlendarPlan/EmployeeV2/constants/modal';

import './styles.scss';
import { useAbsencePredction } from 'Hooks/usePredictionData';
import AddLayer from './component/AddLayer/AddLayer';
import {
  PREDICT_DAY_SHIFT_VALUE,
  PREDICT_LATE_SHIFT_VALUE,
  PREDICT_NIGHT_SHIFT_VALUE,
} from 'constant';

const NewStaffPlan = ({
  planSelected,
  setPlanSelected,
  setIsMainContent,
  unitSelected,
  refetchPlanList,
}) => {
  const { uuid: userUuid, isEditabled } = useSelector(selectAuth);
  const gridRef = useRef(null);
  const modal = useSelector(selectModal);
  const { data: taskList, isFetched: isTaskListFetched } = useTaskList({ unitSelected });

  const [predictShiftList, setPredictShiftList] = useState([]);

  useEffect(() => {
    if (taskList && taskList.length) {
      setPredictShiftList(taskList.filter((item) => +item.taskTypeValue < 0));
    }
  }, [taskList]);

  const [tableData, setTableData] = useState([
    {
      isSummary: true,
      isHeader: true,
      taskUUID: 'task-summary',
      taskName: t('summary'),
    },
  ]);
  const [predictPercentData, setPredictPercentData] = useState({});
  const [showAbsencePrediction, setShowAbsencePrediction] = useState(false);
  const [layerCreated, setLayerCreated] = useState([]);
  const [showSummary, setShowSummary] = useState(false);
  const [deletedList, setDeletedList] = useState([]);
  const [planInfo, setPlanInfo] = useState({
    planName: '',
    fromDate: '',
    endDate: '',
    numWeek: 1,
  });
  // const {
  //   data: planDetailData,
  //   isFetching: isPlanDetailDataFetching,
  //   refetch: refetchPlanDetail,
  // } = usePlanDetail({
  //   plan: { uuid: planInfo.uuid },
  // });
  const {
    data: planDetailData,
    isFetching: isPlanDetailDataFetching,
    refetch: refetchPlanDetail,
  } = usePlanDetailData({
    plan: planInfo,
  });

  const { data: publicHoliday } = useHoliday();

  const { data: layerList, refetch: refetchLayerList } = useLayerList({
    unitSelected,
    planID: planInfo.id,
  });
  const { data: predictPercent, refetch: refetchPredictPercent } = usePredictPercent({
    plan: planInfo,
  });
  const isUpdating = useMemo(() => !!planInfo.uuid, [planInfo]);
  const {
    data: { data: taskTypeList },
  } = useTaskType();
  const { data: dayPredictData } = useAbsencePredction({
    startDay: planInfo.fromDate,
    endDay: planInfo.endDate,
    unitSelected: [unitSelected],
    typeShift: ['day'],
  });
  const { data: latePredictData } = useAbsencePredction({
    startDay: planInfo.fromDate,
    endDay: planInfo.endDate,
    unitSelected: [unitSelected],
    typeShift: ['late'],
  });
  const { data: nightPredictData } = useAbsencePredction({
    startDay: planInfo.fromDate,
    endDay: planInfo.endDate,
    unitSelected: [unitSelected],
    typeShift: ['night'],
  });
  const calculatePredictData = ({ predictData, percent, task }) => {
    if (!predictData) {
      return [];
    }

    const data = predictData.map((item) => {
      const predictValue = percent ? (item.PredictAbsence * percent) / 100 : 0;
      return {
        date: item.Date,
        endTime: task.endTime,
        fromTime: task.endTime,
        numberPerson: Math.ceil(predictValue),
        planDetailUUID: null,
        taskUUID: task.uuid,
        isEditted: true,
        ...task,
      };
    });

    return data;
  };

  const [predictCalculated, setPredictCalculated] = useState([]);
  useEffect(() => {
    let placholderPredictData = [];

    const listDefaultPredictData = tableData.filter((item) => item.taskTypeValue < 0);
    if (listDefaultPredictData.length) {
      placholderPredictData = listDefaultPredictData.map((item) => ({
        ...item,
        isPrediction: true,
        isHeader: false,
        isCell: true,
        layerName: t('task-prediction'),
        layerID: t('task-prediction'),
      }));

      if (listDefaultPredictData.length < predictShiftList.length) {
        const listShiftPredicted = listDefaultPredictData.map((item) => item.taskID);
        const listNotPredictShift = predictShiftList.filter(
          (item) => !listShiftPredicted.includes(item.taskID),
        );
        const additionalPredictData = listNotPredictShift.map((item) => {
          let predictData;
          switch (+item.taskTypeValue) {
            case PREDICT_DAY_SHIFT_VALUE:
              predictData = dayPredictData.predictData;
              break;
            case PREDICT_LATE_SHIFT_VALUE:
              predictData = latePredictData.predictData;
              break;
            case PREDICT_NIGHT_SHIFT_VALUE:
              predictData = nightPredictData.predictData;
              break;

            default:
              predictData = [];

              break;
          }
          return {
            isPrediction: true,
            isHeader: false,
            isCell: true,
            taskUUID: item.taskUUID,
            taskID: item.taskID,
            taskList:
              item.taskList ||
              calculatePredictData({
                predictData: predictData,
                percent:
                  predictPercentData[item.taskID] && showAbsencePrediction
                    ? predictPercentData[item.taskID]
                    : 0,
                task: item,
              }),
            taskName: item.taskName,
            fromTime: item.fromTime,
            endTime: item.endTime,
            taskTypeValue: item.taskTypeValue,
            layerName: t('task-prediction'),
            layerID: t('task-prediction'),
          };
        });

        placholderPredictData = [...placholderPredictData, ...additionalPredictData];
      }
    } else {
      placholderPredictData = predictShiftList.map((item) => {
        let predictData;
        switch (+item.taskTypeValue) {
          case PREDICT_DAY_SHIFT_VALUE:
            predictData = dayPredictData.predictData;
            break;
          case PREDICT_LATE_SHIFT_VALUE:
            predictData = latePredictData.predictData;
            break;
          case PREDICT_NIGHT_SHIFT_VALUE:
            predictData = nightPredictData.predictData;
            break;

          default:
            predictData = [];

            break;
        }

        return {
          isPrediction: true,
          isHeader: false,
          isCell: true,
          taskUUID: item.taskUUID,
          taskID: item.taskID,
          taskList:
            item.taskList ||
            calculatePredictData({
              predictData: predictData,
              percent:
                predictPercentData[item.taskID] && showAbsencePrediction
                  ? predictPercentData[item.taskID]
                  : 0,
              task: item,
            }),
          taskName: item.taskName,
          fromTime: item.fromTime,
          endTime: item.endTime,
          taskTypeValue: item.taskTypeValue,
          layerName: t('task-prediction'),
          layerID: t('task-prediction'),
        };
      });
    }

    placholderPredictData = placholderPredictData.sort((a, b) => {
      return b.taskTypeValue - a.taskTypeValue;
    });

    setPredictCalculated(showAbsencePrediction ? placholderPredictData : []);
  }, [
    predictPercentData,
    predictPercent,
    dayPredictData,
    latePredictData,
    nightPredictData,
    predictShiftList,
    tableData,
    planInfo,
    showAbsencePrediction,
  ]);

  useEffect(() => {
    if (predictPercent) {
      setPredictPercentData(predictPercent);
      if (Object.keys(predictPercent).length) {
        setShowAbsencePrediction(true);
      } else {
        setShowAbsencePrediction(false);
      }
    }
  }, [predictPercent]);
  useEffect(() => {
    if (tableData.length === 0) {
      setShowSummary(false);
    }
  }, [tableData]);
  useEffect(() => {
    if (planSelected && Object.keys(planSelected).length) {
      const { uuid, from_date: fromDate, to_date: endDate, plan_name: planName, id } = planSelected;
      const numWeek = moment(endDate).diff(moment(fromDate), 'week') + 1;
      setPlanInfo({
        ...planInfo,
        id,
        planName,
        fromDate: moment(fromDate).startOf('W').toDate(),
        endDate: moment(endDate).endOf('W').toDate(),
        uuid,
        numWeek,
      });
    }
  }, [planSelected]);

  useEffect(() => {
    if (planDetailData?.length && planInfo?.uuid) {
      setTableData(
        planDetailData.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        }),
      );
    } else {
      setTableData([]);
    }
    return () => {
      setTableData([]);
    };
  }, [planDetailData, planInfo.uuid]);
  const resetAction = () => {
    setPlanSelected({});
    setLayerCreated([]);
    setIsMainContent(true);
    clearSession('currentNavTab');
    saveSession('isMainContent', true);
  };
  const handleSaveData = useCallback(async () => {
    await gridRef.current?.api.stopEditing();
    const { planName, fromDate, endDate } = planInfo;
    if (!planName || !fromDate || !endDate) {
      Toast({ icon: 'error', title: `${t('please-input-all')}` });
      return;
    }

    planInfo.fromDate = moment(fromDate).format('YYYY-MM-DD');
    planInfo.endDate = moment(endDate).format('YYYY-MM-DD');

    const updatedData = [];
    const updateList = [];

    const layerSet = new Set();
    tableData
      .filter((item) => !item.isSummary)
      .forEach((item) => {
        const planDetailData = {
          groupList: item.groupList,
          roleList: item.roleList,
          planDetailID: item.planDetailID,
          layerName: item.layerName,
          taskID: item.taskID,
          fromTime: item.fromTime,
          endTime: item.endTime,
        };
        const dateList = [];
        const taskList = item.taskList;
        if (taskList.length) {
          taskList.forEach((task) => {
            if (task.isEditted) {
              updatedData.push(task);
              dateList.push(task);
              layerSet.add(task.layerID);
            }
          });
        } else {
          const initShift = {
            ...item,
            isEditted: true,
            date: planInfo.fromDate,
            numberPerson: 0,
          };
          updatedData.push(initShift);
          dateList.push(initShift);
        }
        updateList.push({ ...planDetailData, dateList });
      });
    predictCalculated
      .filter((item) => !item.isSummary)
      .forEach((item) => {
        const planDetailData = {
          groupList: [],
          roleList: [],
          planDetailID: item.planDetailID,
          taskID: item.taskID,
          fromTime: item.fromTime,
          endTime: item.endTime,
        };
        const dateList = [];
        const taskList = item.taskList;
        if (taskList.length) {
          taskList.forEach((task) => {
            if (task.isEditted) {
              updatedData.push(task);
              dateList.push(task);
              layerSet.add(task.layerID);
            }
          });
        } else {
          const initShift = {
            ...item,
            isEditted: true,
            date: planInfo.fromDate,
            numberPerson: 0,
          };
          updatedData.push(initShift);
          dateList.push(initShift);
        }
        updateList.push({ ...planDetailData, dateList });
      });

    const predictShiftInfo = [];
    if (showAbsencePrediction) {
      predictCalculated
        .filter((item) => !item.isSummary)
        .forEach((item) => {
          predictShiftInfo.push({
            taskID: item.taskID,
            fromTime: item.fromTime,
            endTime: item.endTime,
          });
          const taskList = item.taskList;
          taskList.forEach((task) => {
            if (task.isEditted) {
              updatedData.push({ ...task, fromTime: item.fromTime, endTime: item.endTime });
            }
          });
        });
    }

    const makeRequest = async () => {
      showLoading({ shouldShow: true, title: t(isUpdating ? 'updating-plan' : 'creating-plan') });
      try {
        const res = await axiosClient.post('calendar-plan/staff-plan/update', {
          updateList,
          planInfo,
          unitCode: unitSelected,
          deletedList,
          userUuid,
          predictPercentData,
          showAbsencePrediction,
          layerList: Array.from(layerSet),
          layerCreated,
          predictShiftInfo,
        });
        if (res?.status === 200) {
          // resetAction();
          setLayerCreated([]);
          setDeletedList([]);
          setPlanInfo(res.planInfo);
          if (!showAbsencePrediction) {
            setPredictPercentData({});
          }
          await refetchLayerList();
          await refetchPredictPercent();
          await refetchPlanList();
          await refetchPlanDetail();
          showLoading({ shouldShow: false });
          Toast({
            icon: 'success',
            title: `${t(planInfo.uuid ? 'update' : 'create')} ${t('success')}`,
          });
        } else {
          throw new Error();
        }
      } catch (err) {
        console.log(err);
        Toast({
          icon: 'error',
          title: `${t(isUpdating ? 'update' : 'create')} ${t('unsuccess')}`,
        });
      }
    };

    if (isUpdating) {
      confirmUpdate({
        title: 'update-plan-warning',
        text: 'update-staff-plan-effect',
        btnText: 'save-new-plan',
      }).then((result) => result.isConfirmed && makeRequest());
    } else {
      makeRequest();
    }
  }, [
    planInfo,
    tableData,
    deletedList,
    predictPercentData,
    predictCalculated,
    showAbsencePrediction,
  ]);

  return (
    <div className="new-staff-plan">
      <div className={`return-nav ${isEditabled ? '' : 'view-only'}`}>
        <div onClick={resetAction}>
          <img src={LeftArrowIcon} /> <p>{t('staff')}</p> <p>/</p> <p>{t('new-staff')}</p>
        </div>
        <div onClick={resetAction}>
          <img src={CloseIcon} /> <p>{t('close')}</p>
        </div>
      </div>
      <div className="new-staff-plan-wrapper">
        <PlanAction
          planInfo={planInfo}
          setPlanInfo={setPlanInfo}
          handleSaveData={handleSaveData}
          isUpdating={isUpdating}
          tableData={tableData}
          setTableData={setTableData}
        />
        {isPlanDetailDataFetching ? (
          <Loading />
        ) : (
          //TODO migreate to context later
          <PlanDetail
            planInfo={planInfo}
            tableData={tableData}
            setTableData={setTableData}
            gridRef={gridRef}
            taskTypeList={taskTypeList}
            showSummary={showSummary}
            setShowSummary={setShowSummary}
            unitSelected={unitSelected}
            setDeletedList={setDeletedList}
            predictPercentData={predictPercentData}
            setPredictPercentData={setPredictPercentData}
            calculatePredictData={calculatePredictData}
            dayPredictData={dayPredictData}
            latePredictData={latePredictData}
            nightPredictData={nightPredictData}
            predictCalculated={predictCalculated}
            setPredictCalculated={setPredictCalculated}
            setPredictShiftList={setPredictShiftList}
            showAbsencePrediction={showAbsencePrediction}
            setShowAbsencePrediction={setShowAbsencePrediction}
            layerList={layerList}
            refetchLayerList={refetchLayerList}
            layerCreated={layerCreated}
            setLayerCreated={setLayerCreated}
            publicHoliday={publicHoliday}
            predictPercent={predictPercent}
          />
        )}
      </div>
      {modal.name === DUPLICATE_WEEKS && (
        <DuplicateWeeks
          planInfo={planInfo}
          setPlanInfo={setPlanInfo}
          tableData={tableData}
          setTableData={setTableData}
          predictPercentData={predictPercentData}
        />
      )}
      {modal.name === ADD_TASK_CODE && (
        <AddTaskCode
          planInfo={planInfo}
          tableData={tableData}
          setTableData={setTableData}
          isUpdating={isUpdating}
          setDeletedList={setDeletedList}
          layerList={layerList}
          layerCreated={layerCreated}
          unitSelected={unitSelected}
        />
      )}
      {modal.name === ADD_LAYER && (
        <AddLayer
          refetchLayerList={refetchLayerList}
          unitSelected={unitSelected}
          planID={planSelected.id}
          layerList={layerList}
          layerCreated={layerCreated}
          setLayerCreated={setLayerCreated}
          gridRef={gridRef}
        />
      )}
    </div>
  );
};

export default NewStaffPlan;
