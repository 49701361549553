import { t } from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import ChildQuestion from 'components/CalendarPlan/SurveyFormEmployee/ChildQuestion';
import AnswerItem from 'components/CalendarPlan/CalendarPlanSurveyForm/ItemInSurvey/AnswerItem';

import { Toast } from 'helper/alert';
import { getQuestionAnswerType } from './helper';
import { selectGlobal } from 'redux/global/globalSlice';
import { selectModal } from '_components/Modal/slice';
import { LockRedIcon, ToggleOffIcon, ToggleOnIcon, UnlockRedIcon } from 'assets/img';
import { CLINICSL_ENV, HOSPITAL_ENV } from 'constant';
import { useHolidayWithName } from 'Hooks/useData';

const RenderSubQuestion = ({
  handleOnChange,
  questionData,
  language,
  surveyRecord,
  setSurveyRecord,
  renderNewRangeDate,
  renderRangeDate,
  warningMessage,
  jobPercentage,
  isReadOnly
}) => {
  const [listSubQuestion, setListSubQuestion] = useState([]);

  useEffect(() => {
    const parentRecord = surveyRecord[questionData?.parentId];

    if (
      questionData?.child &&
      Object.keys(questionData?.child)[0]?.child_question_id !== null &&
      parentRecord
    ) {
      if (Array.isArray(parentRecord)) {
        const listSub = [];
        const listRecord = parentRecord.map((item) => item.en);

        Object.keys(questionData.child).forEach((subQuestion) => {
          const question = questionData.child[subQuestion];
          const parrentActiveAnswer = question.child_parent_answer_choice;
          if (listRecord.includes(parrentActiveAnswer)) {
            listSub.push(subQuestion);
          }
        });
        listSub.sort((a, b) => {
          const indexA = listRecord.indexOf(questionData.child[a].child_parent_answer_choice);
          const indexB = listRecord.indexOf(questionData.child[b].child_parent_answer_choice);
          return indexA - indexB;
        });
        setListSubQuestion(listSub);
      } else {
        const listSub = [];
        Object.keys(questionData.child).forEach((subQuestion) => {
          const question = questionData.child[subQuestion];
          const parrentActiveAnswer = question.child_parent_answer_choice;
          if (parentRecord.en === parrentActiveAnswer || parentRecord.no === parrentActiveAnswer) {
            listSub.push(subQuestion);
          }
        });
        setListSubQuestion(listSub);
      }
    }
  }, [questionData, surveyRecord]);

  return (
    <>
      {questionData?.child !== null && Object.keys(questionData?.child)[0]
        ? listSubQuestion.map((childQuestionKey) => {
            return (
              <ChildQuestion
                key={childQuestionKey}
                childQuestionKey={childQuestionKey}
                handleOnChange={handleOnChange}
                parentQuestionData={questionData}
                language={language}
                renderNewRangeDate={renderNewRangeDate}
                renderRangeDate={renderRangeDate}
                surveyRecord={surveyRecord}
                setSurveyRecord={setSurveyRecord}
                warningMessage={warningMessage}
                jobPercentage={jobPercentage}
                isReadOnly={isReadOnly}
              />
            );
          })
        : null}
    </>
  );
};

const QuestionContent = ({
  listTopic,
  parentQuestionData,
  isNo,
  listBlockedQuestion,
  setListBlockedQuestion,
  listHardRuleQuestion,
  setListHardRuleQuestion,
  surveyRecord,
  setSurveyRecord,
  handleOnChange,
  renderNewRangeDate,
  renderRangeDate,
  warningMessage,
  isDisabled,
  managerOption = [],
  holidayList,
  isReadOnly,
}) => {
  const site = process.env.REACT_APP_SITE;
  const { language } = useSelector(selectGlobal);
  const { data: empInfo } = useSelector(selectModal);
  const {
    kommuneTypeQuestionProperty,
    hospitalTypeQuestionProperty,
    customerType,
    showingQuestionOfHospitalToKommune,
  } = useSelector(selectGlobal);

  const [questionData, setQuestionData] = useState({});
  const { parent_question_content, parent_question_no_ver, parent_question_type } =
    parentQuestionData;
  const parentAnswerType = getQuestionAnswerType(parent_question_type);

  // block-toggle question -> this will remove the question when employee fill the survey
  const [isBlock, setIsBlock] = useState(false);
  const handleBlockQuestion = () => {
    if (isBlock) {
      const updateBlockList = listBlockedQuestion.filter((item) => item !== questionData.parentId);
      setListBlockedQuestion(updateBlockList);
    } else {
      setListBlockedQuestion([...listBlockedQuestion, questionData.parentId]);
    }
  };

  // lock question -> this will remove this question input when optimzing the survey
  const lockedTopic = ['Gyldighetsperioder', 'Validity periods.', 'FERIE', 'VACATION'];
  const [isLocked, setIsLocked] = useState(false);

  const handleLockQuestion = () => {
    let listHardRuleQuestionUpdate = listHardRuleQuestion;
    const foundQuestionIndex = listHardRuleQuestionUpdate.findIndex(
      (item) => questionData.parentId === item.question_uuid,
    );
    if (listHardRuleQuestionUpdate[foundQuestionIndex]?.always) {
      Toast({ icon: 'error', title: t('question-blocked') });
      return;
    }
    if (isLocked && foundQuestionIndex > -1) {
      listHardRuleQuestionUpdate[foundQuestionIndex].isHard = 0;

      setIsLocked(false);
    } else if (foundQuestionIndex > -1) {
      listHardRuleQuestionUpdate[foundQuestionIndex].isHard = 1;
      setIsLocked(true);
    } else {
      listHardRuleQuestionUpdate.push({
        question_uuid: parentQuestionData.parent_question_id,
        isHard: 1,
      });
      setIsLocked(true);
    }
    setListHardRuleQuestion(listHardRuleQuestionUpdate);
  };

  useEffect(() => {
    let isDefaultLocked = lockedTopic.includes(
      listTopic.find((topic) => topic.uuid === parentQuestionData.parent_question_topic_id)?.topic,
    );

    const isLocked = listHardRuleQuestion?.findIndex(
      (item) =>
        item.question_uuid === parentQuestionData.parent_question_id &&
        (item.is_hard || item.always) &&
        item.employee_id === empInfo?.id,
    );

    setIsLocked(isDefaultLocked || isLocked > -1);
  }, [listHardRuleQuestion, listTopic, parentQuestionData.parent_question_id]);

  useEffect(() => {
    if (parentQuestionData) {
      const data = {
        questionProperty: parentQuestionData.parrent_question_property,
        questionContent: isNo
          ? parentQuestionData?.parent_question_no_ver
          : parentQuestionData?.parent_question_content,
        isMandatory: !!parentQuestionData?.parent_question_mandatory,
        description: isNo
          ? parentQuestionData.parent_question_description_no_ver
          : parentQuestionData.parent_question_description,
        answerList: isNo
          ? parentQuestionData.parent_answer_no_ver
          : parentQuestionData.parent_answer_choice,
        questionChoice: parentQuestionData.parent_answer_choice,
        questionType: parentAnswerType,
        child: parentQuestionData.child,
        parentId: parentQuestionData.parent_question_id,
        type: parentQuestionData.type,
        topicId: parentQuestionData.parent_question_topic_id,
      };
      setQuestionData(data);
    }
  }, [parentQuestionData, isNo]);

  useEffect(() => {
    setIsBlock(listBlockedQuestion?.includes(questionData.parentId));
  }, [questionData, listBlockedQuestion]);

  if (customerType === HOSPITAL_ENV) {
    if (kommuneTypeQuestionProperty.includes(questionData.questionProperty)) {
      return;
    }
  }

  if (customerType === CLINICSL_ENV) {
    const isHospitalQuestion = hospitalTypeQuestionProperty.includes(questionData.questionProperty);
    const isShowHospitalQuesion = showingQuestionOfHospitalToKommune.find(
      (item) => item.property === questionData.questionProperty && item.site.includes(site),
    );
    if (isHospitalQuestion && !isShowHospitalQuesion) {
      return;
    }
  }
  const isHaveManagerOption = () => {
    // Early return if questionData or answerList is missing
    if (!questionData?.answerList) return false;

    return questionData.answerList.some((answer) => {
      const answerKeys = Object.keys(answer);
      return managerOption.some((item) => item.uuid === answer[answerKeys[0]]);
    });
  };

  return (
    <div className="question-content">
      <div className="main-desc">
        <p>{isNo ? parent_question_no_ver : parent_question_content}</p>
        {questionData.questionProperty === 'working_holiday_with_type_shift' ? (
          <p>Vakttype du ønsker</p>
        ) : null}
      </div>
      {questionData.description && (
        <div className="sub-desc">
          <p>{questionData.description}</p>
        </div>
      )}
      <div className={`answer-list ${parentAnswerType === 'radio' ? 'inline' : ''}`}>
        {questionData?.answerList?.map((answer, index) => {
          const answerKeys = Object.keys(answer);
          const isManagerOption = managerOption
            .map((item) => item.uuid)
            .includes(answer[answerKeys[0]]);
          if (isManagerOption) return;
          return (
            <AnswerItem
              key={answer[answerKeys[0]]}
              parentAnswerType={parentAnswerType}
              answer={answer}
              answerKeys={answerKeys}
              surveyRecord={surveyRecord}
              questionData={questionData}
              handleOnChange={handleOnChange}
              index={index}
              setSurveyRecord={setSurveyRecord}
              isDisabled={isDisabled || isReadOnly}
              listTopic={listTopic}
              holidayList={holidayList}
            />
          );
        })}
      </div>
      {isHaveManagerOption() ? (
        <div
          className={`answer-list manager-option-list ${
            parentAnswerType === 'radio' ? 'inline' : ''
          }`}
        >
          <h5 className="manager-option">{t('only-selectable-for-managers')}:</h5>
          {questionData?.answerList?.map((answer, index) => {
            const answerKeys = Object.keys(answer);
            const isManagerOption = managerOption
              .map((item) => item.uuid)
              .includes(answer[answerKeys[0]]);
            if (!isManagerOption) return;
            return (
              <AnswerItem
                key={answer[answerKeys[0]]}
                parentAnswerType={parentAnswerType}
                answer={answer}
                answerKeys={answerKeys}
                surveyRecord={surveyRecord}
                questionData={questionData}
                handleOnChange={handleOnChange}
                index={index}
                setSurveyRecord={setSurveyRecord}
                isDisabled={isDisabled || isReadOnly}
                listTopic={listTopic}
                holidayList={holidayList}
              />
            );
          })}
        </div>
      ) : null}

      {questionData.child && (
        <RenderSubQuestion
          handleOnChange={handleOnChange}
          language={language}
          listSubQuestion={Object?.keys(questionData.child)}
          questionData={questionData}
          renderNewRangeDate={renderNewRangeDate}
          renderRangeDate={renderRangeDate}
          setSurveyRecord={setSurveyRecord}
          surveyRecord={surveyRecord}
          warningMessage={warningMessage}
          isReadOnly={isReadOnly}
        />
      )}
    </div>
  );
};

export default QuestionContent;
