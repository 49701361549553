/** libs */
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useMemo, useState, useCallback } from 'react';

/** helpers */
import { Toast, confirmDelete, showLoading } from 'helper/alert';

/** hooks */
import { useLanguge } from 'Hooks/useLangugue';

/** components */
import ActionCol from './ActionColumns';
import Popover from '_components/Popover';
import Text from '_components/Text';
import Input from '_components/DataEntry/Input';
import SearchIcon from '_components/Icons/SearchIcon';
import LinkColumn from '_components/AgGridTable/components/LinkColumn';
import ColumnToolPanel from '_components/AgGridTable/components/ColumnToolPanel';

/** style */
import './styles.scss';
import 'ag-grid-enterprise';
import '_components/AgGridTable/styles.scss';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

/** constant */
import { SEND_EMP_SURVEY, SIDE_MENU } from '_components/CarlendarPlan/EmployeeV2/constants/modal';

/** store */
import { selectGlobal } from 'redux/global/globalSlice';
import { openModal, selectModal } from '_components/Modal/slice';
import { selectGeneratorColApi } from 'redux/generateTableApi/slice';

/** api */
import axiosClient from 'axios/axiosClient';
import employeeAPI from '_apis/employee';

/** icons */
import { ColumnsIcon, CornerDownRightArrow } from 'assets/img';
import { useDepartmentList, useGroups, useRoles, useRoleTypes } from 'Hooks/useData';
import SideMenu from './EmpSideMenu/EmpSideMenu';
import { CheckGreenIcon, CloseRedIcon } from 'assets/img';
import SendSurvey from './SendSurvey/SendSurvey';
import { updateGlobalSortEmployeeAction } from 'redux/global/globalAction';
import CommentCell from './CommentCell/CommentCell';
import { selectAuth } from 'redux/auth/authSlice';
import BulkAction from './BulkAction/BulkAction';
import GroupTooltip from './GroupTooltip/GroupTooltip';

const DEFAULT_VALUE = '-';

const EmployeeTablev2 = ({
  data,
  onEmpGridReady,
  ref,
  unitSelected,
  refetchEmployeeWithPositionFetch,
}) => {
  const modal = useSelector(selectModal);
  const { t } = useLanguge();
  const dispatch = useDispatch();
  const { language } = useSelector(selectGlobal);
  const filterInputRef = useRef(null);
  const [rowData, setRowData] = useState([]);
  const [convertedData, setConvertedData] = useState([]);
  const [selectedEmp, setSelectedEmp] = useState([]);
  const { deleteSplitEmployee } = employeeAPI();
  const { isEditabled } = useSelector(selectAuth);

  // edit value selection
  const { data: roleList = [] } = useRoles();
  const {
    data: { data: groupList },
  } = useGroups({ unitSelected });
  const { data: roleTypeList } = useRoleTypes();

  const { data: departmentList } = useDepartmentList();

  const { api: empTableApi, colApi: empTableColApi } = useSelector(selectGeneratorColApi).empTable;
  const handleDelete = (emp) => {
    confirmDelete({}).then(async (result) => {
      if (result.isConfirmed) {
        showLoading({ shouldShow: true, title: 'deleting' });
        if (emp?.isSplited) {
          const splitID = emp.splitID;
          await deleteSplitEmployee({ splitID });
          await refetchEmployeeWithPositionFetch();
          showLoading({ shouldShow: false });
          Toast({
            icon: 'success',
            title: `$${emp.name} {t('deleted')} `,
          });
          return;
        }

        const employeeInfo = emp;
        axiosClient
          .post('calendar-plan/employee/delete', { employeeInfo, page: 1 })
          .then(async (result) => {
            await refetchEmployeeWithPositionFetch();
            showLoading({ shouldShow: false });
            Toast({
              icon: 'success',
              title: `${emp.name} ${t('deleted')} `,
            });
          })
          .catch((err) => {
            Toast({
              icon: 'error',
              title: `${t('delete')} ${t('employee')} ${t('unsuccess')}`,
            });
          });
      }
    });
  };

  const defaultColDef = useMemo(
    () => ({
      cellClass: 'no-padding-right-cell',
      headerClass: 'no-padding-right-header',
      suppressMovable: true,
      sortable: true,
      editable: false,
      resizable: true,
      menuTabs: [],

      flex: 1,
      valueFormatter: ({ value }) => {
        return value ? value : DEFAULT_VALUE;
      },
      suppressKeyboardEvent: (params) => {
        const KEY_ENTER = 13;
        const event = params.event;
        if (event.keyCode === KEY_ENTER) {
          return true; // suppress disable auto submit edit when press enter
        } else {
          return false; // don't suppress other keys
        }
      },
      cellStyle: (params) => {
        const { data } = params;
        let cellStyle = {};
        if (!data) {
          const { node } = params;
          const employeeFound = convertedData.find((item) => item.id === +node.key);
          if (!employeeFound) return {};
          const isSeleceted = selectedEmp.map((item) => item.id).includes(+employeeFound.id);
          if (isSeleceted) {
            cellStyle = { ...cellStyle, backgroundColor: '#FFE1D9' };
          }
          return cellStyle;
        }
        const { id, isSubPosition } = data;

        if (isSubPosition) {
          cellStyle = { ...cellStyle, backgroundColor: '#F3F1F9' };
        } else {
          const isSeleceted = selectedEmp.map((item) => item.id).includes(+id);
          if (isSeleceted) {
            cellStyle = { ...cellStyle, backgroundColor: '#FFE1D9' };
          }
        }
        return cellStyle;
      },
    }),
    [language, selectedEmp],
  );

  const isNo = useMemo(() => language === 'NO', [language]);
  const colDefs = useMemo(
    () => [
      {
        field: 'id',
        colId: 'id',
        headerName: '#',
        width: 55,
        hide: false,
        sortIndex: 0,
        cellRenderer: ({data}) => <div>{data.index}</div>
      },

      {
        field: 'name',
        colId: 'emp-name',
        headerName: t('name'),

        minWidth: 200,
        flex: 1,
        hide: false,
        colSpan: (params) => {
          const { data } = params;
          return data?.isSubPosition ? 2 : 1;
        },
        cellRenderer: (params) => {
          const { data } = params;
          if (!data) {
            const { node } = params;
            const employeeFound = convertedData.find((item) => item.id === +node.key);
            if (!employeeFound) {
              return;
            }
            return (
              <LinkColumn
                fontSize={16}
                fontWeight={500}
                onClick={() => {
                  dispatch(openModal({ name: SIDE_MENU, data: employeeFound }));
                }}
              >
                {employeeFound.name}
              </LinkColumn>
            );
          }
          if (data.isSubPosition) {
            const departmentFound = departmentList.find((item) =>
              item.unit.find((unit) => unit.unitCode === data.unitCode),
            );
            if (departmentFound) {
              const unitFound = departmentFound.unit.find(
                (unit) => unit.unitCode === data.unitCode,
              );

              return (
                <span>
                  <img
                    src={CornerDownRightArrow}
                    alt=""
                    width={18}
                    style={{ marginRight: '5px' }}
                  />
                  <span style={{ marginRight: '5px' }}>{t('shared-position').toLowerCase()}:</span>
                  {departmentFound.departmentName}-{unitFound?.unitName}
                </span>
              );
            }
            return;
          }

          return (
            <LinkColumn
              fontSize={16}
              fontWeight={500}
              onClick={() => {
                dispatch(openModal({ name: SIDE_MENU, data }));
              }}
            >
              {data.name}
            </LinkColumn>
          );
        },
        comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
          if (valueA == valueB) return 0;
          if (isDescending) {
            return valueA > valueB ? -1 : 1;
          }
          return valueA > valueB ? 1 : -1;
        },
      },
      {
        field: 'emp_id_number',
        colId: 'emp_id_number',
        hide: false,
        headerName: t('emp-id-number'),
      },
      {
        field: 'role',
        colId: 'role',
        headerName: t('role'),
        width: 120,
        cellEditor: 'agSelectCellEditor',
        hide: false,
        cellEditorParams: {
          values: [DEFAULT_VALUE, ...roleList].map((item) => item.role),
        },
        valueGetter: (params) => {
          const { data } = params;
          if (!data) {
            const { node } = params;
            const employeeFound = convertedData.find((item) => item.id === +node.key);
            if (!employeeFound) return;
            return employeeFound.role;
          } else {
            return data.role;
          }
        },
        valueSetter: ({ data, newValue }) => {
          const selectedRoleItem = roleList.find((item) => item.role === newValue);
          if (selectedRoleItem) {
            data.role = selectedRoleItem.role;
            data.roleKey = selectedRoleItem.key;
            data.role_id = selectedRoleItem.id;
          } else {
            data.role = null;
            data.roleKey = null;
            data.role_id = null;
          }
          return true;
        },
        valueFormatter: ({ value }) => {
          if (value == null) {
            return DEFAULT_VALUE;
          }
          return value.charAt(0).toUpperCase() + value.slice(1);
        },
      },
      {
        colId: 'emp-group',
        headerName: t('group'),
        width: 80,
        cellEditor: 'agSelectCellEditor',
        hide: false,
        cellEditorParams: {
          values: [DEFAULT_VALUE, ...groupList].map((item) => item.name),
        },
        cellRenderer: (params) => {
          const { data } = params;
          if (!data) {
            const { node } = params;
            const employeeFound = convertedData.find((item) => item.id === +node.key);
            if (!employeeFound) return;
            const listGroupName = employeeFound.listGroup
              .map((item) => {
                return item.groupName;
              })
              .join(', ');
            return (
              <div className="emp-group-cell__wrap">
                <span>{listGroupName}</span>
              </div>
            );
          }
          if (!data.listGroup.length) return;
          const listGroupName = data.listGroup
            .map((item) => {
              return item.groupName;
            })
            .join(', ');
          return (
            <div className="emp-group-cell__wrap">
              <span>{listGroupName}</span>
            </div>
          );
        },
        tooltipValueGetter: ({ data }) => {
          if (!data) return;
          return data.listGroup;
        },
        tooltipComponent: GroupTooltip,
        valueSetter: ({ data, newValue }) => {
          const selectedGroupItem = groupList.find((item) => item.name === newValue);
          if (selectedGroupItem) {
            data.group_id = selectedGroupItem.id;
            data.group = selectedGroupItem.name;
          } else {
            data.group_id = null;
            data.group = null;
          }
          return true;
        },
      },
      {
        field: 'job_percentage',
        colId: 'emp-job-percentage',
        headerName: `${t('job')} %`,
        hide: false,
        valueFormatter: ({ value }) => {
          return value ? `${value}%` : DEFAULT_VALUE;
        },
        valueSetter: (params) => {
          let value = params.newValue;
          value = value.replace(/[^0-9]/g, '');
          value = Math.max(0, Math.min(100, value));
          params.data[params.colDef.field] = value;
          return true;
        },
        valueGetter: (params) => {
          if (!params.data) {
            const { node } = params;
            const employeeFound = convertedData.find((item) => item.id === +node.key);
            if (!employeeFound) return;
            return employeeFound[params.colDef.field];
          }
          return params.data[params.colDef.field];
        },
        width: isNo ? 100 : 80,
      },
      {
        colId: 'role_type',
        headerName: t('role-type'),
        hide: false,
        valueGetter: (params) => {
          if (!params.data) {
            const { node } = params;
            const employeeFound = convertedData.find((item) => item.id === +node.key);
            if (!employeeFound) return DEFAULT_VALUE;
            return employeeFound.role_type_detail?.type;
          }
          return params.data?.role_type_detail?.type || DEFAULT_VALUE;
        },
        width: 120,
      },
      {
        colId: 'medical_delivery',
        field: 'medical_delivery',
        headerName: t('medical-distribution'),
        headerClass: 'center-header',
        hide: false,
        cellRenderer: ({ data, ...params }) => {
          if (!data) {
            const { node } = params;
            const employeeFound = convertedData.find((item) => item.id === +node.key);
            if (!employeeFound) return;
            return (
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  src={employeeFound.medical_delivery ? CheckGreenIcon : CloseRedIcon}
                  width={18}
                  height={18}
                />
              </div>
            );
          }
          if (data.isSubPosition) return;

          return (
            <div
              style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={data.medical_delivery ? CheckGreenIcon : CloseRedIcon}
                width={18}
                height={18}
              />
            </div>
          );
        },
      },
      {
        field: 'comment',
        colId: 'emp-comment',
        headerName: t('comment'),
        headerClass: 'center-header',
        menuTabs: [],
        hide: false,
        tooltipValueGetter: (params) => {
          const { data } = params;
          if (!data || !data.uuid || !data.comment) {
            const { node } = params;
            const employeeFound = convertedData.find((item) => item.id === +node.key);
            if (!employeeFound) return;
            return employeeFound.comment;
          }

          return data.comment;
        },
        cellRenderer: ({ data, ...params }) => {
          if (!data) {
            const { node } = params;
            const employeeFound = convertedData.find((item) => item.id === +node.key);
            if (!employeeFound) return;
            return <CommentCell data={employeeFound} />;
          }
          if (data.isSubPosition) return;

          return <CommentCell data={data} />;
        },
        width: 160,
      },
      {
        field: 'surveyUpdatedAt',
        colId: 'surveyUpdatedAt',
        headerName: t('last-updated'),
        headerClass: 'center-header',
        sortingOrder: ['asc', 'desc'],
        menuTabs: [],
        hide: false,
        cellRenderer: ({ data, ...params }) => {
          if (!data) {
            const { node } = params;
            const employeeFound = convertedData.find((item) => item.id === +node.key);
            if (!employeeFound) return;
            return (
              <Text>
                {employeeFound.surveyUpdatedAt
                  ? moment(employeeFound.surveyUpdatedAt).format('DD-MM-YYYY HH:mm')
                  : DEFAULT_VALUE}
              </Text>
            );
          }
          if (data.isSubPosition) return;

          return (
            <Text>
              {data.surveyUpdatedAt
                ? moment(data.surveyUpdatedAt).format('DD-MM-YYYY HH:mm')
                : DEFAULT_VALUE}
            </Text>
          );
        },
        width: 160,
      },
      {
        headerName: t('edit'),
        colId: 'action',
        headerClass: 'center-header',
        width: 120,
        sortable: false,
        hide: false,
        headerTooltip: t('edit'),
        cellRenderer: ({ data, rowIndex, ...params }) => {
          if (!isEditabled) return;

          if (!data) {
            const { node } = params;
            const employeeFound = convertedData.find((item) => item.id === +node.key);
            if (!employeeFound) return;
            return (
              <ActionCol
                rowIndex={rowIndex}
                empInfo={employeeFound}
                handleDelete={handleDelete}
                unitSelected={unitSelected}
                refetchEmployees={refetchEmployeeWithPositionFetch}
                api={empTableApi}
                colApi={empTableColApi}
                isOpen={true}
              />
            );
          }
          if (data.isSubPosition) return;

          return (
            <ActionCol
              rowIndex={rowIndex}
              empInfo={data}
              handleDelete={handleDelete}
              unitSelected={unitSelected}
              refetchEmployees={refetchEmployeeWithPositionFetch}
              api={empTableApi}
              colApi={empTableColApi}
              isOpen={true}
            />
          );
        },
      },
    ],
    [
      language,
      roleList,
      groupList,
      empTableApi,
      empTableColApi,
      convertedData,
      departmentList,
      selectedEmp,
    ],
  );
  useEffect(() => {
    const convertToRowData = () => {
      const result = [];
      for (const employeeIndex in data.sort((a, b) => a.id - b.id)) {
        const employee = data[employeeIndex];
        const employeePosition = employee.employeePosition;
        for (const positionIndex in employeePosition) {
          const position = employeePosition[positionIndex];
          const listGroup = position.employeeGroups.map((item) => ({
            groupID: item.groupInformation.id,
            groupName: item.groupInformation.name,
            groupKey: item.groupInformation.key,
          }));
          result.push({
            ...employee,
            role_id: position.employeeRole.id,
            role: position.employeeRole.role,
            role_type_detail: position.employeeRoleType,
            listGroup,
            unit_cod: position.unit_code,
            job_percentage: position.job_percentage,
            isSubPosition: positionIndex > 0,
            unitCode: position.unit_code,
            index: +employeeIndex + 1,
          });
        }
      }
      return result;
    };
    const convertedData = convertToRowData();

    const result = [...convertedData];
    setRowData(result);
    setConvertedData(result);
  }, [data]);

  const onFilterTextBoxChanged = (e) => {
    const searchText = e.target.value;
    empTableApi.setQuickFilter(searchText);
  };
  const onSortChanged = useCallback(() => {
    const empColumnState = empTableColApi.getColumnState();
    const sortedColumns = empColumnState.find((column) => column.sort !== null);
    if (sortedColumns) {
      const { sort = '', colId } = sortedColumns;
      dispatch(
        updateGlobalSortEmployeeAction({
          state: [{ colId, sort }],
          defaultState: { sort: null },
          applyOrder: false,
        }),
      );
    }
  }, [empTableColApi]);

  const handleSelectAllEmp = () => {
    if(selectedEmp.length > 0) {
      setSelectedEmp([]);
    } else {
      setSelectedEmp(rowData);
    }
  }

  const autoGroupColumnDef = useMemo(() => {
    return {
      field: 'index',
      colId: 'empIndex',
      headerComponent: () =><div className='employee-select-all'><input disabled={!isEditabled} checked={selectedEmp.length > 0} onChange={handleSelectAllEmp} type="checkbox" /></div> ,
      showRowGroup: 'id',
      cellRenderer: 'agGroupCellRenderer',
      width: 20,
      cellRendererParams: {
        suppressCount: true,
        suppressDoubleClickExpand: true,

        innerRenderer: (params) => {
          const { data, node } = params;
          if (!data) {
            const child = node.allLeafChildren[0];
            const childData = child.data;
            return (
              <div className="selected-employee__wrap">
                <label htmlFor="">{childData.index}</label>{' '}
                {isEditabled ? (
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    checked={selectedEmp.map((item) => item.id).includes(+childData.id)}
                    onChange={() => {
                      const isExist = selectedEmp.find((item) => +item.id === +childData.id);
                      if (isExist) {
                        setSelectedEmp((prev) => prev.filter((item) => +item.id !== +childData.id));
                      } else {
                        setSelectedEmp((prev) => [...prev, childData]);
                      }
                    }}
                  />
                ) : null}
              </div>
            );
          }
          if (data.isSubPosition) return;
          const { id } = data;
          return (
            <div className="selected-employee__wrap">
              {isEditabled ? (
                <input
                  type="checkbox"
                  name=""
                  id=""
                  checked={selectedEmp.map((item) => item.id).includes(+id)}
                  onChange={() => {
                    const isExist = selectedEmp.find((item) => +item.id === +id);
                    if (isExist) {
                      setSelectedEmp((prev) => prev.filter((item) => +item.id !== +id));
                    } else {
                      setSelectedEmp((prev) => [...prev, data]);
                    }
                  }}
                />
              ) : null}
            </div>
          );
        },
      },
    };
  }, [selectedEmp]);
  const getDataPath = useCallback((data) => {
    const dataPath = [data.id];
    if (data.isSubPosition) {
      dataPath.push(data.unitCode);
    }
    return dataPath;
  }, []);

  useEffect(() => {
    setSelectedEmp([]);
  }, [unitSelected]);

  return (
    <div className="ag-theme-alpine table-init" style={{ width: '100%', height: '60vh' }}>
      <div className="employee-table__option-action">
        <Popover
          content={<ColumnToolPanel colsList={colDefs} columnApi={empTableColApi} />}
          placement="bottomLeft"
          style={{
            width: '280px',
            padding: '16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 4,
          }}
        >
          <button className="columns-btn">
            <img src={ColumnsIcon} />
            {t('column')}
          </button>
        </Popover>
        <div className="employee-table__search-action">
          {!selectedEmp.length ? (
            <Input
              placeholder={t('seach-employee')}
              prefix={<SearchIcon />}
              onChange={(e) => onFilterTextBoxChanged(e)}
              ref={filterInputRef}
              style={{ borderRaidus: 0 }}
            />
          ) : (
            <BulkAction
              setSelectedEmp={setSelectedEmp}
              selectedEmp={selectedEmp}
              refetchEmployeeWithPositionFetch={refetchEmployeeWithPositionFetch}
              groupList={groupList}
              unitSelected={unitSelected}
              roleList={roleList}
              roleTypeList={roleTypeList}
            />
          )}
        </div>
      </div>
      <div className="emp-table-container">
        <AgGridReact
          ref={ref}
          rowData={rowData}
          animateRows={true}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          onGridReady={onEmpGridReady}
          tooltipShowDelay={0}
          editType="fullRow"
          suppressClickEdit={true} // disable edit when user double click a cell
          suppressClipboardPaste={true} // disable pasting from clipboard
          suppressRowClickSelection={true} // disable row selection on click
          onSortChanged={onSortChanged}
          groupDefaultExpanded={1}
          // groupMaintainOrder={true}
          // groupDisplayType={'custom'}
          autoGroupColumnDef={autoGroupColumnDef}
          // groupHideOpenParents={true}
          treeData={true}
          getDataPath={getDataPath}
          rowGroupPanelSuppressSort={true}
        />
      </div>
      {modal.name === SIDE_MENU && (
        <SideMenu
          unitSelected={unitSelected}
          refetchEmployeeWithPositionFetch={refetchEmployeeWithPositionFetch}
        />
      )}
      {modal.name === SEND_EMP_SURVEY && <SendSurvey unitSelected={unitSelected} />}
    </div>
  );
};

export default EmployeeTablev2;
