import { t } from 'i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';

import { CalenderIcon } from 'assets/img';
import HolidaySelection from './HolidaySelection';
import DatePickerCustom from 'components/DatePicker/DatePickerCustom';

const DateOnlyItem = ({
  questionId,
  surveyRecord,
  setSurveyRecord,
  index,
  isHoliday = false,
  isDisabled,
  handleRemoveQuestion,
  limitSurveyStart,
  limitSurveyEnd,
  holidayList,
}) => {
  const [date, setDate] = useState(null);

  let survey = { ...surveyRecord };
  if (!survey[questionId]) {
    survey[questionId] = { type: 'date-only', dateArr: [] };
  }
  if (
    survey[questionId] &&
    (moment(survey[questionId][index]).year() < 2000 ||
      moment(survey[questionId][index]).year() < 2000)
  ) {
    survey[questionId] = { type: 'date-only', dateArr: [] };
  }

  useEffect(() => {
    if (survey[questionId].dateArr[index]) {
      setDate(moment(survey[questionId].dateArr[index]).format('YYYY-MM-DD'));
    }
  }, [survey[questionId].dateArr[index]]);

  return (
    <div className="calendar-plan-survey-form__date__wrap">
      <div className="calendar-plan-survey-form__date__group date-only">
        {isHoliday ? (
          <HolidaySelection
            date={date}
            setDate={setDate}
            questionId={questionId}
            setSurveyRecord={setSurveyRecord}
            survey={survey}
            index={index}
            isDisabled={isDisabled}
            limitSurveyStart={limitSurveyStart}
            limitSurveyEnd={limitSurveyEnd}
            holidayList={holidayList}
          />
        ) : (
          <DatePickerCustom
            className="inline"
            disabled={isDisabled}
            date={date !== null ? moment(date).format('YYYY-MM-DD') : ''}
            setDate={(value) => {
              survey[questionId].dateArr[index] = value ? new Date(value).toISOString() : '';
              setDate(value);
              if (new Date(value).getFullYear() > 1970) {
                setSurveyRecord(survey);
              }
            }}
            minDate={new Date(limitSurveyStart)}
            maxDate={new Date(limitSurveyEnd)}
            isNotAutofocus={true}
            placeholder="dd/mm/yyyy"
          />
        )}
        {survey[questionId].dateArr.length > 1 && !isDisabled && (
          <CloseOutlined className="clear-date-icon" onClick={handleRemoveQuestion} />
        )}
      </div>
    </div>
  );
};

export default DateOnlyItem;
