import axiosClient from 'axios/axiosClient';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { t } from 'i18next';
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';
import { updatePlanSelectedAction } from 'redux/calendarPlan/calendarPlanAction';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import { selectAuth } from 'redux/auth/authSlice';
import { useTurnusContext } from '../../Turnus/TurnusContext';

const useVersionData = ({ planID }) => {
  return useQuery(
    ['versionList', planID],
    async () => {
      const result = await axiosClient.post('/calendar-plan/staff-plan/versions', {
        planID,
      });
      return result;
    },
    {
      initialData: [],
      enabled: !!planID,
    },
  );
};
const RemoveButton = ({ version, refetchPlanChildList, refetchVersions }) => {
  const { is_active: isActive, plan_id: planID } = version;
  if (isActive) {
    return;
  }
  const handleRemoveVersion = async () => {
    if (isActive) return;
    axiosClient
      .post('calendar-plan/staff-plan/remove-version', { versionID: version.id })
      .then((_) => {
        refetchVersions();
      });
  };
  return (
    <span className={`remove-btn ${!isActive ? 'active' : ''}`} onClick={handleRemoveVersion}>
      {t('remove')}
    </span>
  );
};
const RestoreButton = ({
  version,
  refetchVersions,
  dispatch,
  planSelected,
  refetchPlanChildList,
}) => {
  const turnusData = useTurnusContext();
  const { setPlanSelected } = turnusData;
  const { is_active: isActive, plan_id: planID } = version;
  const handleRestore = async () => {
    if (isActive) return;
    await axiosClient
      .post('calendar-plan/staff-plan/active-versions', {
        planID: planID,
        versionID: version.id,
      })
      .then((_) => {
        refetchVersions();
        refetchPlanChildList();
        setPlanSelected({ ...planSelected, versionID: version.id });
      });
  };
  return (
    <span className={`restore-btn ${!isActive ? 'active' : ''}`} onClick={handleRestore}>
      {isActive ? t('activated') : t('restore')}
    </span>
  );
};

const VersionHistory = ({ planSelected, refetchPlanChildList }) => {
  const { data: versionList, refetch: refetchVersions } = useVersionData({
    planID: planSelected.id,
  });
  const dispatch = useDispatch();
  const { isEditabled } = useSelector(selectAuth);

  const defaultColDef = useMemo(
    () => ({
      resizable: false,
      filter: false,
      sortable: true,
      editable: false,
      menuTabs: [],
      flex: 1,
      headerClass: 'plan-header',
    }),
    [],
  );
  const columnDefs = useMemo(
    () => [
      {
        headerName: t('created-at'),
        headerClass: 'dark-header',
        resizable: true,
        field: 'created_at',

        valueFormatter: ({ value }) => moment(value).format('DD/MM/YYYY HH:mm:ss'),
      },
      {
        headerName: t('version'),
        headerClass: 'dark-header',

        field: 'version',
      },
      {
        headerName: t('performed-by'),
        headerClass: 'dark-header',

        field: 'created_by',
      },
      {
        headerName: t('version-note'),
        headerClass: 'dark-header',

        field: 'note',
        width: 200,
        flex: 2,
      },
      // TODO: implement logic for these columns
      // {
      //   headerName: t('working-environment-act'),
      //   headerClass: 'dark-header',

      //   cellStyles: () => {
      //     return { display: 'flex', alignItemm: 'center' };
      //   },
      //   cellRenderer: () => {
      //     const percent = 94;

      //     return (
      //       <div
      //         style={{
      //           width: 40,
      //           height: 40,
      //           textAlign: 'center',
      //         }}
      //       >
      //         <CircularProgressbar
      //           value={percent}
      //           text={`${percent}`}
      //           strokeWidth={10}
      //           styles={buildStyles({
      //             textSize: '30px',
      //             textColor: '#000',
      //             pathColor: '#0F8737',
      //           })}
      //         />
      //       </div>
      //     );
      //   },
      // },
      // {
      //   headerName: t('soft-rules'),
      //   headerClass: 'dark-header',

      //   cellRenderer: () => {
      //     const percent = 94;

      //     return (
      //       <div style={{ width: 40, height: 40, textAlign: 'center' }}>
      //         <CircularProgressbar
      //           value={percent}
      //           text={`${percent}`}
      //           strokeWidth={10}
      //           styles={buildStyles({
      //             textSize: '30px',
      //             textColor: '#000',
      //             pathColor: '#0F8737',
      //           })}
      //         />
      //       </div>
      //     );
      //   },
      // },
      // {
      //   headerName: t('emp-wish'),
      //   headerClass: 'dark-header',

      //   cellRenderer: () => {
      //     return (
      //       <div className="progress__wrap">
      //         <div style={{ width: 40, height: 40, textAlign: 'center' }}>
      //           <CircularProgressbar
      //             value={83}
      //             text={`${83}`}
      //             strokeWidth={10}
      //             styles={buildStyles({
      //               textSize: '30px',
      //               textColor: '#000',
      //               pathColor: '#96CA22',
      //             })}
      //           />
      //         </div>
      //       </div>
      //     );
      //   },
      // },
      {
        headerName: t('vacant-man-years'),
        headerClass: 'dark-header',

        valueGetter: () => {
          return 0.3;
        },
      },
      {
        headerName: '',
        headerClass: 'dark-header',

        cellRenderer: ({ data }) => {
          if (!isEditabled) return;
          return (
            <RestoreButton
              version={data}
              refetchVersions={refetchVersions}
              dispatch={dispatch}
              planSelected={planSelected}
              refetchPlanChildList={refetchPlanChildList}
            />
          );
        },
      },
      {
        headerName: '',
        headerClass: 'dark-header',

        cellRenderer: ({ data }) => {
          if (!isEditabled) return;

          return (
            <RemoveButton
              version={data}
              refetchVersions={refetchVersions}
              refetchPlanChildList={refetchPlanChildList}
            />
          );
        },
      },
    ],
    [versionList],
  );
  return (
    <div className="version-history-table__wrap ag-theme-alpine">
      <AgGridReact
        rowData={versionList}
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        animateRows={true}
        tooltipShowDelay={0}
        rowHeight={60}
      />
    </div>
  );
};

export default VersionHistory;
