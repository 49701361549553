import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axiosClient from 'axios/axiosClient';

import { Toast } from 'helper/alert';

import { t } from 'i18next';

import './style.scss';
import { LeftArrowBrownIcon } from 'assets/img';

const ResetPassword = () => {
  const navigate = useNavigate();
  const { id: resetToken } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(null);
  const [password, setPassword] = useState('');

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const response = await axiosClient.post(
          '/verify-reset-token',
          { token: resetToken },
          { disableToast: true },
        );
        setIsTokenValid(response.code === 200);
      } catch (error) {
        console.log(error);
        setIsTokenValid(false);
      }
    };

    checkTokenValidity();
  }, [resetToken]);

  const handleResetPassword = async () => {
    setIsLoading(true);
    try {
      const response = await axiosClient.post('/reset-password', { password, token: resetToken });
      if (response.code === 200) {
        Toast({ icon: 'success', title: t('reset-success'), timer: 1500 });
      } else {
        Toast({ icon: 'error', title: t('try-again'), timer: 1500 });
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <div className="reset-pw__container">
      {isTokenValid ? (
        <div className="valid-token">
          <p>{t('reset-password')}</p>
          <label>
            {t('new-password')}
            <input
              type="text"
              value={password}
              disabled={isLoading}
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
          <button onClick={handleResetPassword} disabled={isLoading}>
            {t('send')}
          </button>
        </div>
      ) : (
        <div className="expire-token">
          <p>{t('token-expired')}</p>
        </div>
      )}
      <button onClick={() => navigate('/login', { replace: true })}>
        <img src={LeftArrowBrownIcon} />
        {t('back-to-login')}
      </button>
    </div>
  );
};

export default ResetPassword;
