export const createDownloadXlsx = (data, fileName) => {
    if(!data) return;
    const fileType = fileName.split('.').pop();

    if (fileType !== 'xlsx') {
        throw new Error(`Unsupported type ${fileType}`);
    }

    const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
}
