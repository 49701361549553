import { t } from 'i18next';

export const convertToAbbreviation = (number) => {
  if (number >= 1000) {
    const suffixNum = (number / 1000).toFixed(2);

    return suffixNum + 'K';
  }
  return number;
};
export const formatNumberWithComas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export const secondsToHourMinutes = (seconds) => {
  // Calculate hours
  let hours = Math.floor(seconds / 3600);
  // Calculate remaining seconds after subtracting hours
  let remainingSeconds = seconds % 3600;
  // Calculate minutes
  let minutes = Math.floor(remainingSeconds / 60);

  return { hour: hours, minutes: minutes };
};
export const convertShortHoursMin = (data) => {
  const hoursAndMin = secondsToHourMinutes(data);
  return `${hoursAndMin.hour} ${t('hour')[0].toLowerCase()} ${hoursAndMin.minutes} min`;
};
