import React from 'react';
import QuestionItem from '../DefaultRotation/QuestionItem';
import axiosClient from 'axios/axiosClient';

const DayOffAfterNightShift = ({ refetchSoftRuleList, softRuleList = [], unitSelected }) => {
  const listEnabledQuestion = ['two_night_off_after_night_shift'];
  const handleUpdate = async (e) => {
    const { name: ruleID, checked } = e.target;
    e.target.checked = !checked;
    await axiosClient
      .post('calendar-plan/soft-rule/single-update', {
        unitCode: unitSelected,
        ruleID,
        value: checked,
      })
      .then((_) => {
        refetchSoftRuleList();
      });
  };

  return (
    <div>
      {softRuleList
        .filter((question) => listEnabledQuestion.includes(question.property))
        .map((question) => {
          return (
            <QuestionItem
              key={question.id}
              question={question}
              isNameId={true}
              type={'checkbox'}
              value={question.sofRuleRecord?.value}
              handleUpdate={handleUpdate}
            />
          );
        })}
    </div>
  );
};

export default DayOffAfterNightShift;
