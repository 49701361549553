import { loadState } from 'helper/localStorage';

const site = process.env.REACT_APP_SITE;
//add role to each obj
const baseNav = [
  {
    key: 'predict',
    to: '/predict',
    name: 'predict',
    roles: ['planner', 'unions', 'admin', 'super-admin'],
  },
  {
    key: 'calendar',
    to: '/calendar',
    name: 'calendar-upcase',
    roles: ['planner', 'unions', 'admin', 'partner', 'super-admin'],
  },
  {
    key: 'admin',
    to: '/admin',
    name: 'admin',
    roles: ['admin', 'super-admin'],
  },
];
// const trondheimNav = [...baseNav];
const bluebirdcarersNav = [...baseNav];
const heathlandsvillageNav = [...baseNav];
const falcareNav = [...baseNav];
const malvikNav = [...baseNav];
const demoNav = [
  {
    key: 'predict',
    to: '/predict',
    name: 'predict',
    roles: ['planner', 'unions', 'admin', 'super-admin'],
  },
  {
    key: 'calendar',
    to: '/calendar',
    name: 'calendar-upcase',
    roles: ['planner', 'unions', 'admin', 'partner', 'super-admin'],
  },

  {
    key: 'admin',
    to: '/admin',
    name: 'admin',
    roles: ['admin', 'super-admin'],
  },
];
const chironNav = [
  {
    key: 'predict',
    to: '/predict',
    name: 'predict',
    roles: ['planner', 'unions', 'admin', 'super-admin'],
  },
  {
    key: 'calendar',
    to: '/calendar',
    name: 'calendar-upcase',
    roles: ['planner', 'unions', 'admin', 'partner', 'super-admin'],
  },

  {
    key: 'admin',
    to: '/admin',
    name: 'admin',
    roles: ['admin', 'super-admin'],
  },
];

const chironStgNav = [
  {
    key: 'predict',
    to: '/predict',
    name: 'predict',
    roles: ['planner', 'unions', 'admin', 'super-admin'],
  },
  {
    key: 'calendar',
    to: '/calendar',
    name: 'calendar-upcase',
    roles: ['planner', 'unions', 'admin', 'partner', 'super-admin'],
  },

  {
    key: 'chiron',
    to: '/chiron',
    name: 'chiron-upcase',
    roles: ['planner', 'unions', 'admin', 'partner', 'super-admin'],
  },

  {
    key: 'admin',
    to: '/admin',
    name: 'admin',
    roles: ['admin', 'super-admin'],
  },
];
const chironDevNav = [
  {
    key: 'predict',
    to: '/predict',
    name: 'predict',
    roles: ['planner', 'unions', 'admin', 'super-admin'],
  },
  {
    key: 'calendar',
    to: '/calendar',
    name: 'calendar-upcase',
    roles: ['planner', 'unions', 'admin', 'partner', 'super-admin'],
  },

  {
    key: 'chiron',
    to: '/chiron',
    name: 'chiron-upcase',
    roles: ['planner', 'unions', 'admin', 'partner', 'super-admin'],
  },

  {
    key: 'admin',
    to: '/admin',
    name: 'admin',
    roles: ['admin', 'super-admin'],
  },
];

const trondheimNav = [...baseNav];
const finnmarkssykehusetNav = [...baseNav];
const indreOstfoldNav = [...baseNav];
const diakonNav = [...baseNav];
const baerumNav = [...baseNav];
const nesoddenNav = [...baseNav];
const bydelAlnaNav = [...baseNav];

const indrefosenNav = [
  {
    key: 'dash-board',
    to: '/dashboard',
    name: 'dashboard-upcase',
    roles: ['planner', 'unions', 'admin', 'economist', 'super-admin'],
  },

  {
    key: 'predict',
    to: '/predict',
    name: 'predict',
    roles: ['planner', 'unions', 'admin', 'super-admin'],
  },
];

const osloNav = [
  {
    key: 'predict',
    to: '/predict',
    name: 'predict',
    roles: ['planner', 'unions', 'admin', 'super-admin'],
  },
  {
    key: 'calendar',
    to: '/calendar',
    name: 'calendar-upcase',
    roles: ['planner', 'unions', 'admin', 'partner', 'super-admin'],
  },
];
const hitraNav = [
  {
    key: 'predict',
    to: '/predict',
    name: 'predict',
    roles: ['planner', 'unions', 'admin', 'super-admin'],
  },
];

const diakonhjemmetNav = [...baseNav];
let _nav = trondheimNav;

switch (site) {
  case 'bydel-alna':
    _nav = bydelAlnaNav;
    break;

  case 'nesodden':
    _nav = nesoddenNav;
    break;

  case 'diakon':
    _nav = diakonNav;
    break;

  case 'indre-ostfold':
    _nav = indreOstfoldNav;
    break;

  case 'finnmarkssykehuset':
    _nav = finnmarkssykehusetNav;
    break;

  case 'baerum':
    _nav = baerumNav;
    break;

  case 'trondheim':
    _nav = trondheimNav;
    break;

  case 'indrefosen':
    _nav = indrefosenNav;
    break;

  case 'oslo':
    _nav = osloNav;
    break;

  case 'hitra':
    _nav = hitraNav;
    break;

  case 'diakonhjemmet':
    _nav = diakonhjemmetNav;
    break;

  case ('demo', 'chiron-base'):
    _nav = demoNav;
    break;
  case 'chiron':
    _nav = chironNav;
    break;

  case 'helse':
    _nav = chironNav;
    break;

  case 'chiron-stg':
    _nav = chironStgNav;
    break;
  case 'chiron-dev':
    _nav = chironDevNav;
    break;

  case 'admin-chiron':
    _nav = chironStgNav;
    break;

  case 'bluebirdcarers':
    _nav = bluebirdcarersNav;
    break;
  case 'heathlandsvillage':
    _nav = heathlandsvillageNav;
    break;
  case 'falcare':
    _nav = falcareNav;
    break;
  case 'malvik':
    _nav = malvikNav;
    break;
  default:
    _nav = demoNav;
    break;
}

export default _nav;
