import React, { useState } from 'react';
import { t } from 'i18next';
import { useMutation } from 'react-query';
import axiosClient from 'axios/axiosClient';

import EmpTable from './EmpTable';
import { CloseIcon } from 'assets/img';

import './styles.scss';
import { useSelector } from 'react-redux';
import { selectGlobal } from 'redux/global/globalSlice';
import { Toast } from 'helper/alert';

const SendSurveyForm = ({ unitSelected, setIsSendSurvey, topicList }) => {
  const [selectedTopic, setSelectedTopic] = useState(topicList.map((item) => item.uuid));
  const [selectedRows, setSelectedRows] = useState({});
  const { language } = useSelector(selectGlobal);
  const totalEmployees = Object.keys(selectedRows).length;

  const isDisabled = selectedTopic.length === 0 || totalEmployees === 0;

  const handleSelectQuestion = (questionId) => {
    if (selectedTopic.includes(questionId)) {
      setSelectedTopic(selectedTopic.filter((item) => item !== questionId));
    } else {
      setSelectedTopic([...selectedTopic, questionId]);
    }
  };

  const { mutate: sendSurvey, isLoading } = useMutation(async () => {
    const rowsData = Object.entries(selectedRows);
    const email = rowsData.reduce((acc, [rowId, row]) => (row.email ? [...acc, rowId] : acc), []);
    const phone = rowsData.reduce(
      (acc, [rowId, row]) => (row.phone_number ? [...acc, rowId] : acc),
      [],
    );
    await axiosClient
      .post('/send-survey', {
        topicId: selectedTopic,
        phone,
        email,
      })
      .then((_) => {
        Toast({ icon: 'success', title: `${t('send')} ${t('succes')}` });
      })
      .catch((err) => {
        Toast({ icon: 'error', title: `${t('send')} ${t('unsucces')}` });
      });
  });

  const handleResetDefault = () => {
    setSelectedTopic(topicList.map((item) => item.uuid));
    setSelectedRows({});
  };

  const handleSend = async () => {
    await sendSurvey();
    setIsSendSurvey(false);
  };

  return (
    <div className="modal-wrapper">
      <div className="modal-background" onClick={() => setIsSendSurvey(false)} />
      <div className="survey-form">
        <div className="survey-form__header">
          <p className="survey-form__header-title">{t('send-survey')}</p>
          <div className="survey-form__header-icon" onClick={() => setIsSendSurvey(false)}>
            <img src={CloseIcon} />
          </div>
        </div>

        <div className="survey-form__info">
          <div>
            <p>{t('mandatory-question')}</p>
            <p>{selectedTopic.length}</p>
          </div>
          <div>
            <p>{t('selected-employee')}</p>
            <p>{totalEmployees}</p>
          </div>
        </div>

        <div className="survey-form__select-questions">
          <p>{t('select-questions')}</p>
          <div>
            {topicList.map(({ uuid, topic, no_ver }) => (
              <div
                key={uuid}
                className={selectedTopic.includes(uuid) ? 'selected' : ''}
                onClick={() => handleSelectQuestion(uuid)}
              >
                {language === 'NO' ? no_ver : topic}
              </div>
            ))}
          </div>
        </div>

        <div className="survey-form__emp-table">
          <EmpTable
            unitSelected={unitSelected}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        </div>

        <div className="survey-form__btns">
          <button onClick={handleResetDefault}>{t('reset-default')}</button>
          <button
            className={isDisabled ? 'disabled' : ''}
            disabled={isDisabled || isLoading}
            onClick={handleSend}
          >
            {t('send')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SendSurveyForm;
