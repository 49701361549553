import { GUARD_CODE_TYPE, TYPE_SURVEY_QUESTION, WEEK_TYPE, weekdayKey } from 'constant';
import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const WeekdayTableCell = ({
  isActiveDefault,
  weekdayKeyValue,
  type,
  onChangeTypeShiftValue,
  weekKey,
  isDisabled,
}) => {
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    setIsActive(isActiveDefault);
  }, [isActiveDefault]);
  return (
    <div
      key={uuidv4()}
      className={`type-shift__cell  ${isActive ? 'active' : ''}`}
      onClick={() => {
        if (!isDisabled) {
          onChangeTypeShiftValue(weekdayKeyValue, type.value, weekKey);

          setIsActive((prev) => !prev);
        }
      }}
    >
      <span> {type.key}</span>
    </div>
  );
};

const WeekdayTypeShifTable = ({
  tableValue,
  onChangeTypeShiftValue,
  weekKey,
  isDisabled,
  isExpired,
}) => {
  const typeShift = [
    GUARD_CODE_TYPE.DAY_SHIFT,
    GUARD_CODE_TYPE.LATE_SHIFT,
    GUARD_CODE_TYPE.NIGHT_SHIFT,
  ];
  const renderBody = useCallback(() => {
    return typeShift.map((type) => {
      return (
        <tr key={uuidv4()}>
          {Array.from({ length: 7 }).map((_, index) => {
            const weekdayKeyValue = weekdayKey[index];
            const currentValue = tableValue.typeShiftValue[weekdayKeyValue];

            const isActive = currentValue.includes(type.value);
            return (
              <td key={uuidv4()}>
                <WeekdayTableCell
                  isActiveDefault={isActive}
                  weekdayKeyValue={weekdayKeyValue}
                  type={type}
                  weekKey={weekKey}
                  onChangeTypeShiftValue={onChangeTypeShiftValue}
                  isDisabled={isDisabled || isExpired}
                />
              </td>
            );
          })}
        </tr>
      );
    });
  }, [tableValue]);

  return (
    <div className="weekday-type-shift__type-shift__wrap">
      <p>{t(weekKey)}</p>
      <table className="weekday-type-shift__type-shift__table">
        <thead>
          <th>{t('mon')}</th>
          <th>{t('tue')}</th>
          <th>{t('wed')}</th>
          <th>{t('thu')}</th>
          <th>{t('fri')}</th>
          <th>{t('sat')}</th>
          <th>{t('sun')}</th>
        </thead>
        <tbody>{renderBody()}</tbody>
      </table>
    </div>
  );
};

const WeekdayTypeShift = ({ questionId, surveyRecord, setSurveyRecord, isExpired, isDisabled }) => {
  const [weekType, setWeekType] = useState('odd-week');
  const [value, setValue] = useState({
    type: TYPE_SURVEY_QUESTION.WEEKDAY_TYPE_SHIFT,
    data: [
      {
        weekKey: 'odd-week',
        typeWeek: WEEK_TYPE.ODD_WEEK,
        typeShiftValue: { mon: [], tue: [], wed: [], thu: [], fri: [], sat: [], sun: [] },
        type: TYPE_SURVEY_QUESTION.WEEKDAY_TYPE_SHIFT,
      },
      {
        weekKey: 'even-week',
        typeWeek: WEEK_TYPE.EVEN_WEEK,
        typeShiftValue: { mon: [], tue: [], wed: [], thu: [], fri: [], sat: [], sun: [] },
        type: TYPE_SURVEY_QUESTION.WEEKDAY_TYPE_SHIFT,
      },
    ],
    reason: '',
  });

  const survey = { ...surveyRecord };
  if (survey[questionId] && typeof survey[questionId] === 'string') {
    survey[questionId] = JSON.parse(survey[questionId]);
  }

  const resetState = () => {
    const defaultValue = {
      type: TYPE_SURVEY_QUESTION.WEEKDAY_TYPE_SHIFT,
      data: [
        {
          weekKey: 'odd-week',
          typeWeek: WEEK_TYPE.ODD_WEEK,
          typeShiftValue: { mon: [], tue: [], wed: [], thu: [], fri: [], sat: [], sun: [] },
          type: TYPE_SURVEY_QUESTION.WEEKDAY_TYPE_SHIFT,
        },
        {
          weekKey: 'even-week',
          typeWeek: WEEK_TYPE.EVEN_WEEK,
          typeShiftValue: { mon: [], tue: [], wed: [], thu: [], fri: [], sat: [], sun: [] },
          type: TYPE_SURVEY_QUESTION.WEEKDAY_TYPE_SHIFT,
        },
      ],
    };

    setValue(defaultValue);
    setSurveyRecord({ ...surveyRecord, [questionId]: defaultValue });
  };

  useEffect(() => {
    if (surveyRecord[questionId]) {
      setValue(surveyRecord[questionId]);
    }
  }, [surveyRecord]);

  const onChangeTypeShiftValue = (weekdayKeyValue, typeShiftValue, weekKey) => {
    if (isDisabled || isExpired) {
      return;
    }
    const valueChangeIndex = value.data.findIndex((item) => item.weekKey === weekKey);
    let currentValue = value.data[valueChangeIndex]?.typeShiftValue[weekdayKeyValue];
    if (currentValue.includes(typeShiftValue)) {
      currentValue = currentValue.filter((item) => item !== typeShiftValue);
    } else {
      currentValue.push(typeShiftValue);
    }
    setValue((prev) => {
      const updateValue = [...prev.data];
      updateValue[valueChangeIndex].typeShiftValue = {
        ...updateValue[valueChangeIndex].typeShiftValue,
        [weekdayKeyValue]: currentValue,
      };
      survey[questionId] = { ...prev, data: updateValue };
      setSurveyRecord(survey);
      return { ...prev, data: updateValue };
    });
  };

  const handleInputChange = (e) => {
    setValue((prev) => {
      survey[questionId] = { ...prev, reason: e.target.value };
      setSurveyRecord(survey);
      return { ...prev, reason: e.target.value };
    });
  };

  return (
    <div className="weekday-type-shift__wrap">
      <div className="weekday-type-shift">
        <p>{t('select-type-shift-can-not-working')}</p>
        <div className="weekday-type-shift__body">
          {value.data.map((item) => {
            return (
              <WeekdayTypeShifTable
                key={uuidv4()}
                tableValue={item}
                onChangeTypeShiftValue={onChangeTypeShiftValue}
                weekKey={item.weekKey}
                isDisabled={isDisabled}
                isExpired={isExpired}
              />
            );
          })}
        </div>

        <div className="user-reason">
          <p>{t('arrangement-reason')}</p>
          <input
            disabled={isDisabled || isExpired}
            value={value.reason}
            onChange={handleInputChange}
          />
        </div>
      </div>
    </div>
  );
};

export default WeekdayTypeShift;
