import Swal from 'sweetalert2';
import { useLanguge } from 'Hooks/useLangugue';
import './styles.scss';

const Toast = Swal.mixin({
  toast: true,
  position: 'bottom',
  showConfirmButton: false,
  timer: 5000,
  background: '#000',
  iconColor: '#ffbb00',
  color: '#ffff',
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

export const STATUS = {
  success: 'success',
  error: 'error',
};

const RenderToast = ({ type, title }) => {
  const { t } = useLanguge();

  return Toast({ icon: type, title: `${title} ${t(type)}` });
};

export default RenderToast;
