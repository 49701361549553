import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';

import employeeAPI from '_apis/employee';
import axiosClient from 'axios/axiosClient';
import { Toast, showLoading } from 'helper/alert';
import { EDITABLE_COL, STARTING_CELL_EDIT } from './constants';
import SplitEmployee from './SplitEmployee/SplitEmployee';
import { isEmailValid, isPhoneValid } from 'helper/dateTime';
import { DeleteIcon, EditIcon, DoneIcon, SplitIcon, LeftArrowBrownIcon } from 'assets/img';
import { setEditedEmpAction } from 'redux/global/globalAction';
import { openModal } from '_components/Modal/slice';
import { SIDE_MENU } from '_components/CarlendarPlan/EmployeeV2/constants/modal';
import { selectAuth } from 'redux/auth/authSlice';

const ActionCol = ({
  handleDelete,
  rowIndex,
  empInfo,
  unitSelected,
  refetchEmployees,
  api,
  colApi,
}) => {
  const { isEditabled } = useSelector(selectAuth);

  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [isSplit, setIsSplit] = useState(false);
  const splitBtnRef = useRef(null);
  const { splitEmployee } = employeeAPI();

  const toggleEdit = (isEdit) => {
    setIsEdit(isEdit);
    EDITABLE_COL.forEach((col) => {
      colApi.getColumn(col).getColDef().editable = isEdit;
    });
  };

  const handleStartEdit = async () => {
    toggleEdit(true);

    // set default phone number to +47 if empty
    const rowData = api.getRowNode(rowIndex);
    if (!rowData.data.phone_number) {
      rowData.data.phone_number = '+47 ';
    }

    api.setFocusedCell(rowIndex, STARTING_CELL_EDIT);
    api.startEditingCell({
      rowIndex: rowIndex,
      colKey: STARTING_CELL_EDIT,
    });
  };

  const handleStopEdit = async () => {
    showLoading({ shouldShow: true, title: 'revert' });
    await refetchEmployees();
    showLoading({ shouldShow: false });
    setIsEdit(false);
    api.stopEditing();
  };

  const handleUpdate = async () => {
    // stop edit here to update newest changes to the
    api.stopEditing();
    api.startEditingCell({
      rowIndex: rowIndex,
      colKey: STARTING_CELL_EDIT,
    });

    const { data: rowData } = api.getRowNode(rowIndex);
    const { phone_number, email, job_percentage, isSplited = false, listGroupSplit } = rowData;

    const showErrorToast = async (title, key = STARTING_CELL_EDIT) => {
      Toast({
        icon: 'error',
        title: t(title),
      });
      // focus the error cell
      api.startEditingCell({
        rowIndex: rowIndex,
        colKey: key,
      });
    };

    const updateEmployee = async (url, data) => {
      showLoading({ shouldShow: true, title: 'update' });
      await axiosClient
        .post(url, data)
        .then(async () => {
          await refetchEmployees();
          showLoading({ shouldShow: false });
          api.stopEditing();
          setIsEdit(false);
          Toast({
            icon: 'success',
            title: `${t('update')} ${t('employee')} ${t('success')}`,
          });
        })
        .catch((error) => {
          console.log('error', error);
          showLoading({ shouldShow: false });
          showErrorToast('update-fail');
        });
    };

    if (email && !isEmailValid(email)) {
      showErrorToast('wrong-email-format', 'email');
      return;
    }

    if (phone_number) {
      const trimmedPhoneNumber = phone_number.trim();

      if (trimmedPhoneNumber === '+47') {
        rowData.phone_number = '';
      } else if (!isPhoneValid(trimmedPhoneNumber)) {
        showErrorToast('wrong-phone-format', 'phone_number');
        return;
      }
    }

    if (!job_percentage || +job_percentage <= 0) {
      showErrorToast('job-percentage-required', 'job_percentage');
      return;
    }

    if (!isSplited && listGroupSplit.find((group) => group.jobPercentage > job_percentage)) {
      showErrorToast('job-percentage-cannot-lower-than-split');
      return;
    }

    if (isSplited) {
      const { id, group_id, job_percentage, splitID, source_job_percentage } = rowData;

      if (job_percentage > source_job_percentage) {
        showErrorToast('splited-job-percentage-invalid');
        return;
      }

      showLoading({ shouldShow: true, title: 'update' });

      await splitEmployee({
        employeeID: id,
        groupID: group_id,
        jobPercentage: job_percentage,
        splitID,
      }).then(async () => {
        await refetchEmployees();
        showLoading({ shouldShow: false });
        Toast({
          icon: 'success',
          title: `${t('update')} ${t('employee')} ${t('success')}`,
        });
        dispatch(setEditedEmpAction(id));
      });
      return;
    }

    await updateEmployee('calendar-plan/employee/update', { employeeInfo: rowData });
    return;
  };

  const coord = splitBtnRef.current?.getBoundingClientRect();
  // disable edit when focus on another cell
  useEffect(() => {
    if (isEdit) {
      const handleCellFocus = () => {
        const focusedCell = api.getFocusedCell();
        if (focusedCell && focusedCell.rowIndex !== rowIndex) {
          handleStopEdit();
        }
      };

      const focusedCell = api.getFocusedCell();
      if (focusedCell && focusedCell.rowIndex === rowIndex) {
        api?.addEventListener('cellFocused', handleCellFocus);
      }

      return () => {
        api?.removeEventListener('cellFocused', handleCellFocus);
      };
    }
  }, [rowIndex, handleStopEdit, api]);
  return (
    <>
      <div className="emp-action">
        {/* <img src={isEdit ? DoneIcon : EditIcon} onClick={isEdit ? handleUpdate : handleStartEdit} /> */}
        {/* <img
          src={EditIcon}
          onClick={() => dispatch(openModal({ name: SIDE_MENU, data: empInfo }))}
        /> */}
        <img
          src={isEdit ? LeftArrowBrownIcon : DeleteIcon}
          onClick={isEdit && isEditabled ? handleStopEdit : () => handleDelete(empInfo)}
        />
        {/* {process.env.REACT_APP_NODE_ENV !== 'production' ? (
          <img src={SplitIcon} ref={splitBtnRef} onClick={() => setIsSplit(true)} />
        ) : null} */}
      </div>
      {isSplit &&
        ReactDOM.createPortal(
          <SplitEmployee
            employee={empInfo}
            setIsSplit={setIsSplit}
            isSplit={isSplit}
            coord={coord}
            splitBtnRef={splitBtnRef}
            unitSelected={unitSelected}
            refetchEmployees={refetchEmployees}
          />,
          document.body,
        )}
    </>
  );
};

export default ActionCol;
