import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DefaultFooter from 'navigators/DefaultFooter/DefaultFooter';
import { useCheckAuthentication } from 'Hooks/useCheckAuthentication';

import './style.scss';

import DefaultHeader from 'navigators/DefaultHeader/DefaultHeader';

import Content from 'navigators/Content';
import { useSelector } from 'react-redux';
import { selectGlobal } from 'redux/global/globalSlice';
import { ProductFruits } from 'react-product-fruits';
import { Cookies } from 'react-cookie';

export default function DefaultLayout() {
  const navigate = useNavigate();
  const path = useLocation().pathname;
  const [filterActive, setFilterActive] = useState(false);
  const { ignoreFilterPath } = useSelector(selectGlobal);
  const isAuthenticated = useCheckAuthentication();
  if (!isAuthenticated) {
    navigate('/login', { replace: true });
  }
  const cookies = new Cookies();

  const userInfo = {
    username: cookies.get('username') || '==REPLACE==', // REQUIRED - any unique user identifier,
    //will remove new date when not showing all the time
  };
  return (
    <div className="app-layout">
      <ProductFruits
        workspaceCode={process.env.REACT_APP_PRODUCT_FRUITS_WORKSPACE_CODE}
        language="nb"
        user={userInfo}
      />
      <div className="app-header">
        <DefaultHeader />
      </div>

      <div
        className={`app-body ${ignoreFilterPath.includes(path) ? 'ignore-filter' : ''} ${
          filterActive ? 'filter-active' : ''
        }`}
      >
        <Content />
      </div>
      <footer className="app-footer">
        <DefaultFooter />
      </footer>
    </div>
  );
}
