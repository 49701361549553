import React from 'react';
import { t } from 'i18next';

import { isEmailValid, isPhoneValid } from 'helper/dateTime';
import { SINGLE_CREATE } from '_components/CarlendarPlan/EmployeeV2/constants/modal';

import './styles.scss';

const InputField = ({
  label,
  type,
  name,
  placeholder,
  options = [],
  onChange,
  required,
  value,
  onBlur,
}) => (
  <label>
    {t(label)} {required && '*'}
    {type === 'select' ? (
      <select name={name} onChange={onChange} required={required}>
        <option value="">{t(placeholder)}</option>
        {options.map((option) => (
          <option key={option.id} value={option.name || option.role || option.id}>
            {option.name || option.role || option.type}
          </option>
        ))}
      </select>
    ) : (
      <input
        type={type}
        value={value}
        name={name}
        placeholder={t(placeholder)}
        onChange={onChange}
        onBlur={onBlur}
        required={required}
      />
    )}
  </label>
);

const SingleCreateEmp = ({ roleList, groupList, formData, setFormData, roleTypeList }) => {
  const data = formData[SINGLE_CREATE];
  const handleInputChange = (event) => {
    const { value, name } = event.target;

    if (name === 'job_percentage') {
      setFormData((prev) => ({
        ...prev,
        [SINGLE_CREATE]: {
          ...prev[SINGLE_CREATE],
          [name]: value > 100 ? 100 : value < 0 ? 0 : value,
        },
      }));
      return;
    }

    if (name === 'group') {
      setFormData((prev) => ({
        ...prev,
        [SINGLE_CREATE]: {
          ...prev[SINGLE_CREATE],
          group_id: groupList.find((group) => group.name == value)?.id,
          group: value,
        },
      }));
      return;
    }

    if (name === 'role') {
      setFormData((prev) => ({
        ...prev,
        [SINGLE_CREATE]: {
          ...prev[SINGLE_CREATE],
          role: value,
          role_id: roleList.find((role) => role.role == value)?.id,
        },
      }));
      return;
    }

    setFormData((prev) => ({
      ...prev,
      [SINGLE_CREATE]: {
        ...prev[SINGLE_CREATE],
        [name]: value,
      },
    }));
  };

  const validationFields = {
    email: (value) => (value !== '' && !isEmailValid(value) ? 'wrong-email-format' : null),
    phone_number: (value) =>
      value !== '' && value.trim() !== '+47' && !isPhoneValid(value) ? 'wrong-phone-format' : null,
  };

  const handleOnBlur = (e) => {
    const { value, name } = e.target;

    if (validationFields[name]) {
      setFormData((prev) => ({
        ...prev,
        [SINGLE_CREATE]: {
          ...prev[SINGLE_CREATE],
          error: {
            ...prev[SINGLE_CREATE].error,
            [name]: validationFields[name](value),
          },
        },
      }));
    }
  };

  return (
    <div className="create-single-emp">
      <p>{t('single-desc')}</p>
      <form>
        <div className="row">
          <InputField
            label="name"
            type="text"
            name="name"
            placeholder="name"
            onChange={handleInputChange}
            required
            value={data.name}
            onBlur={handleOnBlur}
          />
          <InputField
            label="email"
            type="email"
            name="email"
            placeholder="email"
            onChange={handleInputChange}
            value={data.email}
            onBlur={handleOnBlur}
          />
        </div>
        <div className="row">
          <InputField
            label="emp-id-number"
            type="text"
            name="emp_id_number"
            placeholder="Employee ID"
            onChange={handleInputChange}
            value={data.emp_id_number}
            onBlur={handleOnBlur}
          />
          <InputField
            label="salary"
            type="number"
            name="salary"
            placeholder="Salary"
            onChange={handleInputChange}
            value={data.salary}
            onBlur={handleOnBlur}
          />
        </div>
        <div className="row">
          <InputField
            label="group"
            type="select"
            name="group"
            placeholder="select-group"
            options={groupList}
            onChange={handleInputChange}
          />
          <InputField
            label="phone-number"
            type="tel"
            name="phone_number"
            placeholder="phone-number"
            onChange={handleInputChange}
            value={data.phone_number}
            onBlur={handleOnBlur}
          />
        </div>
        <div className="row">
          <InputField
            label="job-percentage"
            type="number"
            name="job_percentage"
            placeholder="job-percentage"
            onChange={handleInputChange}
            required
            value={data.job_percentage}
          />
          <InputField
            label="role"
            type="select"
            name="role"
            placeholder="select-role"
            options={roleList}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="row">
          <InputField
            label="role-type"
            type="select"
            name="role_type"
            placeholder="select-role-type"
            options={roleTypeList}
            onChange={handleInputChange}
            required
          />
        </div>
      </form>
    </div>
  );
};

export default SingleCreateEmp;
