import moment from 'moment';
import { t } from 'i18next';
import { EVENING_SHIFT_VALUE, LONG_SHIFT_VALUE, NIGHT_SHIFT_VALUE, maxHourWorking } from 'constant';
import { isTimeRangeOverlap } from 'helper/dateTime';

const listHardRuleKey = [
  'checkRole',
  'checkingWorkingInRow',
  'checkTimeOffBetweenShift',
  'checkWorkingOver54Hours',
  'listWorkingMoreThanThreeWeekend',
  'checkHourOffPerWeek',
  'listCheckingF1',
];
const ignoreHardAndSoftRule = [
  'employeeData',
  'employeeDataWeekend',
  'listTypeShift',
  'employeeWorkingWeekendAndHoliday',
];

const startEveSupProperty = 'start_time_evening_HTA';
const endEveSupProperty = 'end_time_evening_HTA';
const eveCostProperty = 'cost_of_evening_supplement_HTA';
const eveParam = 4.6;

const startNightSupProperty = 'start_time_evening_KS';
const endNightSupProperty = 'end_time_evening_KS';
const nightCostProperty = 'cost_of_night_supplement_KS';
const nightParam = 0.25;

const startWeekendProperty = 'start_time_weekend_HTA';
const endWeekendProperty = 'end_time_weekend_HTA';
const costWeekendProperty = 'cost_of_weekend_supplement_HTA';
const weekendParam = 0.22;

const costHolidayProperty = 'cost_of_holiday_supplement';
const holidayParam = 1.33;

const isWeekend = (date) => {
  return moment(date).weekday() === 0 || moment(date).weekday() === 6;
};

export const calculateBudgetData = ({ rotationCost, employeeData, numWeek, publicHoliday }) => {
  const result = {
    nightSupplement: 0,
    weekendSupplement: 0,
    holidaySupplement: 0,
    eveSupplement: 0,
  };

  //Calculate Eve Supplement
  const startEveSup = rotationCost[startEveSupProperty]?.value;
  const endEveSup = rotationCost[endEveSupProperty]?.value;
  const costEveSup = rotationCost[eveCostProperty]?.value || 0;

  //Calculate Night Supplement
  const startNightSup = rotationCost[startNightSupProperty]?.value;
  const endNightSup = rotationCost[endNightSupProperty]?.value;
  const costNightSup = rotationCost[nightCostProperty]?.value || 0;

  //Calculate Weekend Supplement
  const startWeekendSup = rotationCost[startWeekendProperty]?.value;
  const endWeekendSup = rotationCost[endWeekendProperty]?.value;
  const costWeekendSup = rotationCost[costWeekendProperty]?.value || 0;

  //Calculate Holiday Supplement

  const costHolidaySup = rotationCost[costHolidayProperty]?.value || 0;

  if (!numWeek || !rotationCost || !employeeData) {
    return result;
  }
  for (const employee of employeeData) {
    if (isNaN(employee.employeeId)) continue;
    const employeeSalary = employee.salary;
    const taskAssgined = employee.task_arr;
    let numLateAndNightShift = 0;
    let numWeekendShift = 0;
    let numEveShift = 0;
    let holidayHour = 0;
    for (const date of Object.keys(taskAssgined)) {
      const dateData = taskAssgined[date][0];

      if (isWeekend(date)) {
        numWeekendShift++;
      }
      if (publicHoliday.includes(date)) {
        const startTime = moment(dateData.fromTime, 'HH:mm:ss');
        const endTime = moment(dateData.endTime, 'HH:mm:ss');
        const duration = moment.duration(endTime.diff(startTime));
        let durationInHours = duration.asMinutes() / 60;

        if (startTime.isAfter(endTime)) {
          durationInHours = 24 - Math.abs(duration.asMinutes() / 60);
        }
        holidayHour += durationInHours;
      }
    }

    const startTimeNightSup = moment(startNightSup, 'HH:mm');
    const endTimeNightSup = moment(endNightSup, 'HH:mm').add(1, 'days');
    const diffNightSup = moment.duration(endTimeNightSup.diff(startTimeNightSup)).asMinutes();
    numLateAndNightShift = Object.keys(taskAssgined).reduce((curr, date) => {
      const dateData = taskAssgined[date][0];
      const typeValue = dateData.typeValue;
      if (typeValue > LONG_SHIFT_VALUE) {
        return curr;
      }

      const fromTime = dateData.fromTime?.split('.')[0];
      const endTime = dateData.endTime?.split('.')[0];
      const isInRange = isTimeRangeOverlap(fromTime, endTime, startNightSup, endNightSup);
      if (isInRange) {
        return curr + 1;
      }
      return curr++;
    }, 0);
    if (diffNightSup) {
      //Total night hour = total num late and night shift * (diff of start and end suppliment)
      const nightHour = numLateAndNightShift * (diffNightSup / 60);
      let rate = costNightSup || 0;
      if (employeeSalary) {
        const salaryRate = (employeeSalary / maxHourWorking) * nightParam;
        if (salaryRate > rate) {
          // Get bigger rate
          rate = salaryRate;
        }
      }

      const empNightSupplement = (53.5 * (nightHour * rate)) / (numWeek * numWeek);
      result.nightSupplement += empNightSupplement;
    }

    //Calculate Sunday Supplement
    const startTimeWeekendSup = moment(startWeekendSup, 'HH:mm');
    const endTimeWeekendSup = moment(endWeekendSup, 'HH:mm');
    const diffWeekendSup = moment.duration(endTimeWeekendSup.diff(startTimeWeekendSup)).asMinutes();

    if (diffWeekendSup) {
      //Total night hour = total num late and night shift * (diff of start and end suppliment)
      const weekendHour = numWeekendShift * (diffWeekendSup / 60);
      let rate = costWeekendSup || 0;
      if (employeeSalary) {
        const salaryRate = (employeeSalary / maxHourWorking) * weekendParam;
        if (salaryRate > rate) {
          // Get bigger rate
          rate = salaryRate;
        }
      }

      const empWeekendSupplement = (53.5 * (weekendHour * rate)) / (numWeek * numWeek);
      result.weekendSupplement += empWeekendSupplement;
    }

    //Calculate Holiday Supplement

    if (holidayHour && employeeSalary) {
      const empHolidaySupplement =
        (holidayParam * costHolidaySup * (employeeSalary / maxHourWorking)) / holidayHour;
      result.holidaySupplement += empHolidaySupplement;
    }

    //Calculate Eve Supplement
    const startTimeEveSup = moment(startEveSup, 'HH:mm');
    const endTimeEveSup = moment(endEveSup, 'HH:mm');
    const diffEveSup = moment.duration(endTimeEveSup.diff(startTimeEveSup)).asMinutes();
    numEveShift = Object.keys(taskAssgined).reduce((curr, date) => {
      const dateData = taskAssgined[date][0];
      const typeValue = dateData.typeValue;
      if (typeValue > LONG_SHIFT_VALUE) {
        return curr;
      }

      const fromTime = dateData.fromTime?.split('.')[0];
      const endTime = dateData.endTime?.split('.')[0];
      const isInRange = isTimeRangeOverlap(fromTime, endTime, startEveSup, endEveSup);

      if (isInRange) {
        return curr + 1;
      }
      return curr++;
    }, 0);
    if (diffEveSup) {
      const eveHour = numEveShift * (diffEveSup / 60);
      const empEveSup = (eveHour / numWeek) * eveParam * costEveSup || 0;
      result.eveSupplement += empEveSup;
    }
  }
  return result;
};
export const calculateQualityValue = ({
  vakantData,
  roleList,
  dashboardData,
  surveyDashboardData,
  numWeek,
}) => {
  const result = {
    totalVakantHrs: 0,
    weekendHours: 0,
    workSetup: 100,
    softRule: 100,
    surveyPercent: 100,
    role: { total: 0, none: 0 },
  };
  try {
    const listSoftRuleKey = Object.keys(dashboardData).filter(
      (key) => !listHardRuleKey.includes(key) && !ignoreHardAndSoftRule.includes(key),
    );
    const empSurveyPercent = [];
    for (const empSurvey of surveyDashboardData) {
      let totalCase = Object.keys(empSurvey).filter((key) => key !== 'employeeID').length;
      let passCase = totalCase;
      for (const surveyKey of Object.keys(empSurvey)) {
        const data = empSurvey[surveyKey];
        if (data && Array.isArray(data) && data.length) {
          passCase--;
        }
      }
      empSurveyPercent.push((passCase / totalCase) * 100);
    }
    if (empSurveyPercent.length) {
      //Avg survey percent
      result.surveyPercent =
        empSurveyPercent.reduce((acc, percent) => {
          return acc + percent;
        }, 0) / empSurveyPercent.length;
    }

    const numEmp = dashboardData.employeeData?.filter((item) => item.name).length;
    const totalWorkSetupCase = numEmp * listHardRuleKey.length;
    for (const workSetupKey of listHardRuleKey) {
      const data = dashboardData[workSetupKey].filter((item) => !isNaN(item.employeeId));
      const workSetupPercent = (data.length / totalWorkSetupCase) * 100;
      result.workSetup -= workSetupPercent;
    }

    const totalSoftRuleCase = numEmp * listSoftRuleKey.length;
    let passCaseSoftRule = totalSoftRuleCase;
    for (const softRuleKey of listSoftRuleKey) {
      const data = dashboardData[softRuleKey].filter((item) => !isNaN(item.employeeId));
      const softRuleWeek = {};
      for (const emp of data) {
        if (!softRuleWeek[emp.employeeId]) {
          softRuleWeek[emp.employeeId] = new Set().add(moment(emp.date).weeks());
        } else {
          softRuleWeek[emp.employeeId].add(moment(emp.date).weeks());
        }
      }
      for (const empID of Object.keys(softRuleWeek)) {
        const missCasePercent = (softRuleWeek[empID].size / numWeek) * 100;
        if (missCasePercent > 20) {
          passCaseSoftRule--;
        }
      }
    }
    result.softRule = (passCaseSoftRule / totalSoftRuleCase) * 100;
    if (roleList && roleList.length) {
      for (const role of roleList) {
        result.role[role.id] = 0;
      }
    }

    for (const vakant of vakantData.data) {
      const taskList = vakant.task_arr;
      for (const date of Object.keys(taskList)) {
        const taskData = taskList[date][0];
        const roleID = taskData.roleID;
        const startTime = moment(taskData.fromTime, 'HH:mm:ss');
        const endTime = moment(taskData.endTime, 'HH:mm:ss');
        const duration = moment.duration(endTime.diff(startTime));
        let durationInHours = duration.asMinutes() / 60;

        if (startTime.isAfter(endTime)) {
          durationInHours = 24 - Math.abs(duration.asMinutes() / 60);
        }
        if (isWeekend(date)) {
          result.weekendHours += durationInHours;
        }

        if (roleID) {
          if (result.role[roleID]) {
            result.role[roleID] += 1;
          } else {
            result.role[roleID] = 1;
          }
        } else {
          result.role.none += 1;
        }
        result.role.total += 1;

        result.totalVakantHrs += durationInHours;
      }
    }
    return result;
  } catch (error) {
    if (error) {
      return result;
    }
  }
};

export const calculateQualityChartData = ({ qualityData, roleList }) => {
  const roleData = qualityData.role;
  const total = roleData.total;
  const chartData = [];
  for (const roleID of Object.keys(roleData)) {
    const roleFound = roleList.find((role) => +role.id === +roleID);
    if (roleFound) {
      const chartValue = {
        label: roleFound.role,
        value: ((roleData[roleID] / total) * 100).toFixed(2),
      };
      if (chartValue.value > 0) {
        chartData.push(chartValue);
      }
    } else {
      if (roleID === 'none') {
        const chartValue = {
          label: t('none-layer'),
          value: ((roleData.none / total) * 100).toFixed(2),
        };
        if (+chartValue.value > 0) {
          chartData.push(chartValue);
        }
      }
    }
  }
  return chartData;
};
