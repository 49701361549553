import React, { createContext, useContext, useState } from 'react';

const EditingContext = createContext();

export const useEditingContext = () => useContext(EditingContext);

export const EditingProvider = ({ children }) => {
  const [trigger, setTrigger] = useState(false);
  const [isEditing, setEditing] = useState(false);

  return (
    <EditingContext.Provider value={{ trigger, setTrigger, isEditing, setEditing }}>
      {children}
    </EditingContext.Provider>
  );
};
