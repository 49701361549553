import React, { useMemo } from 'react';
import { t } from 'i18next';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useDispatch } from 'react-redux';

import { updateFilter } from 'redux/generateTableFilter/slice';

const animatedComponents = makeAnimated();

const DropdownSelect = ({ options, filters }) => {
  const dispatch = useDispatch();
  const style = {
    control: (base, state) => ({
      ...base,
      boxShadow: 'none',
      borderColor: '#F5F5F5',
      borderRadius: 10,
    }),
  };

  const handleChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    dispatch(updateFilter({ key: 'role', value: selectedValues }));
  };

  const defaultValues = useMemo(() => {
    return filters?.role.map((role) => ({ value: role, label: role }));
  }, [filters?.role]);
  const filterOptions = options.map((item) => ({ value: item, label: item }));

  return (
    <Select
      isMulti
      className="custom-select"
      styles={style}
      value={defaultValues}
      components={animatedComponents}
      closeMenuOnSelect={false}
      placeholder={`${t('select')} ${t('role-low')}`}
      onChange={handleChange}
      options={filterOptions}
    />
  );
};

export default DropdownSelect;
