import { loadSession, loadState } from 'helper/localStorage';
import { Cookies } from 'react-cookie';
export const useCheckAuthentication = () => {
  const cookies = new Cookies();
  const haveToken = cookies.get('accessToken')?.length > 0;

  let isAuthenticated = haveToken;
  return isAuthenticated;
};

export const useCheckAuthenticationForEmployee = () => {
  const cookies = new Cookies();

  const haveToken = cookies.get('employeeToken')?.length > 0;
  let isAuthenticated = haveToken;
  return isAuthenticated;
};
