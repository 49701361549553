import axiosClient from 'axios/axiosClient';
import { useQuery } from 'react-query';

export const useGeneralSettings = ({ unitCode }) => {
  return useQuery(
    ['general-settings', unitCode],
    async () => {
      const response = await axiosClient.get('calendar-plan/general-settings', {
        params: { unitCode },
      });
      return response.data;
    },

    {
      initialData: [],
      enabled: !!unitCode,
    },
  );
};

export const useGeneralSettingsByKey = ({ unitCode, questionKey }) => {
  return useQuery(
    ['general-settings-by-key', unitCode, questionKey],
    async () => {
      const response = await axiosClient.post('calendar-plan/general-settings/by-key', {
        questionKey,
        unitCode,
      });
      return response;
    },

    {
      initialData: {},
      enabled: !!unitCode && !!questionKey,
      questionKey,
    },
  );
};
