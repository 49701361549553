import { Toast } from 'helper/alert';
import commonChecking from './commonChecking';
import hardRuleChecking from './hardRuleChecking';
import { t } from 'i18next';
import surveyRuleChecking from './surveyRuleChecking';
import {
  DAY_SHIFT_VALUE,
  EVENING_SHIFT_VALUE,
  LONG_SHIFT_VALUE,
  NIGHT_SHIFT_VALUE,
  PER_POSITION_CATEGORY,
} from 'constant';
import weekendChecking from './weekendChecking';
import { calculateHrPerWeek } from 'helper/calculateTableData';
import { updateSummary } from './updateShift';
import { calculateHardAndSoftRuleData, calculateSurveyData } from './calculateData';
import { getAllDateInWeek } from 'helper/dateTime';

export const createAssignShift = ({
  employeeID,
  taskData,
  planSelected,
  cleanVakantID,
  vakantID,
  isOffShift,
}) => {
  const { date, planDetailId, taskId, timelotId, hourAssigned, versionID } = taskData;
  const updateData = {
    ...taskData,
    date,
    employee_id: employeeID,
    plan_id: planSelected.id,
    plan_detail_id: planDetailId,
    task_id: taskId,
    time_lot_id: timelotId,
    hour_assigned: hourAssigned,
    version_id: planSelected.versionID,
    is_clean: !isOffShift,
    cleanVakantID,
    vakantID,
  };
  return updateData;
};
export const cleanSingleVakant = async ({
  listTableApi,
  listEmpData,
  data,
  workingEnvSetup,
  surveyData,
  setEmployeeData,
  roleList,
  groupList,
  planSelected,
  weekRange,
  taskList,
  taskArr,
  fromDate,
  endDate,
  summarySelectedView,
  amlInformation,
  publicHoliday,
  setCleanData,
  handleClose,
  isCleanAll,
}) => {
  if (!data) return;
  let employeeDataUpdate = [...listEmpData];
  const vakantID = data.employeeId;
  const vakantIndex = employeeDataUpdate.findIndex((item) => item.employeeId === vakantID);
  const vakantUpdate = employeeDataUpdate[vakantIndex];
  let shiftClean = 0;
  const cleanedVakantShiftID = [];
  const assignList = [];
  const summaryApi = listTableApi.summary;

  for (const date of Object.keys(data.task_arr)) {
    if (commonChecking.isWeekend(date)) continue;
    const taskData = { ...data.task_arr[date][0], date };
    const taskType = taskData.taskType;
    const layerName = taskData.layerName;
    const employeeMatched = findEmployeeMatched({
      listEmpData,
      taskData,
      workingEnvSetup,
      surveyData,
      roleList,
      groupList,
      amlInformation,
      publicHoliday,
    });

    if (employeeMatched) {
      const empIndex = employeeDataUpdate.findIndex(
        (item) =>
          +item.employeeId === +employeeMatched.employeeId ||
          item.employeeId === employeeMatched.employeeId,
      );
      const employeeUpdate = employeeDataUpdate[empIndex];
      const employeeTaskArr = { ...employeeUpdate.task_arr };

      //Try to checking if the cleaned shift will be remove off shift will be checking and add another F1 shift
      const oldTask = employeeTaskArr[date];
      if (oldTask && oldTask.length) {
        const oldTaskData = oldTask[0];
        if (commonChecking.isOffShift(oldTaskData)) {
          const allDateInWeek = getAllDateInWeek(date);
          const isHaveOffShiftInWeek = allDateInWeek.find((dateChecking) => {
            if (dateChecking === date) {
              return false;
            }
            if (employeeTaskArr[dateChecking] && employeeTaskArr[dateChecking].length) {
              return commonChecking.isOffShift(employeeTaskArr[dateChecking][0]);
            }
            return false;
          });
          if (!isHaveOffShiftInWeek) {
            const listDateOff = allDateInWeek.filter((dateOff) => !employeeTaskArr[dateOff]);

            for (const dateOff of listDateOff) {
              const isValidF1 = hardRuleChecking.checkValidF1({
                dateOff,
                employeeTaskList: employeeTaskArr,
                listDateOff: employeeUpdate.dateOff,
                listPublicHoliday: publicHoliday,
                timeOffWeek: amlInformation.minimumHourOffWeek,
              });
              if (isValidF1) {
                const offAssignShift = createAssignShift({
                  employeeID: employeeMatched.employeeId,
                  taskData: { ...oldTaskData, date: dateOff },
                  planSelected,
                  cleanVakantID: taskData.vakantShiftID,
                  vakantID,
                  isOffShift: true,
                });
                assignList.push(offAssignShift);
                employeeTaskArr[dateOff] = [{ ...oldTaskData, date: dateOff }];
                break;
              }
            }
          }
        }
      }

      //Remove olf shift if it  is off shift
      delete employeeTaskArr[date];

      const newTask = {
        ...taskData,
        isAssignByCleanVakant: true,
        cleanVakantID: taskData.vakantShiftID,
        vakantID,
        isClean: true,
        employee_id: employeeMatched.employeeId,
      };
      cleanedVakantShiftID.push(taskData.vakantShiftID);
      const assignShift = createAssignShift({
        employeeID: employeeMatched.employeeId,
        taskData,
        planSelected,
        cleanVakantID: taskData.vakantShiftID,
        vakantID,
        isOffShift: false,
      });
      assignList.push(assignShift);
      delete newTask.vakantShiftID;
      delete newTask.reasonKey;
      employeeTaskArr[date] = [newTask];
      employeeUpdate.task_arr = employeeTaskArr;
      employeeUpdate.allocatedHours += newTask.hourAssigned;
      employeeUpdate.allocatedHoursDeviation += newTask.hourAssigned;

      vakantUpdate.allocatedHours -= newTask.hourAssigned;

      employeeUpdate.deviation += newTask.hourAssigned;
      vakantUpdate.deviation -= newTask.hourAssigned;

      switch (taskType) {
        case DAY_SHIFT_VALUE:
          employeeUpdate.dayShift++;
          vakantUpdate.dayShift && vakantUpdate.dayShift--;
          updateSummary(
            summaryApi,
            summarySelectedView === PER_POSITION_CATEGORY && layerName
              ? `day${layerName}`
              : 'dayShift',
            summarySelectedView === PER_POSITION_CATEGORY && layerName
              ? `day${layerName}-${date}`
              : `dayShift-${date}`,
            date,
            1,
          );
          break;
        case EVENING_SHIFT_VALUE:
          employeeUpdate.lateShift++;
          vakantUpdate.lateShift && vakantUpdate.lateShift--;
          updateSummary(
            summaryApi,
            summarySelectedView === PER_POSITION_CATEGORY && layerName
              ? `late${layerName}`
              : 'lateShift',
            summarySelectedView === PER_POSITION_CATEGORY && layerName
              ? `late${layerName}-${date}`
              : `lateShift-${date}`,

            date,
            1,
          );
          break;
        case NIGHT_SHIFT_VALUE:
          employeeUpdate.nightShift++;
          vakantUpdate.nightShift && vakantUpdate.nightShift--;
          updateSummary(
            summaryApi,
            summarySelectedView === PER_POSITION_CATEGORY && layerName
              ? ` night${layerName}`
              : 'nightShift',
            summarySelectedView === PER_POSITION_CATEGORY && layerName
              ? `night${layerName}-${date}`
              : `nightShift-${date}`,
            date,
            1,
          );
          break;
        case LONG_SHIFT_VALUE:
          employeeUpdate.longShift++;
          vakantUpdate.longShift && vakantUpdate.longShift--;
          updateSummary(
            summaryApi,
            summarySelectedView === PER_POSITION_CATEGORY && layerName
              ? ` long-shift${layerName}`
              : 'long-shift',
            summarySelectedView === PER_POSITION_CATEGORY && layerName
              ? `long-shift${layerName}-${date}`
              : `long-shift-${date}`,
            date,
            1,
          );
          break;

        default:
          employeeUpdate.otherShift++;
          vakantUpdate.otherShift && vakantUpdate.otherShift--;

          break;
      }
      if (vakantUpdate.task_arr[date]) {
        vakantUpdate.task_arr[date][0].isDeleted = true;
      }
      // delete vakantUpdate.task_arr[date];
      shiftClean++;
    }
  }
  if (!Object.keys(vakantUpdate.task_arr).length) {
    employeeDataUpdate.splice(vakantIndex, 1);
  }

  if (shiftClean > 0) {
    updateTableData({
      listTableApi,
      employeeDataUpdate,
      weekRange,
      taskList,
      taskArr,
      surveyData,
      fromDate,
      endDate,
      publicHoliday,
      amlInformation,
    });

    setCleanData({ cleanedVakantShiftID, assignList });
    return { cleanedVakantShiftID, assignList };
  } else {
    if (!isCleanAll) {
      Toast({ title: `${t('no-shift-cleaned')}`, icon: 'info' });
    }
    handleClose();
    return { cleanedVakantShiftID: [], assignList: [] };
  }
};
export const updateTableData = ({
  listTableApi,
  employeeDataUpdate,
  weekRange,
  taskList,
  taskArr,
  surveyData,
  fromDate,
  endDate,
  amlInformation,
  publicHoliday,
}) => {
  const listTableUpdate = ['empInfo', 'taskList', 'dashboard'];
  for (const table of Object.keys(listTableApi)) {
    if (!listTableUpdate.includes(table)) continue;
    const tableApi = listTableApi[table].api;
    if (tableApi.forEachNode) {
      tableApi.forEachNode((row) => {
        const rowNewValue = employeeDataUpdate.find(
          (item) => item.employeeId === row.data.employeeId,
        );

        row.updateData(rowNewValue);
      });
    }
  }

  //Update Dashboard table
  const dashboardApi = listTableApi['dashboard'].api;

  dashboardApi.forEachNode((row) => {
    const reCalculateSurveyRule = calculateSurveyData(
      row.data,
      taskArr,
      surveyData,
      fromDate,
      endDate,
    );
    const reCalculateHardAndSoftRule = calculateHardAndSoftRuleData(
      row.data,
      taskArr,
      amlInformation,
      publicHoliday,
      fromDate,
      endDate,
    );
    if (reCalculateHardAndSoftRule) {
      for (const key of Object.keys(reCalculateHardAndSoftRule)) {
        if (reCalculateHardAndSoftRule[key] && Array.isArray(reCalculateHardAndSoftRule[key])) {
          const isNotValid = reCalculateHardAndSoftRule[key].find(
            (item) => item.employeeId === row.data.employeeId,
          );
          try {
            row.setDataValue(key, !!isNotValid);
          } catch (error) {
            console.log(key);
          }
        }
      }
    }

    if (reCalculateSurveyRule) {
      for (const key of Object.keys(reCalculateSurveyRule)) {
        if (reCalculateSurveyRule[key] && Array.isArray(reCalculateSurveyRule[key])) {
          const isNotValid = reCalculateSurveyRule[key].find(
            (item) => item.employeeID === row.data.employeeId,
          );
          try {
            row.setDataValue(key, !!isNotValid);
          } catch (error) {
            console.log(key);
          }
        }
      }
    }
  });

  //Update Hour per week table
  const newHrPerWeekData = calculateHrPerWeek({ data: employeeDataUpdate, weekRange, taskList });
  const hrPerWeekApi = listTableApi['hrPerWeek'].api;
  hrPerWeekApi.forEachNode((row) => {
    const rowNewValue = newHrPerWeekData.find((item) => item.employeeId === row.data.employeeId);

    row.updateData(rowNewValue);
  });
};
export const findEmployeeMatched = ({
  listEmpData,
  taskData,
  workingEnvSetup,
  surveyData,
  roleList,
  groupList,
  checkingPareShift,
  checkingEmployee,
  loopedList,
  prevShiftType,
  prevShift,
  amlInformation,
  publicHoliday,
}) => {
  let employeeMatched;
  const { date: taskDate } = taskData;
  for (const employee of listEmpData) {
    if (checkingPareShift && employee.employeeId !== checkingEmployee.employeeId) {
      continue;
    }
    if (loopedList?.includes(employee.employeeId)) {
      continue;
    }
    const listDateOff = employee.dayOff;

    const workingDateList = [];
    for (const workingDay of Object.keys(employee.task_arr)) {
      if (commonChecking.isHaveShiftOnDate(workingDay, employee.task_arr)) {
        workingDateList.push(workingDay);
      }
    }
    const deviation = employee.deviation;
    const employeeGroupID = employee.groupID;
    const employeeRoleID = employee.roleId;
    if (
      workingDateList.includes(taskDate) ||
      listDateOff?.includes(taskDate) ||
      !employee.name ||
      isNaN(employee.employeeId)
    ) {
      continue;
    }

    //Checking condition
    let isValidTypeShift = true;
    let isTooMuchShiftConsecutive = true;
    let isValidRoleAndGroup = true;
    let isValidDeviation = true;
    let isValidTimeOffBetweenShift = true;
    let isValidSingleNightShift = true;
    let isValidNotWoringDayAfterNightShift = true;
    let isValidShiftAfterWorkingNightAndLongWeekend = true;
    let isValidTimeInWeek = true;
    let isHaveF1InWeek = true;

    if (surveyData) {
      const employeeSurveyRecord = surveyData.find(
        (item) => +item.employeeID === +employee.employeeId,
      );
      isValidTypeShift = surveyRuleChecking.checkingTypeShift({
        employeeSurveyRecord: employeeSurveyRecord ? employeeSurveyRecord.surveyRecord : [],
        taskData,
        employee,
      });
    }

    if (!isValidTypeShift) {
      continue;
    }

    const employeeTaskList = employee.task_arr;
    //Checking consecutive shift
    isTooMuchShiftConsecutive = hardRuleChecking.checkIsTooMuchShiftConsecutive({
      taskData,
      employeeTaskList,
      workingEnvSetup,
      prevShiftType,
      checkingPareShift,
    });
    if (!isTooMuchShiftConsecutive) {
      continue;
    }

    //Checking Role And Group
    isValidRoleAndGroup = hardRuleChecking.checkingRoleAndGroup({
      taskData,
      employeeGroupID,
      employeeRoleID,
      roleList,
      employeePosition: employee.employeePosition,
    });
    if (!isValidRoleAndGroup) {
      continue;
    }

    //Checking devliation
    isValidDeviation = hardRuleChecking.checkingEnoughTime({
      taskData,
      deviation,
      workingEnvSetup,
    });

    if (!isValidDeviation) {
      continue;
    }

    //Checking Time off between
    isValidTimeOffBetweenShift = hardRuleChecking.checkingTimeOffBetweenShift({
      taskData,
      employeeTaskList,
      workingEnvSetup,
    });

    if (!isValidTimeOffBetweenShift) {
      continue;
    }

    //Checking singele night shift
    isValidSingleNightShift = hardRuleChecking.checkingSingleNightShift({
      taskData,
      employeeTaskList,
    });

    if (!isValidSingleNightShift) {
      continue;
    }
    //Checking not working day shift after night shift
    isValidNotWoringDayAfterNightShift = hardRuleChecking.checkingNotWorkingDayShiftAfterNightShift(
      {
        taskData,
        employeeTaskList,
      },
    );

    if (!isValidNotWoringDayAfterNightShift) {
      continue;
    }

    //Checking off Monday after working night or long shift at weekend
    isValidShiftAfterWorkingNightAndLongWeekend =
      weekendChecking.checkingOffAfterWorkingNightAndLongShiftWeekend({
        taskData,
        employeeTaskList,
      });

    if (!isValidShiftAfterWorkingNightAndLongWeekend) {
      continue;
    }

    //Checking Working time in week
    isValidTimeInWeek = hardRuleChecking.checkingTimeInWeek({
      taskData,
      employeeTaskList,
      amlInformation,
      prevShift,
    });
    if (!isValidTimeInWeek) {
      continue;
    }

    isHaveF1InWeek = hardRuleChecking.checkingIfHaveF1InWeek({
      taskData,
      employeeTaskList,
      amlInformation,
      prevShift,
      publicHoliday,
      listDateOffOfEmployee: employee.dateOff,
    });
    if (employeeMatched) {
      if (deviation < employeeMatched.deviation) {
        employeeMatched = employee;
      }
    } else {
      employeeMatched = employee;
    }
  }

  return employeeMatched;
};
