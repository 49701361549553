import React, { forwardRef, useEffect, useRef } from 'react';
import './CustomTooltip.scss';

const CustomTooltip = forwardRef(({ children }, _) => {
  const tooltipRef = useRef(null);
  useEffect(() => {
    if (tooltipRef) {
      const tooltip = tooltipRef.current;
      const tableDom = document.getElementById('ag-wrap');
      const parentRect = tableDom.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      if (parentRect.bottom - tooltipRect.bottom < 0) {
        const newTop = parentRect.bottom - tooltipRect.bottom;
        tooltip.style.top = `${newTop}px`;
      }
      if (parentRect.right - tooltipRect.right < 0) {
        const newRight = parentRect.right - tooltipRect.right;
        tooltip.style.right = `${-newRight / 2}px`;
      }
    }
  }, [tooltipRef]);

  return (
    <div className="custom-tooltip__wrap" ref={tooltipRef}>
      <div className="tooltip">
        <div className="tooltip-text">{children}</div>
      </div>
    </div>
  );
});

export default CustomTooltip;
