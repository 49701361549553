import React, { useState, useEffect, useRef } from 'react';
import Select, { components } from 'react-select';
import { t } from 'i18next';
import { ChevronDownBrownIcon, User } from 'assets/img';

const customStyles = () => ({
  control: (provided, state) => ({
    ...provided,
    minWidth: '310px',
    backgroundColor: '#F5F3F4',
    borderRadius: '4px',
    boxShadow: 'none',
    padding: '4px 8px',
    fontSize: '14px',
    fontFamily: 'SatoshiMedium',
    border: 'none',
    color: '#4E1830'
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: '#4E1830',
    }
  },
  menu: (provided) => ({
    ...provided,
    width: 382,
    right: 0
  })
});

const CustomOption = ({ innerProps, label, data }) => (
  <div className='version-select-custom-option' {...innerProps}>
    <div  className='version-select-custom-option-wrapper'>
      <div className='version-select-custom-option-time'>
        {
          data.isLatestVersion  && <span>{`${t('last')} - `}</span>
        }
        {data.datetime}
      </div>
      <div className='version-select-custom-option-user'>
        {data.modifiedBy && <div className='version-select-custom-option-user-icon'><img src={User} /></div >}
        <div className='version-select-custom-option-user-text' >{data.modifiedBy}</div>
      </div>
    </div>
  </div>
);

const CustomMenu = (props) => {
  return (
    <div>
      <components.Menu {...props}>
        <div style={{ padding: 10 }}>
          <div className='version-select-custom-menu'>
            {t('version-history')}
          </div>
          {props.children}
        </div>
      </components.Menu >
    </div>
  );
};

const VersionHistorySelect = (props) => {
  return (
    <div className='version-select-wrapper'>
      <div
        className='version-select'
      />
      <Select
        placeholder={t('last-answer')}
        options={props.options}
        styles={customStyles()}
        isSearchable={false}
        components={{
          Option: CustomOption,
          DropdownIndicator: () => <img src={ChevronDownBrownIcon} height={16} />,
          Menu: CustomMenu,
        }}
        onChange={props.onChange}
        value={props.value}
      />
    </div>
  );
};

export default VersionHistorySelect;
