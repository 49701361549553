import React from 'react';
import { InfoCircleIcon, TrendUpIcon } from 'assets/img';
import { t } from 'i18next';

const AvailableWeekendShift = ({ title, value, unit, percent, isIncrease, isBalance }) => {
  return (
    <div className="available-weekend__wrap">
      <div className="available-weekend__title">
        <h5>
          {t(title)} <img src={InfoCircleIcon} />
        </h5>
      </div>
      <div className="available-weekend__content">
        <div className="available-weekend__content-item">
          <div className="available-weekend__content-item__value">
            <span className="value">{value}</span>
            <span className="symbol">{t(unit)}</span>
          </div>
        </div>
      </div>
      <div className="available-weekend__footer">
        {/* <div className={`value__wrap ${isIncrease ? 'isIncrease' : ''}`}>
          {isIncrease && <img src={TrendUpIcon} />}
          {isBalance && <span className="icon"> - </span>}

          <span className={`value `}>{percent}</span>
        </div>
        <div className="content">fra forrige versjon</div> */}
      </div>
    </div>
  );
};

export default AvailableWeekendShift;
