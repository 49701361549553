import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  empInfo: true,
  taskList: true,
  dashboard: true,
};

const showTableSlice = createSlice({
  name: 'showTable',
  initialState,
  reducers: {
    toggleTable: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
  },
});

export const selectShowTable = (state) => state.showTable;
export const { toggleTable } = showTableSlice.actions;
export default showTableSlice.reducer;
