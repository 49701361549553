import React, { useEffect, useState } from 'react';

const CheckboxTextItem = ({
  questionData,
  answer,
  answerKeys,
  isDisabled,
  surveyRecord,
  setSurveyRecord,
  value,
  isExpired,
}) => {
  const [defaultValueInput, setDefaultValueInput] = useState('');
  const [isChecked, setIsChecked] = useState('');
  useEffect(() => {
    const existingItems = surveyRecord[questionData?.parentId]?.data || [];
    const itemExistsIndex = existingItems?.findIndex(
      (item) => item.en === value.en || item.no === value.no,
    );

    if (itemExistsIndex > -1) {
      const value = existingItems[itemExistsIndex].input;
      setIsChecked(true);
      setDefaultValueInput(value);
    } else {
      setDefaultValueInput('');
    }
  }, [surveyRecord, questionData]);
  return (
    <div
      className="checkbox-text__wrap"
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 4,
        marginBottom: 4,
      }}
    >
      <input
        type={'checkbox'}
        name={questionData?.parentId}
        id={answer[answerKeys[0]]}
        value={answerKeys[0]}
        checked={isChecked}
        disabled={isDisabled || isExpired ? !!isDisabled || isExpired : false}
        onChange={(e) => {
          const { name, type } = e.target;

          const existingItems = surveyRecord[name]?.data || [];
          const itemExists = existingItems.some(
            (item) => item.en === value.en && item.no === value.no,
          );

          let updatedItems;
          if (itemExists) {
            updatedItems = existingItems.filter(
              (item) => item.en !== value.en && item.no !== value.no,
            );
            setIsChecked(false);
          } else {
            updatedItems = [...existingItems, { ...value, type: 'checkbox-text', input: '' }];
          }
          setSurveyRecord({
            ...surveyRecord,
            [name]: { data: updatedItems, type: 'checkbox-text' },
          });
        }}
      />
      <label htmlFor={answer[answerKeys[0]]}>{answerKeys[0]}</label>
      <input
        name={questionData?.parentId}
        className="checkbox-text__input"
        type="number"
        value={defaultValueInput}
        onChange={(e) => {
          const { name } = e.target;
          const inputValue = e.target.value;
          if (isNaN(inputValue)) {
            return;
          }

          const existingItems = surveyRecord[name]?.data || [];
          const itemExistsIndex = existingItems.findIndex(
            (item) => item.en === value.en || item.no === value.no,
          );
          if (itemExistsIndex > -1) {
            existingItems[itemExistsIndex] = { ...value, input: +inputValue };
          } else {
            existingItems.push({
              ...value,
              type: 'checkbox-text',
              input: inputValue.length ? +inputValue : 0,
            });
          }
          setSurveyRecord({
            ...surveyRecord,
            [name]: { data: existingItems, type: 'checkbox-text' },
          });

          setDefaultValueInput(inputValue);
        }}
      />
    </div>
  );
};

export default CheckboxTextItem;
