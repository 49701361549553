import { CloseOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import React from 'react';

const NewRangeTime = ({ questionId, surveyRecord, setSurveyRecord, isExpired }) => {
  const survey = { ...surveyRecord };
  if (!survey[questionId]) {
    survey[questionId] = [];
  }
  let index = 0;
  return (
    <div key={Math.random()} className="calendar-plan-survey-form__date__wrap">
      <div className="calendar-plan-survey-form__date__index">{t('time')}:</div>
      <div className="calendar-plan-survey-form__date__group">
        <div className="calendar-plan-survey-form__date__item">
          <input
            className="survey-page-input-time"
            disabled={isExpired}
            type="time"
            name=""
            id=""
            value={survey[questionId][index]?.time}
            onChange={(e) => {
              const { value } = e.target;
              survey[questionId][index] = {
                ...survey[questionId][index],
                time: value,
                type: 'time',
              };

              setSurveyRecord(survey);
            }}
          />
        </div>
        <div
          className="calendar-plan-survey-form__date-close survey-close"
          onClick={() => {
            if (isExpired) return;
            survey[questionId][index] = {
              ...survey[questionId][index],
              time: '',
              type: 'time',
            };

            setSurveyRecord(survey);
          }}
        >
          <CloseOutlined />
        </div>
      </div>
    </div>
  );
};

export default NewRangeTime;
