import React, { createContext, memo, useContext, useEffect, useMemo, useReducer } from 'react';
import turnusConstant from './constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  useAMLInformation,
  useEmployeeList,
  useGroups,
  useHoliday,
  useLayerList,
  usePlanDetailData,
  usePlanListAndChildren,
  useRoleByUnit,
  useRoles,
  useRotationCost,
  useSurveyRecord,
  useTaskList,
  useVakantList,
  userVerionDateRange,
} from 'Hooks/useData';
import { selectGlobal } from 'redux/global/globalSlice';
import { clearSession, loadSession, loadState, saveSession } from 'helper/localStorage';
import {
  generateHardAndSoftDashboardData,
  generateSurveytDashboardData,
} from 'helper/generatorDashboard';
import { socket } from 'socketIO';
import { enumerateDaysBetweenDates } from 'helper/enumerateDaysBetweenDates';
import moment from 'moment';

import { calculateSummaryData, calculateTotalTaskByType } from './helper';
import { IN_SUMMARY } from 'constant';
import { PROGRESS_PLAN_VERSION_ROOM_ID } from 'socketIO/room';
import { updatePlanSelectedAction } from 'redux/calendarPlan/calendarPlanAction';
import {
  useGeneralSettings,
  useGeneralSettingsByKey,
} from 'views/pages/Admin/components/Settings/hook/useData';
import { useWorkingEnvSetup } from 'Hooks/useWorkingEnvData';
import { generalSettingKey } from 'Hooks/constants';

// Create a context to hold the state
const TurnusContext = createContext({});

// Define the initial state
const initialState = {
  originData: [],
  planSelected: loadSession('planSelected') || {},
  employeeList: [],
  vakantList: [],
  sortObj: { name: true },
  isOptimized: false,
  toggleFilterModal: false,
  showCleanAlert: false,
  isAvailable: true,
  weekSelect: 0,
  isPlanSelectedInProgressPlan: false,
  filterRoleList: [],
  progressData: [],
  colApi: turnusConstant.initColApi,
  amlInformation: {},
  publicHoliday: [],
  surveyData: [],
  progressPlan: [],
  employeeData: [],
  dashboardData: {},
  surveyDashboardData: [],
  holidayAndSundayRange: [],
  summarySelectedView: IN_SUMMARY,
  rotationCost: {},
  empInfoRef: null,
  gridColumnApiEmpInfo: null,

  taskListRef: null,
  gridColumnApiTaskList: null,
  //dashboard table ref
  dashboardRef: null,
  gridColumnApiDashboard: null,

  summaryRef: null,

  //hrPerWeekRef table ref
  hrPerWeekRef: null,
  gridColumnApiHrPerWeek: null,
  colSort: '',
  sortType: '',
  cleanData: { assignList: [] },
};

// Define the reducer function to handle state transitions
const reducer = (state, action) => {
  switch (action.type) {
    case turnusConstant.action.SET_ROTATION_COST:
      return { ...state, rotationCost: action.payload };

    case turnusConstant.action.SET_PLAN_SELECTED:
      return { ...state, planSelected: action.payload };

    case turnusConstant.action.SET_ORIGIN_DATA:
      return { ...state, originData: action.payload };

    case turnusConstant.action.SET_SUMMARY_SELECTED_VIEW:
      return { ...state, summarySelectedView: action.payload };

    case turnusConstant.action.SET_EMPLOYEE_LIST:
      return { ...state, employeeList: action.payload };

    case turnusConstant.action.SET_VAKANT_LIST:
      return { ...state, vakantList: action.payload };

    case turnusConstant.action.SET_DASHBOARD_DATA:
      return { ...state, dashboardData: action.payload };

    case turnusConstant.action.SET_SURVEY_DASHBOARD_DATA:
      return { ...state, surveyDashboardData: action.payload };

    case turnusConstant.action.SET_AML_INFORMATION:
      return { ...state, amlInformation: action.payload };

    case turnusConstant.action.SET_HOLIDAY_LIST:
      return { ...state, publicHoliday: action.payload };

    case turnusConstant.action.SET_SURVEY_DATA:
      return { ...state, surveyData: action.payload };

    case turnusConstant.action.SET_FILTER_ROLE_LIST:
      return { ...state, filterRoleList: action.payload };

    case turnusConstant.action.SET_EMPLOYEE_DATA:
      return { ...state, employeeData: action.payload };

    case turnusConstant.action.SET_PROGRESS_DATA:
      return { ...state, progressPlan: action.payload };

    case turnusConstant.action.SET_IS_PLAN_IN_PROGRESS:
      return { ...state, isPlanSelectedInProgressPlan: action.payload };

    case turnusConstant.action.SET_SUNDAY_AND_HOLYDAY_RANGE:
      return { ...state, holidayAndSundayRange: action.payload };

    case turnusConstant.action.SET_IS_AVAILABLE:
      return { ...state, isAvailable: action.payload };

    //GRID API
    case turnusConstant.action.SET_GRID_COLUMN_API_EMP_INFO:
      return { ...state, gridColumnApiEmpInfo: action.payload };

    case turnusConstant.action.SET_GRID_COLUMN_API_TASK_LIST:
      return { ...state, gridColumnApiTaskList: action.payload };

    case turnusConstant.action.SET_GRID_COLUMN_API_DASHBOARD:
      return { ...state, gridColumnApiDashboard: action.payload };

    case turnusConstant.action.SET_GRID_COLUMN_API_HR_PER_WEEK:
      return { ...state, gridColumnApiHrPerWeek: action.payload };

    //SORT PARAM
    case turnusConstant.action.SET_COL_SORT:
      return { ...state, colSort: action.payload };

    case turnusConstant.action.SET_SORT_TYPE:
      return { ...state, sortType: action.payload };

    //REF

    case turnusConstant.action.SET_TASK_LIST_REF:
      return { ...state, taskListRef: action.payload };

    //CLEAN DATA
    case turnusConstant.action.SET_CLEAN_DATA:
      return { ...state, cleanData: action.payload };
    case turnusConstant.action.SET_CLEAN_ALERT:
      return { ...state, showCleanAlert: action.payload };

    default:
      throw new Error('Wrong action');
  }
};

// IncrementProvider takes in an argument called children
const TurnusProvider = ({ children, unitSelected, setIsMainContent }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const globalDispatch = useDispatch();
  const {
    weekSelect,
    sortObj,
    isPlanSelectedInProgressPlan,
    progressData,
    employeeData,
    progressPlan,
    planSelected,
  } = state;
  const isMainContentSession = loadSession('isMainContent');

  const sessionPlanSelected = loadSession('planSelected');
  const { language, globalSortEmployee } = useSelector(selectGlobal);

  const fromDate = moment(planSelected.from_date).format('YYYY-MM-DD');
  const endDate = moment(planSelected.to_date).format('YYYY-MM-DD');
  const { data: rotationCostData, refetch: refetchCostData } = useRotationCost({
    unitCode: unitSelected,
  });
  const { data: versionDateRange, refetch: refetchVersionDateRange } = userVerionDateRange({
    versionID: planSelected.versionID,
  });
  const {
    data: planList,
    refetch: refetchPlanChildList,
    isFetched: isPlanFetched,
  } = usePlanListAndChildren({
    unitSelected,
  });
  const {
    data: employeeList,
    refetch: refetchEmployeeList,
    isFetching: isEmployeeFetching,
  } = useEmployeeList({
    weekSelect,
    planSelected: planSelected.id ? planSelected : {},
    unitSelected,
    sortObj,
    versionID: planSelected.versionID,
    isPlanSelectedInProgressPlan,
  });
  const { data: vakantList, refetch: refetchVakant } = useVakantList({
    weekSelect,
    planSelected: planSelected.id ? planSelected : {},
    unitSelected,
    versionID: planSelected.versionID,
    isPlanSelectedInProgressPlan,
  });
  const { data: amlInformation } = useAMLInformation({ unitSelected });
  const { data: groupList } = useGroups({ unitSelected });
  const { data: roleListByUnit } = useRoleByUnit({ unitSelected });
  const { data: roleList } = useRoles();
  const { data: publicHoliday } = useHoliday();
  const { data: surveyData } = useSurveyRecord({ unitSelected });
  const { data: taskList } = useTaskList({ unitSelected });
  const { data: originalPlanData } = usePlanDetailData({
    plan: { id: planSelected.parentID, versionID: planSelected.versionID },
  });
  const { data: workingEnvSetup } = useWorkingEnvSetup({ unitCode: unitSelected });

  const { data: layerList, refetch: refetchLayerList } = useLayerList({
    unitSelected,
    planID: planSelected.parentID,
  });
  const { data: generalSettingList } = useGeneralSettings({
    unitCode: unitSelected,
  });

  const isShowNightShiftByEndDay = useMemo(() => {
    const nightShiftSetting = generalSettingList.find(
      (item) => item.question_key === 'start-time-for-night-shift',
    );
    if (!nightShiftSetting) {
      return false;
    }

    const settingRecord = nightShiftSetting.records;
    if (settingRecord && settingRecord.length) {
      return settingRecord[0].generalSettingOption?.value === generalSettingKey.nightTime;
    } else {
      return false;
    }
  }, [generalSettingList]);

  const totalTaskCount = useMemo(() => {
    if (originalPlanData) {
      return calculateTotalTaskByType(originalPlanData);
    }
    return {};
  }, [originalPlanData]);

  const summaryDataDefault = useMemo(() => {
    if (employeeData && totalTaskCount) {
      return calculateSummaryData(employeeData, totalTaskCount);
    }
    return {};
  }, [employeeData, totalTaskCount]);
  const dateRange = useMemo(() => {
    if (employeeData && employeeList && vakantList) {
      let result = [];
      if (versionDateRange?.minDate && versionDateRange?.maxDate) {
        result = enumerateDaysBetweenDates(versionDateRange.minDate, versionDateRange.maxDate);
      }
      return result;
    }
  }, [employeeData, employeeList, vakantList, versionDateRange]);

  const taskArr = useMemo(() => {
    if (!employeeList.taskArr) {
      return [];
    }
    const result = [...employeeList.taskArr];
    // if (layerList && layerList.length > 0) {
    //   layerList.forEach((item) => {
    //     employeeList.taskArr.forEach((task) => {
    //       result.push({ ...task, layerID: item.id, layerName: item.name });
    //     });
    //   });
    // }

    return result;
  }, [employeeList.taskArr, layerList]);

  const updateState = (type, payload) => {
    dispatch({ type, payload });
  };

  useEffect(() => {
    if (sessionPlanSelected && !isMainContentSession) {
      try {
        const planParse = JSON.parse(sessionPlanSelected);
        if (planParse.unitCode === unitSelected) {
          updateState(turnusConstant.action.SET_PLAN_SELECTED, planParse);

          // setPlanSelected(planParse);
          saveSession('isMainContent', false);
          setIsMainContent(false);
        }
      } catch (error) {
        const planParse = sessionPlanSelected;
        if (planParse.unitCode === unitSelected) {
          updateState(turnusConstant.action.SET_PLAN_SELECTED, planParse);

          // setPlanSelected(planParse);
          saveSession('isMainContent', false);
          setIsMainContent(false);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (rotationCostData) {
      const rotationCostUnitData = {};
      rotationCostData.forEach((item) => {
        if (item.rotationCostUnitRecord.length) {
          rotationCostUnitData[item.rotationCostProperty.property_name] = {
            question: item.question,
            questionNO: item.question_no,
            propertyName: item.rotationCostProperty.property_name,
            value: item.rotationCostUnitRecord[0]?.answer,
          };
        } else {
          rotationCostUnitData[item.rotationCostProperty.property_name] = {
            question: item.question,
            questionNO: item.question_no,
            propertyName: item.rotationCostProperty.property_name,
            value: '',
          };
        }
      });
      updateState(turnusConstant.action.SET_ROTATION_COST, rotationCostUnitData);
    }
  }, [rotationCostData, unitSelected]);

  useEffect(() => {
    if (employeeList) {
      updateState(turnusConstant.action.SET_EMPLOYEE_LIST, employeeList);
    }
    if (vakantList) {
      updateState(turnusConstant.action.SET_VAKANT_LIST, vakantList);
    }
    if (amlInformation) {
      updateState(turnusConstant.action.SET_AML_INFORMATION, amlInformation);
    }
    if (publicHoliday) {
      updateState(turnusConstant.action.SET_HOLIDAY_LIST, publicHoliday);
    }
    if (surveyData) {
      updateState(turnusConstant.action.SET_SURVEY_DATA, surveyData);
    }
  }, [employeeList, vakantList, amlInformation, publicHoliday, surveyData]);

  useEffect(() => {
    if (employeeList && vakantList && planSelected) {
      const uniqueRoles = [...new Set(employeeList.data?.map((item) => item.role))];
      updateState(turnusConstant.action.SET_FILTER_ROLE_LIST, uniqueRoles);
    }
  }, [employeeList, vakantList, planSelected]);

  useEffect(() => {
    if (
      Object.keys(employeeList).length &&
      publicHoliday &&
      planSelected.id &&
      amlInformation &&
      surveyData
    ) {
      const employeeDashboard = generateHardAndSoftDashboardData({
        data: employeeList.data,
        taskArr: employeeList.taskArr,
        emp_id_number: employeeList.emp_id_number,
        amlInformation,
        publicHoliday,
        fromDate: planSelected.from_date,
        endDate: planSelected.to_date,
      });

      const surveyDashboardData = generateSurveytDashboardData(
        employeeData.filter((item) => item.name),
        employeeList?.taskArr,
        surveyData,
        planSelected.from_date,
        planSelected.to_date,
      );
      const ignoreKey = ['employeeData', 'employeeID'];
      const listEmployeeDashboardKey = Object.keys(employeeDashboard).filter(
        (item) => !ignoreKey.includes(item),
      );

      const employeeWithTaskData = employeeDashboard.employeeData.map((employeeInList) => {
        const dashboardValue = {};
        const surveyValue = {};
        for (const dashboardValueKey of listEmployeeDashboardKey) {
          const checkingValue = employeeDashboard[dashboardValueKey];
          const empValue = checkingValue.filter(
            (item) => +item.employeeId === +employeeInList.employeeId,
          );
          if (empValue.length) {
            dashboardValue[dashboardValueKey] = true;
          } else {
            dashboardValue[dashboardValueKey] = false;
          }
        }
        const survey = surveyDashboardData.find(
          (item) => +item.employeeID === +employeeInList.employeeId,
        );
        if (survey) {
          const listEmployeeSuryveyDashboardKey = Object.keys(survey).filter(
            (item) => !ignoreKey.includes(item),
          );
          for (const surveyValueKey of listEmployeeSuryveyDashboardKey) {
            const checkingValue = survey[surveyValueKey];
            if (checkingValue) {
              const empValue = checkingValue.filter(
                (item) => +item.employeeID === +employeeInList.employeeId,
              );
              if (empValue.length) {
                surveyValue[surveyValueKey] = true;
              } else {
                surveyValue[surveyValueKey] = false;
              }
            }
          }
        }

        return {
          ...surveyValue,
          ...dashboardValue,
          ...employeeInList,
          task_arr: employeeList.data.find(
            (employee) => employee.employeeId === employeeInList.employeeId,
          )?.task_arr,
        };
      });

      const unassignedEmployeeDashboard = generateHardAndSoftDashboardData({
        data: vakantList.data,
        taskArr: employeeList.taskArr,
        amlInformation,
        publicHoliday,
        fromDate: planSelected.from_date,
        endDate: planSelected.to_date,
      });

      const vakantWithTaskData = unassignedEmployeeDashboard.employeeData.map((item) => ({
        ...item,
        task_arr: vakantList.data.find((employee) => employee.employeeId === item.employeeId)
          .task_arr,
      }));
      const employeeAndVakantData = [...employeeWithTaskData, ...vakantWithTaskData];
      updateState(turnusConstant.action.SET_EMPLOYEE_DATA, employeeAndVakantData);
      updateState(turnusConstant.action.SET_ORIGIN_DATA, employeeAndVakantData);

      return () => {
        updateState(turnusConstant.action.SET_EMPLOYEE_DATA, []);
        updateState(turnusConstant.action.SET_ORIGIN_DATA, []);
      };
    }
  }, [
    surveyData,
    amlInformation,
    planSelected.id,
    planSelected.from_date,
    planSelected.to_date,
    publicHoliday,
    employeeList,
    vakantList,
  ]);
  useEffect(() => {
    const dashboardData = generateHardAndSoftDashboardData({
      data: employeeData,
      taskArr: employeeList?.taskArr,
      emp_id_number: employeeList.emp_id_number,
      amlInformation,
      publicHoliday,
      fromDate: planSelected.from_date,
      endDate: planSelected.to_date,
    });
    const surveyDashboardData = generateSurveytDashboardData(
      employeeData.filter((item) => item.name),
      employeeList?.taskArr,
      surveyData,
      planSelected.from_date,
      planSelected.to_date,
    );

    updateState(turnusConstant.action.SET_DASHBOARD_DATA, dashboardData);
    updateState(turnusConstant.action.SET_SURVEY_DASHBOARD_DATA, surveyDashboardData);
    return () => {
      updateState(turnusConstant.action.SET_DASHBOARD_DATA, {});
      updateState(turnusConstant.action.SET_SURVEY_DASHBOARD_DATA, []);
    };
  }, [employeeData, planSelected, surveyData, employeeList]);

  useEffect(() => {
    socket.emit('join_room', { room: PROGRESS_PLAN_VERSION_ROOM_ID });

    const handleReceiveVersionProgress = async (data) => {
      const isHavePlanSelected = data.find((item) => +item.id === +planSelected.versionID);
      if (isHavePlanSelected) {
        if (data && JSON.stringify(data) !== JSON.stringify(progressPlan)) {
          updateState(turnusConstant.action.SET_PROGRESS_DATA, data);
          updateState(turnusConstant.action.SET_IS_PLAN_IN_PROGRESS, true);
        } else if (!data || !data.length) {
          updateState(turnusConstant.action.SET_PROGRESS_DATA, []);
          updateState(turnusConstant.action.SET_IS_PLAN_IN_PROGRESS, false);
        }
      } else {
        if (isPlanSelectedInProgressPlan) {
          updateState(turnusConstant.action.SET_IS_PLAN_IN_PROGRESS, false);
        }
      }
    };

    socket.on('receive_version_progress', handleReceiveVersionProgress);

    return () => {
      socket.off('receive_version_progress', handleReceiveVersionProgress);
    };
  }, [progressPlan, planSelected]);
  useEffect(() => {
    const listAllDateInRange = enumerateDaysBetweenDates(fromDate, endDate);
    const holidayAndSundayRangeSet = new Set();
    for (const date of listAllDateInRange) {
      if (moment(date).weekday() === 0) {
        holidayAndSundayRangeSet.add(date);
      }
    }
    for (const date of publicHoliday) {
      if (listAllDateInRange.includes(date)) {
        holidayAndSundayRangeSet.add(date);
      }
    }
    updateState(
      turnusConstant.action.SET_SUNDAY_AND_HOLYDAY_RANGE,
      Array.from(holidayAndSundayRangeSet),
    );
  }, [employeeList, unitSelected, vakantList]);

  useEffect(() => {
    const isInProgress = progressPlan.some(
      (progress) =>
        +progress.id === +planSelected.versionID && progress.process < 100 && progress.process > 0,
    );
    updateState(turnusConstant.action.SET_IS_PLAN_IN_PROGRESS, isInProgress);
  }, [progressData, planSelected, progressPlan]);
  const setGridColumnApiEmployeeInfo = (payload) =>
    updateState(turnusConstant.action.SET_GRID_COLUMN_API_EMP_INFO, payload);

  const setGridColumnApiTaskList = (payload) =>
    updateState(turnusConstant.action.SET_GRID_COLUMN_API_TASK_LIST, payload);

  const setGridColumnApiDashboard = (payload) =>
    updateState(turnusConstant.action.SET_GRID_COLUMN_API_DASHBOARD, payload);

  const setGridColumnApiHrPerWeek = (payload) =>
    updateState(turnusConstant.action.SET_GRID_COLUMN_API_HR_PER_WEEK, payload);

  const setSortCol = (payload) => updateState(turnusConstant.action.SET_COL_SORT, payload);

  const setSortType = (payload) => updateState(turnusConstant.action.SET_SORT_TYPE, payload);

  const setIsPlanSelectedInProgressPlan = (payload) =>
    updateState(turnusConstant.action.SET_IS_PLAN_IN_PROGRESS, payload);

  const setEmployeeData = (payload) =>
    updateState(turnusConstant.action.SET_EMPLOYEE_DATA, payload);

  const setPlanSelected = (payload) => {
    saveSession('planSelected', payload);
    globalDispatch(updatePlanSelectedAction(payload));
    updateState(turnusConstant.action.SET_PLAN_SELECTED, payload);
    updateState(turnusConstant.action.SET_CLEAN_ALERT, false);
    updateState(turnusConstant.action.SET_CLEAN_DATA, { assignList: [] });
  };
  const setSummarySelectedView = (payload) =>
    updateState(turnusConstant.action.SET_SUMMARY_SELECTED_VIEW, payload);
  const setCleanData = (payload) => updateState(turnusConstant.action.SET_CLEAN_DATA, payload);
  const setCleanDataAlert = (payload) =>
    updateState(turnusConstant.action.SET_CLEAN_ALERT, payload);
  const setTaskListRef = (payload) => updateState(turnusConstant.action.SET_TASK_LIST_REF, payload);
  // In this return value, we passed-in children as the CONSUMER of the PROVIDER
  // This will able children components to access the data inside the context
  return (
    <TurnusContext.Provider
      value={{
        dateRange,
        taskArr,
        unitSelected,
        setIsMainContent,
        roleListByUnit,
        refetchPlanChildList,
        planSelected,
        fromDate,
        endDate,
        language,
        globalSortEmployee,
        versionDateRange,
        planList,
        isPlanFetched,
        groupList: groupList && groupList.data ? groupList.data : [],
        isPlanSelectedInProgressPlan,
        refetchVersionDateRange,
        refetchEmployeeList,
        refetchVakant,
        setGridColumnApiEmployeeInfo,
        setGridColumnApiTaskList,
        setGridColumnApiDashboard,
        setGridColumnApiHrPerWeek,
        setSortCol,
        setSortType,
        setIsPlanSelectedInProgressPlan,
        setPlanSelected,
        setEmployeeData,
        setSummarySelectedView,
        setTaskListRef,
        setCleanData,
        setCleanDataAlert,
        isEmployeeFetching,
        summaryData: summaryDataDefault,
        weekRange: employeeList.weekRange || [],
        layerList,
        ...state,
        employeeDataList: state.employeeList,
        surveyData,
        dispatch,
        vakantList,
        roleList,
        taskList,
        originalPlanData,
        publicHoliday,
        workingEnvSetup,
        isShowNightShiftByEndDay,
      }}
    >
      {children}
    </TurnusContext.Provider>
  );
};
export default memo(TurnusProvider);

// Create a function that invokes the context
export const useTurnusContext = () => {
  return useContext(TurnusContext);
};
