import React, { useMemo, useCallback, useEffect, useState, memo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import i18n, { t } from 'i18next';

import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import {
  weekdayKey,
  surveyDataKey,
  TASK_LIST_LEGEND_COLOR,
  NIGHT_SHIFT_VALUE,
  offShiftReg,
  dateFormatReg,
} from 'constant';
import { selectGlobal } from 'redux/global/globalSlice';
import listTitleCheck from 'constant/kalendarPlanDashboardHeaderV2';
import {
  generateHardAndSoftDashboardData,
  generateSurveytDashboardData,
} from 'helper/generatorDashboard';
import { selectCalendarPlan } from 'redux/calendarPlan/calendarPlanSlice';
import { selectGeneratorTableFilter, updateFilter } from 'redux/generateTableFilter/slice';
import { enumerateDaysBetweenDates } from 'helper/enumerateDaysBetweenDates';
import { saveSession } from 'helper/localStorage';
import axiosClient from 'axios/axiosClient';
import moment from 'moment';
import { useGeneralSettingsByKey } from 'views/pages/Admin/components/Settings/hook/useData';
import {
  LockMiniIcon,
  Unlock2,
  CopyIcon2,
  Lock2
} from 'assets/img';

import {
  getEditShiftPlanDetailID,
  getPlanDetailLocked,
  getShiftColID,
  lockEmployeeShiftByRangeSelect,
  unlockEmployeeShiftByRangeSelect,
} from './helper/lockRange';
import { Toast } from 'helper/alert';
import { selectGeneratorColApi, updateApi } from 'redux/generateTableApi/slice';
import { useTurnusContext } from '../../Turnus/TurnusContext';
import { updateEmpData } from './helper/updateShift';
import TaskEditComponent from './TaskEditComponent';
import { selectAuth } from 'redux/auth/authSlice';
import { autoSyncScroll } from 'helper/syncTables';
import TaskCleanCell from './TaskListComponent/TaskCleanCell/TaskCleanCell';

const tooltipDescription = (headerName, language) => {
  const headerObj = listTitleCheck[headerName];
  if (headerObj) {
    return language === 'NO' ? headerObj['no-description'] : headerObj['en-description'];
  }
};

const TaskList = ({
  onFirstDataRendered,
  hiddenColDef,
  doesExternalFilterPass,
  getRowId,
  currentTab,
  isExternalFilterPresent,
  checkingIfDifferences,
  setIsActiveSaveBtn,
  listGroupAndColor = [],
  versionDateRange,
  originalPlanData,
  postSortRows,
}) => {
  const turnusData = useTurnusContext();
  const {
    employeeData,
    weekRange,
    taskArr,
    amlInformation,
    surveyData,
    setGridColumnApiTaskList,
    globalSortEmployee,
    unitSelected,
    planSelected,
    publicHoliday,
    fromDate,
    endDate,
    summaryData,
    summarySelectedView,
    layerList,
    setTaskListRef,
    setEmployeeData,
    roleList,
    workingEnvSetup,
    groupList,
    isShowNightShiftByEndDay,
  } = turnusData;
  const {
    empInfo: empInfoApi,
    dashboard: dashboardApi,
    summary: summaryApi,
    hrPerWeek: hrPerWeekAPI,
    taskList: taskListAPI,
  } = useSelector(selectGeneratorColApi);
  const { isEditabled } = useSelector(selectAuth);
  const isPlanHaveLayer = !!layerList.length; //Checking if plan have layer
  const taskListRef = useRef(null);
  const dispatch = useDispatch();
  const show = currentTab === 'schedule';
  const [checkingData, setCheckingData] = useState({});
  const { listLocked } = useSelector(selectCalendarPlan);
  const planDetailLocked = getPlanDetailLocked(listLocked, planSelected);
  const lockedData = planDetailLocked.lockedData;
  const processData = (originalData) => {
    const convertedData = originalData.map((employee) => {
      const employeeTask = { ...employee.task_arr }; // Copy task_arr

      // Filter, remove, and add night shifts (logic from original function)
      const listWorkingDay = Object.keys(employeeTask);
      const nightShifts = listWorkingDay.reduce((acc, date) => {
        const taskData = employeeTask[date];
        if (taskData && taskData.length && taskData[0].taskType === NIGHT_SHIFT_VALUE) {
          acc.push({ [date]: taskData });
          delete employeeTask[date];
        }
        return acc;
      }, []);

      const addedNightShifts = {};
      nightShifts.forEach((nightShift) => {
        const dateKey = Object.keys(nightShift)[0];
        const newDate = moment(dateKey).add(1, 'd').format('YYYY-MM-DD');

        addedNightShifts[newDate] = nightShift[dateKey];
      });

      return { ...employee, task_arr: { ...employeeTask, ...addedNightShifts } };
    });

    return convertedData;
  };
  const [rowData, setRowData] = useState([]);
  useEffect(() => {
    let data = [];
    if (isShowNightShiftByEndDay) {
      const processedData = processData(employeeData);

      data = processedData;
    } else {
      data = JSON.parse(JSON.stringify(employeeData));
    }
    setRowData(data);
  }, [employeeData, isShowNightShiftByEndDay]);

  const { surveyDashboardData = [] } = checkingData;
  const taskList = useMemo(() => ['', ...taskArr.map((task) => task.taskName)], [taskArr]);
  const {
    planDetailLocked: { employeeIdLockedList },
  } = useSelector(selectCalendarPlan);
  const { toggleWeekdays, toggleHolidays } = useSelector(selectGeneratorTableFilter);
  const dateRange = useMemo(
    () =>
      versionDateRange && versionDateRange.minDate && versionDateRange.maxDate
        ? enumerateDaysBetweenDates(versionDateRange.minDate, versionDateRange.maxDate)
        : enumerateDaysBetweenDates(planSelected.from_date, planSelected.to_date),
    [planSelected.versionID, versionDateRange],
  );
  useEffect(() => {
    const employeeDashboard = generateHardAndSoftDashboardData({
      data: rowData,
      taskArr: taskArr,
      amlInformation,
      publicHoliday,
      fromDate: planSelected.from_date,
      endDate: planSelected.to_date,
    });
    const employeeList = rowData.filter((item) => item.name);
    const surveyDashboardData = generateSurveytDashboardData(
      employeeList,
      taskArr,
      surveyData,
      planSelected.from_date,
      planSelected.to_date,
    );

    setCheckingData({ ...employeeDashboard, surveyDashboardData });
  }, [rowData]);
  const { language } = useSelector(selectGlobal);
  const defaultColDef = useMemo(
    () => ({
      editable: isEditabled,
      cellEditor: TaskEditComponent,
      cellEditorPopup: true,
      cellEditorParams: {
        groupList,
      },

      filter: 'agTextColumnFilter', // show text filter
      flex: 1,
    }),
    [],
  );
  const invalidDateData = useMemo(() => {
    // const ignoreKey = ['taskArr', 'weekRange', 'data', 'dateRange', 'surveyDashboardData'];
    if (rowData) {
      let result = {};

      //TODO MARK SOFT RULE
      // const listKey = Object.keys(rowData).filter((item) => !ignoreKey.includes(item));
      // for (const key of listKey) {
      //   const checkingData = rowData[key];
      //   if (Array.isArray(checkingData)) {
      //     for (const value of checkingData) {
      //       if (value.date) {
      //         if (result[value.employeeId]) {
      //           result[value.employeeId].push(value.date);
      //         } else {
      //           result[value.employeeId] = [value.date];
      //         }
      //       }
      //     }
      //   }
      // }

      for (const emp of surveyDashboardData) {
        const empID = emp.employeeID;
        const empKeys = Object.keys(emp);
        for (const key of empKeys) {
          const checkingData = emp[key];
          if (Array.isArray(checkingData) && checkingData.length) {
            checkingData.forEach((item) => {
              if (item.date) {
                if (result[empID]) {
                  result[empID].push({ [item.date]: key });
                } else {
                  result[empID] = [{ [item.date]: key }];
                }
              }
            });
          }
        }
      }
      return result;
    }
  }, [rowData, checkingData, surveyDashboardData]);

  const isShortenHeader = useMemo(() => {
    return toggleHolidays || toggleWeekdays;
  }, [toggleHolidays, toggleWeekdays]);

  i18n.on('languageChanged', () => {
    dispatch(updateFilter({ key: 'toggleHolidays', value: false }));
    dispatch(updateFilter({ key: 'toggleWeekdays', value: false }));
  });
  const updateTasksAndData = async (params, tasks, empData, dateInWeek, oldTask) => {
    if (!tasks[0].planDetailID && !oldTask) {
      return;
    }
    const empTaskArr = { ...params.data.task_arr };
    empTaskArr[dateInWeek] = tasks;
    params.data.task_arr = empTaskArr;

    const updatedData = {
      employeeID: empData.employeeId,
      taskUpdate: tasks,
      date: dateInWeek,
      shiftID: tasks && tasks.length && tasks[0].shiftID ? [tasks[0].shiftID] : [],
      vakantShifIDs: tasks && tasks[0]?.vakantShiftID ? [tasks[0]?.vakantShiftID] : [],
      versionID: planSelected.versionID,
    };

    if (oldTask && oldTask[0].isDeleted) {
      updatedData.shiftID = [];
    }

    //TODO Create new API for single saving later
    // params.colDef.cellStyle = () => {
    //   const groupList = tasks[0].groupList;
    //   if (groupList && groupList.length) {
    //     const groupID = groupList[0].groupID;
    //     const colorFound = listGroupAndColor?.find((group) => group.id === groupID);
    //     return {
    //       backgroundColor: colorFound ? colorFound.color : TASK_LIST_LEGEND_COLOR.DEFAULT,
    //     };
    //   }
    // };
    taskListAPI.api.refreshCells({ force: true });
    await axiosClient
      .post('calendar-plan/v2/update-generator-plan', {
        dataUpdated: [updatedData],
        plan: planSelected,
      })
      .then(async (updatedList) => {
        //Will be update when setup new api
        if (params.data.task_arr[dateInWeek] && updatedList && updatedList.length) {
          params.data.task_arr[dateInWeek] = [{ ...tasks[0], shiftID: updatedList[0].id }];
        }
        updateEmpData(
          empInfoApi,
          dashboardApi,
          summaryApi,
          hrPerWeekAPI,
          empData,
          tasks,
          params,
          employeeData,
          publicHoliday,
          dateInWeek,
          taskArr,
          amlInformation,
          fromDate,
          endDate,
          surveyData,
          summaryData,
          summarySelectedView,
          oldTask,
          weekRange,
        );
      })
      .catch((err) => {
        console.log(err);
        Toast({ icon: 'error', title: `${t('update')} ${t('unsuccess')}` });
      });
  };

  const copySelectedItems = () => {
    const api = taskListRef.current;
    const selectedCellRanges = api.getCellRanges();

    // Prepare cell plain value to be copied to clipboad with tab as delimiter
    const copiedData = selectedCellRanges.map((cellRange) => {
      const { startRow, endRow, columns } = cellRange;
      const copiedData = [];
      for (let rowIndex = startRow.rowIndex; rowIndex <= endRow.rowIndex; rowIndex++) {
        const rowData = api.getDisplayedRowAtIndex(rowIndex).data;
        const copiedRowData = columns.map((column) => {
          // From column and rowdata, get the 'taskName' inside 'task_arr' object
          const dateInWeek = dateFormatReg.exec(column.colId)[0];
          const dayTasks = rowData?.task_arr[dateInWeek];
          if (!dayTasks || !dayTasks.length) return '';
          return dayTasks[0].taskName;
        });
        copiedData.push(copiedRowData.join('\t'));
      }
      return copiedData.join('\n');
    });

    // Copy the string to the clipboard
    navigator.clipboard.writeText(copiedData);
  };

  const columnDefs = useMemo(() => {
    return weekRange
      ? weekRange.map(({ week, dateString }, weekIndex) => ({
          headerName: `${t('week')} ${week} ${isShortenHeader ? '' : `(${dateString})`}`,
          headerClass: `dark-header ${isShortenHeader ? 'shorten-header' : ''}`,
          headerTooltip: isShortenHeader ? dateString : null,
          tooltipShowDelay: 2000,
          suppressColumnsToolPanel: true,
          children: weekdayKey.map((day, dayIndex) => {
            const dateInWeek = dateRange[weekIndex * 7 + dayIndex];
            const shiftColumnID = getShiftColID({ dateInWeek, publicHoliday });
            // ColumnID Ex: weekdays-2024-12-26--holidays | weekend-2024-12-22--not_holidays
            const [__date, holidayInfo] = shiftColumnID.split('--');
            const getHeaderClass = () => {
              let headerCustomDayOffAccumulatedClass =
                // Handle weekend
                ['sat', 'sun'].includes(day)
                  ? day === 'sun'
                    ? 'weekend-header task-day-cell-header end-of-week'
                    : 'weekend-header task-day-cell-header'
                  : 'task-day-cell-header';
              // Handle public holidays and semi-free holiday
              if (holidayInfo === 'semi_holidays' || holidayInfo === 'holidays') {
                headerCustomDayOffAccumulatedClass += ' holiday-background';
              }
              return headerCustomDayOffAccumulatedClass;
            };

            return {
              // this will exclude these column when searching globally
              getQuickFilterText: () => {
                return '';
              },
              // cellRenderer: 'agAnimateShowChangeCellRenderer',
              headerName: t(day).charAt(0).toUpperCase(),
              headerClass: getHeaderClass(),
              minWidth: 40,
              filter: false,
              editable: (params) => {
                const dayTasks = params.data?.task_arr[dateInWeek];
                const { employeeId: employeeId } = params.data;

                if (dayTasks && dayTasks.length) {
                  const { planDetailID } = dayTasks[0];
                  const isDeleted = dayTasks[0].isDeleted;
                  const isClean = dayTasks[0].isClean;
                  if (isClean) return false;

                  let isDateLocked = lockedData.find(
                    (item) => item.date === dateInWeek && item.employeeID === employeeId,
                  );

                  if (isDateLocked) {
                    Toast({ icon: 'warning', title: t('unlocked-before-edit') });
                    return false;
                  }
                }

                return true;
              },
              menuTabs: [],
              field: 'date',

              tooltipValueGetter: (params) => {
                if (!params.data) return;
                const { employeeId, task_arr } = params.data;
                const dayTasks = task_arr[dateInWeek];
                if (dayTasks && dayTasks.length && dayTasks[0].reasonKey) {
                  return t(dayTasks[0].reasonKey);
                }
                const empInValidData = invalidDateData[employeeId];
                if (empInValidData) {
                  const invalidDataFound = empInValidData.find((item) => {
                    const date = Object.keys(item)[0];
                    return date === dateInWeek;
                  });
                  if (invalidDataFound) {
                    const headerKey = surveyDataKey[invalidDataFound[dateInWeek]];
                    return tooltipDescription(headerKey, language) || t(headerKey);
                  }
                }
              },
              colId: getShiftColID({ dateInWeek, publicHoliday }),
              cellEditorParams: {
                values: taskList,
                isPlanHaveLayer,
                dateInWeek,
                setEmployeeData,
                roleList,
                workingEnvSetup,
                listEmpData: rowData,
              },
              cellClass: (params) => {
                let cellClass = "";

                // Handle task day
                if (dayIndex === weekdayKey.length - 1) { 
                  cellClass += 'end-of-group task-day-cell';
                } else {
                  cellClass += 'task-day-cell';
                }

                // Handle semi-free holiday
                const { data: empData, column } = params;
                const [__date, holidayParam] = column.colId.split('--');
                const dayOff = empData.dayOff || [];
                const isDayOff = dayOff.includes(dateInWeek);
                if (holidayParam === 'semi_holidays' && !isDayOff) {
                  cellClass += ' semi-free-holiday'
                }
                return cellClass;
              },
              cellStyle: (params) => {
                const { data: empData, column } = params;

                if (!empData) return;
                const cellStyle = {};

                const { employeeId: employeeId } = params.data;

                const dayTasks = params.data?.task_arr[dateInWeek];
                let isDateLocked = lockedData.find(
                  (item) => item.date === dateInWeek && item.employeeID === employeeId,
                );

                if (isDateLocked) {
                  cellStyle.border = '1px solid #D12444';
                }
                // if (publicHoliday.includes(dateInWeek)) {
                //   cellStyle.backgroundColor = TASK_LIST_LEGEND_COLOR.HOLIDAY;
                // }
                const dayOff = empData.dayOff || [];
                const isDayOff = dayOff.includes(dateInWeek);

                const [__date, holidayParam] = column.colId.split('--');
                if (holidayParam === 'semi_holidays' && !isDayOff) {
                  cellStyle.background = `linear-gradient(to bottom right, white 50%, #fbe5e3 50%)`;
                }
                if (holidayParam === 'holidays' && !isDayOff) {
                  cellStyle.background = '#fbe5e3';
                }

                if (!dayTasks || !Object.keys(dayTasks).length) return cellStyle;
                const isDeleted = dayTasks[0].isDeleted;
                const taskId = dayTasks && dayTasks.length ? dayTasks[0].taskId : null;
                const workingRange = empData.workingDayRange || [];
                const planDetailID = dayTasks[0].planDetailID;

                // vacation day off highlight
                if (isDayOff) {
                  cellStyle.clipPath = '';

                  cellStyle.background = TASK_LIST_LEGEND_COLOR.DAY_OFF;
                }

                if (isDeleted) {
                  return cellStyle;
                }
                // group highlight
                if (taskId) {
                  const taskFound = taskArr.find((task) => {
                    const planDetailCondition = planDetailID
                      ? planDetailID === task.planDetailID
                      : true;
                    return planDetailCondition && +task.taskId === +taskId;
                  });
                  if (taskFound) {
                    const groupIDs = taskFound?.groupList?.map((item) => item.groupID) || [];

                    if (taskFound && groupIDs.length) {
                      const colorFound = listGroupAndColor?.find((group) =>
                        groupIDs.includes(group.id),
                      );
                      cellStyle.backgroundColor = colorFound
                        ? colorFound.color
                        : TASK_LIST_LEGEND_COLOR.DEFAULT;
                    }
                  }
                }

                if (dayTasks && dayTasks.length) {
                  const { planDetailID, isAssignByCleanVakant, isRervert, isClean } = dayTasks[0];

                  if (isAssignByCleanVakant || isClean) {
                    cellStyle.border = '2.5px solid #2F4CE0';
                    cellStyle.backgroundColor = '#F0F2FD';
                  }
                  if (isRervert) {
                    cellStyle.border = '2.5px dashed orange';
                  }
                }
                return cellStyle;
              },

              cellRenderer: (params) => {
                let isDateLocked = false;
                if (!params.data) return;

                const { employeeId: employeeId, isDeleted } = params.data;
                if (isDeleted) return;

                isDateLocked = lockedData.find(
                  (item) => item.date === dateInWeek && item.employeeID === employeeId,
                );
                const dayTasks = params.data?.task_arr[dateInWeek];

                if (!dayTasks || !dayTasks.length) {
                  return (
                    <div className="shift-cell">
                      {isDateLocked ? <img src={LockMiniIcon} alt="" /> : null}
                      <span></span>
                    </div>
                  );
                } else {
                  const { planDetailID } = dayTasks[0];

                  if (
                    dayTasks[0].isAssignByCleanVakant ||
                    dayTasks[0].is_clean ||
                    dayTasks[0].isClean
                  ) {
                    return (
                      <TaskCleanCell
                        data={params.data}
                        dateInWeek={dateInWeek}
                        rowData={rowData}
                        planSelected={planSelected}
                      />
                    );
                  }
                  return (
                    <div className="shift-cell">
                      {isDateLocked ? <img src={LockMiniIcon} alt="" /> : null}
                      <div>
                        {!dayTasks[0].isDeleted ? dayTasks[0].taskName : ''}
                      </div>
                    </div>
                  );
                }
              },
              valueGetter: (params) => {
                if (!params.data) return;
                const dayTasks = params.data?.task_arr[dateInWeek];
                if (!dayTasks || !dayTasks.length) {
                  return '';
                }

                return !dayTasks[0].isDeleted ? dayTasks[0].taskName : '';
              },

              valueSetter: async (params) => {
                const { data: empData, colDef } = params;
                if (!isEditabled || !empData || empData.isClean) return;

                try {
                  let newValue = { taskID: null, groupID: null, roleID: null, planDetailID: null };

                  if (params.newValue.length) {
                    newValue = JSON.parse(params.newValue);
                  }
                  saveSession('lastColEdited', params.colDef.colId);
                  saveSession('lastRowEdited', params.node.rowIndex);

                  let dayTasks = empData.task_arr[dateInWeek];
                  let oldTask = empData.task_arr[dateInWeek];
                  let newTask = taskArr.find((task) => {
                    const { taskId, group_id, roleId } = task;
                    const { taskID, groupID, roleID, planDetailID } = newValue;

                    let isValid = +taskId === +taskID;
                    if (planDetailID) {
                      return planDetailID === task.planDetailID;
                    } else {
                      if (!taskID) {
                        return false;
                      }

                      if (groupID) {
                        isValid = isValid && +group_id === +groupID;
                      }

                      if (roleID) {
                        isValid = isValid && +roleId === +roleID;
                      }

                      return isValid;
                    }
                  });

                  if (
                    employeeIdLockedList.includes(params.data.employeeId) ||
                    (!newValue && Object(newValue).length && !dayTasks)
                  ) {
                    return false;
                  }
                  // const layerID = newValue[1];
                  // const layerName = newValue[2];
                  // if (layerID && layerName) {
                  //   newTask.layerID = layerID;
                  //   newTask.layerName = layerName;
                  // }
                  // if (isNaN(newValue[1]) && newValue !== 'null') {
                  //   newTask = taskArr.find((task) => task.taskName === newValue[0]) || '';
                  // }

                  const planDetailID = getEditShiftPlanDetailID({
                    originalPlanData,
                    taskWillUpdate: newTask,
                    date: dateInWeek,
                  });
                  const isOffShift = offShiftReg.test(newTask?.taskName);
                  //Enabled assign off shift and checking if that shift not available
                  if (!planDetailID && newTask && !isOffShift) {
                    Toast({ icon: 'warning', title: t('shift-not-available-on-this-day') });
                    return;
                  }

                  if (!planDetailID && !isOffShift) {
                    newTask = {
                      ...newTask,
                      planDetailID: planDetailID,
                      taskId: null,
                      isDeleted: true,
                    };
                  } else {
                    newTask = {
                      ...newTask,
                      planDetailID: planDetailID,
                      isDeleted: false,
                    };
                  }
                  if (!dayTasks) {
                    return await updateTasksAndData(
                      params,
                      [newTask],
                      empData,
                      dateInWeek,
                      oldTask,
                    );
                  } else if (params.data.task_arr[dateInWeek][0] !== newTask) {
                    let updatedTasks = [...params.data.task_arr[dateInWeek]];
                    updatedTasks[0] = { ...updatedTasks[0], ...newTask };
                    return await updateTasksAndData(
                      params,
                      updatedTasks,
                      empData,
                      dateInWeek,
                      oldTask,
                    );
                  }

                  return false;
                } catch (error) {
                  console.log(error);
                  return;
                }
              },
            };
          }),
        }))
      : [];
  }, [
    rowData,
    invalidDateData,
    taskList,
    checkingData,
    isShortenHeader,
    dateRange,
    planDetailLocked,
    listLocked,
    empInfoApi,
    dashboardApi,
    summaryApi,
    planSelected,
    lockedData,
  ]);
  //Handle right click menu
  const getContextMenuItems = useCallback(
    (params) => {
      const result = [
        {
          // custom item
          name: t('lock'),
          icon: `<img src="${Lock2}" width="14px"/>`,
          action: () => {
            if (!isEditabled) return;
            lockEmployeeShiftByRangeSelect({
              params,
              planSelected,
              planDetailLocked,
              dispatch,
            });
          },
        },
        {
          // custom item
          name: t('unlock'),
          icon: `<img src="${Unlock2}" width="14px"/>`,
          action: () => {
            if (!isEditabled) return;

            unlockEmployeeShiftByRangeSelect({
              params,
              planSelected,
              planDetailLocked,
              dispatch,
            });
          },
        },
        {
          // custom item
          name: t('copy-plain-text'),
          icon: `<img src="${CopyIcon2}" width="14px"/>`,
          action: () => {
            copySelectedItems();
          },
        },
      ];
      return result;
    },
    [rowData, planDetailLocked, listLocked],
  );
  const onTaskGridReady = (params) => {
    autoSyncScroll('task-list-wrapper');

    setGridColumnApiTaskList(params.columnApi);
    taskListRef.current = params.api;
    dispatch(updateApi({ key: 'taskList', value: { api: params.api, colApi: params.columnApi } }));
    if (globalSortEmployee) {
      params.columnApi.applyColumnState(globalSortEmployee);
    }
    setTaskListRef(taskListRef);
  };
  useEffect(() => {
    autoSyncScroll('task-list-wrapper');
  }, [currentTab]);

  const processCellForClipboard = (params) => {
    const colId = params.column?.colId;
    if (colId) {
      const dateCopy = dateFormatReg.exec(colId)[0];
      const cellNode = params.node;
      const employeeData = cellNode.data;
      if (employeeData) {
        const taskArr = employeeData.task_arr;
        const dateCopyDataList = taskArr[dateCopy];
        if (dateCopyDataList && dateCopyDataList.length) {
          const taskCopyData = dateCopyDataList[0];

          const copyData = {
            taskID: taskCopyData.taskId,
            groupID: taskCopyData.groupID,
            roleID: taskCopyData.roleID,
            planDetailID: taskCopyData.planDetailID,
          };
          return JSON.stringify(copyData);
        }
      }
    }
  };

  return (
    <div className={`ag-theme-alpine task-list ${show ? '' : 'hidden'}`} id="task-list-wrapper">
      <AgGridReact
        ref={taskListRef}
        defaultColDef={defaultColDef}
        onGridReady={onTaskGridReady}
        rowData={rowData}
        columnDefs={[...hiddenColDef, ...columnDefs]}
        onFirstDataRendered={onFirstDataRendered}
        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
        enableRangeSelection={true} // Optional - allows range selection of cells, chart, auto fill
        enableFillHandle={true} // allow auto fill, need to enableRangeSelection
        // enable chart
        enableCharts={true} // need to enableRangeSelection
        undoRedoCellEditing={true} // enable undo redo
        undoRedoCellEditingLimit={20} // set undo redo limit
        // enableCellChangeFlash={true} // highlight cell when change
        immutableData={true}
        tooltipShowDelay={0}
        getRowId={getRowId}
        // enable external filter
        isExternalFilterPresent={() => isExternalFilterPresent}
        doesExternalFilterPass={doesExternalFilterPass}
        includeHiddenColumnsInQuickFilter={true} // this will allow searching for hidden col
        getContextMenuItems={getContextMenuItems}
        reactiveCustomComponents={true}
        postSortRows={postSortRows}
        // processDataFromClipboard={processDataFromClipboard}
        processCellForClipboard={processCellForClipboard}
        // postSortRows={postSortRows}
        // onRangeSelectionChanged={onRangeSelectionChanged}
        stopEditingWhenCellsLoseFocus={true}
      />
    </div>
  );
};

export default TaskList;
