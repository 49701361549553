import { t } from 'i18next';
import ReactDOM from 'react-dom';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { SEND_EMP_SURVEY } from '_components/CarlendarPlan/EmployeeV2/constants/modal';
import EmpTable from 'components/CalendarPlan/CalendarPlanEmployee/CalendarPlanSendSurveyForm/EmpTable';

import { Toast } from 'helper/alert';
import axiosClient from 'axios/axiosClient';
import { openModal } from '_components/Modal/slice';

import { CloseIcon, SendScheduleIcon, SendBrownIcon } from 'assets/img';
import { selectAuth } from 'redux/auth/authSlice';

const SendScheduleButton = (props) => {
  const dispatch = useDispatch();
  const { isEditabled } = useSelector(selectAuth);

  const { unitSelected, alter = false, disabled = false, style = {}, planID, versionID } = props;
  const [selectedRows, setSelectedRows] = useState({});
  const totalEmployees = Object.keys(selectedRows).length;
  const isDisabled = totalEmployees === 0;

  const [toggleSendSchedule, setToggleSendSchedule] = useState(false);

  const { mutate: sendSchedule, isLoading } = useMutation(async () => {
    const rowsData = Object.entries(selectedRows);
    const email = rowsData.reduce((acc, [rowId, row]) => (row.email ? [...acc, rowId] : acc), []);
    const phone = rowsData.reduce(
      (acc, [rowId, row]) => (row.phone_number ? [...acc, rowId] : acc),
      [],
    );
    const payload = {
      email,
      phone,
      planID,
      versionID,
    };
    await axiosClient
      .post('/send-schedule', payload)
      .then((_) => {
        Toast({ icon: 'success', title: `${t('send')} ${t('succes')}` });
      })
      .catch((err) => {
        Toast({ icon: 'error', title: `${t('send')} ${t('unsucces')}` });
      });
  });

  const handleResetDefault = () => {
    setSelectedRows({});
  };

  const handleSend = () => {
    sendSchedule();
  };

  const modal = toggleSendSchedule && (
    <div className="schedule-modal">
      <div className="schedule-modal__background" onClick={() => setToggleSendSchedule(false)} />

      <div className="schedule-modal__form" style={{ height: 'auto' }}>
        <div className="schedule-modal__form__header">
          <p className="schedule-modal__form__header-title">{t('send-schedule')}</p>

          <div
            className="schedule-modal__form__header-icon"
            onClick={() => setToggleSendSchedule(false)}
          >
            <img src={CloseIcon} />
          </div>
        </div>

        <div className="schedule-modal__info">
          <div>
            <p>{t('selected-employee')}</p>
            <p>{totalEmployees}</p>
          </div>
        </div>

        <div className="schedule-modal__emp-table">
          <EmpTable
            unitSelected={unitSelected}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        </div>

        <div className="schedule-modal__btns">
          <button onClick={handleResetDefault}>{t('reset-default')}</button>
          <button
            className={isDisabled ? 'send-disabled' : ''}
            disabled={isDisabled || isLoading}
            onClick={handleSend}
          >
            {t('send')}
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {alter ? (
        <button
          // onClick={() => setToggleSendSchedule(!toggleSendSchedule)}
          onClick={() => dispatch(openModal({ name: SEND_EMP_SURVEY }))}
          className="primary-btn send-btn"
          disabled={disabled || !isEditabled}
          style={style}
        >
          <img src={SendBrownIcon} /> {t('send-schedule')}
        </button>
      ) : (
        <div className="employee-action__wrap">
          <img src={SendScheduleIcon} onClick={() => setToggleSendSchedule(!toggleSendSchedule)} />
        </div>
      )}

      {ReactDOM.createPortal(modal, document.body)}
    </>
  );
};

export default SendScheduleButton;
