import { LONG_SHIFT_VALUE, NIGHT_SHIFT_VALUE, offShiftReg } from 'constant';
import moment from 'moment';

const WEEKDAY_VALUE = {
  //Value base on momentjs
  SUNDAY_VALUE: 0,
  MONDAY_VALUE: 1,
  TUESDAY_VALUE: 2,
  WEDNESDAY_VALUE: 3,
  THURSDAY_VALUE: 4,
  FRIDAY_VALUE: 5,
  SATURDAY_VALUE: 6,
};

const getPrevWeekendShift = ({ taskDate, employeeTaskList, taskWeekdayValue }) => {
  const prevSunday = moment(taskDate).subtract(taskWeekdayValue, 'days');
  const sundayShift = employeeTaskList[prevSunday];
  if (sundayShift) {
    const sundayData = sundayShift[0];
    if (!offShiftReg.test(sundayData.taskName)) {
      return sundayData;
    }
  }
  return null;
};
const checkingOffAfterWorkingNightAndLongShiftWeekend = ({ taskData, employeeTaskList }) => {
  const listDateChecking = [WEEKDAY_VALUE.MONDAY_VALUE];
  const { date: taskDate, taskType } = taskData;

  const taskWeekdayValue = moment(taskDate).weekday();

  //Ignore date not in list
  // if (!listDateChecking.includes(taskWeekdayValue)) return true;

  const prevWeekendShift = getPrevWeekendShift({
    taskDate,
    employeeTaskList,
    taskWeekdayValue,
  });

  //Ignore if employee not working previous weekend or it's night,long shift
  if (
    !prevWeekendShift ||
    (prevWeekendShift.taskType !== NIGHT_SHIFT_VALUE &&
      prevWeekendShift.taskType !== LONG_SHIFT_VALUE)
  ) {
    return true;
  } else {
    return false;
  }
};

export default { checkingOffAfterWorkingNightAndLongShiftWeekend };
