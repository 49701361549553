import { closeModal } from '_components/Modal/slice';
import { CloseBlackIcon } from 'assets/img';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import './style.scss';

const ModalLayout = ({ children }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModal());
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden'; // Prevent scrolling in the background

    return () => {
      document.body.style.overflow = 'auto'; // Re-enable scrolling when the component unmounts
    };
  }, []);

  return (
    <div className="modal__wrapper" id="global-modal">
      <div className="modal__background" onClick={handleClose}></div>
      <div className="modal__body">
        <div className="modal-icon">
          <img src={CloseBlackIcon} onClick={handleClose} />
        </div>
        <div className="modal__main-section">{children}</div>
      </div>
    </div>
  );
};

export default ModalLayout;
