import { t } from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';
import GroupList from '../GroupList/GroupList';
import { DeleteIcon, DeleteIconBlack, PlusBrownIcon } from 'assets/img';
import UnitSelectBox from './UnitSelectBox';
import { useSelector } from 'react-redux';
import { selectAuth } from 'redux/auth/authSlice';
const FormField = ({
  label,
  type,
  name,
  value = '',
  onChange,
  placeholder = '',
  disabled,
  required,
}) => (
  <label>
    <span>
      {t(label)}
      {required ? <span className="required">*</span> : ''}
    </span>

    <input
      disabled={disabled}
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={t(placeholder)}
    />
  </label>
);
const PositionInfo = ({
  roleList,
  isEditabled,
  roleTypeList,
  positionDataDefault,
  isCreating,
  index,
  departmentList,
  positionList,
  handleSelectUnit,
  handleDeleteGroup,
  handleOpenAddGroup,
  handleDeletePosition,
  handleOnChangePositionList,
}) => {
  const { role } = useSelector(selectAuth);
  const [positionData, setPositionData] = useState({});
  const handleOnChange = (e) => {
    const { value, name } = e.target;
    handleOnChangePositionList(e, positionData.id, index);
    if (name === 'job_percentage') {
      setPositionData({
        ...positionData,
        [name]: value > 100 ? 100 : value < 0 ? 0 : value,
      });
      return;
    }
    setPositionData({ ...positionData, [name]: value });
  };
  useEffect(() => {
    if (positionDataDefault) {
      setPositionData({ ...positionData, ...positionDataDefault });
    }
  }, [positionDataDefault, positionList]);
  const positionSubFix = useMemo(() => {
    if (isCreating) return t('new');
    if (index > 0) {
      return index;
    }
  }, [isCreating]);
  return (
    <div>
      <p>
        {t('position')} {positionSubFix}
      </p>
      <div className="form-info">
        <div className="row">
          <label>
            <span>
              {t('role')}
              <span className="required">*</span>
            </span>

            <select
              value={+positionData.role_id}
              onChange={handleOnChange}
              name="role_id"
              disabled={!isEditabled}
              required
            >
              <option>{t('select')}</option>
              {roleList.map((role) => (
                <option key={role.id} value={role.id}>
                  {role.role}
                </option>
              ))}
            </select>
          </label>
          <FormField
            label="job-percentage"
            type="text"
            name="job_percentage"
            value={positionData.job_percentage}
            onChange={handleOnChange}
            disabled={!isEditabled}
            required={true}
          />
        </div>
        <div className="row">
          <label>
            <span>
              {t('unit-header')} <span className="required">*</span>
            </span>
            <UnitSelectBox
              departmentList={departmentList}
              value={+positionData.unit_code}
              handleOnChange={handleOnChange}
              name="unit_code"
              disabled={!isEditabled}
              positionData={positionData}
              handleSelectUnitPosition={handleSelectUnit}
              index={index}
              positionList={positionList}
            />
          </label>
        </div>

        <div className="row">
          <label>
            <span>
              {t('role-type')} <span className="required">*</span>
            </span>
            <select
              value={+positionData.role_type}
              onChange={handleOnChange}
              name="role_type"
              disabled={!isEditabled}
            >
              <option>{t('select')}</option>
              {roleTypeList.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.type}
                </option>
              ))}
            </select>
          </label>

          {role.toLowerCase() !== 'unions' ? (
            <FormField
              label="salary"
              type="number"
              name="salary"
              value={positionData.salary}
              onChange={handleOnChange}
              disabled={!isEditabled}
            />
          ) : null}
        </div>
        <div className="row">
          <FormField
            label="valid-from"
            type="date"
            name="valid_from"
            value={positionData.valid_from}
            onChange={handleOnChange}
            disabled={!isEditabled}
            placeholder="dd/mm/yyyy"
          />
          <FormField
            label="valid-to"
            type="date"
            name="valid_to"
            value={positionData.valid_to}
            onChange={handleOnChange}
            disabled={!isEditabled}
            placeholder="dd/mm/yyyy"
          />
        </div>
        <div className="row">
          <label htmlFor="">
            <GroupList
              employeeGroupList={positionData.employeeGroups}
              handleDeleteGroup={handleDeleteGroup}
              positionData={positionData}
            />
            {isEditabled ? (
              <div className="position-info-action">
                <div
                  className="add-group-btn"
                  onClick={() => {
                    handleOpenAddGroup(positionData, index);
                  }}
                >
                  <button>
                    <img src={PlusBrownIcon} alt="" width={18} />
                  </button>
                  <span htmlFor="">
                    {t('create')} {t('groups')}
                  </span>
                </div>
                {index > 0 ? (
                  <div
                    className="delete-action"
                    onClick={() => {
                      handleDeletePosition(positionData.id, index);
                    }}
                  >
                    <img src={DeleteIconBlack} alt="" width={14} />
                    <span htmlFor="">
                      {t('delete')} {t('position').toLowerCase()}
                    </span>
                  </div>
                ) : null}
              </div>
            ) : null}
          </label>
        </div>
      </div>
    </div>
  );
};

export default PositionInfo;
