import { v4 as uuidv4 } from 'uuid';
import DateOnlyItem from './DateOnlyItem';
import React, { useEffect, useState, useMemo } from 'react';

import './AnswerItem.scss';
import RangeDate from './RangeDate';
import NewRangeTime from './NewRangeTime';
import RangeDateOnly from './RangeDateOnly';
import SelectWeekItem from './SelectWeekItem';
import CheckboxTextItem from './CheckboxTextItem';
import { CLINICSL_ENV, HOSPITAL_ENV, MULTIPLE_CHOICE, TYPE_SURVEY_QUESTION } from 'constant';
import WeekdayTypeShift from './WeekdayTypeShift';
import { useSelector } from 'react-redux';
import { selectGlobal } from 'redux/global/globalSlice';
import { AddMore } from 'assets/img';
import { t } from 'i18next';
import HolidayTypeShift from './HolidayTypeShift';
const LONG_SHIFT_HOSPITAL = 'Long shift (3. weekend rotation)';
const LONG_SHIFT_CLINICSL = [
  'Long shift (4. weekend rotation)',
  'Long shift (2. weekend rotation)',
];
const onlyOneDateInputQuestion = [
  'Which calendar week do you start with the first weekend in the new calendar plan?',
  'Hvilken kalenderuke starter du med første helg i ny kalenderplan?',
];

const AnswerItem = ({
  parentAnswerType,
  answer,
  answerKeys,
  surveyRecord,
  questionData,
  handleOnChange,
  index,
  setSurveyRecord,
  isDisabled,
  listTopic,
  holidayList,
}) => {
  const { customerType } = useSelector(selectGlobal);
  const isHospital = customerType === HOSPITAL_ENV;
  const APP_SITE = process.env.REACT_APP_SITE;
  const hospitalHiddenAnswer = ['5.each', '5. hver', '8.each', '8. hver'];
  const komummneHiddenAnswer = ['3/3/2.each', '3/3/2. hver'];
  const answerKey = Object.keys(answer)[0];

  if (hospitalHiddenAnswer.includes(answerKey) && isHospital) {
    return;
  }
  if (komummneHiddenAnswer.includes(answerKey) && !isHospital) {
    return;
  }
  const typeQuestionParent = parentAnswerType === 'date' ? 'radio' : parentAnswerType;
  const value = {
    en: Object.keys(questionData?.questionChoice[index])[0],
    no: answerKeys[0],
    id: answer[answerKeys[0]],
    type: typeQuestionParent,
  };

  // Chcecking choice for long shift weekend
  if (customerType === HOSPITAL_ENV) {
    if (LONG_SHIFT_CLINICSL.includes(value.en)) {
      return;
    }
  }
  // if (customerType === CLINICSL_ENV) {
  //   if (value.en === LONG_SHIFT_HOSPITAL) {
  //     return;
  //   }
  // }

  const questionTopic = listTopic?.find((item) => item.uuid === questionData.topicId);

  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    if (
      Array.isArray(surveyRecord[questionData?.parentId]) &&
      parentAnswerType !== TYPE_SURVEY_QUESTION.TEXT &&
      parentAnswerType !== TYPE_SURVEY_QUESTION.CHECK_BOX_TEXT &&
      parentAnswerType !== TYPE_SURVEY_QUESTION.SELECT_WEEK &&
      parentAnswerType !== TYPE_SURVEY_QUESTION.DATE &&
      parentAnswerType !== TYPE_SURVEY_QUESTION.RANGE_DATE_ONLY &&
      parentAnswerType !== TYPE_SURVEY_QUESTION.HOLIDAY_TYPE_SHIFT &&
      parentAnswerType !== TYPE_SURVEY_QUESTION.DATE_ONLY
    ) {
      switch (parentAnswerType) {
        case 'radio':
          setIsChecked(
            +surveyRecord[questionData?.parentId]?.en === +value.en ||
              +surveyRecord[questionData?.parentId]?.en === +value.no,
          );
          break;

        default:
          setIsChecked(
            surveyRecord[questionData?.parentId]?.some(
              (item) => item.en === value.en || item.no === value.no,
            ),
          );
          break;
      }
    }
  }, [surveyRecord, questionData]);

  const itemTypes = [TYPE_SURVEY_QUESTION.MULTIPLE_CHOICE, TYPE_SURVEY_QUESTION.SINGLE_CHOICE];

  const itemWidthClass = useMemo(
    () =>
      `calendar-plan-survey-form__answer-item ${
        itemTypes.includes(parentAnswerType) ? 'auto-width' : ''
      }`,
    [parentAnswerType],
  );

  const handleAddQuestion = () => {
    const id = questionData?.parentId;
    const arr = surveyRecord[id]?.dateArr || [''];
    setSurveyRecord((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        dateArr: [...arr, ''],
      },
    }));
  };
  const handleAddHolidayQuestion = () => {
    const id = questionData?.parentId;
    const arr = surveyRecord[id]?.data || [{ typeShift: '', date: '' }];

    if (surveyRecord[id]) {
      setSurveyRecord((prev) => ({
        ...prev,
        [id]: {
          ...prev[id],
          data: [...arr, { typeShift: '', date: '' }],
        },
      }));
    }
  };

  const handleRemoveQuestion = (ind) => {
    const id = questionData?.parentId;
    const arr = surveyRecord[id]?.dateArr;
    const newArr = arr.filter((_, index) => index !== ind);

    setSurveyRecord((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        dateArr: newArr,
      },
    }));
  };

  const handleAddRangeDate = () => {
    const arr = surveyRecord[questionData?.parentId] || [''];
    setSurveyRecord((prev) => ({
      ...prev,
      [questionData?.parentId]: [...arr, { fromDate: '', toDate: '', type: 'date' }],
    }));
  };
  const addMoreContent = questionTopic?.no_ver === 'FERIE' ? 'add-holiday' : 'add-date-off';
  return (
    <div className={itemWidthClass}>
      {parentAnswerType === TYPE_SURVEY_QUESTION.DATE ? (
        <>
          <RangeDate
            questionId={questionData?.parentId}
            surveyRecord={surveyRecord}
            setSurveyRecord={setSurveyRecord}
            isManager
            isDisabled={isDisabled}
          />
          {!isDisabled && (
            <div className="add-more-wraper" onClick={handleAddRangeDate}>
            <img src={AddMore} />
            <span>{t(addMoreContent)}</span>
          </div>
          )}
          
        </>
      ) : null}
      {/* {parentAnswerType === TYPE_SURVEY_QUESTION.DATE ? (
        <NewRangeDate
          questionId={questionData?.parentId}
          surveyRecord={surveyRecord}
          setSurveyRecord={setSurveyRecord}
        />
      ) : null} */}
      {parentAnswerType === TYPE_SURVEY_QUESTION.HOLIDAY_TYPE_SHIFT ? (
        <div className="multiple-days">
          {Array.from({
            length: surveyRecord?.[questionData?.parentId]?.data?.length || 3,
          }).map((_, index) => {
            return (
              <HolidayTypeShift
                key={uuidv4()}
                questionId={questionData?.parentId}
                surveyRecord={surveyRecord}
                setSurveyRecord={setSurveyRecord}
                index={index}
                isDisabled={isDisabled}
                holidayList={holidayList}
              />
            );
          })}
          {!isDisabled && (
            <div className="add-more-wraper" onClick={handleAddHolidayQuestion}>
            <img src={AddMore} />
            <span>{t('add-more-2')}</span>
          </div>
          )}
          
        </div>
      ) : null}
      {parentAnswerType === TYPE_SURVEY_QUESTION.RANGE_DATE_ONLY ? (
        <RangeDateOnly
          questionId={questionData?.parentId}
          surveyRecord={surveyRecord}
          setSurveyRecord={setSurveyRecord}
          className={'manager-range-date'}
          isDisabled={isDisabled}
        />
      ) : null}

      {parentAnswerType === TYPE_SURVEY_QUESTION.DATE_ONLY ? (
        <div className="multiple-days">
          {Array.from({
            length: onlyOneDateInputQuestion.includes(questionData.questionContent.trim())
              ? 1
              : surveyRecord?.[questionData?.parentId]?.dateArr?.length || 3,
          }).map((_, index) => {
            return (
              <DateOnlyItem
                key={uuidv4()}
                questionId={questionData?.parentId}
                surveyRecord={surveyRecord}
                setSurveyRecord={setSurveyRecord}
                index={index}
                isHoliday={questionTopic.topic === 'HOLIDAYS'}
                isDisabled={isDisabled}
                handleRemoveQuestion={() => handleRemoveQuestion(index)}
                holidayList={holidayList}
              />
            );
          })}
          {!isDisabled && (
            <div className="add-more-wraper" onClick={handleAddQuestion}>
              <img src={AddMore} />
              <span>{t('add-more-2')}</span>
            </div>
          )}
          
        </div>
      ) : null}
      {parentAnswerType === TYPE_SURVEY_QUESTION.SELECT_WEEK ? (
        <SelectWeekItem
          key={uuidv4()}
          questionId={questionData?.parentId}
          surveyRecord={surveyRecord}
          setSurveyRecord={setSurveyRecord}
          index={index}
          isDisabled={isDisabled}
        />
      ) : null}
      {parentAnswerType === TYPE_SURVEY_QUESTION.TIME ? (
        <NewRangeTime
          questionId={questionData?.parentId}
          surveyRecord={surveyRecord}
          setSurveyRecord={setSurveyRecord}
          isExpired={isDisabled}
        />
      ) : null}
      {parentAnswerType === TYPE_SURVEY_QUESTION.CHECK_BOX_TEXT && (
        <CheckboxTextItem
          answer={answer}
          answerKeys={answerKeys}
          isChecked={isChecked}
          isDisabled={isDisabled}
          questionData={questionData}
          setSurveyRecord={setSurveyRecord}
          surveyRecord={surveyRecord}
          value={value}
        />
      )}
      {parentAnswerType === TYPE_SURVEY_QUESTION.SINGLE_CHOICE && (
        <div className="parent-question-radio">
          <input
            type={parentAnswerType}
            name={questionData?.parentId}
            id={answer[answerKeys[0]]}
            value={answerKeys[0]}
            checked={
              surveyRecord[questionData?.parentId]?.en === value.en ||
              surveyRecord[questionData?.parentId]?.en === value.no
            }
            disabled={isDisabled ? !!isDisabled : false}
            onChange={(e) => {
              const { name, type } = e.target;

              setIsChecked(true);
              if (type === 'radio') {
                const updatedItems = { ...value, type: 'single' };

                setSurveyRecord({
                  ...surveyRecord,
                  [name]: updatedItems,
                });
              } else {
                setSurveyRecord({
                  ...surveyRecord,
                  [name]: { ...value, type: 'single' },
                });
              }
            }}
          />
          <label htmlFor={answer[answerKeys[0]]}>{answerKeys[0]}</label>
        </div>
      )}

      {parentAnswerType === TYPE_SURVEY_QUESTION.TEXT && (
        <input
          className="text-input"
          type={parentAnswerType}
          name={questionData?.parentId}
          id={answer[answerKeys[0]]}
          value={surveyRecord[questionData.parentId]}
          onChange={handleOnChange}
          disabled={isDisabled ? !!isDisabled : false}
        />
      )}

      {parentAnswerType === TYPE_SURVEY_QUESTION.MULTIPLE_CHOICE && (
        <div className="question-checkbox-container">
          <label htmlFor={answer[answerKeys[0]]}>
            <input
              type={parentAnswerType}
              name={questionData?.parentId}
              id={answer[answerKeys[0]]}
              value={answerKeys[0]}
              checked={isChecked}
              disabled={isDisabled ? !!isDisabled : false}
              onChange={(e) => {
                const { name, type } = e.target;

                if (type === TYPE_SURVEY_QUESTION.MULTIPLE_CHOICE) {
                  const existingItems = surveyRecord[name] || [];
                  const itemExists = existingItems?.some(
                    (item) => item.en === value.en && item.no === value.no,
                  );

                  let updatedItems;
                  if (itemExists) {
                    updatedItems = existingItems.filter(
                      (item) => item.en !== value.en && item.no !== value.no,
                    );
                  } else {
                    updatedItems = [...existingItems, { ...value, type: MULTIPLE_CHOICE }];
                  }

                  setSurveyRecord({
                    ...surveyRecord,
                    [name]: updatedItems,
                  });
                } else {
                  setSurveyRecord({
                    ...surveyRecord,
                    [name]: value,
                  });
                }
              }}
            />
            {answerKeys[0]}
          </label>
        </div>
      )}
      {parentAnswerType === TYPE_SURVEY_QUESTION.WEEKDAY_TYPE_SHIFT && (
        <WeekdayTypeShift
          questionId={questionData?.parentId}
          surveyRecord={surveyRecord}
          setSurveyRecord={setSurveyRecord}
          className={'weekday-type-shift'}
          isDisabled={isDisabled}
        />
      )}
    </div>
  );
};

export default AnswerItem;
