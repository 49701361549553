import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { t } from 'i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';

import DropdownSelect from './DropdownSelect';
import { CloseIcon, CalenderIcon } from 'assets/img';
import { reset, toggleFilter, updateFilter } from 'redux/generateTableFilter/slice';
import { selectGeneratorColApi } from 'redux/generateTableApi/slice';

const Input = ({ id, type, label, placeholder = '', icon = '', onChange, onBlur, value }) => (
  <label htmlFor={id}>
    {label}
    <input
      type={type}
      id={id}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      style={
        icon
          ? {
              backgroundImage: `url(${icon})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right 18px center',
              backgroundSize: '14px 14px',
              cursor: 'pointer',
            }
          : {}
      }
    />
  </label>
);

const GeneralFilter = ({
  gridColumnApi,
  setToggleFilterModal,
  handleDateChange,
  showDateRange,
  filters,
  filterRoleList,
  setIsExternalFilterPresent,
}) => {
  const dispatch = useDispatch();
  const { id, name, assignedShift, jobPercentage, customDateRange } = filters;
  const { taskList, empInfo, dashboard } = useSelector(selectGeneratorColApi);

  const hanldeReset = async () => {
    setIsExternalFilterPresent(false);
    const allColumns = gridColumnApi.getColumns();
    allColumns.forEach((column) => {
      if (!column.getColId().startsWith('emp-')) {
        gridColumnApi.setColumnVisible(column.getColId(), true);
      }
    });

    await dispatch(reset());

    if (empInfo.api && dashboard.api && taskList.api) {
      empInfo.api.onFilterChanged();
      dashboard.api.onFilterChanged();
      taskList.api.onFilterChanged();
    }
  };

  const handleApplyFilter = useCallback(async () => {
    await setIsExternalFilterPresent(true);
    if (customDateRange.from || customDateRange.to) {
      showDateRange();
    }

    if (empInfo.api && dashboard.api && taskList.api) {
      empInfo.api.onFilterChanged();
      dashboard.api.onFilterChanged();
      taskList.api.onFilterChanged();
    }

    dispatch(toggleFilter(true));
    setToggleFilterModal(false);
    await setIsExternalFilterPresent(false);
  }, [filters]);

  const onInputChange = (key, value, isNested = false, nestedKey = '') => {
    dispatch(updateFilter({ key, value, isNested, nestedKey }));
  };

  // toggle weekdays and holidays

  const checkDateInRange = (colId) => {
    if (colId.startsWith('emp-')) {
      return true;
    }
    const dateStr = colId.split('-').slice(1, -1).join('-');
    const date = moment(dateStr, 'YYYY-MM-DD');
    const from = moment(customDateRange.from, 'YYYY-MM-DD');
    const to = moment(customDateRange.to, 'YYYY-MM-DD');

    if (!customDateRange.from && !customDateRange.to) {
      return true;
    }
    if (customDateRange.from && !customDateRange.to) {
      return date.isSameOrAfter(from);
    }
    if (!customDateRange.from && customDateRange.to) {
      return date.isSameOrBefore(to);
    }
    return date.isSameOrAfter(from) && date.isSameOrBefore(to);
  };

  const toggleColumnVisibility = (condition, visibility, setVisibility) => {
    const allColumns = taskList.colApi.getColumns();
    allColumns.forEach((column) => {
      const colId = column.getColId();
      if (condition(colId)) {
        taskList.colApi.setColumnVisible(colId, visibility);
        setVisibility(!visibility);
      }
    });
  };

  const toggleWeekdays = () => {
    toggleColumnVisibility(
      (colId) => {
        const isWeekend = colId.startsWith('weekdays');
        const isInRange = checkDateInRange(colId);
        return (
          isWeekend && (!filters.toggleHolidays || !colId.endsWith('not_holidays')) && isInRange
        );
      },
      filters.toggleWeekdays,
      (newState) => dispatch(updateFilter({ key: 'toggleWeekdays', value: newState })),
    );
  };

  const toggleHolidays = () => {
    toggleColumnVisibility(
      (colId) => {
        const isHoliday = colId.endsWith('not_holidays');
        const isInRange = checkDateInRange(colId);
        return isHoliday && (!filters.toggleWeekdays || !colId.startsWith('weekdays')) && isInRange;
      },
      filters.toggleHolidays,
      (newState) => dispatch(updateFilter({ key: 'toggleHolidays', value: newState })),
    );
  };

  return (
    <>
      <div className="modal-background" onClick={() => setToggleFilterModal(false)} />
      <div className="general-filter">
        <div className="general-filter__header">
          <p>{t('general-filter')}</p>
          <div onClick={() => setToggleFilterModal(false)}>
            <img src={CloseIcon} />
          </div>
        </div>

        <div className="general-filter__form">
          <div className="general-filter__form-toggle">
            <p>
              {t('show-weekend')}
              <input type="checkbox" checked={filters.toggleWeekdays} onChange={toggleWeekdays} />
            </p>
            <p>
              {t('show-holidays')}
              <input type="checkbox" checked={filters.toggleHolidays} onChange={toggleHolidays} />
            </p>
          </div>

          <div className="general-filter__form-range">
            <Input
              id="from"
              type="date"
              label={t('from')}
              icon={CalenderIcon}
              value={customDateRange.from}
              onChange={handleDateChange('from')}
              onBlur={handleDateChange('from')}
            />
            <Input
              id="to"
              type="date"
              label={t('to')}
              icon={CalenderIcon}
              value={customDateRange.to}
              onChange={handleDateChange('to')}
              onBlur={handleDateChange('to')}
            />
          </div>

          <div className="general-filter__form-info">
            <Input
              id="name"
              type="text"
              label={t('emp-name')}
              placeholder={`${t('search')} ${t('employee-low')}`}
              value={name}
              onChange={(e) => onInputChange('name', e.target.value)}
            />
          </div>

          <div className="general-filter__form-role-match">
            <Input
              id="role-match-from"
              type="number"
              label={t('role-match-from')}
              placeholder={t('enter-number')}
              value={assignedShift.from}
              onChange={(e) =>
                onInputChange('from', parseInt(e.target.value), true, 'assignedShift')
              }
            />
            <Input
              id="role-match-to"
              type="number"
              label={t('role-match-to')}
              placeholder={t('enter-number')}
              value={assignedShift.to}
              onChange={(e) => onInputChange('to', parseInt(e.target.value), true, 'assignedShift')}
            />
          </div>

          <div className="general-filter__form-role">
            <label htmlFor={id}>
              {t('role')}
              <DropdownSelect options={filterRoleList} filters={filters} />
            </label>
          </div>

          <div className="general-filter__form-job-percentage">
            <Input
              id="job-percentage-from"
              type="number"
              label={t('job-percentage-from')}
              placeholder={t('enter-number')}
              value={jobPercentage.from}
              onChange={(e) =>
                onInputChange('from', parseInt(e.target.value), true, 'jobPercentage')
              }
            />
            <Input
              id="job-percentage-to"
              type="number"
              label={t('job-percentage-to')}
              placeholder={t('enter-number')}
              value={jobPercentage.to}
              onChange={(e) => onInputChange('to', parseInt(e.target.value), true, 'jobPercentage')}
            />
          </div>
        </div>

        <div className="general-filter__actions">
          <button onClick={hanldeReset}>
            {t('reset')} {t('filter-low')}
          </button>
          <button onClick={handleApplyFilter}>{t('save')}</button>
        </div>
      </div>
    </>
  );
};

export default GeneralFilter;
