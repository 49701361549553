import React, { forwardRef, memo, useEffect, useImperativeHandle, useState } from 'react';
import { useTurnusContext } from '../../Turnus/TurnusContext';
import { t } from 'i18next';
import { revertCleanedShift } from './helper/revertCleanedShift';

const RevertCleanVakantShift = ({
  employeeData,
  taskData,
  stopEditing,
  setEmployeeData,
  roleList,
  workingEnvSetup,
  listEmpData,
}) => {
  return (
    <div className="revert-clean-vakant-shift__wrap">
      <div className="revert-clean-vakant-shift">
        <h5 className="title">{t('roll-back-shift')}</h5>
        <table className="revert-clean-vakant-shift__info">
          <thead>
            <th>{t('date')}</th>
            <th>{t('task-name')}</th>
          </thead>
          <tbody>
            <tr>
              <td>{taskData.date}</td>
              <td>{taskData.taskName}</td>
            </tr>
          </tbody>
        </table>
        <div className="revert-clean-vakant-shift__action">
          <button
            className="custom-btn outline-btn"
            onClick={() => {
              revertCleanedShift({
                employeeData,
                taskData,
                setEmployeeData,
                listEmpData,
                workingEnvSetup,
                roleList,
              });
            }}
          >
            {t('yes-question')}
          </button>
          <button className="custom-btn" onClick={stopEditing}>
            {t('no-question')}
          </button>
        </div>
      </div>
    </div>
  );
};

const TaskEditComponent = forwardRef((params, ref) => {
  const { onValueChange, stopEditing, isPlanHaveLayer, groupList } = params;

  const [value, setValue] = useState(params.value);
  const turnusData = useTurnusContext();
  const { layerList, taskArr } = turnusData;
  const [layerSelected, setLayer] = useState({});
  const [taskSelected, setTaskSelected] = useState({});
  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },
    };
  });
  const dateInWeek = params.dateInWeek;
  if (params.data) {
    const taskList = params.data.task_arr;
    const dateData = taskList[dateInWeek];
    if (dateData && dateData.length) {
      if (dateData[0].isAssignByCleanVakant) {
        return;
      }
    }
  }
  const handleSelectTask = (task) => {
    setTaskSelected({ ...task, layerID: layerSelected.id, layerName: layerSelected.name });
    const editData = {
      taskID: task.taskId,
      groupID: task.group_id,
      roleID: task.roleId,
      planDetailID: task.planDetailId,
    };
    onValueChange(JSON.stringify(editData));
    setValue(task.taskName);
    stopEditing();
    //Function valueSetter will handle and push to BE
  };

  //MAYBE USE LATER
  // const taskOnDate = data.task_arr[dateInWeek];
  // if (taskOnDate) {
  //   const taskData = taskOnDate[0];
  //   const { isAssignByCleanVakant } = taskData;
  //   if (isAssignByCleanVakant) {
  //     return (
  //       <RevertCleanVakantShift
  //         employeeData={data}
  //         taskData={taskData}
  //         stopEditing={stopEditing}
  //         setEmployeeData={setEmployeeData}
  //         roleList={roleList}
  //         workingEnvSetup={workingEnvSetup}
  //         listEmpData={listEmpData}
  //       />
  //     );
  //   }
  // }

  useEffect(() => {
    if (!isPlanHaveLayer) {
      setLayer({ name: t('none-layer') });
    }
  }, [isPlanHaveLayer]);
  return (
    <div
      className="task-edit__wrap"
      onMouseLeave={() => {
        isPlanHaveLayer && setLayer({});
      }}
    >
      {isPlanHaveLayer ? (
        <div className="task-edit__group">
          {[...layerList, { id: null, name: t('none-layer') }].map((item) => {
            return (
              <div
                className={`task-edit__item ${item.name === layerSelected.name ? 'selected' : ''}`}
                key={`${item.id}-${item.name}`}
                onMouseEnter={() => {
                  setLayer(item);
                }}
              >
                {item.name}
              </div>
            );
          })}
        </div>
      ) : null}

      {Object.keys(layerSelected).length ? (
        <div
          className={`task-edit__list 
        ${!isPlanHaveLayer ? 'have-layer' : ''}
        `}
        >
          {taskArr.map((item) => {
            const groupFound = groupList.find((group) => group.id === item.group_id);
            return (
              <div
                className={`task-edit__list-item 
                ${taskSelected.taskId === item.taskId ? 'selected' : ''} 
                `}
                key={`${item.taskName}-${item.taskId}`}
                onClick={() => {
                  handleSelectTask(item);
                }}
              >
                {item.taskName} {item.roleName ? ` | ${item.roleName}` : ''}{' '}
                {groupFound ? ` | ${groupFound.name}` : ''}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
});

export default memo(TaskEditComponent);
