import axios from 'axios';
import { Toast } from 'helper/alert';
import { t } from 'i18next';
import { Cookies } from 'react-cookie';

import { clearStorage, loadSession, loadState } from 'helper/localStorage';
import globalRouter from 'app/globalRouter';

const cookies = new Cookies();
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    'Content-Type': `application/json`,
  },
});

axiosClient.interceptors.request.use((config) => {
  const token = cookies.get('accessToken');
  const employeeToken = cookies.get('employeeToken');
  config.headers['Authorization'] = 'Bearer ' + token;
  config.headers['AuthorizationEmployee'] = 'Bearer ' + employeeToken;
  config.headers['withCredentials'] = true;
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data;
    }

    return response;
  },
  ({ response, config }) => {
    if (
      response?.status === 400 &&
      response.data.message === 'A user with this email already exists'
    ) {
      Toast({ icon: 'error', title: 'A user with this email already exists' });
    }

    if (response?.status === 500 && !config.disableToast) {
      Toast({ icon: 'error', title: `${t('fetch-data')} ${t('unsuccess')}` });
    }
    if ((response?.status === 403 || response?.status === 401) && !config.disableToast) {
      const token = cookies.get('accessToken');
      if (token?.length > 0) {
        clearStorage();
        globalRouter.navigate('/');
      }
      clearStorage();

      if (response.data && response.data.msg) {
        Toast({ icon: 'warning', title: response.data.msg });
      }
    }

    return Promise.reject(response);
  },
);
export default axiosClient;
