import { useGroups } from 'Hooks/useData';
import { CheckGreenIcon, CloseBlackIcon } from 'assets/img';
import axiosClient from 'axios/axiosClient';
import { Toast } from 'helper/alert';
import { t } from 'i18next';
import { useEditingContext } from 'providers';
import React, { useState } from 'react';

const CalendarPlanGroupRowNew = ({ setActive, unitSelected }) => {
  const { refetch: refetchGroup } = useGroups({ unitSelected });
  const [group, setGroup] = useState({ name: '', description: '', unit_code: unitSelected });
  const handleOnChange = (e) => {
    setGroup({ ...group, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    let isValid = true;
    for (const key of Object.keys(group)) {
      if (group[key].length == 0 && key !== 'description') {
        isValid = false;
      }
    }
    if (isValid) {
      axiosClient
        .post('calendar-plan/group/create', { group })
        .then((_) => {
          Toast({ icon: 'success', title: `${t('create-group')} ${t('success')}` });
          refetchGroup();
          setActive(false);
        })
        .catch((err) => {
          Toast({ icon: 'error', title: `${t('create-group')} ${t('unsuccess')}` });
        });
    } else {
      Toast({ icon: 'error', title: `${t('please-input-all')} ` });
    }
  };
  return (
    <tr className="calendar-plan-create-role__item">
      <td className="calendar-plan-create-role__item-label">
        <input
          autoFocus={true}
          name="name"
          type="text"
          value={group.name}
          onChange={handleOnChange}
          required
        />
      </td>
      <td className="calendar-plan-create-role__item-label">
        <input name="key" type="text" value={group.key} onChange={handleOnChange} required />
      </td>

      <td className="calendar-plan-create-role__item-description">
        <input name="description" type="text" value={group.description} onChange={handleOnChange} />
      </td>
      <td className="calendar-plan-create-role__item-action">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: 10,
          }}
        >
          <div onClick={handleSubmit}>
            <img src={CheckGreenIcon} alt="" width={24} />
          </div>
          <div onClick={() => setActive(false)}>
            <img src={CloseBlackIcon} alt="" width={24} />
          </div>
        </div>
      </td>
    </tr>
  );
};

export default CalendarPlanGroupRowNew;
