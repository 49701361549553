import { CaretDownOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useState } from 'react';
import AnswerItem from '../CalendarPlanSurveyForm/ItemInSurvey/AnswerItem';
import ChildQuestion from './ChildQuestion';
import { t } from 'i18next';

import { Toast } from 'helper/alert';

import { LockIcon, UnlockIcon, ToggleOnIcon, ToggleOffIcon } from 'assets/img';

const RenderSubQuestion = ({
  handleOnChange,
  questionData,
  language,
  surveyRecord,
  setSurveyRecord,
  renderNewRangeDate,
  renderRangeDate,
  warningMessage,
  jobPercentage,
}) => {
  const [listSubQuestion, setListSubQuestion] = useState([]);

  useEffect(() => {
    const parentRecord = surveyRecord[questionData?.parentId];

    if (
      questionData?.child &&
      Object.keys(questionData?.child)[0]?.child_question_id !== null &&
      parentRecord
    ) {
      if (Array.isArray(parentRecord)) {
        const listSub = [];
        const listRecord = parentRecord.map((item) => item.en);

        Object.keys(questionData.child).forEach((subQuestion) => {
          const question = questionData.child[subQuestion];
          const parrentActiveAnswer = question.child_parent_answer_choice;
          if (listRecord.includes(parrentActiveAnswer)) {
            listSub.push(subQuestion);
          }
        });
        listSub.sort((a, b) => {
          const indexA = listRecord.indexOf(questionData.child[a].child_parent_answer_choice);
          const indexB = listRecord.indexOf(questionData.child[b].child_parent_answer_choice);
          return indexA - indexB;
        });
        setListSubQuestion(listSub);
      } else {
        const listSub = [];
        Object.keys(questionData.child).forEach((subQuestion) => {
          const question = questionData.child[subQuestion];
          const parrentActiveAnswer = question.child_parent_answer_choice;
          if (parentRecord.en === parrentActiveAnswer || parentRecord.no === parrentActiveAnswer) {
            listSub.push(subQuestion);
          }
        });
        setListSubQuestion(listSub);
      }
    }
  }, [questionData, surveyRecord]);

  return (
    <>
      {questionData?.child !== null && Object.keys(questionData?.child)[0]
        ? listSubQuestion.map((childQuestionKey) => (
            <ChildQuestion
              key={childQuestionKey}
              childQuestionKey={childQuestionKey}
              handleOnChange={handleOnChange}
              parentQuestionData={questionData}
              language={language}
              renderNewRangeDate={renderNewRangeDate}
              renderRangeDate={renderRangeDate}
              surveyRecord={surveyRecord}
              setSurveyRecord={setSurveyRecord}
              warningMessage={warningMessage}
              jobPercentage={jobPercentage}
            />
          ))
        : null}
    </>
  );
};

const ParentQuestion = ({
  parentQuestionData,
  language,
  index,
  renderNewRangeDate,
  surveyRecord,
  setSurveyRecord,
  handleOnChange,
  emp,
  listTopic,
  renderRangeDate,
  listHardRuleQuestion,
  setListHardRuleQuestion,
  listBlockedQuestion,
  setListBlockedQuestion,
  listExpandedQuestionActive,
  warningMessage,
  jobPercentage,
}) => {
  const [questionData, setQuestionData] = useState({});
  const typeQuestionParent = parentQuestionData.parent_question_type;
  const parentAnswerType = {
    single: 'radio',
    text: 'text',
    multiple: 'checkbox',
    date: 'date',
    time: 'time',
    ['checkbox-text']: 'checkbox-text',
    ['date-only']: 'date-only',
    ['select-week']: 'select-week',
    ['range-date-only']: 'range-date-only',
  }[typeQuestionParent];

  // block question
  const [isBlock, setIsBlock] = useState(false);

  useEffect(() => {
    setIsBlock(listBlockedQuestion?.includes(questionData.parentId));
  }, [questionData, listBlockedQuestion]);

  const handleBlockQuestion = () => {
    if (isBlock) {
      const updateBlockList = listBlockedQuestion.filter((item) => item !== questionData.parentId);
      setListBlockedQuestion(updateBlockList);
    } else {
      setListBlockedQuestion([...listBlockedQuestion, questionData.parentId]);
    }
  };

  // lock question
  const lockedTopic = ['Gyldighetsperioder', 'Validity periods.', 'FERIE', 'VACATION'];

  const [isLocked, setIsLocked] = useState(false);

  useEffect(() => {
    let isDefaultLocked = lockedTopic.includes(
      listTopic.find((topic) => topic.uuid === parentQuestionData.parent_question_topic_id)?.topic,
    );

    const isLocked = listHardRuleQuestion?.findIndex(
      (item) =>
        item.question_uuid === parentQuestionData.parent_question_id &&
        (item.is_hard || item.always) &&
        item.employee_id === emp?.id,
    );

    setIsLocked(isDefaultLocked || isLocked > -1);
  }, [listHardRuleQuestion, listTopic, parentQuestionData.parent_question_id]);

  const handleLockQuestion = () => {
    let listHardRuleQuestionUpdate = listHardRuleQuestion;
    const foundQuestionIndex = listHardRuleQuestionUpdate.findIndex(
      (item) => questionData.parentId === item.question_uuid,
    );
    if (listHardRuleQuestionUpdate[foundQuestionIndex]?.always) {
      Toast({ icon: 'error', title: t('question-blocked') });
      return;
    }
    if (isLocked && foundQuestionIndex > -1) {
      listHardRuleQuestionUpdate[foundQuestionIndex].isHard = 0;

      setIsLocked(false);
    } else if (foundQuestionIndex > -1) {
      listHardRuleQuestionUpdate[foundQuestionIndex].isHard = 1;
      setIsLocked(true);
    } else {
      listHardRuleQuestionUpdate.push({
        question_uuid: parentQuestionData.parent_question_id,
        isHard: 1,
      });
      setIsLocked(true);
    }
    setListHardRuleQuestion(listHardRuleQuestionUpdate);
  };

  useEffect(() => {
    if (parentQuestionData) {
      const data = {
        questionContent:
          language === 'NO'
            ? parentQuestionData?.parent_question_no_ver
            : parentQuestionData?.parent_question_content,
        isMandatory: !!parentQuestionData?.parent_question_mandatory,
        description:
          language === 'NO'
            ? parentQuestionData.parent_question_description_no_ver
            : parentQuestionData.parent_question_description,
        answerList:
          language === 'NO'
            ? parentQuestionData.parent_answer_no_ver
            : parentQuestionData.parent_answer_choice,
        questionChoice: parentQuestionData.parent_answer_choice,
        questionType: parentAnswerType,
        child: parentQuestionData.child,
        parentId: parentQuestionData.parent_question_id,
        type: parentQuestionData.type,
      };
      setQuestionData(data);
    }
  }, [parentQuestionData, language]);

  return (
    <>
      {Object.keys(questionData).length &&
      (questionData.type !== 'expaned'
        ? true
        : listExpandedQuestionActive.findIndex(
            (question) => question.questionUUID === questionData.parentId && !!question.isActive,
          ) > -1) ? (
        <>
          <div className="calendar-plan-parent-question">
            <div className="question-layout">
              <h3 htmlFor="" className="calendar-plan-survey-form__question">
                <img
                  src={isLocked ? LockIcon : UnlockIcon}
                  width={18}
                  height={18}
                  onClick={handleLockQuestion}
                  style={{ marginRight: 4 }}
                />
                {index}. {questionData?.questionContent}
                {questionData?.isMandatory === '1' ? <span style={{ color: 'red' }}>*</span> : ''}
                {Object.keys(questionData?.child)[0] !== 'null' ? <CaretDownOutlined /> : ''}
              </h3>

              <img
                src={isBlock ? ToggleOffIcon : ToggleOnIcon}
                width={32}
                height={18}
                onClick={handleBlockQuestion}
              />
            </div>

            {questionData.description ? (
              <>
                <p className="calendar-plan-survey-form__description">{questionData.description}</p>
              </>
            ) : null}
            <div
              className={`calendar-plan-survey-form__answer-group ${
                parentAnswerType === 'checkbox-text' && 'checkbox-text__container'
              }`}
            >
              {questionData?.answerList.map((answer, index) => {
                const answerKeys = Object.keys(answer);
                return (
                  <AnswerItem
                    key={answer[answerKeys[0]]}
                    parentAnswerType={parentAnswerType}
                    answer={answer}
                    answerKeys={answerKeys}
                    surveyRecord={surveyRecord}
                    questionData={questionData}
                    handleOnChange={handleOnChange}
                    index={index}
                    setSurveyRecord={setSurveyRecord}
                    isDisabled={isBlock}
                  />
                );
              })}
            </div>
          </div>

          <RenderSubQuestion
            handleOnChange={handleOnChange}
            language={language}
            listSubQuestion={Object.keys(questionData?.child)}
            questionData={questionData}
            renderNewRangeDate={renderNewRangeDate}
            renderRangeDate={renderRangeDate}
            setSurveyRecord={setSurveyRecord}
            surveyRecord={surveyRecord}
            warningMessage={warningMessage}
            jobPercentage={jobPercentage}
          />
        </>
      ) : null}
    </>
  );
};

export default ParentQuestion;
