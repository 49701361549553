import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useRef, useState } from 'react';
import { SwapOutlined } from '@ant-design/icons';

import ResetIcon from '_components/Icons/ResetIcon';

import { useClickOutside } from 'helper/useClickOutSide';
import { openModal } from '_components/Modal/slice';

import { useNewEmpTableUI } from 'redux/global/globalSlice';
import { AddGroupIcon, AddRoleIcon, DownloadBrownIcon, PlusIcon, SendBrownIcon, VerticalDot } from 'assets/img';
import {
  ADD_GROUP,
  ADD_NEW_EMPLOYEE,
  ADD_ROLE,
  SEND_EMP_SURVEY,
} from '_components/CarlendarPlan/EmployeeV2/constants/modal';

import './styles.scss';
import { selectAuth } from 'redux/auth/authSlice';

const ButtonList = ({ setIsSendSurvey, setResetEmpWishes, handleExportSurveyEmpWishes }) => {
  const { isEditabled } = useSelector(selectAuth);

  const dispatch = useDispatch();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const menuRefBtn = useRef(null);
  const menuRef = useRef(null);

  useClickOutside(
    menuRefBtn,
    () => {
      setIsOpenMenu(false);
    },
    menuRef,
  );

  return (
    <div className="calendar-plan-employee__feature">
      <div className="view">
        <p className="tab-title">{t('employee')}</p>
      </div>

      <div className="feature-btns">
        <button
          disabled={!isEditabled}
          onClick={() => {
            // setIsSendSurvey(true);

            dispatch(openModal({ name: SEND_EMP_SURVEY }));
          }}
        >
          <img src={SendBrownIcon} /> {t('send-survey')}
        </button>

        <button
          disabled={!isEditabled}
          onClick={() => dispatch(openModal({ name: ADD_NEW_EMPLOYEE }))}
        >
          <img src={PlusIcon} /> {t('add-employees')}
        </button>

        <button
          ref={menuRefBtn}
          disabled={!isEditabled}
          onClick={() => {
            setIsOpenMenu(!isOpenMenu);
          }}
        >
          <img src={VerticalDot} />
        </button>

        {isOpenMenu && (
          <div ref={menuRef} className="vertical-dot-menu">
            <div
              onClick={() => {
                dispatch(openModal({ name: ADD_ROLE }));
              }}
            >
              <img src={AddRoleIcon} />
              <p>{t('add-position')}</p>
            </div>

            <div
              onClick={() => {
                dispatch(openModal({ name: ADD_GROUP }));
              }}
            >
              <img src={AddGroupIcon} />
              <p>{t('add-group')}</p>
            </div>

            <div
              onClick={handleExportSurveyEmpWishes}
            >
              <img src={DownloadBrownIcon} />
              <p>{t('download-wishes-survey')}</p>
            </div>

            <div onClick={() => setResetEmpWishes(true)}>
              <ResetIcon />
              <p>{t('reset-wishes')}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ButtonList;
