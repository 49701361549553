import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import moment from 'moment';
import 'moment/locale/nb';
import { useDispatch, useSelector } from 'react-redux';
import axiosClient from 'axios/axiosClient';

import QuestionTopic from './QuestionTopic';
import Loading from 'components/Loading/Loading';
import Select from 'react-select';

import {
  useListQuestions,
  useEmpSurveyRecord,
  useListBlockedQuestion,
  useListHardRuleQuestions,
  useListExpandedQuestionActive,
  useListTopic,
  useEmployeeComment,
  useEmployees,
  useEmployeesWithPosition,
  useListManagerOption,
  useSurveyRecordHistory,
  useSurveyRecordHistoryDetail,
  useSurveyRecordHistoryDiff,
  useHolidayWithName,
} from 'Hooks/useData';
import { Toast, showLoading } from 'helper/alert';
import { HOSPITAL_ENV, MULTIPLE_CHOICE, TYPE_SURVEY_QUESTION, contentHave5Range } from 'constant';
import { selectGlobal } from 'redux/global/globalSlice';
import { setEditedEmpAction } from 'redux/global/globalAction';

import './styles.scss';
import DatePickerCustom from 'components/DatePicker/DatePickerCustom';
import { CloseOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { selectAuth } from 'redux/auth/authSlice';
import { User } from 'assets/img';
import VersionHistorySelect from './VersionHistorySelect';

const Survey = ({ unitSelected, empInfo, handleClose }) => {
  const dispatch = useDispatch();
  const { language } = useSelector(selectGlobal);
  const { isEditabled } = useSelector(selectAuth);

  const isNo = language === 'NO';
  const { surveyUpdatedAt, uuid, job_percentage: jobPercentage } = empInfo;
  // state
  const [surveyRecord, setSurveyRecord] = useState([]);
  const [listBlockedQuestion, setListBlockedQuestion] = useState([]);
  const [listHardRuleQuestion, setListHardRuleQuestion] = useState([]);
  const [empComment, setEmpComment] = useState('');
  const [listExpandedQuestionActive, setListExpandedQuestionActive] = useState([]);
  const [warningMessage, setWarningMessage] = useState('');
  const [surveyVersionSelected, setSelectedSurveyVersion] = useState(null);
  const [open, setOpen] = useState(false);

  // survey questions
  const { data: managerOption } = useListManagerOption();
  const { data: listTopic, isFetched: isListTopicFetched } = useListTopic();
  const { data: listQuestions, isFetched: isListQuestionsFetched } = useListQuestions({
    unitCode: unitSelected,
  });
  const { data: holidayList } = useHolidayWithName();

  const { data: listExpandedQuestionActiveQuery, isFetched: islistExpandedQuestionActiveFetched } =
    useListExpandedQuestionActive({ empUUID: uuid });
  const { data: listHardRuleQuestionQuery, isFetched: islistHardRuleQuestionFetched } =
    useListHardRuleQuestions({ empUUID: uuid });
  const { data: listBlockedQuestionQuery, isFetched: islistBlockedQuestionFetched } =
    useListBlockedQuestion({ empUUID: uuid });
  const { data: empCommnetQuery } = useEmployeeComment(empInfo.uuid);
  const { refetch: refetchEmployees } = useEmployeesWithPosition({ unitSelected });
  const { customerType } = useSelector(selectGlobal);
  const isHospital = customerType === HOSPITAL_ENV;

  const { data: surveyRecordHistoryDiff } = useSurveyRecordHistoryDiff({ empID: uuid, versionNumber: surveyVersionSelected })

  const { data: surveyRecordHistoryData } = useSurveyRecordHistory({ empID: uuid }, (res) => {
    setSelectedSurveyVersion(res.reduce((max, version) => Math.max(max, version.version_number), 1))
  });

  useEffect(() => {
    if (listBlockedQuestionQuery.length) {
      setListBlockedQuestion(listBlockedQuestionQuery);
    }
  }, [listBlockedQuestionQuery]);

  useEffect(() => {
    if (listHardRuleQuestionQuery.length) {
      setListHardRuleQuestion(listHardRuleQuestionQuery);
    }
  }, [listHardRuleQuestionQuery]);

  useEffect(() => {
    if (empCommnetQuery) setEmpComment(empCommnetQuery.comment);
  }, [empCommnetQuery]);

  useEffect(() => {
    if (listExpandedQuestionActiveQuery)
      setListExpandedQuestionActive(listExpandedQuestionActiveQuery);
  }, [listExpandedQuestionActiveQuery]);

  const { data: empSurveyRecord, isFetched: isEmpSurveyRecordFetched } = useEmpSurveyRecord({
    empUUID: uuid,
    versionNumber:
      surveyVersionSelected === surveyRecordHistoryData?.[0]?.version_number
        ? undefined
        : surveyVersionSelected,
  });

  const getSurveyIsChanged = (id) => {
    const { updateChanges, deleteChanges, insertChanges } = surveyRecordHistoryDiff;
    if (!updateChanges) return false;
    const isUpdated = updateChanges.some((change) => change.topic_id === id);
    const isDeleted = deleteChanges.some((change) => change.topic_id === id);
    const isInserted = insertChanges.some((change) => change.topic_id === id);
    return isUpdated || isDeleted || isInserted;
  };

  const shouldShowSurvey =
    isListTopicFetched &&
    isListQuestionsFetched &&
    islistExpandedQuestionActiveFetched &&
    islistHardRuleQuestionFetched &&
    islistBlockedQuestionFetched &&
    isEmpSurveyRecordFetched;

  useEffect(() => {
    if (empSurveyRecord.length) {
      const result = empSurveyRecord;
      const arr = {};
      result.forEach((record) => {
        if (record.answer) {
          if (record.type === MULTIPLE_CHOICE) {
            const enAnswer = record.answer.split(',');
            const noAnswer = record.answer_no.split(',');
            arr[record.question_uuid] = enAnswer.map((item, index) => ({
              en: item,
              no: noAnswer[index],
              id: record.answer_id,
              type: record.type,
            }));
          } else if (record.type === 'text') {
            arr[record.question_uuid] = record.answer;
          } else if (record.type === 'single-date') {
            arr[record.question_uuid] = JSON.parse(record.answer);
          } else if (record.type === 'checkbox-text') {
            arr[record.question_uuid] = JSON.parse(record.answer);
          } else if (record.type === 'date-only') {
            arr[record.question_uuid] = JSON.parse(record.answer);
          } else if (record.type === 'select-week') {
            arr[record.question_uuid] = JSON.parse(record.answer);
          } else if (
            record.type === TYPE_SURVEY_QUESTION.WEEKDAY_TYPE_SHIFT ||
            record.type === TYPE_SURVEY_QUESTION.HOLIDAY_TYPE_SHIFT
          ) {
            arr[record.question_uuid] = JSON.parse(record.answer);
          } else if (record.type === 'date') {
            arr[record.question_uuid] = JSON.parse(record.answer);
            arr[record.question_uuid] = arr[record.question_uuid].map((item) => {
              return {
                ...item,
                fromDate: item.fromDate && item.fromDate.length ? new Date(item.fromDate) : '',
                toDate: item.toDate && item.toDate.length ? new Date(item.toDate) : '',
              };
            });
          } else if (record.type === 'range-date-only') {
            arr[record.question_uuid] = JSON.parse(record.answer);
            arr[record.question_uuid] = arr[record.question_uuid].map((item) => {
              if (item.fromDate && item.toDate) {
                return {
                  ...item,
                  fromDate: item.fromDate && item.fromDate.length ? new Date(item.fromDate) : '',
                  toDate: item.toDate && item.toDate.length ? new Date(item.toDate) : '',
                };
              } else {
                return { ...item };
              }
            });
          } else if (record.type === 'time') {
            arr[record.question_uuid] = JSON.parse(record.answer);
            arr[record.question_uuid] = arr[record.question_uuid].map((item) => {
              return { ...item, time: item.time };
            });
          } else {
            arr[record.question_uuid] = {
              en: record.answer,
              no: record.answer_no,
              id: record.answer_id,
              type: record.type,
            };
          }
        }
      });
      setSurveyRecord(arr);
    }
  }, [empSurveyRecord, uuid]);

  useEffect(() => {
    axiosClient.post('calendar-plan/survey/default-value').then((res) => {
      if (res?.length) {
        setSurveyRecord((prev) => {
          let surveyRecordUpdate = { ...prev };

          for (const question of res) {
            if (surveyRecordUpdate[question.questionUUID] || question.length) continue;
            if (
              !surveyRecordUpdate[question.questionUUID] ||
              !surveyRecordUpdate[question.questionUUID].length
            ) {
              if (question.listValue[0].type === 'single') {
                surveyRecordUpdate[question.questionUUID] = question.listValue[0];
              } else {
                surveyRecordUpdate[question.questionUUID] = question.listValue;
              }
            }
          }
          for (const question of res) {
            if (!question.length) continue;
            if (
              surveyRecordUpdate[question.questionUUID] &&
              surveyRecordUpdate[question.questionUUID].length < question.length
            ) {
              const currentLength = surveyRecordUpdate[question.questionUUID].length;
              const additionalItems = Array.from(
                { length: question.length - currentLength },
                () => ({
                  fromDate: '',
                  toDate: '',
                  type: 'date',
                }),
              );

              surveyRecordUpdate[question.questionUUID].push(...additionalItems);
            } else if (!surveyRecordUpdate[question.questionUUID]) {
              surveyRecordUpdate[question.questionUUID] = Array.from(
                { length: question.length },
                () => ({ fromDate: '', toDate: '', type: 'date' }),
              );
            }
          }
          // Check if the key exists and the array length is less than 5
          const listQuestionHave5RangeUUID = res
            .map((item) => {
              if (contentHave5Range.includes(item.questionContent)) {
                return item.questionUUID;
              }
            })
            .filter((item) => item);
          listQuestionHave5RangeUUID.forEach((questionUUID) => {
            if (surveyRecordUpdate[questionUUID] && surveyRecordUpdate[questionUUID].length < 5) {
              const currentLength = surveyRecordUpdate[questionUUID].length;
              const additionalItems = Array.from({ length: 5 - currentLength }, () => ({
                fromDate: '',
                toDate: '',
                type: 'date',
              }));
              surveyRecordUpdate[questionUUID].push(...additionalItems);
            } else if (!surveyRecordUpdate[questionUUID]) {
              // If the key doesn't exist, initialize it with 5 items
              surveyRecordUpdate[questionUUID] = Array.from({ length: 5 }, () => ({
                fromDate: '',
                toDate: '',
                type: 'date',
              }));
            }
          });

          // Handle default values for date-only question type
          const listDateOnlyQuestions = listQuestions.filter(
            (question) => question.parent_question_type === 'date-only',
          );
          listDateOnlyQuestions.forEach((dateOnlyQuestion) => {
            let numsAnswerForDateOnly = 3;
            const sixElementDateOnlyQuestion = ['extend_date_off'];
            if (!isHospital) {
              if (
                sixElementDateOnlyQuestion.includes(
                  dateOnlyQuestion.parrent_question_property.trim(),
                )
              ) {
                numsAnswerForDateOnly = 6;
              } else {
                numsAnswerForDateOnly = 5;
              }
            }
            if (!surveyRecordUpdate[dateOnlyQuestion.parent_question_id]) {
              surveyRecordUpdate[dateOnlyQuestion.parent_question_id] = {
                dateArr: Array.from({ length: numsAnswerForDateOnly }, () => ''),
                type: 'date-only',
              };
            }
          });

          // END DEFAULT VALUE HANDLER
          return surveyRecordUpdate;
        });
      }
    });
  }, [uuid, empSurveyRecord]);

  const commentTopic = {
    uuid: 'comment-question-topicID',
    no_ver: t('comment'),
    topic: t('comment'),
  };

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const questionId = Object.keys(surveyRecord);
      const empRecord = questionId.map((key) => {
        if (typeof surveyRecord[key] === 'string') {
          return {
            questionUUID: key,
            answerID: surveyRecord[key].id || '',
            empID: empInfo.id,
            empUUID: empInfo.uuid,
            answer: surveyRecord[key].en || surveyRecord[key],
            answer_no: surveyRecord[key].no || surveyRecord[key],
            type: 'text',
          };
        } else if (Array.isArray(surveyRecord[key])) {
          if (
            surveyRecord[key][0]?.type === 'date' ||
            surveyRecord[key][0]?.type === 'range-date-only' ||
            surveyRecord[key][0]?.type === 'time' ||
            ''
          ) {
            const value = surveyRecord[key].filter(
              (item) =>
                item.fromDate &&
                item.toDate &&
                item.fromDate !== 'Invalid date' &&
                item.toDate !== 'Invalid date',
            );
            return {
              questionUUID: key,
              answerID: surveyRecord[key].id || '',
              empID: empInfo.id,
              empUUID: empInfo.uuid,
              answer: JSON.stringify(value) || value,
              answer_no: JSON.stringify(value) || value,
              type: surveyRecord[key][0]?.type || surveyRecord[key][1].type,
            };
          } else {
            return {
              questionUUID: key,
              answerID: surveyRecord[key].id || '',
              empID: empInfo.id,
              empUUID: empInfo.uuid,
              answer: surveyRecord[key].en || surveyRecord[key],
              answer_no: surveyRecord[key].no || surveyRecord[key],
              type: surveyRecord[key][0]?.type,
            };
          }
        } else if (surveyRecord[key].type === 'single-date') {
          return {
            questionUUID: key,
            answerID: surveyRecord[key].id || '',
            empID: empInfo.id,
            empUUID: empInfo.uuid,
            answer: JSON.stringify(surveyRecord[key]) || surveyRecord[key],
            answer_no: JSON.stringify(surveyRecord[key]) || surveyRecord[key],
            type: 'single-date',
          };
        } else if (surveyRecord[key].type === 'checkbox-text') {
          return {
            questionUUID: key,
            answerID: surveyRecord[key].id || '',
            empID: empInfo.id,
            empUUID: empInfo.uuid,
            answer: JSON.stringify(surveyRecord[key]) || surveyRecord[key],
            answer_no: JSON.stringify(surveyRecord[key]) || surveyRecord[key],
            type: 'checkbox-text',
          };
        } else if (surveyRecord[key].type === 'date-only') {
          return {
            questionUUID: key,
            answerID: surveyRecord[key].id || '',
            empID: empInfo.id,
            empUUID: empInfo.uuid,
            answer: JSON.stringify(surveyRecord[key]) || surveyRecord[key],
            answer_no: JSON.stringify(surveyRecord[key]) || surveyRecord[key],
            type: 'date-only',
          };
        } else if (surveyRecord[key].type === 'select-week') {
          return {
            questionUUID: key,
            answerID: surveyRecord[key].id || '',
            empID: empInfo.id,
            empUUID: empInfo.uuid,
            answer: JSON.stringify(surveyRecord[key]) || surveyRecord[key],
            answer_no: JSON.stringify(surveyRecord[key]) || surveyRecord[key],
            type: 'select-week',
          };
        } else if (surveyRecord[key].type === TYPE_SURVEY_QUESTION.WEEKDAY_TYPE_SHIFT) {
          return {
            questionUUID: key,
            answerID: surveyRecord[key].id || '',
            empID: empInfo.id,
            empUUID: empInfo.uuid,
            answer: JSON.stringify(surveyRecord[key]) || surveyRecord[key],
            answer_no: JSON.stringify(surveyRecord[key]) || surveyRecord[key],
            type: TYPE_SURVEY_QUESTION.WEEKDAY_TYPE_SHIFT,
          };
        } else if (surveyRecord[key].type === TYPE_SURVEY_QUESTION.HOLIDAY_TYPE_SHIFT) {
          return {
            questionUUID: key,
            answerID: surveyRecord[key].id || '',
            empID: empInfo.id,
            empUUID: empInfo.uuid,
            answer: JSON.stringify(surveyRecord[key]) || surveyRecord[key],
            answer_no: JSON.stringify(surveyRecord[key]) || surveyRecord[key],
            type: TYPE_SURVEY_QUESTION.HOLIDAY_TYPE_SHIFT,
          };
        } else {
          return {
            questionUUID: key,
            answerID: surveyRecord[key].id || '',
            empID: empInfo.id,
            empUUID: empInfo.uuid,
            answer: surveyRecord[key].en || surveyRecord[key],
            answer_no: surveyRecord[key].no || surveyRecord[key],
            type: surveyRecord[key]?.type,
          };
        }
      });
      showLoading({ shouldShow: true, title: t('updating') });
      Promise.all([
        // axiosClient.post('calendar-plan/survey-intro-employee/update', {
        //   intro,
        //   employeeId: empInfo.uuid,
        // }),
        axiosClient.post('calendar-plan/survey/comment/update', {
          employeeUUID: empInfo.uuid,
          commentUpdate: empComment,
        }),
        axiosClient.post('calendar-plan/survey/block-question/update', {
          listQuestionId: listBlockedQuestion,
          empId: empInfo.uuid,
        }),
        axiosClient.post('calendar-plan/survey/property/hard-rule/update', {
          employee: empInfo,
          listHardRuleQuestion,
        }),
        axiosClient.post('calendar-plan/survey-record/create', { empRecord }),
      ])
        .then((_) => {
          axiosClient
            .post('calendar-plan/survey/expaned-question/update', {
              listQuestion: listExpandedQuestionActive,
              empUUID: empInfo.uuid,
            })
            .then(async (_) => {
              await refetchEmployees();
              showLoading({ shouldShow: false });
              Toast({
                icon: 'success',
                title: `${t('update')} ${t('survey')} ${t('success')}`,
                timer: 3000,
              });
              handleClose();
              dispatch(setEditedEmpAction(empInfo.id));
            })
            .catch(() => {
              Toast({ icon: 'error', title: `${t('save')} ${t('survey')} ${t('unsuccess')}` });
            });
        })
        .catch(() => {
          Toast({ icon: 'error', title: `${t('save')} ${t('survey')} ${t('unsuccess')}` });
        });
    },
    [
      surveyRecord,
      empComment,
      listBlockedQuestion,
      listHardRuleQuestion,
      listExpandedQuestionActive,
    ],
  );

  const renderRangeDate = useCallback(
    (questionId, isDisabled) => {
      const survey = { ...surveyRecord };
      if (!survey[questionId]) {
        survey[questionId] = [{ fromDate: new Date(), toDate: new Date(), type: 'date' }];
      } else if (typeof survey[questionId] === 'string') {
        survey[questionId] = JSON.parse(survey[questionId]);
      }
      const deleteDate = (questionId, index) => {
        const surveyUpdate = { ...survey, [questionId]: [] };
        setSurveyRecord(surveyUpdate);
      };

      return survey[questionId].length > 0
        ? survey[questionId].map((range, index) => {
            return (
              <div
                key={`${questionId}-date-survey-${index}`}
                className="calendar-plan-survey-form__date__wrap"
              >
                <div className="calendar-plan-survey-form__date__index">
                  {t('range')} {index + 1}:
                </div>
                <div className="calendar-plan-survey-form__date__group">
                  <div className="calendar-plan-survey-form__date__item">
                    <label htmlFor="">{t('from')}</label>

                    <DatePickerCustom
                      showWeekNumbers={true}
                      showMonthDropdown={true}
                      showYearDropdown={true}
                      disabled={!!isDisabled}
                      date={survey[questionId] ? survey[questionId][index]?.fromDate : new Date()}
                      setDate={(value) => {
                        survey[questionId][index] = {
                          ...survey[questionId][index],
                          fromDate: value,
                          type: 'date',
                        };
                        _.debounce(() => setSurveyRecord(survey), 1500)();
                      }}
                    />
                  </div>
                  <div className="calendar-plan-survey-form__date__item">
                    <label htmlFor="">{t('to')}</label>

                    <DatePickerCustom
                      showWeekNumbers={true}
                      showMonthDropdown={true}
                      showYearDropdown={true}
                      disabled={!!isDisabled}
                      date={survey[questionId] ? survey[questionId][index]?.toDate : new Date()}
                      setDate={_.debounce((value) => {
                        survey[questionId][index] = {
                          ...survey[questionId][index],
                          toDate: value,
                          type: 'date',
                        };
                        setSurveyRecord(survey);
                      }, 3000)}
                    />
                  </div>
                  <div
                    className="calendar-plan-survey-form__date-close"
                    onClick={() => {
                      deleteDate(questionId, index);
                    }}
                  >
                    <CloseOutlined />
                  </div>
                </div>
              </div>
            );
          })
        : null;
    },
    [surveyRecord],
  );

  const renderNewRangeDate = (questionId, isDisabled) => {
    const survey = { ...surveyRecord };
    if (!survey[questionId]) {
      survey[questionId] = [];
    }
    let index = survey[questionId].length;
    return (
      <div key={Math.random()} className="calendar-plan-survey-form__date__wrap">
        <div className="calendar-plan-survey-form__date__index">{t('new-range')}:</div>
        <div className="calendar-plan-survey-form__date__group">
          <div className="calendar-plan-survey-form__date__item">
            <label htmlFor="">{t('from')}</label>
            <input
              type="date"
              name=""
              disabled={!!isDisabled}
              id=""
              onChange={(e) => {
                if (!!isDisabled) {
                  return;
                }
                const { value } = e.target;
                survey[questionId][index] = {
                  ...survey[questionId][index],
                  fromDate: new Date(value),
                  type: 'date',
                };

                if (moment(value).year() > 1970) {
                  setSurveyRecord(survey);
                }
              }}
            />
          </div>
          <div className="calendar-plan-survey-form__date__item">
            <label htmlFor="">{t('to')}</label>
            <input
              type="date"
              name=""
              id=""
              disabled={!!isDisabled}
              onChange={(e) => {
                const { value } = e.target;
                survey[questionId][index] = {
                  ...survey[questionId][index],
                  toDate: new Date(value),
                  type: 'date',
                };
                if (moment(value).year() > 1970) {
                  setSurveyRecord(survey);
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const listFullTopic = [...listTopic, commentTopic];
  const getTopic = useCallback(() => {
    //WILL BE CONFIG BY BE LATER, PLEASE NOTE THAT THIS FUNCTION NEED TO BE SETUP ON EMPLOYEE'S SURVEY TOO
    const listTopicFiltered = listFullTopic.map((item) => {
      if (item.topic === 'Temporary work summer (if under 50%)') {
        return;
      }
      if (item.topic === 'Full-time (if position below 100%)' && +jobPercentage === 100) {
        return;
      }

      return item;
    });
    return listTopicFiltered.filter((item) => item);
  }, [listFullTopic, jobPercentage]);

  const listTopicFiltered = getTopic();

  const surveyHistoryOptions = useMemo(() => {
    const sortedSurveyHistoryData = surveyRecordHistoryData.sort(
      (a, b) => b.version_number - a.version_number,
    );
    const options = sortedSurveyHistoryData.map((versionDetail) => {
      const { modified_by, updated_at, version_number } = versionDetail;
      moment.locale('nb');
      const updatedAtDateTimeObj = moment.utc(updated_at, 'YYYY-MM-DD HH:mm:ss').local();
      // Convert to browser local time zone
      const time = updated_at ? updatedAtDateTimeObj.format('DD.MMMM YYYY HH:mm') : '';

      return {
        value: version_number,
        datetime: time,
        modifiedBy: modified_by,
        isLatestVersion: version_number === surveyRecordHistoryData[0].version_number,
      };
    });

    return options;
  }, [surveyRecordHistoryData]);

  const isReadOnly = useMemo(() => {
    if (!surveyVersionSelected || !surveyHistoryOptions) return false;

    const lastestVersion = surveyHistoryOptions[0];
    return surveyVersionSelected?.toString() !== lastestVersion.value?.toString();
  }, [surveyHistoryOptions, surveyVersionSelected]);

  const getVersionHistoryOptionDisplayLabel = () => { 
    if (!surveyVersionSelected || !surveyHistoryOptions) return '';

    const selectedVersion = surveyHistoryOptions.find((option) => option.value === surveyVersionSelected);
    if (!selectedVersion) return '';

    if (selectedVersion.isLatestVersion) { 
      return `${t('lastest-survey-answer')}`;
    }

    return `${selectedVersion.datetime}`;
  }

  return (
    <div className="menu-overview__survey">
      <div className="menu-overview__survey-header">
        <div>
          <p>{t('mapping')} </p>
          {surveyUpdatedAt && (
            <p>
              {t('last-updated')}: {moment(surveyUpdatedAt).format('DD.MM.YYYY HH:mm')}
            </p>
          )}
        </div>
        <div className="chart-unit">
          <VersionHistorySelect 
            options={surveyHistoryOptions}
            onChange={(newValue) => {
              setSelectedSurveyVersion(newValue.value);
            }}
            value={{ 
              value: surveyVersionSelected,
              label: getVersionHistoryOptionDisplayLabel(),
            }}
          />
        </div>
      </div>

      <div className="menu-overview__survey-questions-wrapper">
        {shouldShowSurvey ? (
          <div className="menu-overview__survey-questions">
            {listTopicFiltered.map(({ topic, uuid, no_ver }) => {
              const topicQuestions = listQuestions.filter(
                (question) => question.parent_question_topic_id === uuid,
              );

              const isCommentBox = uuid === commentTopic.uuid;
              return (
                <QuestionTopic
                  key={uuid}
                  title={isNo ? no_ver : topic}
                  empInfo={empInfo}
                  listTopic={listTopicFiltered}
                  questionList={topicQuestions}
                  isCommentBox={isCommentBox}
                  isNo={isNo}
                  isChanged={getSurveyIsChanged(uuid)}
                  surveyRecord={surveyRecord}
                  setSurveyRecord={setSurveyRecord}
                  // block question
                  listBlockedQuestion={listBlockedQuestion}
                  setListBlockedQuestion={setListBlockedQuestion}
                  // lock question
                  listHardRuleQuestion={listHardRuleQuestion}
                  setListHardRuleQuestion={setListHardRuleQuestion}
                  // expanded question
                  listExpandedQuestionActive={listExpandedQuestionActive}
                  setListExpandedQuestionActive={setListExpandedQuestionActive}
                  // comment
                  empComment={empComment}
                  setEmpComment={setEmpComment}
                  renderNewRangeDate={renderNewRangeDate}
                  renderRangeDate={renderRangeDate}
                  warningMessage={warningMessage}
                  setWarningMessage={setWarningMessage}
                  managerOption={managerOption}
                  isReadOnly={isReadOnly}
                  //public holiday
                  holidayList={holidayList}
                />
              );
            })}
            <button
              className="survey-submit-btn"
              onClick={handleSubmit}
              disabled={warningMessage || !isEditabled || isReadOnly}
            >
              {t('update')}
            </button>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default Survey;
