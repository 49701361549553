import { CheckGreenIcon, DeleteIcon, DeleteIconBlack, EditIcon } from 'assets/img';
import axiosClient from 'axios/axiosClient';
import { Toast, confirmDelete, showLoading } from 'helper/alert';
import { t } from 'i18next';
import React, { useState } from 'react';
import RoleCoverSelection from './RoleCoverSelection';

export default function CalendarPlanRoleRow({
  defaultRole,
  refetchRole,
  refetchEmployees,
  roleList,
}) {
  const [role, setRole] = useState(defaultRole);
  const [isEditing, setIsEditing] = useState(false);

  const [selectedRole, setSelectedRole] = useState([]);
  const [unSelectedRole, setUnSelectedRole] = useState([]);
  const handleSelectRoleCover = (roleSelect) => {
    setRole((prev) => {
      const newRole = [];
      const updateRole = { ...prev };
      if (!updateRole.roleCovered) {
        newRole.push(roleSelect.id);
        updateRole.roleCovered = [roleSelect.id];
        setUnSelectedRole((prev) => prev.filter((item) => !newRole.includes(item)));
      } else {
        const isHaveRole = updateRole.roleCovered.find((item) => +roleSelect.id === +item);
        if (isHaveRole) {
          updateRole.roleCovered = updateRole.roleCovered.filter(
            (item) => +item !== +roleSelect.id,
          );
          setSelectedRole((prev) => prev.filter((item) => +item !== +roleSelect.id));
          if (!selectedRole.includes(roleSelect.id)) {
            setUnSelectedRole((prev) => [...prev, roleSelect.id]);
          }
        } else {
          if (!unSelectedRole.includes(roleSelect.id)) {
            newRole.push(roleSelect.id);
            setUnSelectedRole((prev) => prev.filter((item) => item !== roleSelect.id));
          }
          if (!newRole.includes(roleSelect.id)) {
            setUnSelectedRole((prev) => prev.filter((item) => item !== roleSelect.id));
          }
          updateRole.roleCovered.push(roleSelect.id);
        }
      }
      setSelectedRole((prev) => [...prev, ...newRole]);
      return updateRole;
    });
  };
  const handleOnChange = (e) => {
    setRole({ ...role, [e.target.name]: e.target.value });
  };

  const handleRemoveSelectedRole = () => {
    setRole((prev) => {
      const updateRole = { ...prev };
      updateRole.roleCovered = updateRole.roleCovered.filter(
        (item) => !selectedRole.includes(+item),
      );
      return updateRole;
    });
  };
  const handleUpdate = async (e) => {
    let isValid = true;
    for (const key of Object.keys(role)) {
      if (role[key]?.length == 0 && key !== 'description' && key !== 'roleCovered') {
        isValid = false;
      }
    }
    if (isValid) {
      await axiosClient
        .post('calendar-plan/role/update', { role })
        .then((_) => {
          Toast({ icon: 'success', title: `${t('update-role')} ${t('success')}` });
          setIsEditing(false);
          refetchRole();
          setSelectedRole([]);
          setUnSelectedRole([]);
        })
        .catch((err) => {
          Toast({ icon: 'error', title: `${t('update-role')} ${t('unsuccess')}` });
        });
    } else {
      Toast({ icon: 'error', title: `${t('please-input-all')} ` });
    }
  };

  const handleDelete = async (e) => {
    confirmDelete({}).then(async (result) => {
      if (result.isConfirmed) {
        showLoading({ shouldShow: true, title: 'deleting' });
        await axiosClient
          .post('calendar-plan/role/delete', { role })
          .then(async (_) => {
            await refetchRole();
            await refetchEmployees();
            showLoading({ shouldShow: false });
            Toast({ icon: 'success', title: `${t('delete-role')} ${t('success')}` });
          })
          .catch((err) => {
            Toast({ icon: 'error', title: `${t('delete-role')} ${t('unsuccess')}` });
          });
      }
    });
  };
  return (
    <tr className="calendar-plan-create-role__item">
      <td className="calendar-plan-create-role__item-label">
        <input
          name="role"
          disabled={!isEditing}
          type="text"
          value={role.role}
          onChange={handleOnChange}
          required
        />
      </td>
      <td className="calendar-plan-create-role__item-label">
        <input
          name="key"
          disabled={!isEditing}
          type="text"
          value={role.key}
          required
          onChange={handleOnChange}
        />
      </td>
      <td className="calendar-plan-create-role__item-description">
        {/* <input
          name="rank"
          disabled={!isEditing}
          type="number"
          value={role.rank}
          onChange={handleOnChange}
          required
        /> */}
        <RoleCoverSelection
          roleList={roleList}
          role={role}
          handleSelectRoleCover={handleSelectRoleCover}
          selectedRole={selectedRole}
          handleUpdate={handleUpdate}
          handleRemoveSelectedRole={handleRemoveSelectedRole}
          unSelectedRole={unSelectedRole}
          // disabled={!isEditing}
        />
      </td>
      <td className="calendar-plan-create-role__item-description">
        <input
          name="description"
          disabled={!isEditing}
          type="text"
          value={role.description || t('no-description')}
          onChange={handleOnChange}
        />
      </td>
      <td className="calendar-plan-create-role__item-action">
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 8 }}>
          <div onClick={() => (isEditing ? handleUpdate() : setIsEditing(true))}>
            {!isEditing ? (
              <img src={EditIcon} alt="" width={30} />
            ) : (
              <img src={CheckGreenIcon} alt="" width={24} />
            )}
          </div>
          <div onClick={handleDelete}>
            <img src={DeleteIconBlack} alt="" width={16} />
          </div>
        </div>
      </td>
    </tr>
  );
}
