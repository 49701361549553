import { loadSession, loadState } from 'helper/localStorage';

const getEnvironmentType = () => {
  //GET APP NAME
  const alreadyConfig = loadSession('customerType') || loadState('customerType');
  if (process.env.REACT_APP_NODE_ENV === 'production') {
    const environment = process.env.REACT_APP_SITE_TYPE;
    return environment.trim();
  } else {
    const environment = alreadyConfig || process.env.REACT_APP_SITE_TYPE;
    return environment.trim();
  }
};

export default getEnvironmentType;
