import axiosClient from 'axios/axiosClient';
import { useQuery } from 'react-query';

export const useDayOffAndHoliday = ({ unitCode }) => {
  return useQuery(
    ['dayOffHoliday', unitCode],
    async () => {
      const res = await axiosClient.post('calendar-plan/working-env/day-off-holiday', {
        unitCode: unitCode,
      });
      return res;
    },
    {
      enabled: !!unitCode,
    },
  );
};
export const useWorkingEnvSetup = ({ unitCode }) => {
  return useQuery(
    ['working-env-setup', unitCode],
    async () => {
      const res = await axiosClient.post('calendar-plan/working-env/setup', {
        unitCode: unitCode,
      });
      return res;
    },
    {
      enabled: !!unitCode,
    },
  );
};

export const useSoftRules = ({ unitCode }) => {
  return useQuery(['soft-rule', unitCode], async () => {
    const res = await axiosClient.post('calendar-plan/soft-rule', { unitCode });
    return res;
  });
};
