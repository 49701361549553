import axiosClient from 'axios/axiosClient';
import { Toast, showLoading } from 'helper/alert';
import { t } from 'i18next';
import moment from 'moment';
import * as XLSX from 'xlsx';

//These Index get from export excel file from out system
const EMPLOYEE_ID_INDEX = 0;
const EMPLOYEE_NAME_INDEX = 1;
const EMPLOYEE_JOB_PERCENT_INDEX = 5;
const SHIFT_START_INDEX = 8;

const convertExcelDataToTurnusData = (sheetData) => {
  if (!sheetData || !Array.isArray(sheetData)) {
    return;
  }
  const result = { data: [], taskList: [] };
  const regex = /\d{2}-\d{2}-\d{4}/;

  const startDateRaw = sheetData[0][SHIFT_START_INDEX];
  const matched = regex.exec(startDateRaw);
  if (!matched) {
    return;
  }
  const startDate = moment(matched[0], 'DD-MM-YYYY');
  const taskList = new Set();
  sheetData.shift();
  sheetData.shift();
  for (let rowIdx = 0; rowIdx < sheetData.length; rowIdx++) {
    const employee = sheetData[rowIdx];
    const employeeIDNumber = employee[EMPLOYEE_ID_INDEX];
    const employeeName = employee[EMPLOYEE_NAME_INDEX];
    const employeeJobPercent = employee[EMPLOYEE_JOB_PERCENT_INDEX];
    const employeeObj = { employeeIDNumber, employeeName, employeeJobPercent, listShift: [] };
    if (!employeeName || !employeeName.length) {
      continue;
    }
    for (let colIdx = SHIFT_START_INDEX; colIdx < employee.length; colIdx++) {
      const shiftName = employee[colIdx];

      const date = startDate
        .clone()
        .add(colIdx - SHIFT_START_INDEX, 'day')
        .format('YYYY-MM-DD');
      if (!shiftName.length) {
        continue;
      }
      taskList.add(shiftName);
      employeeObj.listShift.push({ shiftName, date });
    }
    result.data.push(employeeObj);
  }
  result.taskList = Array.from(taskList);
  return result;
};

export const uploadTurnusPlan = (
  e,
  planSelected,
  unitCode,
  openUploadReport,
  setReportData,
  handleImportTurnusData,
) => {
  const planID = planSelected.id;
  showLoading({ title: 'updating' });
  const file = e.target.files[0];
  if (!file) {
    return;
  }
  const reader = new FileReader();
  reader.onload = function (e) {
    const data = e.target.result;
    const workbook = XLSX.read(data, {
      type: 'binary',
    });

    workbook.SheetNames.forEach(function (sheetName) {
      // Here is your object
      const fileData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
        header: 1,
        defval: '',
      });
      const turnusData = convertExcelDataToTurnusData(fileData);

      axiosClient
        .post('calendar-plan/turnus/verify-upload-data', { turnusData, unitCode, planID })
        .then(async (res) => {
          e.target.value = null;
          showLoading({ shouldShow: false });
          const { notFoundEmpList, errorShiftList, offShiftList, validShiftList } = res;
          setReportData(res);
          if (!validShiftList.length) {
            Toast({ icon: 'error', title: t('file-not-have-valid-record') });
          }
          if (notFoundEmpList.length || errorShiftList.length) {
            Toast({ icon: 'error', title: t('file-import-unsuccess') });

            openUploadReport();
          } else {
            handleImportTurnusData();
          }
        })
        .catch((err) => {
          showLoading({ shouldShow: false });
        });
    });
  };
  reader.onerror = function (ex) {
    console.log(ex);
  };

  reader.readAsBinaryString(file);
};
