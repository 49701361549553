import React, { useState } from 'react';

import ButtonList from './ButtonList/ButtonList';

import PlanListContentv2 from './Plan/PlanContent/PlanListContentv2';
import TaskListContentv2 from './Task/TaskContent/TaskListContentv2';
import CreateStaffTaskForm from './Task/CreateStaffTaskForm/CreateStaffTaskForm';

import { useGroups, useRoles, useStaffTask, useTaskType, usePlanList } from 'Hooks/useData';

import './CalendarStaff.scss';
import NewStaffPlan from './NewStaffPlan/NewStaffPlan';

export default function CalendarStaff({ unitSelected, isMainContent, setIsMainContent }) {
  const { data: roleList } = useRoles();
  const { data: staffTaskList, refetch: refetchStaffTaskList } = useStaffTask({ unitSelected });
  const {
    data: { data: listTaskType },
  } = useTaskType();
  const {
    data: { data: listGroup },
  } = useGroups({ unitSelected });
  const { refetch: refetchPlanList } = usePlanList({ unitSelected });

  const [activeContent, setActiveContent] = useState('plan');
  const [isUploading, setIsUploading] = useState(false);
  const [addNewGuardCode, setAddNewGuardCode] = useState(false);
  const [planSelected, setPlanSelected] = useState({});
  const [activeCreateFormPlan, setActiveCreateFormPlan] = useState(false);
  const [taskEdit, setTaskEdit] = useState({});
  const renderContent = () => {
    switch (activeContent) {
      case 'plan':
        return (
          <PlanListContentv2
            unitSelected={unitSelected}
            isUploading={isUploading}
            setIsUploading={setIsUploading}
            setPlanSelected={setPlanSelected}
            setAddNewGuardCode={setAddNewGuardCode}
            setActiveCreateForm={setActiveCreateFormPlan}
            setIsMainContent={setIsMainContent}
          />
        );
      case 'task':
        return (
          <TaskListContentv2
            unitSelected={unitSelected}
            addNewGuardCode={addNewGuardCode}
            setAddNewGuardCode={setAddNewGuardCode}
            setActiveCreateForm={setActiveCreateFormPlan}
            setTaskEdit={setTaskEdit}
          />
        );

      default:
        return (
          <PlanListContentv2
            unitSelected={unitSelected}
            isUploading={isUploading}
            setIsUploading={setIsUploading}
            planSelected={planSelected}
            setPlanSelected={setPlanSelected}
            activeCreateForm={activeCreateFormPlan}
            setActiveCreateForm={setActiveCreateFormPlan}
          />
        );
    }
  };

  return (
    <div className="calendar-staff__wrap">
      {isMainContent ? (
        <>
          <ButtonList
            activeContent={activeContent}
            setActiveContent={setActiveContent}
            setIsUploading={setIsUploading}
            setAddNewGuardCode={setAddNewGuardCode}
            setPlanSelected={setPlanSelected}
            setActiveCreateForm={setActiveCreateFormPlan}
            isMainContent={isMainContent}
            setIsMainContent={setIsMainContent}
            setTaskEdit={setTaskEdit}
          />
          <div className="calendar-staff__content">{renderContent()}</div>
          {addNewGuardCode ? (
            <CreateStaffTaskForm
              refetchTask={refetchStaffTaskList}
              setActiveCreateForm={setAddNewGuardCode}
              unitSelected={unitSelected}
              roleList={roleList}
              listTaskType={listTaskType}
              listGroup={listGroup}
              defaultTask={taskEdit}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <NewStaffPlan
          planSelected={planSelected}
          setPlanSelected={setPlanSelected}
          setIsMainContent={setIsMainContent}
          unitSelected={unitSelected}
          refetchPlanList={refetchPlanList}
        />
      )}
    </div>
  );
}
