import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useState, useMemo, useEffect, memo } from 'react';

import EmpInfo from './EmpInfo';
import TaskList from './TaskList';
import Dashboard from './Dashboard';
import TableLegend from '../TableLegend';
import ShiftSummary from './ShiftSummary';
import TableActions from './TableActions';
import VakantReport from '../VakantReport/VakantReport';
import ManageSurvey from 'components/CalendarPlan/SurveyFormEmployee/ManageSurvey';

import _ from 'lodash';
import { syncTables } from 'helper/syncTables';
import { useEmployeeInfo, useGroups, usePlanDetailData } from 'Hooks/useData';
import { selectGeneratorTableFilter, updateFilter } from 'redux/generateTableFilter/slice';

import './styles.scss';
import {
  offShiftReg,
  TAB_SCHEDULE,
  TAB_HARD_RULE,
  TAB_EMP_WISH,
  TAB_SUMMARY_SCHEDULE,
  TAB_VERSION_HISTORY,
  TAB_TURNUS,
  TAB_AML,
  GENERATOR_GROUP_COLOR_LIST,
  TAB_HR_PER_WEEK,
  TAB_ROTATION_COST_SUMMARY,
  MAXIMUM_WEEK_WORKING_HOUR,
  TAB_SOFT_RULE,
} from 'constant';
import VersionHistory from './VersionHistory';
import PlanQuality from 'components/CalendarPlan/GeneratorContent/components/TableContent/PlanQuality/PlanQuality';
import GeneratorNavBar from './GeneratorNavBar/GeneratorNavBar';
import CalendarWorkEnvironment from 'components/CalendarPlan/CalendarWorkEnvironment/CalendarWorkEnvironment';
import SoftRulesTab from 'components/CalendarPlan/SoftRulesTab/SoftRulesTab';
import GeneralFilter from '../TableSetting/TableFilter/GeneralFilter';
import { selectGeneratorColApi } from 'redux/generateTableApi/slice';
import moment from 'moment';
import EmployeeNameCell from './EmployeeNameCell/EmployeeNameCell';
import { loadSession, saveSession } from 'helper/localStorage';
import HourPerWeek from './HourPerWeek';
import { selectModal } from '_components/Modal/slice';
import { SEND_EMP_SURVEY, SIDE_MENU } from '_components/CarlendarPlan/EmployeeV2/constants/modal';
import EmpSideMenu from 'components/CalendarPlan/CalendarPlanEmployee/EmployeeTablev2/EmpSideMenu/EmpSideMenu';
import SendSurvey from 'components/CalendarPlan/CalendarPlanEmployee/EmployeeTablev2/SendSurvey/SendSurvey';
import { useGeneralSettingsByKey } from 'views/pages/Admin/components/Settings/hook/useData';
import { generalSettingKey } from 'Hooks/constants';
import { useTurnusContext } from '../../Turnus/TurnusContext';
import CleaningAlert from './CleaningAlert/CleaningAlert';

const calculateTotalTaskByType = (data) => {
  const result = {};

  for (const task of data) {
    const { taskKey, taskList } = task;
    for (const weekDay of taskList) {
      const { date, numberPerson } = weekDay;

      if (!result[date]) {
        result[date] = {
          totalDayShift: 0,
          totalLateShift: 0,
          totalNightShift: 0,
          totalLongShift: 0,
          totalOtherShift: 0,
        };
      }

      switch (taskKey) {
        case 'day-shift': {
          result[date].totalDayShift += numberPerson;
          break;
        }
        case 'aft-shift': {
          result[date].totalLateShift += numberPerson;
          break;
        }
        case 'evn-shift': {
          result[date].totalNightShift += numberPerson;
          break;
        }
        case 'long-shift': {
          result[date].totalLongShift += numberPerson;
          break;
        }
        case 'other-shift': {
          result[date].totalOtherShift += numberPerson;
          break;
        }
        default: {
          console.warn(`Unknown taskKey: ${taskKey}`);
          break;
        }
      }
    }
  }

  return result;
};

const calculateSummaryData = (data, totalTaskCount) => {
  const result = {};
  for (const emp of data) {
    const isVakant = !emp.name;
    const taskArr = emp.task_arr;
    const listDate = Object.keys(taskArr);
    for (const date of listDate) {
      const task = taskArr[date];
      if (!task.length) {
        continue;
      }
      const isHaveRoleOrGroup = task[0].roleID || task[0].groupID;
      const taskType = task[0]?.taskType;
      const taskName = task[0]?.taskName;
      const roleName = task[0]?.roleName;
      const groupName = task[0]?.groupName;
      if (offShiftReg.test(taskName)) {
        continue;
      }
      if (!result[date]) {
        result[date] = {
          dayShift: 0,
          dayShiftRequirment: new Set(),
          dayShiftHaveRoleOrGroup: 0,
          totalDayShift: totalTaskCount[date]?.totalDayShift,
          totalDayShiftHaveRoleOrGroup: 0,

          lateShift: 0,
          lateShiftRequirment: new Set(),
          lateShifHaveRoleOrGroup: 0,
          totalLateShift: totalTaskCount[date]?.totalLateShift,
          totalLateShiftHaveRoleOrGroup: 0,

          nightShift: 0,
          nightShiftRequirment: new Set(),
          nightShifHaveRoleOrGroup: 0,
          totalNightShift: totalTaskCount[date]?.totalNightShift,
          totalNightShiftHaveRoleOrGroup: 0,

          longShift: 0,
          longShiftRequirment: new Set(),
          longShifHaveRoleOrGroup: 0,
          totalLongShift: totalTaskCount[date]?.totalLongShift,
          totalLongShiftHaveRoleOrGroup: 0,

          otherShift: 0,
          otherShiftRequirment: new Set(),
          otherShifHaveRoleOrGroup: 0,
          totalOtherShift: totalTaskCount[date]?.totalOtherShift,
          totalOtherShiftHaveRoleOrGroup: 0,
          vakantShift: 0,
        };
      }
      if (isVakant) {
        result[date].vakantShift++;
      }
      switch (taskType) {
        case 0:
          if (isHaveRoleOrGroup) result[date].totalDayShiftHaveRoleOrGroup++;
          if (!isVakant) {
            result[date].dayShift++;
            if (isHaveRoleOrGroup) {
              result[date].dayShiftHaveRoleOrGroup++;
              if (roleName) {
                result[date].dayShiftRequirment.add(roleName);
              }
              if (groupName) {
                result[date].dayShiftRequirment.add(groupName);
              }
            }
          }
          break;
        case 1:
          if (isHaveRoleOrGroup) result[date].totalLateShiftHaveRoleOrGroup++;
          if (!isVakant) {
            result[date].lateShift++;
            if (isHaveRoleOrGroup) {
              result[date].lateShifHaveRoleOrGroup++;
              if (roleName) {
                result[date].lateShiftRequirment.add(roleName);
              }
              if (groupName) {
                result[date].lateShiftRequirment.add(groupName);
              }
            }
          }
          break;
        case 2:
          if (isHaveRoleOrGroup) result[date].totalNightShiftHaveRoleOrGroup++;

          if (!isVakant) {
            result[date].nightShift++;
            if (isHaveRoleOrGroup) {
              result[date].nightShifHaveRoleOrGroup++;
              if (roleName) {
                result[date].nightShiftRequirment.add(roleName);
              }
              if (groupName) {
                result[date].nightShiftRequirment.add(groupName);
              }
            }
          }
          break;
        case 3:
          if (isHaveRoleOrGroup) result[date].totaLongShiftHaveRoleOrGroup++;

          if (!isVakant) {
            result[date].longShift++;
            if (isHaveRoleOrGroup) {
              result[date].longShifHaveRoleOrGroup++;
              if (roleName) {
                result[date].longShiftRequirment.add(roleName);
              }
              if (groupName) {
                result[date].longShiftRequirment.add(groupName);
              }
            }
          }
          break;

        default:
          if (isHaveRoleOrGroup) result[date].totalOtherShiftHaveRoleOrGroup++;

          if (!isVakant) {
            result[date].otherShift++;
            if (isHaveRoleOrGroup) {
              result[date].otherShifHaveRoleOrGroup++;
              if (roleName) {
                result[date].otherShiftRequirment.add(roleName);
              }
              if (groupName) {
                result[date].otherShiftRequirment.add(groupName);
              }
            }
          }
          break;
      }
    }
  }
  return result;
};

const TableContent = () => {
  const turnusData = useTurnusContext();
  const {
    employeeData,
    weekRange,
    onSortChanged,
    filterRoleList,
    // 3 tables
    // emp
    empInfoRef,
    // onEmpGridReady,
    planDetailLocked,
    unitSelected,
    publicHoliday,
    versionDateRange,
    // dashboard
    dashboardRef,
    onDashboardGridReady,
    holidayAndSundayRange,
    amlInformation,
    taskArr,
    planSelected,
    surveyData,
    refetchEmployeeList,
    refetchVakant,
    //summary
    summaryRef,
    onSummaryGridReady,
    refetchPlanChildList,
    // hr per week
    hrPerWeekRef,
    onHrPerWeekGridReady,
  } = turnusData;
  if (!employeeData || !employeeData.length) {
    return;
  }
  const modal = useSelector(selectModal);
  const filtersObj = useSelector(selectGeneratorTableFilter);
  const [toggleFilterModal, setToggleFilterModal] = useState(false);
  const colApi = useSelector(selectGeneratorColApi);
  const { empInfo, taskList, dashboard } = colApi;
  const filters = useSelector(selectGeneratorTableFilter);
  const { customDateRange } = filters;
  const [activeSurvey, setActiveSurvey] = useState(false);
  const [activeReport, setActiveReport] = useState(false);
  const [empSelected, setEmpSelected] = useState({});
  const [vakantInfo, setVakantInfo] = useState({});
  const { data: nightShiftSetting } = useGeneralSettingsByKey({
    unitCode: unitSelected,
    questionKey: 'start-time-for-night-shift',
  });

  const { data: groupList } = useGroups({ unitSelected });
  const listGroupAndColor = useMemo(
    () =>
      groupList?.data.map((item, index) => {
        return {
          ...item,
          color:
            GENERATOR_GROUP_COLOR_LIST[index] ||
            GENERATOR_GROUP_COLOR_LIST[GENERATOR_GROUP_COLOR_LIST.length - 1],
        };
      }),
    [groupList],
  );
  const dispatch = useDispatch();

  const checkDateInRange = (colId) => {
    if (colId.startsWith('emp-')) {
      return true;
    }
  };
  const showDateRange = () => {
    const allColumns = taskList.colApi.getColumns();
    allColumns.forEach((column) => {
      const colId = column.getColId();
      if (colId.startsWith('emp-')) {
        return;
      }
      const shouldShow = checkDateInRange(colId);
      taskList.colApi.setColumnVisible(colId, shouldShow);
    });
  };

  const handleDateChange = (field) => (e) => {
    const formattedDate = moment(e.target.value).format('YYYY-MM-DD');
    dispatch(
      updateFilter({
        key: field,
        value: formattedDate,
        isNested: true,
        nestedKey: 'customDateRange',
      }),
    );
  };

  const { data: employeeInfo } = useEmployeeInfo({
    empSelected,
  });

  // shift summary calcalation
  const { data: originalPlanData } = usePlanDetailData({
    plan: { id: planSelected?.parentID, versionID: planSelected?.versionID },
  });

  const totalTaskCount = useMemo(() => {
    if (originalPlanData) {
      return calculateTotalTaskByType(originalPlanData);
    }
    return {};
  }, [originalPlanData]);

  const summaryData = useMemo(() => {
    if (employeeData && totalTaskCount) {
      return calculateSummaryData(employeeData, totalTaskCount);
    }
    return {};
  }, [employeeData, totalTaskCount]);

  const empSession = loadSession('filter-emp-list') || [];
  const formatEmpSession = empSession.reduce(
    (acc, cur) => ({ ...acc, [cur.key]: !cur.checked }),
    {},
  );
  // clone emp info table's colDef to task list and dashboard for global search, need to sync with Emp Info table
  const hiddenColDef = [
    {
      field: 'id',
      headerName: '#',
      colId: 'emp-id',
      width: 40,
      hide: true,
      sortable: true,
      valueGetter: (params) => {
        const indexInList = employeeData.findIndex((emp) => +emp.employeeId === +params.node.id);
        return indexInList > -1 ? indexInList + 1 : '';
      },
      cellClass: 'id-cell',
      headerClass: 'id-header',
    },
    {
      field: 'name',
      headerName: t('emp-name'),
      hide: true,
      colId: 'emp-name',
      width: 140,
      cellStyle: () => {
        return { color: '#2098c0', fontWeight: 700, cursor: 'pointer' };
      },
      cellRenderer: ({ data }) => {
        if (!data) return;

        return <EmployeeNameCell data={data} />;
      },
    },
    {
      field: 'groupName',
      headerName: t('group'),
      colId: 'emp-group',
      hide: true,

      width: 120,
      resizable: true,
      sortable: true,
      valueGetter: ({ data }) => {
        if (!data || !data.employeePosition) return;
        const employeeGroups = data.employeePosition.employeeGroups;
        const listGroupName = employeeGroups.map((item) => item.groupInformation.name).join(',');
        return employeeGroups.length ? listGroupName : '-';
      },
      tooltipValueGetter: ({ data }) => {
        if (!data || !data.employeePosition) return;
        const employeeGroups = data.employeePosition.employeeGroups;
        return employeeGroups;
      },
    },
    {
      headerName: t('role-matched/role-not-match'),
      field: 'totalTask',
      colId: 'emp-task',
      hide: true,
      width: 130,
      valueGetter: ({ data }) => {
        if (!data) return;

        return `${data.numShiftWithExactRole}/${data.totalShift}` || '-';
      },
    },
    {
      field: 'role',
      headerName: t('role'),
      colId: 'emp-role',
      hide: true,
      width: 130,
    },
    {
      field: 'jobPercentage',
      headerName: t('job-percentage'),
      colId: 'emp-job-percentage',
      hide: true,
      headerClass: 'main-col-header',
      cellClass: 'main-col-cell',
      width: 70,
      valueFormatter: ({ data }) => {
        if (!data) return;

        const totalHrsPerWeek =
          data.totalHrsPerWeek && data.totalHrsPerWeek > 0
            ? data.totalHrsPerWeek
            : MAXIMUM_WEEK_WORKING_HOUR;

        const TOTAL_HRS = totalHrsPerWeek * weekRange?.length; // default 35.5 hrs per week
        const isAllocatedHoursNumber = typeof data.allocatedHours === 'number';
        const isJobPercentageNumber = typeof data.jobPercentage === 'number';

        return isAllocatedHoursNumber && data.jobPercentage === undefined
          ? `${((data.allocatedHours * 100) / TOTAL_HRS).toFixed(2)}%`
          : isJobPercentageNumber
          ? `${data.jobPercentage}%`
          : '-';
      },
    },
    {
      headerName: t('allocated-hours'),
      field: 'allocatedHours',
      colId: 'emp-hours',
      headerClass: 'main-col-header',
      cellClass: 'main-col-cell',
      hide: true,
      width: 70,
      valueGetter: ({ data }) => {
        if (!data) return;
        const rounded = data.allocatedHours.toFixed(2);
        /* rounded return string => sort wrong. Must be is number */
        return Number(rounded) || 0;
      },
    },
    {
      headerName: t('deviation'),
      field: 'deviation',
      headerClass: 'main-col-header',
      cellClass: 'main-col-cell',
      hide: true,
      colId: 'emp-deviation',
      resizable: false,
      width: 70,
      valueGetter: ({ data }) => {
        if (!data || !data.name) return;

        const rounded = data.deviation.toFixed(2);
        return data.jobPercentage === undefined ? 0 : Number(rounded) || 0;
      },
    },
  ];

  const buttonList = [
    { key: TAB_SCHEDULE, tableID: 'task-list-wrapper' },
    { key: TAB_HARD_RULE, tableID: 'ag-wrap' },
    { key: TAB_SOFT_RULE, tableID: 'ag-wrap' },
    { key: TAB_EMP_WISH, tableID: 'ag-wrap' },
    { key: TAB_ROTATION_COST_SUMMARY, tableID: '' },
    { key: TAB_HR_PER_WEEK, tableID: 'hr-per-week-wrapper' },
  ];

  const getRowId = ({ data }) => {
    if (!data) return;

    return data.employeeId;
  };

  const [isExternalFilterPresent, setIsExternalFilterPresent] = useState(false);
  const doesExternalFilterPass = useCallback(
    ({ data }) => {
      if (!data) return;

      const { id, name, role, assignedShift, jobPercentage } = filtersObj;

      let idMatch = true;
      let nameMatch = true;
      let roleMatch = true;
      let assignedMatchFrom = true;
      let assignedMatchTo = true;
      let jobPercentageFrom = true;
      let jobPercentageTo = true;

      if (id) {
        idMatch = data?.id.toString().includes(id.toString());
      }
      if (name) {
        nameMatch = data?.name?.toLowerCase().includes(name.toLowerCase());
      }
      if (role.length > 0) {
        roleMatch = role.includes(data?.role);
      }
      if (assignedShift.from) {
        assignedMatchFrom = data?.numShiftWithExactRole >= assignedShift.from;
      }
      if (assignedShift.to) {
        assignedMatchTo = data?.numShiftWithExactRole <= assignedShift.to;
      }
      if (jobPercentage.from) {
        jobPercentageFrom = data.jobPercentage >= jobPercentage.from;
      }
      if (jobPercentage.to) {
        jobPercentageTo = data.jobPercentage <= jobPercentage.to;
      }

      return (
        idMatch &&
        nameMatch &&
        roleMatch &&
        assignedMatchFrom &&
        assignedMatchTo &&
        jobPercentageFrom &&
        jobPercentageTo
      );
    },
    [filtersObj],
  );

  const [currentTab, setCurrentTab] = useState(TAB_SCHEDULE);
  const currentNavTabSession = loadSession('currentNavTab');
  const [currentNavTab, setCurrentNavTab] = useState(currentNavTabSession || TAB_TURNUS);

  const handleChangeNavTab = (tab) => {
    setCurrentNavTab(tab);
    saveSession('currentNavTab', tab);
  };

  const navList = [
    {
      key: TAB_TURNUS,
      method: () => {
        handleChangeNavTab(TAB_TURNUS);
      },
    },
    // {
    //   key: TAB_SUMMARY_SCHEDULE,
    //   method: () => {
    //     handleChangeNavTab(TAB_SUMMARY_SCHEDULE);
    //   },
    //   component: (
    //     <ShiftSummary
    //       currentTab={currentTab}
    //       ref={summaryRef}
    //       onGridReady={onSummaryGridReady}
    //       tableData={allData}
    //       planSelected={planSelected}
    //       summaryData={summaryData}
    //     />
    //   ),
    // },
    {
      key: TAB_AML,
      method: () => {
        handleChangeNavTab(TAB_AML);
      },
      component: <CalendarWorkEnvironment unitSelected={unitSelected} noMarginTop />,
    },
    // {
    //   // removed by request from lise marie
    //   key: TAB_SOFT_RULE,
    //   method: () => {
    //     handleChangeNavTab(TAB_SOFT_RULE);
    //   },
    //   component: <SoftRulesTab unitSelected={unitSelected} noMarginTop />,
    // },
    // TODO: implement logic for quality tab
    // {
    //   key: TAB_SUMMARY_QUALITY,
    //   method: () => {
    //     handleChangeNavTab(TAB_SUMMARY_QUALITY);
    //   },
    //   component: (
    //     <PlanQuality
    //       planSelected={planSelected}
    //       unitSelected={unitSelected}
    //       currentTab={currentNavTab}
    //     />
    //   ),
    // },
    {
      key: TAB_VERSION_HISTORY,
      method: () => {
        handleChangeNavTab(TAB_VERSION_HISTORY);
      },
      component: (
        <VersionHistory planSelected={planSelected} refetchPlanChildList={refetchPlanChildList} />
      ),
    },
  ];
  const renderNavContent = useCallback(() => {
    return navList.map((nav) => {
      if (nav.component) {
        return (
          <div
            key={`nav-component-${nav.key}`}
            className={`tables-container ${currentNavTab === nav.key ? 'active' : ''} ${
              currentNavTab !== TAB_TURNUS ? 'white_bg' : ''
            }`}
          >
            {nav.component}
          </div>
        );
      }
    });
  }, [currentNavTab, navList]);
  const [isActiveSaveBtn, setIsActiveSaveBtn] = useState(false);

  const searchTextFromSession = loadSession('generator-search-value') || '';
  const lastColEdited = loadSession('lastColEdited');
  const lastRowEdited = loadSession('lastRowEdited');
  useEffect(() => {
    if (searchTextFromSession !== undefined || (searchTextFromSession !== null && employeeData)) {
      const filterData = employeeData?.filter(
        (item) =>
          (item.name && item.name.toLowerCase().includes(searchTextFromSession)) ||
          item.name === undefined,
      );

      if (empInfo?.api?.setRowData) {
        empInfo.api.setRowData(filterData);
      }
      if (taskList?.api?.setRowData) {
        taskList.api.setRowData(filterData);

        //Auto focus last edit cell
        // if (lastColEdited) {
        //   taskList.api.ensureColumnVisible(lastColEdited, 'middle');
        // }
        // if (lastRowEdited) {
        //   taskList.api.ensureIndexVisible(lastRowEdited, 'middle');
        //   empInfo.api.ensureIndexVisible(lastRowEdited, 'middle');
        // }
      }
      if (dashboard?.api?.setRowData) {
        dashboard.api.setRowData(filterData);
      }
    }
  }, [
    searchTextFromSession,
    empInfo,
    taskList,
    dashboard,
    employeeData,
    lastColEdited,
    lastRowEdited,
  ]);
  const postSortRows = useCallback((params) => {
    let rowNodes = params.nodes;
    // let nextInsertPos = rowData.length ;
    let nextInsertPos = 0;

    // params.nodes = [...listEmp, ...listVakant];
    for (let i = 0; i < rowNodes.length; i++) {
      const name = rowNodes[i].data.name;
      const employeeId = rowNodes[i].data.employeeId;

      if (name && !isNaN(employeeId)) {
        // rowNodes.splice(i, 1);
        rowNodes.splice(nextInsertPos, 0, rowNodes.splice(i, 1)[0]);
        nextInsertPos++;
      }
    }
  }, []);

  return (
    <div>
      {toggleFilterModal && (
        <GeneralFilter
          gridColumnApi={taskList.colApi}
          setToggleFilterModal={setToggleFilterModal}
          fromRange={customDateRange.from}
          toRange={customDateRange.to}
          handleDateChange={handleDateChange}
          showDateRange={showDateRange}
          filters={filters}
          gridRef={empInfo.api}
          filterRoleList={filterRoleList}
          setIsExternalFilterPresent={setIsExternalFilterPresent}
        />
      )}
      {!!activeSurvey && Object.keys(employeeInfo).length ? (
        <ManageSurvey
          unitSelected={unitSelected}
          empInfo={employeeInfo}
          setActive={setActiveSurvey}
          refetchEmployeeList={refetchEmployeeList}
        />
      ) : (
        <></>
      )}
      {employeeData.length > 0 && (
        <div className="generator-main-content">
          <GeneratorNavBar
            navList={navList}
            setCurrentTab={setCurrentTab}
            currentNavTab={currentNavTab}
            setCurrentNavTab={setCurrentNavTab}
          />
          {renderNavContent()}
          <div className={`tables-action ${currentNavTab === TAB_TURNUS ? 'active' : ''}`}>
            <TableActions
              buttonList={buttonList}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              refetchEmployeeList={refetchEmployeeList}
              refetchVakant={refetchVakant}
            />
          </div>
          <div className={`tables-container__wrap ${currentNavTab === TAB_TURNUS ? 'active' : ''}`}>
            <>
              <div className={`tables-container ${currentNavTab === TAB_TURNUS ? 'active' : ''}`}>
                <EmpInfo
                  // allData={allData}
                  onFirstDataRendered={syncTables}
                  setEmpSelected={setEmpSelected}
                  setActiveSurvey={setActiveSurvey}
                  setActiveReport={setActiveReport}
                  setVakantInfo={setVakantInfo}
                  planDetailLocked={planDetailLocked}
                  onSortChanged={onSortChanged}
                  doesExternalFilterPass={doesExternalFilterPass}
                  getRowId={getRowId}
                  unitSelected={unitSelected}
                  filtersObj={filtersObj}
                  isExternalFilterPresent={isExternalFilterPresent}
                  planSelected={planSelected}
                  publicHoliday={publicHoliday}
                  postSortRows={postSortRows}
                />

                <TaskList
                  currentTab={currentTab}
                  // allData={allData}
                  onFirstDataRendered={syncTables}
                  hiddenColDef={hiddenColDef}
                  doesExternalFilterPass={doesExternalFilterPass}
                  getRowId={getRowId}
                  isExternalFilterPresent={isExternalFilterPresent}
                  setIsActiveSaveBtn={setIsActiveSaveBtn}
                  listGroupAndColor={listGroupAndColor}
                  unitSelected={unitSelected}
                  versionDateRange={versionDateRange}
                  originalPlanData={originalPlanData}
                  postSortRows={postSortRows}

                  //
                />
                <Dashboard
                  currentTab={currentTab}
                  ref={dashboardRef}
                  // allData={allData}
                  onFirstDataRendered={syncTables}
                  onGridReady={onDashboardGridReady}
                  holidayAndSundayRange={holidayAndSundayRange}
                  hiddenColDef={hiddenColDef}
                  doesExternalFilterPass={doesExternalFilterPass}
                  getRowId={getRowId}
                  amlInformation={amlInformation}
                  publicHoliday={publicHoliday}
                  planSelected={planSelected}
                  taskArr={taskArr}
                  surveyData={surveyData}
                  filtersObj={filtersObj}
                  isExternalFilterPresent={isExternalFilterPresent}
                  postSortRows={postSortRows}
                />
                <HourPerWeek
                  currentTab={currentTab}
                  ref={hrPerWeekRef}
                  onGridReady={onHrPerWeekGridReady}
                  // allData={allData}
                  onFirstDataRendered={syncTables}
                  getRowId={getRowId}
                  hiddenColDef={hiddenColDef}
                  isExternalFilterPresent={isExternalFilterPresent}
                  doesExternalFilterPass={doesExternalFilterPass}
                  unitSelected={unitSelected}
                  postSortRows={postSortRows}
                />
                <PlanQuality
                  currentTab={currentTab}
                  currentNavTab={currentNavTab}
                  planSelected={planSelected}
                  unitSelected={unitSelected}
                />
              </div>
              <TableLegend listGroupAndColor={listGroupAndColor} />
            </>
          </div>
          <CleaningAlert />

          <ShiftSummary
            currentNavTab={currentNavTab}
            planSelected={planSelected}
            onFirstDataRendered={syncTables}
          />
          {!!activeReport && Object.keys(vakantInfo).length ? (
            <VakantReport
              vakantInfo={vakantInfo}
              planSelected={planSelected}
              unitSelected={unitSelected}
              setActiveReport={setActiveReport}
            />
          ) : null}
        </div>
      )}
      {modal.name === SIDE_MENU && <EmpSideMenu unitSelected={unitSelected} />}
      {modal.name === SEND_EMP_SURVEY && <SendSurvey unitSelected={unitSelected} />}
    </div>
  );
};

export default memo(TableContent);
