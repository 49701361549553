import { t } from 'i18next';

//ENVIRONMENT TYPE
export const passwordRegex = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/;

export const HOSPITAL_ENV = 'hospital';
export const CLINICSL_ENV = 'clinics';
export const LIMIT_YEAR_IN_SURVEY = 3;
export const SORTABLE_COLUMN_ID = [
  'emp-id',
  'emp-name',
  'emp-group',
  'emp-role',
  'emp-task',
  'emp-job-percentage',
  'emp-hours',
  'emp-deviation',
  'emp-group',
];
export const EMPTY_ROLE = 'empty_role';
export const maximumWorkingHour = 1846;
export const DAY_OFF_TIME = 7.1;
export const DAY_WORKING_TIME = 7.5;
export const MAXIMUM_WEEK_WORKING_HOUR = 35.5;
export const SWITCH_BUTON_CALENDAR_EMPLOYEE = 'switch_button_calendar_employee';
export const analysisChartColor = [
  '#bc4bd1',
  '#077ae9',
  '#e1ce58',
  '#87c756',
  '#ff4081',
  '#ffffff',
  '#0ac4ba',
  '#ba7f3b',
  '#ff0000',
];

export const weekday = [t('mon'), t('tue'), t('wed'), t('thu'), t('fri'), t('sat'), t('sun')];
export const weekdayKey = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
export const weekdayKeyStartSun = [
  'sunday',
  'monday',
  'tuesday',
  'wedday',
  'thuday',
  'friday',
  'satday',
];

export const months = [
  t('jan'),
  t('feb'),
  t('mar'),
  t('apr'),
  t('may'),
  t('jun'),
  t('jul'),
  t('aug'),
  t('sep'),
  t('oct'),
  t('nov'),
  t('dec'),
];
export const monthKeys = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec',
];
export const websocketURL = process.env.REACT_APP_OPTIMIZE_API;
export const defaultStartDate = '2023-01-01';
export const planCode = ['Prediksjonslag'];
export const dateFormatReg =
  /[1-9][0-9][0-9]{2}-([0][1-9]|[1][0-2])-([1-2][0-9]|[0][1-9]|[3][0-1])/;

export const maxHourWorking = 1846;

export const EMP_NAME_TABLE_COLOR = '#2098c0';
export const EMP_ROLE_EDITED_COLOR = '#f5a623';

export const GENERATOR_GROUP_COLOR_LIST = [
  '#C2D076',
  '#C5AFA0',
  '#90BE6D',
  '#c9f0ff',
  '#FFE1EA',
  '#EFEFF0',
  '#E9BCB7',
  '#C5AFA0',
  '#94A187',
  '#EAEFBD',
  '#B4B8AB',
  '#949D6A',
  '#DBCBD8',
  '#D4CDC3',
  '#EA9010',
];

export const TASK_LIST_LEGEND_COLOR = {
  DAY_OFF: '#ecd8a8',
  VALIDITY_PERIOD: '#c9e8af',
  HOLIDAY: '#a6c2f0',
  DEFAULT: '#c43cbf2a',
};

export const WEEKENDS_KEY = ['fri', 'sat', 'sun'];

export const chartColorList = [
  '#883DCF', // purple
  '#F9C80E', // yellow
  '#22CAAD', // light green
  '#3B6BC9', // dark blue
  '#F86624', // dark orange
  '#2BB2FE', // cyan
  '#3366CC',
  '#DC3912',
  '#FF9900',
  '#109618',
  '#990099',
  '#3B3EAC',
  '#0099C6',
  '#DD4477',
  '#66AA00',
  '#B82E2E',
  '#316395',
  '#994499',
  '#22AA99',
  '#AAAA11',
  '#6633CC',
  '#E67300',
  '#8B0707',
  '#329262',
  '#5574A6',
  '#3B3EAC',
];
export const chartTooltipSize =
  screen.width > 1990
    ? {
        titleFont: {
          size: Math.ceil(screen.width / 150),
        },
        bodyFont: {
          size: Math.ceil(screen.width / 170),
        },
      }
    : {};

export const initChatGptConversation = [
  {
    message: 'from date "30-01-2023" and end date "31-12-2023" dataType analysis absence ',
    direction: 'outgoing',
    sender: 'user',
  },
  {
    sender: 'ChatGPT',
    message:
      '{    "from": "2023-01-30",    "to": "2023-12-31",    "dataType": "analysis absence",    "unitId": [],    "dateSelection": "Date"}',
  },
  {
    message: 'give me evaluation dataType data  and date from 01-05-2023 to 20-10-2023',
    direction: 'outgoing',
    sender: 'user',
  },

  {
    sender: 'ChatGPT',
    message:
      '{    "from": "2023-05-01",    "to": "2023-10-20",    "dataType": "evaluation",    "unitId": [],    "dateSelection": "Date"}',
  },

  {
    message:
      'give me data of efo and date from 01-05-2023 to 20-10-2023 unit Heimdal BOA Uståsen 28, Lade BOA Strindfjordvegen',
    direction: 'outgoing',
    sender: 'user',
  },
  {
    sender: 'ChatGPT',
    message:
      '{    "from": "2023-05-01",    "to": "2023-10-20",    "dataType": "efo",    "unitId": ["Heimdal BOA Uståsen 28", "Lade BOA Strindfjordvegen"],    "dateSelection": "Date"}',
  },

  {
    message: 'give me evaluation dataType data  and date from 01-05-2023 to 20-10-2023',
    direction: 'outgoing',
    sender: 'user',
  },
  {
    sender: 'ChatGPT',
    message:
      '{    "from": "2023-05-01",    "to": "2023-10-20",    "dataType": "evaluation",    "unitId": [],    "dateSelection": "Date"}',
  },

  {
    sender: 'ChatGPT',
    message:
      '{    "from": "2023-05-01",    "to": "2023-10-20",    "dataType": "efo",    "unitId": ["Heimdal BOA Uståsen 28", "Lade BOA Strindfjordvegen"],    "dateSelection": "Date"}',
  },

  {
    message:
      'gi meg data analys-absence og dato fra 01-01-2020 til 20-08-2023 typeskift er dagenhet Heimdal BOA Uståsen 28, Lade BOA Strindfjordvegen',
    direction: 'outgoing',
    sender: 'user',
  },
  {
    sender: 'ChatGPT',
    message:
      '{    "from": "2020-01-01",    "to": "2023-08-20",    "dataType": "analys-absence",    "unitId": ["Heimdal BOA Uståsen 28", "Lade BOA Strindfjordvegen"],    "dateSelection": "Date",    "typeShift": "dagenhet"}\nnull\nnull',
  },

  {
    message:
      'gi meg data sykefravær og dato fra 01-01-2020 til 20-08-2023 typeskift er aftenenhet ',
    direction: 'outgoing',
    sender: 'user',
  },
  {
    sender: 'ChatGPT',
    message:
      '{    "from": "2020-01-01",    "to": "2023-08-20",    "dataType": "sykefravær",    "unitId": [],    "dateSelection": "Date",    "typeShift": "aftenenhet"}\nnull\nnull\nnull',
  },
];
export const custommerVer2 = ['ahus'];
export const DATE_RANGE = 'date-range';
export const MULTIPLE_CHOICE = 'multiple-choice';
export const SINGLE_CHOICE = 'single-choice';
export const SINGLE_DATE = 'single-date';
export const MULTIPLE_DATE = 'multiple-date';
export const RANGE_DATE_ONLY = 'range-date-only';
export const TIME = 'time';
export const NUMBER = 'number';
export const CHECKBOX_TEXT = 'checkbox-text';
export const offShiftReg = /^F$|^F\d{1,}$/;
export const weekendRottionSpecialReg = /(\d{1,})\/(\d{1,})/;
export const surveyQuestionKey = {
  working_day_range: { key: 'validityPeriods', type: DATE_RANGE, dataKey: 'validityPeriods' },
  vacation_off: { key: 'vaction', type: DATE_RANGE, dataKey: 'vacation' },
  type_shift: { key: 'categoriesShift', type: MULTIPLE_CHOICE, dataKey: 'typeShift' },
  avg_afternoon_shift: { key: 'averageLateShift', type: NUMBER, dataKey: 'avgLateShift' },
  night_shift_in_row_per_week: {
    key: 'nightShiftPerWeek',
    type: SINGLE_CHOICE,
    dataKey: 'nigthShiftInRow',
  },
  weekend_rotation: { key: 'weekendRotation', type: SINGLE_CHOICE, dataKey: 'weekendRotation' },
  start_weekend_rotation: { key: 'startWeekendRotation', type: SINGLE_DATE },
  extend_date_off: { key: 'extendDayOff', type: MULTIPLE_DATE, dataKey: 'extendDateOff' },
  summer_period_week_percent: { key: 'temporaryJob', type: CHECKBOX_TEXT },
  holiday_off: { key: 'holidayOff', type: MULTIPLE_DATE },
  start_shift: { key: 'shiftStart', type: TIME, dataKey: 'extenedStartShift' },
  end_shift: { key: 'endShift', type: TIME, dataKey: 'extenedEndShift' },
  cannot_working_weekday: {
    key: 'weekdayCannotWorking',
    type: MULTIPLE_CHOICE,
    dataKey: 'extenedWeekday',
  },
  not_working_range: { key: 'notWorkingRange', type: DATE_RANGE },
};

export const surveyDataKey = {
  validityPeriods: 'validity-periods',
  vacation: 'vacation-check',
  typeShift: 'type-shift-check',
  avgLateShift: 'avg-env-shift-check',
  nigthShiftInRow: 'night-shift-in-rows',
  longShiftWeekend: 'long-shift-weekend',
  weekendRotation: 'weekend-rotation',
  extendDateOff: 'extended-date-off',
  summerPeriods: 'summer-periods',
  holidayOff: 'holiday-off',
  extenedStartShift: 'extended-question-shift-start',
  extenedEndShift: 'extended-question-shift-end',
  extenedWeekday: 'extended-question-weekdayt',
  extenedDateRange: 'extended-question-date-range',
};

export const TAB_SCHEDULE = 'schedule';
export const TAB_TURNUS = 'shift-plan';
export const TAB_AML = 'working-environment-act';
export const TAB_HARD_RULE = 'hard-rule';
export const TAB_SOFT_RULE = 'soft-rule';
export const TAB_EMP_WISH = 'emp-wish';
export const TAB_SUMMARY_SCHEDULE = 'summary-emp';
export const TAB_VERSION_HISTORY = 'version-history';
export const TAB_SUMMARY_QUALITY = 'quality';
export const TAB_HR_PER_WEEK = 'hr-per-week';
export const TAB_ROTATION_COST = 'rotation-cost';
export const TAB_ROTATION_COST_SUMMARY = 'costs';

//TYPE QUESTION IN SURVEY
export const TYPE_SURVEY_QUESTION = {
  TEXT: 'text',
  CHECK_BOX_TEXT: 'checkbox-text',
  SELECT_WEEK: 'select-week',
  DATE: 'date',
  RANGE_DATE_ONLY: 'range-date-only',
  DATE_ONLY: 'date-only',
  TIME: 'time',
  MULTIPLE_CHOICE: 'checkbox',
  SINGLE_CHOICE: 'radio',
  WEEKDAY_TYPE_SHIFT: 'week-day-type-shift',
  HOLIDAY_TYPE_SHIFT: 'holiday-type-shift',
};

export const GUARD_CODE_TYPE = {
  DAY_SHIFT: { key: 'D', value: 0 },
  LATE_SHIFT: { key: 'A', value: 1 },
  NIGHT_SHIFT: { key: 'N', value: 2 },
};

export const WEEK_TYPE = {
  ODD_WEEK: 'ODD_WEEK',
  EVEN_WEEK: 'EVEN_WEEK',
  ALL_WEEK: 'ALL_WEEK',
};

export const DAY_SHIFT_VALUE = 0;
export const EVENING_SHIFT_VALUE = 1;
export const NIGHT_SHIFT_VALUE = 2;
export const LONG_SHIFT_VALUE = 3;
export const PREDICT_DAY_SHIFT_VALUE = -1;
export const PREDICT_LATE_SHIFT_VALUE = -2;
export const PREDICT_NIGHT_SHIFT_VALUE = -3;
export const dateFormat = 'DD.MM.YYYY';

export const PREDICTION_TAB = 'prediction';
export const REGISTERED_ABSENCE_TAB = 'registered-absence';
export const EVALUATION_TAB = 'evaluation';

export const contentHave5Range = ['Set dates for your vacation weeks.'];
export const TIME_TO_CALL_PEOPLE_SECOND = 10 * 60; //10 minutes

export const IN_SUMMARY = 'in-summary';
export const PER_POSITION_CATEGORY = 'per-position-category';

//PAID BREAK KEY
export const PAID_BREAK_ALL_DAY = 'weekdays-weekends';
export const PAID_BREAK_WEEKDAY_ONLY = 'weekdays-only';
export const PAID_BREAK_WEEKEND_ONLY = 'weekends-only';
export const PAID_BREAK_HOLIDAY_ONLY = 'holiday-only';
export const PAID_BREAK_HOLIDAY_WEEKENDS_ONLY = 'holiday-weekends-only';

//HARD RULE PROPERTY

export const HARD_RULE_PROPERTY = {
  MAX_CONSECUTIVE_SUNDAYS: 'MaxConsecutiveSundays',
  MAX_SCHEDULED_DAY: 'MaxScheduledDay',
  MAX_SCHEDULED_WEEK: 'MaxScheduledWeek',
  MIN_DAILY_EVE_DAY: 'MinDailyEveDay',
  MIN_DAILY_DAY_NIGHT: 'MinDailyDayNight',
  MIN_DAILY_NIGHT_EVE: 'MinDailyNightEve',
  WEEK_HOURS_OFF: 'WeekHoursOff',
  MAX_CONSECUTIVE_SHIFTS: 'MaxConsecutiveShifts',
  MAX_AFTEN_ROW: 'MaxAftenRow',
  MAX_DAY_ROW: 'MaxDayRow',
  MAX_NIGHT_ROW: 'MaxNightRow',
};
