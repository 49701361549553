import React, { useState, forwardRef, useEffect } from 'react';

import Statistic from './Statistic';
import GuideHelper from './GuideHelper';
import TableHeader from './TableHeader';
import TaskTable from './TaskTable/TaskTable';

import './styles.scss';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useTaskList } from 'Hooks/useData';

const PlanDetail = forwardRef(function PlanDetail({
  planInfo,
  tableData,
  setTableData,
  gridRef,
  taskTypeList,
  showSummary,
  setShowSummary,
  unitSelected,
  setDeletedList,
  predictPercentData,
  setPredictPercentData,
  calculatePredictData,
  dayPredictData,
  latePredictData,
  nightPredictData,
  predictCalculated,
  setPredictCalculated,
  setPredictShiftList,
  showAbsencePrediction,
  setShowAbsencePrediction,
  layerList,
  refetchLayerList,
  layerCreated,
  setLayerCreated,
  publicHoliday,
  predictPercent,
}) {
  const [showStatistics, setShowStatistics] = useState(false);
  const { data: taskList, isFetched: isTaskListFetched } = useTaskList({ unitSelected });

  // useEffect(() => {
  //   if (Object.keys(predictPercentData).length) {
  //     setShowAbsencePrediction(true);
  //   }
  // }, [predictPercentData]);
  return (
    <div className="plan-detail">
      {planInfo.fromDate && planInfo.endDate ? (
        <>
          <TableHeader
            taskList={taskList}
            tableData={tableData}
            showSummary={showSummary}
            setShowSummary={setShowSummary}
            showStatistics={showStatistics}
            setShowStatistics={setShowStatistics}
            showAbsencePrediction={showAbsencePrediction}
            setShowAbsencePrediction={setShowAbsencePrediction}
            calculatePredictData={calculatePredictData}
            setPredictPercentData={setPredictPercentData}
          />
          <Statistic
            showStatistics={showStatistics}
            planInfo={planInfo}
            tableData={tableData}
            unitSelected={unitSelected}
            predictCalculated={predictCalculated}
            showAbsencePrediction={showAbsencePrediction}
            publicHoliday={publicHoliday}
            layerList={layerList}
            layerCreated={layerCreated}
          />
          <div className="plan-detail__table ag-theme-alpine">
            <TaskTable
              taskList={taskList}
              unitSelected={unitSelected}
              planInfo={planInfo}
              tableData={tableData}
              setTableData={setTableData}
              gridRef={gridRef}
              showSummary={showSummary}
              setShowSummary={setShowSummary}
              taskTypeList={taskTypeList}
              showAbsencePrediction={showAbsencePrediction}
              setDeletedList={setDeletedList}
              predictPercentData={predictPercentData}
              setPredictPercentData={setPredictPercentData}
              calculatePredictData={calculatePredictData}
              dayPredictData={dayPredictData}
              latePredictData={latePredictData}
              nightPredictData={nightPredictData}
              predictCalculated={predictCalculated}
              setPredictCalculated={setPredictCalculated}
              setPredictShiftList={setPredictShiftList}
              layerList={layerList}
              refetchLayerList={refetchLayerList}
              layerCreated={layerCreated}
              setLayerCreated={setLayerCreated}
              publicHoliday={publicHoliday}
              predictPercent={predictPercent}
            />
          </div>
        </>
      ) : (
        <GuideHelper />
      )}
    </div>
  );
});

export default PlanDetail;
