import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import axiosClient from 'axios/axiosClient';

import { Toast } from 'helper/alert';
import { useVersionData } from 'Hooks/useData';
import { selectGeneratorColApi } from 'redux/generateTableApi/slice';
import { selectGeneratorTableFilter } from 'redux/generateTableFilter/slice';

import { SearchIcon } from 'assets/img';
import EmpHeaderSetting from './EmpHeaderSetting';
import TableColumnView from './TableColumnView';
import { loadSession, saveSession } from 'helper/localStorage';
import { CLINICSL_ENV, TAB_ROTATION_COST_SUMMARY } from 'constant';
import { selectGlobal } from 'redux/global/globalSlice';
import { useTurnusContext } from '../../Turnus/TurnusContext';
import { autoSyncScroll, syncTables } from 'helper/syncTables';

const TableActions = ({
  buttonList,

  setAllData,
  currentTab,
  setCurrentTab,
  refetchEmployeeList,
  refetchVakant,
  setIsActiveSaveBtn,
}) => {
  const turnusData = useTurnusContext();
  const { planSelected, employeeData } = turnusData;
  const { customerType } = useSelector(selectGlobal);

  const colApi = useSelector(selectGeneratorColApi);
  const { empInfo, taskList, dashboard, hrPerWeek } = colApi;
  const originalData = useMemo(() => JSON.parse(JSON.stringify(employeeData)), [employeeData]);

  const filters = useSelector(selectGeneratorTableFilter);
  const { data: versionList = [] } = useVersionData({
    planID: planSelected.id,
  });
  const activeVersion = useMemo(() => versionList.find((item) => item.is_active), [versionList]);
  const [isLoading, setIsLoading] = useState(false);

  const [currentTableData, setCurrentTableData] = useState([]);

  useEffect(() => {
    setCurrentTableData([...employeeData]);
  }, [employeeData]);

  const searchTextFromSession = loadSession('generator-search-value') || '';
  const [searchText, setSearchText] = useState(searchTextFromSession);

  const handleQuickSearch = useCallback((e) => {
    const searchText = e.target.value.toLowerCase().trim();
    saveSession('generator-search-value', searchText);
    setSearchText(searchText);
  }, []);

  useEffect(() => {
    if (searchText !== undefined || (searchText !== null && employeeData)) {
      const filterData = employeeData?.filter(
        (item) =>
          (item.name && item.name.toLowerCase().includes(searchText)) || item.name === undefined,
      );

      if (empInfo?.api?.setRowData) {
        empInfo.api.setRowData(filterData);
      }
      if (taskList?.api?.setRowData) {
        taskList.api.setRowData(filterData);
      }
      if (dashboard?.api?.setRowData) {
        dashboard.api.setRowData(filterData);
      }
      if (hrPerWeek?.api?.setRowData) {
        hrPerWeek.api.setRowData(filterData);
      }

      setCurrentTableData(filterData);
    }
  }, [searchText, empInfo, taskList, dashboard, hrPerWeek, employeeData]);

  const findDifferences = (originalData, tableRowData) => {
    const differences = [];

    tableRowData.forEach((item) => {
      const equivalentItem = originalData.find(
        (originalItem) => originalItem.employeeId === item.employeeId,
      );

      if (!equivalentItem) {
        differences.push({ item, key: null, newValue: item.task_arr, oldValue: null });
        return;
      }

      Object.keys(item.task_arr).forEach((date) => {
        const newTasks = item.task_arr[date];
        const oldTasks = equivalentItem.task_arr[date];

        if (
          !oldTasks ||
          newTasks.length !== oldTasks.length ||
          newTasks.some((task, i) => +task.taskId !== +oldTasks[i].taskId)
        ) {
          differences.push({
            item,
            key: date,
            newValue: newTasks,
            oldValue: oldTasks,
          });
        }
      });
    });

    return differences;
  };

  const handleUpdateData = useCallback(async () => {
    // setIsLoading(true);
    const tableRowData = [];
    taskList.api.forEachNode((node) => tableRowData.push(node.data));
    const differences = findDifferences(originalData, tableRowData);

    if (!differences.length) {
      setIsLoading(false);
      Toast({ icon: 'error', title: t('no-changes-found'), timer: 1500 });
      return;
    }

    const updatedData = differences.map(({ item, newValue, oldValue, key }) => {
      const shifIDs = oldValue ? oldValue.map((item) => item.shiftID) : [];
      const vakantShifIDs = oldValue ? oldValue.map((item) => item.vakantShiftID) : [];

      return {
        employeeID: item.employeeId,
        taskUpdate: newValue,
        date: key,
        shiftID: item.name && shifIDs,
        vakantShifIDs: item.name ? null : vakantShifIDs,
        versionID: activeVersion.id,
      };
    });
    await axiosClient
      .post('calendar-plan/v2/update-generator-plan', {
        dataUpdated: updatedData,
        plan: planSelected,
      })
      .then(async (_) => {
        setAllData((prev) => ({ ...prev, data: tableRowData }));
        await refetchEmployeeList();
        await refetchVakant();
        Toast({ icon: 'success', title: `${t('update')} ${t('success')}` });
        setIsLoading(false);
        setIsActiveSaveBtn(false);
      })
      .catch((err) => {
        Toast({ icon: 'error', title: `${t('update')} ${t('unsuccess')}` });
        setIsLoading(false);
      });
  }, [currentTableData, activeVersion, taskList, employeeData]);

  return (
    <div className="tables-actions">
      <div className="tables-actions-left-container">
        <EmpHeaderSetting planSelected={planSelected} />
        <div className="table-filtering__search">
          <img src={SearchIcon} />
          <input
            type="text"
            id="filter-text-box"
            placeholder={`${t('search')}...`}
            value={searchText}
            onChange={handleQuickSearch}
          />
        </div>
      </div>
      <div className="tables-actions-right-container">
        <div className="nav-btns">
          {buttonList
            .filter((item) => {
              if (item.key === TAB_ROTATION_COST_SUMMARY) {
                return customerType === CLINICSL_ENV; //NOT RENDERING COST SUMMARY ON HOSPITAL ENV
              } else {
                return true;
              }
            })
            .map((item) => (
              <button
                key={item.key}
                className={currentTab === item.key ? 'active' : ''}
                onClick={() => {
                  autoSyncScroll(item.tableID);

                  if (currentTab === item.key) {
                    setCurrentTab('schedule');
                  } else {
                    setCurrentTab(item.key);
                  }
                }}
              >
                {t(item.key)}
              </button>
            ))}
        </div>
        <div className="action-btns">
          <TableColumnView />
          {/* Hidden bulk saving */}
          {/* <button className="save-btn" onClick={handleUpdateData} disabled={isLoading}>
            {t('save')}
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default TableActions;
