import React from 'react';

import { DeleteIcon, EditIcon } from 'assets/img';
import { useSelector } from 'react-redux';
import { selectAuth } from 'redux/auth/authSlice';

const PlanAction = ({ plan, setPlanSelected, handleDelete, setIsMainContent }) => {
  const { isEditabled } = useSelector(selectAuth);

  return (
    <div className="staff-plan-created">
      <p>{plan.firstName && plan.lastName ? plan.firstName + ' ' + plan.lastName : ''}</p>
      {isEditabled ? (
        <div>
          <img
            src={EditIcon}
            onClick={() => {
              setPlanSelected(plan);
              setIsMainContent(false);
            }}
          />
          <img
            src={DeleteIcon}
            onClick={() => {
              handleDelete(plan);
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default PlanAction;
