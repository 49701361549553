import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateListLockedAction,
  updatePlanDetailLockedAction,
} from 'redux/calendarPlan/calendarPlanAction';
import { selectCalendarPlan } from 'redux/calendarPlan/calendarPlanSlice';

import { LockIcon, UnlockIcon } from 'assets/img';

import './EmployeeGroupAction.scss';
import { getPlanDetailLocked, getShiftColID } from '../TableContent/helper/lockRange';
import { offShiftReg } from 'constant';
import { selectAuth } from 'redux/auth/authSlice';
import { enumerateDaysBetweenDates } from 'helper/enumerateDaysBetweenDates';

const LockedButton = (props) => {
  const { data, isHeader, totalData, agParam, publicHoliday = [] } = props;
  const { isEditabled } = useSelector(selectAuth);

  const { listLocked, planSelected } = useSelector(selectCalendarPlan);
  const planDetailLocked = useMemo(
    () => getPlanDetailLocked(listLocked, planSelected),
    [listLocked, planSelected],
  );
  const [isLocked, setIsLocked] = useState(false);

  const dispatch = useDispatch();

  const lockUser = useCallback(
    (empId, taskArr) => {
      if (
        isLocked ||
        planDetailLocked?.lockedData.find((item) => +item.employeeID === +data.employeeId)
      ) {
        dispatch(
          updateListLockedAction({
            uuid: planSelected.uuid,
            planDetailID: null,
            lockedData: planDetailLocked.lockedData.filter(
              (item) => +item.employeeID !== data.employeeId,
            ),
          }),
        );
        return;
      }
      const dateInPlan = enumerateDaysBetweenDates(planSelected.from_date, planSelected.to_date); //Get All date in plan

      const lockedData = [...planDetailLocked.lockedData] || [];
      for (const date of dateInPlan) {
        const dateTask = taskArr[date];
        if (!dateTask || !dateTask.length) {
          lockedData.push({
            date,
            shiftID: null,
            employeeID: empId,
            planDetailID: null,
            planID: planSelected.id,
          });
          continue;
        }
        const shift = dateTask[0];

        lockedData.push({
          date,
          shiftID: shift.isDeleted ? null : shift.shiftID,
          employeeID: empId,
          planDetailID: shift.isDeleted ? null : shift.planDetailID,
          planID: planSelected.id,
        });
      }

      dispatch(
        updateListLockedAction({
          uuid: planSelected.uuid,
          planDetailID: null,
          planID: planSelected.id,

          lockedData,
        }),
      );
    },
    [listLocked],
  );
  const lockAllUser = useCallback(
    (data) => {
      let lockedData = [];
      const dateInPlan = enumerateDaysBetweenDates(planSelected.from_date, planSelected.to_date);

      if (planDetailLocked.lockedData.length) {
        dispatch(
          updateListLockedAction({
            uuid: planSelected.uuid,
            planDetailID: null,
            lockedData: [],
          }),
        );
        return;
      }

      for (const empDataIndex in data) {
        const empData = data[empDataIndex];
        const empId = empData.employeeId;
        const taskArr = empData.task_arr;
        if (isNaN(empId)) continue;
        for (const date of dateInPlan) {
          const dateTask = taskArr[date];
          if (!dateTask || !dateTask.length) {
            lockedData.push({
              date,
              shiftID: null,
              employeeID: empId,
              planDetailID: null,
              planID: planSelected.id,
            });
            continue;
          }
          const shift = dateTask[0];
          lockedData.push({
            date,
            shiftID: shift.isDeleted ? null : shift.shiftID,
            employeeID: empId,
            planDetailID: shift.isDeleted ? null : shift.planDetailID,
            planID: planSelected.id,
          });
        }
      }
      dispatch(
        updateListLockedAction({
          uuid: planSelected.uuid,
          planDetailID: null,
          planID: planSelected.id,

          lockedData,
        }),
      );
    },
    [listLocked],
  );

  const handleLocked = () => {
    if (!isEditabled) return;
    if (isHeader) {
      lockAllUser(totalData);
    } else {
      lockUser(data.employeeId, data.task_arr);
    }
  };

  useEffect(() => {
    if (data?.employeeId) {
      if (planDetailLocked?.lockedData.find((item) => item.employeeID === +data.employeeId)) {
        setIsLocked(true);
      } else {
        setIsLocked(false);
      }
    }
    if (isHeader) {
      if (
        planDetailLocked?.employeeIdLockedList?.length === totalData?.length &&
        totalData?.length > 0
      ) {
        setIsLocked(true);
      } else {
        setIsLocked(false);
      }
    }
  }, [data, data?.employeeId, planDetailLocked, planDetailLocked.employeeIdLockedList]);
  return (
    <div className="employee-action__wrap">
      {data?.name || isHeader ? (
        // <LockOutlined className={isLocked ? 'lock' : ''} onClick={handleLocked} />
        <img src={isLocked ? LockIcon : UnlockIcon} onClick={handleLocked} />
      ) : null}
    </div>
  );
};

export default LockedButton;
