import { Toast } from 'helper/alert';
import axiosClient from 'axios/axiosClient';
import { clearStorage, saveState } from 'helper/localStorage';
import { logged, logging, logout } from './authSlice';
import { t } from 'i18next';
import { Cookies } from 'react-cookie';

import { persistor } from 'app/store';
import { removeState } from 'helper/localStorage';
import { resetGlobalState } from 'redux/global/globalSlice';

export const loginAction = (loginInfo) => {
  return async (dispatch) => {
    try {
      const result = await axiosClient.post('/auth/login', loginInfo);
      dispatch(logging({ isLoading: false }));
      dispatch(
        logged({
          isLogged: true,
          role: result.role,
          accessToken: result.accessToken,
          fullName: result?.fullName || '',
          username: result?.username || '',
          uuid: result?.uuid || '',
          isEditabled: result.isEditabled || false,
          isRemember: loginInfo.isRemember,
        }),
      );

      Toast({
        icon: 'success',
        title: 'Log in success',
      });
      Object.keys(result).forEach((item) => {
        saveState(item, result[item]);
      });
    } catch (err) {
      dispatch(logging({ isLoading: false }));
      if (err.response?.status === 500) {
        Toast({
          icon: 'error',
          title: t('check-connection'),
        });
      } else {
        Toast({
          icon: 'error',
          title: t('check-login-info'),
        });
      }
    }
  };
};

export const logoutAction = () => {
  clearStorage();

  return (dispatch) => {
    dispatch(logout(false));
    dispatch(resetGlobalState());
  };
};
