import { t } from 'i18next';
import React from 'react';

const GroupTable = ({ groupList, groupSelected, setGroupSelected, listGroupNotValid }) => {
  return (
    <table className="add-group-position__table">
      <thead>
        <th>{t('group')}</th>
        <th>{t('key')}</th>
      </thead>
      <tbody>
        {groupList?.map((group) => {
          const isExist = groupSelected.find((item) => {
            return +item.id === +group.id;
          });
          const isNotValid = listGroupNotValid.find((item) => {
            return +item.id === +group.id;
          });
          if (isNotValid) return;
          return (
            <tr key={`${group.id}-${group.name}`} onClick={() => {}}>
              <td>
                <input
                  type="checkbox"
                  name=""
                  id=""
                  checked={isExist}
                  onChange={() => {
                    if (isExist) {
                      setGroupSelected((prev) => prev.filter((item) => !(item.id === group.id)));
                    } else {
                      setGroupSelected((prev) => [
                        ...prev,
                        { ...group, isAdded: true, isDefault: false },
                      ]);
                    }
                  }}
                />
                {group.name}
              </td>
              <td>{group.key}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default GroupTable;
