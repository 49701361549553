import { useClickOutside } from 'helper/useClickOutSide';
import { t } from 'i18next';
import React, { useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { v4 as uuidv4 } from 'uuid';
import './EmployeeNameCell.scss';
import { SplitIcon } from 'assets/img';

const TooltipTable = ({ data, coord }) => {
  const totalValue = useMemo(() => {
    const result = { jobPercentage: 0, allocatedOfGroup: 0, deviationOfGroup: 0 };
    data.forEach((item) => {
      result.jobPercentage += item.jobPercentage;
      result.allocatedOfGroup += item.allocatedOfGroup;
      result.deviationOfGroup += item.deviationOfGroup;
    });
    return result;
  }, [data]);

  const renderBody = () => {
    return data.map((item) => {
      return (
        <tr key={uuidv4()}>
          <td>{item.groupName}</td>
          <td>{item.jobPercentage ? `${item.jobPercentage?.toFixed(2)}%` : '-'} </td>
          <td>{item.allocatedOfGroup.toFixed(2)} </td>
          <td>{item.deviationOfGroup.toFixed(2)} </td>
        </tr>
      );
    });
  };
  return (
    <div
      className="tooltip-table__wrap"
      style={{ position: 'fixed', top: coord?.y, left: '160px', zIndex: 9999 }}
    >
      <div className="emp-name">
        <span>{t('emp-name')}:</span>
        <span>{data[0].name}</span>
      </div>
      <table className="tooltip-table">
        <thead>
          <th>{t('group')}</th>
          <th>{t('job-percentage')}</th>
          <th>{t('allocated-hours')}</th>
          <th>{t('deviation').split(' ')[0]}</th>
        </thead>
        <tbody>
          {renderBody()}
          <tr>
            <td>{t('total')}</td>
            <td>{totalValue.jobPercentage.toFixed(2)}</td>
            <td>{totalValue.allocatedOfGroup.toFixed(2)}</td>
            <td>{totalValue.deviationOfGroup.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
const EmployeeNameCell = ({ data }) => {
  const { allocatedHours, splitHours, splitGroup, deviation, listSplitDeviation, name } = data;
  const isSplited = !!data.splitGroup?.length;
  const [isActiveTooltip, setIsActiveTooltip] = useState(false);
  const cellRef = useRef(null);
  const coord = cellRef.current?.getBoundingClientRect();

  const tableData = useMemo(() => {
    if (!splitGroup || splitGroup.length <= 1) {
      return [];
    }
    return splitGroup.map((item) => {
      const { groupID, groupName, jobPercentage } = item;
      let allocatedOfGroup = allocatedHours;
      let deviationOfGroup = deviation;
      if (splitHours) {
        const allocatedOfFound = splitHours.find((splg) => splg.groupID === groupID);
        if (allocatedOfFound) {
          allocatedOfGroup = allocatedOfFound.hourAssigned;
        }
      }
      if (listSplitDeviation) {
        const deviationFound = listSplitDeviation.find((det) => det.groupID === groupID);
        if (deviationFound) {
          deviationOfGroup = deviationFound.deviationSplit;
        }
      }
      return { groupID, groupName, jobPercentage, allocatedOfGroup, deviationOfGroup, name };
    });
  }, [data]);

  useClickOutside(cellRef, () => setIsActiveTooltip(false), cellRef);

  return (
    <div
      style={{ display: 'flex', gap: '5px', alignItems: 'center', position: 'relative' }}
      onMouseEnter={() => {
        setIsActiveTooltip(true);
      }}
      onMouseLeave={() => {
        setIsActiveTooltip(false);
      }}
      ref={cellRef}
    >
      <span>{name === undefined ? t('un-assigned') : name}</span>
      {/* {isSplited ? (
        <span>
          <img width={14} height={14} src={SplitIcon} alt="" />
        </span>
      ) : null} */}
      {/* {isSplited && isActiveTooltip && tableData.length > 0
        ? ReactDOM.createPortal(<TooltipTable data={tableData} coord={coord} />, document.body)
        : null} */}
    </div>
  );
};

export default EmployeeNameCell;
