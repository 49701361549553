import React from 'react';
import './PopUp.scss';
import { t } from 'i18next';
import { CloseOutlined } from '@ant-design/icons';

const PopUp = ({
  children,
  title,
  submitCallback,
  isActive,
  setIsActive,
  submitBtnText = 'submit',
  closeCallBack = () => {},
}) => {
  const closePopUp = async () => {
    if (closeCallBack) await closeCallBack();
    setIsActive(false);
  };

  if (!isActive) {
    return;
  }

  return (
    <>
      <div className="popup__background" onClick={closePopUp}></div>
      <div className="popup__wrap">
        <div className="popup__content">
          <div className="close" onClick={closePopUp}>
            <CloseOutlined />
          </div>
          <div className="popup__content-body">
            <div className="popup__content-title__wrap">
              <h3 className="popup__content-title">{title}</h3>
            </div>
            {children}
          </div>
          <div className="popup__content-footer">
            <button className="custom-btn none-bg" onClick={closePopUp}>
              {t('cancel')}
            </button>
            <button
              className="custom-btn "
              onClick={async () => {
                if (submitCallback) await submitCallback();
                setIsActive(false);
              }}
            >
              {t(submitBtnText)}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopUp;
