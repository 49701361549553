import React, { memo, useEffect, useMemo, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { t } from 'i18next';

import { TAB_HR_PER_WEEK } from 'constant';

import { useTaskList } from 'Hooks/useData';
import { calculateHrPerWeek } from 'helper/calculateTableData';

import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useDispatch } from 'react-redux';
import { useTurnusContext } from '../../Turnus/TurnusContext';
import { updateApi } from 'redux/generateTableApi/slice';
import { autoSyncScroll } from 'helper/syncTables';

const HourPerWeek = ({
  currentTab,
  onFirstDataRendered,
  getRowId,
  hiddenColDef,
  doesExternalFilterPass,
  isExternalFilterPresent,
  postSortRows,
}) => {
  const dispatch = useDispatch();
  const turnusData = useTurnusContext();
  const hrPerWeekRef = useRef(null);
  const { setGridColumnApiHrPerWeek, globalSortEmployee, unitSelected, employeeData, weekRange } =
    turnusData;
  const show = currentTab === TAB_HR_PER_WEEK;

  const { data: taskList } = useTaskList({ unitSelected });
  const rowData = useMemo(() => {
    return calculateHrPerWeek({ data: employeeData, weekRange, taskList });
  }, [employeeData, weekRange, taskList]);
  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      menuTabs: [],
      flex: 1,
    }),
    [],
  );

  const columnDefs = useMemo(() => {
    return [
      {
        headerName: '',
        children: weekRange?.map(({ week, dateString }, index) => ({
          headerName: `${t('week')} ${week}`,
          headerTooltip: dateString,
          tooltipShowDelay: 2000,
          headerClass: 'hr-header',
          cellClass: 'hr-cell',
          minWidth: 70,
          suppressColumnsToolPanel: true,
          filter: false,
          editable: false,
          menuTabs: [],
          valueGetter: (params) => {
            const { data } = params;
            if (!data) return;
            if (
              !data.hrPerWeek ||
              !data.hrPerWeek[week] ||
              (data.hrPerWeek && data.hrPerWeek[week] === 0)
            ) {
              return 0;
            }
            return data.hrPerWeek ? data.hrPerWeek[week]?.toFixed(2) : 0;
          },
        })),
      },
    ];
  }, [rowData, weekRange, employeeData]);
  const onHrPerWeekGridReady = (params) => {
    autoSyncScroll('hr-per-week-wrapper');

    setGridColumnApiHrPerWeek(params.columnApi);
    hrPerWeekRef.current = params.api;
    dispatch(updateApi({ key: 'hrPerWeek', value: { api: params.api, colApi: params.columnApi } }));
    if (globalSortEmployee) {
      params.columnApi.applyColumnState(globalSortEmployee);
    }
  };
  useEffect(() => {
    autoSyncScroll('hr-per-week-wrapper');
  }, [currentTab]);
  return (
    <div className={`ag-theme-alpine hr-per-week ${show ? '' : 'hidden'}`} id="hr-per-week-wrapper">
      <AgGridReact
        ref={hrPerWeekRef}
        defaultColDef={defaultColDef}
        onGridReady={onHrPerWeekGridReady}
        rowData={rowData}
        columnDefs={[...columnDefs, ...hiddenColDef]}
        onFirstDataRendered={onFirstDataRendered}
        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
        enableRangeSelection={true} // Optional - allows range selection of cells, chart, auto fill
        enableFillHandle={true} // allow auto fill, need to enableRangeSelection
        // enable chart
        enableCharts={true} // need to enableRangeSelection
        undoRedoCellEditing={true} // enable undo redo
        undoRedoCellEditingLimit={20} // set undo redo limit
        enableCellChangeFlash={true} // highlight cell when change
        immutableData={true}
        tooltipShowDelay={0}
        getRowId={getRowId}
        // enable external filter
        isExternalFilterPresent={() => isExternalFilterPresent}
        doesExternalFilterPass={doesExternalFilterPass}
        includeHiddenColumnsInQuickFilter={true} // this will allow searching for hidden col
        postSortRows={postSortRows}
      />
    </div>
  );
};

export default memo(HourPerWeek);
