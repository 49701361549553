import { t } from 'i18next';
import React, { Fragment } from 'react';

import { WhiteCaretDownIcon } from 'assets/img';

const CommentBox = ({
  empComment,
  setEmpComment,
  commentTopic,
  listCollapseTopic = [],
  isExpired,
  handleCollapseTopic = () => {},
}) => {
  const handleOnChange = (e) => {
    const { value } = e.target;
    setEmpComment(value);
  };
  return (
    <div className="topic__wrap survey-comment">
      <div
        className="topic-title__wrap"
        onClick={() => {
          if (commentTopic) handleCollapseTopic(commentTopic?.uuid);
        }}
      >
        <h2 className="topic-title">{t('comment-survey')}</h2>
      </div>
      <div
        className={`topic-group__question ${
          listCollapseTopic?.includes(commentTopic?.uuid) ? 'inactive' : ''
        }`}
      >
        <div className="calendar-plan-survey-form__answer-group">
          <div className="comment-box__wrap">
            <textarea
              className="comment-box"
              onChange={handleOnChange}
              value={empComment}
              cols="20"
              rows="5"
              maxLength={400}
              disabled={isExpired}
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentBox;
