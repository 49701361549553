export const syncTables = () => {
  const grid1 = document.getElementById('emp-info-wrapper');
  const grid2 = document.getElementById('task-list-wrapper');
  const grid3 = document.getElementById('ag-wrap');
  const grid4 = document.getElementById('hr-per-week-wrapper');
  const grid5 = document.getElementById('shift-summary-wrapper');
  if (grid1 && grid2 && grid3 && grid4 && grid5) {
    const gridBody1 = grid1.querySelector('.ag-body-viewport');
    const gridBody2 = grid2.querySelector('.ag-body-viewport');
    const gridHorizontalBody2 = grid2.querySelector('.ag-center-cols-viewport');
    const gridBody3 = grid3.querySelector('.ag-body-viewport');
    const gridBody4 = grid4.querySelector('.ag-body-viewport');
    const gridHorizontalBody5 = grid5.querySelector('.ag-center-cols-viewport');

    const syncScroll1 = () => {
      gridBody2.scrollTop = gridBody1.scrollTop;
      gridBody3.scrollTop = gridBody1.scrollTop;
      gridBody4.scrollTop = gridBody1.scrollTop;
    };

    const syncScroll2 = () => {
      gridBody1.scrollTop = gridBody2.scrollTop;
      gridBody3.scrollTop = gridBody2.scrollTop;
      gridBody4.scrollTop = gridBody2.scrollTop;
    };

    const syncScroll3 = () => {
      gridBody1.scrollTop = gridBody3.scrollTop;
      gridBody2.scrollTop = gridBody3.scrollTop;
      gridBody4.scrollTop = gridBody3.scrollTop;
      gridHorizontalBody5.scrollLeft = gridBody3.scrollLeft;
    };

    const syncScroll4 = () => {
      gridBody1.scrollTop = gridBody4.scrollTop;
      gridBody2.scrollTop = gridBody4.scrollTop;
      gridBody3.scrollTop = gridBody4.scrollTop;
      gridHorizontalBody5.scrollLeft = gridBody4.scrollLeft;
    };

    const syncScrollHorizontal5 = () => {
      gridHorizontalBody2.scrollLeft = gridHorizontalBody5.scrollLeft;
    };
    const syncScrollHorizontal2 = () => {
      gridHorizontalBody5.scrollLeft = gridHorizontalBody2.scrollLeft;
    };

    if (gridBody1 && gridBody2 && gridBody3 && gridBody4) {
      gridBody1.addEventListener('scroll', syncScroll1);
      gridBody2.addEventListener('scroll', syncScroll2);
      gridBody3.addEventListener('scroll', syncScroll3);
      gridBody4.addEventListener('scroll', syncScroll4);
    }
    if (gridHorizontalBody5 && gridHorizontalBody2) {
      gridHorizontalBody5.addEventListener('scroll', syncScrollHorizontal5);
      gridHorizontalBody2.addEventListener('scroll', syncScrollHorizontal2);
    }
  }
};

export const syncScrollTables = (elementId1, elementId2) => {
  const grid1 = document.getElementById(elementId1);
  const grid2 = document.getElementById(elementId2);
  if (grid1 && grid2) {
    const gridBody1 = grid1.querySelector('.ag-body-viewport');
    const gridBody2 = grid2.querySelector('.ag-body-viewport');

    const syncScroll1 = () => {
      gridBody2.scrollTop = gridBody1.scrollTop;
    };

    const syncScroll2 = () => {
      gridBody1.scrollTop = gridBody2.scrollTop;
    };

    if (gridBody1 && gridBody2) {
      gridBody1.addEventListener('scroll', syncScroll1);
      gridBody2.addEventListener('scroll', syncScroll2);
    }
  }
};

export const autoSyncScroll = (nextTableID) => {
  if (!nextTableID || !nextTableID.length) return;
  const grid1 = document.getElementById('emp-info-wrapper');
  const grid2 = document.getElementById(nextTableID);

  if (grid1 && grid2) {
    const gridBody1 = grid1.querySelector('.ag-body-viewport');
    const gridBody2 = grid2.querySelector('.ag-body-viewport');
    if (gridBody1 && gridBody2) {
      gridBody2.scrollTop = gridBody1.scrollTop;
    }
  }
};
