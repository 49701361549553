import React, { useEffect, useRef, useState } from 'react';
import {
  GlobalOutlined,
  UserOutlined,
  RightOutlined,
  QuestionCircleOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';

import { selectAuth } from 'redux/auth/authSlice';
import { logoutAction } from 'redux/auth/authAction';
import { changeLangAction } from 'redux/global/globalAction';

import { LeftArrowBrownIcon, ChangePasswordIcon, LogoutIcon } from 'assets/img';
import { clearStorage } from 'helper/localStorage';

const UserProfile = ({ setActiveChangePassword }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isShow, setIsShow] = useState(false);
  const [isChangeLanguage, setIsChangeLanguage] = useState(false);
  const btnRef = useRef(null);
  const menuRef = useRef(null);

  const { username, fullName } = useSelector(selectAuth);

  const toggleSetting = () => {
    setIsShow(!isShow);
  };

  const menuOptionList = [
    {
      title: t('language'),
      onClick: () => {
        setIsChangeLanguage(true);
        setIsShow(true);
      },
      startIcon: <GlobalOutlined />,
      endIcon: <RightOutlined />,
    },
    {
      title: t('change-password'),
      onClick: () => {
        setActiveChangePassword(true);
      },
      startIcon: <img src={ChangePasswordIcon} width={18} height={18} />,
      endIcon: null,
    },
    {
      title: t('help'),
      onClick: () => {
        navigate('/help');
        setIsShow(false);
      },
      startIcon: <QuestionCircleOutlined />,
      endIcon: null,
    },
  ];

  const langList = [
    {
      value: 'EN',
    },
    {
      value: 'NO',
    },
  ];

  const renderOptions = () => {
    return menuOptionList.map((item, index) => {
      return (
        <div key={index} className="option" onClick={item.onClick}>
          <div>{item.startIcon}</div>
          <span>{item.title}</span>
          <div>{item.endIcon}</div>
        </div>
      );
    });
  };

  const handleLogout = () => {
    clearStorage();
    navigate('/login');
    dispatch(logoutAction());
  };

  const handleChangeLang = (lang) => {
    i18next.changeLanguage(lang);
    dispatch(changeLangAction(lang));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (btnRef.current && btnRef.current.contains(event.target)) {
        return;
      }
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsShow(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div
        ref={btnRef}
        className={`user-profile__name ${isShow && 'active'}`}
        onClick={toggleSetting}
      >
        <p>{fullName}</p>
        <div>
          <UserOutlined />
        </div>
      </div>

      <div ref={menuRef} className={`user-profile__menu ${isShow && 'is-open'}`}>
        {isChangeLanguage ? (
          <div className="user-profile__menu-lang">
            <div className="user-profile__menu-lang-back">
              <span>{t('change-password')}</span>
              <img
                src={LeftArrowBrownIcon}
                width={24}
                height={24}
                onClick={() => setIsChangeLanguage(false)}
              />
            </div>
            <div className="user-profile__menu-lang-options" style={{ borderBottom: 'none' }}>
              {langList.map((item) => (
                <div
                  key={item.value}
                  className="option"
                  onClick={() => handleChangeLang(item.value)}
                >
                  <span className="lang-option">{item.value}</span>
                  {i18next.language === item.value && (
                    <div>
                      <CheckOutlined />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <>
            <div className="user-profile__menu-info">
              <p>{fullName}</p>
              <p>{username}</p>
            </div>
            <div className="user-profile__menu-options">{renderOptions()}</div>
            <div className="user-profile__menu-logout">
              <div className="option" onClick={handleLogout}>
                <img src={LogoutIcon} width={18} height={18} />
                <span>{t('log-out')}</span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
