import React, { forwardRef, useEffect, useState } from 'react';

const GroupMultipleSelection = forwardRef((params, ref) => {
  const { data, groupFetch, onValueChange, stopEditing, setIsTableError } = params;
  const [groupSelected, setGroupSelected] = useState([]);
  useEffect(() => {
    setGroupSelected(data.listGroup);
  }, [data]);
  const handleOnChange = (group) => {
    const isExist = groupSelected.find((item) => +group.id === +item.groupID);
    if (isExist) {
      setGroupSelected((prev) => prev.filter((item) => item.groupID !== +group.id));
      data.listGroup = groupSelected.filter((item) => item.groupID !== +group.id);
    } else {
      setGroupSelected((prev) => [
        ...prev,
        { groupID: group.id, groupName: group.name, groupKey: group.key },
      ]);
      data.listGroup = [
        ...groupSelected,
        { groupID: group.id, groupName: group.name, groupKey: group.key },
      ];
    }
  };
  return (
    <div className="group-multiple-list__wrap">
      <div className="group-multiple-list">
        {groupFetch.map((item) => {
          return (
            <div
              key={`${item.id}-${item.key}-${item.name}-bulk-edi-group`}
              className="group-multiple-item"
            >
              <input
                type="checkbox"
                name=""
                id={`${item.id}-${item.key}-${item.name}-bulk-edi-group`}
                checked={groupSelected.find((group) => +item.id === +group.groupID)}
                onChange={() => {
                  handleOnChange(item);
                }}
              />
              <label htmlFor={`${item.id}-${item.key}-${item.name}-bulk-edi-group`}>
                {item.name}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
});

export default GroupMultipleSelection;
