import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useSelector } from 'react-redux';
import { selectGlobal } from 'redux/global/globalSlice';
import { selectAuth } from 'redux/auth/authSlice';
const QuestionItem = ({
  question = {},
  value = '',
  handleUpdate,
  isNameId = false,
  onChange,
  type,
}) => {
  const { isEditabled } = useSelector(selectAuth);
  const { language } = useSelector(selectGlobal);
  const isNo = language === 'NO';
  const questionType = type ? type : question.type;
  const defaultValue =
    question.records && question.records.length ? question.records[0].option_id : null;
  return (
    <div className="question-item">
      <p className="question-col ">
        {isNo ? question.question_no : question.question}
        <span className="desc">{isNo ? question.question_no_desc : question.question_desc}</span>
      </p>

      {questionType === 'select' ? (
        // TODO: integrate dynamic option for this select
        <select onChange={onChange} value={defaultValue} disabled={!isEditabled}>
          {question.options.map((option) => (
            <option key={uuidv4()} value={+option.id}>
              {isNo ? option.no_content : option.en_content}
            </option>
          ))}
        </select>
      ) : questionType === 'checkbox' ? (
        <input
          type={'checkbox'}
          checked={value}
          defaultValue={value}
          name={isNameId ? question.id : question.uuid}
          onChange={handleUpdate}
          disabled={!isEditabled}
        />
      ) : question.inputUnit ? (
        <div className="input-with-unit">
          <input
            type="number"
            value={value}
            name={isNameId ? question.id : question.uuid}
            onChange={handleUpdate}
            disabled={!isEditabled}
          />
          <p>{question.inputUnit}</p>
        </div>
      ) : (
        <input
          type={questionType || 'number'}
          value={value}
          name={isNameId ? question.id : question.uuid}
          onChange={handleUpdate}
          style={isNameId ? { textAlign: 'center', width: 150 } : {}}
          disabled={!isEditabled}
        />
      )}
    </div>
  );
};

export default QuestionItem;
