import React, { useRef, useCallback, useState, useEffect } from 'react';
import './SplitEmployee.scss';
import { useClickOutside } from 'helper/useClickOutSide';
import { t } from 'i18next';
import { Toast } from 'helper/alert';
import employeeAPI from '_apis/employee';
import { useGroups } from 'Hooks/useData';

const SplitEmployee = ({
  employee,
  setIsSplit,
  coord,
  splitBtnRef,
  unitSelected,
  refetchEmployees,
  // groupList,
}) => {
  if (!employee) {
    return;
  }
  const { data: groupList } = useGroups({ unitSelected }); // use this in emp new ui only

  const splitRef = useRef(null);

  const [splitInfo, setSplitInfo] = useState({
    groupID: '',
    employeeID: employee.id,
    jobPercentage: null,
  });
  const { splitEmployee } = employeeAPI();

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setSplitInfo((prev) => ({ ...prev, [name]: value }));
  };
  const handleSubmit = async () => {
    const { jobPercentage, groupID } = splitInfo;

    if (jobPercentage > employee.job_percentage) {
      Toast({
        icon: 'warning',
        title: 'Job percentage too big',
      });
      return;
    }
    if (!groupID || !groupID.length) {
      Toast({
        icon: 'warning',
        title: 'Group cannot null',
      });
      return;
    }

    splitEmployee({
      ...splitInfo,
    }).then((_) => {
      Toast({
        icon: 'success',
        title: `${t('split')} ${t('success')}`,
      });
      refetchEmployees();
      setIsSplit(false);
    });
  };

  useClickOutside(splitRef, () => setIsSplit(false), splitBtnRef);
  const listGroupSplited = employee.listGroupSplit?.map((item) => item.groupID);
  const renderGroupOpt = useCallback(() => {
    return groupList?.data
      ?.filter((item) => !listGroupSplited?.includes(item.id))
      ?.filter((item) => !(item.id === employee.group_id))
      ?.map((item) => {
        return (
          <option key={`group-${item.id}-${item.key}`} value={item.id}>
            {item.name}
          </option>
        );
      });
  }, [groupList, employee.group_id, listGroupSplited]);
  return (
    <div
      className={` ${coord ? 'split-emp__wrap' : 'split-emp__wrap-alt'}`}
      ref={splitRef}
      style={{ top: coord?.y, right: coord ? 20 : 70 }}
    >
      <div className="split-emp">
        <div className="split-emp__header">
          <div className="emp-name">{employee.name}</div>
        </div>
        <div className="split-emp__body">
          <div className="split-emp__group">
            <div className="split-emp__item">
              <label htmlFor="">Group</label>
              <select onChange={handleOnChange} name="groupID" id="">
                <option value=""></option>
                {renderGroupOpt()}
              </select>
            </div>
            <div className="split-emp__item">
              <label htmlFor="">Job Percent</label>
              <input onChange={handleOnChange} type="number" max={100} name="jobPercentage" id="" />
            </div>
          </div>
        </div>
        <div className="split-emp__footer">
          <button onClick={handleSubmit} className="custom-btn square-btn">
            {t('split')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SplitEmployee;
