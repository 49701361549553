import { CommentIcon } from 'assets/img';
import './CommentCell.scss';
import React from 'react';

const CommentCell = ({ data }) => {
  const comment = data.comment;
  if (!comment || !comment.length) {
    return;
  }
  return (
    <div className="comment-cell">
      <img src={CommentIcon} alt="" />
    </div>
  );
};

export default CommentCell;
