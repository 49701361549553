import { WEEKENDS_KEY, dateFormatReg, offShiftReg, weekdayKey } from 'constant';
import moment from 'moment';
import { updateListLockedAction } from 'redux/calendarPlan/calendarPlanAction';

export const getShiftColID = ({ dateInWeek, publicHoliday }) => {
  let dateChecking = dateInWeek;

  let colId = `${dateChecking}`;
  let weekday = moment(dateChecking).weekday();
  if (weekday == 0) {
    weekday = 6;
  } else {
    weekday -= 1;
  }
  const dayKey = weekdayKey[weekday];
  if (WEEKENDS_KEY.includes(dayKey)) {
    colId = 'weekend-' + colId;
  } else {
    colId = 'weekdays-' + colId;
  }
  if (!publicHoliday.includes(dateChecking)) {
    const beforeMaundyThursday = [
      "2021-03-31",
      "2022-04-13",
      "2023-04-05",
      "2024-03-27",
      "2025-04-16",
      "2026-04-01",
      "2027-03-24",
      "2028-04-12",
      "2029-03-28",
      "2030-04-17",
      "2031-04-02",
      "2032-03-24",
      "2033-04-13",
      "2034-03-29",
  ];
  const beforeChristmas = '12-24';
  const beforeNewyearEve = '12-31';

  if (beforeMaundyThursday.includes(dateChecking) || 
    dateChecking.indexOf(beforeChristmas) > -1 ||
    dateChecking.indexOf(beforeNewyearEve) > -1
    ) {
      colId = colId + '--semi_holidays';
    } else {
      colId = colId + '--not_holidays';
    }
    
  } else {
    colId = colId + '--holidays';
  }
  return colId;
};

export const getPlanDetailLocked = (listLocked, planSelected) => {
  const planDetailLocked = listLocked.find((item) => item.uuid === planSelected.uuid);
  if (!planDetailLocked || !planDetailLocked.lockedData) {
    return {
      lockingPlanId: '',
      planDetailLockedList: [],
      employeeIdLockedList: [],
      emptyCell: [],
      offShiftLocked: [],
      lockedData: [],
    };
  }
  return planDetailLocked;
};

export const getEditShiftPlanDetailID = ({ originalPlanData, date, taskWillUpdate }) => {
  if (!taskWillUpdate || !taskWillUpdate.taskId) {
    return;
  }

  if (taskWillUpdate.planDetailID) {
    return taskWillUpdate.planDetailID;
  }

  const taskData = originalPlanData?.find((task) => {
    let layerCondition = true;
    let groupConditinon = true;
    let roleCondition = true;
    if (taskWillUpdate.layerID && taskWillUpdate.layerID !== 'null') {
      layerCondition = +taskWillUpdate.layerID === +task.layerID;
    }
    if (taskWillUpdate.group_id) {
      groupConditinon = +taskWillUpdate.group_id === +task.groupID;
    }
    if (taskWillUpdate.roleId) {
      roleCondition = +taskWillUpdate.roleId === +task.roleID;
    }
    return (
      +task.taskID === +taskWillUpdate.taskId && layerCondition && groupConditinon && roleCondition
    );
  });
  if (!taskData) {
    return;
  }
  const { taskList } = taskData;
  const planDetail = taskList.find((item) => item.date === date);
  if (!planDetail) {
    return;
  }
  return planDetail.planDetailID;
};

export const calculateCellLocked = (isShiftLocked, isOffShiftLocked, employeeId, dateInWeek) => {
  let isCellLocked = false;

  if (isShiftLocked) {
    isCellLocked = isShiftLocked.listEmpID.includes(employeeId);
  }

  if (isOffShiftLocked) {
    isCellLocked =
      isCellLocked || isOffShiftLocked.lockDate.some((item) => item.date === dateInWeek);
  }

  return isCellLocked;
};

export //Ưill refactor this function later
const lockEmployeeShiftByRangeSelect = ({ params, planSelected, planDetailLocked, dispatch }) => {
  const gridApi = params.api;

  let cellRanges = gridApi.getCellRanges();
  const lockedData = [...planDetailLocked.lockedData];

  if (cellRanges) {
    cellRanges.forEach((range) => {
      // get starting and ending row, remember rowEnd could be before rowStart
      const startRow = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
      const endRow = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);
      for (let rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
        range.columns.forEach((column) => {
          const rowNode = gridApi.getDisplayedRowAtIndex(rowIndex);
          const colId = column.colId;
          let date;
          if (dateFormatReg.exec(colId)) {
            date = dateFormatReg.exec(colId)[0];
          }

          const data = rowNode?.data;
          const taskArr = data?.task_arr;
          const employeeId = data?.employeeId;
          const isLocked = lockedData.find((item) => {
            item.employeeID === employeeId && date === item.date;
          });
          if (!isLocked) {
            const dateTask = taskArr[date];
            if (!dateTask || !dateTask.length) {
              lockedData.push({
                date,
                shiftID: null,
                employeeID: employeeId,
                planDetailID: null,
                planID: planSelected.id,
              });
            } else {
              const shift = dateTask[0];

              lockedData.push({
                date,
                shiftID: shift.isDeleted ? null : shift.shiftID,
                employeeID: employeeId,
                planDetailID: shift.isDeleted ? null : shift.planDetailID,
                planID: planSelected.id,
              });
            }
          }
        });
      }
    });
  }

  dispatch(
    updateListLockedAction({
      uuid: planSelected.uuid,
      planID: planSelected.id,
      lockedData,
    }),
  );
};

export const unlockEmployeeShiftByRangeSelect = ({
  params,
  planSelected,
  planDetailLocked,
  dispatch,
}) => {
  const gridApi = params.api;

  let cellRanges = gridApi.getCellRanges();

  let lockedData = [...planDetailLocked.lockedData];

  if (cellRanges) {
    cellRanges.forEach((range) => {
      // get starting and ending row, remember rowEnd could be before rowStart
      const startRow = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
      const endRow = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);
      for (let rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
        range.columns.forEach((column) => {
          const rowNode = gridApi.getDisplayedRowAtIndex(rowIndex);
          const colId = column.colId;
          let date;
          if (dateFormatReg.exec(colId)) {
            date = dateFormatReg.exec(colId)[0];
          }

          const data = rowNode?.data;
          const employeeId = data?.employeeId;

          lockedData = lockedData.filter(
            (item) => +item.employeeID !== +employeeId || date !== item.date,
          );
        });
      }
    });

    dispatch(
      updateListLockedAction({
        uuid: planSelected.uuid,
        planID: planSelected.id,
        lockedData,
      }),
    );
  }
};
