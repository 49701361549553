import { CloseCircleOutlined } from '@ant-design/icons';
import { ADD_LAYER } from '_components/CarlendarPlan/EmployeeV2/constants/modal';
import { openModal } from '_components/Modal/slice';
import { CloseIcon, EditIcon } from 'assets/img';
import axiosClient from 'axios/axiosClient';
import { dispatch } from 'd3';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuth } from 'redux/auth/authSlice';

const EditLayer = ({
  layerID,
  refetchLayerList,
  setTableData,
  setDeletedList,
  tableData,
  setLayerCreated,
}) => {
  const { isEditabled } = useSelector(selectAuth);

  const dispatch = useDispatch();
  const handleEditLayer = () => {
    if (!isEditabled) return;

    dispatch(openModal({ name: ADD_LAYER, data: { layerID } }));
  };
  const handleDeleteLayer = async () => {
    if (!isEditabled) return;
    if (!isNaN(layerID)) {
      await axiosClient.post('calendar-plan/staff-plan/layer/delete', { layerID }).then((_) => {
        setTableData((prev) => {
          return prev.filter((item) => +item.layerID !== +layerID);
        });

        const listTaskDeleted = tableData.filter((item) => +item.layerID === +layerID);
        const deletedList = listTaskDeleted
          .map((rowData) => {
            return rowData.taskList.map((item) => item.planDetailID);
          })
          .flat();
        setDeletedList((prev) => [...prev, ...deletedList].filter((item) => item));
        refetchLayerList();
      });
    } else {
      setLayerCreated((prev) => {
        return prev.filter((item) => item.layerName != layerID);
      });
    }
  };

  return (
    <div className="edit-layer__wrap">
      <div className="edit-layer__item" onClick={handleEditLayer}>
        <img width={30} src={EditIcon} alt="" />
      </div>
      <div className="edit-layer__item" onClick={handleDeleteLayer}>
        <CloseCircleOutlined />
      </div>
    </div>
  );
};

export default EditLayer;
