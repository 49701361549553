import { t } from 'i18next';
import React, { useMemo } from 'react';
import { Pie } from 'react-chartjs-2';
import { calculateQualityChartData } from '../calculatior';

const QualityPieChart = ({ qualityData, roleList }) => {
  const chartData = calculateQualityChartData({ qualityData, roleList });
  const colorList = [
    '#2034E6',
    '#6AD2FF',
    '#EFF4FB',
    '#F7CAC9', // Light salmon pink
    '#C2F0F7', // Light blue-green
    '#FCE4EC', // Light pink
    '#A7EEBB', // Light turquoise
    '#FFEBCC', // Light peach
    '#D7CCC8', // Light taupe (for smaller slices)
  ];
  const labels = useMemo(() => chartData.map((item) => item.label), [chartData]);
  const data = useMemo(
    () => ({
      labels,
      datasets: [
        {
          data: chartData.map((item) => item.value),
          backgroundColor: colorList,
          borderColor: colorList,
          borderWidth: 1,
        },
      ],
    }),
    [chartData],
  );
  const options = () => {
    return {
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: 'bottom',
        },
      },
    };
  };
  return (
    <div className="quality-pie-chart__wrap">
      <div className="quality-pie-chart__title">
        <h4>{t('vacancies-per-job-category')}</h4>
      </div>
      <div className="quality-pie-chart__content">
        <div className="chart">
          <Pie data={data} options={options()} />
        </div>
        <div className="chart-legend">
          {chartData.map((data, index) => {
            return (
              <div key={`chart-legned__item"-${data.label}`} className="chart-legend__item">
                <div className="buble" style={{ backgroundColor: colorList[index] }}></div>
                <div className="legend-name">
                  <div className="name">{data.label}</div>
                  <div className="value">{data.value}%</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default QualityPieChart;
