import React from 'react';
import { t } from 'i18next';

import { PlusIcon, PlusBrownIcon } from 'assets/img';
import NoTableData from 'assets/img/noData/no_staff_plan_guard_code.png';

import './NoData.scss';

const NoData = ({ setAddNewGuardCode, setActiveCreateForm }) => {
  return (
    <div className="no-table-data">
      <img src={NoTableData} alt="create new code" />
      <p>{t('create-new-plan-intro')}</p>
      <p>{t('create-code-before-plan')}</p>
      {/* <button onClick={() => setActiveCreateForm(true)} className="add-plan">
        <img src={PlusIcon} alt="plus icon" />
        {t('create-new-plan-intro')}
      </button> */}
      <button onClick={() => setAddNewGuardCode(true)} className="add-code">
        <img src={PlusBrownIcon} alt="upload icon" />
        {t('add-new-code')}
      </button>
    </div>
  );
};

export default NoData;
