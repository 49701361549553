import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { t } from 'i18next';
import { useSelector } from 'react-redux';

import { selectGeneratorColApi } from 'redux/generateTableApi/slice';

import {
  ColumnsIcon,
  CheckboxCheckIcon,
  CheckboxUncheckIcon,
  CheckboxDisabledIcon,
} from 'assets/img';
import { loadSession, saveSession } from 'helper/localStorage';

const CheckBoxSelect = ({ label, colId, checked, onToggle, onToggleTable, checkedCount }) => {
  const isIgnoredColumn = ['emp-lock', 'emp-copy', 'emp-send-schedule'].includes(colId);
  const isDisabled = false && checkedCount === 3 && checked && !isIgnoredColumn;
  return (
    <label onClick={() => (isDisabled ? {} : onToggle(label, colId, onToggleTable))}>
      <img
        style={{ width: 16, height: 16, cursor: 'pointer' }}
        src={isDisabled ? CheckboxDisabledIcon : checked ? CheckboxCheckIcon : CheckboxUncheckIcon}
      />
      {t(label)}
    </label>
  );
};

const EmpHeaderSetting = ({ planSelected }) => {
  const buttonRef = useRef();
  const [showSetting, setShowSetting] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  const {
    empInfo: { colApi: colApiEmpInfo },
  } = useSelector(selectGeneratorColApi);
  const filterEmpSession = loadSession('filter-emp-list');

  const initState = filterEmpSession || [
    {
      label: 'column-number',
      key: 'emp-id',
      checked: false,
    },
    {
      label: 'name',
      key: 'emp-name',
      checked: true,
    },
    {
      label: 'group',
      key: 'emp-group',
      checked: false,
    },
    {
      label: 'role',
      key: 'emp-role',
      checked: false,
    },
    {
      label: 'assign-role',
      key: 'emp-task',
      checked: false,
    },
    {
      label: 'job-percentage',
      key: 'emp-job-percentage',
      checked: true,
    },
    {
      label: 'allocated-hours',
      key: 'emp-hours',
      checked: true,
    },
    {
      label: 'deviation',
      key: 'emp-deviation',
      checked: true,
    },
    {
      label: 'lock-emp',
      key: 'emp-lock',
      checked: false,
    },
    {
      label: 'copy',
      key: 'emp-copy',
      checked: false,
    },
    {
      label: 'send-schedule',
      key: 'emp-send-schedule',
      checked: false,
    },
  ];
  const [colList, setColList] = useState(initState);

  useEffect(() => {
    //Handle old session of user's local
    if (loadSession('filter-emp-list')) {
      const isHaveDeviation = filterEmpSession.find((item) => item.key === 'emp-deviation');
      if (!isHaveDeviation) {
        setColList((prev) => [
          ...prev,
          {
            label: 'deviation',
            key: 'emp-deviation',
            checked: true,
          },
        ]);
      }
    }
  }, [filterEmpSession]);

  useEffect(() => {
    setColList(initState);
  }, [planSelected]);

  const checkedCount = colList.filter(
    (item) => item.checked && !['emp-lock', 'emp-copy', 'emp-send-schedule'].includes(item.key),
  ).length;

  const handleShowSetting = () => {
    const coord = buttonRef.current.getBoundingClientRect();
    setPosition({ top: coord.bottom, left: coord.left });
    setShowSetting(!showSetting);
  };

  const handleToggleCol = (label, colId) => {
    setColList((prevColList) =>
      prevColList.map((col) => {
        if (col.label === label) {
          colApiEmpInfo.setColumnVisible(colId, !col.checked);
          return { ...col, checked: !col.checked };
        }
        return col;
      }),
    );
  };

  useEffect(() => {
    saveSession('filter-emp-list', colList);
  }, [colList]);

  const modal = showSetting && (
    <div className="setting-modal">
      <div className="setting-modal__background" onClick={() => setShowSetting(false)} />

      <div className="setting-modal__form" style={{ top: position.top, left: 20 }}>
        <p>{t('display-col')}</p>
        {colList.map(({ label, checked, key }) => (
          <CheckBoxSelect
            key={key}
            label={label}
            colId={key}
            checked={checked}
            onToggle={handleToggleCol}
            checkedCount={checkedCount}
          />
        ))}
      </div>
    </div>
  );

  return (
    <>
      <button ref={buttonRef} onClick={handleShowSetting}>
        <img src={ColumnsIcon} />
        {t('column')}
      </button>

      {ReactDOM.createPortal(modal, document.body)}
    </>
  );
};

export default EmpHeaderSetting;
