import { t } from 'i18next';

export const validateSubmittedBulkEditData = (rowData) => {
    // Filter out empty rows
    const nonEmptyRows = rowData.filter(
      (row) =>
        row.name ||
        row.email ||
        row.phone ||
        row.job_percentage ||
        row.role ||
        row.group ||
        row.role_type,
    );
  
    // if no name, job_percentage, role prevent submit
    const missingRequireProps = nonEmptyRows.reduce((acc, row) => {
      if (!row.name) {
        acc.push(t('name'));
      }
      if (!row.job_percentage) {
        acc.push(t('jobPercentage'));
      }
      if (!row.role) {
        acc.push(t('role'));
      }
      if (!row.role_type) {
        acc.push(t('role-type'));
      }
      return acc;
    }, []);
  
    if (nonEmptyRows.length === 0) {
      return {
        isValid: false,
        message: t('please-fill-one-row'),
        data: nonEmptyRows,
      };
    }
  
    if (missingRequireProps.length > 0) {
      return {
        isValid: false,
        message: `${t('missing-field')}: ${missingRequireProps.join(', ')}`,
        data: nonEmptyRows,
      };
    }
  
    return {
      isValid: true,
      message: 'no-error-in-table',
      data: nonEmptyRows, // Return the non-empty rows
    };
  };