import React from 'react';
import { t } from 'i18next';

import './styles.scss';

const NoDataPlaceholder = ({ title, description, img, children }) => {
  return (
    <div className="no-data">
      <img src={img} alt="no data" />
      <p>{t(title)}</p>
      <p>{t(description)}</p>
      {children}
    </div>
  );
};

export default NoDataPlaceholder;
