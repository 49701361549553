import { CheckOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { CheckGreenIcon, DeleteIconBlack, EditIcon } from 'assets/img';
import axiosClient from 'axios/axiosClient';
import { Toast, confirmDelete, showLoading } from 'helper/alert';
import { t } from 'i18next';
import React, { useState } from 'react';

export const CalendarPlanGroupRow = ({ defaultGroup, refetchGroup }) => {
  const [group, setgroup] = useState(defaultGroup);
  const [isEditing, setIsEditing] = useState(false);

  const handleOnChange = (e) => {
    setgroup({ ...group, [e.target.name]: e.target.value });
  };
  const handleUpdate = (e) => {
    let isValid = true;
    for (const key of Object.keys(group)) {
      if (group[key]?.length == 0 && key !== 'description') {
        isValid = false;
      }
    }
    if (isValid) {
      axiosClient
        .post('calendar-plan/group/update', { group })
        .then(async (_) => {
          await refetchGroup();
          Toast({ icon: 'success', title: `${t('update-group')} ${t('success')}` });
          setIsEditing(false);
        })
        .catch((err) => {
          Toast({ icon: 'error', title: `${t('update-group')} ${t('unsuccess')}` });
        });
    } else {
      Toast({ icon: 'error', title: `${t('please-input-all')} ` });
    }
  };
  const handleDelete = (e) => {
    confirmDelete({}).then((result) => {
      if (result.isConfirmed) {
        showLoading({ shouldShow: true, title: 'deleting' });
        axiosClient
          .post('calendar-plan/group/delete', { group })
          .then(async (_) => {
            await refetchGroup();
            Toast({ icon: 'success', title: `${t('delete-group')} ${t('success')}` });
          })
          .catch((err) => {
            console.log(err);
            refetchGroup();
            Toast({ icon: 'error', title: `${t('delete-group')} ${t('unsuccess')}` });
          });
      }
    });
  };
  return (
    <tr className="calendar-plan-create-role__item">
      <td className="calendar-plan-create-role__item-label">
        <input
          name="name"
          disabled={!isEditing}
          type="text"
          value={group.name}
          onChange={handleOnChange}
          required
        />
      </td>
      <td className="calendar-plan-create-role__item-label">
        <input
          name="key"
          disabled={!isEditing}
          type="text"
          value={group.key}
          required
          onChange={handleOnChange}
        />
      </td>

      <td className="calendar-plan-create-role__item-description">
        <input
          name="description"
          disabled={!isEditing}
          type="text"
          value={group.description || t('no-description')}
          onChange={handleOnChange}
        />
      </td>
      <td className="calendar-plan-create-role__item-action">
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 8 }}>
          <div onClick={() => (isEditing ? handleUpdate() : setIsEditing(true))}>
            {!isEditing ? (
              <img src={EditIcon} alt="" width={30} />
            ) : (
              <img src={CheckGreenIcon} alt="" width={24} />
            )}
          </div>
          <div onClick={handleDelete}>
            <img src={DeleteIconBlack} alt="" width={16} />
          </div>
        </div>
      </td>
    </tr>
  );
};
