import React from 'react';
import './OptimizeProcessDashBoard.scss';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { selectCalendarPlan } from 'redux/calendarPlan/calendarPlanSlice';

import OptimizingPlan from 'assets/img/noData/optimizing.png';
import { useTurnusContext } from '../GeneratorContent/Turnus/TurnusContext';

const RenderProgress = ({ data }) => {
  const turnusData = useTurnusContext();

  const { planSelected } = turnusData;
  const dataFound = data.find((item) => +item.id === +planSelected.versionID);
  if (!dataFound) {
    return;
  }
  return (
    <div className="optimize-process-dashboard__item" key={`${dataFound.id}-${dataFound.planName}`}>
      <div className="optimize-process-dashboard__item-progress">
        <ProgressBar value={dataFound.process || 0} />{' '}
        <span className="percent">{dataFound.process || 0} %</span>
      </div>
      <div className="optimize-process-dashboard__item-status">
        {t(dataFound.statusKey)} <span className="loading">...</span>{' '}
      </div>
    </div>
  );
};

const OptimizeProcessDashBoard = ({ data }) => {
  return (
    <div className="optimize-process-dashboard__wrap">
      <img src={OptimizingPlan} alt="optimizing data" />
      <div className={`optimize-process-dashboard__content`}>
        <RenderProgress data={data} />
      </div>
    </div>
  );
};

export default OptimizeProcessDashBoard;
