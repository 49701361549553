import React from 'react';
import { t } from 'i18next';

import EmployeeTable from '_components/CarlendarPlan/EmployeeV2/components/SendSurveyModal/components/EmployeeTable';
import DatePickerCustom from 'components/DatePicker/DatePickerCustom';
import moment from 'moment';

const Employees = ({ unitSelected, selectedRows, setSelectedRows }) => {
  return (
    <div className="survey-employee-list">
      <div className="header">
        <p className="title">{t('select-recipients')}</p>
        <p>
          {Object.keys(selectedRows).length} {t('employee').toLowerCase()}
        </p>
      </div>

      <div className="emp-table">
        <EmployeeTable
          unitSelected={unitSelected}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </div>
  );
};

export default Employees;
