import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { t } from 'i18next';
import axiosClient from 'axios/axiosClient';
import { Toast } from 'helper/alert';

import { CloseIcon } from 'assets/img';

import UploadFileAndLink from './UploadFileAndLink';
import DatePickerCustom from 'components/DatePicker/DatePickerCustom';

import './UploadFileFormv2.scss';

const UploadFileFormv2 = ({ setActive, unitSelected, refetchPlanList, handleFileChange }) => {
  const [planInfo, setPlanInfo] = useState({});
  const [uploadFile, setUploadFile] = useState({});
  const isFileUploaded = Object.keys(uploadFile).length > 0;
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (isFileUploaded) {
      setPlanInfo((prevPlanInfo) => ({ ...prevPlanInfo, importType: 'file' }));
    } else {
      setPlanInfo((prevPlanInfo) => {
        const { importType, ...rest } = prevPlanInfo;
        return { ...rest };
      });
    }
  }, [isFileUploaded]);

  const handleChangeName = (e) => {
    setPlanInfo({ ...planInfo, planName: e.target.value });
  };

  const handleChangeDate = (value, key) => {
    const date = moment(value);
    const formattedDate = key === 'from' ? date.startOf('W') : date.endOf('W');
    setPlanInfo({
      ...planInfo,
      [key]: formattedDate.format('YYYY-MM-DD'),
    });
  };

  const handleGetIdSheet = (e) => {
    const { value, name } = e.target;
    const sheetIdReg = /\/d\/([a-zA-Z0-9_-]+)\//;
    if (value && value.match(sheetIdReg)) {
      setPlanInfo({
        ...planInfo,
        [name]: value.match(sheetIdReg)[1],
      });
    }
  };

  const isCommonInfoFilled = planInfo.planName && planInfo.from && planInfo.to;
  const isSubmitable = isCommonInfoFilled && (isFileUploaded || planInfo.googleSheetId);

  const handlSubmit = () => {
    if (isFileUploaded) {
      handleFileChange(uploadFile, planInfo, setIsUploading);
      return;
    }
    if (planInfo.googleSheetId) {
      axiosClient
        .post('/calendar-plan/kalender/upload-sheet-link', {
          planName: planInfo.planName,
          fileName: planInfo.planName,
          fromDate: planInfo.from,
          endDate: planInfo.to,
          googleSheetId: planInfo.googleSheetId,
          unitCode: unitSelected,
          fileType: planInfo.fileType,
        })
        .then((result) => {
          setActive(false);
          setIsUploading(false);
          refetchPlanList();
          Toast({ icon: 'success', title: `${t('submit')} ${t('success')}` });
          if (result.status === 400) {
            Toast({ icon: 'error', title: `${t('submit')} ${t('fail')}` });
          }
        })
        .catch((err) => {
          console.log(err);
          Toast({ icon: 'error', title: `${t('submit')} ${t('fail')}` });
          setIsUploading(false);
        });
    } else {
      Toast({ icon: 'error', title: `${t('submit')} ${t('fail')}` });
    }
  };

  return (
    <div className="modal-wrapper">
      <div className="modal-background" onClick={() => setActive(false)} />
      <div className="upload-form">
        <div className="upload-form__header">
          <p className="upload-form__header-title">{t('upload-plan')}</p>
          <div
            className="upload-form__header-icon"
            onClick={() => {
              if (!isUploading) {
                setActive(false);
              }
            }}
          >
            <img src={CloseIcon} />
          </div>
        </div>

        <div className="upload-form__form">
          <div className="plan-name">
            <label htmlFor="plan-name">{t('plan-name')}</label>
            <input type="text" placeholder={t('enter-plan-name')} onChange={handleChangeName} />
          </div>
          <div className="plan-date">
            <label>
              {t('from')}
              <DatePickerCustom
                date={planInfo.from}
                setDate={(e) => handleChangeDate(e, 'from')}
                className="upload-plan-input"
                placeholder="mm/dd/yyyy"
              />
            </label>
            <label>
              {t('to')}
              <DatePickerCustom
                date={planInfo.to}
                setDate={(e) => handleChangeDate(e, 'to')}
                className="upload-plan-input"
                placeholder="mm/dd/yyyy"
              />
            </label>
          </div>
        </div>

        <UploadFileAndLink
          uploadFile={uploadFile}
          setUploadFile={setUploadFile}
          isFileUploaded={isFileUploaded}
          handleGetIdSheet={handleGetIdSheet}
          planInfo={planInfo}
          setPlanInfo={setPlanInfo}
        />

        <div className="upload-form__action">
          <button disabled={!isSubmitable} onClick={handlSubmit}>
            {t('save')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadFileFormv2;
