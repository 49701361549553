import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: '',
  name: '',
  role: [],
  toggleHolidays: false,
  toggleWeekdays: false,
  assignedShift: { from: '', to: '' },
  jobPercentage: { from: '', to: '' },
  customDateRange: { from: '', to: '' },
  isFiltered: false,
};

export const generateTableFilterSlice = createSlice({
  name: 'generateTableFilter',
  initialState,
  reducers: {
    reset: () => initialState,
    updateFilter: (state, action) => {
      const { key, value, isNested, nestedKey } = action.payload;
      if (isNested) {
        state[nestedKey][key] = value;
      } else {
        state[key] = value;
      }
    },
    toggleFilter: (state, action) => {
      state.isFiltered = action.payload;
    },
  },
});

export const selectGeneratorTableFilter = (state) => state.generateTableFilter;
export const { reset, updateFilter, toggleFilter } = generateTableFilterSlice.actions;
export default generateTableFilterSlice.reducer;
