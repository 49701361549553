const { STATUS } = require('_components/Toast');

const typeResponse = (res) => {
  return res.status === 200 || res.status === undefined ? STATUS.success : STATUS.error;
};

const isSuccessResponse = (res) => {
  return res.status === 200 || res.status === undefined;
};

export { typeResponse, isSuccessResponse };
