import { CloseBlackIcon } from 'assets/img';
import React, { useEffect } from 'react';

import './style.scss';

const SubModalLayout = ({ children, handleClose }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'; // Prevent scrolling in the background
    return () => {
      document.body.style.overflow = 'auto'; // Re-enable scrolling when the component unmounts
    };
  }, []);

  return (
    <div className="sub-modal__wrapper">
      <div className="sub-modal__background" onClick={handleClose}></div>
      <div className="sub-modal__body">
        <div className="sub-modal-icon">
          <img src={CloseBlackIcon} onClick={handleClose} />
        </div>
        <div className="sub-modal__main-section">{children}</div>
      </div>
    </div>
  );
};

export default SubModalLayout;
