import DatePickerCustom from 'components/DatePicker/DatePickerCustom';
import { t } from 'i18next';
import moment from 'moment';
import React from 'react';

const Message = ({
  limitDate,
  setLimitDate,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  message,
  setMessage,
}) => {
  return (
    <div className="message__wrap">
      <div className="message__section limit-area">
        <p className="title">{t('dead-line-for-the-form')}</p>
        <div className="limit-date__section">
          <DatePickerCustom
            date={limitDate}
            setDate={(value) => {
              setLimitDate(moment(value).format('YYYY-MM-DD'));
            }}
          />
        </div>
        <p className="desc">{t('dead-line-for-the-form-desc')}</p>
      </div>
      <div className="message__section rotation-period">
        <p className="title">{t('rotation-period')}</p>
        <div className="limit-date__section">
          <div className="date-item">
            <label htmlFor="">{t('start')}</label>
            <DatePickerCustom
              date={startDate}
              setDate={(value) => {
                setStartDate(moment(value).format('YYYY-MM-DD'));
              }}
              maxDate={endDate}
              isRequired={true}
            />
          </div>
          <div className="date-item">
            <label htmlFor="">{t('end')}</label>
            <DatePickerCustom
              date={endDate}
              setDate={(value) => {
                setEndDate(moment(value).format('YYYY-MM-DD'));
              }}
              minDate={new Date(startDate)}
              isRequired={true}
            />
          </div>
        </div>
        <p className="desc">{t('rotation-period-desc')}</p>
      </div>

      <div className="message__section message">
        <p className="title">{t('message-to-employee')}</p>
        <textarea
          name=""
          id=""
          cols="30"
          rows="10"
          value={message}
          maxLength={1800}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        <p className="desc">{t('message-to-employee-desc')}</p>
      </div>
    </div>
  );
};

export default Message;
