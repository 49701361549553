import { useEffect, useRef } from 'react';

export const useClickOutside = (ref, callback, optionalRef) => {
  const handleClick = (e) => {
    if (
      ref.current &&
      !ref.current.contains(e.target) &&
      (!optionalRef?.current || !optionalRef.current.contains(e.target))
    ) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export const useClickOutsideV2 = (callback) => {
  const ref = useRef();
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
  return ref;
};
