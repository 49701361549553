import { t } from 'i18next';
import Swal from 'sweetalert2';
const colorType = {
  success: { background: '#EAF0E7', iconColor: '#2F7417' },
  error: { background: '#FCEEF0', iconColor: '#D12444' },
  warning: { background: '#FCF0D1', iconColor: '#F8B114' },
  info: { background: '#F0F2FD', iconColor: '#2F4CE0' },
};
export const LoadingToast = ({ icon = 'success', title }) => {
  const alertColor = colorType[icon];
  return Swal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    zIndex: 99999999,
    background: alertColor.background,
    iconColor: alertColor.iconColor,
    color: '#000',
    timerProgressBar: true,
    title: `${t(title)}...`,
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    },
  }).fire({ icon, title });
};
export const Toast = ({ icon = 'success', title }) => {
  const alertColor = colorType[icon];
  return Swal.mixin({
    toast: true,
    position: 'bottom',
    showConfirmButton: false,
    timer: 5000,
    zIndex: 99999999,
    background: alertColor.background,
    iconColor: alertColor.iconColor,
    color: '#000',
    timerProgressBar: true,
    willOpen: () => {
      Swal.showLoading();
    },

    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
      Swal.hideLoading();
    },
  }).fire({ icon, title });
};

export const confirmDelete = async ({ title = 'are-you-sure', btnText = 'yes-delete-it' }) => {
  return Swal.fire({
    title: t(title),
    text: t('you-wont-be-able-to-revert-this'),
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#4E1830',
    cancelButtonColor: '#d33',
    confirmButtonText: t(btnText),
  });
};

export const confirmUpdate = async ({
  title = 'are-you-sure',
  text = '',
  btnText = 'yes-delete-it',
}) => {
  return Swal.fire({
    title: t(title),
    text: t(text),
    icon: 'warning',
    showCancelButton: true,

    confirmButtonColor: '#4E1830',
    confirmButtonText: t(btnText),
  });
};

export const confirmImport = async ({
  title = 'are-you-sure',
  btnText = 'yes-import-it',
  text = '',
}) => {
  return Swal.fire({
    title: t(title),
    text: t(text),
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: t(btnText),
  });
};

export const showLoading = ({ shouldShow = true, title = 'deleting' }) => {
  if (shouldShow) {
    return Swal.fire({
      title: `${t(title)}...`,
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  } else {
    Swal.close();
  }
};
