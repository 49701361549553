import React, { useRef, useState, useEffect, useMemo } from 'react';
import axiosClient from 'axios/axiosClient';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { clearSession, loadSession, saveSession } from 'helper/localStorage';

import { ArrowDownIcon, BrownArrowDownIcon } from 'assets/img';

import {
  updateGeneratorDashboardAction,
  updatePlanSelectedAction,
  updatePlanDetailLockedAction,
} from 'redux/calendarPlan/calendarPlanAction';
import { updateUnitSelected, syncDepartmentSelected, selectGlobal } from 'redux/global/globalSlice';

import './styles.scss';
import { t } from 'i18next';
import { updateStatusOfStaffPlanAction } from 'redux/global/globalAction';

const DepartmentSelection = () => {
  const unitSelectedFromSessionStorage = loadSession('unitSelected');
  const locale = useLocation();
  const dispatch = useDispatch();
  const titleRef = useRef(null);
  const selectRef = useRef(null);
  const [departments, setDepartments] = useState([]);
  const [unitSelected, setUnitSelected] = useState({
    unitCode: '',
    unitName: '',
  });
  const [departmentSelected, setDepartmentSelected] = useState({
    departmentCode: '',
    departmentName: '',
  });

  const [toggleDropdown, setToggleDropdown] = useState(false);
  useEffect(() => {
    axiosClient.post('calendar-plan/department').then((result) => {
      if (result && result.length) {
        setDepartments(result);
        if (!unitSelectedFromSessionStorage) {
          setUnitSelected({
            unitCode: result[0].unit[0].unitCode,
            unitName: result[0].unit[0].unitName,
          });
          setDepartmentSelected({
            departmentCode: result[0].departmentCode,
            departmentName: result[0].departmentName,
          });
          dispatch(updateUnitSelected(result[0].unit[0].unitCode));
        } else {
          const selectedDepartment = result.find((department) =>
            department.unit.find((item) => item.unitCode === unitSelectedFromSessionStorage),
          );
          setUnitSelected({
            unitCode: unitSelectedFromSessionStorage,
            unitName: selectedDepartment?.unit.find(
              (item) => item.unitCode === unitSelectedFromSessionStorage,
            )?.unitName,
          });
          setDepartmentSelected({
            departmentCode: selectedDepartment?.departmentCode,
            departmentName: selectedDepartment?.departmentName,
          });
          dispatch(updateUnitSelected(unitSelectedFromSessionStorage));
        }
      }
    });

    dispatch(
      updateGeneratorDashboardAction({
        employAssigneData: [],
        employSurveyCheckList: [],
        employeeUnAssigneData: {},
        maxDate: '',
      }),
    );
  }, [locale.key]);

  useEffect(() => {
    dispatch(
      syncDepartmentSelected({
        departmentCode: departmentSelected.departmentCode,
        unitCode: unitSelected.unitCode,
      }),
    );
  }, [departmentSelected, unitSelected]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target) &&
        !titleRef.current.contains(event.target)
      ) {
        setToggleDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleToggleSelection = (e) => {
    e.stopPropagation();
    setToggleDropdown(!toggleDropdown);
  };

  const handleSelectDepartment = (departmentCode, departmentName) => {
    setDepartmentSelected({ departmentCode, departmentName });
    saveSession('isMainContent', true);

    const { unitCode, unitName } = departments.find(
      (item) => item.departmentCode === departmentCode,
    ).unit[0];
    dispatch(updateUnitSelected(unitCode));
    setUnitSelected({ unitCode, unitName });
    dispatch(updatePlanSelectedAction({}));
    dispatch(updateStatusOfStaffPlanAction({ isMainContentStaffPlan: true }));
    dispatch(
      updateGeneratorDashboardAction({
        employAssigneData: [],
        employSurveyCheckList: [],
        employeeUnAssigneData: {},
        maxDate: '',
      }),
    );
    dispatch(
      updatePlanDetailLockedAction({
        planDetailLockedList: [],
        employeeIdLockedList: [],
      }),
    );
    clearSession('generator-search-value');
  };

  const handleSelectUnit = (unitCode, unitName) => {
    clearSession('generator-search-value');
    saveSession('isMainContent', true);
    clearSession('currentNavTab');
    setUnitSelected({ unitCode: unitCode, unitName });
    dispatch(updateUnitSelected(unitCode));
    dispatch(updatePlanSelectedAction({}));
    dispatch(updateStatusOfStaffPlanAction({ isMainContentStaffPlan: true }));
    dispatch(
      updateGeneratorDashboardAction({
        employAssigneData: [],
        employSurveyCheckList: [],
        employeeUnAssigneData: {},
        maxDate: '',
      }),
    );
    dispatch(
      updatePlanDetailLockedAction({
        planDetailLockedList: [],
        employeeIdLockedList: [],
      }),
    );
  };
  const DEFAULT_DEPARTMENT_NAME = 'Chiron';
  const { ignoreFilterPath, ignoreDepartmentHeaderSelection } = useSelector(selectGlobal);

  const isSelectable = ignoreFilterPath.includes(locale.pathname);
  const isRender = !ignoreDepartmentHeaderSelection.includes(locale.pathname);

  const departmentName = useMemo(() => {
    return departmentSelected.departmentName && isSelectable
      ? departmentSelected.departmentName
      : DEFAULT_DEPARTMENT_NAME;
  }, [departmentSelected.departmentName, locale.pathname]);

  const getUnitName = () => {
    if (!unitSelected || !unitSelected.unitName) return '';
    const unitReg = /unit/;
    if (unitReg.test(unitSelected.unitName.toLocaleLowerCase())) {
      const unitName = unitSelected.unitName.split(' ');
      return t('unit-header') + ' ' + unitName[1];
    } else {
      return unitSelected.unitName;
    }
  };
  return (
    <div className="department-selection">
      <div
        ref={titleRef}
        className="department-selection-title__wrap"
        onClick={isSelectable && isRender ? handleToggleSelection : null}
      >
        <p className="department-selection-title">
          {!isRender ? DEFAULT_DEPARTMENT_NAME : departmentName}
          {isSelectable && isRender && <img src={BrownArrowDownIcon} />}
        </p>
        <p className="unit-selection-title">{isRender && unitSelected?.unitName}</p>
      </div>

      <div
        ref={selectRef}
        className={`department-selection-select ${toggleDropdown ? 'show' : ''}`}
      >
        {departments.map(({ departmentCode, departmentName, unit }) => (
          <div className="item" key={departmentCode}>
            <div
              className="department-select"
              onClick={() => handleSelectDepartment(departmentCode, departmentName)}
            >
              <input
                checked={departmentSelected.departmentCode === departmentCode}
                type="radio"
                id={`radio-${departmentCode}`}
                name="department"
                readOnly={true}
              />
              <label htmlFor={`radio-${departmentCode}`}>{departmentName}</label>
              {unit.length > 0 && <img src={ArrowDownIcon} />}
            </div>

            {departmentSelected.departmentCode === departmentCode && (
              <div className="sub-select">
                {unit.map(({ unitCode, unitName }) => (
                  <div
                    key={unitCode}
                    className="unit-select"
                    onClick={() => handleSelectUnit(unitCode, unitName)}
                  >
                    <input
                      checked={unitSelected.unitCode === unitCode}
                      type="radio"
                      id={`radio-${unitCode}`}
                      name="unit"
                      readOnly={true}
                    />
                    <label htmlFor={`radio-${unitCode}`}>{unitName}</label>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DepartmentSelection;
