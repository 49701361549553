import React from 'react';
import { Popover as PopoverAnt } from 'antd';

import './styles.scss';

const Popover = ({ content, title, children, ...props }) => {
  return (
    <PopoverAnt content={content} title={title} trigger="click" {...props}>
      {children}
    </PopoverAnt>
  );
};

export default Popover;
