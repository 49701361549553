import React from 'react';

import { CloseCircleOutlined } from '@ant-design/icons';
import { selectAuth } from 'redux/auth/authSlice';
import { useSelector } from 'react-redux';
import { CloseTagIcon } from 'assets/img';
import { getRowIDStaffPlanTable } from '../helper';

const DeleteCell = ({ tableData, setTableData, rowData, setDeletedList }) => {
  const { isEditabled } = useSelector(selectAuth);

  const layerID = rowData?.layerID;

  const handleRemoveTask = () => {
    if (!isEditabled) return;
    const newTableData = tableData.filter((item) => {
      if (rowData.planDetailID) {
        return rowData.planDetailID !== item.planDetailID;
      } else {
        return getRowIDStaffPlanTable(rowData) !== getRowIDStaffPlanTable(item);
      }
    });
    setTableData(newTableData);
    setDeletedList((prev) => [...prev, ...rowData.taskList.map((item) => item.planDetailID)]);
  };

  return (
    <div className="delete-cell" onClick={handleRemoveTask}>
      <CloseCircleOutlined />
    </div>
  );
};

export default DeleteCell;
