/** libs */
import React from 'react';
import { Typography } from 'antd';

/** styles */
import './styles.scss';

const { Text: TextAnt } = Typography;

const Text = ({ children, ...props }) => {
  return (
    <TextAnt {...props} className="text-init">
      {children}
    </TextAnt>
  );
};

export default Text;
