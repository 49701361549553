import { CloseOutlined } from '@ant-design/icons';
import DatePickerCustom from 'components/DatePicker/DatePickerCustom';
import { LIMIT_YEAR_IN_SURVEY } from 'constant';
import { t } from 'i18next';
import moment from 'moment';
import React, { useMemo } from 'react';

const RangeDate = ({
  questionId,
  surveyRecord,
  setSurveyRecord,
  isManager,
  isDisabled,
  limitSurveyStart,
  limitSurveyEnd,
}) => {
  const survey = { ...surveyRecord };

  if (!survey[questionId] || !survey[questionId].length) {
    survey[questionId] = [{ fromDate: '', toDate: '', type: 'date' }];
  } else if (typeof survey[questionId] === 'string') {
    survey[questionId] = JSON.parse(survey[questionId]);
  }

  const handleRemove = (ind) => {
    const arr = survey[questionId];
    const newArr = arr.filter((_, index) => index !== ind);
    setSurveyRecord((prev) => ({
      ...prev,
      [questionId]: newArr,
    }));
  };

  return survey[questionId].length > 0
    ? survey[questionId].map((range, index) => {
        return (
          <div
            key={`${questionId}-date-survey-${index}`}
            className="calendar-plan-survey-form__date__wrap"
          >
            {!isManager && (
              <div className="calendar-plan-survey-form__date__index">
                {t('range')} {index + 1}:
              </div>
            )}

            <div className="calendar-plan-survey-form__date__group">
              <div className="calendar-plan-survey-form__date__item">
                <label htmlFor="">{t('from')}</label>

                <DatePickerCustom
                  isMaxWidth
                  showWeekNumbers={true}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  disabled={isDisabled}
                  minDate={new Date(limitSurveyStart)}
                  maxDate={new Date(limitSurveyEnd)}
                  date={
                    survey[questionId]
                      ? moment(survey[questionId][index]?.fromDate).format('YYYY-MM-DD')
                      : ''
                  }
                  setDate={(value) => {
                    survey[questionId][index] = {
                      ...survey[questionId][index],
                      fromDate: moment(new Date(value)).format('YYYY-MM-DD'),
                      type: 'date',
                    };
                    setSurveyRecord(survey);
                  }}
                  placeholder={'dd/mm/yyyy'}
                  limitYear={LIMIT_YEAR_IN_SURVEY}
                  // maxDate={survey[questionId] ? survey[questionId][index]?.toDate : new Date()}
                />
              </div>
              <div className="calendar-plan-survey-form__date__item">
                <label htmlFor="">{t('to')}</label>

                <DatePickerCustom
                  isMaxWidth
                  showWeekNumbers={true}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  disabled={isDisabled}
                  date={
                    survey[questionId]
                      ? moment(survey[questionId][index]?.toDate).format('YYYY-MM-DD')
                      : ''
                  }
                  setDate={(value) => {
                    survey[questionId][index] = {
                      ...survey[questionId][index],
                      toDate: moment(new Date(value)).format('YYYY-MM-DD'),
                      type: 'date',
                    };
                    setSurveyRecord(survey);
                  }}
                  placeholder={'dd/mm/yyyy'}
                  minDate={new Date(limitSurveyStart)}
                  maxDate={new Date(limitSurveyEnd)}
                  limitYear={LIMIT_YEAR_IN_SURVEY}
                  // minDate={survey[questionId] ? survey[questionId][index]?.fromDate : new Date()}
                />
              </div>

              <div className="calendar-plan-survey-form__date-close">
                {survey[questionId].length > 1 && !isDisabled && (
                  <>
                    <div
                      style={{ height: 15 }}
                      className="calendar-plan-survey-form__date-close-placeholder"
                    />
                    <CloseOutlined
                      onClick={() => {
                        handleRemove(index);
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        );
      })
    : null;
};

export default RangeDate;
