import { useTaskList, useVakantDetail } from 'Hooks/useData';
import React, { useEffect, useMemo, useState } from 'react';
import './VakantReport.scss';
import { t } from 'i18next';
import { v4 as uuidv4 } from 'uuid';
import { CloseIcon } from 'assets/img';
import moment from 'moment';
import { offShiftReg, weekdayKeyStartSun } from 'constant';
const TableHeader = () => {
  const headerList = [
    {
      headerName: 'no',
    },
    {
      headerName: 'customDateRange',
    },
    {
      headerName: 'weekday',
    },
    {
      headerName: 'shift',
    },
    {
      headerName: 'role',
    },
    // {
    //   headerName: 'reason',
    // },
  ];
  return headerList.map((header) => {
    return <th key={uuidv4()}>{t(header.headerName)}</th>;
  });
};

const VakantReport = ({ vakantInfo, unitSelected, setActiveReport }) => {
  const reportData = useMemo(() => {
    const vakantTaskArr = vakantInfo.task_arr;
    const data = Object.keys(vakantTaskArr).map((date) => {
      const taskData = vakantTaskArr[date][0];
      if (taskData) {
        return { date, taskName: taskData.taskName, roleList: taskData.roleList };
      }
    });

    return data.sort((a, b) => {
      const momentA = moment(a.date);
      const momentB = moment(b.date);

      if (!momentA.isValid() || !momentB.isValid()) {
        console.error('Invalid date:', momentA.isValid() ? momentB : momentA);
        return 0; // Handle invalid dates as needed (e.g., return 0 or throw an error)
      }

      return momentA.diff(momentB);
    });
  }, [vakantInfo]);
  return (
    <div className="vakant-report__wrap modal-background ">
      <div className="vakant-report__container">
        <div className="close" onClick={() => setActiveReport(false)}>
          <img src={CloseIcon} />
        </div>

        <div className="vakant-report__title">
          <div className="vakant-report__title-item">
            <span className="title">{t('un-assigned')} ID:</span>{' '}
            <span>{vakantInfo.employeeId || vakantInfo.id}</span>
          </div>
        </div>
        <div className="vakant-report__detail">
          <span className="title">
            {t('total')} : {reportData.length}
          </span>
        </div>
        <div className="table__wrap">
          <table>
            <thead>
              <TableHeader />
            </thead>
            <tbody>
              {reportData.map((report, index) => {
                const week = moment(report.date).isoWeek();
                const year = moment(report.date).year();
                let weekday = moment(report.date).weekday();
                const roleName = report.roleList.map((role) => {
                  return role.roleInformation.role;
                });
                const str = `${t('week')} ${week} - ${year} | ${t(weekdayKeyStartSun[weekday])}`;
                return (
                  <tr key={uuidv4()}>
                    <td>{index + 1}</td>
                    <td>{report.date}</td>
                    <td>{str}</td>
                    <td>{report.taskName}</td>
                    <td>{roleName}</td>
                    {/* <td>{t(report.reasonKey)}</td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default VakantReport;
