import React from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import moment from 'moment';
import { t } from 'i18next';

import axiosClient from 'axios/axiosClient';
import { TriangleDownIcon } from 'assets/img';

import { selectGlobal } from 'redux/global/globalSlice';
import { dateFormat } from 'constant';

const HolidaySelection = ({
  setSurveyRecord,
  survey,
  questionId,
  index,
  date,
  setDate,
  isDisabled,
  holidayList,
  limitSurveyStart,
  limitSurveyEnd,
}) => {
  const { language } = useSelector(selectGlobal);
  const isNo = language === 'NO';

  const handleSelectChange = (event) => {
    const { value } = event.target;
    survey[questionId].dateArr[index] = value ? new Date(value).toISOString() : '';

    if (new Date(value).getFullYear() > 1970) {
      setSurveyRecord(survey);
    }

    setDate(value);
  };
  return (
    <select
      className="holiday-selection"
      value={date}
      onChange={handleSelectChange}
      disabled={isDisabled}
      style={{
        backgroundImage: `url(${TriangleDownIcon})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right 18px center',
        backgroundSize: '14px 14px',
        textAlign: 'left',
        padding: '10px',
        boxSizing: 'border-box',
        width: '100%',
      }}
    >
      <option value="">{t('select-holiday')}</option>
      {holidayList
        ?.filter((item) => {
          if (limitSurveyStart && limitSurveyEnd) {
            const momentLimitSurveyStart = moment(limitSurveyStart);
            const momentLimitSurveyEnd = moment(limitSurveyEnd);

            return (
              moment(item.date).isSameOrAfter(momentLimitSurveyStart) &&
              moment(item.date).isSameOrBefore(momentLimitSurveyEnd)
            );
          } else {
            return true;
          }
        })
        .map(({ date, localName, name }, index) => (
          <option key={index} value={date}>
            {isNo ? localName : name} - {moment(date).format(dateFormat)}
          </option>
        ))}
    </select>
  );
};

export default HolidaySelection;
