import React from 'react';

const GroupTooltip = (params) => {
  const { data } = params;
  if (!data || !data.listGroup.length) return;
  return (
    <div className="group-tooltip__wrap">
      {data.listGroup.map((item) => {
        return (
          <div className="group-tooltip__item" key={`emp-group-tooltip ${item.groupID}`}>
            {item.groupName}
          </div>
        );
      })}
    </div>
  );
};

export default GroupTooltip;
