import _ from 'lodash';
import { t } from 'i18next';
import axiosClient from 'axios/axiosClient';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';

import ButtonList from './ButtonList/ButtonList';
import CalendarPlanRole from './CalendarPlanRole/CalendarPlanRole';
import CalendarPlanGroup from './CalendarPlanGroup/CalendarPlanGroup';
import UploadEmployeeForm from './UploadEmployeeForm/UploadEmployeeForm';
import EmployeeTablev2 from 'components/CalendarPlan/CalendarPlanEmployee/EmployeeTablev2';

import { useEmployees, useEmployeesWithPosition, useGroups, useRoles } from 'Hooks/useData';

import SendSurveyForm from './CalendarPlanSendSurveyForm';
import CreateEmpModal from './CreateEmpModal/CreateEmpModal';

import { selectModal } from '_components/Modal/slice';
import {
  ADD_GROUP,
  ADD_NEW_EMPLOYEE,
  ADD_ROLE,
} from '_components/CarlendarPlan/EmployeeV2/constants/modal';
import PopUp from 'components/Common/PopUp/PopUp';
import { Toast, showLoading } from 'helper/alert';
import { updateApi } from 'redux/generateTableApi/slice';

import './CalendarPlanEmployee.scss';
import { selectGlobal } from 'redux/global/globalSlice';
import { createDownloadXlsx } from './DownloadEmployeeWishes/CreateDownloadXlsx';
import { dateFormat } from 'constant';
import moment from 'moment';

const surveyEmpWishesApi = async (payload) => await axiosClient.get(`calendar-plan/employee/export/${payload.unitCode}`, { responseType: 'blob' });

export default function CalendarPlanEmployeev2({ unitSelected }) {
  const { globalSortEmployee } = useSelector(selectGlobal);
  const modal = useSelector(selectModal);
  const [isSendSurvey, setIsSendSurvey] = useState(false);
  const [isUploadingEmployee, setIsUploadingEmployee] = useState(false);
  const [topicList, setTopicList] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [employees, setEmployees] = useState([]);

  const [resetEmpWishes, setResetEmpWishes] = useState(false);

  const { data: employeeFetch, isFetched: isEmpFetched } = useEmployees({ unitSelected });

  const { data: employeeWithPositionFetch, refetch: refetchEmployeeWithPositionFetch } =
    useEmployeesWithPosition({ unitSelected });
  const { data: roleList, refetch: refetchRole } = useRoles();
  const { data: groupList, refetch: refetchGroup } = useGroups({ unitSelected });

  // employee table
  const dispatch = useDispatch();
  const empTableRef = useRef();
  const onEmpTableGridReady = (params) => {
    empTableRef.current = params.api;

    dispatch(updateApi({ key: 'empTable', value: { api: params.api, colApi: params.columnApi } }));
    if (globalSortEmployee) {
      params.columnApi.applyColumnState(globalSortEmployee);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!unitSelected) return;
      try {
        const [surveyTopicResponse] = await Promise.all([
          axiosClient.post('calendar-plan/survey-topic'),
        ]);

        if (surveyTopicResponse) {
          setTopicList(surveyTopicResponse);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [unitSelected, isUpdate, employeeFetch]);

  useEffect(() => {
    let data = employeeFetch;
    const splitedData = [];
    data
      ?.filter((item) => item.listGroupSplit?.length > 0)
      ?.forEach((employee) => {
        return employee.listGroupSplit.forEach((group) => {
          splitedData.push({
            ...employee,
            source_group: employee.group_id,
            source_job_percentage: employee.job_percentage,
            group: group.groupName,
            group_id: group.groupID,
            isSplited: true,
            job_percentage: group.jobPercentage,
            splitID: group.splitID,
          });
        });
      });

    if (employeeFetch) {
      const result = [...employeeFetch, ...splitedData].sort((a, b) => a.id - b.id);
      setEmployees(result);
    }
  }, [employeeFetch, unitSelected]);
  const handleResetEmpWishes = async () => {
    showLoading({ title: 'reset' });
    await axiosClient
      .delete(`/calendar-plan/employee/reset-wishes/${unitSelected}`)
      .then(async () => {
        await refetchEmployeeWithPositionFetch();
        showLoading({ shouldShow: false });
        setResetEmpWishes(false);
        Toast({
          icon: 'success',
          title: t('reset-wishes-success'),
        });
      });
  };

  useEffect(() => {
    refetchEmployeeWithPositionFetch();
  }, [unitSelected]);

  if (!isEmpFetched) {
    return;
  }

  const handleExportSurveyEmpWishes = async () => {
    const response = await surveyEmpWishesApi({unitCode :unitSelected})
    if (response) {
      createDownloadXlsx(response, `employee-survey-wishes_${moment().format(dateFormat)}.xlsx`) 
    }
  }

  return (
    <div className="calendar-plan-employee">
      <div className="employee-content">
        <ButtonList handleExportSurveyEmpWishes={handleExportSurveyEmpWishes} setIsSendSurvey={setIsSendSurvey} setResetEmpWishes={setResetEmpWishes} />
      </div>

      <EmployeeTablev2
        ref={empTableRef}
        refetchEmployeeWithPositionFetch={refetchEmployeeWithPositionFetch}
        data={employeeWithPositionFetch}
        onEmpGridReady={onEmpTableGridReady}
        unitSelected={unitSelected}
      />

      {modal.name === ADD_NEW_EMPLOYEE ? (
        <CreateEmpModal
          refetchEmployees={refetchEmployeeWithPositionFetch}
          unitSelected={unitSelected}
          roleList={roleList}
          groupList={groupList.data}
          employees={employees}
        />
      ) : null}

      {isSendSurvey && (
        <SendSurveyForm
          unitSelected={unitSelected}
          setIsSendSurvey={setIsSendSurvey}
          topicList={topicList}
        />
      )}

      {modal.name === ADD_ROLE ? (
        <CalendarPlanRole refetchEmployees={refetchEmployeeWithPositionFetch} />
      ) : null}
      {modal.name === ADD_GROUP ? (
        <CalendarPlanGroup
          groupList={groupList.data}
          refetchGroup={refetchGroup}
          isUpdate={isUpdate}
          setIsUpdate={setIsUpdate}
          unitSelected={unitSelected}
        />
      ) : null}

      {isUploadingEmployee ? (
        <UploadEmployeeForm
          setActive={setIsUploadingEmployee}
          unitCode={unitSelected}
          isUpdate={isUpdate}
          setIsUpdate={setIsUpdate}
          refetchEmployees={refetchEmployeeWithPositionFetch}
        />
      ) : null}
      <PopUp
        isActive={resetEmpWishes}
        setIsActive={setResetEmpWishes}
        submitBtnText="reset"
        submitCallback={handleResetEmpWishes}
      >
        <p className="popup-title">{t('reset-wishes')} ?</p>
        <p className="popup-desc">{t('reset-wishes-desc')}</p>
      </PopUp>
    </div>
  );
}
