import { t } from 'i18next';
import React, { useMemo } from 'react';
const UnwantedShiftCell = ({ taskList, label, handleChangeTask, rowData = {}, isEditing }) => {
  const taskSelected = useMemo(() => {
    return taskList.find((item) => +item.taskID === +rowData.shiftList[label]);
  }, [rowData, taskList]);
  return (
    <div className="unwanted-shift-cell__wrap">
      <div className={`unwanted-shift-cell ${!isEditing ? 'disabled' : ''}`}>
        <span className="task-name">{taskSelected?.taskName}</span>
      </div>
      {isEditing ? (
        <div className="unwanted-shift-list">
          <div
            className="unwanted-shift-list__item"
            onClick={() => {
              handleChangeTask({ label, taskWillSelect: { taskID: null } });
            }}
          >
            <span className="task-name">{t('unselected')}</span>
          </div>
          {taskList
            .filter((task) => task.taskTypeValue < 4)
            .map((task) => {
              return (
                <div
                  key={task.taskUUID}
                  className="unwanted-shift-list__item"
                  onClick={() => {
                    handleChangeTask({ label, taskWillSelect: task });
                  }}
                >
                  <span className="task-name">
                    {task.taskName} ({task.fromTime} - {task.endTime})
                  </span>
                </div>
              );
            })}
        </div>
      ) : null}
    </div>
  );
};

export default UnwantedShiftCell;
