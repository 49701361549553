import React, { memo, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import routes from 'navigators/routes';
import { useSelector } from 'react-redux';
import Loading from 'components/Loading/Loading';
import { selectAuth } from 'redux/auth/authSlice';

function Content() {
  const page = process.env.REACT_APP_SITE;
  const { role } = useSelector(selectAuth);
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        {routes.map(({ key, path, component, roles, pages }) => {
          if (pages?.includes(page) && roles.includes(role.toLowerCase())) {
            return <Route key={key} path={path} element={component} />;
          }
        })}
        <Route path="*" element={<Navigate replace to="/calendar" />} />
      </Routes>
    </Suspense>
  );
}

export default memo(Content);

Content.whyDidYouRender = true;
