import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { t } from 'i18next';

import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import CopyButton from '../EmployeeGroupAction/CopyButton';
import LockedButton from '../EmployeeGroupAction/LockedButton';
import ShowCalenderButton from '../EmployeeGroupAction/ShowCalenderButton';
import SendScheduleButton from '../EmployeeGroupAction/SendScheduleButton';
import EmployeeNameCell from './EmployeeNameCell/EmployeeNameCell';
import { EMP_NAME_TABLE_COLOR, MAXIMUM_WEEK_WORKING_HOUR, SORTABLE_COLUMN_ID } from 'constant';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobal } from 'redux/global/globalSlice';
import { loadSession } from 'helper/localStorage';
import { openModal } from '_components/Modal/slice';
import { SIDE_MENU } from '_components/CarlendarPlan/EmployeeV2/constants/modal';
import { useTurnusContext } from '../../Turnus/TurnusContext';
import { selectGeneratorColApi, updateApi } from 'redux/generateTableApi/slice';
import { updateGlobalSortEmployeeAction } from 'redux/global/globalAction';
import { selectAuth } from 'redux/auth/authSlice';
import { useWorkingEnvSetup } from 'Hooks/useWorkingEnvData';
import CleanVakantButton from '../EmployeeGroupAction/CleanVakant/CleanVakantButton';

const GroupTooltip = (params) => {
  const { data } = params;
  if (!data || !data.employeePosition) return;
  const employeeGroups = data.employeePosition.employeeGroups;
  if (!employeeGroups.length) return;
  return (
    <div className="group-tooltip__wrap">
      {employeeGroups.map((item) => {
        const groupInformation = item.groupInformation;
        return (
          <div
            className="group-tooltip__item"
            key={`emp-group-tooltip-emp-info ${groupInformation.id}`}
          >
            {groupInformation.name}
          </div>
        );
      })}
    </div>
  );
};
const EmpInfo = ({
  onFirstDataRendered,
  setActiveReport,
  setVakantInfo,
  planDetailLocked,
  doesExternalFilterPass,
  getRowId,
  isExternalFilterPresent,
  postSortRows,
}) => {
  const dispatch = useDispatch();
  const turnusData = useTurnusContext();
  const empRef = useRef(null);
  const {
    employeeData: data,
    weekRange,
    dateRange,

    setGridColumnApiEmployeeInfo,
    globalSortEmployee,
    unitSelected,
    planSelected,
    publicHoliday,
    gridColumnApiTaskList,
    gridColumnApiDashboard,
    gridColumnApiHrPerWeek,
    gridColumnApiEmpInfo,
    setSortType,
    setSortCol,
    versionDateRange,
    setEmployeeData,
    workingEnvSetup,
  } = turnusData;
  const colApi = useSelector(selectGeneratorColApi);
  const { empInfo, taskList, dashboard } = colApi;
  const totalWeek = weekRange?.length;
  const [listEmp, setListEmp] = useState([]);
  const employeeList = data.filter((item) => {
    return item.name && item.name.trim() !== '';
  });
  const { editedEmp } = useSelector(selectGlobal);
  const searchTextFromSession = loadSession('generator-search-value') || '';
  const { isEditabled } = useSelector(selectAuth);
  const rowData = useMemo(
    () =>
      data
        ?.filter(
          (item) =>
            (item.name && item.name.toLowerCase().includes(searchTextFromSession)) ||
            item.name === undefined,
        )
        .map((item, index) => ({ ...item, id: index + 1 })) || [],
    [data, searchTextFromSession],
  );

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
      editable: false,
      filter: false,
      menuTabs: [],
      headerClass: 'cell-dark-header',
      sortingOrder: ['asc', 'desc'],
    }),
    [],
  );

  const empSession = loadSession('filter-emp-list') || [];

  const formatEmpSession = empSession.reduce(
    (acc, cur) => ({ ...acc, [cur.key]: !cur.checked }),
    {},
  );

  const columnDefs = useMemo(
    //TODO REMBER EDIT hiddenCOlDef in tableContent too
    () => [
      {
        headerName: t('emp-info'),
        headerClass: 'dark-header',
        resizable: true,
        children: [
          {
            // field: 'id',
            headerName: '#',
            colId: 'emp-id',
            width: 40,
            hide: !!formatEmpSession['emp-id'],
            sortable: false,
            resizable: true,

            valueGetter: (params) => {
              const indexInList = rowData.findIndex((emp) => +emp.employeeId === +params.node.id);
              return indexInList > -1 ? indexInList + 1 : '';
            },
            cellClass: 'id-cell',
            headerClass: 'id-header',
          },
          {
            field: 'name',
            headerName: t('emp-name'),
            colId: 'emp-name',
            width: 140,
            resizable: true,

            cellStyle: () => {
              return {
                color: EMP_NAME_TABLE_COLOR,
                fontWeight: 700,
                cursor: 'pointer',
              };
            },
            cellRenderer: ({ data }) => {
              if (!data) return;

              return <EmployeeNameCell data={data} />;
            },
            onCellClicked: (params) => {
              const { data, rowIndex } = params;
              if (!data.name) {
                setActiveReport(true);
                setVakantInfo(data);
              } else {
                dispatch(
                  openModal({
                    name: SIDE_MENU,
                    data: {
                      ...data,
                      isCalendarPlan: true,
                      rowIndex,
                    },
                  }),
                );
              }
            },
          },
          {
            field: 'groupName',
            headerName: t('group'),
            colId: 'emp-group',
            width: 120,
            resizable: true,
            sortable: true,
            hide: !!formatEmpSession['emp-group'],
            valueGetter: ({ data }) => {
              if (!data || !data.employeePosition) return;
              const employeeGroups = data.employeePosition.employeeGroups;
              const listGroupName = employeeGroups
                .map((item) => item.groupInformation.name)
                .join(',');
              return employeeGroups.length ? listGroupName : '-';
            },
            tooltipValueGetter: ({ data }) => {
              if (!data || !data.employeePosition) return;
              const employeeGroups = data.employeePosition.employeeGroups;
              return employeeGroups;
            },
            tooltipComponent: GroupTooltip,
          },
          {
            headerComponent: 'lockedButton',
            colId: 'emp-lock',
            headerClass: 'action-btn',
            resizable: false,
            hide: !!formatEmpSession['emp-lock'],
            width: 40,
            isEditabled: false,

            cellRenderer: (param) => {
              if (!param.data) return;
              if (param.data.name) {
                return (
                  <LockedButton
                    agParam={param}
                    data={param.data}
                    totalData={employeeList}
                    isHeader={!param.data}
                    dateRange={dateRange}
                    publicHoliday={publicHoliday}
                    planDetailLocked={
                      planDetailLocked || {
                        planDetailLockedList: [],
                        employeeIdLockedList: [],
                      }
                    }
                  />
                );
              }
              return (
                <CleanVakantButton
                  param={param}
                  rowData={rowData}
                  workingEnvSetup={workingEnvSetup}
                />
              );
            },
            headerComponentParams: {
              totalData: employeeList,
              isHeader: true,
              dateRange,
              planDetailLocked: planDetailLocked || {
                planDetailLockedList: [],
                employeeIdLockedList: [],
              },
            },
          },
          {
            headerComponent: 'copyButton',
            colId: 'emp-copy',
            hide: !!formatEmpSession['emp-copy'],
            resizable: false,
            headerClass: 'action-btn',
            width: 40,
            cellRenderer: (param) => {
              return (
                <CopyButton
                  data={param.data}
                  totalData={employeeList}
                  isHeader={!param.data}
                  dateRange={dateRange}
                />
              );
            },
            headerComponentParams: {
              totalData: employeeList,
              isHeader: true,
              dateRange,
              planDetailLocked: planDetailLocked || {
                planDetailLockedList: [],
                employeeIdLockedList: [],
              },
            },
            isEditabled: false,
          },
          {
            headerComponent: 'sendScheduleButton',
            headerComponentParams: {
              unitSelected,
              planID: planSelected.id,
              versionID: planSelected.versionID,
            },
            colId: 'emp-send-schedule',
            headerClass: 'action-btn',
            resizable: false,
            hide: !!formatEmpSession['emp-send-schedule'],
            width: 40,
            cellRenderer: ShowCalenderButton,
            cellRendererParams: ({ data }) => ({
              data,
              planID: planSelected.id,
              versionID: planSelected.versionID,
            }),
            isEditabled: false,
          },
          {
            headerName: t('role-matched/role-not-match'),
            field: 'totalTask',
            colId: 'emp-task',
            resizable: true,

            hide: !!formatEmpSession['emp-task'],
            width: 130,
            valueGetter: ({ data }) => {
              if (!data) return;

              return `${data.numShiftWithExactRole}/${data.totalShift}` || '-';
            },
          },
          {
            field: 'role',
            headerName: t('role'),
            colId: 'emp-role',
            resizable: true,

            hide: !!formatEmpSession['emp-role'],
            width: 130,
          },
          {
            field: 'jobPercentage',
            headerName: t('job-percentage'),
            colId: 'emp-job-percentage',
            hide: false,
            headerClass: 'main-col-header',
            cellClass: 'main-col-cell',
            width: 70,
            valueFormatter: ({ data }) => {
              if (!data) return;
              const totalHrsPerWeek =
                data.totalHrsPerWeek && data.totalHrsPerWeek > 0
                  ? data.totalHrsPerWeek
                  : MAXIMUM_WEEK_WORKING_HOUR;

              const TOTAL_HRS = totalHrsPerWeek * totalWeek; // default 35.5 hrs per week
              const isAllocatedHoursNumber = typeof data.allocatedHours === 'number';
              const isJobPercentageNumber = typeof data.jobPercentage === 'number';

              return isAllocatedHoursNumber && data.jobPercentage === undefined
                ? `${((data.allocatedHours * 100) / TOTAL_HRS).toFixed(2)}%`
                : isJobPercentageNumber
                ? `${data.jobPercentage}%`
                : '-';
            },
          },
          {
            headerName: t('allocated-hours'),
            field: 'allocatedHours',
            colId: 'emp-hours',
            headerClass: 'main-col-header',
            cellClass: 'main-col-cell',
            width: 70,
            hide: !!formatEmpSession['emp-hours'],

            valueGetter: ({ data }) => {
              if (!data) return;

              const rounded = data.allocatedHours.toFixed(2);
              /* rounded return string => sort wrong. Must be is number */
              return Number(rounded) || 0;
            },
          },

          {
            headerName: t('deviation'),
            field: 'deviation',
            headerClass: 'main-col-header',
            cellClass: 'main-col-cell',
            colId: 'emp-deviation',
            resizable: false,
            width: 70,
            hide: !!formatEmpSession['emp-deviation'],

            valueGetter: ({ data }) => {
              if (!data || !data.name) return;

              const rounded = data.deviation.toFixed(2);
              return data.jobPercentage === undefined ? 0 : Number(rounded) || 0;
            },
          },
        ],
      },
    ],
    [data, editedEmp, rowData, formatEmpSession, rowData],
  );

  const components = useMemo(
    () => ({
      copyButton: CopyButton,
      lockedButton: LockedButton,
      sendScheduleButton: SendScheduleButton,
    }),
    [],
  );
  const onGridReady = (params) => {
    setGridColumnApiEmployeeInfo(params.columnApi);

    empRef.current = params.api;
    if (globalSortEmployee) {
      params.columnApi.applyColumnState(globalSortEmployee);
    }
    dispatch(updateApi({ key: 'empInfo', value: { api: params.api, colApi: params.columnApi } }));
  };

  const sortTable = ({ colId, sort }) => {
    const count = {};
    gridColumnApiTaskList.getColumnState()?.forEach((item) => {
      if (count[item.colId]) {
        count[item.colId]++;
      } else {
        count[item.colId] = 1;
      }
    });

    // sort task table
    gridColumnApiTaskList.applyColumnState({
      state: [{ colId, sort }],
      defaultState: { sort: null },
      applyOrder: true,
    });

    // sort dashboard table
    gridColumnApiDashboard.applyColumnState({
      state: [{ colId, sort }],
      defaultState: { sort: null },
      applyOrder: true,
    });

    // hr per week table
    gridColumnApiHrPerWeek.applyColumnState({
      state: [{ colId, sort }],
      defaultState: { sort: null },
      applyOrder: true,
    });
  };

  const onSortChanged = (_) => {
    // get the sorted column in emp table
    const empColumnState = gridColumnApiEmpInfo.getColumnState();

    const sortedColumns = empColumnState?.find(
      (column) => SORTABLE_COLUMN_ID.includes(column.colId) && column.sort !== null,
    );
    if (sortedColumns) {
      const { colId = '', sort = '' } = sortedColumns;
      setSortType(sort);
      setSortCol(colId);

      sortTable({ colId, sort });

      if (!!colId && !!sort) {
        dispatch(
          updateGlobalSortEmployeeAction({
            state: [{ colId, sort }],
            defaultState: { sort: null },
            applyOrder: false,
          }),
        );
      }
    } else {
      dispatch(updateGlobalSortEmployeeAction({}));
    }
  };
  const rowDragEnd = (params) => {
    const { node, overIndex } = params;
    const nodeData = node.data;
    const displayedData = [];
    // get data displayed on ag grid
    params.api.forEachNodeAfterFilterAndSort((ele) => {
      if (ele.data) {
        displayedData.push(ele.data);
      }
    });
    const updateArr = [...displayedData];
    const oldIndex = displayedData.findIndex((item) => item.employeeId === nodeData.employeeId);
    updateArr.splice(oldIndex, 1);
    updateArr.splice(overIndex, 0, nodeData);

    setEmployeeData(updateArr);
  };
  return (
    <div className="ag-theme-alpine emp-info" id="emp-info-wrapper">
      <AgGridReact
        ref={empRef}
        onGridReady={onGridReady}
        rowData={rowData}
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        animateRows={true}
        components={components}
        // onFirstDataRendered={onFirstDataRendered}
        enableCharts={true}
        // enableRangeSelection={true}
        immutableData={true}
        tooltipShowDelay={0}
        getRowId={getRowId}
        isExternalFilterPresent={() => isExternalFilterPresent}
        doesExternalFilterPass={doesExternalFilterPass}
        onSortChanged={onSortChanged}
        gridOptions={{ maintainColumnOrder: true, rowDragManaged: true }}
        rowDragEntireRow={true}
        rowDragManaged={true}
        onRowDragEnd={rowDragEnd}
        postSortRows={postSortRows}
      />
    </div>
  );
};

export default memo(EmpInfo);
