import moment from 'moment';
import {
  DAY_SHIFT_VALUE,
  EVENING_SHIFT_VALUE,
  NIGHT_SHIFT_VALUE,
  LONG_SHIFT_VALUE,
  PER_POSITION_CATEGORY,
} from 'constant';
import { calculateHardAndSoftRuleData, calculateSurveyData } from './calculateData';
import { getMinusPaidTimeBreak } from 'helper/calculatePaidBreak';
import { calculateHrPerWeek } from 'helper/calculateTableData';
import { getWeekNumberStartOnMonday } from 'helper/dateTime';

export const updateEmpData = (
  empInfoApi,
  dashboardApi,
  summaryApi,
  hrPerWeekAPI,
  empData,
  tasks,
  params,
  employeeData,
  publicHoliday,
  dateInWeek,
  taskArr,
  amlInformation,
  fromDate,
  endDate,
  surveyData,
  summaryData,
  summarySelectedView,
  oldTask,
  weekRange,
) => {
  const empRow = empInfoApi.api.getRowNode(empData.employeeId);
  const empDashboardRow = dashboardApi.api.getRowNode(empData.employeeId);
  if (!empRow) return;
  const empInfoData = empRow.data;
  const jobPercentage = empInfoData.jobPercentage || 100;
  let allocatedHours = +empInfoData.allocatedHours;
  let allocatedHoursDeviation = +empInfoData.allocatedHoursDeviation;
  if (tasks.length) {
    const {
      isDeleted,
      hourAssigned: hourAssignedTask,
      taskType,
      layerID,
      layerName,
      taskId,
    } = tasks[0];
    const minusPaidBreak = getMinusPaidTimeBreak(tasks[0], dateInWeek, publicHoliday);

    const hourAssigned = +hourAssignedTask - minusPaidBreak;

    const shiftAdjustment = isDeleted ? -1 : +1;
    //Will be refactor to use layer later
    switch (taskType) {
      case DAY_SHIFT_VALUE:
        updateSummary(
          summaryApi,
          summarySelectedView === PER_POSITION_CATEGORY && layerName
            ? `day${layerName}`
            : 'dayShift',
          summarySelectedView === PER_POSITION_CATEGORY && layerName
            ? `day${layerName}-${dateInWeek}`
            : `dayShift-${dateInWeek}`,
          dateInWeek,
          shiftAdjustment,
        );

        break;
      case EVENING_SHIFT_VALUE:
        updateSummary(
          summaryApi,
          summarySelectedView === PER_POSITION_CATEGORY && layerName
            ? `late${layerName}`
            : 'lateShift',
          summarySelectedView === PER_POSITION_CATEGORY && layerName
            ? `late${layerName}-${dateInWeek}`
            : `lateShift-${dateInWeek}`,

          dateInWeek,
          shiftAdjustment,
        );

        break;
      case NIGHT_SHIFT_VALUE:
        updateSummary(
          summaryApi,
          summarySelectedView === PER_POSITION_CATEGORY && layerName
            ? ` night${layerName}`
            : 'nightShift',
          summarySelectedView === PER_POSITION_CATEGORY && layerName
            ? `night${layerName}-${dateInWeek}`
            : `nightShift-${dateInWeek}`,
          dateInWeek,
          shiftAdjustment,
        );

        break;
      case LONG_SHIFT_VALUE:
        updateSummary(
          summaryApi,
          summarySelectedView === PER_POSITION_CATEGORY && layerName
            ? ` long-shift${layerName}`
            : 'long-shift',
          summarySelectedView === PER_POSITION_CATEGORY && layerName
            ? `long-shift${layerName}-${dateInWeek}`
            : `long-shift-${dateInWeek}`,
          dateInWeek,
          shiftAdjustment,
        );

        break;

      default:
        break;
    }

    let hoursAdjustment = isDeleted ? -hourAssigned : +hourAssigned;
    if (oldTask && oldTask.length && taskId && !oldTask[0].isDeleted) {
      const oldTaskHour = oldTask[0].hourAssigned;
      hoursAdjustment = hoursAdjustment - Number(oldTaskHour);
    }

    allocatedHours = allocatedHours + Number(hoursAdjustment);
    allocatedHoursDeviation = allocatedHoursDeviation + Number(hoursAdjustment);
  }

  const deviation = calculateDeviation(
    Number(allocatedHoursDeviation),
    Number(empInfoData.totalValidHour),
    Number(jobPercentage),
  );

  updateEmpRow(empRow, deviation, allocatedHours, allocatedHoursDeviation);
  updateDashboardData(
    empData,
    empDashboardRow,
    params,
    taskArr,
    surveyData,
    amlInformation,
    publicHoliday,
    fromDate,
    endDate,
  );

  const empIndexInAllData = employeeData.findIndex(
    (item) => +item.employeeId === +empData.employeeId,
  );
  if (empIndexInAllData > -1) {
    const empData = employeeData[empIndexInAllData];
    updateEmpRecord(
      empData,
      tasks[0],
      params.data,
      allocatedHours,
      allocatedHoursDeviation,
      deviation,
      publicHoliday,
      dateInWeek,
      oldTask,
    );
  }
};
export const updateSummary = (summaryApi, rowId, colId, date, value) => {
  try {
    const summaryRow = summaryApi.api.getRowNode(rowId.trim());
    const oldValue = summaryRow.data[date];
    const newRowData = { ...summaryRow.data };
    newRowData[date] = oldValue + value;
    summaryRow.setData(newRowData);
  } catch (error) {
    console.log(`error`, error);
    console.log(colId);
  }
};

const calculateDeviation = (allocatedHours, totalValidHour, jobPercentage) => {
  return allocatedHours - totalValidHour * (+jobPercentage / 100);
};

const updateEmpRow = (empRow, deviation, allocatedHours) => {
  empRow.setDataValue('emp-deviation', deviation);
  empRow.setDataValue('emp-hours', allocatedHours);
};
export const updateDashboardData = (
  empData,
  empDashboardRow,
  params,
  taskArr,
  surveyData,
  amlInformation,
  publicHoliday,
  fromDate,
  endDate,
) => {
  //Update dashboard data
  const reCalculateSurveyRule = calculateSurveyData(
    params.data,
    taskArr,
    surveyData,
    fromDate,
    endDate,
  );
  const reCalculateHardAndSoftRule = calculateHardAndSoftRuleData(
    params.data,
    taskArr,
    amlInformation,
    publicHoliday,
    fromDate,
    endDate,
  );
  if (reCalculateHardAndSoftRule) {
    for (const key of Object.keys(reCalculateHardAndSoftRule)) {
      if (reCalculateHardAndSoftRule[key] && Array.isArray(reCalculateHardAndSoftRule[key])) {
        const isNotValid = reCalculateHardAndSoftRule[key].find(
          (item) => item.employeeId === empData.employeeId,
        );
        try {
          empDashboardRow.setDataValue(key, !!isNotValid);
        } catch (error) {
          console.log(key);
        }
      }
    }
  }

  if (reCalculateSurveyRule) {
    for (const key of Object.keys(reCalculateSurveyRule)) {
      if (reCalculateSurveyRule[key] && Array.isArray(reCalculateSurveyRule[key])) {
        const isNotValid = reCalculateSurveyRule[key].find(
          (item) => item.employeeID === empData.employeeId,
        );
        try {
          empDashboardRow.setDataValue(key, !!isNotValid);
        } catch (error) {
          console.log(key);
        }
      }
    }
  }
};
const updateEmpRecord = (
  empData,
  task,
  paramsData,
  allocatedHours,
  allocatedHoursDeviation,
  deviation,
  publicHoliday,
  dateInWeek,
  oldTask,
) => {
  const { taskType, isDeleted, hourAssigned, taskId } = task;
  const { totalShift, hrPerWeek } = paramsData;

  const isPublicHoliday = publicHoliday.includes(dateInWeek);
  const isSunday = moment(dateInWeek).weekday() === 0;
  const shiftAdjust = isDeleted ? -1 : 1;
  let hourAdjust = isDeleted ? -hourAssigned : +hourAssigned;
  const numWeek = getWeekNumberStartOnMonday(dateInWeek);
  if (oldTask && oldTask.length && taskId && !oldTask[0].isDeleted) {
    const oldTaskHour = oldTask[0].hourAssigned;
    hourAdjust -= Number(oldTaskHour);
  }
  empData.hrPerWeek[numWeek] = +hrPerWeek[numWeek] + Number(hourAdjust);
  if (empData.hrPerWeek < 0) {
    empData.hrPerWeek = 0;
  }
  empData.totalShift = totalShift + shiftAdjust;
  empData.allocatedHours = allocatedHours;
  empData.allocatedHoursDeviation = allocatedHoursDeviation;
  empData.deviation = deviation;
  empData.task_arr[dateInWeek] = [task];

  updateShiftCounts(empData, taskType, shiftAdjust);
  if (isPublicHoliday) {
    empData.numWorkingHoliday += shiftAdjust;
  }
  if (isSunday) {
    empData.numWorkingSunday += shiftAdjust;
  }
};

const updateShiftCounts = (empData, taskType, shiftAdjust) => {
  switch (taskType) {
    case DAY_SHIFT_VALUE:
      empData.dayShift += shiftAdjust;
      break;
    case EVENING_SHIFT_VALUE:
      empData.lateShift += shiftAdjust;
      break;
    case NIGHT_SHIFT_VALUE:
      empData.nightShift += shiftAdjust;
      break;
    case LONG_SHIFT_VALUE:
      empData.longShift += shiftAdjust;
      break;
    default:
      empData.otherShift += shiftAdjust;
  }
};
