/** libs */
import React from 'react';

/** components */
import Text from '_components/Text';

const style = {
  color: '#4E1830',
  textUnderlineOffset: '2px',
  cursor: 'pointer',
};

const LinkColumn = ({ onClick, children, ...props }) => {
  return (
    <Text underline {...props} style={style} className="link-column" onClick={onClick}>
      {children}
    </Text>
  );
};

export default LinkColumn;
