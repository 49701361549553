import { t } from 'i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import React, { forwardRef, useCallback, useMemo } from 'react';

import DeleteCell from './DeleteCell';
import {
  PREDICT_DAY_SHIFT_VALUE,
  PREDICT_LATE_SHIFT_VALUE,
  PREDICT_NIGHT_SHIFT_VALUE,
  PREDICT_SHIFT_VALUE,
  TASK_LIST_LEGEND_COLOR,
  weekdayKey,
} from 'constant';
import { selectGlobal } from 'redux/global/globalSlice';
import {
  calculateFTEAbsencePredictionLayer,
  calculateSummaryData,
  getTaskTranslation,
} from 'helper/calculateTableData';

import './styles.scss';
import { useRoles, useTaskList } from 'Hooks/useData';
import _ from 'lodash';
import EditLayer from './EditLayer';
import { selectAuth } from 'redux/auth/authSlice';
import TimeEditor from './TimeEditor';
import RoleTooltip from './RoleTooltip';
import { getRowIDStaffPlanTable } from '../helper';

const SUMMARY_HEADER_COLOR = '#F3F3F3';
const PREDICTION_HEADER_COLOR = '#FFD7CD';
const PREDICTION_CELL_COLOR = '#FFF5F3';

const WeekHeader = ({ weekIndex, startDateInWeek, endDateInWeek }) => {
  const formatStartDate = moment(startDateInWeek, 'DD-MM-YYYY').format('DD. MMM').toLowerCase();
  const formatEndDate = moment(endDateInWeek, 'DD-MM-YYYY').format('DD. MMM YYYY').toLowerCase();
  const calendarWeek = moment(startDateInWeek, 'DD-MM-YYYY').week();
  return (
    <div className="week-group-header">
      <p>
        {t('week')} {weekIndex}
      </p>
      <p>
        {formatStartDate} - {formatEndDate} ({t('week').toLowerCase()} {calendarWeek})
      </p>
    </div>
  );
};

const TaskTable = forwardRef(function TaskTable({
  planInfo,
  tableData,
  setTableData,
  gridRef,
  showSummary,
  taskTypeList,
  showAbsencePrediction,
  setDeletedList,
  unitSelected,
  predictPercentData,
  setPredictPercentData,
  calculatePredictData,
  dayPredictData,
  latePredictData,
  nightPredictData,
  predictCalculated,
  setPredictCalculated,
  setPredictShiftList,
  taskList,
  layerList,
  refetchLayerList,
  layerCreated,
  setLayerCreated,
  publicHoliday,
  predictPercent,
}) {
  const { language } = useSelector(selectGlobal);
  const { isEditabled } = useSelector(selectAuth);

  const { data: listRole } = useRoles();
  const rowData = useMemo(() => {
    const staffPlanData = tableData.filter((item) => item.taskTypeValue >= 0);

    const summaryData = [...calculateSummaryData(tableData, taskTypeList)];
    const listLayerID = staffPlanData.map((item) => item.layerID);
    const listLayerRendered = [...layerList];
    layerCreated.forEach((item, index) => {
      listLayerRendered.push({
        id: `${item.layerName}`,
        name: item.layerName,
        hour_per_week: item.weekHr,
        role_id: item.roleID,
      });
    });
    const layerData = listLayerRendered
      .filter((item) => {
        return !listLayerID.includes(item.id);
      })
      .map((item) => {
        return { layerID: item.id, layerName: item.name, taskList: [], roleID: item.role_id };
      });

    const result = [
      ...staffPlanData,
      ...layerData,
      ...(showAbsencePrediction ? predictCalculated : []),
      ...(showSummary ? summaryData : []),
    ];

    return result;
  }, [
    tableData,
    taskTypeList,
    showSummary,
    showAbsencePrediction,
    planInfo,
    layerList,
    layerCreated,
    predictCalculated,
  ]);

  const defaultColDef = useMemo(
    () => ({
      filter: false,
      sortable: false,
      editable: false,
      resizable: false,
      menuTabs: [], // hide menu when hover header
      cellStyle: ({ data }) => {
        if (!data) {
          return;
        }
        const cellStyle = {};
        if (data.isSummary && data.isHeader) {
          cellStyle.backgroundColor = SUMMARY_HEADER_COLOR;
        }
        if (data.isPrediction && data.isHeader) {
          cellStyle.backgroundColor = PREDICTION_HEADER_COLOR;
        }
        if (data.isPrediction && data.isCell) {
          cellStyle.backgroundColor = PREDICTION_CELL_COLOR;
        }
        return cellStyle;
      },
    }),
    [],
  );
  const handleOnChangePredictPercent = (e, task) => {
    const { value, name: taskID } = e.target;
    setPredictPercentData((prev) => ({ ...prev, [taskID]: +value }));
    let predictData;
    switch (+task.taskTypeValue) {
      case PREDICT_DAY_SHIFT_VALUE:
        predictData = dayPredictData.predictData;
        break;
      case PREDICT_LATE_SHIFT_VALUE:
        predictData = latePredictData.predictData;
        break;
      case PREDICT_NIGHT_SHIFT_VALUE:
        predictData = nightPredictData.predictData;
        break;

      default:
        predictData = [];

        break;
    }
    const newPredictData = calculatePredictData({
      predictData: predictData,
      percent: value,
      task: task,
    });
    for (const data of newPredictData) {
      const dateFoundIndex = task.taskList.findIndex((item) => item.date === data.date);
      if (dateFoundIndex > -1) {
        task.taskList[dateFoundIndex].numberPerson = data.numberPerson;
        task.taskList[dateFoundIndex].isEditted = true;
      }
    }
  };
  const staticCol = useMemo(() => {
    return [
      {
        headerName: t('guard-code-info'),
        headerClass: 'parent-table-header last-header',

        children: [
          {
            headerName: t('layer'),
            field: 'layerID',
            rowGroup: true,
            hide: true,
            sortable: true,
            filter: true,
            sort: 'asc',

            comparator: function (valueA, valueB, nodeA, nodeB, isDescending) {
              // > 0 Sort valueA after valueB
              // < 0 Sort valueA before valueB
              if (valueA === t('summary')) {
                return 10;
              }
              if (valueB === t('summary')) {
                return -10;
              }
              if (valueA === t('task-prediction')) {
                return 9;
              }
              if (valueB === t('task-prediction')) {
                return -9;
              }

              if (!valueA || !valueB) {
                return -8;
              }

              if (!isNaN(valueA) && !isNaN(valueB)) {
                return 0;
              }
            },
          },

          {
            headerName: t('guard-code'),
            headerClass: 'table-header',
            width: 150,
            field: 'taskName',
            pinned: true,
            resizable: true,
            showRowGroup: true,
            flex: 1,
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: {
              suppressDoubleClickExpand: true,
              suppressCount: true,

              innerRenderer: (params) => {
                const { data, node } = params;
                if (!data) {
                  if (node.field === 'layerID') {
                    const layerFound = layerList.find((item) => +item.id === +node.key);
                    if (layerFound) {
                      return layerFound.name;
                    }
                    return params.value;
                  }
                  return;
                }
                const { taskName, isSummary, isCell } = data;
                return isSummary && isCell
                  ? getTaskTranslation(taskName, language === 'NO')
                  : taskName;
              },
            },

            suppressNavigable: true, // remove this column from tab navigation,
            valueGetter: (params) => {
              const { data, node } = params;

              if (!data) {
                if (node.field === 'layerID') {
                  const layerFound = layerList.find((item) => +item.id === +node.key);
                  if (layerFound) {
                    return layerFound.name;
                  }
                  return node.groupData.taskName;
                }
                return;
              }
              const { taskName, isSummary, isCell } = data;
              return isSummary && isCell
                ? getTaskTranslation(taskName, language === 'NO')
                : taskName;
            },
          },
          {
            headerName: t('role'),
            headerClass: 'table-header',
            width: 140,
            pinned: true,
            flex: 1,
            resizable: true,
            suppressNavigable: true, // remove this column from tab navigation,
            tooltipComponent: RoleTooltip,
            tooltipValueGetter: ({ data }) => {
              if (!data) return;
              return data.roleInfoList;
            },
            valueGetter: (params) => {
              const { data, node } = params;
              if (!data) {
                return;
                // if (node.field === 'layerID') {
                //   const layerFound = layerList.find((item) => +item.id === +node.key);
                //   if (layerFound) {
                //     const roleFound = listRole.find((item) => +item.id === +layerFound.role_id);
                //     if (roleFound) return roleFound.role;
                //   } else {
                //     const newLayerFound = layerCreated.find((item) => item.layerName === node.key);
                //     if (newLayerFound) {
                //       const roleFound = listRole.find(
                //         (item) => +item.id === +newLayerFound?.roleID,
                //       );
                //       if (roleFound) return roleFound.role;
                //     }
                //   }
                // }
                // return;
              }
              const { roleKey, isSummary, isCell, roleID, roleList } = data;

              if (roleList?.length) {
                const roleInList = listRole.filter((item) => roleList.includes(+item.id));
                return roleInList.map((item) => item.role);
              }
              return isSummary && isCell ? getTaskTranslation(roleKey, language === 'NO') : roleKey;
            },
          },
          {
            headerClass: 'table-header',
            headerName: t('FTE'),

            cellClass: ({ data }) => {
              if (!data) {
                return;
              }
              const { isPrediction, isHeader } = data;
              return isPrediction ? (isHeader ? 'no-ellipsis' : 'input-wrapper') : '';
            },
            resizable: true,

            width: 80,
            pinned: true,
            suppressNavigable: true,
            colSpan: (params) => {
              const { data } = params;
              return data ? 1 : 2;
            },
            cellRenderer: (params) => {
              const { data, node } = params;
              if (!data) {
                const layerID = params.node.key;
                const layerFound = layerList.find((item) => +item.id === +layerID);
                if (layerFound) {
                  const layerFTE = calculateFTEAbsencePredictionLayer({
                    planData: tableData,
                    layerID,
                    planInfo,
                    hourPerWeek: layerFound.hour_per_week,
                    publicHoliday,
                  });
                  return `${layerFTE}`;
                } else {
                  const newLayerFound = layerCreated.find((item) => item.layerName === node.key);

                  if (newLayerFound) {
                    const layerFTE = calculateFTEAbsencePredictionLayer({
                      planData: tableData,
                      layerID: node.key,
                      planInfo,
                      hourPerWeek: newLayerFound.weekHr,
                      publicHoliday,
                    });
                    return `${layerFTE}`;
                  }
                }

                return;
              }
              const { isPrediction, isHeader } = data;
              return isPrediction ? (
                isHeader ? (
                  t('percent-prediction')
                ) : (
                  <input
                    className="predict-input"
                    name={data.taskID}
                    type="float"
                    defaultValue={predictPercentData[data.taskID] || 0}
                    placeholder="100%"
                    onChange={(e) => handleOnChangePredictPercent(e, data)}
                    disabled={!isEditabled}
                  />
                )
              ) : (
                ''
              );
            },
          },
          {
            headerName: t('group'),
            headerClass: 'table-header',
            width: 100,
            pinned: true,
            flex: 1,
            resizable: true,

            suppressNavigable: true,
            valueGetter: ({ data }) => {
              return data.groupName;
            },
          },

          {
            headerName: t('from'),
            headerClass: 'table-header',
            width: 80,
            pinned: true,
            cellEditor: TimeEditor,
            colId: 'fromTime',
            cellEditorParams: {
              setPredictCalculated,
              setPredictShiftList,
            },
            editable: ({ data }) => {
              if (!data) {
                return;
              }
              return data.isPrediction;
            },
            suppressNavigable: true,
            valueGetter: ({ data }) => {
              if (!data) {
                return;
              }
              const { fromTime } = data;
              return fromTime ? moment(fromTime, 'HH:mm:ss').format('HH:mm') : '';
            },
            valueSetter: (params) => {
              const { data, newValue } = params;
              data.fromTime = newValue;
              return true;
            },
          },
          {
            headerName: t('to'),
            headerClass: 'table-header ',
            colId: 'endTime',
            width: 80,
            pinned: true,
            cellEditor: TimeEditor,
            cellEditorParams: {
              setPredictCalculated,
              setPredictShiftList,
            },
            editable: ({ data }) => {
              if (!data) {
                return;
              }
              return data.isPrediction;
            },

            suppressNavigable: true,
            valueGetter: ({ data }) => {
              if (!data) {
                return;
              }
              const { endTime } = data;
              return endTime ? moment(endTime, 'HH:mm:ss').format('HH:mm') : '';
            },
            valueSetter: (params) => {
              const { data, newValue } = params;
              data.endTime = newValue;
              return true;
            },
          },
          {
            headerClass: 'table-header last-header center-header',
            headerName: '',
            pinned: true,
            width: 80,
            cellClass: 'custom-cell',
            sortable: false,
            editable: false,
            cellStyle: ({ data }) => {
              if (!data) {
                return;
              }
              const { isSummary, isPrediction, isHeader, isCell } = data;

              return {
                textAlign: 'center',
                backgroundColor:
                  (isSummary && isHeader && SUMMARY_HEADER_COLOR) ||
                  (isPrediction && isHeader && PREDICTION_HEADER_COLOR) ||
                  (isPrediction && isCell && PREDICTION_CELL_COLOR) ||
                  '',
              };
            },
            cellRenderer: (params) => {
              const { data, node } = params;
              if (!data) {
                if (
                  !node.key?.length ||
                  node.key === t('summary') ||
                  node.key === t('task-prediction')
                ) {
                  return;
                }

                return (
                  <EditLayer
                    layerID={params.node.key}
                    refetchLayerList={refetchLayerList}
                    setTableData={setTableData}
                    setDeletedList={setDeletedList}
                    tableData={tableData}
                    setLayerCreated={setLayerCreated}
                  />
                );
              }
              const { isHeader, isSummary, isCell, totalTaskCount, isPrediction } = data;
              // check header or summary row
              if (isHeader || isPrediction) {
                return null;
              }
              if (isSummary && isHeader) {
                return <span>{t('total')}</span>;
              }
              if (isSummary && isCell) {
                return <span>{totalTaskCount || ''}</span>;
              }
              if (!data.taskUUID) return;

              return (
                <>
                  <DeleteCell
                    tableData={tableData}
                    setTableData={setTableData}
                    rowData={data}
                    setDeletedList={setDeletedList}
                  />
                </>
              );
            },
          },
        ],
      },
    ];
  }, [tableData, language, showSummary, layerList, layerCreated]);
  const dynamicCol = useMemo(() => {
    const weekColumns = Array.from({ length: planInfo.numWeek }).map((_, weekIndex) => {
      const startDateInWeek = moment(planInfo.fromDate)
        .add(weekIndex, 'w')
        .startOf('W')
        .format('DD-MM-YYYY');
      const endDateInWeek = moment(planInfo.fromDate)
        .add(weekIndex, 'w')
        .endOf('W')
        .format('DD-MM-YYYY');

      return {
        headerGroupComponent: WeekHeader,
        headerGroupComponentParams: { weekIndex: weekIndex + 1, startDateInWeek, endDateInWeek },
        headerClass: 'parent-table-header center-week-header',
        suppressColumnsToolPanel: true,
        children: weekdayKey.map((day, dayIndex) => {
          const dateInWeek = moment(planInfo.fromDate)
            .add(weekIndex * 7 + dayIndex, 'd')
            .format('YYYY-MM-DD');
          const isWeekend = ['sat', 'sun'].includes(day);
          return {
            colId: `${7 + (weekIndex + 1) * 7 + dayIndex}`, // code info col + weekindex * 7 + dayindex
            headerName: t(day),
            width: 70,
            headerClass: `table-header  ${isWeekend ? 'weekend-header' : ''}`,
            cellClass: ({ data }) => {
              if (!data) {
                return;
              }
              if (data.isSummary && data.isHeader) {
                return `table-header ${isWeekend ? 'weekend-header' : ''}`;
              }
            },

            colSpan: (params) => {
              const { data: taskData } = params;
              if (!taskData) {
                if (weekIndex === 0 && dayIndex === 0) {
                  const layerID = params.node.key;
                  const layerFound = layerList.find((item) => +item.id === +layerID);
                  if (layerFound) {
                    return 4;
                  }
                }
                if (dayIndex === weekdayKey.length - 2 && weekIndex === planInfo.numWeek - 1) {
                  return 3;
                }
              }
              return 1;
            },
            editable: ({ data }) => {
              if (!isEditabled) return false;
              if (data && !data.taskID) return false;
              return data.isSummary || data.isPrediction || (data.isPrediction && data.isHeader)
                ? false
                : true;
            },
            suppressNavigable: ({ data }) => {
              if (!data) {
                return;
              }
              return data.isSummary || (data.isPrediction && data.isHeader) ? true : false;
            },
            cellStyle: (params) => {
              const { data: taskData } = params;
              const cellStyle = {};
              if (!taskData) return {};
              if (publicHoliday.includes(dateInWeek)) {
                cellStyle.backgroundColor = TASK_LIST_LEGEND_COLOR.HOLIDAY;
              }
              return cellStyle;
            },
            valueGetter: (params) => {
              const { data: taskData } = params;
              if (taskData?.isHeader) {
                return null;
              }
              if (taskData?.isSummary && taskData?.isHeader) {
                return t(day);
              }
              const dateFound = taskData?.taskList.find((item) => item.date === dateInWeek);
              if (dateFound) {
                return dateFound.numberPerson > 0 ? dateFound.numberPerson : '';
              }
              if (!taskData) {
                // if (weekIndex === 0 && dayIndex === 0) {
                //   const layerID = params.node.key;
                //   const layerFound = layerList.find((item) => +item.id === +layerID);
                //   if (layerFound) {
                //     return `${layerFound.hour_per_week} ${t('hour')}/${'week'}`;
                //   }
                // }
                // if (dayIndex === weekdayKey.length - 2 && weekIndex === planInfo.numWeek - 1) {
                //   const layerID = params.node.key;
                //   const layerFound = layerList.find((item) => +item.id === +layerID);
                //   if (layerFound) {
                //     const layerFTE = calculateFTEAbsencePredictionLayer({
                //       planData: tableData,
                //       layerID,
                //       planInfo,
                //       hourPerWeek: layerFound.hour_per_week,
                //     });
                //     return `${layerFTE} ${t('FTE').toLowerCase()}`;
                //   }
                // }
              }
              return null;
            },
            valueSetter: (params) => {
              const { data: taskData, newValue } = params;

              if (!taskData) {
                return;
              }
              if (taskData && !taskData.taskID) return false;
              const dateFoundIndex = taskData?.taskList.findIndex(
                (item) => item.date === dateInWeek,
              );
              if (dateFoundIndex > -1) {
                taskData.taskList[dateFoundIndex].numberPerson = newValue;
                taskData.taskList[dateFoundIndex].isEditted = true;
                taskData.taskList[dateFoundIndex].fromTime = taskData?.fromTime.split('.')[0];
                taskData.taskList[dateFoundIndex].endTime = taskData?.endTime.split('.')[0];
                taskData.taskList[dateFoundIndex].layerID = taskData?.layerID;
                taskData.taskList[dateFoundIndex].layerName = taskData?.layerName;
                taskData.taskList[dateFoundIndex].roleID = taskData?.roleID;
                taskData.taskList[dateFoundIndex].groupID = taskData?.groupID;
              } else {
                taskData?.taskList.push({
                  date: dateInWeek,
                  numberPerson: newValue,
                  planDetailUUID: null,
                  isEditted: true,
                  taskUUID: taskData?.taskUUID,
                  fromTime: taskData?.fromTime.split('.')[0],
                  endTime: taskData?.endTime.split('.')[0],
                  layerID: taskData.layerID,
                  layerName: taskData.layerName,
                  roleID: taskData.roleID,
                  groupID: taskData?.groupID,
                });
              }
              // if predict data, then dont rerender table data
              if (taskData?.isPrediction) {
                return true;
              }

              setTableData((prev) => {
                const newTableData = [...prev];

                const updatedTaskIndex = newTableData.findIndex(
                  (item) =>
                    item.taskUUID === taskData?.taskUUID &&
                    +item.layerID === +taskData?.layerID &&
                    +item.groupID === +taskData?.groupID &&
                    item.layerName === taskData?.layerName,
                );
                if (updatedTaskIndex !== -1) {
                  newTableData[updatedTaskIndex] = taskData;
                }
                return newTableData;
              });
              return true;
            },
          };
        }),
      };
    });
    return weekColumns;
  }, [
    planInfo,
    language,
    showSummary,
    tableData,
    predictPercentData,
    predictCalculated,
    layerList,
    rowData,
    predictPercent,
  ]);

  const getRowId = useMemo(() => {
    return (params) => {
      if (!params) {
        return '';
      }
      const rowID = getRowIDStaffPlanTable(params.data);
      return rowID;
    };
  }, [tableData]);
  const autoGroupColumnDef = useMemo(() => {
    return {
      // enables filtering on the group column with specified filter type
      filter: 'agTextColumnFilter',
      filterValueGetter: (params) => getRowIDStaffPlanTable(params.data),
    };
  }, []);
  return (
    <div className="task-info">
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        columnDefs={[...staticCol, ...dynamicCol]}
        defaultColDef={defaultColDef}
        animateRows={true}
        suppressMovableColumns={true} //disable default drag column
        enableRangeSelection={true}
        enableFillHandle={true}
        undoRedoCellEditing={true} // enable undo redo
        undoRedoCellEditingLimit={20} // set undo redo limit
        getRowId={getRowId} // distinguish row by id, optional but recommended if need to mutate row data
        groupDefaultExpanded={1}
        groupDisplayType={'custom'}
        groupMaintainOrder={true}
        autoGroupColumnDef={autoGroupColumnDef}
        reactiveCustomComponents={true}
        stopEditingWhenCellsLoseFocus={true}
        tooltipShowDelay={0}
      />
    </div>
  );
});

export default TaskTable;
