import React from 'react';

import CalendarPlanEmployeev2 from '../CalendarPlanEmployee/CalendarPlanEmployeev2';

import './CalendarPlanSurvey.scss';

const CalendarPlanSurvey = ({ unitSelected }) => {
  return (
    <div className="calendar-plan-survey">
      <div className="calendar-plan-survey__content">
        <CalendarPlanEmployeev2 unitSelected={unitSelected} />
      </div>
    </div>
  );
};

export default CalendarPlanSurvey;
