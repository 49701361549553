import { CheckGreenIcon, CloseBlackIcon } from 'assets/img';
import axiosClient from 'axios/axiosClient';
import { Toast } from 'helper/alert';
import { t } from 'i18next';
import React, { useState } from 'react';
import RoleCoverSelection from './RoleCoverSelection';

export default function CalendarPlanRoleRowNew({ setActive, refetchRole, roleList }) {
  const [role, setRole] = useState({ role: '', description: '', roleCovered: [] });
  const handleOnChange = (e) => {
    setRole({ ...role, [e.target.name]: e.target.value });
  };
  const handleSelectRoleCover = (roleSelect) => {
    setRole((prev) => {
      const updateRole = { ...prev };
      if (!updateRole.roleCovered) {
        updateRole.roleCovered = [roleSelect.id];
      } else {
        const isHaveRole = updateRole.roleCovered.find((item) => +roleSelect.id === +item);
        if (isHaveRole) {
          updateRole.roleCovered = updateRole.roleCovered.filter(
            (item) => +item !== +roleSelect.id,
          );
        } else {
          updateRole.roleCovered.push(roleSelect.id);
        }
      }
      return updateRole;
    });
  };
  const handleSubmit = async (e) => {
    let isValid = true;

    if (roleList.find((item) => item.role === role.role)) {
      Toast({ icon: 'error', title: `${t('role-already-exist')} ` });
      return;
    }
    for (const key of Object.keys(role)) {
      if (role[key].length == 0 && key !== 'description' && key !== 'roleCovered') {
        isValid = false;
      }
    }
    if (isValid) {
      await axiosClient
        .post('calendar-plan/role/create', { role })
        .then((_) => {
          Toast({ icon: 'success', title: `${t('create-role')} ${t('success')}` });
          setActive(false);
          refetchRole();
        })
        .catch((err) => {
          Toast({ icon: 'error', title: `${t('create-role')} ${t('unsuccess')}` });
        });
    } else {
      Toast({ icon: 'error', title: `${t('please-input-all')} ` });
    }
  };
  return (
    <tr className="calendar-plan-create-role__item">
      <td className="calendar-plan-create-role__item-label">
        <input
          autoFocus={true}
          name="role"
          type="text"
          value={role.role}
          onChange={handleOnChange}
          required
        />
      </td>
      <td className="calendar-plan-create-role__item-label">
        <input name="key" type="text" value={role.key} onChange={handleOnChange} required />
      </td>
      <td className="calendar-plan-create-role__item-label">
        <RoleCoverSelection
          roleList={roleList}
          role={role}
          handleSelectRoleCover={handleSelectRoleCover}
          disabled={false}
          isNewRole={true}
        />
      </td>
      <td className="calendar-plan-create-role__item-description">
        <input name="description" type="text" value={role.description} onChange={handleOnChange} />
      </td>
      <td className="calendar-plan-create-role__item-action">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: 10,
          }}
        >
          <div onClick={handleSubmit}>
            <img src={CheckGreenIcon} alt="" width={24} />
          </div>
          <div onClick={() => setActive(false)}>
            <img src={CloseBlackIcon} alt="" width={24} />
          </div>
        </div>
      </td>
    </tr>
  );
}
