import React, { useCallback, useRef, useEffect } from 'react';
import './GeneratorNavBar.scss';
import { t } from 'i18next';

const GeneratorNavBar = ({ navList, currentNavTab, setCurrentNavTab }) => {
  const refs = useRef([]);

  const handleKeyDown = (event, index) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      const nextIndex = (index + 1) % navList.length;
      setCurrentNavTab(navList[nextIndex].key);
      if (navList[nextIndex].method) {
        navList[nextIndex].method();
      }
      refs.current[nextIndex].focus();
    }
  };

  const renderNavList = useCallback(() => {
    return navList.map((nav, index) => {
      return (
        <div
          key={`nav-menu-${nav.key}`}
          ref={(ref) => (refs.current[index] = ref)}
          className={`generator-nav-bar__list-item ${currentNavTab === nav.key ? 'active' : ''}`}
          onClick={() => {
            setCurrentNavTab(nav.key);
            if (nav.method) {
              nav.method();
            }
          }}
          onKeyDown={(event) => handleKeyDown(event, index)}
          tabIndex={0}
        >
          {t(nav.key)}
        </div>
      );
    });
  }, [navList]);

  useEffect(() => {
    const currentIndex = navList.findIndex((nav) => nav.key === currentNavTab);
    if (currentIndex !== -1) {
      refs.current[currentIndex].focus();
    }
  }, [currentNavTab, navList]);

  return (
    <div className="generator-nav-bar__wrap">
      <div className="generator-nav-bar__content">
        <div className="generator-nav-bar__list">{renderNavList()}</div>
      </div>
    </div>
  );
};

export default GeneratorNavBar;
