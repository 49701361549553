const initColApi = {
  empInfo: {
    api: {},
    colApi: {},
  },
  taskList: {
    api: {},
    colApi: {},
  },
  dashboard: {
    api: {},
    colApi: {},
  },
  empTable: {
    api: {},
    colApi: {},
  },
  hrPerWeek: {
    api: {},
    colApi: {},
  },
  summary: {
    api: {},
    colApi: {},
  },
};

const action = {
  SET_IS_AVAILABLE: 'SET_IS_AVAILABLE',
  SET_EMPLOYEE_LIST: 'SET_EMPLOYEE_LIST',
  SET_VAKANT_LIST: 'SET_VAKANT_LIST',
  SET_AML_INFORMATION: 'SET_AML_INFORMATION',
  SET_HOLIDAY_LIST: 'SET_HOLIDAY_LIST',
  SET_SURVEY_DATA: 'SET_SURVEY_DATA',
  SET_FILTER_ROLE_LIST: 'SET_FILTER_ROLE_LIST',
  SET_EMPLOYEE_DATA: 'SET_EMPLOYEE_DATA',
  SET_PROGRESS_DATA: 'SET_PROGRESS_DATA',
  SET_IS_PLAN_IN_PROGRESS: 'SET_IS_PLAN_IN_PROGRESS',
  SET_SUNDAY_AND_HOLYDAY_RANGE: 'SET_SUNDAY_AND_HOLYDAY_RANGE',
  SET_COL_SORT: 'SET_COL_SORT',
  SET_SORT_TYPE: 'SET_SORT_TYPE',
  SET_DASHBOARD_DATA: 'SET_DASHBOARD_DATA',
  SET_SURVEY_DASHBOARD_DATA: 'SET_SURVEY_DASHBOARD_DATA',
  SET_ORIGIN_DATA: 'SET_ORIGIN_DATA',
  SET_TASK_LIST_REF: 'SET_TASK_LIST_REF',
  SET_ROTATION_COST: 'SET_ROTATION_COST',
  SET_CLEAN_DATA: 'SET_CLEAN_DATA',
  SET_CLEAN_ALERT: 'SET_CLEAN_ALERT',

  //TABLE REF AND API
  SET_GRID_COLUMN_API_EMP_INFO: 'SET_GRID_COLUMN_API_EMP_INFO',
  SET_GRID_COLUMN_API_TASK_LIST: 'SET_GRID_COLUMN_API_TASK_LIST',
  SET_GRID_COLUMN_API_DASHBOARD: 'SET_GRID_COLUMN_API_DASHBOARD',
  SET_GRID_COLUMN_API_HR_PER_WEEK: 'SET_GRID_COLUMN_API_HR_PER_WEEK',
  SET_PLAN_SELECTED: 'SET_PLAN_SELECTED',
  SET_SUMMARY_SELECTED_VIEW: 'SET_SUMMARY_SELECTED_VIEW',
};

export default { initColApi, action };
