import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import './MultipleStaffTask.scss';
import { PlusBrownIcon } from 'assets/img';

const PaidBreakTypeBox = ({ paidBreakType, handleOnChange }) => {
  return (
    <select name="paid_break_type" id="" onChange={handleOnChange}>
      {paidBreakType.map((item) => {
        return (
          <option key={item.key} value={item.id}>
            {t(item.key)}
          </option>
        );
      })}
    </select>
  );
};

const TaskRow = ({
  data,
  setBulkData,
  index,
  renderTaskTypeOption,
  renderTaskGroupOption,
  paidBreakType,
}) => {
  const [taskData, setTaskData] = useState({});
  useEffect(() => {
    setTaskData(data);
  }, []);
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    if (name === 'is_paid_break') {
      setTaskData((prev) => ({ ...prev, [name]: !prev[name] }));
      setBulkData((prev) => {
        const updateList = [...prev];
        updateList[index] = { ...updateList[index], [name]: !data.is_paid_break };
        return updateList;
      });
    } else {
      setTaskData((prev) => ({ ...prev, [name]: value }));
      setBulkData((prev) => {
        const updateList = [...prev];
        updateList[index] = { ...updateList[index], [name]: value };
        return updateList;
      });
    }
  };
  return (
    <tr>
      <td>
        <input
          className="single-staff-task__item-input"
          onChange={handleOnChange}
          name="task_name"
        />
      </td>
      <td>
        <input type="time" name="from_time" id="" onChange={handleOnChange} />
      </td>
      <td>
        <input type="time" name="to_time" id="" onChange={handleOnChange} />
      </td>
      <td>
        <input type="time" name="pause" id="" onChange={handleOnChange} defaultValue={'00:30'} />
      </td>
      <td>
        <input
          type="checkbox"
          name="is_paid_break"
          id=""
          // onChange={setPaidBreak(!data.is_paid_break)}
          onChange={handleOnChange}
          value={data.is_paid_break}
        />
      </td>
      <td>
        {data.is_paid_break ? (
          <PaidBreakTypeBox paidBreakType={paidBreakType} handleOnChange={handleOnChange} />
        ) : null}
      </td>
      <td>
        <select name="type_id" id="" onChange={handleOnChange}>
          {/* //   value={taskWillCreate.type_id}> */}
          <option value={''}>{t('choose-type')}</option>
          {renderTaskTypeOption()}
        </select>
      </td>
      {/* <td>
        <select
          name="group_id"
          id=""
          //   value={taskWillCreate.group_id}
          onChange={handleOnChange}
        >
          <option value={''}>{t('choose-group')}</option>
          {renderTaskGroupOption()}
        </select>
      </td> */}
      <td>
        <input
          className="single-staff-task__item-input"
          onChange={handleOnChange}
          name="description"
        />
      </td>
    </tr>
  );
};

const MultipleStaffTask = ({
  renderTaskTypeOption,
  renderTaskGroupOption,
  bulkData,
  setBulkData,
  unitSelected,
  paidBreakType,
}) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'; // Prevent scrolling in the background

    return () => {
      document.body.style.overflow = 'auto'; // Re-enable scrolling when the component unmounts
    };
  }, []);

  const handleAddMore = () => {
    setBulkData((prev) => [
      ...prev,
      {
        unit_code: unitSelected,
        from_time: '',
        to_time: '',
        pause: '00:30',
        is_paid_break: false,
      },
    ]);
  };

  return (
    <div className="multiple-staff-task__wrap">
      <div className="multiple-staff-task__content">
        <table className="multiple-staff-task__content-table">
          <thead>
            <th>{t('task-name')}</th>
            <th>{t('from')}</th>
            <th>{t('to')}</th>
            <th>{t('pause')}</th>
            <th>{t('paid-break')}?</th>
            <th>{t('paid-breaks-apply')}?</th>
            <th>{t('type')}</th>
            {/* <th>{t('group')}</th> */}
            <th>{t('description')}</th>
          </thead>
          <tbody>
            {bulkData.map((data, index) => {
              return (
                <TaskRow
                  key={`bulk-staff-code-${index}`}
                  data={data}
                  index={index}
                  renderTaskGroupOption={renderTaskGroupOption}
                  renderTaskTypeOption={renderTaskTypeOption}
                  setBulkData={setBulkData}
                  paidBreakType={paidBreakType}
                />
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="add-more" onClick={handleAddMore}>
        <img src={PlusBrownIcon} width={20} height={20} alt="" /> {t('add-more')}
      </div>
    </div>
  );
};

export default MultipleStaffTask;
