const cellStyle = (_) => {
  const cellStyle = { textAlign: 'center' };

  return cellStyle;
};

const GROUP_DISPLAY_TYPE = 'groupRows';
const EDITABLE_COL = ['email', 'phone_number', 'job_percentage', 'role', 'group'];
const STARTING_CELL_EDIT = 'email';
const DEFAULT_CELL_VALUE = '-';
const NORWAY_PHONE_REGEX = /^(\+47)[0-9]{7,10}$/;

export {
  cellStyle,
  EDITABLE_COL,
  STARTING_CELL_EDIT,
  GROUP_DISPLAY_TYPE,
  NORWAY_PHONE_REGEX,
  DEFAULT_CELL_VALUE,
};
