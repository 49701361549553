import CustomTooltip from 'components/CustomTooltip/CustomTooltip';
import { useClickOutside } from 'helper/useClickOutSide';
import React, { useEffect, useRef, useState } from 'react';
import StatusTable from './StatusTable';
import { CheckOutlined, CloseOutlined, WarningOutlined } from '@ant-design/icons';

const StatusIcon = ({ level, isActive, check }) => {
  switch (level) {
    case 'survey':
      if (isActive) {
        if (check) {
          return <WarningOutlined />;
        }
        return <CheckOutlined />;
      }

      return '-';
    case 'hard':
      if (check) return <CloseOutlined />;
      return <CheckOutlined />;

    default:
      if (check) return <WarningOutlined />;
      return <CheckOutlined />;
  }
};

const Status = ({ check, dataDefault, employeeId, type, available, level, isActive }) => {
  const [activeTooltip, setActiveTooltip] = useState(false);
  const statusTableRef = useRef(null);
  useClickOutside(statusTableRef, () => {
    if (activeTooltip) setActiveTooltip(false);
  });
  const data = dataDefault?.filter(
    (shift) =>
      +shift.employeeId === +employeeId ||
      shift.employeeId === employeeId ||
      shift.employeeID === employeeId,
  );

  return (
    <div ref={statusTableRef}>
      <div
        className="status__wrap"
        onClick={() => {
          setActiveTooltip(!activeTooltip);
        }}
      >
        <div
          className={`status ${level === 'survey' && !isActive ? 'not-active' : ''} ${
            check ? `${level === 'hard' ? 'wrong' : 'warning'}` : ''
          }`}
        >
          <div className="">
            <StatusIcon level={level} isActive={isActive} check={check} />
          </div>

          {check && activeTooltip && data.length ? (
            <CustomTooltip>
              <StatusTable statusData={data} type={type} />
            </CustomTooltip>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default Status;
