import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import _nav from 'navigators/_nav';
import { useTranslation } from 'react-i18next';

import { EyeOutlined } from '@ant-design/icons';

import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectGlobal } from 'redux/global/globalSlice';
import './DefaultHeader.scss';
import { selectAuth } from 'redux/auth/authSlice';
import chironLogo from 'assets/logo/new-chiron-logo.png';

import ChangePasswordForm from 'components/ChangePasswordForm/ChangePasswordForm';
import DepartmentSelection from 'components/DepartmentSelection/DepartmentSelection';
import UserProfile from './UserProfile';
import { SettingAdminIcon } from 'assets/img';
import SelectEnvironment from './SelectEnvironment/SelectEnvironment';

export default function DefaultHeader() {
  const { role } = useSelector(selectAuth);

  const { language } = useSelector(selectGlobal);
  const { isEditabled } = useSelector(selectAuth);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [activeChangePassWord, setActiveChangePassword] = useState(false);
  const renderNavLink = () => {
    return _nav.map((nav) => {
      if (nav.roles.includes(role.toLowerCase()))
        return (
          <NavLink
            key={nav.key}
            to={nav.to}
            className={`nav-item ${pathname === nav.path && 'active'} ${
              pathname === '/' && nav.to == '/dashboard' ? 'active' : ''
            }`}
          >
            {t(nav.name)}
          </NavLink>
        );
    });
  };
  useEffect(() => {
    i18next.changeLanguage(language);
  }, [language]);
  return (
    <div className="header__wrap">
      {!isEditabled ? (
        <div
          className=""
          style={{
            width: '100%',
            height: '14px',
            backgroundColor: '#FFCDC0',
            display: 'flex',
            alignItems: 'center',
            padding: '6px 24px',
            color: '#000',
            fontWeight: 700,
            gap: '10px',
          }}
        >
          <EyeOutlined /> {t('view-only')}
        </div>
      ) : null}
      <div className="header__content">
        <div className="header__logo">
          <div
            className="small-logo"
            style={{
              backgroundImage: `url(${chironLogo})`,
              width: '75px',
              height: '80px',
              marginRight: '20px',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          ></div>
          <div className="environment-selection">
            {process.env.REACT_APP_NODE_ENV !== 'production' ? <SelectEnvironment /> : null}
            <DepartmentSelection />
          </div>
        </div>
        <div className="header__middle">{renderNavLink()}</div>
        <div className="header__right">
          {role === 'admin' ? (
            <img className="admin-icon" src={SettingAdminIcon} onClick={() => navigate('/admin')} />
          ) : null}
          <UserProfile setActiveChangePassword={setActiveChangePassword} />
          {activeChangePassWord ? (
            <ChangePasswordForm setActive={setActiveChangePassword} active={activeChangePassWord} />
          ) : null}
        </div>
      </div>
    </div>
  );
}
