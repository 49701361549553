/** libs */
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';

import api from 'axios/axiosClient';
import { Toast } from 'helper/alert';

import { useLanguge } from 'Hooks/useLangugue';

/** store */
import { hideLoading, showLoading } from '_components/Loading/slice';
import { isSuccessResponse, typeResponse } from 'helper/response';

const PREFIX = 'calendar-plan';

const surveyAPI = () => {
  const { t } = useLanguge();
  const dispatch = useDispatch();

  return {
    getSurveyTopic: () => {
      dispatch(showLoading());
      return api
        .post(`${PREFIX}/survey-topic`)
        .then((result) => {
          return result;
        })
        .catch((err) => {
          return [];
        })
        .finally(() => {
          dispatch(hideLoading());
        });
    },

    getSurveyQuestion: () => {
      dispatch(showLoading());
      return api
        .post(`${PREFIX}/survey-question`)
        .then((result) => {
          return result;
        })
        .catch((err) => {
          return [];
        })
        .finally(() => {
          dispatch(hideLoading());
        });
    },

    getSurveyExtendQuestion: () => {
      dispatch(showLoading());
      return api
        .post(`${PREFIX}/survey/expaned-question`)
        .then((result) => {
          return result;
        })
        .catch((err) => {
          return [];
        })
        .finally(() => {
          dispatch(hideLoading());
        });
    },

    sendSurvey: async ({ topicId, phone, email }) => {
      return api
        .post(`/send-survey`, {
          topicId,
          phone,
          email,
        })
        .then(() => {
          Toast({
            icon: 'success',
            title: `${t('send')} ${'success'}`,
          });
        })
        .catch(() => {
          Toast({
            icon: 'error',
            title: `${t('send')} ${'fail'}`,
          });
        });
    },
  };
};

export default surveyAPI;
