import { t } from 'i18next';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { openModal } from '_components/Modal/slice';
import { ADD_LAYER, ADD_TASK_CODE } from '_components/CarlendarPlan/EmployeeV2/constants/modal';

import {
  ArrowDownIcon,
  CheckboxCheckIcon,
  CheckboxUncheckIcon,
  ChevronDownBrownIcon,
  DownArrow,
  PlusBrownIcon,
  PredictIcon,
  PredictWhiteIcon,
} from 'assets/img';
import { Toast } from 'helper/alert';
import { selectAuth } from 'redux/auth/authSlice';

const TableHeader = ({
  taskList,
  tableData,
  showSummary,
  setShowSummary,
  showStatistics,
  setShowStatistics,
  setPredictPercentData,
  showAbsencePrediction,
  setShowAbsencePrediction,
}) => {
  const [isActiveAddGroup, setIsActiveAddGroup] = useState(false);
  const { isEditabled } = useSelector(selectAuth);

  const dispatch = useDispatch();
  const handleAddTaskCode = () => {
    if (!isEditabled) return;
    dispatch(openModal({ name: ADD_TASK_CODE }));
  };
  const handleAddLayer = () => {
    if (!isEditabled) return;

    dispatch(openModal({ name: ADD_LAYER }));
  };

  const handleShowSummary = () => {
    if (tableData.length === 0) {
      Toast({
        icon: 'error',
        title: t('please-add-code-first'),
      });
      return;
    }
    setShowSummary(!showSummary);
  };

  const handleShowStatistics = () => {
    if (tableData.length === 0) {
      Toast({
        icon: 'error',
        title: t('please-add-code-first'),
      });
      return;
    }
    setShowStatistics(!showStatistics);
  };

  const handleShowAbsencePrediction = () => {
    if (tableData.length === 0) {
      Toast({
        icon: 'error',
        title: t('please-add-code-first'),
      });
      return;
    }
    const predictTaskList = taskList.filter((item) => item.taskTypeValue < 0);
    const predictPercentInit = {};
    if (!showAbsencePrediction) {
      predictTaskList.forEach((item) => {
        predictPercentInit[item.taskID] = 0;
      });

      setPredictPercentData(predictPercentInit);
    }
    setShowAbsencePrediction(!showAbsencePrediction);
  };

  return (
    <div className="plan-detail__action">
      <button className="add-btn" onClick={() => setIsActiveAddGroup(true)}>
        {t('add')}
        <img src={DownArrow} />
        <div className={`add-group ${isActiveAddGroup ? '' : 'hiden'}`}>
          <span onClick={handleAddTaskCode}>{t('task-name')}</span>
          <span onClick={handleAddLayer}>{t('layer')}</span>
        </div>
      </button>

      <div className="right">
        <p onClick={handleShowSummary}>
          <img src={showSummary ? CheckboxCheckIcon : CheckboxUncheckIcon} />
          {t('view-summary')}
        </p>
        <p onClick={handleShowStatistics}>
          {t('view-statistics')}
          <img src={ChevronDownBrownIcon} />
        </p>
        <p onClick={handleShowAbsencePrediction} className={showAbsencePrediction && 'predict-btn'}>
          <img src={showAbsencePrediction ? PredictWhiteIcon : PredictIcon} />
          {showAbsencePrediction
            ? `${t('remote')} ${t('prediction')}`
            : t('add-absence-prediction')}
        </p>
      </div>
    </div>
  );
};

export default TableHeader;
