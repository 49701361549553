import {
  MAXIMUM_WEEK_WORKING_HOUR,
  MULTIPLE_CHOICE,
  DAY_OFF_TIME,
  maxHourWorking,
  offShiftReg,
  surveyQuestionKey,
  weekendRottionSpecialReg,
  EVENING_SHIFT_VALUE,
  DAY_SHIFT_VALUE,
  NIGHT_SHIFT_VALUE,
} from 'constant';
import { getMinusPaidTimeBreak } from 'helper/calculatePaidBreak';
import { enumerateDaysBetweenDates } from 'helper/enumerateDaysBetweenDates';
import moment from 'moment';
const getStartWeekendRotation = (empData) => {
  const empTaskList = empData.task_arr;
  if (!empTaskList) {
    return 0;
  }
  const listWorkingDate = Object.keys(empTaskList).filter((date) => {
    const dateData = empTaskList[date];
    return !offShiftReg.test(dateData.taskName);
  });
  const firstWeekend = listWorkingDate.find((date) => moment(date).weekday() === 6);
  return moment(firstWeekend).startOf('W').week();
};
const calculateTotalValidHour = (
  dateOff = [],
  workingDayRangeSurvey,
  fromDate,
  endDate,
  leaveWithoutPayDate,
  totalHrsPerWeek,
) => {
  const maximumHrPerWeek =
    totalHrsPerWeek && totalHrsPerWeek > 0 ? totalHrsPerWeek : MAXIMUM_WEEK_WORKING_HOUR;
  const workingDayRange = workingDayRangeSurvey?.filter((day) => {
    return (
      moment(day).isSameOrAfter(moment(fromDate)) && moment(day).isSameOrBefore(moment(endDate))
    );
  });
  let numDateOff =
    dateOff
      ?.filter((day) => {
        if (leaveWithoutPayDate && leaveWithoutPayDate.length) {
          return leaveWithoutPayDate.includes(day);
        } else {
          return true;
        }
      })
      ?.filter((day) => {
        return (
          moment(day).isSameOrAfter(moment(fromDate)) && moment(day).isSameOrBefore(moment(endDate))
        );
      })
      .filter((date) => {
        const weekdDay = moment(date).weekday();

        return weekdDay !== 0;
      }).length || 0;
  let numWorkingDay =
    enumerateDaysBetweenDates(fromDate, endDate).filter((date) => {
      const weekdDay = moment(date).weekday();

      return weekdDay !== 6 && weekdDay !== 0;
    }).length || 0;

  if (!workingDayRange || workingDayRange.length === 0) {
    return numWorkingDay * DAY_OFF_TIME - numDateOff * (maximumHrPerWeek / 6);
  }
  const dateAvaliable = workingDayRange?.filter(
    (day) =>
      new Date(day).valueOf() === new Date(fromDate).valueOf() ||
      new Date(day).valueOf() === new Date(endDate).valueOf() ||
      moment(day).isBetween(moment(fromDate), moment(endDate)),
  );
  const listMoments = dateAvaliable.map((date) => moment(date));
  const minDate = moment.min(listMoments);
  const maxDate = moment.max(listMoments);

  numDateOff =
    dateOff
      ?.filter((date) => {
        return (
          moment(date).isSameOrAfter(moment(minDate)) &&
          moment(date).isSameOrBefore(moment(maxDate))
        );
      })
      .filter((date) => {
        const weekdDay = moment(date).weekday();

        return weekdDay !== 0;
      }).length || 0;

  numWorkingDay =
    enumerateDaysBetweenDates(minDate.format('YYYY-MM-DD'), maxDate.format('YYYY-MM-DD')).filter(
      (date) => {
        const weekdDay = moment(date).weekday();

        return weekdDay !== 6 && weekdDay !== 0;
      },
    ).length || 0;

  return numWorkingDay * DAY_OFF_TIME - numDateOff * (maximumHrPerWeek / 6);
};

const getShiftByDate = (date, workingDay, taskAssgined, taskArr) => {
  if (!workingDay.includes(date)) {
    return null;
  }

  const shift = taskAssgined[date][0];
  if (!shift) {
    return null;
  }
  const taskFound = taskArr.find((task) => +task.taskId === +shift.taskId);
  return taskFound;
};

const isWorking = (date, workingDay, taskAssgined, taskArr) => {
  const task = getShiftByDate(date, workingDay, taskAssgined, taskArr);
  if (!task || offShiftReg.test(task.taskName)) {
    return false;
  }
  return true;
};
const isWorkingLongShift = (date, workingDay, taskAssgined, taskArr) => {
  const task = getShiftByDate(date, workingDay, taskAssgined, taskArr);
  if (!task || offShiftReg.test(task.taskName)) {
    return false;
  }
  if (+task.taskType === 3) return true;
  return false;
};

export const calculateHardAndSoftRuleData = (
  element,
  taskArr,
  amlInformation,
  publicHoliday,
  fromDate,
  endDate,
) => {
  let result = {
    checkRole: [],
    checkingWorkingInRow: [],
    checkTimeOffBetweenShift: [],
    checkWorkingOver54Hours: [],
    listWorkingMoreThanThreeWeekend: [],
    checkHourOffPerWeek: [],
    listCheckingF1: [],
    checkingF1Over50: [],
    checkingF2Over50: [],
    checkingWorkingLongWeekend: [],
    checkingMore3Weekend: [],
    checkingOffWeekendBeforeVacation: [],
    checkingDagShiftIfNotWorkingWeekend: [],
    off2DayAfterNightShift: [],
  };
  if (!element || !taskArr) {
    return result;
  }
  const {
    employee_id,
    employee_unassigned_uuid,
    name,
    jobPercentage,
    role,
    roleId,
    dayOff,
    roleRank,
    hourAssigned,
    task_arr: taskAssgined,
    workingDayRange,
    employeeId: employeeIdDefault,
    splitGroup,
    splitHours,
    groupName,
    leaveWithoutPayDate,
    emp_id_number,
    totalHrsPerWeek,
  } = element;

  const employeeId = employee_id || employee_unassigned_uuid || employeeIdDefault;
  let counsecutive = 0;
  let counsecutiveSunday = 0;
  let hoursWeek = 0;
  let hoursOffWeek = 0;
  let numWorkingInWeek = 0;
  let numWorkingHoliday = 0;
  let numWorkingSunday = 0;
  let numShiftWithExactRole = 0;
  let allocatedHours = 0;
  let weekendHours = 0;
  let dayShift = 0;
  let lateShift = 0;
  let nightShift = 0;
  let longShift = 0;
  let otherShift = 0;
  const listHoliday = [];
  const setHolidayAndSun = new Set();
  const sundaySet = new Set();
  const workingDay = Object.keys(taskAssgined).filter((item) => {
    return (
      moment(item).isSameOrAfter(moment(fromDate).add(-1, 'd')) &&
      moment(item).isSameOrBefore(moment(endDate))
    );
  });

  const listWeek = new Set();
  workingDay.forEach((item) => {
    const shift = taskAssgined[item][0];
    if (shift) {
      const taskFound = taskArr.find((task) => +task.taskId === +shift.taskId);
      listWeek.add(`${moment(item).startOf('W').week()}|${moment(item).startOf('W').year()}`);
      if (taskFound) {
        if (moment(item).weekday() === 0 && !offShiftReg.test(taskFound.taskName)) {
          setHolidayAndSun.add(item);
          sundaySet.add(item);
        }
      }
    }
  });
  const listSunday = Array.from(sundaySet);
  let listUniqueWeek = Array.from(listWeek).map((item) => {
    const week = +item.split('|')[0];
    const year = +item.split('|')[1];
    return {
      week,
      year,
      isHaveOffShift: false,
    };
  });

  for (const holidayIndex in publicHoliday) {
    //Check off before holidays
    const holiday = publicHoliday[holidayIndex];
    const lastSunday = moment(holiday).endOf('W').add(-1, 'week').format('YYYY-MM-DD');
    const lastSaturday = moment(lastSunday).add(-1, 'd').format('YYYY-MM-DD');
    const isWorkingHoliday = isWorking(holiday, workingDay, taskAssgined, taskArr);
    const isWorkingSaturday = isWorking(lastSaturday, workingDay, taskAssgined, taskArr);
    const isWorkingSunday = isWorking(lastSunday, workingDay, taskAssgined, taskArr);
    if (isWorkingHoliday) {
      listHoliday.push(holiday);
      setHolidayAndSun.add(holiday);

      if (isWorkingSaturday || isWorkingSunday) {
        const isExist = result.checkingOffWeekendBeforeVacation.findIndex(
          (item) =>
            item.employeeId === employeeId &&
            (item.date === lastSaturday || item.date === lastSunday),
        );
        if (isExist === -1) {
          result.checkingOffWeekendBeforeVacation.push({
            employeeId,
            date: isWorkingSaturday ? lastSaturday : lastSunday,
          });
        }
      }
    }
  }

  const numHolidayAndSun = Array.from(setHolidayAndSun);
  for (const sunDayIndex in listSunday) {
    const sunday = listSunday[sunDayIndex];
    const prevSun = listSunday[sunDayIndex - 1];
    const shift = taskAssgined[sunday][0];

    if (offShiftReg.test(shift.taskName)) {
      counsecutiveSunday = 0;
      continue;
    }
    numWorkingSunday++;
    if (prevSun) {
      const week = moment(sunday).startOf('W').week();
      const prevWeek = moment(prevSun).startOf('W').week();

      if (week - prevWeek === 1) {
        counsecutiveSunday++;
        if (counsecutiveSunday > 3) {
          result.listWorkingMoreThanThreeWeekend.push({ employeeId, date: sunday });
        }
      } else {
        counsecutiveSunday = 0;
      }
    }
  }

  for (const dateIndex in workingDay) {
    const date = workingDay[dateIndex];
    const prevDate = moment(date).add(-1, 'd').format('YYYY-MM-DD');
    const nextDate = moment(date).add(1, 'd').format('YYYY-MM-DD');
    const nextSecondDate = moment(date).add(2, 'd').format('YYYY-MM-DD');
    const nextMonday = moment(date).startOf('W').add(1, 'week').format('YYYY-MM-DD');
    const nextTuesday = moment(nextMonday).add(1, 'd').format('YYYY-MM-DD');
    const sunday = moment(date).endOf('W').format('YYYY-MM-DD');
    const saturday = moment(date).endOf('W').add(-1, 'd').format('YYYY-MM-DD');
    const friday = moment(date).endOf('W').add(-2, 'd').format('YYYY-MM-DD');
    const thursday = moment(date).endOf('W').add(-3, 'd').format('YYYY-MM-DD');
    const wednesday = moment(date).endOf('W').add(-4, 'd').format('YYYY-MM-DD');
    const tuesday = moment(date).endOf('W').add(-5, 'd').format('YYYY-MM-DD');
    const week = moment(date).startOf('W').weeks();
    const isWorkingNextMon = isWorking(nextMonday, workingDay, taskAssgined, taskArr);
    const isWorkingNextTue = isWorking(nextTuesday, workingDay, taskAssgined, taskArr);
    const isWorkingTuesday = isWorking(tuesday, workingDay, taskAssgined, taskArr);
    const isWorkingWednesday = isWorking(wednesday, workingDay, taskAssgined, taskArr);
    const isWorkingThursday = isWorking(thursday, workingDay, taskAssgined, taskArr);
    const isWorkingFriday = isWorking(friday, workingDay, taskAssgined, taskArr);
    const isWorkingSaturday = isWorking(saturday, workingDay, taskAssgined, taskArr);
    const isWorkingSunday = isWorking(sunday, workingDay, taskAssgined, taskArr);
    const isWorkingLongShiftSaturday = isWorkingLongShift(
      saturday,
      workingDay,
      taskAssgined,
      taskArr,
    );
    const isWorkingLongShiftSunday = isWorkingLongShift(sunday, workingDay, taskAssgined, taskArr);

    const fridayShift = getShiftByDate(friday, workingDay, taskAssgined, taskArr);
    const shift = taskAssgined[date][0];
    if (!shift) {
      continue;
    }
    const taskFound = taskArr.find((task) => +task.taskId === +shift.taskId);
    let prevDateweek = moment(prevDate).startOf('W').weeks();

    let prevShift = null;
    let prevTaskFound = null;
    if (offShiftReg.test(shift.taskName)) {
      counsecutive = 0;

      const weekIndex = listUniqueWeek.findIndex((item) => +item.week === +week);
      if (weekIndex !== -1) {
        listUniqueWeek[weekIndex].isHaveOffShift = true;
      }
    }

    if (isWorkingLongShiftSaturday || isWorkingLongShiftSunday) {
      if (isWorkingFriday || isWorkingNextMon) {
        const isExist = result.checkingWorkingLongWeekend.findIndex(
          (item) =>
            item.employeeId === employeeId && (item.date === friday || item.date === nextMonday),
        );
        if (isExist === -1) {
          result.checkingWorkingLongWeekend.push({
            employeeId,
            date: isWorkingFriday ? friday : nextMonday,
          });
        }
      }
    }

    if (isWorkingSaturday && isWorkingSunday) {
      if (isWorkingThursday && isWorkingFriday) {
        const isExist = result.checkingMore3Weekend.findIndex(
          (item) => item.employeeId === employeeId && item.date === thursday,
        );
        if (isExist === -1) {
          result.checkingMore3Weekend.push({
            employeeId,
            date: thursday,
          });
        }
      }
      if (isWorkingNextMon && isWorkingNextTue) {
        const isExist = result.checkingMore3Weekend.findIndex(
          (item) => item.employeeId === employeeId && item.date === nextTuesday,
        );
        if (isExist === -1) {
          result.checkingMore3Weekend.push({
            employeeId,
            date: nextTuesday,
          });
        }
      }
    }

    if (isWorkingSaturday || isWorkingSunday) {
      if (jobPercentage > 50) {
        if (!isWorkingThursday) {
          if (fridayShift && fridayShift.taskType === 0) {
            const isExist = result.checkingF1Over50.findIndex(
              (item) => item.employeeId === employeeId && item.date === friday,
            );
            if (isExist === -1) {
              result.checkingF1Over50.push({ employeeId, date: friday });
            }
          }
          if (isWorkingNextMon && isWorkingNextTue) {
            const isExist = result.checkingF2Over50.findIndex(
              (item) =>
                item.employeeId === employeeId &&
                (item.date === nextMonday || item.date === nextTuesday),
            );
            if (isExist === -1) {
              result.checkingF2Over50.push({
                employeeId,
                date: isWorkingNextMon ? nextMonday : nextTuesday,
              });
            }
          }
        }
      }
    } else {
      //Checking not working day shift if not working weekend
      if (fridayShift && +fridayShift.taskType !== 0) {
        const isExist = result.checkingDagShiftIfNotWorkingWeekend.findIndex(
          (item) => item.employeeId === employeeId && item.date === friday,
        );
        if (isExist === -1) {
          result.checkingDagShiftIfNotWorkingWeekend.push({
            employeeId,
            date: friday,
          });
        }
      }
    }

    if (publicHoliday.includes(date)) {
      numWorkingHoliday++;
    }

    if (taskFound && +taskFound.taskType === 2) {
      const nextShift = getShiftByDate(nextDate, workingDay, taskAssgined, taskArr);
      const isWorkingNextSecondDate = isWorking(nextSecondDate, workingDay, taskAssgined, taskArr);
      if (nextShift && nextShift.taskType !== 2) {
        const isExist = result.off2DayAfterNightShift.findIndex(
          (item) => item.employeeId === employeeId && item.date === nextDate,
        );
        if (isExist === -1) {
          result.off2DayAfterNightShift.push({ employeeId, date: nextDate });
        }
      }
      if (!nextShift && isWorkingNextSecondDate) {
        const isExist = result.off2DayAfterNightShift.findIndex(
          (item) => item.employeeId === employeeId && item.date === nextSecondDate,
        );
        if (isExist === -1) {
          result.off2DayAfterNightShift.push({ employeeId, date: nextSecondDate });
        }
      }
    }

    if (taskAssgined[prevDate]) {
      //Checking consecutive shifts

      prevShift = taskAssgined[prevDate][0];
      if (prevShift) {
        prevTaskFound = taskArr.find(
          (task) => +task.taskId === +prevShift.taskId && !offShiftReg.test(task.taskName),
        );
      }

      if (prevTaskFound) {
        counsecutive++;
      }
      if (!prevTaskFound || offShiftReg.test(taskFound?.taskName)) {
        counsecutive = 0;
      }

      if (counsecutive >= +amlInformation.maxConsecutiveShifts) {
        const isExist = result.checkingWorkingInRow.findIndex(
          (item) => item.employeeId === employeeId && item.date === date,
        );
        if (isExist === -1) {
          result.checkingWorkingInRow.push({ employeeId, date });
        }
      }

      //Check time off between 2 shift
      if (prevTaskFound && taskFound && !offShiftReg.test(taskFound.taskName)) {
        const endPrevShift =
          +prevTaskFound.taskType === 2
            ? moment(`${prevDate} ${prevTaskFound.endTime}`, 'YYYY-MM-DD HH:mm:ss').add(1, 'd')
            : moment(`${prevDate} ${prevTaskFound.endTime}`, 'YYYY-MM-DD HH:mm:ss');
        const startShift = moment(`${date} ${taskFound.fromTime}`, 'YYYY-MM-DD  HH:mm:ss');
        const diffTime = startShift.diff(endPrevShift, 'second') / 60 / 60;
        const prevTypeShift = prevTaskFound.taskType;
        const taskFoundType = taskFound.taskType;

        let minimumTimeOffTwoShift = 9;
        if (prevTypeShift === EVENING_SHIFT_VALUE && taskFoundType === DAY_SHIFT_VALUE) {
          minimumTimeOffTwoShift = amlInformation.minimumTimeOffTwoShiftEveningAndDay;
        }
        if (prevTypeShift === DAY_SHIFT_VALUE && taskFoundType === NIGHT_SHIFT_VALUE) {
          minimumTimeOffTwoShift = amlInformation.minimumTimeOffTwoShiftDayAndNight;
        }
        if (prevTypeShift === NIGHT_SHIFT_VALUE && taskFoundType === EVENING_SHIFT_VALUE) {
          minimumTimeOffTwoShift = amlInformation.minimumTimeOffTwoShiftNightAndEvening;
        }
        if (Math.abs(diffTime) < minimumTimeOffTwoShift) {
          const isExist = result.checkTimeOffBetweenShift.findIndex(
            (item) => item.employeeId === employeeId && item.date === date,
          );
          if (isExist === -1) {
            result.checkTimeOffBetweenShift.push({ employeeId, date });
          }
        }
      }
    } else {
      counsecutive = 0;
    }
    //Checking Working too much per week
    if (week > prevDateweek) {
      hoursOffWeek += 24 * (7 - numWorkingInWeek);
      if (hoursOffWeek < +amlInformation.minimumHourOffWeek && hoursOffWeek > 0) {
        const isExist = result.checkHourOffPerWeek.findIndex(
          (item) => item.employeeId === employeeId && item.week === prevDateweek,
        );
        if (isExist === -1) {
          result.checkHourOffPerWeek.push({
            employeeId,
            week: prevDateweek,
            year: moment(prevDate).year(),
          });
        }
      }

      if (hoursWeek > +amlInformation.maxWorkingHour) {
        const isExist = result.checkWorkingOver54Hours.findIndex(
          (item) => item.employeeId === employeeId && +item.week === +prevDateweek,
        );
        if (isExist === -1) {
          result.checkWorkingOver54Hours.push({
            employeeId,
            week: prevDateweek,
            year: moment(prevDate).year(),
          });
        }
      }

      hoursWeek = 0;
      hoursOffWeek = 0;
      numWorkingInWeek = 0;
    }
    if (
      dateIndex > 0 &&
      week >
        moment(workingDay[dateIndex - 1])
          .startOf('W')
          .weeks()
    ) {
      hoursWeek = 0;
      hoursOffWeek = 0;
      numWorkingInWeek = 0;
    }

    if (taskFound) {
      if (moment(date).weekday() === 6 || moment(date).weekday() === 0) {
        weekendHours += +taskFound.hourAssigned;
      }
      switch (taskFound.taskType) {
        case 0:
          dayShift++;
          break;
        case 1:
          lateShift++;
          break;
        case 2:
          nightShift++;
          break;
        case 3:
          longShift++;
          break;
        case 4:
          otherShift++;
          break;

        default:
          break;
      }
      const minusPaidBreak = getMinusPaidTimeBreak(taskFound, date, publicHoliday);

      allocatedHours += +taskFound.hourAssigned - minusPaidBreak;
      //Checking Roles
      const taskRole = taskFound.roleId;
      const taskRoleRank = taskFound.roleRank;

      if (+taskRole === +roleId) {
        numShiftWithExactRole++;
      }
      if (taskRole && +taskRoleRank > +roleRank) {
        const isExist = result.checkRole.findIndex(
          (item) => item.employeeId === employeeId && item.date === date,
        );
        if (isExist === -1) {
          result.checkRole.push({ employeeId, date });
        }
      }
    }
  }
  listUniqueWeek.forEach((uniqueWeek) => {
    if (!uniqueWeek.isHaveOffShift) {
      const isExist = result.listCheckingF1.findIndex(
        (item) =>
          item.employeeId === employeeId &&
          item.week === uniqueWeek.week &&
          item.year === uniqueWeek.year,
      );
      if (isExist === -1) {
        result.listCheckingF1.push({
          employeeId,
          week: uniqueWeek.week,
          year: uniqueWeek.year,
        });
      }
    }
  });

  const totalValidHour = calculateTotalValidHour(
    dayOff,
    workingDayRange,
    fromDate,
    endDate,
    leaveWithoutPayDate,
    totalHrsPerWeek,
  );

  let deviation = jobPercentage
    ? allocatedHours - totalValidHour * (+jobPercentage / 100)
    : allocatedHours - totalValidHour;

  const listSplitDeviation = splitHours?.map((item) => {
    const jobPercentageFound = splitGroup?.find((group) => +item.groupID === +group.groupID);
    let jobPercentageSplit = jobPercentageFound
      ? +jobPercentageFound?.jobPercentage
      : jobPercentage;
    const totalValidHourSplit = totalValidHour * (+jobPercentageSplit / 100);
    const hourAssignedSplit = item.hourAssigned;

    const deviationSplit = hourAssignedSplit - totalValidHourSplit;
    return { ...item, deviationSplit };
  });
  const empData = {
    employeeId,
    name,
    emp_id_number,
    hourAssigned: +hourAssigned,
    jobPercentage,
    role,
    roleId,
    numWorkingHoliday,
    numWorkingSunday,
    numShiftWithExactRole,
    allocatedHours,
    dayShift,
    lateShift,
    nightShift,
    longShift,
    otherShift,
    weekendHours,
    dayOff,
    totalShift: workingDay.length,
    workingDayRange,
    deviation: parseFloat(deviation.toFixed(2)),
    splitGroup,
    splitHours,
    groupName,
    listSplitDeviation: listSplitDeviation || [],
    totalValidHour,
  };

  return { ...result, empData };
};

export const calculateSurveyData = (emp, taskArr, surveyData, fromDate, endDate) => {
  if (!emp || !taskArr) {
    return {};
  }

  const { employeeId: employeeID, task_arr: taskAssgined } = emp;
  const workingDay = Object.keys(taskAssgined).filter((item) => {
    return (
      moment(item).isSameOrAfter(moment(fromDate).add(-1, 'd')) &&
      moment(item).isSameOrBefore(moment(endDate))
    );
  });
  const weekSet = new Set();
  const empData = {
    validityPeriods: [],
    vacation: [],
    typeShift: null,
    avgLateShift: null,
    nigthShiftInRow: null,
    longShiftWeekend: null,
    weekendRotation: null,
    extendDateOff: null,
    summerPeriods: null,
    holidayOff: null,
    extenedStartShift: null,
    extenedEndShift: null,
    extenedWeekday: null,
    extenedDateRange: null,
  };
  let numLateShift = 0;
  let numNightShift = 0;
  let numNightShiftInWeek = 0;
  let weekHoursAssgined = 0;
  const surveyParam = {};
  const empSurveyRecord = surveyData.find((item) => item.employeeID === employeeID)?.surveyRecord;
  if (empSurveyRecord) {
    for (const record of empSurveyRecord) {
      const key = record.wishProperty;

      const paramKey = surveyQuestionKey[key];
      if (paramKey) {
        switch (paramKey.type) {
          case MULTIPLE_CHOICE:
            surveyParam[paramKey.key] = record.answer.split(',');
            break;

          default:
            surveyParam[paramKey.key] = record.answer;

            break;
        }
      }
    }
  }

  //Convert categoriesShift from survey
  if (surveyParam.categoriesShift) {
    empData.typeShift = [];
    surveyParam.categoriesShift = surveyParam.categoriesShift.map((item) => {
      switch (item) {
        case 'Day':
          return 0;
        case 'Late':
          return 1;
        case 'Night':
          return 2;
        case 'Combination code (long shift)':
          return 3;

        default:
          return 4;
      }
    });
  }

  //Convert temporaryJob from Survey

  if (surveyParam.temporaryJob) {
    empData.summerPeriods = [];

    surveyParam.temporaryJob = surveyParam.temporaryJob.data.map((item) => {
      const week = +item.en.split(' ')[1];
      return { week, jobPercentage: item.input };
    });
  }
  const empTaskData = emp;

  //Survey param variables
  let workingRangeFrom = null;
  let workingRangeEnd = null;
  let notWorkingRangeFrom = null;
  let notWorkingRangeEnd = null;
  let vacationFrom = null;
  let vacationEnd = null;
  let startTime = null;
  let endTime = null;
  let cannotWorkingWeekdays = null;
  let weekendRotation = null;
  let startWeekendRotation = getStartWeekendRotation(empTaskData);

  if (surveyParam.shiftStart) {
    empData.extenedStartShift = [];
    startTime = surveyParam.shiftStart[0].time;
  }
  if (surveyParam.endShift) {
    empData.extenedEndShift = [];

    endTime = surveyParam.endShift[0].time;
  }

  if (surveyParam.notWorkingRange) {
    empData.extenedDateRange = [];

    const dateRange = surveyParam.notWorkingRange[0];
    if (dateRange.fromDate?.length && dateRange.toDate?.length) {
      notWorkingRangeFrom = moment(dateRange.fromDate).add(-1, 'd');
      notWorkingRangeEnd = moment(dateRange.toDate).add(1, 'd');
    }
  }

  if (surveyParam.validityPeriods) {
    const dateRange = surveyParam.validityPeriods[0];
    if (dateRange?.fromDate?.length && dateRange?.toDate?.length) {
      workingRangeFrom = moment(dateRange.fromDate).add(-1, 'd');
      workingRangeEnd = moment(dateRange.toDate).add(1, 'd');
    }
  }

  if (surveyParam.weekendRotation) {
    empData.weekendRotation = [];

    const rotation = surveyParam.weekendRotation.split('.')[0];
    if (isNaN(Number(rotation))) {
      weekendRotation = rotation;
    } else {
      weekendRotation = +rotation;
    }
  }

  if (surveyParam.vacation) {
    const dateRange = surveyParam.vacation[0];
    if (dateRange.fromDate?.length && dateRange.toDate?.length) {
      vacationFrom = moment(dateRange.fromDate);
      vacationEnd = moment(dateRange.toDate);
    }
  }
  if (surveyParam.weekdayCannotWorking) {
    empData.extenedWeekday = [];
    cannotWorkingWeekdays = surveyParam.weekdayCannotWorking;
  }

  if (surveyParam.extendDayOff) {
    //Check extend day off
    empData.extendDateOff = [];
    surveyParam.extendDayOff = surveyParam.extendDayOff.forEach((item) => {
      const date = moment(item).format('YYYY-MM-DD');
      if (workingDay.includes(date)) {
        if (!empData.extendDateOff) {
          empData.extendDateOff = [{ employeeID, date }];
        } else {
          empData.extendDateOff.push({ employeeID, date });
        }
      }
    });
  }

  //Check holiday off
  if (surveyParam.holidayOff) {
    empData.holidayOff = [];
    surveyParam.holidayOff = surveyParam.holidayOff.dateArr.forEach((item) => {
      const date = moment(item).format('YYYY-MM-DD');
      if (workingDay.includes(date)) {
        if (!empData.holidayOff) {
          empData.holidayOff = [{ employeeID, date }];
        } else {
          empData.holidayOff.push({ employeeID, date });
        }
      }
    });
  }
  for (const dateIndex in workingDay) {
    const date = workingDay[dateIndex];
    const prevDate = moment(date).add(-1, 'd').format('YYYY-MM-DD');
    const sunday = moment(date).endOf('W').format('YYYY-MM-DD');
    const saturday = moment(date).endOf('W').add(-1, 'd').format('YYYY-MM-DD');
    const isWorkingSaturday = isWorking(saturday, workingDay, taskAssgined, taskArr);
    const isWorkingSunday = isWorking(sunday, workingDay, taskAssgined, taskArr);
    const week = moment(date).startOf('W').week();
    const prevWeek = moment(prevDate).startOf('W').week();

    weekSet.add(week);
    const shift = taskAssgined[date][0];
    if (!shift) {
      continue;
    }
    const taskFound = taskArr.find((task) => +task.taskId === +shift.taskId);
    let taskType = 4; // Default is other shift
    if (offShiftReg.test(shift.taskName)) {
      continue;
    }

    if (week > prevWeek) {
      if (surveyParam.nightShiftPerWeek) {
        if (!isWorkingSaturday && !isWorkingSunday) {
          if (numNightShiftInWeek > surveyParam.nightShiftPerWeek) {
            if (!empData.nigthShiftInRow) {
              empData.nigthShiftInRow = [{ employeeID, week: prevWeek }];
            } else {
              empData.nigthShiftInRow.push({ employeeID, week: prevWeek });
            }
          }
        }
      }
      numNightShiftInWeek = 0;
    }
    //Checking Weekend Rotation

    if (taskAssgined[saturday] || taskAssgined[sunday]) {
      const satTaskName = taskAssgined[saturday] && taskAssgined[saturday][0].taskName;
      const sunTaskName = taskAssgined[sunday] && taskAssgined[sunday][0].taskName;
      if (weekendRotation && week > startWeekendRotation) {
        if (weekendRottionSpecialReg.test(weekendRotation)) {
          const execRotation = weekendRottionSpecialReg.exec(weekendRotation);
          const num1 = +execRotation[1];
          const num2 = +execRotation[2];
          if (
            (week - startWeekendRotation) % num1 !== 0 ||
            (week - startWeekendRotation) % (num1 + num2) !== 0
          ) {
            if (!empData.weekendRotation) {
              const weekendRotationResult = [];
              if (satTaskName && !offShiftReg.test(satTaskName)) {
                weekendRotationResult.push({ employeeID, date: saturday });
              }
              if (sunTaskName && !offShiftReg.test(sunTaskName)) {
                weekendRotationResult.push({ employeeID, date: sunday });
              }
              empData.weekendRotation = weekendRotationResult;
            } else {
              const isExist = empData.weekendRotation.find(
                (item) =>
                  item.employeeID === employeeID &&
                  (item.date === sunday || item.date === saturday),
              );
              if (!isExist) {
                if (satTaskName && !offShiftReg.test(satTaskName)) {
                  empData.weekendRotation.push({ employeeID, date: saturday });
                }
                if (sunTaskName && !offShiftReg.test(sunTaskName)) {
                  empData.weekendRotation.push({ employeeID, date: sunday });
                }
              }
            }
          }
        } else {
          if ((week - startWeekendRotation) % weekendRotation !== 0) {
            const isExist = empData.weekendRotation.find(
              (item) =>
                item.employeeID === employeeID && (item.date === sunday || item.date === saturday),
            );
            if (!isExist) {
              if (satTaskName && !offShiftReg.test(satTaskName)) {
                empData.weekendRotation.push({ employeeID, date: saturday });
              }
              if (sunTaskName && !offShiftReg.test(sunTaskName)) {
                empData.weekendRotation.push({ employeeID, date: sunday });
              }
            }
          }
        }
      }
    }

    //Checking Extend cannot working weekday
    if (cannotWorkingWeekdays) {
      if (cannotWorkingWeekdays.includes(moment(date).format('dddd'))) {
        if (!empData.extenedWeekday) {
          empData.extenedWeekday = [{ employeeID, date }];
        } else {
          empData.extenedWeekday.push({ employeeID, date });
        }
      }
    }

    //Checking vacation range
    if (vacationFrom && vacationEnd) {
      if (
        moment(date).isSameOrAfter(vacationFrom.add(-1, 'd')) &&
        moment(date).isSameOrBefore(vacationEnd)
      ) {
        empData.vacation.push({ employeeID, date });
      }
    }

    //Checking validity periods
    if (workingRangeFrom && workingRangeEnd) {
      if (moment(date).isBefore(workingRangeFrom) || moment(date).isAfter(workingRangeEnd)) {
        empData.validityPeriods.push({ employeeID, date });
      }
    }

    //Checking not working range
    if (notWorkingRangeFrom && notWorkingRangeEnd) {
      if (moment(date).isBefore(notWorkingRangeFrom) || !moment(date).isAfter(notWorkingRangeEnd)) {
        empData.extenedDateRange.push({ employeeID, date });
      }
    }
    if (taskFound) {
      weekHoursAssgined += +taskFound.hourAssigned;
      if (week > prevWeek) {
        if (surveyParam.temporaryJob) {
          const temporaryFound = surveyParam.temporaryJob.find((item) => item.week === prevWeek);
          if (temporaryFound) {
            const maxWorkingHour = (maxHourWorking * temporaryFound.jobPercentage) / 100;
            if (weekHoursAssgined > maxWorkingHour) {
              const isExist = empData.summerPeriods.find(
                (item) => item.employeeID === employeeID && item.week === week,
              );
              if (!isExist) {
                if (!empData.summerPeriods) {
                  empData.summerPeriods = [{ employeeID, week }];
                } else {
                  empData.summerPeriods.push({ employeeID, week });
                }
              }
            }
          }
        }

        weekHoursAssgined = 0;
        weekHoursAssgined += +taskFound.hourAssigned;
      }
      if (!offShiftReg.test(taskFound.taskName)) {
        //Check start and end time extend question
        if (startTime) {
          const isBeforeStartShift = moment(taskFound.fromTime, 'HH:mm:ss').isBefore(
            moment(startTime, 'HH:mm'),
          );

          if (isBeforeStartShift) {
            if (!empData.extenedStartShift) {
              empData.extenedStartShift = [{ employeeID, date }];
            } else {
              empData.extenedStartShift.push({ employeeID, date });
            }
          }
        }

        if (endTime) {
          const isAfterEndShift = moment(taskFound.endTime, 'HH:mm:ss').isAfter(
            moment(endTime, 'HH:mm'),
          );
          if (isAfterEndShift) {
            if (!empData.extenedEndShift) {
              empData.extenedEndShift = [{ employeeID, date }];
            } else {
              empData.extenedEndShift.push({ employeeID, date });
            }
          }
        }
      }
      //Check type shift
      taskType = taskFound.taskType;
      switch (taskType) {
        case 1:
          numLateShift++;

          break;

        case 2:
          numNightShift++;
          if (week === prevWeek) {
            numNightShiftInWeek++;
          }

          break;

        default:
          break;
      }

      if (taskType !== 4 && !surveyParam.categoriesShift?.includes(taskType)) {
        if (!empData.typeShift) {
          empData.typeShift = [{ employeeID, date }];
        } else {
          empData.typeShift.push({ employeeID, date });
        }
      }
    }
  }

  if (surveyParam.averageLateShift) {
    empData.avgLateShift = [];
    const averageLateShift = numLateShift / weekSet.size;
    if (averageLateShift > surveyParam.averageLateShift) {
      if (!empData.avgLateShift) {
        empData.avgLateShift = [{ employeeID }];
      } else {
        empData.avgLateShift.push({ employeeID });
      }
    }
  }
  return empData;
};
