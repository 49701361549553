import React from 'react';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';

import { reset } from 'redux/generateTableFilter/slice';
import { saveSession } from 'helper/localStorage';

const NavigateTabs = ({ menuActive, setMenuActive }) => {
  const dispatch = useDispatch();
  const menuSide = [
    'employee',
    'working-environment-act',
    // 'soft-rules',
    'staff-plan',
    'rotation-schedules',
  ];

  const handleChangeTab = (tab) => {
    saveSession('menuActive', tab);
    setMenuActive(tab);
    dispatch(reset());
  };

  return (
    <div className="nav-tabs">
      {menuSide.map((item) => {
        return (
          <div
            onClick={() => handleChangeTab(item)}
            className={`nav-tabs__item ${menuActive === item ? 'active' : ''}`}
            key={item}
          >
            <p>{t(item)}</p>
          </div>
        );
      })}
    </div>
  );
};

export default NavigateTabs;
