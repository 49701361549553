import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
  memo,
  useLayoutEffect,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import { v4 as uuidv4 } from 'uuid';

import { selectGlobal } from 'redux/global/globalSlice';

import listTitleCheck from 'constant/kalendarPlanDashboardHeaderV2';
import Status from 'components/CalendarPlan/GeneratorContent/components/DashboardTable/Status/Status';

import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {
  generateHardAndSoftDashboardData,
  generateSurveytDashboardData,
} from 'helper/generatorDashboard';
import { useTurnusContext } from '../../Turnus/TurnusContext';
import { updateApi } from 'redux/generateTableApi/slice';
import { autoSyncScroll, syncTables } from 'helper/syncTables';
import { useRefDimensions } from 'Hooks/useREfDimensions';

const checkIndex = (array, employeeId) =>
  array
    ? array.findIndex((el) => +el.employeeId === +employeeId || +el.employeeID === +employeeId) > -1
    : false;

const Dashboard = ({
  currentTab,
  onFirstDataRendered,
  holidayAndSundayRange,
  hiddenColDef,
  doesExternalFilterPass,
  getRowId,
  isExternalFilterPresent,
  postSortRows,
}) => {
  const show = ['hard-rule', 'soft-rule', 'emp-wish'].includes(currentTab);
  const allowedTabs = ['soft-rule', 'hard-rule', 'summary', 'emp-wish'];
  const tab = allowedTabs.includes(currentTab) ? currentTab : 'hard-rule';
  // const showSoftRule = tab === 'soft-rule';
  const showHardRule = useMemo(() => tab === 'hard-rule', [tab]);
  const showSoftRule = useMemo(() => tab === 'soft-rule', [tab]);
  const showSurvey = useMemo(() => tab === 'emp-wish', [tab]);

  const { language } = useSelector(selectGlobal);
  const [checkingData, setCheckingData] = useState({});
  const dispatch = useDispatch();
  const turnusData = useTurnusContext();
  const dashboardRef = useRef(null);
  const {
    globalSortEmployee,
    setGridColumnApiDashboard,
    employeeData: rowData,
    dashboardData,
    surveyDashboardData,
  } = turnusData;

  const {
    checkRole = [],
    checkingWorkingInRow = [],
    checkTimeOffBetweenShift = [],
    checkWorkingOver54Hours = [],
    listWorkingMoreThanThreeWeekend = [],
    checkHourOffPerWeek = [],
    listCheckingF1 = [],
    checkingF1Over50 = [],
    checkingF2Over50 = [],
    checkingWorkingLongWeekend = [],
    checkingMore3Weekend = [],
    checkingOffWeekendBeforeVacation = [],
    checkingDagShiftIfNotWorkingWeekend = [],
    off2DayAfterNightShift = [],
    // surveyDashboardData ,
  } = checkingData;
  useEffect(() => {
    setCheckingData({ ...dashboardData, surveyDashboardData });
  }, [rowData, dashboardData, surveyDashboardData]);

  const cellRenderer = useCallback(
    (checkData, key, type, checkDataKey) =>
      ({ data }) => {
        if (!data) return;
        const { name, employeeId } = data;
        const isUnassigned = name === undefined;
        let check = !isUnassigned ? checkIndex(checkData, employeeId) : false;
        if (checkDataKey) {
          check = data[checkDataKey];
        }

        return (
          <Status
            key={`${key}-${uuidv4()} `}
            check={check}
            dataDefault={checkData}
            employeeId={data.employeeId}
            level={'hard'}
            type={type || 'date'}
          />
        );
      },
    [checkingData, rowData],
  );
  const createColumn = useCallback(
    (headerName, valueGetter, checkData, optionalColProps = {}, type, checkDataKey) => ({
      headerName: t(headerName),
      colId: checkDataKey || headerName,
      field: checkDataKey,
      valueGetter,
      ...(checkData && {
        cellRenderer: cellRenderer(checkData, headerName, type, checkDataKey),
        tooltipValueGetter: ({ data }) => {
          if (!data) return;
          const { name, employeeId } = data;
          const isUnassigned = name === undefined;
          let check = !isUnassigned ? checkIndex(checkData, employeeId) : false;
          if (checkDataKey) {
            check = data[checkDataKey];
          }

          if (!check) {
            return t('passed');
          }
          return t('failed');
        },
      }),
      ...optionalColProps,

      // this will excluse these column when searching globally
      getQuickFilterText: () => {
        return '';
      },
    }),
    [rowData],
  );
  const renderSoftStatus = useCallback(
    (
      headerName,
      width,
      checkIndexFunction,
      dataDefault,
      type,
      optionalColProps = {},
      checkDataKey,
    ) => {
      const description = () => {
        const headerObj = listTitleCheck[headerName];
        if (headerObj) {
          return language === 'NO' ? headerObj['no-description'] : headerObj['en-description'];
        }
      };
      return {
        headerName: t(headerName),
        headerTooltip: description(),
        colId: checkDataKey || headerName,
        valueGetter: ({ data }) => {
          if (data) return data[checkDataKey];
        },
        field: checkDataKey,

        width: width,
        ...optionalColProps,
        // this will excluse these column when searching globally
        getQuickFilterText: () => {
          return '';
        },
        tooltipValueGetter: ({ data }) => {
          if (!data) return;
          let check = checkIndex(checkIndexFunction, data.employeeId);
          if (checkDataKey) {
            check = data[checkDataKey];
          }
          if (!checkIndexFunction) {
            return t('inactive');
          }
          if (!check) {
            return t('passed');
          }
          return t('warning-soft');
        },

        cellRenderer: ({ data }) => {
          if (!data) return;

          let check = checkIndex(checkIndexFunction, data.employeeId);
          if (checkDataKey) {
            check = data[checkDataKey];
          }
          return (
            <Status
              key={`${headerName}-${uuidv4()}`}
              check={check}
              dataDefault={dataDefault}
              employeeId={data.employeeId}
              level={'soft'}
              type={type || 'date'}
            />
          );
        },
      };
    },
    [rowData],
  );

  const renderSurveyStatus = (headerName, checkKey, optionalColProps = {}, isHard) => {
    const alwaysActiveKey = ['validityPeriods', 'vacation', 'weekendRotation'];
    const description = () => {
      const headerObj = listTitleCheck[headerName];
      if (headerObj) {
        return language === 'NO' ? headerObj['no-description'] : headerObj['en-description'];
      }
    };

    return {
      headerName: t(headerName),
      headerTooltip: description(),
      colId: checkKey,
      field: checkKey,
      // this will excluse these column when searching globally
      getQuickFilterText: () => {
        return '';
      },
      ...optionalColProps,
      tooltipValueGetter: ({ data }) => {
        if (!data) return;

        const employSurveyCheck = surveyDashboardData?.find(
          (item) => item.employeeID === data.employeeId,
        );
        if (!employSurveyCheck) {
          return t('inactive');
        }
        let check = employSurveyCheck
          ? checkIndex(employSurveyCheck[checkKey], data.employeeId)
          : false;
        if (checkKey) {
          check = data[checkKey];
        }
        if (!check) {
          return t('passed');
        }
        return t('warning-emp');
      },
      cellRenderer: ({ data }) => {
        if (!data) return;

        const employSurveyCheck = surveyDashboardData.find(
          (item) => +item.employeeID === +data.employeeId,
        );

        let check = employSurveyCheck
          ? checkIndex(employSurveyCheck[checkKey], data.employeeId)
          : false;
        if (checkKey) {
          check = data[checkKey];
        }
        return (
          <Status
            key={`${headerName}-${uuidv4()}`}
            check={check}
            dataDefault={employSurveyCheck ? employSurveyCheck[checkKey] : []}
            employeeId={data.employeeId}
            type={'date'}
            level={isHard ? 'hard' : 'survey'}
            isActive
          />
        );
      },
    };
  };

  const DASHBOARD_COLUMNS = [
    createColumn('day-off', ({ data }) => (data.dayOff ? data.dayOff.length : 0), null, {
      width: 90,
      hide: !showHardRule,
    }),
    createColumn(
      'Number of working Sundays',
      ({ data }) => `${data.numWorkingSunday} / ${holidayAndSundayRange.length}`,
      null,
      { width: 210, hide: !showHardRule },
    ),
    createColumn(
      'Number of public holidays',
      ({ data }) => `${data.numWorkingHoliday} / ${holidayAndSundayRange.length}`,
      null,
      { width: 200, hide: !showHardRule },
    ),
    createColumn(
      'role-check',
      ({ data }) => data.checkRole,
      checkRole,
      { width: 150, hide: !showHardRule },
      'date',
      'checkRole',
    ),
    createColumn(
      'policy-eight',
      ({ data }) => data.checkingWorkingInRow,
      checkingWorkingInRow,
      { hide: !showHardRule },
      'date',
      'checkingWorkingInRow',
    ),
    createColumn(
      'time-off-between-check',
      ({ data }) => data.checkTimeOffBetweenShift,
      checkTimeOffBetweenShift,
      { hide: !showHardRule },

      'date',
      'checkTimeOffBetweenShift',
    ),
    createColumn(
      'working-over-54-hours',
      ({ data }) => data.checkWorkingOver54Hours,
      checkWorkingOver54Hours,
      { hide: !showHardRule },
      'week',
      'checkWorkingOver54Hours',
    ),
    createColumn(
      'working-more-than-three-weekend',
      ({ data }) => data.listWorkingMoreThanThreeWeekend,
      listWorkingMoreThanThreeWeekend,
      {
        width: 200,
        hide: !showHardRule,
      },
      'date',
      'listWorkingMoreThanThreeWeekend',
    ),
    createColumn(
      'hour-off-per-week',
      ({ data }) => data.checkHourOffPerWeek,
      checkHourOffPerWeek,
      { width: 150, hide: !showHardRule },
      'week',
      'checkHourOffPerWeek',
    ),
    createColumn(
      'f1-checking',
      ({ data }) => data.listCheckingF1,
      listCheckingF1,
      { width: 140, resizable: false, hide: !showHardRule },
      'week',
      'listCheckingF1',
    ),

    //SOFT RULE
    createColumn(
      'weekend-hours',
      ({ data }) => {
        return data.weekendHours.toFixed(2);
      },
      null,
      { field: 'weekendHours', width: 160, hide: !showSoftRule },
    ),
    createColumn('day-shift', null, null, { field: 'dayShift', width: 100, hide: !showSoftRule }),
    createColumn('evening-shift', null, null, {
      field: 'lateShift',
      width: 120,
      hide: !showSoftRule,
    }),
    createColumn('night-shift', null, null, {
      field: 'nightShift',
      width: 110,
      hide: !showSoftRule,
    }),
    createColumn('long-shift', null, null, {
      field: 'longShift',
      width: 100,
      hide: !showSoftRule,
    }),
    createColumn('other', null, null, { field: 'otherShift', width: 80, hide: !showSoftRule }),
    renderSoftStatus(
      'f1-checking-soft-over-50',
      120,
      checkingF1Over50,
      checkingF1Over50,
      'date',
      { hide: !showSoftRule },
      'checkingF1Over50',
    ),
    renderSoftStatus(
      'f2-checking-soft',
      120,
      checkingF2Over50,
      checkingF2Over50,
      'date',
      { hide: !showSoftRule },
      'checkingF2Over50',
    ),
    renderSoftStatus(
      'checking-three-weekend-in-row',
      140,
      checkingMore3Weekend,
      checkingMore3Weekend,
      'date',
      { hide: !showSoftRule },
      'checkingMore3Weekend',
    ),
    renderSoftStatus(
      'checking-after-working-long-shift-weekend',
      140,
      checkingWorkingLongWeekend,
      checkingWorkingLongWeekend,
      'date',
      { hide: !showSoftRule },
      'checkingWorkingLongWeekend',
    ),
    renderSoftStatus(
      'checking-off-weekend-before-holiday',
      280,
      checkingOffWeekendBeforeVacation,
      checkingOffWeekendBeforeVacation,
      'date',
      { hide: !showSoftRule },
      'checkingOffWeekendBeforeVacation',
    ),
    // renderSoftStatus('extended-f1', 120, null, checkingWorkingLongWeekend),
    renderSoftStatus(
      'not-working-aft-shift-before-off-weekend',
      230,
      checkingDagShiftIfNotWorkingWeekend,
      checkingDagShiftIfNotWorkingWeekend,
      'date',
      { hide: !showSoftRule },
      'checkingDagShiftIfNotWorkingWeekend',
    ),
    renderSoftStatus(
      'two-day-off-after-working-night',
      120,
      off2DayAfterNightShift,
      off2DayAfterNightShift,
      'date',
      {
        resizable: false,
        hide: !showSoftRule,
      },
      'off2DayAfterNightShift',
    ),

    renderSurveyStatus('validity-periods', 'validityPeriods', { width: 150, hide: !showSurvey }),
    renderSurveyStatus('type-shift-check', 'typeShift', { width: 100, hide: !showSurvey }),
    renderSurveyStatus('weekend-rotation', 'weekendRotation', { width: 150, hide: !showSurvey }),
    renderSurveyStatus('holiday-off', 'holidayOff', { width: 100, hide: !showSurvey }),
    renderSurveyStatus('vacation-check', 'vacation', { width: 100, hide: !showSurvey }),
    renderSurveyStatus(
      'extended-date-off',
      'extendDateOff',
      { width: 130, hide: !showSurvey },
      true,
    ),
    renderSurveyStatus('summer-periods', 'summerPeriods', { hide: !showSurvey }),
  ];

  const columnDefs = useMemo(() => {
    return [
      {
        headerName: '',
        headerClass: 'dark-header',
        children: DASHBOARD_COLUMNS,
      },
    ];
  }, [currentTab, checkingData, rowData]);
  const defaultColDef = useMemo(
    () => ({
      sortable: false,
      menuTabs: [], // hide menu when hover header
      filter: 'agTextColumnFilter', // show text filter
      headerClass: 'center-header black-header',
      cellClass: 'center-cell',
      resizable: true,
      suppressSizeToFit: true,
      editable: false,
      flex: 1,
    }),
    [],
  );

  const onDashboardGridReady = (params) => {
    autoSyncScroll('ag-wrap');

    setGridColumnApiDashboard(params.columnApi);
    dashboardRef.current = params.api;
    dispatch(updateApi({ key: 'dashboard', value: { api: params.api, colApi: params.columnApi } }));
    if (globalSortEmployee) {
      params.columnApi.applyColumnState(globalSortEmployee);
    }
  };
  useEffect(() => {
    autoSyncScroll('ag-wrap');
  }, [currentTab]);

  return (
    <div className={`ag-theme-alpine dashboard ${show ? '' : 'hidden'}`} id="ag-wrap">
      <AgGridReact
        ref={dashboardRef}
        onFirstDataRendered={onFirstDataRendered}
        onGridReady={onDashboardGridReady}
        rowData={rowData}
        defaultColDef={defaultColDef}
        columnDefs={[...columnDefs, ...hiddenColDef]}
        animateRows={true}
        suppressRowTransform={true}
        tooltipShowDelay={0}
        getRowId={getRowId}
        enableRangeSelection={true} // Optional - allows range selection of cells, chart, auto fill
        enableCharts={true} // need to enableRangeSelection
        immutableData={true}
        isExternalFilterPresent={() => isExternalFilterPresent}
        doesExternalFilterPass={doesExternalFilterPass}
        includeHiddenColumnsInQuickFilter={true} // this will allow searching for hidden col
        postSortRows={postSortRows}
        suppressHorizontalScroll={false}
      />
    </div>
  );
};

export default memo(Dashboard);
