import { CloseOutlined } from '@ant-design/icons';
import { usePlanList } from 'Hooks/useData';
import axiosClient from 'axios/axiosClient';
import DatePickerCustom from 'components/DatePicker/DatePickerCustom';

import { Toast, showLoading } from 'helper/alert';
import { t } from 'i18next';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { socket } from 'socketIO';
import { PLAN_VERSION_ROOM_ID } from 'socketIO/room';

const NewPlanVersionBox = ({ setIsActiveNewVersionForm, unitCode, refetchPlanList }) => {
  const [versionInfo, setVersionInfo] = useState({
    versionName: '',

    planSelectedID: '',
  });

  const [fromDate, setFromDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateRange, setDateRange] = useState({});
  const { data: planList } = usePlanList({ unitSelected: unitCode });
  const handleChangeDate = (value, setValue, type) => {
    let updateValue = moment(value).startOf('W').format('YYYY-MM-DD');
    if (type === 'endDate') {
      updateValue = moment(value).endOf('W').format('YYYY-MM-DD');
    }
    if (
      moment(updateValue).isSameOrAfter(moment(dateRange.minDate)) &&
      moment(updateValue).isSameOrBefore(moment(dateRange.maxDate))
    ) {
      setValue(updateValue);
    }
  };
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setVersionInfo((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleSelectPlan = (e) => {
    const { name, value } = e.target;
    setVersionInfo((prev) => {
      return { ...prev, [name]: value };
    });
    const planFound = planList.find((plan) => +plan.id === +value);
    const { from_date, to_date } = planFound;
    setFromDate(from_date);
    setEndDate(to_date);
    setDateRange({ minDate: from_date, maxDate: to_date });
  };

  const handleSubmit = async () => {
    showLoading({ shouldShow: true, title: t('creating') });
    await axiosClient
      .post('calendar-plan/staff-plan/gen-new-version', {
        ...versionInfo,
        fromDate,
        endDate,
        unitCode,
      })
      .then(async () => {
        await refetchPlanList();
        showLoading({ shouldShow: false });
        setIsActiveNewVersionForm(false);
        socket.emit('send_version', {
          room: PLAN_VERSION_ROOM_ID,
        });
        Toast({ icon: 'success', title: `${t('create')} ${t('success')}` });
      })
      .catch(() => {
        showLoading({ shouldShow: false });
        Toast({ icon: 'error', title: `${t('create')} ${t('fail')}` });
      });
  };

  const renderPlan = useCallback(() => {
    return planList.map((plan) => {
      return (
        <option key={plan.uuid} value={plan.id}>
          {plan.planName || plan.plan_name}
        </option>
      );
    });
  }, [planList]);

  return (
    <div className="new-plan-version-box">
      <div className="box-header">
        <div className="box-title">
          <h3>{t('new-rotation')}</h3>
        </div>
        <div className="box-close" onClick={() => setIsActiveNewVersionForm(false)}>
          <CloseOutlined />
        </div>
      </div>
      <div className="box-body">
        <div className="description">{t('new-version-form-desc')}</div>
        <div className="content">
          <div className="content-item">
            <label htmlFor="">{t('name-of-rotation-plan')}</label>
            <input
              type="text"
              name="versionName"
              placeholder={t('name')}
              value={versionInfo.versionName}
              onChange={handleOnChange}
            />
          </div>
          <div className="content-item">
            <label htmlFor="">{t('staff-plan')}</label>
            <select name="planSelectedID" id="" onChange={handleSelectPlan}>
              <option value="">
                {t('select')} {t('staff-plan').toLowerCase()}
              </option>
              {renderPlan()}
            </select>
          </div>
          <div className="content-item__group">
            <div className="content-item">
              <label htmlFor="">{t('from-date')}</label>
              <DatePickerCustom
                date={fromDate}
                setDate={(value) => handleChangeDate(value, setFromDate)}
                isNotAutofocus={true}
                disabled={true}
              />
            </div>
            <div className="content-item">
              <label htmlFor="">{t('to-date')}</label>
              <DatePickerCustom
                date={endDate}
                setDate={(value) => handleChangeDate(value, setEndDate, 'endDate')}
                isNotAutofocus={true}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="box-submit">
        <button className="custom-btn square-btn" onClick={handleSubmit}>
          {t('create-rotation-schedule')}
        </button>
      </div>
    </div>
  );
};

export default NewPlanVersionBox;
