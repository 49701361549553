import axiosClient from 'axios/axiosClient';
import { custommerVer2, SORTABLE_COLUMN_ID, websocketURL } from 'constant';
import { Toast } from 'helper/alert';
import { t } from 'i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updatePlanSelectedAction } from 'redux/calendarPlan/calendarPlanAction';
import { selectCalendarPlan } from 'redux/calendarPlan/calendarPlanSlice';

import { OptimizeIcon } from 'assets/img';
import { Cookies } from 'react-cookie';
import { getEnvironmentName } from 'helper/environment';
import { saveSession } from 'helper/localStorage';
import { selectGlobal } from 'redux/global/globalSlice';
import { getPlanDetailLocked } from '../TableContent/helper/lockRange';
import PopUp from 'components/Common/PopUp/PopUp';

import './OptimizeButton.scss';
import { useTurnusContext } from '../../Turnus/TurnusContext';
import { selectAuth } from 'redux/auth/authSlice';
import { socket } from 'socketIO';
import { updateGlobalSortEmployeeAction } from 'redux/global/globalAction';

const cookies = new Cookies();

const getErrorMsg = ({ payload }) => {
  const payloadMsg = JSON.stringify(payload);
  if (/role/.test(payloadMsg)) {
    return t('generator-error-miss-competancy');
  }
  if (/task/.test(payloadMsg)) {
    return t('generator-error-miss-task');
  }
  if (/shift|Shift|max/.test(payloadMsg)) {
    return t('generator-error-miss-hard-rules');
  }

  return t('generator-error-unknow');
};

const requestOptimizePlan = async ({
  dispatch,
  note,
  planSelected,
  unitSelected,
  weekSelect,
  planDetailLocked,
  setPlanSelected,
  refetchEmployeeList,
  refetchVakant,
  refetchPlanChildList,
  setIsPlanSelectedInProgressPlan,
  setProgressData,
  setIsMainContent,
  customerType,
  refetchVersionDateRange,
  setIsActive,
  setEmployeeData,
  progressPlan,
  sortTable,
}) => {
  let clientSocket = new WebSocket(websocketURL);
  clientSocket.onopen = async (e) => {
    if (Object.keys(planSelected).length && planSelected.id) {
      const fullName = cookies.get('fullName');

      //GENERATOR WILL BE DETECT ENVIRONMENT TO USE IT's LOGIC
      const environmentName = getEnvironmentName();

      const payload = {
        unitCode: unitSelected,
        week: weekSelect,
        planId: planSelected.id,
        note: note,
        createdBy: fullName,
        environmentName,
        customerType,
      };
      if (planDetailLocked?.lockedData.length) {
        await axiosClient
          .post('calendar-plan/turnus/locking-shift', {
            lockedData: planDetailLocked?.lockedData || [],
          })
          .catch((err) => {
            console.log(err);
          });
      }
      clientSocket.send(JSON.stringify(payload));

      setIsPlanSelectedInProgressPlan(true);
      socket.emit('optimizing_plan', { planID: planSelected.id });
      axiosClient
        .post('/calendar-plan/kalender/est-optimize-time', {
          planId: planSelected.parentID,
        })
        .then((result) => {
          Toast({
            icon: 'info',

            title: `${t('optimizing-plan-est')}  ${result.timeEst} ${t('minutes')}`,
          });
        });
    }
  };
  clientSocket.onmessage = async (e) => {
    const payload = e.data;
    if (!isNaN(payload)) {
      dispatch(
        updatePlanSelectedAction({ ...planSelected, versionID: +payload, isAvailable: false }),
      );
      setPlanSelected({ ...planSelected, versionID: +payload, isAvailable: false });
    } else if (payload === 'Optimize success') {
      socket.emit('optimizing_plan_done', { planID: planSelected.id });

      await refetchVersionDateRange();
      await refetchVakant();
      await refetchEmployeeList();
      setIsPlanSelectedInProgressPlan(false);
      setIsActive(true);
      sortTable({ colId: 'emp-job-percentage', sort: 'desc' });
      Toast({
        icon: 'success',

        title: `${t('optimize-success')}`,
      });

      clientSocket.close(1000);
    } else {
      socket.emit('optimizing_plan_done', { planID: planSelected.id });

      const errorMsg = getErrorMsg({ payload });
      Toast({
        icon: 'error',
        title: errorMsg,
      });
      setIsMainContent(true);
      saveSession('isMainContent', true);
      setIsPlanSelectedInProgressPlan(false);
      setIsActive(true);

      // refetchPlanList();
      refetchVakant();
      refetchEmployeeList();
      refetchPlanChildList();

      clientSocket.close(1000);
    }
  };
  clientSocket.onerror = async (error) => {
    Toast({ icon: 'error', title: 'Optimze server disconnect' });
  };
};
const ConfirmOptimizePopUp = ({
  isActive,
  setIsActive,
  comment,
  setComment,
  handleOptimizeClick,
}) => {
  return (
    <PopUp
      isActive={isActive}
      setIsActive={setIsActive}
      title={t('optimize-plan')}
      submitCallback={handleOptimizeClick}
      submitBtnText={t('optimize')}
    >
      <div className="optimize-pop-up">
        <p className="desc">{t('optimize-desc')}</p>

        <div className="comment-box">
          <label htmlFor="">{t('comment')}</label>
          <input
            type="text"
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
            }}
          />
        </div>
      </div>
    </PopUp>
  );
};

const OptimizeButton = () => {
  const turnusData = useTurnusContext();
  const {
    planSelected,
    unitSelected,
    weekSelect,
    // refetchPlanList,
    refetchEmployeeList,
    refetchVakant,
    refetchPlanChildList,
    setIsPlanSelectedInProgressPlan,
    setProgressData,
    setIsMainContent,
    refetchVersionDateRange,
    isPlanSelectedInProgressPlan,
    setPlanSelected,
    setEmployeeData,
    progressPlan,
    gridColumnApiEmpInfo,
    gridColumnApiTaskList,
    gridColumnApiDashboard,
    gridColumnApiHrPerWeek,
  } = turnusData;
  const dispatch = useDispatch();
  const { listLocked } = useSelector(selectCalendarPlan);
  const { isEditabled } = useSelector(selectAuth);

  const planDetailLocked = getPlanDetailLocked(listLocked, planSelected);
  const { customerType } = useSelector(selectGlobal);
  const [activePopup, setAcitvePopUp] = useState(false);
  const [comment, setComment] = useState('');
  const [active, setIsActive] = useState(true);

  const sortTable = ({ colId, sort }) => {
    const count = {};

    gridColumnApiTaskList?.getColumnState()?.forEach((item) => {
      if (count[item.colId]) {
        count[item.colId]++;
      } else {
        count[item.colId] = 1;
      }
    });

    // sort task table
    gridColumnApiEmpInfo?.applyColumnState({
      state: [{ colId, sort }],
      defaultState: { sort: null },
      applyOrder: true,
    });
    // sort task table
    gridColumnApiTaskList?.applyColumnState({
      state: [{ colId, sort }],
      defaultState: { sort: null },
      applyOrder: true,
    });

    // sort dashboard table
    gridColumnApiDashboard?.applyColumnState({
      state: [{ colId, sort }],
      defaultState: { sort: null },
      applyOrder: true,
    });

    // hr per week table
    gridColumnApiHrPerWeek?.applyColumnState({
      state: [{ colId, sort }],
      defaultState: { sort: null },
      applyOrder: true,
    });
    if (!!colId && !!sort) {
      dispatch(
        updateGlobalSortEmployeeAction({
          state: [{ colId, sort }],
          defaultState: { sort: null },
          applyOrder: false,
        }),
      );
    }
  };

  const handleOptimizeClick = async () => {
    if (custommerVer2.includes(process.env.REACT_APP_SITE)) {
      axiosClient.post('calendar-plan/fill-shift-to-empty-date', {
        planId: planSelected.id,
        fromDate: moment(planSelected.from_date).format('YYYY-MM-DD'),
        endDate: moment(planSelected.to_date).format('YYYY-MM-DD'),
        unitCode: unitSelected,
      });
    } else {
      dispatch(updatePlanSelectedAction({ ...planSelected, isAvailable: false }));
      setPlanSelected({ ...planSelected, isAvailable: false });

      await requestOptimizePlan({
        dispatch,
        comment,
        planSelected,
        unitSelected,
        weekSelect,
        planDetailLocked,
        setPlanSelected,
        refetchEmployeeList,
        refetchVakant,
        refetchPlanChildList,
        setIsPlanSelectedInProgressPlan,
        setProgressData,
        setIsMainContent,
        customerType,
        refetchVersionDateRange,
        setIsActive,
        setEmployeeData,
        progressPlan,
        sortTable,
      });
      setComment('');
    }
  };

  useEffect(() => {
    setIsActive(!isPlanSelectedInProgressPlan && Object.keys(planSelected).length);
  }, [isPlanSelectedInProgressPlan, planSelected]);
  return (
    <div>
      <button
        className="primary-btn optimize-btn"
        onClick={() => setAcitvePopUp(true)}
        disabled={!active || !isEditabled}
      >
        <img src={OptimizeIcon} /> {t('optimize-plan')}
      </button>
      <ConfirmOptimizePopUp
        isActive={activePopup}
        setIsActive={setAcitvePopUp}
        handleOptimizeClick={handleOptimizeClick}
        comment={comment}
        setComment={setComment}
      />
    </div>
  );
};

export default OptimizeButton;
