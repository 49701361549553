import { CloseOutlined, SyncOutlined } from '@ant-design/icons';
import axiosClient from 'axios/axiosClient';
import { Toast } from 'helper/alert';
import { handleGetIdSheet } from 'helper/getGoogleSheetId';
import { t } from 'i18next';
import React, { useState } from 'react';
import './UploadEmployeeForm.scss';
import { read, utils } from 'xlsx';
import { getColName } from 'helper/getColumnOfExcelName';

const RenderUploadContent = ({ uploadType, setGoogleSheetId, setFileData }) => {
  switch (uploadType) {
    case 'file':
      return (
        <div className="upload-file-form-group link__wrap ">
          <div className="upload-file-form-group link">
            <input
              type="file"
              name=""
              onChange={(e) => {
                handleFileChange(e, setFileData);
              }}
            />
          </div>
        </div>
      );

    default:
      return (
        <div className="upload-file-form-group link__wrap ">
          <div className="upload-file-form-group link">
            <label htmlFor="end-date">Sheet {t('link')}</label>
            <input
              type="text"
              name="googleSheetId"
              onChange={(e) => {
                const googleSheetId = handleGetIdSheet(e.target.value);
                setGoogleSheetId(googleSheetId);
              }}
            />
          </div>
        </div>
      );
  }
};

const handleFileChange = async (e, setFileData) => {
  const file = e.target.files[0];
  if (!file) {
    return;
  }
  await file.arrayBuffer().then((data) => {
    const workbook = read(data);
    const sheet = workbook['Sheets'];
    const firstSheet = Object.keys(sheet)[0];
    const sheetData = sheet[firstSheet];
    const range = utils.decode_range(sheetData['!ref']);
    const starRow = 1;
    const endColumn = range['e']['c'];
    const endRow = range['e']['r'] - 4;
    const result = [];

    //LOOP ROW
    for (let i = starRow; i <= endRow + 1; i++) {
      const rowData = [];
      //LOOP COULMN
      for (let j = 0; j <= endColumn; j++) {
        const column = getColName(j);
        const columnData = sheetData[`${column}${i}`];

        rowData.push(columnData?.v || '');
      }
      result.push(rowData);
    }
    setFileData(result);
  });
};

const UploadEmployeeForm = ({ setActive, unitCode, isUpdate, setIsUpdate, refetchEmployees }) => {
  const [googleSheetId, setGoogleSheetId] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [uploadType, setUploadType] = useState('link');

  const handleSubmit = () => {
    setIsUploading(true);
    axiosClient
      .post('calendar-plan/employee/upload', { googleSheetId, unitCode, fileData, uploadType })
      .then((res) => {
        if (res.status === 200) {
          setIsUploading(false);
          setActive(false);
          setIsUpdate(!isUpdate);
          refetchEmployees();
          Toast({ icon: 'success', title: `${t('upload')} ${t('success')}` });
        } else {
          setIsUploading(false);

          Toast({ icon: 'error', title: `${t('upload')} ${t('unsuccess')}` });
        }
      })
      .catch((err) => {
        setIsUploading(false);
        Toast({ icon: 'error', title: `${t('upload')} ${t('unsuccess')}` });
      });
  };

  return (
    <div className="overlay">
      <div className="upload-file-form__wrap">
        <div className="upload-file-form__content">
          <div className="upload-file-form__header">
            <h4>{t('upload-employee-form')}</h4>
            <div className="upload-file-form__close">
              <button disabled={isUploading}>
                <CloseOutlined
                  onClick={() => {
                    setActive(false);
                  }}
                />
              </button>
            </div>
          </div>

          <div className="upload-file-form-group type-import ">
            <label htmlFor="">{t('import-type')}</label>
            <select
              name=""
              id=""
              value={uploadType}
              onChange={(e) => {
                setUploadType(e.target.value);
              }}
            >
              <option value="link">Sheet link</option>
              <option value="file">{t('File')}</option>
            </select>
          </div>
          <RenderUploadContent
            uploadType={uploadType}
            setFileData={setFileData}
            setGoogleSheetId={setGoogleSheetId}
          />
          {/* <div className="upload-file-form-group link__wrap ">
            <div className="upload-file-form-group link">
              <label htmlFor="end-date"> Sheet {t('link')}</label>
              <input
                type="text"
                name="googleSheetId"
                onChange={(e) => {
                  const googleSheetId = handleGetIdSheet(e.target.value);
                  setGoogleSheetId(googleSheetId);
                }}
              />
            </div>
          </div> */}
          <div className="upload-file-form-group submit">
            <button className="custom-btn" disabled={isUploading} onClick={handleSubmit}>
              {t('send')}
              {isUploading ? (
                <span className="loading-icon">
                  <SyncOutlined spin />
                </span>
              ) : null}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadEmployeeForm;
