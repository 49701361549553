import { t } from 'i18next';
import React, { useState, useEffect } from 'react';

import { useEmployees } from 'Hooks/useData';
import { calculateStaffPlanStatisticData } from 'helper/calculateTableData';

const Statistic = ({
  planInfo,
  showStatistics,
  tableData,
  unitSelected,
  predictCalculated,
  showAbsencePrediction,
  publicHoliday,
  layerCreated,
  layerList,
}) => {
  const { data: listEmployee } = useEmployees({ unitSelected });
  const [statisticData, setStatisticData] = useState({
    avgJobPercentage: 0,
    anualWorkInPlan: 0,
    totalEmpNeededPer3Weekend: 0,
    defaultValue: 0,
  });

  useEffect(() => {
    setStatisticData(
      calculateStaffPlanStatisticData({
        planData: tableData,
        employeeData: listEmployee,
        planInfo,
        predictCalculated,
        showAbsencePrediction,
        publicHoliday,
        layerCreated,
        layerList,
      }),
    );
  }, [tableData, listEmployee, planInfo, predictCalculated, showAbsencePrediction]);
  return (
    <div className={`plan-detail__statistic ${showStatistics ? 'active' : ''}`}>
      <p>
        {statisticData.avgJobPercentage} <span>{t('yearly-list')}</span>
      </p>
      <p>
        {statisticData.anualWorkInPlan}
        <span>{t('annual-work-in-plan')}</span>
      </p>
      <p>
        {statisticData.FTEAbsencePrediction}
        <span>{t('fte-absence-prediction')}</span>
      </p>

      {/* WILL USE LATER */}
      {/* <p>
        {statisticData.totalEmpNeededPer3Weekend}
        <span>{t('shift-per-3-week')}</span>
      </p>
      <p>
        {statisticData.defaultValue ||
          (
            Number(planInfo.numWeek) * statisticData.totalEmpNeededPer3Weekend -
            statisticData.every3rdWeekCount
          ).toFixed(2)}
        <span>{t('vakant-number')}</span>
      </p> */}
    </div>
  );
};

export default Statistic;
