const checkingTypeShift = ({ employeeSurveyRecord, taskData, employee }) => {
  const mapValueTypeShift = {
    0: 'Day',
    1: 'Late',
    2: 'Night',
    3: 'Long shift (Weekday)',
  };
  let listTypeValid = ['Day', 'Late', 'Night'];
  const typeShiftProperty = 'type_shift';
  const taskType = taskData.taskType;
  if (employeeSurveyRecord) {
    const typeShiftRecord = employeeSurveyRecord.find(
      (item) => item.wishProperty === typeShiftProperty,
    );
    if (typeShiftRecord && typeShiftRecord.answer.length) {
      listTypeValid = typeShiftRecord.answer.split(',');
    }
  }

  return listTypeValid.includes(mapValueTypeShift[taskType]);
};

export default { checkingTypeShift };
