import React, { forwardRef } from 'react';

import './styles.scss';

const PrimaryButton = forwardRef(
  (
    { iconLeft, title, iconRight, secondary, tertiary, style, onClick, onImgClick, disabled },
    ref,
  ) => {
    return (
      <button
        ref={ref}
        className={`primary-button ${secondary ? 'secondary' : ''} ${tertiary ? 'tertiary' : ''}`}
        style={style}
        onClick={onClick}
        disabled={disabled}
      >
        {iconLeft && <img src={iconLeft} alt="icon" />}
        {title}
        {iconRight && <img src={iconRight} alt="icon" onClick={onImgClick} />}
      </button>
    );
  },
);

export default PrimaryButton;
