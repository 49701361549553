import { t } from 'i18next';
import React, { useMemo, useRef, useState } from 'react';
import './BulkAction.scss';
import { DeleteIconWhite, DownArrow, EditIcon } from 'assets/img';
import { confirmDelete, showLoading, Toast } from 'helper/alert';
import axiosClient from 'axios/axiosClient';
import { useClickOutside } from 'helper/useClickOutSide';
import ModalLayout from 'layout/Modal';
import BulkEditTable from './BulkEditTable';

const BulkDeleteButton = ({ setSelectedEmp, selectedEmp, refetchEmployeeWithPositionFetch }) => {
  const handleDelete = () => {
    confirmDelete({}).then(async (result) => {
      if (result.isConfirmed) {
        showLoading({ shouldShow: true, title: 'deleting' });
        await axiosClient
          .post('calendar-plan/employee/bulk-delete', {
            deletedList: selectedEmp.map((item) => item.id),
          })
          .then(async (res) => {
            setSelectedEmp([]);

            await refetchEmployeeWithPositionFetch();
          })
          .catch((err) => {
            Toast({ icon: 'error', title: t('save-fail') });
          })
          .finally(() => {
            showLoading({ shouldShow: false });
          });
      }
    });
  };
  return (
    <button className="custom-btn delete-btn" onClick={handleDelete}>
      <img src={DeleteIconWhite} width={18} alt="" /> {t('delete')} {t('employee').toLowerCase()}
    </button>
  );
};

const BulkEditButton = ({ selectedEmp, setActiveBulkEditTable }) => {
  return (
    <button
      className="custom-btn outline-btn"
      onClick={() => {
        setActiveBulkEditTable(true);
      }}
    >
      <img src={EditIcon} alt="" width={24} />
      {t('bulk-edit')}
    </button>
  );
};

const BulkEditGroup = ({
  setSelectedEmp,
  selectedEmp,
  refetchEmployeeWithPositionFetch,
  groupList,
  unitSelected,
}) => {
  const [groupSelected, setGroupSelected] = useState([]);
  const [isOpenList, setIsOpenList] = useState(false);
  const buttonRef = useRef(null);
  const handleOnChange = (group) => {
    const isExist = groupSelected.find((item) => +item === +group.id);
    if (isExist) {
      setGroupSelected((prev) => prev.filter((item) => item.id !== +group.id));
    } else {
      setGroupSelected((prev) => [...prev, +group.id]);
    }
  };
  useClickOutside(
    buttonRef,
    () => {
      setIsOpenList(false);
      setGroupSelected([]);
    },
    buttonRef,
  );

  const handleBulkEditGroup = () => {
    axiosClient
      .post('calendar-plan/employee/bulk-add-group', {
        unitSelected,
        groupSelected,
        employeeList: selectedEmp,
      })
      .then((_) => {
        refetchEmployeeWithPositionFetch();
        setGroupSelected([]);
        setIsOpenList(false);
      });
  };
  return (
    <div ref={buttonRef} className="  bulk-edit-group__header" onClick={() => setIsOpenList(true)}>
      <span>{t('add-to-group')}</span>
      <img src={DownArrow} alt="" width={18} />
      {isOpenList ? (
        <div className="group-list__wrap">
          <div className="group-list">
            {groupList.map((item) => {
              return (
                <div
                  key={`${item.id}-${item.key}-${item.name}-bulk-edi-group`}
                  className="group-item"
                >
                  <input
                    type="checkbox"
                    name=""
                    id={`${item.id}-${item.key}-${item.name}-bulk-edi-group`}
                    checked={groupSelected.find((group) => +item === +group.id)}
                    onChange={() => {
                      handleOnChange(item);
                    }}
                  />
                  <label htmlFor={`${item.id}-${item.key}-${item.name}-bulk-edi-group`}>
                    {item.name}
                  </label>
                </div>
              );
            })}
          </div>
          <div className="group-list-action">
            <button className="custom-btn" onClick={handleBulkEditGroup}>
              {t('create')}
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const BulkAction = ({
  setSelectedEmp,
  selectedEmp,
  refetchEmployeeWithPositionFetch,
  groupList,
  roleList,
  unitSelected,
  roleTypeList,
}) => {
  const [activeBulkEditTable, setActiveBulkEditTable] = useState(false);

  return (
    <div className="employee-table-bulk__wrap">
      <BulkEditGroup
        setSelectedEmp={setSelectedEmp}
        selectedEmp={selectedEmp}
        refetchEmployeeWithPositionFetch={refetchEmployeeWithPositionFetch}
        groupList={groupList}
        unitSelected={unitSelected}
      />
      <BulkEditButton
        setSelectedEmp={setSelectedEmp}
        selectedEmp={selectedEmp}
        refetchEmployeeWithPositionFetch={refetchEmployeeWithPositionFetch}
        setActiveBulkEditTable={setActiveBulkEditTable}
      />
      <BulkDeleteButton
        setSelectedEmp={setSelectedEmp}
        selectedEmp={selectedEmp}
        refetchEmployeeWithPositionFetch={refetchEmployeeWithPositionFetch}
      />

      {activeBulkEditTable ? (
        <BulkEditTable
          setSelectedEmp={setSelectedEmp}
          selectedEmp={selectedEmp}
          groupFetch={groupList}
          roleFetch={roleList}
          roleTypeList={roleTypeList}
          refetchEmployeeWithPositionFetch={refetchEmployeeWithPositionFetch}
          setActiveBulkEditTable={setActiveBulkEditTable}
          unitSelected={unitSelected}
        />
      ) : null}
    </div>
  );
};

export default BulkAction;
