import React, { useCallback } from 'react';
import SoftRulesTab from '../SoftRulesTab/SoftRulesTab';
import CalendarStaff from '../CalendarStaff/CalendarStaff';
import CalendarWorkEnvironment from '../CalendarWorkEnvironment/CalendarWorkEnvironment';
import CalendarPlanSurvey from '../CalendarPlanSurvey/CalendarPlanSurvey';

import './CalendarPlanContent.scss';
import GeneratorContent from '../GeneratorContent/GeneratorContent';
import TurnusProvider from '../GeneratorContent/Turnus/TurnusContext';

export default function CalendarPlanContent({
  menuActive,
  unitSelected,
  isMainContent,
  setIsMainContent,
}) {
  const contentArr = [
    {
      key: 'employee',
      component: <CalendarPlanSurvey unitSelected={unitSelected} />,
    },
    {
      key: 'working-environment-act',
      component: <CalendarWorkEnvironment unitSelected={unitSelected} />,
    },
    // {
    //   key: 'soft-rules',
    //   component: <SoftRulesTab unitSelected={unitSelected} />,
    // },
    {
      key: 'staff-plan',
      component: (
        <CalendarStaff
          unitSelected={unitSelected}
          isMainContent={isMainContent}
          setIsMainContent={setIsMainContent}
        />
      ),
    },
    {
      key: 'rotation-schedules',
      component: (
        <TurnusProvider unitSelected={unitSelected} setIsMainContent={setIsMainContent}>
          <GeneratorContent
            unitSelected={unitSelected}
            isMainContent={isMainContent}
            setIsMainContent={setIsMainContent}
          />
        </TurnusProvider>
      ),
    },
  ];
  const renderContent = useCallback(() => {
    let content = <></>;
    contentArr.forEach((item) => {
      if (item.key === menuActive) {
        content = item.component;
      }
    });
    return content;
  }, [menuActive, unitSelected, isMainContent]);
  return <div className="calendar-plan-content__wrap">{renderContent()}</div>;
}
