import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import AnswerItem from './AnswerItem';
import { Toast } from 'helper/alert';

import {
  LockIcon,
  UnlockIcon,
  ToggleOnIcon,
  ToggleOffIcon,
  UnlockRedIcon,
  LockRedIcon,
} from 'assets/img';
import { CLINICSL_ENV, HOSPITAL_ENV, TYPE_SURVEY_QUESTION } from 'constant';
import { useSelector } from 'react-redux';
import { selectGlobal } from 'redux/global/globalSlice';
import { selectAuth } from 'redux/auth/authSlice';

const ExpanedQuestion = ({
  parentQuestionData,
  index,
  language,
  listExpandedQuestionActive,
  setListExpandedQuestionActive,
  surveyRecord,
  setSurveyRecord,
  handleOnChange,
  emp,
  listHardRuleQuestion,
  setListHardRuleQuestion,
  listTopic,
  isReadOnly
}) => {
  const { isEditabled } = useSelector(selectAuth);

  const [activeQuestion, setActiveQuestion] = useState(true);
  const [isLocked, setIsLocked] = useState(false);
  const [questionData, setQuestionData] = useState({});
  const typeQuestionParent = parentQuestionData.parent_question_type;
  const { kommuneTypeQuestionProperty, hospitalTypeQuestionProperty, customerType } =
    useSelector(selectGlobal);
  const parentAnswerType = {
    single: TYPE_SURVEY_QUESTION.SINGLE_CHOICE,
    text: TYPE_SURVEY_QUESTION.TEXT,
    multiple: TYPE_SURVEY_QUESTION.MULTIPLE_CHOICE,
    date: TYPE_SURVEY_QUESTION.DATE,
    time: TYPE_SURVEY_QUESTION.TIME,
    'range-date-only': TYPE_SURVEY_QUESTION.RANGE_DATE_ONLY,
    'week-day-type-shift': TYPE_SURVEY_QUESTION.WEEKDAY_TYPE_SHIFT,
  }[typeQuestionParent];

  useEffect(() => {
    const questionIndex = listHardRuleQuestion?.findIndex(
      (item) => item.question_uuid === parentQuestionData.parent_question_id,
    );
    if (questionIndex > -1 && listHardRuleQuestion[questionIndex].is_hard) {
      setIsLocked(true);
    }
  }, [parentQuestionData, listHardRuleQuestion]);

  useEffect(() => {
    if (parentQuestionData && Object.keys(parentQuestionData).length) {
      const data = {
        questionContent:
          language === 'NO'
            ? parentQuestionData?.parent_question_no_ver
            : parentQuestionData?.parent_question_content,
        isMandatory: !!parentQuestionData?.parent_question_mandatory,
        description:
          language === 'NO'
            ? parentQuestionData.parent_question_description_no_ver
            : parentQuestionData.parent_question_description,
        answerList:
          language === 'NO'
            ? parentQuestionData.parent_answer_no_ver
            : parentQuestionData.parent_answer_choice,
        questionChoice: parentQuestionData.parent_answer_choice,
        questionType: parentAnswerType,
        child: parentQuestionData.child,
        parentId: parentQuestionData.parent_question_id,
        type: parentQuestionData.type,
        questionProperty: parentQuestionData.parrent_question_property,
      };

      setQuestionData(data);
    }
  }, [parentQuestionData, language]);
  useEffect(() => {
    const expanedQuestion = listExpandedQuestionActive.find(
      (question) => question.questionUUID === parentQuestionData.parent_question_id,
    );
    if (expanedQuestion) setActiveQuestion(!!expanedQuestion.isActive);
  }, [listExpandedQuestionActive]);

  const updateActiveQuestion = (isActive) => {
    setActiveQuestion(isActive);
    if (!isActive) {
      let updateValue = [...listHardRuleQuestion];

      const updateValueIndex = updateValue.findIndex(
        (item) => item.question_uuid === parentQuestionData.parent_question_id,
      );
      if (updateValueIndex > -1) {
        updateValue[updateValueIndex].isHard = 0;
        updateValue[updateValueIndex].is_hard = 0;
        setIsLocked(false);
        setListHardRuleQuestion(updateValue);
      }
    }
    const expanedQuestion = listExpandedQuestionActive.find(
      (question) => question.questionUUID === parentQuestionData.parent_question_id,
    );
    if (!expanedQuestion) {
      setListExpandedQuestionActive([
        ...listExpandedQuestionActive,
        {
          questionUUID: parentQuestionData.parent_question_id,
          questionContentEn: parentQuestionData.parent_question_content,
          questionContentNo: parentQuestionData.parent_question_no_ver,
          isActive,
        },
      ]);
    } else {
      setListExpandedQuestionActive((prevState) => {
        const updateValue = [...prevState];
        const expanedQuestionIndex = updateValue.findIndex(
          (question) => question.questionUUID === parentQuestionData.parent_question_id,
        );

        updateValue[expanedQuestionIndex] = { ...prevState[expanedQuestionIndex], isActive };
        return updateValue;
      });
    }
  };
  const handleLockQuestion = () => {
    let listHardRuleQuestionUpdate = listHardRuleQuestion;
    const foundQuestionIndex = listHardRuleQuestionUpdate.findIndex(
      (item) => parentQuestionData.parent_question_id === item.question_uuid,
    );
    if (listHardRuleQuestionUpdate[foundQuestionIndex]?.always) {
      Toast({ icon: 'error', title: t('question-blocked') });
      return;
    }
    if (isLocked && foundQuestionIndex > -1) {
      listHardRuleQuestionUpdate[foundQuestionIndex].isHard = 0;

      setIsLocked(false);
    } else if (foundQuestionIndex > -1) {
      listHardRuleQuestionUpdate[foundQuestionIndex].isHard = 1;
      setIsLocked(true);
    } else {
      listHardRuleQuestionUpdate.push({
        question_uuid: parentQuestionData.parent_question_id,
        isHard: 1,
      });
      setIsLocked(true);
    }
    setListHardRuleQuestion(listHardRuleQuestionUpdate);
  };

  if (customerType === HOSPITAL_ENV) {
    if (kommuneTypeQuestionProperty.includes(questionData.questionProperty)) {
      return;
    }
  }

  if (customerType === CLINICSL_ENV) {
    if (hospitalTypeQuestionProperty.includes(questionData.questionProperty)) {
      return;
    }
  }

  const disabled = !isEditabled ||isReadOnly

  return (
    <>
      <div className="calendar-plan-survey-form__question-header">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h3 htmlFor="" className="calendar-plan-survey-form__question">
            {index}.{' '}
            {t(
              `${
                language === 'NO'
                  ? parentQuestionData.parent_question_no_ver
                  : parentQuestionData.parent_question_content
              }`,
            )}
          </h3>
        </div>
      </div>

      <div className="calendar-plan-survey-form__answer-group">
        {activeQuestion ? (
          <>
            {questionData?.answerList?.map((answer, index) => {
              const answerKeys = Object.keys(answer);
              return (
                <AnswerItem
                  key={answer[answerKeys[0]]}
                  parentAnswerType={parentAnswerType}
                  answer={answer}
                  answerKeys={answerKeys}
                  surveyRecord={surveyRecord}
                  questionData={questionData}
                  handleOnChange={handleOnChange}
                  index={index}
                  setSurveyRecord={setSurveyRecord}
                  listTopic={listTopic}
                  isDisabled={disabled}
                />
              );
            })}
          </>
        ) : null}
      </div>
    </>
  );
};

export default ExpanedQuestion;
