import React, { memo } from 'react';

import TableSetting from '../components/TableSetting';
import TurnusMainSection from './components/TurnusMainSection';

const Turnus = () => {
  return (
    <div>
      <TableSetting />
      <TurnusMainSection />
    </div>
  );
};

export default memo(Turnus);
