import React, { useState, useCallback, useRef, useContext, memo } from 'react';
import { t } from 'i18next';
import { Toast } from 'helper/alert';

import PlanSelect from './PlanSelect';
import TableFilter from './TableFilter';
import OptimizeButton from '../OptimizeButton/OptimizeButton';

import {
  CloseIcon,
  CopyBrownIcon,
  DownloadBrownIcon,
  LeftArrowIcon,
  UploadBrownIcon,
  VerticalDot,
} from 'assets/img';

import './styles.scss';
import SendScheduleButton from '../EmployeeGroupAction/SendScheduleButton';
import { useClickOutside } from 'helper/useClickOutSide';
import {
  updateListLockedAction,
  updatePlanDetailLockedAction,
  updatePlanSelectedAction,
} from 'redux/calendarPlan/calendarPlanAction';
import { useDispatch, useSelector } from 'react-redux';
import ScheduleNotification from '../ScheduleNotification/ScheduleNotification';
import { clearSession, saveSession } from 'helper/localStorage';
import { UploadOutlined } from '@ant-design/icons';
import { uploadTurnusPlan } from './Helper/uploadTurnusPlan';
import { openModal, selectModal } from '_components/Modal/slice';
import { UPLOAD_TURNUS_REPORT } from './constants/modal';
import TurnusUploadReport from './TurnusUploadReport/TurnusUploadReport';
import axiosClient from 'axios/axiosClient';
import { selectCalendarPlan } from 'redux/calendarPlan/calendarPlanSlice';
import { TurnusContext } from '../../Turnus/Turnus';
import { useTurnusContext } from '../../Turnus/TurnusContext';
import { selectAuth } from 'redux/auth/authSlice';

const TableSetting = () => {
  const turnusData = useTurnusContext();
  const {
    planSelected,
    unitSelected,
    employeeData,
    refetchEmployeeList,
    // 3 tables
    taskListRef,
    setIsMainContent,
    refetchPlanChildList,
    setPlanSelected,
  } = turnusData;
  const modal = useSelector(selectModal);
  const { isEditabled } = useSelector(selectAuth);
  const dispatch = useDispatch();

  const [openTableAction, setOpenTableAction] = useState(false);
  const [reportData, setReportData] = useState({});
  const turnusFileInputRef = useRef(null);
  const buttonRef = useRef(null);

  const openUploadReport = () => {
    dispatch(
      openModal({
        name: UPLOAD_TURNUS_REPORT,
      }),
    );
  };

  const handleImportTurnusData = () => {
    axiosClient
      .post('calendar-plan/turnus/import-upload-data', {
        planID: planSelected.id,
        validShiftList: reportData.validShiftList,
      })
      .then((data) => {
        dispatch(updatePlanDetailLockedAction(data));
        dispatch(
          updateListLockedAction({
            ...data,
            uuid: planSelected.uuid,
          }),
        );
        refetchEmployeeList();
        Toast({ icon: 'success', title: t('file-import-success') });
      });
  };
  // Excel export
  const handleExport = useCallback(() => {
    taskListRef.current.exportDataAsExcel({
      allColumns: true,
      fileName: planSelected.planName,
    });
  }, [taskListRef]);
  const handleCopy = () => {
    const csvData = taskListRef.current.getDataAsCsv();
    let dataCopied = [];
    for (const string of csvData.split(',')) {
      dataCopied.push(string);
    }
    navigator.clipboard
      .writeText(dataCopied.join('\t'))
      .then(() => {
        Toast({
          icon: 'success',
          title: `${t('copy')} ${t('data')} ${t('success')}`,
        });
      })
      .catch((err) => {
        console.error('Error copying table data to clipboard:', err);
      });
  };

  useClickOutside(buttonRef, () => {
    if (openTableAction) setOpenTableAction(false);
  });
  const resetAction = () => {
    setIsMainContent(true);
    saveSession('isMainContent', true);
    clearSession('currentNavTab');
    clearSession('lastColEdited');
    clearSession('lastRowEdited');
    clearSession('generator-search-value');
    dispatch(updatePlanSelectedAction({}));
    refetchPlanChildList();
    setPlanSelected({});
  };
  return (
    <div className="table-settings__wrap">
      <div className={`back-to-main ${!isEditabled ? 'view-only' : ''}`}>
        <div className="back-to-main__item" onClick={resetAction}>
          <img src={LeftArrowIcon} /> <p>{t('rotation-schedules')}</p> /
          <p>{planSelected.planName}</p>
        </div>
        <div className="back-to-main__item" onClick={resetAction}>
          <img src={CloseIcon} /> <p>{t('close')}</p>
        </div>
      </div>
      <div className={`table-setting ${!isEditabled ? 'view-only' : ''}`}>
        <div className="generator-plan-info">
          <h1 className="plan-name">{planSelected.plan_name || planSelected.planName}</h1>
          <h4 className="description">
            {t('staff-plan')}: {planSelected.parentName}
          </h4>
        </div>
        <div className="table-setting-btns">
          {modal.name === UPLOAD_TURNUS_REPORT && reportData ? (
            <TurnusUploadReport
              reportData={reportData}
              handleImportTurnusData={handleImportTurnusData}
            />
          ) : null}
          <>
            {/* {employeeData.length > 0 && (
              <SendScheduleButton
                alter={true}
                unitSelected={unitSelected}
                planID={planSelected.id}
                versionID={planSelected.versionID}
              />
            )} */}

            <OptimizeButton />
            {employeeData.length > 0 && (
              <>
                {/* <ScheduleNotification /> */}

                <button
                  ref={buttonRef}
                  className="primary-btn download-btn"
                  onClick={() => setOpenTableAction(!openTableAction)}
                >
                  <img src={VerticalDot} />
                </button>
                {/* Hidden input */}
                <input
                  type="file"
                  className="hide-element"
                  ref={turnusFileInputRef}
                  onChange={(e) =>
                    uploadTurnusPlan(
                      e,
                      planSelected,
                      unitSelected,
                      openUploadReport,
                      setReportData,
                      handleImportTurnusData,
                    )
                  }
                  accept="xlsx,xls"
                />
                {openTableAction && (
                  <div className="vertical-dot-menu">
                    <div onClick={handleExport}>
                      <img src={DownloadBrownIcon} />
                      <p>{t('download-plan')}</p>
                    </div>
                    {/* Redirect to file input */}
                    <div
                      onClick={() => {
                        if (!isEditabled) return;
                        turnusFileInputRef.current.click();
                      }}
                    >
                      <img src={UploadBrownIcon} />

                      <p>{t('upload-plan')}</p>
                    </div>
                    <div onClick={handleCopy}>
                      <img src={CopyBrownIcon} />
                      <p>{t('copy-plan')}</p>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default memo(TableSetting);
