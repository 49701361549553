import { loadState } from 'helper/localStorage';
import environmentComponent from './environmentComponent.json';

/* **************************************************
YOU ALSO CAN INTEGRATE RENDER NAV BAR BY THIS HELPER

WHEN CREATE COMPONENT OR PAGE PLEASE DEFINE IT'S NAME
* EXAMPLE: 
        + const COMPONENT_KEY = "sick_leave_chart";u
        + const isWillBeRendered = isWillBeRendered(COMPONENT_KEY);

Component will be rendered if isWillBeRendered is true

*************************************************** */

const getEnvironmentName = () => {
  //GET APP NAME
  const environment = process.env.REACT_APP_SITE;
  return environment;
};

//WILL BE GET BY API IF NEEDED
const getLisComponent = () => {
  // IF CALLING LIST COMPONENT FROM API YOU NEED TO CALL IT AFTER LOGIN AND USE THAT LIST AS GLOBAL STATE

  const environmentName = getEnvironmentName();
  const defaultListComponent = environmentComponent.default;

  let listComponent = environmentComponent[environmentName];
  if (!listComponent) {
    listComponent = environmentComponent.default;
  }
  return { ...defaultListComponent, ...listComponent };
};

const isWillBeRendered = (componentKey, pageKey) => {
  if (!pageKey || !componentKey) throw new Error('Please input pageKey,componentKey');

  const listComponentInPage = getLisComponent()[pageKey]?.components;
  const permission = loadState('role');

  if (!listComponentInPage.length) return true;

  //CHECK IF COMPONENT EXISTS
  const componentFound = listComponentInPage.find(
    (component) => component.componentKey === componentKey,
  );

  if (!componentFound) throw new Error('Wrong component key');

  //CHECKING PERMISSION
  if (!componentFound.permission || !componentFound.permission.length) return true;

  const isMatchedPermission = permission === componentFound.permission;

  if (!isMatchedPermission) return false;

  return true;
};

export { getEnvironmentName, getLisComponent, isWillBeRendered };
