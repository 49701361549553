import { saveState } from 'helper/localStorage';
import {
  changeLang,
  changeTheme,
  deletePlanPatinesTask,
  getMinDate,
  initPlanPatiensTask,
  selectDayNight,
  selectDepartment,
  selectUnit,
  setlectTypeShiftByChatGPT,
  setlectUnitByChatGPT,
  unSelectDepartment,
  unSelectUnit,
  updatePlanPatinesTask,
  updatePlanPatinesTaskDetail,
  updatePlanPatinesTaskTime,
  setEditedEmp,
  useChangeEnvironment,
  updateGlobalSortEmployee,
  updateStatusOfStaffPlan,
} from './globalSlice';

export const changeEnvironmentAction = (val) => {
  return (dispatch) => {
    dispatch(useChangeEnvironment({ environment: val }));
  };
};

export const changeThemeAction = (val) => {
  return (dispatch) => {
    dispatch(changeTheme({ theme: val }));
    saveState('theme', val);
  };
};

export const setEditedEmpAction = (id) => {
  return (dispatch) => {
    dispatch(setEditedEmp(id));
  };
};

export const changeLangAction = (val) => {
  return (dispatch) => {
    dispatch(changeLang({ language: val }));
    saveState('language', val);
  };
};

export const selectDepartmentAction = (val, unitCodes, departmentName, ignoreUnitDay) => {
  return (dispatch) => {
    dispatch(
      selectDepartment({ departmentSelected: val, unitCodes, departmentName, ignoreUnitDay }),
    );
  };
};

export const unSelectDepartmentAction = (unitCodes, val, departmentName) => {
  return (dispatch) => {
    dispatch(unSelectDepartment({ departmentSelected: val, unitCodes, departmentName }));
  };
};

export const selectUnitAction = (val, departmentCode, unitName) => {
  return (dispatch) => {
    dispatch(selectUnit({ unitSelected: val, departmentCode, unitName }));
  };
};

export const unSelectUnitAction = (val, totalUnitOfDepartment, departmentCode, unitName) => {
  return (dispatch) => {
    dispatch(
      unSelectUnit({
        unitSelected: val,
        totalUnitOfDepartment,
        departmentSelected: departmentCode,
        unitName,
      }),
    );
  };
};

export const selectDayNightAction = (val) => {
  return (dispatch) => {
    dispatch(
      selectDayNight({
        dayNightSelected: val,
      }),
    );
  };
};
export const selectUnitByChatGPTAction = (val) => {
  return (dispatch) => {
    dispatch(setlectUnitByChatGPT(val));
  };
};
export const selectTypeShiftByChatGPTAction = (val) => {
  return (dispatch) => {
    dispatch(setlectTypeShiftByChatGPT(val));
  };
};

export const getMinDateAction = (val) => {
  return (dispatch) => {
    dispatch(
      getMinDate({
        minDate: val,
      }),
    );
  };
};
export const updateGlobalSortEmployeeAction = (val) => {
  return (dispatch) => {
    dispatch(
      updateGlobalSortEmployee({
        sortedBy: val,
      }),
    );
  };
};
export const updateStatusOfStaffPlanAction = (val) => {
  return (dispatch) => {
    dispatch(
      updateStatusOfStaffPlan({
        isMainContentStaffPlan: val,
      }),
    );
  };
};
