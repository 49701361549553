import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { t } from 'i18next';
import { v4 as uuidv4 } from 'uuid';

import UploadEmp from './component/UploadEmp/UploadEmp';
import BulkCreateEmp from './component/BulkCreateEmp/BulkCreateEmp';
import SingleCreateEmp from './component/SingleCreateEmp/SingleCreateEmp';

import axiosClient from 'axios/axiosClient';
import { Toast, showLoading } from 'helper/alert';
import { AlertIcon, CloseBlackIcon, UndoIcon } from 'assets/img';
import { closeModal } from '_components/Modal/slice';
import {
  BULK_CREATE,
  DEFAULT_ROW,
  SINGLE_CREATE,
  UPLOAD_CREATE,
} from '_components/CarlendarPlan/EmployeeV2/constants/modal';
import { isEmailValid, isPhoneValid } from 'helper/dateTime';
import {
  createEmptyRow,
  validateBulkData,
  validateRowData,
} from './component/BulkCreateEmp/helper';
import { Undo2 } from 'assets/img';

import './styles.scss';
import { useRoleTypes } from 'Hooks/useData';

const CreateEmpModal = ({ refetchEmployees, unitSelected, roleList, groupList, employees }) => {
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState(SINGLE_CREATE);
  const [isShowingOld, setIsShowingOld] = useState(false);
  const [undoEnabled, setUndoEnabled] = useState(false);
  const [formData, setFormData] = useState({
    [SINGLE_CREATE]: {
      name: '',
      email: '',
      phone_number: '+47 ',
      job_percentage: '',
      group: null,
      group_id: null,
      emp_id_number: '',
      salary: '',
      role_id: '',
      role_type: '',
      role_type_name: '',
      error: {},
    },
    [BULK_CREATE]: Array.from({ length: DEFAULT_ROW }, () => createEmptyRow(uuidv4())),
    [UPLOAD_CREATE]: {},
  });
  const [isTableError, setIsTableError] = useState({});
  const { data: roleTypeList } = useRoleTypes();
  const bulkCreateEmpGridRef = useRef();

  const navTabList = [
    {
      key: SINGLE_CREATE,
    },
    {
      key: BULK_CREATE,
    },
    // {
    //   key: UPLOAD_CREATE,
    // },
  ];

  const showingAllEmployee = () => {
    setIsShowingOld((prevShowing) => !prevShowing);
    if (isShowingOld) {
      setFormData((prev) => {
        const bulkList = [...prev[BULK_CREATE]].filter((employee) => !employee.isOldEmployee);
        return { ...prev, [BULK_CREATE]: bulkList };
      });
      return;
    }
    const listOldEmployee = employees.map((employee) => {
      const {
        uuid,
        name,
        email,
        phone_number,
        job_percentage,
        role,
        role_id,
        group,
        group_id,
        salary,
        emp_id_number,
        role_type,
      } = employee;
      return {
        id: uuid,
        uuid,
        name,
        email,
        phone: phone_number,
        jobPercentage: `${job_percentage}`,
        role,
        role_type,
        role_id,
        group,
        group_id,
        salary: salary ? `${salary}` : null,
        emp_id_number,
        isOldEmployee: true,
        rowError: {
          name: false,
          email: false,
          phone: false,
          jobPercentage: false,
          role: false,
          group: false,
          salary: false,
          emp_id_number: false,
        },
      };
    });
    setFormData((prev) => {
      const bulkList = [...listOldEmployee, ...prev[BULK_CREATE]];
      return { ...prev, [BULK_CREATE]: bulkList };
    });
  };

  const renderNavTabs = useCallback(() => {
    return (
      <div className="create-emp-modal__content-nav">
        {navTabList.map((navTab, index) => (
          <p
            key={index}
            className={currentTab === navTab.key && 'active'}
            onClick={() => setCurrentTab(navTab.key)}
          >
            {t(navTab.key)}
          </p>
        ))}
      </div>
    );
  }, [currentTab]);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const isSubmitable = useMemo(() => {
    const currentData = formData[currentTab];

    const checks = {
      [SINGLE_CREATE]: () =>
        !!(
          currentData.name &&
          currentData.role_id &&
          currentData.job_percentage &&
          currentData.role_type
        ),
      [BULK_CREATE]: () => currentData.length > 0,
      default: () => false,
    };

    return (checks[currentTab] || checks.default)();
  }, [formData, currentTab]);

  const handleCreate = useCallback(async () => {
    if (!isSubmitable) return;

    const currentData = formData[currentTab];

    const createSingleEmployee = async (employeeInfo) => {
      showLoading({ show: true, title: t('create') });
      try {
        await axiosClient.post('calendar-plan/employee/create', {
          employeeInfo,
        });
        await refetchEmployees();
        handleCloseModal();
        showLoading({ shouldShow: false });

        Toast({
          icon: 'success',
          title: `${t('create')} ${t('employee')} ${t('success')}`,
        });
      } catch (error) {
        console.error('Error adding employee:', error);
        showLoading({ shouldShow: false });
        Toast({ icon: 'error', title: t('create-fail') });
      }
    };

    const createBulkEmployees = async (unitCode, employeeList) => {
      showLoading({ shouldShow: true, title: t('create') });
      const { validList = [], duplicateList = [] } = validateBulkData(employeeList, employees);
      try {
        await axiosClient
          .post('calendar-plan/employee/bulk-create', {
            unitCode,
            employeeList: [...validList, ...duplicateList],
          })
          .then(async (result) => {
            await refetchEmployees();
            handleCloseModal();
            showLoading({ shouldShow: false });

            Toast({ icon: 'success', title: t('create-success') });
          });
      } catch (error) {
        console.error(error);
        showLoading({ shouldShow: false });
        Toast({ icon: 'error', title: t('create-fail') });
      }
    };

    if (currentTab === SINGLE_CREATE) {
      const { phone_number, email } = currentData;

      const employeeInfo = {
        ...currentData,
        phone_number: '',
        medical_delivery: false,
        sent_survey: false,
        unit_code: unitSelected,
      };

      if (phone_number.replace(/^\+47/g, '').replace(/\s/g, '').trim().length) {
        if (!isPhoneValid(phone_number)) {
          Toast({
            icon: 'error',
            title: t('wrong-phone-format'),
          });
          return;
        }
        employeeInfo.phone_number = phone_number.replace(/\s/g, '');
      }
      if (email && !isEmailValid(email)) {
        Toast({
          icon: 'error',
          title: t('wrong-email-format'),
        });
        return;
      }

      await createSingleEmployee(employeeInfo);
    } else if (currentTab === BULK_CREATE) {
      const { isValid, message, data } = validateRowData(formData[currentTab]);
      if (!isValid) {
        Toast({
          icon: 'error',
          title: t(message),
        });
        return;
      }
      await createBulkEmployees(unitSelected, data);
    } else {
      Toast({
        icon: 'error',
        title: `${t('create')} ${t('employee')} ${t('unsuccess')}`,
      });
    }
  }, [formData, currentTab, isSubmitable]);

  const renderNavContent = useCallback(() => {
    switch (currentTab) {
      case SINGLE_CREATE:
        return (
          <SingleCreateEmp
            refetchEmployees={refetchEmployees}
            roleList={roleList}
            groupList={groupList}
            formData={formData}
            setFormData={setFormData}
            roleTypeList={roleTypeList}
          />
        );
      case BULK_CREATE:
        return (
          <BulkCreateEmp
            unitSelected={unitSelected}
            rowData={formData[BULK_CREATE]}
            formData={formData}
            setFormData={setFormData}
            isTableError={isTableError}
            setIsTableError={setIsTableError}
            showingAllEmployee={showingAllEmployee}
            employees={employees}
            isShowingOld={isShowingOld}
            roleTypeList={roleTypeList}
            setUndoEnabled={setUndoEnabled}
            onSetGridRef={(ref) => (bulkCreateEmpGridRef.current = ref.current)}
          />
        );
      // TODO: Implement uploading feature
      // case UPLOAD_CREATE:
      //   return <UploadEmp refetchEmployees={refetchEmployees} />;
      default:
        return <SingleCreateEmp refetchEmployees={refetchEmployees} />;
    }
  }, [currentTab, unitSelected, roleList, groupList, formData, isTableError]);

  const renderError = (errors, altError = false) => {
    const tableErrorMessage = {
      // name: { message: 'name-required' },
      email: { message: 'wrong-email-format' },
      phone: { message: 'wrong-phone-format' },
      role: { message: 'wrong-role-format' },
      group: { message: 'wrong-group-format' },
      jobPercentage: { message: 'wrong-job-percent-format' },
    };

    return (
      <div className="create-emp-modal__content-error">
        {Object.keys(errors)
          .filter((key) => errors[key])
          .map((key, index) => (
            <p key={index}>
              <img src={AlertIcon} width={14} height={14} />
              {altError ? t(tableErrorMessage[key].message) : t(errors[key])}
            </p>
          ))}
      </div>
    );
  };

  const renderErrorMessage = useCallback(() => {
    const currentData = formData[currentTab];

    if (currentTab === BULK_CREATE) {
      const nonEmptyRows = currentData.filter(
        (row) => row.name || row.email || row.phone || row.jobPercentage || row.role || row.group,
      );

      if (nonEmptyRows.length) {
        const errorKey = {
          // name: nonEmptyRows.every((row) => row.rowError.name),
          email: nonEmptyRows.some((row) => row.rowError.email),
          phone: nonEmptyRows.some((row) => row.rowError.phone),
          group: nonEmptyRows.some((row) => row.rowError.group),
          jobPercentage: nonEmptyRows.some((row) => row.rowError.jobPercentage),
          role: nonEmptyRows.some((row) => row.rowError.role),
        };

        if (Object.values(errorKey).some((error) => error)) {
          return renderError(errorKey, true);
        }
        return null;
      }
    }

    if (currentData.error) {
      return renderError(currentData.error);
    }

    return null;
  }, [currentTab, formData, isTableError]);

  const handleUndo = () => {
    bulkCreateEmpGridRef.current.api.undoCellEditing();
  };

  return (
    <div className="create-emp-modal">
      <div className="create-emp-modal__background" />
      <div className="create-emp-modal__content">
        <p>{t('add-employees')}</p>
        <img src={CloseBlackIcon} onClick={handleCloseModal} />
        {renderNavTabs()}
        <div className="create-emp-modal__content-nav-content">{renderNavContent()}</div>
        {renderErrorMessage()}
        <div className="create-emp-modal__content-btns">
          {undoEnabled && (
            <button className="create-emp-modal__undo-btn" onClick={handleUndo}>
              <img src={Undo2} alt="" width={20} />
              {t('undo')}
            </button>
          )}
          <button onClick={handleCloseModal}>{t('cancel')}</button>
          <button disabled={!isSubmitable} onClick={handleCreate}>
            {t('add')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateEmpModal;
