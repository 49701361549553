import { Cookies } from 'react-cookie';
import { persistor } from 'app/store';

// localStorage.js
export const loadState = (key) => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (key, value) => {
  const serializedState = JSON.stringify(value);
  localStorage.setItem(key, serializedState);
};

export const removeState = (key) => {
  localStorage.removeItem(key);
};

export const loadSession = (key) => {
  try {
    const serializedState = sessionStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveSession = (key, value) => {
  try {
    const serializedState = JSON.stringify(value);
    sessionStorage.setItem(key, serializedState);
  } catch {
    // ignore write errors
  }
};

export const clearSession = (key) => {
  sessionStorage.removeItem(key);
};
export const clearCookie = () => {
  const cookies = new Cookies();
  const savedUsername = loadState('remember');
  const savedAppVersion = loadState('appVersion');
  localStorage.clear();
  cookies.remove('username');
  cookies.remove('role');
  cookies.remove('accessToken');
  cookies.remove('employeeToken');
  cookies.remove('token');
  persistor.purge();
  removeState('persist:root');
  if (savedUsername) saveState('remember', savedUsername);
  if (savedAppVersion) saveState('appVersion', savedAppVersion);
};
export const getCoockie = (key) => {
  const cookies = new Cookies();

  cookies.get(key);
};

export const clearStorage = () => {
  const cookies = new Cookies();
  const savedUsername = loadState('remember');
  const savedAppVersion = loadState('appVersion');
  localStorage.clear();
  sessionStorage.clear();
  cookies.remove('username');
  cookies.remove('role');
  cookies.remove('accessToken');
  cookies.remove('employeeToken');
  cookies.remove('token');
  persistor.purge();
  removeState('persist:root');
  if (savedUsername) saveState('remember', savedUsername);
  if (savedAppVersion) saveState('appVersion', savedAppVersion);
};
