const SURVEY_FORM = 'survey-form';

const UPLOAD_EMPLOYEES_FORM = 'upload-employee-form';

const SEND_SURVEY_FORM = 'send-survey-form';

const ADD_GROUP = 'add-group';

const ADD_NEW_ROLE_TYPE = 'add-new-role-type';

const ADD_ROLE = 'add-role';

const ADD_NEW_EMPLOYEE = 'add-new-employee';

const SIDE_MENU = 'side_menu';

const SEND_EMP_SURVEY = 'send-emp-survey';

const DUPLICATE_WEEKS = 'duplicate-weeks';

const ADD_TASK_CODE = 'add-task-code';
const ADD_LAYER = 'add-layer';
const ADD_GROUP_POSITION = 'add-group-position';

const SINGLE_CREATE = 'single-create';
const BULK_CREATE = 'bulk-create';
const BULK_EDIT = 'build-edit';
const UPLOAD_CREATE = 'upload-create';
const DEFAULT_ROW = 200;

export {
  BULK_EDIT,
  SEND_EMP_SURVEY,
  SURVEY_FORM,
  SEND_SURVEY_FORM,
  UPLOAD_EMPLOYEES_FORM,
  ADD_GROUP,
  ADD_ROLE,
  ADD_NEW_EMPLOYEE,
  SINGLE_CREATE,
  BULK_CREATE,
  UPLOAD_CREATE,
  DEFAULT_ROW,
  SIDE_MENU,
  ADD_NEW_ROLE_TYPE,
  DUPLICATE_WEEKS,
  ADD_TASK_CODE,
  ADD_LAYER,
  ADD_GROUP_POSITION,
};
