import React, { useCallback, useMemo, useRef, useState } from 'react';
import { t } from 'i18next';

import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import {
  IN_SUMMARY,
  PER_POSITION_CATEGORY,
  TAB_SUMMARY_SCHEDULE,
  TAB_TURNUS,
  weekdayKey,
} from 'constant';
import { enumerateDaysBetweenDates } from 'helper/enumerateDaysBetweenDates';
import { CheckOutlined } from '@ant-design/icons';
import './ShiftSummary.scss';
import { DownArrow } from 'assets/img';
import { useDispatch } from 'react-redux';
import { updateApi } from 'redux/generateTableApi/slice';
import { useTurnusContext } from '../../Turnus/TurnusContext';

const SummaryCell = ({ data, isVakant, totalData, isRoleOrGroup }) => {
  if (isVakant) {
    if (data > 0)
      return (
        <div className={`summary-value not-full-fill `}>
          <span>{data} </span>
        </div>
      );
    return '';
  }
  if (data === totalData || totalData === 0) {
    return (
      <div className={`summary-value `}>
        <CheckOutlined />
      </div>
    );
  }
  return (
    <div className={`summary-value ${data > totalData ? 'not-full-fill-role' : 'not-full-fill'}  `}>
      <span>{data}</span>/<span>{totalData}</span>
    </div>
  );
};

const ShiftSummary = ({ planSelected, onFirstDataRendered, currentNavTab }) => {
  const summaryRef = useRef();
  const dispatch = useDispatch();
  const [isCollapse, setIsCollapse] = useState(true);
  const dateRange = enumerateDaysBetweenDates(planSelected.from_date, planSelected.to_date);
  const turnusData = useTurnusContext();
  const {
    globalSortEmployee,
    layerList,
    summaryData,
    weekRange,
    summarySelectedView,
    setSummarySelectedView,
  } = turnusData;
  const summaryOption = [{ key: IN_SUMMARY }, { key: PER_POSITION_CATEGORY }];
  // const [selectedView, setSelectedView] = useState(IN_SUMMARY);
  const onChangeView = (e) => {
    e.target.value;
    setSummarySelectedView(e.target.value);
  };
  const getSummaryHeaderList = useCallback(() => {
    const defaultHeader = [
      {
        headerName: t('day'),
        isHead: false,
        dataKey: 'dayShift',
        totalDataKey: 'totalDayShift',
      },

      {
        headerName: t('late'),
        isHead: false,
        dataKey: 'lateShift',
        totalDataKey: 'totalLateShift',
      },

      {
        headerName: t('night'),
        isHead: false,
        dataKey: 'nightShift',
        totalDataKey: 'totalNightShift',
      },

      {
        headerName: t('long-shift'),
        isHead: false,
        dataKey: 'long-shift',
        totalDataKey: 'totalLongShift',
      },

      {
        headerName: t('other-shift'),
        isHead: false,
        dataKey: 'other-shift',
        totalDataKey: 'totalOtherShift',
      },
    ];
    if (summarySelectedView === IN_SUMMARY) {
      return defaultHeader;
    } else {
      const result = [];
      if (!layerList.length) {
        return defaultHeader;
      }
      layerList.forEach((item) => {
        result.push({
          headerName: item.name,
          isHead: true,
          dataKey: item.name,
          totalDataKey: 'total' + item.name,
        });
      });
      return result;
    }
  }, [summarySelectedView, layerList, summaryData]);
  const summaryHeaderList = getSummaryHeaderList();
  const renderSummaryHeader = useCallback(() => {
    const activeHeader = [];
    if (summarySelectedView === PER_POSITION_CATEGORY) {
      summaryHeaderList.forEach((header) => {
        activeHeader.push(header);
        const subHeaderSet = new Set();
        for (const date of Object.keys(summaryData)) {
          if (summaryData[date][header.headerName]) {
            const listSubKey = Object.keys(summaryData[date][header.headerName]);
            for (const key of listSubKey) {
              if (key === 'total') {
                continue;
              }
              if (summaryData[date][header.headerName][key]) {
                subHeaderSet.add(key);
              }
            }
          }
        }
        subHeaderSet.forEach((item) => {
          const dateData = {};
          for (const date of Object.keys(summaryData)) {
            if (summaryData[date][header.headerName]) {
              dateData[date] = summaryData[date][header.headerName][item];
            }
          }
          activeHeader.push({
            headerName: t(item),
            isHead: false,
            layerName: header.headerName,
            typeShift: item,
            dataKey: item + header.headerName,
            totalDataKey: item,
            ...dateData,
          });
        });
      });

      return activeHeader;
    } else {
      summaryHeaderList.forEach((header) => {
        const totalDataKey = header.totalDataKey;

        for (const date of Object.keys(summaryData)) {
          if (summaryData[date][totalDataKey] > 0) {
            const additionalHeader = { ...header };
            for (const date of Object.keys(summaryData)) {
              additionalHeader[date] = summaryData[date][additionalHeader.dataKey];
            }
            const requirementKey = additionalHeader.requirement;
            if (requirementKey) {
              const requirementString = Array.from(summaryData[date][requirementKey]).join(', ');
              additionalHeader.headerName = additionalHeader.headerName + ' ' + requirementString;
            }
            activeHeader.push(additionalHeader);
            break;
          }
        }
      });
      return [...activeHeader];
    }
  }, [summaryHeaderList, summaryData, summarySelectedView, layerList]);
  const summaryHeader = renderSummaryHeader();

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      menuTabs: [],
      flex: 1,
    }),
    [],
  );
  const weekdayHeader = useMemo(() => {
    const result = [];
    if (weekRange && summaryHeader.length) {
      for (const header of summaryHeader) {
        const col = weekRange.map(({ week, dateString }, weekIndex) => {
          return weekdayKey.map((day, dayIndex) => {
            const dateInWeek = dateRange[weekIndex * 7 + dayIndex];
            const colId = !header.isHead ? `${header.dataKey}-${dateInWeek}` : header.ishead;
            return {
              // headerName: t(day).charAt(0).toUpperCase(),
              headerClass: ['sat', 'sun'].includes(day)
                ? `day-dark-header  end-of-week`
                : 'task-day-cell-header day-dark-header',
              colId: colId?.trim(),
              field: dateInWeek,
              valueGetter: ({ data }) => {
                if (!data) return;

                data[dateInWeek];
              },
              cellClass:
                dayIndex === weekdayKey.length - 1 ? 'end-of-group task-day-cell' : 'task-day-cell',
              minWidth: 40,
              cellStyle: (params) => {
                const rowIndex = params.node.rowIndex;
                const cellStyle = { textAlign: 'center' };

                const isHead = (summaryHeader[rowIndex] && summaryHeader[rowIndex].isHead) || false;
                if (isHead) {
                  cellStyle.backgroundColor = '#EAEBEC';
                }
                if (day === 'sun') {
                  cellStyle.borderRight = '2px solid #EAEBEC';
                }
                return cellStyle;
              },

              cellRenderer: (params) => {
                const rowIndex = params.node.rowIndex;
                const headerInfo = summaryHeader[rowIndex] && summaryHeader[rowIndex];
                if (!headerInfo) {
                  return;
                }
                if (headerInfo?.isHead) return;

                const dataKey = headerInfo.dataKey;
                const isVakant = headerInfo.isVakant;
                const layerName = headerInfo.layerName;
                const typeShift = headerInfo.typeShift;
                const totalDataKey = headerInfo.totalDataKey;
                const isRoleOrGroup = headerInfo.isRoleOrGroup;
                let data = summaryData[dateInWeek] ? summaryData[dateInWeek][dataKey] : 0;
                let totalData = 0;
                if (summarySelectedView === PER_POSITION_CATEGORY) {
                  if (summaryData[dateInWeek] && summaryData[dateInWeek][layerName]) {
                    data =
                      summaryData[dateInWeek] && summaryData[dateInWeek][layerName]
                        ? summaryData[dateInWeek][layerName][typeShift]
                        : 0;
                  }

                  if (
                    summaryData[dateInWeek] &&
                    summaryData[dateInWeek][layerName] &&
                    summaryData[dateInWeek] &&
                    summaryData[dateInWeek][layerName].total
                  ) {
                    totalData =
                      summaryData[dateInWeek] && summaryData[dateInWeek][layerName]
                        ? summaryData[dateInWeek][layerName]?.total[totalDataKey]
                        : 0;
                  }
                } else {
                  totalData = summaryData[dateInWeek] ? summaryData[dateInWeek][totalDataKey] : 0;
                }
                return (
                  <SummaryCell
                    data={params.data[dateInWeek]}
                    totalData={totalData}
                    isVakant={isVakant}
                    isEndOffWeek={day === 'sun'}
                    isRoleOrGroup={isRoleOrGroup}
                  />
                );
              },
            };
          });
        });
        result.push(col.flat());
      }
    }
    return result;
  }, [weekRange, dateRange, summarySelectedView, summaryData]);
  const empInfoTableWidth = 367 + 20; // Page padding 20px
  const columnDefs = useMemo(
    () => [
      {
        // headerName: t('Oppsummering'),
        width: empInfoTableWidth,

        resizable: false,
        pinned: true,
        headerClass: 'day-dark-header',
        valueGetter: (params) => {
          const rowIndex = params.node.rowIndex;
          return summaryHeader[rowIndex] && summaryHeader[rowIndex].headerName;
        },
        cellStyle: (params) => {
          const rowIndex = params.node.rowIndex;
          const cellStyle = {};

          const isHead = (summaryHeader[rowIndex] && summaryHeader[rowIndex].isHead) || false;
          if (isHead) {
            cellStyle.backgroundColor = '#EAEBEC';
            cellStyle.fontWeight = 600;
          }
          return cellStyle;
        },
      },
      ...weekdayHeader.flat(),
    ],
    [summaryData, weekdayHeader],
  );
  const onSummaryGridReady = (params) => {
    summaryRef.current = params.api;
    dispatch(updateApi({ key: 'summary', value: { api: params.api, colApi: params.columnApi } }));
    if (globalSortEmployee) {
      params.columnApi.applyColumnState(globalSortEmployee);
    }
  };
  const getRowId = useCallback((params) => {
    return params.data.dataKey.trim();
  }, []);
  const tableHeight = useMemo(() => {
    if (isCollapse) {
      return 0;
    }
    if (summaryHeader.length * 50 > 292) {
      return 292; //Base on design max height is 292px
    } else {
      return summaryHeader.length * 50; // 50px each row
    }
  }, [summaryHeader, isCollapse]);
  return (
    <div className={`summary__wrap ${currentNavTab !== TAB_TURNUS ? 'hide-element' : ''}`}>
      <div className={`collapse-summary  `}>
        <div className="collapse-item" onClick={() => setIsCollapse((prev) => !prev)}>
          <img className={`${isCollapse ? 'rotate' : ''}`} src={DownArrow} alt="" width={'16px'} />
          {t(TAB_SUMMARY_SCHEDULE)}
        </div>
        <div className="collapse-item">
          <select name="" id="" value={summarySelectedView} onChange={onChangeView}>
            {summaryOption.map((item) => {
              if (!layerList.length && item.key === PER_POSITION_CATEGORY) {
                return;
              }
              return (
                <option key={`${item.key}-summary`} value={item.key}>
                  {t(item.key)}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div
        className={`ag-theme-alpine summary ${!isCollapse ? 'active' : ''} `}
        id="shift-summary-wrapper"
        style={{ height: `${tableHeight}px` }}
      >
        <AgGridReact
          ref={summaryRef}
          onGridReady={onSummaryGridReady}
          rowData={summaryHeader}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          animateRows={true}
          enableCharts={true}
          enableRangeSelection={true}
          immutableData={true}
          tooltipShowDelay={0}
          rowHeight={40}
          onFirstDataRendered={onFirstDataRendered}
          getRowId={getRowId}
        />
      </div>
    </div>
  );
};

export default ShiftSummary;
