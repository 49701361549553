import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { enumerateDaysBetweenDates } from './enumerateDaysBetweenDates';
import { MAXIMUM_WEEK_WORKING_HOUR, maximumWorkingHour } from 'constant';
import { t } from 'i18next';
import { getMinusPaidTimeBreak } from './calculatePaidBreak';

export const getTaskTranslation = (task, isNo) => {
  const taskTranslations = {
    'Night Shift': isNo ? 'Nattvakt' : 'Night Shift',
    'Day Shift': isNo ? 'Dagvakt' : 'Day Shift',
    'Long Shift': isNo ? 'Langvakt' : 'Long Shift',
    'Afternoon Shift': isNo ? 'Aftenvakt' : 'Afternoon Shift',
    'Other Shift': isNo ? 'Annat' : 'Other Shift',
  };

  return taskTranslations[task];
};

export const calculateHrPerWeek = ({
  data = [],
  weekRange = [],
  taskList = [],
  publicHoliday = [],
}) => {
  const taskHours = taskList.reduce((acc, task) => {
    let endTime = moment(task.endTime, 'HH:mm:ss');
    const fromTime = moment(task.fromTime, 'HH:mm:ss');
    if (endTime.isBefore(fromTime)) {
      endTime = endTime.add(1, 'days');
    }
    const minutes = Math.abs(endTime.diff(fromTime, 'minutes'));
    const hours = +minutes / 60;
    acc[task.taskID] = +hours;
    return acc;
  }, {});
  for (let empIndex in data) {
    const emp = data[empIndex];

    const totalHrsPerWeek =
      emp.totalHrsPerWeek && emp.totalHrsPerWeek > 0
        ? emp.totalHrsPerWeek
        : MAXIMUM_WEEK_WORKING_HOUR;

    emp.hrPerWeek = Object.entries(emp.task_arr).reduce((acc, [date, tasks]) => {
      if (tasks && tasks.length) {
        const task = tasks[0];
        const key = task.taskId;
        const taskHour = +taskHours[key];
        if (taskHour) {
          const week = weekRange.find((week) =>
            moment(date, 'YYYY-MM-DD').isBetween(
              moment(week.dateString.split(' - ')[0], 'DD-MM-YYYY'),
              moment(week.dateString.split(' - ')[1], 'DD-MM-YYYY'),
              'day',
              '[]',
            ),
          );
          if (week) {
            const minusPaidBreak = getMinusPaidTimeBreak(task, date, publicHoliday);

            acc[week.week] = (acc[week.week] || 0) + taskHour - minusPaidBreak;
          }
        }
      }

      return acc;
    }, {});
    if (emp.dayOff && emp.dayOff.length) {
      const offHrWeek = {};
      emp.dayOff.forEach((date) => {
        const week = weekRange.find((week) =>
          moment(date, 'YYYY-MM-DD').isBetween(
            moment(week.dateString.split(' - ')[0], 'DD-MM-YYYY'),
            moment(week.dateString.split(' - ')[1], 'DD-MM-YYYY'),
            'day',
            '[]',
          ),
        );
        const weekdDay = moment(date).weekday();
        if (week && weekdDay !== 0) {
          const plusValue = (emp.jobPercentage / 100) * (totalHrsPerWeek / 6);
          if (offHrWeek[week.week]) {
            offHrWeek[week.week] += plusValue;
          } else {
            offHrWeek[week.week] = plusValue;
          }
        }
      });
      emp.hrPerWeek = { ...offHrWeek, ...emp.hrPerWeek };
    }
  }

  return data;
};

export const calculateSummaryData = (data, taskTypeList) => {
  const newData = data
    .filter((item) => item.taskTypeValue >= 0)
    .map((item) => ({
      ...item,
      layerName: t('summary'),
      layerID: t('summary'),
      taskTypeName: taskTypeList.find(
        (type) => type.id === item.taskType || type.key === item.taskKey,
      )?.name,
    }));
  const result = newData.reduce((acc, item) => {
    if (!acc[item.taskTypeName]) {
      acc[item.taskTypeName] = item.taskList?.map((task) => ({
        date: task.date,
        numberPerson: Number(task.numberPerson),
      }));
    } else {
      const newTastList = {};
      acc[item.taskTypeName].forEach((task) => {
        newTastList[task.date] = (newTastList[task.date] || 0) + Number(task.numberPerson);
      });
      item?.taskList?.forEach((task) => {
        newTastList[task.date] = (newTastList[task.date] || 0) + Number(task.numberPerson);
      });
      acc[item.taskTypeName] = Object.entries(newTastList)?.map(([date, numberPerson]) => ({
        date,
        numberPerson,
      }));
    }
    return acc;
  }, {});

  const resultArray = Object.entries(result).map(([taskName, taskList]) => {
    return {
      isSummary: true,
      isCell: true,
      isHeader: false,
      taskName,
      taskList,
      layerName: t('summary'),
      layerID: t('summary'),
      taskUUID: uuidv4(),
      totalTaskCount: taskList.reduce((acc, task) => acc + Number(task.numberPerson), 0),
    };
  });

  return resultArray;
};

const calculateTotalTasksInRange = (start, end, planData) => {
  const taskList = planData.map((item) => {
    const startTime = moment(item.fromTime, 'HH:mm:ss');
    const endTime = moment(item.endTime, 'HH:mm:ss');
    const duration = moment.duration(endTime.diff(startTime));
    const durationInHours = duration.asHours();

    // taskList in range
    const tasksWithinRange = item.taskList.filter((task) => {
      const taskDate = moment(task.date, 'YYYY-MM-DD');
      return taskDate.isBetween(start, end, undefined, '[]'); // '[]' includes start and end dates
    });

    const totalPersonsCount = tasksWithinRange.reduce(
      (acc, task) => acc + Number(task.numberPerson),
      0,
    );

    return {
      taskName: item.taskName,
      taskDuration: durationInHours,
      totalPersonsCount,
    };
  });

  const totalHrs = taskList.reduce((sum, item) => {
    const taskTotalHours = item.totalPersonsCount * item.taskDuration;
    return sum + taskTotalHours;
  }, 0);

  return {
    totalHrs,
    taskList,
  };
};

export const calculateStaffPlanStatisticData = ({
  planData,
  employeeData,
  planInfo,
  predictCalculated,
  showAbsencePrediction,
  publicHoliday,
  layerCreated,
  layerList,
}) => {
  const { numWeek, endDate, fromDate } = planInfo;
  // get employee avg job percentage = sum of job % / total employee
  const avgJobPercentage = Number(
    (
      employeeData.map((item) => item.job_percentage).reduce((sum, item) => sum + Number(item), 0) /
      100
    ).toFixed(2),
  );
  // get employee avg total hrs per week = sum of total_hrs / total employee
  const avgEmployeeWorkingHrs = Number(
    (
      employeeData
        .map((item) => item.role_type_detail?.total_hrs)
        .reduce((sum, item) => sum + Number(item), 0) / employeeData.length
    ).toFixed(2),
  );

  // get total task count for all weeks in plan = total person * task duration in plan
  const totalTaskCount = planData
    .filter((item) => item.taskTypeValue >= 0)
    .map((item) => {
      const startTime = moment(item.fromTime, 'HH:mm:ss');
      const endTime = moment(item.endTime, 'HH:mm:ss');
      const duration = moment.duration(endTime.diff(startTime));
      let durationInHours = duration.asHours();
      if (endTime.isBefore(startTime)) {
        durationInHours += 24;
      }
      const minusPaidBreak = item.taskList.reduce(
        (acc, task) => acc + getMinusPaidTimeBreak(item, task.date, publicHoliday),
        0,
      );
      const totalPersons = item.taskList.reduce((acc, task) => acc + Number(task.numberPerson), 0);
      return {
        taskName: item.taskName,
        taskDuration: durationInHours,
        totalTaskCount: totalPersons,
        layerID: item.layerID,
        minusPaidBreak,
      };
    });

  const totalPredictTaskCount = predictCalculated
    .filter((item) => item.taskTypeValue < 0)

    .map((item) => {
      const startTime = moment(item.fromTime, 'HH:mm:ss');
      const endTime = moment(item.endTime, 'HH:mm:ss');
      const duration = moment.duration(endTime.diff(startTime));
      let durationInHours = duration.asHours();
      if (endTime.isBefore(startTime)) {
        durationInHours += 24;
      }

      const totalPersons = item.taskList.reduce((acc, task) => acc + Number(task.numberPerson), 0);
      return {
        taskName: item.taskName,
        taskDuration: durationInHours,
        totalTaskCount: totalPersons,
      };
    });

  const totalHrs = totalTaskCount.reduce((sum, item) => {
    const taskTotalHours = item.totalTaskCount * item.taskDuration - item.minusPaidBreak;
    return sum + taskTotalHours;
  }, 0);

  const totalRedictHrs = showAbsencePrediction
    ? totalPredictTaskCount.reduce((sum, item) => {
        const taskTotalHours = item.totalTaskCount * item.taskDuration;
        return sum + taskTotalHours;
      }, 0)
    : 0;

  // get task count every 3 weeks in plan = ( total task count in 3 weeks ) * avg employee total hrs / every3rdWeekCount
  const weeks3intervals = [];
  const every3rdWeekCount = numWeek >= 3 ? Math.floor(numWeek / 3) : 0;

  for (let i = 0; i < every3rdWeekCount; i++) {
    const startOfWeek = moment(fromDate).add(i * 3, 'w');
    const endOfWeek = moment(startOfWeek).add(2, 'w').endOf('W'); // 2 weeks after the start of the week make 3 weeks

    // check if the end of the week is within the plan's end date
    const actualEndOfWeek = moment.min(endOfWeek, moment(endDate));

    // get all date in the interval
    const dateList = enumerateDaysBetweenDates(startOfWeek, actualEndOfWeek);
    const taskDataSummary = calculateTotalTasksInRange(startOfWeek, actualEndOfWeek, planData);

    weeks3intervals.push({
      startDate: startOfWeek.format('YYYY-MM-DD'),
      endDate: actualEndOfWeek.format('YYYY-MM-DD'),
      dateList: dateList,
      taskDataSummary,
      avgEmpNeeded: Number((taskDataSummary.totalHrs / avgEmployeeWorkingHrs).toFixed(2)),
    });
  }

  const listLayerIDCreated = layerList.map((item) => ({
    id: item.id,
    hourPerWeek: item.hour_per_week,
  }));
  const listLayerIDNew = layerCreated.map((item) => ({
    id: item.layerName,
    hourPerWeek: item.weekHr,
  }));
  const listLayerInPlan = [
    ...listLayerIDCreated,
    ...listLayerIDNew,
    { id: '', hourPerWeek: MAXIMUM_WEEK_WORKING_HOUR },
    { id: null, hourPerWeek: MAXIMUM_WEEK_WORKING_HOUR },
  ];

  const anualWorkInPlan = listLayerInPlan.reduce((sum, item) => {
    const layerFTE = +calculateFTEAbsencePredictionLayer({
      planData,
      layerID: item.id,
      planInfo,
      hourPerWeek: item.hourPerWeek,
      publicHoliday,
    });
    return sum + layerFTE;
  }, 0);
  //WILL BE IMPLEMENT AFTER HAVE API CONNECT WIHT PREDICT FUNCTION
  /// FTEAbsencePrediction= TOTAL-PREDICT-HOUR/(numWeek*MAXIMUM_WEEK_WORKING_HOUR)
  const FTEAbsencePrediction = (totalRedictHrs / (numWeek * MAXIMUM_WEEK_WORKING_HOUR)).toFixed(2);
  const result = {
    avgJobPercentage,
    totalHrs,
    taskData: totalTaskCount,
    anualWorkInPlan: anualWorkInPlan.toFixed(2),
    every3rdWeekCount,
    weeks3intervals,
    FTEAbsencePrediction,
    totalEmpNeededPer3Weekend:
      Number(
        (
          weeks3intervals.reduce((sum, item) => sum + item.avgEmpNeeded, 0) / every3rdWeekCount
        ).toFixed(2),
      ) || 0,
  };

  return result;
};

export const calculateFTEAbsencePredictionLayer = ({
  planData,
  layerID,
  planInfo,
  hourPerWeek = MAXIMUM_WEEK_WORKING_HOUR,
  publicHoliday,
}) => {
  const { numWeek } = planInfo;
  const totalLayerTaskCount = planData
    .filter((item) => item.layerID == layerID)

    .map((item) => {
      const startTime = moment(item.fromTime, 'HH:mm:ss');
      const endTime = moment(item.endTime, 'HH:mm:ss');
      const duration = moment.duration(endTime.diff(startTime));
      let durationInHours = duration.asHours();
      if (endTime.isBefore(startTime)) {
        durationInHours += 24;
      }
      const minusPaidBreak = item.taskList.reduce(
        (acc, task) => acc + getMinusPaidTimeBreak(item, task.date, publicHoliday),
        0,
      );
      const totalPersons = item.taskList.reduce((acc, task) => acc + Number(task.numberPerson), 0);
      return {
        taskName: item.taskName,
        taskDuration: durationInHours,
        totalTaskCount: totalPersons,
        minusPaidBreak,
      };
    });
  const totalLayerHrs = planData
    ? totalLayerTaskCount.reduce((sum, item) => {
        const taskTotalHours = item.totalTaskCount * item.taskDuration - item.minusPaidBreak;
        return sum + taskTotalHours;
      }, 0)
    : 0;
  const FTEAbsencePrediction = (totalLayerHrs / (numWeek * hourPerWeek)).toFixed(2);
  return FTEAbsencePrediction;
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
