import React from 'react';
import { t } from 'i18next';
import { selectAuth } from 'redux/auth/authSlice';
import { useSelector } from 'react-redux';

const F3ShiftByRange = ({ f3Range, setF3Range, hadleUpdate }) => {
  const { isEditabled } = useSelector(selectAuth);

  const onChangeF3Range = ({ name, value }, index) => {
    setF3Range((prev) => {
      const updateRange = [...prev];
      updateRange[index] = { ...updateRange[index], [name]: value };
      return updateRange;
    });
  };

  return (
    <div className="f3-by-range__wrap">
      <h3 className="f3-by-range__title">{t('f3-range-title')}</h3>
      <div className="f3-by-range__body">
        {Array.from({ length: 5 }).map((_, index) => {
          const inputChange = (e) => {
            const { value, name } = e.target;
            onChangeF3Range({ value, name }, index);
          };
          return (
            <div key={`${index}-range__group`} className="f3-by-range__group">
              <label htmlFor="">{t('emp-vacancy-rate')}</label>
              <div className="f3-by-range__item">
                <label htmlFor="">{t('from')}</label>
                <div className="f3-by-range__input">
                  <input
                    value={f3Range[index]?.from}
                    type="number"
                    name="from"
                    onChange={inputChange}
                    disabled={!isEditabled}
                  />
                  <div className="suffix">%</div>
                </div>
              </div>
              <div className="f3-by-range__item">
                <label htmlFor="">{t('to')}</label>
                <div className="f3-by-range__input">
                  <input
                    value={f3Range[index]?.to}
                    type="number"
                    name="to"
                    onChange={inputChange}
                    disabled={!isEditabled}
                  />
                  <div className="suffix">%</div>
                </div>
              </div>
              <label htmlFor="">{t('must-have')}</label>
              <div className="f3-by-range__item">
                <label htmlFor="">{t('number-days')}</label>
                <div className="f3-by-range__input">
                  <input
                    value={f3Range[index]?.numDays}
                    type="number"
                    name="numDays"
                    onChange={inputChange}
                    disabled={!isEditabled}
                  />
                  <div className="suffix"> F3 {t('days')}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default F3ShiftByRange;
