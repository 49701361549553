import { t } from 'i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import FormIntro from './FormIntro';
import SurveyContent from './SurveyContent';
import Loading from 'components/Loading/Loading';
import CommentBox from '../CalendarPlanSurveyForm/ItemInSurvey/CommentBox';
import ExpanedQuestion from '../CalendarPlanSurveyForm/ItemInSurvey/ExpanedQuestion';

import { Toast } from 'helper/alert';
import { CloseIcon } from 'assets/img';
import axiosClient from 'axios/axiosClient';
import { useEmployeeComment } from 'Hooks/useData';
import { selectGlobal } from 'redux/global/globalSlice';
import { setEditedEmpAction } from 'redux/global/globalAction';

import './styles.scss';
import { TYPE_SURVEY_QUESTION } from 'constant';

const TopicItem = ({ topic, domID, language, parentRef }) => {
  const handleOnCLick = () => {
    const element = document.getElementById(domID);

    parentRef.current.scrollTo({
      top: element.offsetTop - parentRef.current.offsetTop,
      behavior: 'smooth',
    });
  };
  const element = document.getElementById(domID);
  if (!element) return null;

  return (
    <div className="topic-item__wrap" onClick={handleOnCLick}>
      <div className="topic-item">
        <span className="topic-name">{language === 'NO' ? topic.no_ver : topic.topic}</span>
      </div>
    </div>
  );
};

const ManageSurvey = ({
  setActive,
  empInfo,
  refetchEmployeeList,
  refetchEmployees,
  unitSelected,
  jobPercentage,
}) => {
  const dispatch = useDispatch();

  const contentRef = useRef(null);

  const [listQuestion, setListQuestion] = useState([]);
  const [listTopic, setListTopic] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [empComment, setEmpComment] = useState('');
  const [listCollapseTopic, setListCollapseTopic] = useState([]);

  // update survey
  const [sendMail, setSendMail] = useState(false);
  const [intro, setIntro] = useState({ surveyIntro: '', surveyIntroNo: '', id: '' });
  const [listHardRuleQuestion, setListHardRuleQuestion] = useState([]);
  const [listBlockedQuestion, setListBlockedQuestion] = useState([]);

  const [listExpandedQuestionActive, setListExpandedQuestionActive] = useState([]);
  const [surveyRecord, setSurveyRecord] = useState({});
  const [warningMessage, setWarningMessage] = useState('');
  const { language } = useSelector(selectGlobal);
  const isNo = language === 'NO';
  const listExtenedQuestion = useMemo(() => {
    return listQuestion?.filter((item) => item.type === 'expaned');
  }, [listQuestion]);
  useEffect(() => {
    axiosClient.post('calendar-plan/survey-question', { unitCode: unitSelected }).then((result) => {
      if (result) {
        setListQuestion(result);
      }
    });
  }, [empInfo.uuid]);

  useEffect(() => {
    axiosClient
      .post('calendar-plan/survey/expaned-question-active', { empUUID: empInfo.uuid })
      .then((res) => {
        if (res) {
          setListExpandedQuestionActive(res);
        }
      });
  }, [empInfo.uuid]);

  const expandedQuestionActive = useMemo(
    () =>
      listExtenedQuestion.map((item) => ({
        isActive: 1,
        questionContenEn: item.parent_question_content,
        questionContenNo: item.parent_question_no_ver,
        questionUUID: item.parent_question_id,
      })),
    [listExtenedQuestion],
  );

  const listHardRuleQuestionQuery = useQuery(['list-hard-rule-question', empInfo], () =>
    axiosClient
      .post('calendar-plan/survey/property/hard-rule', { employeeUUID: empInfo.uuid })
      .then((result) => result),
  );
  const listBlockedQuestionQuery = useQuery(['list-blocked-question', empInfo], () =>
    axiosClient
      .post('calendar-plan/survey/block-question', { empId: empInfo.uuid })
      .then((result) => result),
  );
  const surveyRecordQuery = useQuery(['survey-record-emp', empInfo], () =>
    axiosClient
      .post('calendar-plan/survey-record', { empID: empInfo.uuid })
      .then((result) => result),
  );
  const listTopicQuery = useQuery('list-topic-emp', () =>
    axiosClient.post('calendar-plan/survey-topic').then((result) => result),
  );

  const { data: empCommnetQuery } = useEmployeeComment(empInfo.uuid);

  useEffect(() => {
    if (empCommnetQuery) setEmpComment(empCommnetQuery.comment);
  }, [empCommnetQuery]);

  useEffect(() => {
    if (listBlockedQuestionQuery.data?.length) {
      setListBlockedQuestion(listBlockedQuestionQuery.data);
    }
  }, [listBlockedQuestionQuery.data]);

  useEffect(() => {
    if (listHardRuleQuestionQuery.data?.length) {
      setListHardRuleQuestion(listHardRuleQuestionQuery.data);
    }
  }, [listHardRuleQuestionQuery.data]);

  useEffect(() => {
    if (listTopicQuery.data?.length) {
      setListTopic(listTopicQuery.data);
    }
  }, [listTopicQuery.data]);

  useEffect(() => {
    if (surveyRecordQuery.data?.length) {
      const result = surveyRecordQuery.data;
      const arr = {};
      result.forEach((record) => {
        if (record.answer) {
          if (record.type === 'multiple') {
            const enAnswer = record.answer.split(',');
            const noAnswer = record.answer_no.split(',');
            arr[record.question_uuid] = enAnswer.map((item, index) => ({
              en: item,
              no: noAnswer[index],
              id: record.answer_id,
              type: record.type,
            }));
          } else if (record.type === 'text') {
            arr[record.question_uuid] = record.answer;
          } else if (record.type === 'single-date') {
            arr[record.question_uuid] = JSON.parse(record.answer);
          } else if (record.type === 'checkbox-text') {
            arr[record.question_uuid] = JSON.parse(record.answer);
          } else if (record.type === 'date-only') {
            arr[record.question_uuid] = JSON.parse(record.answer);
          } else if (record.type === 'select-week') {
            arr[record.question_uuid] = JSON.parse(record.answer);
          } else if (record.type === TYPE_SURVEY_QUESTION.WEEKDAY_TYPE_SHIFT) {
            arr[record.question_uuid] = JSON.parse(record.answer);
          } else if (record.type === 'date') {
            arr[record.question_uuid] = JSON.parse(record.answer);
            arr[record.question_uuid] = arr[record.question_uuid].map((item) => {
              return { ...item, fromDate: new Date(item.fromDate), toDate: new Date(item.toDate) };
            });
          } else if (record.type === 'range-date-only') {
            arr[record.question_uuid] = JSON.parse(record.answer);
            arr[record.question_uuid] = arr[record.question_uuid].map((item) => {
              if (item.fromDate && item.toDate) {
                return {
                  ...item,
                  fromDate: new Date(item.fromDate),
                  toDate: new Date(item.toDate),
                };
              } else {
                return { ...item };
              }
            });
          } else if (record.type === 'time') {
            arr[record.question_uuid] = JSON.parse(record.answer);
            arr[record.question_uuid] = arr[record.question_uuid].map((item) => {
              return { ...item, time: item.time };
            });
          } else {
            arr[record.question_uuid] = {
              en: record.answer,
              no: record.answer_no,
              id: record.answer_id,
              type: record.type,
            };
          }
        }
      });
      setSurveyRecord(arr);
    }
  }, [surveyRecordQuery.data, empInfo.uuid]);
  useEffect(() => {
    axiosClient.post('calendar-plan/survey/default-value').then((res) => {
      if (res?.length) {
        setSurveyRecord((prev) => {
          let surveyRecordUpdate = { ...prev };

          for (const question of res) {
            if (surveyRecordUpdate[question.questionUUID]) continue;
            if (
              !surveyRecordUpdate[question.questionUUID] ||
              !surveyRecordUpdate[question.questionUUID].length
            ) {
              if (question.listValue[0].type === 'single') {
                surveyRecordUpdate[question.questionUUID] = question.listValue[0];
              } else {
                surveyRecordUpdate[question.questionUUID] = question.listValue;
              }
            }
          }

          // Check if the key exists and the array length is less than 5
          if (
            surveyRecordUpdate['1ee30b9b-5fec-4a0f-bc29-af09418d48e5'] &&
            surveyRecordUpdate['1ee30b9b-5fec-4a0f-bc29-af09418d48e5'].length < 5
          ) {
            const currentLength = surveyRecordUpdate['1ee30b9b-5fec-4a0f-bc29-af09418d48e5'].length;
            const additionalItems = Array.from({ length: 5 - currentLength }, () => ({
              fromDate: '',
              toDate: '',
              type: 'date',
            }));
            surveyRecordUpdate['1ee30b9b-5fec-4a0f-bc29-af09418d48e5'].push(...additionalItems);
          } else if (!surveyRecordUpdate['1ee30b9b-5fec-4a0f-bc29-af09418d48e5']) {
            // If the key doesn't exist, initialize it with 5 items
            surveyRecordUpdate['1ee30b9b-5fec-4a0f-bc29-af09418d48e5'] = Array.from(
              { length: 5 },
              () => ({ fromDate: '', toDate: '', type: 'date' }),
            );
          }

          return surveyRecordUpdate;
        });
      }
    });
  }, [empInfo.uuid]);
  const handleSendMail = async () => {
    try {
      setIsLoading(true);
      await axiosClient.post('send-survey', { email: [empInfo.uuid] });
      setActive(false);
      Toast({ icon: 'success', title: `${t('send-survey')} ${t('success')}` });
    } catch (error) {
      Toast({ icon: 'error', title: `${t('send-survey')} ${t('fail')}` });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const questionId = Object.keys(surveyRecord);
      const empRecord = questionId.map((key) => {
        if (typeof surveyRecord[key] === 'string') {
          return {
            questionUUID: key,
            answerID: surveyRecord[key].id || '',
            empID: empInfo.id,
            empUUID: empInfo.uuid,
            answer: surveyRecord[key].en || surveyRecord[key],
            answer_no: surveyRecord[key].no || surveyRecord[key],
            type: 'text',
          };
        } else if (Array.isArray(surveyRecord[key])) {
          if (
            surveyRecord[key][0]?.type === 'date' ||
            surveyRecord[key][0]?.type === 'range-date-only' ||
            surveyRecord[key][0]?.type === 'time' ||
            ''
          ) {
            const value = surveyRecord[key].filter((item) => item.fromDate && item.toDate);

            return {
              questionUUID: key,
              answerID: surveyRecord[key].id || '',
              empID: empInfo.id,
              empUUID: empInfo.uuid,
              answer: JSON.stringify(value) || value,
              answer_no: JSON.stringify(value) || value,
              type: surveyRecord[key][0]?.type || surveyRecord[key][1].type,
            };
          } else {
            return {
              questionUUID: key,
              answerID: surveyRecord[key].id || '',
              empID: empInfo.id,
              empUUID: empInfo.uuid,
              answer: surveyRecord[key].en || surveyRecord[key],
              answer_no: surveyRecord[key].no || surveyRecord[key],
              type: surveyRecord[key][0]?.type,
            };
          }
        } else if (surveyRecord[key].type === 'single-date') {
          return {
            questionUUID: key,
            answerID: surveyRecord[key].id || '',
            empID: empInfo.id,
            empUUID: empInfo.uuid,
            answer: JSON.stringify(surveyRecord[key]) || surveyRecord[key],
            answer_no: JSON.stringify(surveyRecord[key]) || surveyRecord[key],
            type: 'single-date',
          };
        } else if (surveyRecord[key].type === 'checkbox-text') {
          return {
            questionUUID: key,
            answerID: surveyRecord[key].id || '',
            empID: empInfo.id,
            empUUID: empInfo.uuid,
            answer: JSON.stringify(surveyRecord[key]) || surveyRecord[key],
            answer_no: JSON.stringify(surveyRecord[key]) || surveyRecord[key],
            type: 'checkbox-text',
          };
        } else if (surveyRecord[key].type === 'date-only') {
          return {
            questionUUID: key,
            answerID: surveyRecord[key].id || '',
            empID: empInfo.id,
            empUUID: empInfo.uuid,
            answer: JSON.stringify(surveyRecord[key]) || surveyRecord[key],
            answer_no: JSON.stringify(surveyRecord[key]) || surveyRecord[key],
            type: 'date-only',
          };
        } else if (surveyRecord[key].type === 'select-week') {
          return {
            questionUUID: key,
            answerID: surveyRecord[key].id || '',
            empID: empInfo.id,
            empUUID: empInfo.uuid,
            answer: JSON.stringify(surveyRecord[key]) || surveyRecord[key],
            answer_no: JSON.stringify(surveyRecord[key]) || surveyRecord[key],
            type: 'select-week',
          };
        } else if (surveyRecord[key].type === TYPE_SURVEY_QUESTION.WEEKDAY_TYPE_SHIFT) {
          return {
            questionUUID: key,
            answerID: surveyRecord[key].id || '',
            empID: empInfo.id,
            empUUID: empInfo.uuid,
            answer: JSON.stringify(surveyRecord[key]) || surveyRecord[key],
            answer_no: JSON.stringify(surveyRecord[key]) || surveyRecord[key],
            type: TYPE_SURVEY_QUESTION.WEEKDAY_TYPE_SHIFT,
          };
        } else {
          return {
            questionUUID: key,
            answerID: surveyRecord[key].id || '',
            empID: empInfo.id,
            empUUID: empInfo.uuid,
            answer: surveyRecord[key].en || surveyRecord[key],
            answer_no: surveyRecord[key].no || surveyRecord[key],
            type: surveyRecord[key]?.type,
          };
        }
      });

      Promise.all([
        axiosClient.post('calendar-plan/survey-intro-employee/update', {
          intro,
          employeeId: empInfo.uuid,
        }),
        axiosClient.post('calendar-plan/survey/comment/update', {
          employeeUUID: empInfo.uuid,
          commentUpdate: empComment,
        }),
        axiosClient.post('calendar-plan/survey/block-question/update', {
          listQuestionId: listBlockedQuestion,
          empId: empInfo.uuid,
        }),
        axiosClient.post('calendar-plan/survey/property/hard-rule/update', {
          employee: empInfo,
          listHardRuleQuestion,
        }),
        axiosClient.post('calendar-plan/survey-record/create', { empRecord }),
      ])
        .then((_) => {
          axiosClient
            .post('calendar-plan/survey/expaned-question/update', {
              listQuestion: listExpandedQuestionActive,
              empUUID: empInfo.uuid,
            })
            .then((_) => {
              if (refetchEmployees) refetchEmployees();
              // if (refetchEmployeeList) {
              //   refetchEmployeeList();
              // }
              Toast({
                icon: 'success',
                title: `${t('save')} ${t('survey')} ${t('success')}`,
                timer: 3000,
              });
              if (sendMail) handleSendMail();
              dispatch(setEditedEmpAction(empInfo.id));
              setActive(false);
            })
            .catch((err) => {
              Toast({ icon: 'error', title: `${t('save')} ${t('survey')} ${t('unsuccess')}` });
            });
        })
        .catch((err) => {
          Toast({ icon: 'error', title: `${t('save')} ${t('survey')} ${t('unsuccess')}` });
        });
    },
    [surveyRecord],
  );

  const commentTopic = {
    uuid: 'comment-question-topicID',
    no_ver: t('comment'),
    topic: t('comment'),
  };
  const extendTopic = {
    uuid: 'extend-question-topicID',
    no_ver: t('extened-question'),
    topic: t('extened-question'),
  };

  const handleCollapseTopic = (topicUUID) => {
    if (listCollapseTopic.includes(topicUUID)) {
      setListCollapseTopic((prev) => prev.filter((item) => item !== topicUUID));
    } else {
      setListCollapseTopic((prev) => [...prev, topicUUID]);
    }
  };

  const handleOnChange = (e) => {
    if (e.target.type === 'checkbox') {
      if (surveyRecord[[e.target.name]]) {
        if (surveyRecord[[e.target.name]]?.includes(e.target.value)) {
          setSurveyRecord({
            ...surveyRecord,
            [e.target.name]: surveyRecord[[e.target.name]].filter(
              (item) => item !== e.target.value,
            ),
          });
        } else {
          setSurveyRecord({
            ...surveyRecord,
            [e.target.name]: [...surveyRecord[[e.target.name]], e.target.value],
          });
        }
      } else {
        setSurveyRecord({
          ...surveyRecord,
          [e.target.name]: [e.target.value],
        });
      }
    } else {
      setSurveyRecord({ ...surveyRecord, [e.target.name]: e.target.value });
    }
  };

  const renderExpanedQuestion = useCallback(() => {
    if (!listExtenedQuestion.length) {
      return;
    }
    return (
      <div>
        <h2
          id={extendTopic.uuid}
          className="extend-question-title"
          onClick={() => {
            handleCollapseTopic(extendTopic.uuid);
          }}
        >
          {t('extened-question')}
        </h2>
        {listExtenedQuestion.map((questionData, index) => (
          <ExpanedQuestion
            key={questionData.key}
            parentQuestionData={questionData}
            index={index + 1}
            language={language}
            listExpandedQuestionActive={listExpandedQuestionActive}
            setListExpandedQuestionActive={setListExpandedQuestionActive}
            surveyRecord={surveyRecord}
            setSurveyRecord={setSurveyRecord}
            handleOnChange={handleOnChange}
            listHardRuleQuestion={listHardRuleQuestion}
            setListHardRuleQuestion={setListHardRuleQuestion}
            emp={empInfo}
          />
        ))}
      </div>
    );
  }, [
    listExtenedQuestion,
    surveyRecord,
    listExpandedQuestionActive,
    listHardRuleQuestion,
    language,
  ]);

  return (
    <div className="modal-wrapper">
      <div className="form-background" onClick={() => setActive(false)} />
      <div className="form-survey">
        <div className="form-survey__header">
          <div className="left">
            <p className="title">{t('survey-form')}</p>
            <div>
              <p className="emp-name">{empInfo?.name}</p>
              <p className="emp-role">{empInfo?.role}</p>
            </div>
          </div>

          <div className="right">
            <div className="form-survey__header-icon" onClick={() => setActive(false)}>
              <img src={CloseIcon} />
            </div>
            <label>
              <input type="checkbox" onChange={(e) => setSendMail(e.target.checked)} />
              {t('send-to-email')}
            </label>
          </div>
        </div>

        <FormIntro
          employeeId={empInfo.uuid}
          isNo={isNo}
          setSendMail={setSendMail}
          intro={intro}
          setIntro={setIntro}
        />

        <div className="form-survey__content">
          <form action="" onSubmit={handleSubmit} className="calendar-plan-survey-form-employee">
            <div className="survey-content__wrap">
              <div className="survey-content__topic-menu">
                {listTopicQuery.isLoading ? (
                  <p>Loading</p>
                ) : (
                  <>
                    {listTopic.map((topic) => {
                      return (
                        <TopicItem
                          key={topic.uuid}
                          topic={topic}
                          domID={topic.uuid}
                          parentRef={contentRef}
                          language={language}
                        />
                      );
                    })}
                    <TopicItem
                      key={extendTopic.uuid}
                      topic={extendTopic}
                      domID={extendTopic.uuid}
                      parentRef={contentRef}
                      language={language}
                    />
                    <TopicItem
                      key={commentTopic.uuid}
                      topic={commentTopic}
                      domID={commentTopic.uuid}
                      parentRef={contentRef}
                      language={language}
                    />
                  </>
                )}
              </div>

              <div className="survey-content" ref={contentRef}>
                {listQuestion.length ? (
                  <SurveyContent
                    listQuestion={listQuestion}
                    listTopic={listTopic}
                    language={language}
                    surveyRecord={surveyRecord}
                    setSurveyRecord={setSurveyRecord}
                    listExpandedQuestionActive={expandedQuestionActive}
                    listExtenedQuestion={listExtenedQuestion}
                    empInfo={empInfo}
                    extendTopic={extendTopic}
                    listHardRuleQuestion={listHardRuleQuestion}
                    setListHardRuleQuestion={setListHardRuleQuestion}
                    listBlockedQuestionDefault={listBlockedQuestion}
                    setListBlockedQuestionDefault={setListBlockedQuestion}
                    listCollapseTopic={listCollapseTopic}
                    setListCollapseTopic={setListCollapseTopic}
                    handleCollapseTopic={handleCollapseTopic}
                    commentTopic={commentTopic}
                    jobPercentage={jobPercentage}
                    warningMessage={warningMessage}
                    setWarningMessage={setWarningMessage}
                  />
                ) : (
                  <></>
                )}

                {renderExpanedQuestion()}

                <div className="comment__wrap" id={commentTopic.uuid}>
                  <CommentBox
                    empComment={empComment}
                    setEmpComment={setEmpComment}
                    commentTopic={commentTopic}
                    listCollapseTopic={listCollapseTopic}
                    handleCollapseTopic={handleCollapseTopic}
                  />
                </div>
              </div>
            </div>

            <div className="form-survey__btn">
              {isLoading && <Loading />}
              <button type="submit">{t('save')}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ManageSurvey;
