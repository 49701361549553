import React from 'react';
import './styles.scss';

export default function Loading({ isShow }) {
  return (
    !!isShow && (
      <div className="container">
        <div className="loader">
          <div className="inner one"></div>
          <div className="inner two"></div>
          <div className="inner three"></div>
        </div>
      </div>
    )
  );
}
