import { CloseBrownIcon } from 'assets/img';
import { t } from 'i18next';
import React, { useId } from 'react';
const RenderRoles = ({ roleFetchList, roleSelected, setRoleSelected }) => {
  const handleChange = (role) => {
    const roleID = role.id;
    if (roleID) {
      if (roleSelected.includes(roleID)) {
        setRoleSelected((prev) => prev.filter((item) => item !== roleID));
      } else {
        setRoleSelected((prev) => [...prev, roleID]);
      }
    }
  };
  return (
    <div className="roles-list__wrap">
      <label htmlFor="">{t('role')}</label>
      <div className="roles-list">
        {roleFetchList.map((role) => {
          return (
            <div key={`${role.key}-${role.role}`} className="roles-list__item">
              <input
                type="checkbox"
                id={`${role.key}-${role.role}-task-role`}
                checked={roleSelected.includes(role.id)}
                onChange={() => {
                  handleChange(role);
                }}
              />
              <label htmlFor={`${role.key}-${role.role}-task-role`}>{role.role}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const RenderGroup = ({ groupList, groupSelected, setGroupSelected }) => {
  if (!groupList || !groupList.data) {
    return;
  }
  const handleChange = (e) => {
    const groupID = e.target.value;
    if (groupID) {
      if (groupID === t('unselected')) {
        setGroupSelected({});
      } else {
        const groupFound = groupList.data.find((item) => +item.id === +groupID);
        if (groupFound) {
          setGroupSelected(groupFound);
        }
      }
    }
  };
  return (
    <div className="group-list__wrap">
      <label htmlFor="">{t('group')}</label>
      <select className="group-list" value={groupSelected.id} onChange={handleChange}>
        <option key={`empty-group`} value={null}>
          {t('unselected')}
        </option>
        {groupList.data.map((group) => {
          const key = useId();
          return (
            <option key={key} value={group.id}>
              {group.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};

const TaskPropertyTable = ({
  handleSelectLayer,
  layerSelected = {},
  layerList,
  renderLayer,
  layerCreated,
  roleFetchList,
  roleSelected,
  setRoleSelected,
  groupList,
  groupSelected,
  setGroupSelected,
}) => {
  return (
    <div className="add-task-property">
      <div className="add-layer-guard-code__wrap">
        <div className="add-layer-guard-code__section">
          <div className="add-layer-guard-code__header">
            <label htmlFor="">{t('add-layer')}</label>
          </div>
          <select
            onChange={handleSelectLayer}
            className="add-layer-guard-code__selection"
            value={`${layerSelected.id}|${layerSelected.roleID}|${layerSelected.layerName}`}
          >
            <option value="||">{t('select-team')}</option>
            {renderLayer(layerList, layerCreated)}
          </select>
        </div>
        {Object.keys(layerSelected).length && layerSelected.id ? (
          <RenderRoles
            roleFetchList={roleFetchList}
            roleSelected={roleSelected}
            setRoleSelected={setRoleSelected}
          />
        ) : null}
      </div>
      <RenderGroup
        groupList={groupList}
        groupSelected={groupSelected}
        setGroupSelected={setGroupSelected}
      />
    </div>
  );
};

export default TaskPropertyTable;
