import { t } from 'i18next';
import ModalLayout from 'layout/Modal';
import React, { useEffect, useState } from 'react';

import './AddLayer.scss';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, selectModal } from '_components/Modal/slice';
import { useRoles } from 'Hooks/useData';
import axiosClient from 'axios/axiosClient';
import { Toast } from 'helper/alert';
const AddLayer = ({
  unitSelected,
  refetchLayerList,
  planID,
  layerList,
  layerCreated,
  setLayerCreated,
  gridRef,
}) => {
  const modal = useSelector(selectModal);
  const { data } = modal;
  const isUpdate = data && !!Object.keys(data).length;

  const [layerData, setLayerData] = useState({ weekHr: 35.5, layerName: '' });
  const dispatch = useDispatch();
  const { data: roleList } = useRoles();
  const renderRoles = () => {
    return roleList.map((role) => {
      return (
        <option key={`${role.key}-${role.role}`} value={role.id}>
          {t(role.role)}
        </option>
      );
    });
  };
  const handleClose = () => {
    dispatch(closeModal());
  };
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setLayerData((prev) => ({ ...prev, [name]: name === 'weekHr' ? +value : value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!layerData.weekHr || !layerData.layerName || isNaN(layerData.weekHr)) {
      return;
    }
    if (data && data.layerID) {
      if (isNaN(data.layerID)) {
        setLayerCreated((prev) => {
          const listUpdate = [...prev];
          const layerFoundIndex = listUpdate.findIndex((item) => item.layerName === data.layerID);
          if (layerFoundIndex > -1) {
            listUpdate[layerFoundIndex] = layerData;
          }

          return listUpdate;
        });
      } else {
        axiosClient
          .post('calendar-plan/staff-plan/layer/create', { layerData, unitSelected, planID })
          .then((_) => {
            Toast({ icon: 'success', title: `${t('create')} ${t('success')}` });
            refetchLayerList();
            dispatch(closeModal());
          })
          .catch((_) => {
            Toast({ icon: 'error', title: `${t('create')} ${t('unsuccess')}` });
          });
      }
    } else {
      setLayerCreated((prev) => [...prev, layerData]);
    }
    dispatch(closeModal());
  };

  useEffect(() => {
    if (data) {
      const layerDefault = layerList.find((item) => +item.id === +data.layerID);
      if (layerDefault) {
        const layer = {
          id: layerDefault.id,
          weekHr: +layerDefault.hour_per_week,
          // roleID: layerDefault.role_id,
          layerName: layerDefault.name,
          planID: layerDefault.plan_id,
        };
        setLayerData(layer);
      } else {
        const layerDefault = layerCreated.find((item) => item.layerName === data.layerID);
        if (layerDefault) {
          setLayerData({ ...layerDefault, weekHr: +layerDefault.weekHr });
        }
      }
    }
  }, [data]);
  return (
    <ModalLayout>
      <div className="add-layer__wrapper">
        <div className="add-layer__header">
          <h3 className="add-layer__title">{isUpdate ? t('edit-layer') : t('new-layer')}</h3>
          <p className="add-layer__desc">{t('new-layer-desc')}</p>
        </div>
        <form onSubmit={handleSubmit} className="add-layer__body">
          <div className="add-layer__group">
            <div className="add-layer__label">{t('name')}</div>
            <input
              className="add-layer__input"
              onChange={handleOnChange}
              name="layerName"
              value={layerData.layerName}
              required
            />
          </div>
          <div className="add-layer__group">
            <div className="add-layer__label">{t('hr-per-week')}</div>
            <div className="add-layer__input__wrap">
              {/* <input
                className="add-layer__input"
                onChange={handleOnChange}
                name="weekHr"
                value={layerData.weekHr}
                type="number"
                required
              /> */}
              <select
                name="weekHr"
                id=""
                className="week-hr-select"
                value={layerData.weekHr}
                onChange={handleOnChange}
              >
                <option value={35.5}>35.5</option>
                <option value={37.5}>37.5</option>
              </select>
              <span className="sufix">{t('hour')}</span>
            </div>
          </div>

          <div className="add-layer__footer">
            <button className="custom-btn outline-btn" onClick={handleClose}>
              {t('cancel')}
            </button>
            <button className="custom-btn ">{t('next')}</button>
          </div>
        </form>
      </div>
    </ModalLayout>
  );
};

export default AddLayer;
