import React, { useMemo } from 'react';
import TableHeader from '../../CreateEmpModal/component/BulkCreateEmp/TableHeader';
import RoleSelection from '../../CreateEmpModal/component/BulkCreateEmp/RoleSelection';
import RoleTypeSelection from '../../CreateEmpModal/component/BulkCreateEmp/RoleTypeSelection';
import { AgGridReact } from 'ag-grid-react';
import { t } from 'i18next';
import axiosClient from 'axios/axiosClient';
import { Toast } from 'helper/alert';
import { ArrowDownIcon } from 'assets/img';
import GroupMultipleSelection from '../../CreateEmpModal/component/BulkCreateEmp/GroupMultipleSelection';
import { formatNorwayPhoneNumber, validateRowData } from '../../CreateEmpModal/component/BulkCreateEmp/helper';
import { isEmailValid } from 'helper/dateTime';
import stringSimilarity from 'string-similarity';
import { validateSubmittedBulkEditData } from './bulkEditTableValidation';

const BulkEditTable = ({
  setSelectedEmp,
  selectedEmp,
  refetchEmployeeWithPositionFetch,
  unitSelected,
  setActiveBulkEditTable,
  groupFetch,
  roleFetch,
  roleTypeList,
}) => {
  const groupList = useMemo(() => ['', ...groupFetch.map((group) => group.name)], [groupFetch]);
  const roleList = useMemo(() => ['', ...roleFetch.map((role) => role.role)], [roleFetch]);
  const rowData = useMemo(() => {
    return selectedEmp.map((item) => {
      const roleTypeFound = roleTypeList.find((roleType) => {
        return roleType.id === item.role_type;
      });
      return { ...item, role_type_name: roleTypeFound.type };
    });
  }, [selectedEmp]);

  const defaultColDef = useMemo(
    () => ({
      filter: false,
      sortable: true,
      editable: true,
      resizable: true,
      menuTabs: [],

      cellClass: 'dark-cell',
      suppressMovable: true,
      flex: 1,
    }),
    [rowData],
  );

  const columnDefs = useMemo(
    () => [
      {
        headerName: `${t('emp-id-number')}`,
        field: 'emp_id_number',
        flex: 1,
      },
      {
        headerName: `${t('name')}`,
        headerComponent: TableHeader,
        headerTooltip:
          'Du kan markere to kolonner i excel (fornavn og etternavn) og lime de inn i denne kolonnen.',
        headerComponentParams: {
          title: `${t('name')}`,
          tooltip: true,
        },
        field: 'name',
      },
      {
        headerName: `${t('salary')}`,
        field: 'salary',
      },

      {
        headerName: t('email'),
        field: 'email',
        valueSetter: ({ data, newValue }) => {
          data.email = newValue ? newValue?.trim() : '';
          const isError = newValue && !isEmailValid(newValue?.trim());
          if (isError) {
            Toast({ icon: 'error', title: t('email-wrong') });
          }
          return true;
        },
      },

      {
        headerName: t('phone-number'),
        field: 'phone_number',
        valueSetter: ({ data, newValue }) => {
          const sanitizedValue = newValue ? newValue.replace(/[^+\d]/g, '') : '';
          let phoneNumber = '';
          if (newValue !== null) {
            phoneNumber = formatNorwayPhoneNumber(sanitizedValue);
            data.phone_number = phoneNumber?.trim();
          } else {
            data.phone_number = '';
          }
        },
      },
      {
        headerName: `${t('job-percentage')} *`,
        field: 'job_percentage',
        cellEditor: 'agNumericCellEditor',
      },
      {
        headerName: `${t('role')} *`,
        field: 'role',
        cellEditor: RoleSelection,
        cellEditorPopup: true,
        editable: true,

        cellEditorParams: {
          roleList,
        },
        valueSetter: ({ data, newValue }) => {
          if (!newValue) {
            data.role = '';
            return true;
          }
          const roleFound = roleFetch.find((role) => role.role === newValue)?.role;
          if (!roleFound) {
            const roleSimilar = stringSimilarity.findBestMatch(newValue.toUpperCase(), roleList);
            if (roleSimilar.bestMatch.rating > 0.7) {
              const correctRole = roleSimilar.bestMatch.target;
              data.role = roleSimilar.bestMatch.target;
            }
            return;
          }
          data.role = roleFound;
        },
        cellRenderer: (params) => {
          const { value, data } = params;

          return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>{value}</span>
              <span>
                <img src={ArrowDownIcon} width={18} alt="" />
              </span>
            </div>
          );
        },
      },
      {
        headerName: `${t('role-type')} *`,
        field: 'role_type_name',
        cellEditor: RoleTypeSelection,
        cellEditorPopup: true,
        editable: true,

        cellEditorParams: {
          roleTypeList,
        },

        valueSetter: ({ data, newValue }) => {
          if (!newValue) {
            data.role_type_name = '';
            return true;
          }
          if (isNaN(newValue)) {
            const roleTypeFound = roleTypeList.find(
              (roleType) => roleType.type === newValue.trim(),
            );
            if (roleTypeFound) {
              data.role_type_name = roleTypeFound.type;
              return true;
            } else {
              return true;
            }
          }

          data.role_type_name = newValue;

          return true;
        },
        cellRenderer: (params) => {
          const { value, data } = params;

          return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>{value}</span>
              <span>
                <img src={ArrowDownIcon} width={18} alt="" />
              </span>
            </div>
          );
        },
      },
      {
        headerName: t('group'),
        field: 'group',
        cellEditor: GroupMultipleSelection,
        editable: true,
        cellEditorPopup: true,

        cellEditorParams: {
          groupFetch,
        },
        cellRenderer: (params) => {
          const { value, data } = params;

          return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>{value}</span>
              <span>
                <img src={ArrowDownIcon} width={18} alt="" />
              </span>
            </div>
          );
        },
        valueGetter: (params) => {
          const { data } = params;
          return data?.listGroup.length ? `${t('group')} + ${data.listGroup.length}` : '';
        },
      },
    ],
    [roleList, groupList, roleTypeList],
  );
  const handleSubmit = async () => {
    // Validate data
    const { isValid, message } = validateSubmittedBulkEditData(rowData);
    if (!isValid) {
      Toast({
        icon: 'error',
        title: t(message),
      });
      return;
    }

    const editData = rowData.map((data) => {
      const { salary, job_percentage, email, phone_number, name, emp_id_number } = data;
      const employeeData = {
        employeeID: data.id,
        positionData: { listGroup: data.listGroup, salary, job_percentage },
        informationData: { email, phone_number, name, emp_id_number },
      };
      const roleFound = roleFetch.find((role) => role.role === data.role);
      const positionTypeFound = roleTypeList.find(
        (positionType) => positionType.type === data.role_type_name,
      );
      if (roleFound) {
        employeeData.positionData.role_id = roleFound.id;
      }
      if (positionTypeFound) {
        employeeData.positionData.role_type = positionTypeFound.id;
      }

      return employeeData;
    });

    await axiosClient
      .post('/calendar-plan/employee/bulk-edit', { editData, unitCode: unitSelected })
      .then((_) => {
        refetchEmployeeWithPositionFetch();
        setActiveBulkEditTable(false);
        setSelectedEmp([]);
        Toast({ icon: 'success', title: t('save-changes') });
      })
      .catch((err) => {
        Toast({ icon: 'error', title: t('save-fail') });
      });
  };

  return (
    <div className="bulk-edit__wrapper">
      <div
        className="modal-background"
        onClick={() => {
          setActiveBulkEditTable(false);
        }}
      ></div>
      <div className="bulk-edit__body">
        <h3>{t('edit-employee')}</h3>
        <div className=" bulk-edit__table ag-theme-alpine">
          <AgGridReact
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            rowData={rowData}
            animateRows={true}
            enableRangeSelection={true}
            singleClickEdit={true}
            enableFillHandle={true}
            undoRedoCellEditing={true}
            undoRedoCellEditingLimit={20}
            tooltipShowDelay={0}
            reactiveCustomComponents={true}
            stopEditingWhenCellsLoseFocus={true}
          />
        </div>

        <div className="bulk-edit__actions">
          <button
            className="custom-btn outline-btn"
            onClick={() => {
              setActiveBulkEditTable(false);
            }}
          >
            {t('cancel')}
          </button>
          <button className="custom-btn" onClick={handleSubmit}>
            {t('save')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BulkEditTable;
