import { useGroups } from 'Hooks/useData';
import { t } from 'i18next';
import SubModalLayout from 'layout/SubModal';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import CreateGroup from './CreateGroup';
import GroupTable from './GroupTable';
import './AddGroup.scss';
import ReactDOM from 'react-dom';
import { CloseBlackIcon } from 'assets/img';
const PRIMIARY_GROUP_VALUE = 'primary';
const SECONDARY_GROUP_VALUE = 'secondary';

const SelectGroupPriority = ({ groupPriority, setGroupPriority }) => {
  const listPriority = [
    { key: 'primary-group', name: 'primary-group', value: PRIMIARY_GROUP_VALUE },
    {
      key: 'secondary-group',
      name: 'secondary-group',
      value: SECONDARY_GROUP_VALUE,
      default: true,
    },
  ];
  return (
    <div className="group-priority__wrap">
      <label htmlFor="">Legg til som</label>
      <select
        name="isPrimary"
        id=""
        value={groupPriority}
        onChange={(e) => {
          setGroupPriority(e.target.value);
        }}
      >
        {listPriority.map((priority) => {
          return (
            <option key={priority.key} value={priority.value}>
              {t(priority.name)}
            </option>
          );
        })}
      </select>
    </div>
  );
};

const AddGroup = ({ unitSelected, setIsActive, addGroupData, setAddGroupData, handleAddGroup }) => {
  const { data: groupList, refetch: refetchGroup } = useGroups({ unitSelected });
  const handleClose = () => {
    setIsActive(false);
    setAddGroupData({});
  };
  const [groupSelected, setGroupSelected] = useState([]);
  const [groupPriority, setGroupPriority] = useState(PRIMIARY_GROUP_VALUE);
  const [listGroupNotValid, setListGroupNotValid] = useState([]);
  const isSelectedPrimary = useMemo(() => groupPriority === PRIMIARY_GROUP_VALUE, [groupPriority]);

  useEffect(() => {
    if (addGroupData && addGroupData.employeeGroups?.length) {
      setGroupSelected(
        addGroupData.employeeGroups
          .filter((item) => item.is_primary === isSelectedPrimary)
          .map((item) => ({
            ...item.groupInformation,
            isDefault: true,
            is_primary: item.is_primary,
          })),
      );
      setListGroupNotValid(
        addGroupData.employeeGroups
          .filter((item) => item.is_primary !== isSelectedPrimary)
          .map((item) => ({
            ...item.groupInformation,
            isDefault: true,
            is_primary: item.is_primary,
          })),
      );
    }
  }, [addGroupData, isSelectedPrimary]);

  const handleSubmit = () => {
    const groupSelectedWithPriority = groupSelected.map((item) => {
      return { ...item, is_primary: groupPriority === PRIMIARY_GROUP_VALUE };
    });
    handleAddGroup(groupSelectedWithPriority, isSelectedPrimary);
    setIsActive(false);
  };

  const modal = (
    <div className="add-group-position-modal">
      <div className="add-group-position-background" onClick={handleClose}></div>

      <div className="add-group-position-box">
        <div className="add-group-position__wrapper">
          <div className="add-group-position__header">
            <h2 className="add-group-position__header-title">
              {t('create')} {t('groups')}
              <img src={CloseBlackIcon} alt="" width={20} onClick={handleClose} />
            </h2>
            <div className="add-group-position__header-desc">
              <p>{t('add-group-position-desc')}</p>
            </div>
          </div>
          <div className="add-group-position__content">
            <SelectGroupPriority
              groupPriority={groupPriority}
              setGroupPriority={setGroupPriority}
            />
            <div className="add-group-position__body">
              <GroupTable
                groupList={groupList.data}
                groupSelected={groupSelected}
                handleAddGroup={handleAddGroup}
                addGroupData={addGroupData}
                setGroupSelected={setGroupSelected}
                groupPriority={groupPriority}
                isSelectedPrimary={isSelectedPrimary}
                listGroupNotValid={listGroupNotValid}
              />
            </div>
            <div className="add-group-position__footer">
              <CreateGroup unitSelected={unitSelected} refetchGroup={refetchGroup} />

              <div className="add-group-position__action">
                <button className="custom-btn outline-btn" onClick={() => setIsActive(false)}>
                  {t('cancel')}
                </button>
                <button className="custom-btn" onClick={handleSubmit}>
                  {t('create')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return <Fragment>{ReactDOM.createPortal(modal, document.body)}</Fragment>;
};

export default AddGroup;
