import React, { useState, useRef, useEffect } from 'react';
import { useQueries, useMutation } from 'react-query';
import axiosClient from 'axios/axiosClient';

import { EditPencil } from 'assets/img';

const FormIntro = ({ employeeId, isNo, setIntro, intro }) => {
  const textAreaRef = useRef(null);
  const [isEditingIntro, setIsEditingIntro] = useState(false);

  const results = useQueries([
    {
      queryKey: `${employeeId}-introData-genneral`,
      queryFn: async () => {
        const data = await axiosClient.post('calendar-plan/survey-intro', {});
        return data;
      },
      enabled: !!employeeId,
    },
    {
      queryKey: `${employeeId}-introData`,
      queryFn: async () => {
        const data = await axiosClient.post('calendar-plan/survey-intro-employee', {
          employeeId,
        });
        return data;
      },
      enabled: !!employeeId,
    },
  ]);

  const introData = results[0].data;
  const empIntroData = results[1].data;

  const { mutate: updateEmpIntro } = useMutation(
    ({ intro, employeeId }) =>
      axiosClient.post('calendar-plan/survey-intro-employee/update', {
        intro,
        employeeId,
      }),
    {
      onSuccess: () => {
        setIsEditingIntro(false);
      },
    },
  );

  useEffect(() => {
    if (introData && empIntroData) {
      const introDataToUse =
        empIntroData.content ?? empIntroData.content_no ? empIntroData : introData;

      setIntro({
        surveyIntro: introDataToUse.content,
        surveyIntroNo: introDataToUse.content_no,
        id: introDataToUse.id,
      });
    }
  }, [introData, empIntroData]);

  const toggleEditIntro = () => {
    setIsEditingIntro((prev) => {
      if (prev) {
        updateEmpIntro({ intro: intro, employeeId });
      }
      return !prev;
    });
  };

  return (
    <div className="form-survey__intro">
      {isEditingIntro ? (
        <textarea
          ref={textAreaRef}
          type="text"
          rows={4}
          value={isNo ? intro.surveyIntroNo : intro.surveyIntro}
          onChange={(e) =>
            setIntro((prevState) => ({
              ...prevState,
              surveyIntroNo: e.target.value || ' ',
              surveyIntro: e.target.value || ' ',
            }))
          }
          autoFocus
        />
      ) : (
        <p>{isNo ? intro.surveyIntroNo : intro.surveyIntro}</p>
      )}
      <div onClick={toggleEditIntro}>
        <img src={EditPencil} />
      </div>
    </div>
  );
};

export default FormIntro;
