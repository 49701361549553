import {
  DAY_SHIFT_VALUE,
  EVENING_SHIFT_VALUE,
  LONG_SHIFT_VALUE,
  NIGHT_SHIFT_VALUE,
  PER_POSITION_CATEGORY,
} from 'constant';
import { updateSummary } from './updateShift';
import { calculateHardAndSoftRuleData, calculateSurveyData } from './calculateData';
import { calculateHrPerWeek } from 'helper/calculateTableData';
import hardRuleChecking from './hardRuleChecking';
import weekendChecking from './weekendChecking';
import axiosClient from 'axios/axiosClient';

export const revertShiftCleaned = ({
  //WILL BE REFACATOR LATER
  listTableApi,
  cleanData,
  summarySelectedView,
  weekRange,
  taskList,
  taskArr,
  surveyData,
  fromDate,
  endDate,
  amlInformation,
  publicHoliday,
  setCleanData,
  cleanDataDefault,
  dateInWeek,
  listEmpData,
  workingEnvSetup,
  roleList,
  planSelected,
}) => {
  const { cleanedVakantShiftID, assignList } = cleanData;
  const { summary: summaryApi } = listTableApi;
  const listTableUpdate = ['empInfo', 'taskList', 'dashboard'];
  const listRevertData = [];

  for (const revertShift of assignList) {
    const { date, taskType, layerName, employee_id, vakantID } = revertShift;
    const matchedVakant = findVakantMatched({
      listEmpData,
      workingEnvSetup,
      taskData: revertShift,
      roleList,
    });
    let vakantIDValid = '';
    if (matchedVakant) {
      vakantIDValid = matchedVakant.employeeId;
      listRevertData.push({ vakantIDValid, revertShift, versionID: planSelected.versionID });
      for (const table of listTableUpdate) {
        const tableApi = listTableApi[table]?.api;
        if (tableApi && tableApi.forEachNode) {
          tableApi.forEachNode((row) => {
            if (row.data) {
              const employeeUpdate = row.data;

              //Rever Employee's shift

              if (employeeUpdate.employeeId === employee_id) {
                const rowTaskList = employeeUpdate.task_arr;
                employeeUpdate.allocatedHours -= revertShift.hourAssigned;
                employeeUpdate.allocatedHoursDeviation -= revertShift.hourAssigned;
                employeeUpdate.deviation -= revertShift.hourAssigned;

                switch (taskType) {
                  case DAY_SHIFT_VALUE:
                    employeeUpdate.dayShift--;
                    updateSummary(
                      summaryApi,
                      summarySelectedView === PER_POSITION_CATEGORY && layerName
                        ? `day${layerName}`
                        : 'dayShift',
                      summarySelectedView === PER_POSITION_CATEGORY && layerName
                        ? `day${layerName}-${date}`
                        : `dayShift-${date}`,
                      date,
                      1,
                    );
                    break;
                  case EVENING_SHIFT_VALUE:
                    employeeUpdate.lateShift--;
                    updateSummary(
                      summaryApi,
                      summarySelectedView === PER_POSITION_CATEGORY && layerName
                        ? `late${layerName}`
                        : 'lateShift',
                      summarySelectedView === PER_POSITION_CATEGORY && layerName
                        ? `late${layerName}-${date}`
                        : `lateShift-${date}`,

                      date,
                      1,
                    );
                    break;
                  case NIGHT_SHIFT_VALUE:
                    employeeUpdate.nightShift--;
                    updateSummary(
                      summaryApi,
                      summarySelectedView === PER_POSITION_CATEGORY && layerName
                        ? ` night${layerName}`
                        : 'nightShift',
                      summarySelectedView === PER_POSITION_CATEGORY && layerName
                        ? `night${layerName}-${date}`
                        : `nightShift-${date}`,
                      date,
                      1,
                    );
                    break;
                  case LONG_SHIFT_VALUE:
                    employeeUpdate.longShift--;
                    updateSummary(
                      summaryApi,
                      summarySelectedView === PER_POSITION_CATEGORY && layerName
                        ? ` long-shift${layerName}`
                        : 'long-shift',
                      summarySelectedView === PER_POSITION_CATEGORY && layerName
                        ? `long-shift${layerName}-${date}`
                        : `long-shift-${date}`,
                      date,
                      1,
                    );
                    break;

                  default:
                    employeeUpdate.otherShift--;

                    break;
                }
                delete rowTaskList[date];
                const rowNewValue = { ...employeeUpdate, task_arr: rowTaskList };
                row.updateData(rowNewValue);
              }

              if (employeeUpdate.employeeId === vakantIDValid) {
                const newTaskList = {};
                switch (taskType) {
                  case DAY_SHIFT_VALUE:
                    //   employeeUpdate.dayShift++;
                    updateSummary(
                      summaryApi,
                      summarySelectedView === PER_POSITION_CATEGORY && layerName
                        ? `day${layerName}`
                        : 'dayShift',
                      summarySelectedView === PER_POSITION_CATEGORY && layerName
                        ? `day${layerName}-${date}`
                        : `dayShift-${date}`,
                      date,
                      1,
                    );
                    break;
                  case EVENING_SHIFT_VALUE:
                    employeeUpdate.lateShift++;
                    updateSummary(
                      summaryApi,
                      summarySelectedView === PER_POSITION_CATEGORY && layerName
                        ? `late${layerName}`
                        : 'lateShift',
                      summarySelectedView === PER_POSITION_CATEGORY && layerName
                        ? `late${layerName}-${date}`
                        : `lateShift-${date}`,

                      date,
                      1,
                    );
                    break;
                  case NIGHT_SHIFT_VALUE:
                    employeeUpdate.nightShift++;
                    updateSummary(
                      summaryApi,
                      summarySelectedView === PER_POSITION_CATEGORY && layerName
                        ? ` night${layerName}`
                        : 'nightShift',
                      summarySelectedView === PER_POSITION_CATEGORY && layerName
                        ? `night${layerName}-${date}`
                        : `nightShift-${date}`,
                      date,
                      1,
                    );
                    break;
                  case LONG_SHIFT_VALUE:
                    employeeUpdate.longShift++;
                    updateSummary(
                      summaryApi,
                      summarySelectedView === PER_POSITION_CATEGORY && layerName
                        ? ` long-shift${layerName}`
                        : 'long-shift',
                      summarySelectedView === PER_POSITION_CATEGORY && layerName
                        ? `long-shift${layerName}-${date}`
                        : `long-shift-${date}`,
                      date,
                      1,
                    );
                    break;

                  default:
                    employeeUpdate.otherShift++;

                    break;
                }
                const rowTaskList = employeeUpdate.task_arr;
                for (const date of Object.keys(rowTaskList)) {
                  const tasks = rowTaskList[date].map((item) => {
                    if (cleanedVakantShiftID.includes(item.vakantShiftID)) {
                      return { ...item, isDeleted: false };
                    }

                    return { ...item };
                  });
                  newTaskList[date] = tasks;
                }
                newTaskList[dateInWeek] = [
                  { ...revertShift, is_clean: false, isClean: false, isAssignByCleanVakant: false },
                ];

                employeeUpdate.allocatedHours += revertShift.hourAssigned;
                const rowNewValue = { ...employeeUpdate, task_arr: newTaskList };
                row.updateData(rowNewValue);
              }
            }
          });
        }
      }
    }
  }

  const employeeDataUpdate = [];
  //Update Dashboard table
  const dashboardApi = listTableApi['dashboard'].api;

  dashboardApi.forEachNode((row) => {
    if (row.data) {
      employeeDataUpdate.push(row.data);
    }
    const reCalculateSurveyRule = calculateSurveyData(
      row.data,
      taskArr,
      surveyData,
      fromDate,
      endDate,
    );
    const reCalculateHardAndSoftRule = calculateHardAndSoftRuleData(
      row.data,
      taskArr,
      amlInformation,
      publicHoliday,
      fromDate,
      endDate,
    );
    if (reCalculateHardAndSoftRule) {
      for (const key of Object.keys(reCalculateHardAndSoftRule)) {
        if (reCalculateHardAndSoftRule[key] && Array.isArray(reCalculateHardAndSoftRule[key])) {
          const isNotValid = reCalculateHardAndSoftRule[key].find(
            (item) => item.employeeId === row.data.employeeId,
          );
          try {
            row.setDataValue(key, !!isNotValid);
          } catch (error) {
            console.log(key);
          }
        }
      }
    }

    if (reCalculateSurveyRule) {
      for (const key of Object.keys(reCalculateSurveyRule)) {
        if (reCalculateSurveyRule[key] && Array.isArray(reCalculateSurveyRule[key])) {
          const isNotValid = reCalculateSurveyRule[key].find(
            (item) => item.employeeID === row.data.employeeId,
          );
          try {
            row.setDataValue(key, !!isNotValid);
          } catch (error) {
            console.log(key);
          }
        }
      }
    }
  });

  //Update Hour per week table
  const newHrPerWeekData = calculateHrPerWeek({ data: employeeDataUpdate, weekRange, taskList });
  const hrPerWeekApi = listTableApi['hrPerWeek'].api;
  hrPerWeekApi.forEachNode((row) => {
    const rowNewValue = newHrPerWeekData.find((item) => item.employeeId === row.data.employeeId);

    row.updateData(rowNewValue);
  });
  axiosClient.post('calendar-plan/turnus/revert-cleaned-shift', { listRevertData }).then((_) => {});
  // const updateData = { ...cleanDataDefault };

  // const cleanedVakantShiftIDNew = updateData.cleanedVakantShiftID.filter(
  //   (item) => !cleanData.cleanedVakantShiftID.includes(item),
  // );
  // const assignListNew = updateData.assignList.filter(
  //   (item) => !cleanData.cleanedVakantShiftID.includes(item.cleanVakantID),
  // );
  // //Update cleanData
  // setCleanData({
  //   ...updateData,
  //   cleanedVakantShiftID: cleanedVakantShiftIDNew,
  //   assignList: assignListNew,
  // });
};

const findVakantMatched = ({ listEmpData, workingEnvSetup, taskData, roleList }) => {
  let vakantMatched;
  const { date: taskDate } = taskData;
  for (const vakant of listEmpData) {
    const workingDateList = Object.keys(vakant.task_arr);

    if (workingDateList.includes(taskDate) || vakant.name || !isNaN(vakant.employeeId)) {
      continue;
    }
    const {
      groupID: vakantGroupID,
      roleId: vakantRoleID,
      task_arr: employeeTaskList,
      allocatedHours,
      totalValidHour,
      hourAssigned,
    } = vakant;

    let isTooMuchShiftConsecutive = true;
    let isValidRoleAndGroup = true;
    let isValidTimeOffBetweenShift = true;
    let isValidShiftAfterWorkingNightAndLongWeekend = true;

    if (allocatedHours + hourAssigned > totalValidHour) {
      continue;
    }

    //Checking consecutive shift
    isTooMuchShiftConsecutive = hardRuleChecking.checkIsTooMuchShiftConsecutive({
      taskData,
      employeeTaskList,
      workingEnvSetup,
    });
    if (!isTooMuchShiftConsecutive) {
      continue;
    }

    //Checking Role And Group
    isValidRoleAndGroup = hardRuleChecking.checkingRoleAndGroup({
      taskData,
      vakantGroupID,
      vakantRoleID,
      roleList,
    });
    if (!isValidRoleAndGroup) {
      continue;
    }

    //Checking Time off between
    isValidTimeOffBetweenShift = hardRuleChecking.checkingTimeOffBetweenShift({
      taskData,
      employeeTaskList,
      workingEnvSetup,
    });
    if (!isValidTimeOffBetweenShift) {
      continue;
    }

    //Checking off Monday after working night or long shift at weekend
    isValidShiftAfterWorkingNightAndLongWeekend =
      weekendChecking.checkingOffAfterWorkingNightAndLongShiftWeekend({
        taskData,
        employeeTaskList,
      });
    if (!isValidShiftAfterWorkingNightAndLongWeekend) {
      continue;
    }
    if (vakantMatched) {
      if (allocatedHours > vakantMatched.allocatedHours) {
        vakantMatched = vakant;
      }
    } else {
      vakantMatched = vakant;
    }
  }
  return vakantMatched;
};
