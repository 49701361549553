import { InfoCircleBlueIcon } from 'assets/img';
import { t } from 'i18next';
import React, { Fragment } from 'react';
import './CleaningAlert.scss';

import ReactDOM from 'react-dom';
import { Toast } from 'helper/alert';
import axiosClient from 'axios/axiosClient';
import { useSelector } from 'react-redux';
import { selectGeneratorColApi } from 'redux/generateTableApi/slice';
import { revertCleanedShift } from '../helper/revertCleanedShift';
import { useTurnusContext } from 'components/CalendarPlan/GeneratorContent/Turnus/TurnusContext';
import { updateTableData } from '../helper/generatorLogic';
import { revertShiftCleaned } from '../helper/revertShiftCleaned';

const CleaningAlert = () => {
  const listTableApi = useSelector(selectGeneratorColApi);
  const turnusData = useTurnusContext();

  const { summarySelectedView, cleanData, setCleanData, setCleanDataAlert, showCleanAlert } =
    turnusData;
  const { cleanedVakantShiftID, assignList } = cleanData;
  if (!showCleanAlert || !assignList.length) {
    return;
  }
  const handleSubmit = () => {
    axiosClient
      .post('calendar-plan/v2/clean-vakant', {
        cleanedVakantShiftID,
        assignList: assignList.map((item) => ({ ...item, is_clean: false })),
      })
      .then((_) => {
        Toast({ title: `${cleanData.assignList.length} ${t('shift-cleaned')}`, icon: 'info' });
        const listTableUpdate = ['empInfo', 'taskList', 'dashboard'];
        for (const table of listTableUpdate) {
          const tableApi = listTableApi[table]?.api;
          if (tableApi && tableApi.forEachNode) {
            tableApi.forEachNode((row) => {
              if (row.data) {
                const rowTaskList = row.data.task_arr;
                const newTaskList = {};
                for (const date of Object.keys(rowTaskList)) {
                  const tasks = rowTaskList[date].map((item) => {
                    return {
                      ...item,
                      is_clean: false,
                      isClean: false,
                      isAssignByCleanVakant: false,
                    };
                  });
                  newTaskList[date] = tasks;
                }
                const rowNewValue = { ...row.data, task_arr: newTaskList };
                row.updateData(rowNewValue);
              }
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        // if (err) Toast({ title: ` ${t('shift-cleaned-unsucess')}`, icon: 'error' });
      })
      .finally((_) => {
        setCleanDataAlert(false);
      });
  };
  const handleRevert = () => {
    revertShiftCleaned({
      listTableApi,
      cleanData,
      summarySelectedView,
      setCleanData,
      cleanDataDefault: cleanData,
    });
    setCleanDataAlert(false);
  };
  const modal = (
    <div className="cleaning-alert__modal">
      <div className="cleaning-alert__wrapper">
        <div className="cleaning-alert__body">
          <img src={InfoCircleBlueIcon} alt="" width={18} />
          <p className="cleaning-alert__content">
            {cleanData.assignList.length} {t('clean-vakant-alert')}
          </p>
          <div className="cleaning-alert__action">
            <button className="custom-btn outline-btn" onClick={handleRevert}>
              {t('cancel')}
            </button>
            <button className="custom-btn " onClick={handleSubmit}>
              {t('save')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  return <Fragment>{ReactDOM.createPortal(modal, document.body)}</Fragment>;
};

export default CleaningAlert;
