import { useCheckAuthenticationForEmployee } from 'Hooks/useCheckAuthentication';
import React from 'react';
import { Navigate, useLocation } from 'react-router';

const EmployeeRoute = ({ children }) => {
  const location = useLocation();
  const isAuthenticated = useCheckAuthenticationForEmployee();
  if (!isAuthenticated) {
    return <Navigate to="/employee-login" state={{ from: location }} />;
  }

  return children;
};

export default EmployeeRoute;
