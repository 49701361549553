/** libs */
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';

import api from 'axios/axiosClient';

/** store */
import { hideLoading, showLoading } from '_components/Loading/slice';

const PREFIX = 'calendar-plan/employee';

const employeeAPI = () => {
  const dispatch = useDispatch();

  return {
    getEmployeesList: ({ unitSelected }) => {
      return useQuery(
        ['employees', unitSelected],
        async () => {
          const res = await api.post(PREFIX, { unitCode: unitSelected });
          return res;
        },

        {
          initialData: [],
          enabled: !!unitSelected,
        },
      );
    },

    deleteEmployee: async ({ data, page, successAction, errorAction }) => {
      dispatch(showLoading());
      return await api
        .post(`${PREFIX}/delete`, { employeeInfo: data, page })
        .then((result) => {
          successAction?.();
        })
        .catch((err) => {
          errorAction?.();
        })
        .finally(() => {
          dispatch(hideLoading());
        });
    },

    uploadEmployee: ({ googleSheetId, unitCode, fileData, uploadType, successAction }) => {
      dispatch(showLoading());
      return api
        .post(`${PREFIX}/upload`, { googleSheetId, unitCode, fileData, uploadType })
        .then((res) => {
          res.status === 200 && successAction?.();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          dispatch(hideLoading());
        });
    },
    splitEmployee: async ({ groupID, employeeID, jobPercentage, splitID, successAction }) => {
      return await api
        .post(`${PREFIX}/split-group`, { groupID, employeeID, jobPercentage, splitID })
        .then((res) => {
          res.status === 200 && successAction?.();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteSplitEmployee: async ({ splitID, successAction }) => {
      return await api
        .post(`${PREFIX}/split-group/delete`, { splitID })
        .then((res) => {
          res.status === 200 && successAction?.();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  };
};

export default employeeAPI;
