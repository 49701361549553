import React from 'react';
import { t } from 'i18next';
import './style.scss';
const TableLegend = ({ listGroupAndColor = [] }) => {
  const renderGroupLegend = () => {
    return listGroupAndColor?.map((item) => {
      return (
        <div key={`${item.id}-group-legend-${item.name}`} className="legend-wrap">
          <div className="legend" style={{ backgroundColor: item.color }}></div>
          <span>{item.name}</span>
        </div>
      );
    });
  };
  return (
    <div className="kalendar-legend">
      <div className="legend-wrap">
        <div className="dayOff legend"></div>
        <span>{t('day-off-legend')}</span>
      </div>
      <div className="legend-wrap">
        <div className="working-date legend"></div>
        <span>{t('validity-periods')}</span>
      </div>
      {renderGroupLegend()}

      <div className="legend-wrap">
        <div className="holiday legend"></div>
        <span>{t('holiday-legend')}</span>
      </div>
    </div>
  );
};

export default TableLegend;
