import React, { useMemo, useRef, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { t } from 'i18next';
import { useSelector } from 'react-redux';

import { useGroups, useRoles } from 'Hooks/useData';
import { selectGlobal } from 'redux/global/globalSlice';
import { formatNorwayPhoneNumber, renderCellError, validateBulkData } from './helper';
import { isEmailValid, isJobpercentageValid, isPhoneValid } from 'helper/dateTime';

import stringSimilarity from 'string-similarity';

import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import './styles.scss';
import TableHeader from './TableHeader';
import ErrorRole from './ErrorRole';
import RoleSelection from './RoleSelection';
import RoleTypeSelection from './RoleTypeSelection';
import { useEffect } from 'react';

const BulkCreateEmp = ({
  unitSelected,
  rowData,
  setIsTableError,
  showingAllEmployee,
  setFormData,
  employees,
  isShowingOld,
  roleTypeList,
  formData,
  setUndoEnabled,
  onSetGridRef,
}) => {
  const { data: roleFetch = [], refetch: refetchRoles } = useRoles();
  const {
    data: { data: groupFetch = [] },
  } = useGroups({ unitSelected });

  const groupList = useMemo(() => ['', ...groupFetch.map((group) => group.name)], [groupFetch]);
  const roleList = useMemo(() => ['', ...roleFetch.map((role) => role.role)], [roleFetch]);

  const { language } = useSelector(selectGlobal);
  const gridRef = useRef();

  const updateUndoState = () => {
    if (gridRef.current) {
      const gridApi = gridRef.current.api;
      setUndoEnabled(gridApi.getCurrentUndoSize() > 0);
    }
  };

  const onCellValueChanged = () => {
    updateUndoState();
  };

  useEffect(() => {
    onSetGridRef(gridRef);
  }, [gridRef]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === 'z') {
        updateUndoState();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const defaultColDef = useMemo(
    () => ({
      filter: false,
      sortable: true,
      editable: true,
      resizable: true,
      menuTabs: [],

      cellClass: 'dark-cell',
      suppressMovable: true,
      flex: 2,
    }),
    [rowData],
  );

  const isCellEmpty = (value) => {
    return (value && !value?.length) || !value ? true : false;
  };

  const columnDefs = useMemo(
    () => [
      {
        headerName: `${t('emp-id-number')}`,
        field: 'emp_id_number',
        flex: 1,
        // minWidth: 80,
        valueSetter: ({ data, newValue }) => {
          // const isDuplicate = employees.find(
          //   (emp) => emp.emp_id_number === newValue || +emp.emp_id_number === +newValue,
          // );
          // if (isDuplicate) {
          //   data.rowError.emp_id_number = true;
          // }
          data.emp_id_number = newValue?.trim();
          return true;
        },
        cellStyle: ({ data }) => {
          return renderCellError(data.rowError?.emp_id_number);
        },
      },
      {
        headerName: `${t('name')}`,
        headerComponent: TableHeader,
        headerTooltip:
          'Du kan markere to kolonner i excel (fornavn og etternavn) og lime de inn i denne kolonnen.',
        headerComponentParams: {
          title: `${t('name')}`,
          tooltip: true,
        },
        field: 'name',
        // minWidth: 200,
        valueSetter: ({ data, newValue, api, colDef }) => {
          data.name = newValue?.trim();

          data.rowError.name = isCellEmpty(newValue);
          colDef.cellStyle = () => renderCellError(isCellEmpty(newValue));

          return true;
        },
        cellStyle: ({ data }) => {
          return renderCellError(data.rowError?.name);
        },
      },
      {
        headerName: `${t('salary')}`,
        field: 'salary',
        // minWidth: 120,

        valueSetter: ({ data, newValue }) => {
          data.salary = newValue?.trim().replace(/\W/, '');
          return true;
        },
      },

      {
        headerName: t('email'),
        field: 'email',
        // minWidth: 150,
        valueSetter: ({ data, newValue }) => {
          data.email = newValue?.trim();
          const isError = newValue && !isEmailValid(newValue?.trim());
          data.rowError.email = isError;
          setIsTableError((prev) => ({
            ...prev,
            email: isError,
          }));
          return true;
        },
        cellStyle: ({ data }) => {
          return renderCellError(data.rowError?.email);
        },
      },

      {
        headerName: t('phone-number'),
        field: 'phone',
        // minWidth: 120,
        valueSetter: ({ data, newValue }) => {
          let phoneNumber = '';
          if (newValue !== null && newValue !== '') {
            const sanitizedValue = newValue ? newValue.replace(/[^+\d]/g, '') : '';
            phoneNumber = formatNorwayPhoneNumber(sanitizedValue);

            data.phone = phoneNumber?.trim();
          } else {
            data.phone = '';
          }
          const isError = isCellEmpty(newValue) ? false : !isPhoneValid(phoneNumber);

          data.rowError.phone = isError;
          setIsTableError((prev) => ({
            ...prev,
            phone: isError,
          }));
          return true;
        },
        cellStyle: ({ data }) => {
          return renderCellError(data.rowError?.phone);
        },
      },
      {
        headerName: `${t('job-percentage')} *`,
        field: 'jobPercentage',
        cellEditor: 'agNumericCellEditor',
        // minWidth: 150,
        valueSetter: ({ data, newValue }) => {
          if (!newValue) {
            data.jobPercentage = '';
            data.rowError.jobPercentage = false;
            setIsTableError((prev) => ({
              ...prev,
              jobPercentage: false,
            }));
            return true;
          }
          const formatValue = (string) => {
            const stringSplitByComas = string.split(',');
            const stringSplitByDot = string.split('.');
            let formatArr = stringSplitByDot;
            if (stringSplitByComas.length > stringSplitByDot.length) {
              formatArr = stringSplitByComas;
            }
            return formatArr.map((item) => item.replace(/\W/, '')).join('.');
          };
          const formattedValue = formatValue(newValue.replace(' ', '')?.trim());

          data.jobPercentage = formattedValue?.trim();
          const isError = !isJobpercentageValid(formattedValue);
          data.rowError.jobPercentage = isError;
          setIsTableError((prev) => ({
            ...prev,
            jobPercentage: isError,
          }));
          return true;
        },
        cellStyle: ({ data }) => {
          return renderCellError(data.rowError?.jobPercentage);
        },
      },
      {
        headerName: `${t('role')} *`,
        field: 'role',
        cellEditor: RoleSelection,
        cellEditorPopup: true,
        cellEditorParams: {
          roleList,
          setIsTableError,
          refetchRoles,
          setFormData,
        },
        // minWidth: 150,

        valueSetter: ({ data, newValue }) => {
          if (!newValue) {
            data.role = '';
            data.role_id = null;
            return true;
          }
          data.role = newValue?.trim();
          data.role_id = roleFetch.find(
            (role) => role.role.toLowerCase() === data.role?.toLowerCase(),
          )?.id;
          let isError = !data.role_id;

          if (isError && roleList && roleList.length) {
            const roleSimilar = stringSimilarity.findBestMatch(newValue.toUpperCase(), roleList);
            if (roleSimilar.bestMatch.rating > 0.7) {
              const correctRole = roleSimilar.bestMatch.target;
              isError = false;
              data.role = roleSimilar.bestMatch.target;
              data.role_id = roleFetch.find((role) => role.role === correctRole)?.id;
            }
          }
          data.rowError.role = isError;
          setIsTableError((prev) => ({
            ...prev,
            role: isError,
          }));
          return true;
        },
        cellRenderer: (params) => {
          const { value, data } = params;
          return value;
        },
        cellStyle: ({ data }) => {
          return renderCellError(data.rowError?.role);
        },
      },
      {
        headerName: `${t('role-type')} *`,
        field: 'role_type_name',
        cellEditor: RoleTypeSelection,
        cellEditorPopup: true,

        cellEditorParams: {
          roleTypeList,
        },
        // minWidth: 150,
        valueSetter: ({ data, newValue }) => {
          if (!newValue) {
            data.role_type_name = '';
            data.role_type = '';
            return true;
          }
          if (isNaN(newValue)) {
            const roleTypeFound = roleTypeList.find(
              (roleType) => roleType.type === newValue?.trim(),
            );
            if (roleTypeFound) {
              data.role_type_name = roleTypeFound.type;
            }
          }

          data.role_type_name = newValue;
          let isError = !roleTypeList.find((roleType) => roleType.type === newValue);
          data.rowError.roleTypeName = isError;
          if (!isError) {
            const roleTypeFound = roleTypeList.find((roleType) => roleType.type === newValue);
            if (roleTypeFound) {
              data.role_type = roleTypeFound.id;
            }
          }

          setIsTableError((prev) => ({
            ...prev,
            roleTypeName: isError,
          }));
          return true;
        },
        cellRenderer: (params) => {
          const { value, data } = params;

          // const roleTypeID = data.role_type_name;
          // if (roleTypeID && !isNaN(roleTypeID)) {
          //   const roleType = roleTypeList.find((item) => +item.id === +roleTypeID);
          //   return roleType ? roleType.type : '';
          // }
          return value;
        },
        cellStyle: ({ data }) => {
          return renderCellError(data.rowError?.roleTypeName);
        },
      },
      {
        headerName: t('group'),
        field: 'group',
        cellEditor: 'agSelectCellEditor',
        // minWidth: 150,
        cellEditorParams: {
          values: groupList,
        },
        valueSetter: ({ data, newValue }) => {
          if (!newValue) {
            data.group = '';
            data.group_id = null;
            return true;
          }
          data.group = newValue?.trim();
          data.group_id = groupFetch.find((group) => group.name === newValue.trim())?.id;
          let isError = isCellEmpty(newValue)
            ? false
            : !groupList.find((group) => group === newValue.trim());
          if (isError && groupList && groupList.length) {
            const groupSimilar = stringSimilarity.findBestMatch(newValue, groupList);
            if (groupSimilar.bestMatch.rating > 0.7) {
              const correctGroup = groupSimilar.bestMatch.target;
              isError = false;
              data.group = groupSimilar.bestMatch.target;
              data.group_id = groupFetch.find((role) => role.role === correctGroup)?.id;
            }
          }
          data.rowError.group = isError;
          setIsTableError((prev) => ({
            ...prev,
            group: isError,
          }));
          return true;
        },
        cellStyle: ({ data }) => {
          return renderCellError(data.rowError?.group);
        },
      },
    ],
    [roleFetch, roleList, groupList, language, rowData, roleTypeList, formData],
  );
  const processDataFromClipboard = (params) => {
    const focusedCell = params.api.getFocusedCell();
    if (focusedCell) {
      const columnName = focusedCell.column.colId;
      if (columnName === 'name') {
        const convertName = params.data.map((item) => item.reverse());
        return convertName.map((item) => {
          const nameCopy = item.filter((name) => name?.length);
          if (nameCopy.length > 1) {
            const indexFirstName = nameCopy.findIndex((nameCopy) => /\w{1,}/.test(nameCopy));
            if (indexFirstName >= 0) {
              nameCopy[indexFirstName] += ',';
            }
          }
          return [nameCopy.join(' ')];
        });
      }
    }

    const mergeData = params.data.map((item) => {
      return [item.join(' ')];
    });

    return mergeData;

    // return params.data;
  };
  //WILL BE IMPLEMENT AFTER HAVE DESIGN
  // const listDuplicate = useMemo(() => {
  //   const listDuplicate = [];
  //   const listEmpID = employees.map((item) => item.emp_id_number);
  //   rowData.forEach((item) => {
  //     if (listEmpID.includes(item.emp_id_number) || listEmpID.includes(+item.emp_id_number)) {
  //       listDuplicate.push(item);
  //     }
  //   });
  //   return listDuplicate;
  // }, [rowData]);

  // const warningContent = useMemo(() => {
  //   if (!listDuplicate || !listDuplicate.length) return '';
  // }, [listDuplicate]);
  return (
    <div className="bulk-create__wrapper">
      <div className="bulk-create__header">
        <p className="multiple-desc">{t('multiple-desc')}</p>
        <div className="bulk-create__actions">
          <input
            type="checkbox"
            name=""
            id="show-all-employee"
            checked={isShowingOld}
            onChange={() => {
              showingAllEmployee();
            }}
          />
          <label htmlFor="show-all-employee">{t('show-all-employee')}</label>
        </div>
      </div>

      <div className="bulk-create__table ag-theme-alpine">
        <AgGridReact
          ref={gridRef}
          onFirstDataRendered={updateUndoState}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowData={rowData}
          animateRows={true}
          enableRangeSelection={true}
          enableFillHandle={true}
          undoRedoCellEditing={true}
          undoRedoCellEditingLimit={20}
          tooltipShowDelay={0}
          processDataFromClipboard={processDataFromClipboard}
          reactiveCustomComponents={true}
          stopEditingWhenCellsLoseFocus={true}
          onCellValueChanged={onCellValueChanged}
        />
      </div>

      {/* <div className="bulk-warning">
        {listDuplicate.length ? t('duplicate-employee-bulk-create') : ''}
      </div> */}
    </div>
  );
};

export default BulkCreateEmp;
