import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  data: null,
  name: null,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    open(state, action) {
      const { name, data = null, action: callback = null } = action.payload;
      state.name = name;
      state.data = data;
      state.action = callback;
    },
    close(state, action) {
      const { action: actionState } = state;
      actionState?.();
      state.data = null;
      state.name = null;
    },
    clear: () => initialState,
  },
});

export const selectModal = (state) => state.modal;
export const { open: openModal, close: closeModal } = modalSlice.actions;
export default modalSlice.reducer;
