import { CloseOutlined } from '@ant-design/icons';
import axiosClient from 'axios/axiosClient';
import { Toast } from 'helper/alert';
import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';

import './CreateStaffTaskForm.scss';
import NavTabs from 'components/NavTabs/NavTabs';
import SingleStaffTask from './components/SingleStaffTask';
import MultipleStaffTask from './components/MultipleStaffTask';
import { usePaidBreakType } from 'Hooks/useData';
export default function CreateStaffTaskForm({
  defaultTask = {},
  refetchTask,
  setActiveCreateForm,
  unitSelected,
  roleList,
  listTaskType,
  listGroup,
}) {
  const TAB_LIST = [
    {
      key: 'one-guard-code',
      value: 'one-guard-code',
      desc: 'create-single-guard-code-desc',
    },
    {
      key: 'more-guard-code',
      value: 'more-guard-code',
      desc: 'create-multiple-guard-code-desc',
    },
  ];

  const [currentTab, setCurrentTab] = useState('one-guard-code');
  const formDesc = TAB_LIST.find((item) => item.key === currentTab).desc;
  const [taskWillCreate, setTaskWillCreate] = useState({
    unit_code: unitSelected,
    from_time: '',
    to_time: '',
    pause: '00:30',
    is_paid_break: false,
  });
  const initBulkData = Array.from({ length: 10 }).fill({
    unit_code: unitSelected,
    from_time: '',
    to_time: '',
    pause: '00:30',
    is_paid_break: false,
  });
  const [bulkData, setBulkData] = useState(initBulkData || []);
  const { data: paidBreakType } = usePaidBreakType();
  const isBulkCreateForm = currentTab === 'more-guard-code';

  const renderTaskTypeOption = () => {
    return listTaskType
      .filter((type) => type.value >= 0 && type.value !== 4) // Off Shift value;)
      .map((type) => {
        return (
          <option key={`${type.key}-${type.name}`} value={type.id}>
            {t(type.key)}
          </option>
        );
      });
  };
  const renderTaskGroupOption = () => {
    return listGroup.map((type) => {
      return (
        <option key={`${type.key}-${type.name}`} value={type.id}>
          {t(type.name)}
        </option>
      );
    });
  };

  const isSubmitAble =
    taskWillCreate.unit_code &&
    taskWillCreate.from_time &&
    taskWillCreate.task_name &&
    taskWillCreate.type_id;

  useEffect(() => {
    if (defaultTask && Object.keys(defaultTask).length) {
      setTaskWillCreate(defaultTask);
    }
  }, [defaultTask]);

  const defaultTimeInput = {
    from_time: Array(2).fill(''),
    to_time: Array(2).fill(''),
  };
  const [currentTimeInput, setCurrentTimeInput] = useState(defaultTimeInput);

  const handleKeyDown = useCallback(
    (e) => {
      const { keyCode } = e;
      const { name } = e.target;

      // Check if the pressed key is a digit
      if (keyCode >= 48 && keyCode <= 57) {
        e.preventDefault();
        const digit = String.fromCharCode(keyCode);

        setCurrentTimeInput((prev) => {
          let newHr = [...prev[name]];

          // Check if any hour digit is empty
          if (newHr[0] === '') {
            newHr[0] = digit;
          } else if (newHr[1] === '') {
            newHr[1] = digit;
          }
          return { ...prev, [name]: newHr };
        });
      }
    },
    [currentTimeInput],
  );

  useEffect(() => {
    if (!currentTimeInput.from_time.some((digit) => digit === '')) {
      const newTime = `${currentTimeInput.from_time[0]}${currentTimeInput.from_time[1]}:00`;
      setTaskWillCreate((prev) => ({ ...prev, from_time: newTime }));
    }
  }, [currentTimeInput]);

  useEffect(() => {
    if (!currentTimeInput.to_time.some((digit) => digit === '')) {
      const newTime = `${currentTimeInput.to_time[0]}${currentTimeInput.to_time[1]}:00`;
      setTaskWillCreate((prev) => ({ ...prev, to_time: newTime }));
    }
  }, [currentTimeInput]);

  const renderForm = () => {
    switch (currentTab) {
      case 'one-guard-code':
        return (
          <SingleStaffTask
            renderTaskTypeOption={renderTaskTypeOption}
            renderTaskGroupOption={renderTaskGroupOption}
            unitSelected={unitSelected}
            refetchTask={refetchTask}
            setActiveCreateForm={setActiveCreateForm}
            taskWillCreate={taskWillCreate}
            setTaskWillCreate={setTaskWillCreate}
            paidBreakType={paidBreakType}
          />
        );
      case 'more-guard-code':
        return (
          <MultipleStaffTask
            renderTaskTypeOption={renderTaskTypeOption}
            renderTaskGroupOption={renderTaskGroupOption}
            unitSelected={unitSelected}
            refetchTask={refetchTask}
            setActiveCreateForm={setActiveCreateForm}
            bulkData={bulkData}
            setBulkData={setBulkData}
            paidBreakType={paidBreakType}
          />
        );

      default:
        return (
          <SingleStaffTask
            renderTaskTypeOption={renderTaskTypeOption}
            renderTaskGroupOption={renderTaskGroupOption}
            unitSelected={unitSelected}
            refetchTask={refetchTask}
            setActiveCreateForm={setActiveCreateForm}
            taskWillCreate={taskWillCreate}
            setTaskWillCreate={setTaskWillCreate}
          />
        );
    }
  };
  const checkingIsSubmitAble = (task) => {
    return task.unit_code && task.from_time && task.to_time && task.task_name && task.type_id;
  };
  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitAble && !isBulkCreateForm) {
      await axiosClient
        .post('calendar-plan/staff-task/create', {
          taskWillCreate: taskWillCreate,
        })
        .then((_) => {
          setActiveCreateForm(false);
          refetchTask();
          Toast({ icon: 'success', title: t('save-changes') });
        })
        .catch((err) => {
          Toast({ icon: 'error', title: t('save-fail') });
        });
    }

    if (isBulkCreateForm) {
      const errorData = bulkData
        .filter((task) => task.from_time || task.to_time || task.task_name || task.type_id)
        .reduce((curr, task) => {
          return !checkingIsSubmitAble(task) ? curr + 1 : curr;
        }, 0);
      if (errorData > 0) {
        Toast({ icon: 'warning', title: t('please-check-data') });

        return;
      }
      await axiosClient
        .post('calendar-plan/staff-task/bulk-create', {
          bulkData: bulkData.filter((task) => checkingIsSubmitAble(task)),
        })
        .then((_) => {
          setActiveCreateForm(false);
          refetchTask();
          Toast({ icon: 'success', title: t('save-changes') });
        })
        .catch((err) => {
          Toast({ icon: 'error', title: t('save-fail') });
        });
    }
  };
  return (
    <div className="create-staff-task-form__wrap">
      <div
        className="create-staff-task-form__background"
        onClick={() => setActiveCreateForm(false)}
      ></div>
      <div className={`create-staff-task-form__content ${isBulkCreateForm ? 'bulk' : ''}`}>
        <div className="create-staff-task-form__header">
          <CloseOutlined
            onClick={() => {
              setActiveCreateForm(false);
            }}
          />
          <h5 className="create-staff-task-form__title">
            {Object.keys(defaultTask).length ? t('update-task') : t('add-task')}
          </h5>
        </div>
        <NavTabs
          tabList={TAB_LIST}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          defaultTask={defaultTask}
        />
        <p className="create-staff-task-form__desc">{t(formDesc)}</p>
        <form onSubmit={handleOnSubmit}>
          {renderForm()}
          <div className="create-staff-task-form__footer">
            <button className="custom-btn outline-btn" onClick={() => setActiveCreateForm(false)}>
              {t('cancel')}
            </button>
            <button className="custom-btn">{t('add')}</button>
          </div>
        </form>
      </div>
    </div>
  );
}
