import React, { useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import NoData from '../../NoData';

import PlanAction from './PlanAction';
import { usePlanList } from 'Hooks/useData';
import axiosClient from 'axios/axiosClient';
import { read, utils } from 'xlsx';
import { getColName } from 'helper/getColumnOfExcelName';
import { Toast, confirmDelete, showLoading } from 'helper/alert';
import UploadFileFormv2 from 'components/CalendarPlan/UploadFileForm/UploadFileFormv2';

import './PlanListContent.scss';
import { useSelector } from 'react-redux';
import { selectAuth } from 'redux/auth/authSlice';

const PlanListContentv2 = ({
  unitSelected,
  isUploading,
  setIsUploading,
  setPlanSelected,
  setActiveCreateForm,
  setAddNewGuardCode,
  setIsMainContent,
}) => {
  const { isEditabled } = useSelector(selectAuth);

  const {
    data: rowData,
    refetch: refetchPlanList,
    isFetched: isPlanFetched,
  } = usePlanList({ unitSelected });

  const handleDelete = (plan) => {
    confirmDelete({}).then(async (result) => {
      if (result.isConfirmed) {
        showLoading({ shouldShow: true, title: 'deleting' });
        axiosClient
          .post('calendar-plan/staff-plan/delete', { planWillDelete: plan })
          .then((result) => {
            refetchPlanList();

            Toast({ icon: 'success', title: `${t('delete')} ${t('success')}` });
          })
          .catch((err) => {
            Toast({ icon: 'error', title: `${t('delete')} ${t('unsuccess')}` });
          });
      }
    });
  };

  const handleFileChange = async (file, planInfo, setIsUploadFile) => {
    try {
      const fromDate = moment(planInfo.from);
      const endDate = moment(planInfo.to);
      const title = ['Dagvakt', 'Aftenvakt', 'Adm', 'Nattvakt', 'Kombinasjonskode'];
      const copyShiftSymbol = '-->';
      const shiftReg = /\w{1,}(\s\(\w{1,}:\w{1,}-\w{1,}:\w{1,}\))/;
      const shiftTimeReg = /(\s\(\w{1,}:\w{1,}-\w{1,}:\w{1,}\))/;
      const dataResult = [];
      const listTaskName = [];
      await file.arrayBuffer().then((data) => {
        const workbook = read(data);
        const sheet = workbook['Sheets'];
        const sheetName = Object.keys(sheet)[0];
        const bemanningsplanData = sheet[sheetName];
        if (bemanningsplanData) {
          const range = utils.decode_range(bemanningsplanData['!ref']);
          const starRow = 2;
          const endColumn = range['e']['c'];
          const endRow = range['e']['r'];
          for (let i = starRow; i <= endRow + 1; i++) {
            // row loop
            let taskName = '';
            let roleKey = null;
            if (title.includes(bemanningsplanData[`A${i}`].v)) {
              continue;
            }
            for (let j = 0; j <= endColumn; j++) {
              // column loop
              const column = getColName(j);
              const columnData = bemanningsplanData[`${column}${i}`];
              if (columnData) {
                if (shiftReg.test(columnData.v)) {
                  taskName = columnData.v.replace(shiftTimeReg, '');
                  listTaskName.push({ taskName, roleKey: null });
                } else if (columnData.v == copyShiftSymbol) {
                  roleKey = bemanningsplanData[`B${i}`].v;
                  taskName = bemanningsplanData[`A${i - 1}`].v.replace(shiftTimeReg, '');
                  listTaskName.push({ taskName, roleKey });
                } else {
                  if (j > 1) {
                    const date = fromDate
                      .clone()
                      .add(j - 2, 'd')
                      .format('YYYY-MM-DD');
                    let numberPerson = columnData.v;
                    if (bemanningsplanData[`A${i + 1}`]?.v == copyShiftSymbol) {
                      const slicePerson = +bemanningsplanData[`${column}${i + 1}`].v;
                      numberPerson = numberPerson - slicePerson || 0;
                    }
                    dataResult.push({
                      taskName,
                      roleKey,
                      date,
                      numberPerson,
                    });
                  }
                }
              } else {
                if (j > 1) {
                  const date = fromDate
                    .clone()
                    .add(j - 2, 'd')
                    .format('YYYY-MM-DD');
                  dataResult.push({
                    taskName,
                    roleKey,
                    date,
                    numberPerson: 0,
                  });
                }
              }
            }
          }
        }
      });

      axiosClient
        .post('/calendar-plan/upload-staff-plan', {
          data: dataResult,
          listTaskName,
          fileName: planInfo.planName,
          fromDate: fromDate.format('YYYY-MM-DD'),
          endDate: endDate.format('YYYY-MM-DD'),
          unitCode: unitSelected,
          planInfo,
        })
        .then((_) => {
          setIsUploading(false);
          setIsUploadFile(false);
          refetchPlanList();
          Toast({ icon: 'success', title: `${t('submit')} ${t('success')}` });
        })
        .catch((err) => {
          Toast({ icon: 'error', title: `${t('submit')} ${t('fail')}` });
          setIsUploading(false);
          setIsUploadFile(false);
        });
    } catch (error) {
      setIsUploading(false);

      console.log(error);
      Toast({ icon: 'error', title: `${t('submit')} ${t('fail')}` });
    }
  };
  const ROW_HEIGHT = 45;
  const HEADER_HEIGHT = 50;

  const defaultColDef = useMemo(
    () => ({
      filter: false,
      sortable: true,
      editable: false,
      resizable: false,
      menuTabs: [],
      flex: 1,
    }),
    [],
  );

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const onPlanSelected = (plan) => {
    setIsMainContent(false);
    setPlanSelected(plan);
  };

  const columnDefs = useMemo(
    () => [
      {
        field: 'plan_name',
        headerName: t('plan-name'),
        colId: 'plan-name',
        flex: 1,
        minWidth: 250,
        cellRenderer: ({ data }) => {
          return (
            <span className="plan-name" onClick={() => onPlanSelected(data)}>
              {data.plan_name}
            </span>
          );
        },
      },
      {
        field: 'from_date',
        headerName: t('from'),
        colId: 'plan-from',
        width: 170,
        headerClass: 'right-align-header',
        cellStyle: { textAlign: 'right' },
        valueFormatter: ({ value }) => moment(value).format('DD. MMMM YYYY').toLowerCase(),
      },
      {
        field: 'to_date',
        headerName: t('to'),
        colId: 'plan-to',
        width: 170,
        valueFormatter: ({ value }) => moment(value).format('DD. MMMM YYYY').toLowerCase(),
      },
      {
        headerName: t('num-weeks'),
        colId: 'plan-weeks',
        width: 100,
        valueGetter: ({ data }) => {
          const { from_date, to_date } = data;
          const startDate = moment(from_date);
          const endDate = moment(to_date);
          const weeks = endDate.diff(startDate, 'w') + 1;
          return weeks;
        },
      },
      {
        headerName: t('created-by'),
        colId: 'plan-created',
        flex: 1,
        minWidth: isEditabled ? 300 : 100,
        sortable: false,
        cellRenderer: (params) => {
          return (
            <PlanAction
              plan={params.data}
              setPlanSelected={setPlanSelected}
              setActiveCreateForm={setActiveCreateForm}
              handleDelete={handleDelete}
              setIsMainContent={setIsMainContent}
            />
          );
        },
      },
    ],
    [currentLanguage],
  );

  if (!isPlanFetched) {
    return;
  }

  return (
    <div
      className="ag-theme-alpine planlist-container"
      style={{ height: rowData.length ? rowData.length * ROW_HEIGHT + HEADER_HEIGHT + 3 : 300 }}
    >
      {rowData.length !== 0 ? (
        <AgGridReact
          rowData={rowData}
          rowHeight={ROW_HEIGHT}
          headerHeight={HEADER_HEIGHT}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          animateRows={true}
          suppressMovableColumns={true} //disable default drag column
        />
      ) : (
        <NoData setAddNewGuardCode={setAddNewGuardCode} setActiveCreateForm={setActiveCreateForm} />
      )}

      {isUploading ? (
        <UploadFileFormv2
          setActive={setIsUploading}
          unitSelected={unitSelected}
          refetchPlanList={refetchPlanList}
          handleFileChange={handleFileChange}
        />
      ) : null}
    </div>
  );
};

export default PlanListContentv2;
