import React, { useEffect } from 'react';
import { t } from 'i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';

import Loading from 'components/Loading/Loading';

import { useTaskList } from 'Hooks/useData';
import { selectGlobal } from 'redux/global/globalSlice';
import { CheckboxCheckIcon, CheckboxUncheckIcon } from 'assets/img';
import { DAY_SHIFT_VALUE, LONG_SHIFT_VALUE, PREDICT_SHIFT_VALUE } from 'constant';

const DEFAULT_VALUE = '-';

const TaskListTable = ({ selectedTask, setSelectedTask, setUnselectedTask }) => {
  const { unitSelected } = useSelector(selectGlobal);
  const { data: taskList, isFetched: isTaskListFetched } = useTaskList({ unitSelected });

  useEffect(() => {
    if (taskList) {
      setUnselectedTask(
        taskList.filter(
          (task) => !selectedTask.map((item) => item.taskUUID).includes(task.taskUUID),
        ),
      );
    }
  }, [selectedTask, taskList]);
  const handleAddTask = (selectTask) => {
    if (selectedTask.map((task) => task.taskUUID).includes(selectTask.taskUUID)) {
      setSelectedTask(selectedTask.filter((item) => item.taskUUID !== selectTask.taskUUID));
    } else {
      setSelectedTask((prev) => [...prev, { ...selectTask, taskList: [] }]);
    }
  };
  return (
    <div className="task-list-table">
      {isTaskListFetched ? (
        <table className="">
          <thead>
            <tr>
              <th>{t('guard-code')}</th>
              {/* <th>{t('role')}</th>
              <th>{t('group')}</th> */}
              <th>{t('from')}</th>
              <th>{t('to')}</th>
            </tr>
          </thead>

          <tbody>
            {taskList
              .filter(
                (item) =>
                  item.taskTypeValue >= DAY_SHIFT_VALUE && item.taskTypeValue <= LONG_SHIFT_VALUE, // Filtered predict and off shift
              )
              .filter((item) => !item.roleID) //Not Showing shift have role
              .map((task) => (
                <tr key={task.taskUUID} onClick={() => handleAddTask(task)}>
                  <td className="code-name-col data-cell">
                    <img
                      src={
                        selectedTask.map((item) => item.taskUUID).includes(task.taskUUID)
                          ? CheckboxCheckIcon
                          : CheckboxUncheckIcon
                      }
                    />
                    {task.taskName || DEFAULT_VALUE}
                  </td>
                  {/* <td className="data-cell">{task.role || DEFAULT_VALUE}</td>
                  <td className="data-cell">{task.groupName || DEFAULT_VALUE}</td> */}
                  <td className="data-cell">
                    {moment(task.fromTime, 'HH:mm:ss').format('HH:mm') || DEFAULT_VALUE}
                  </td>
                  <td className="data-cell">
                    {moment(task.endTime, 'HH:mm:ss').format('HH:mm') || DEFAULT_VALUE}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default TaskListTable;
