import { useQuery } from 'react-query';

import axiosClient from 'axios/axiosClient';
import { extractDateOff } from 'helper/chart/getData';
import { generalSettingKey } from './constants';
import { HOSPITAL_ENV, NIGHT_SHIFT_VALUE } from 'constant';
import getEnvironmentType from 'helper/environment/checkingEnvironment';

const PAGE = 0;
const TOTAL_PAGE = 1;
const RECORD_PER_PAGE = 10;

export const userVerionDateRange = ({ versionID }) => {
  return useQuery(
    ['version-date-range', versionID],
    async () => {
      const result = await axiosClient.post('/calendar-plan/verion-date-range', {
        versionID,
      });
      return result;
    },
    {
      initialData: {},
      enabled: !!versionID,
    },
  );
};
export const usePlanList = ({ unitSelected, isOptimized }) => {
  return useQuery(
    ['planList', unitSelected, isOptimized],
    async () => {
      const { result } = await axiosClient.post('/calendar-plan/staff-plan', {
        unitCode: unitSelected,
        isOptimized,
      });
      return result;
    },
    {
      initialData: [],
      enabled: !!unitSelected,
    },
  );
};

export const usePlanListAndChildren = ({ unitSelected, isOptimized }) => {
  return useQuery(
    ['planList-children', unitSelected, isOptimized],
    async () => {
      const result = await axiosClient.post('calendar-plan/staff-plan-child', {
        unitCode: unitSelected,
      });
      return result;
    },
    {
      initialData: [],
      enabled: !!unitSelected,
    },
  );
};

export const usePlanGetActiveVersion = ({ planID }) => {
  return useQuery(
    [`version-${planID}`, planID],

    async () => {
      const result = await axiosClient.post('calendar-plan/staff-plan/highest-version', { planID });
      return result;
    },
    {
      initialData: {},
      enabled: !!planID,
    },
  );
};

export const useHoliday = ({
  years = [new Date().getFullYear(), new Date().getFullYear() + 1, new Date().getFullYear() + 2],
  country = 'NO',
} = {}) => {
  return useQuery(
    ['publicHoliday'],
    async () => {
      try {
        const responses = await Promise.all(
          years.map((year) =>
            axiosClient.get(`https://date.nager.at/Api/v2/PublicHolidays/${year}/${country}`),
          ),
        );
        return responses.flat().map((item) => item.date);
      } catch (error) {
        const data = await axiosClient.post('/public-holiday');
        return data;
      }
    },

    { initialData: [] },
  );
};
export const useHolidayWithName = ({
  years = [new Date().getFullYear(), new Date().getFullYear() + 1, new Date().getFullYear() + 2],
  country = 'NO',
} = {}) => {
  return useQuery(
    ['publicHoliday-external'],
    async () => {
      try {
        const responses = await Promise.all(
          years.map((year) =>
            axiosClient.get(`https://date.nager.at/Api/v2/PublicHolidays/${year}/${country}`),
          ),
        );
        return responses.flat();
      } catch (error) {
        console.log(error);
        return [];
      }
    },

    { initialData: [] },
  );
};

export const useEmpDayOff = ({ unitSelected }) => {
  return useQuery(
    ['empDayOff', unitSelected],
    async () => {
      const response = await axiosClient.post('calendar-plan/get-date-off', {
        unitCode: unitSelected,
      });
      const data = response.map((item) => ({ ...item, dayOff: extractDateOff(item.dayOff) || [] }));
      return data;
    },
    {
      initialData: [],
    },
  );
};

export const useAMLInformation = ({ unitSelected: unitCode }) => {
  return useQuery(
    ['amlInformation', unitCode],
    async () => {
      const data = await axiosClient.post('/calendar-plan/aml/information', { unitCode });
      return data;
    },
    { initialData: {}, enabled: !!unitCode },
  );
};

export const useAMLQuestions = () => {
  return useQuery(
    ['amlQuestions'],
    async () => {
      const res = await axiosClient.post('calendar-plan/aml/questions');
      return res.data;
    },
    { initialData: [] },
  );
};

export const useAMLAnswers = ({ unitSelected: unitCode }) => {
  return useQuery(
    ['amlAnswers', unitCode],
    async () => {
      const res = await axiosClient.post('calendar-plan/aml/anwsers', { unitCode });
      return res.data;
    },
    { initialData: [], enabled: !!unitCode },
  );
};

export const useSurveyRecord = ({ unitSelected: unitCode, activeSurvey }) => {
  return useQuery(
    ['surveyRecord', unitCode, activeSurvey],
    async () => {
      const customerType = getEnvironmentType();
      const listDiffWishOfKommune = ['holiday_off', 'extend_date_off'];
      const data = await axiosClient.post('/calendar-plan/survey/get-employee-record', {
        unitCode,
      });

      if (customerType === HOSPITAL_ENV) {
        return data.map((item) => {
          const surveyRecord = item.surveyRecord.map((record) => {
            if (listDiffWishOfKommune.includes(record.wishProperty)) {
              if (record.answer?.dateArr) {
                const convertAnswer = record.answer?.dateArr.slice(0, 3);
                return { ...record, dateArr: convertAnswer };
              } else {
                const convertAnswer = record.answer?.slice(0, 3);
                return { ...record, answer: convertAnswer };
              }
            }
            return { ...record };
          });

          return { ...item, surveyRecord };
        });
      }

      return data;
    },
    { initialData: [], enabled: !!unitCode },
  );
};

export const useEmployeeList = ({
  weekSelect,
  unitSelected,
  planSelected,
  sortObj,
  versionID,
  isPlanSelectedInProgressPlan,
}) => {
  return useQuery(
    [
      'employeePlanList',
      weekSelect,
      unitSelected,
      planSelected.id,
      sortObj,
      versionID,
      isPlanSelectedInProgressPlan,
    ],
    async () => {
      let data = await axiosClient.post('/calendar-plan/turnus/employee-data', {
        unitCode: unitSelected,
        parentID: planSelected.parentID,
        versionID,
      });

      // data.dataSummary['numberWorkingWeekend'] = data.numberWorkingWeekend.reduce(
      //   (total, item) => total + item.numberWeekend,
      //   0,
      // );
      return data;
    },
    {
      initialData: {},
      enabled: !!planSelected.id && !!versionID,
    },
  );
};

export const useVakantList = ({
  weekSelect,
  unitSelected,
  planSelected,
  versionID,
  isPlanSelectedInProgressPlan,
}) => {
  return useQuery(
    ['vacantList', planSelected.id, unitSelected, versionID, isPlanSelectedInProgressPlan],
    async () => {
      let data = await axiosClient.post('/calendar-plan/turnus/vakant-data', {
        weekSelect,
        unitCode: unitSelected,
        planId: planSelected.id,
        isCleanVakant: true,
        versionID,
      });
      return data;
    },
    {
      initialData: {},
      enabled: !!planSelected.id && !!versionID,
    },
  );
};
export const useVakantDetail = ({
  weekSelect,
  unitSelected,
  planSelected,
  isGetEach,
  vakantID,
  versionID,
}) => {
  return useQuery(
    [`vacantDatail-${vakantID}`, vakantID, versionID],
    async () => {
      const res = await axiosClient.post('/calendar-plan/kalender/vakant', {
        weekSelect,
        unitCode: unitSelected,
        planId: planSelected.id,
        isCleanVakant: true,
        isGetEach,
        vakantID,
        versionID,
      });
      return res;
    },
    {
      initialData: {},
      enabled: !!planSelected.id && !!versionID,
    },
  );
};
export const useEmpSurveyCheckList = (
  { unitSelected, planSelected, isAvailable, isOptimized },
  onSuccess,
) => {
  return useQuery(
    ['empSurveyCheckList', unitSelected, planSelected.id, isAvailable, isOptimized],
    async () => {
      const res = await axiosClient.post('calendar-plan/kalender/dashboard/survey', {
        unitCode: unitSelected,
        planId: planSelected.id,
      });
      return res;
    },
    {
      enabled: !!planSelected.id,
      onSuccess,
    },
  );
};

export const useProgressData = ({
  planSelected,
  unitSelected,
  refetchPlanList,
  progressPlan,
  isAvailable,
  versionID,
}) => {
  // get progress plan data
  return useQuery(
    ['progressPlan', planSelected.id, unitSelected, versionID],
    async () => {
      let result = [];
      await axiosClient
        .post('calendar-plan/kalender/plan-optimizing', {
          planId: planSelected.id,
          unitCode: unitSelected,
          versionID,
        })
        .then((res) => {
          result = res;
        });

      return result;
    },

    {
      onSuccess: () => {
        if (!progressPlan.length) {
          refetchPlanList();
        }
      },
      enabled: !!versionID,

      initialData: [],
      //WILL BE REMOVE AFTER SETUP SOCKET ON JAVA SERVER
      refetchInterval: !isAvailable && Object.keys(planSelected).length > 0 ? 2000 : false,
      refetchOnWindowFocus: !isAvailable && Object.keys(planSelected).length > 0 ? true : false,
      refetchOnMount: !isAvailable && Object.keys(planSelected).length > 0 ? true : false,
    },
  );
};

export const useEmployeeInfo = ({ empSelected }) => {
  return useQuery(
    ['employeeInfo', empSelected],
    async () => {
      const res = await axiosClient.post('calendar-plan/emp-info', {
        userId: empSelected.employeeId || empSelected.employee_id,
      });

      return res;
    },
    {
      initialData: {},
      enabled: !!empSelected.employeeId || !!empSelected.employee_id,
    },
  );
};

export const useEmployeeGuestInfo = (employeeId) => {
  return useQuery(
    ['employeeInfo', employeeId],
    async () => {
      const res = await axiosClient.post('calendar-plan/guest/emp-info', {
        userId: employeeId,
      });

      return res;
    },
    {
      initialData: {},
      enabled: !!employeeId,
    },
  );
};

export const usePredictPercent = ({ plan }) => {
  return useQuery(
    ['predict-percent', plan.id],
    async () => {
      const res = await axiosClient.post('calendar-plan/staff-plan/predict-percent', {
        planID: plan.id,
      });

      return res;
    },
    {
      initialData: {},
      enabled: !!plan.id,
    },
  );
};
export const useLayerList = ({ unitSelected, planID }) => {
  return useQuery(
    ['layer-list', unitSelected, planID],
    async () => {
      const res = await axiosClient.post('calendar-plan/staff-plan/layer', {
        unitSelected,
        planID,
      });
      return res;
    },
    {
      initialData: [],
      enabled: !!unitSelected,
    },
  );
};

export const usePlanDetailData = ({ plan }) => {
  return useQuery(
    ['planDetail', plan.id],
    async () => {
      const res = await axiosClient.post('calendar-plan/staff-plan/detail', {
        planId: plan.id,
      });
      if (res && res.length) {
        const convertedData = res.map((item) => {
          const { taskData, dateList, roleList, groupList, timelot, layer_id, id, uuid, layer } =
            item;
          const taskList = dateList.map((dateData) => {
            return {
              date: dateData.date,
              fromTime: timelot.from_time,
              endTime: timelot.to_time,
              layerID: layer_id,
              layerName: layer?.name,
              planDetailID: id,
              planDetailUUID: uuid,
              numberPerson: dateData.numberPerson,
            };
          });
          return {
            planDetailID: id,
            taskUUID: taskData.uuid,
            taskName: taskData.task_name,
            taskID: taskData.id,
            taskKey: taskData.taskType.key,
            taskTypeValue: taskData.taskType.value,
            roleID: null,
            roleKey: null,
            groupID: groupList.length ? groupList[0].groupID : null, //Will be remove whwen implement multiple group logic
            groupName: groupList.length ? groupList[0].groupInformation?.name : null, //Will be remove whwen implement multiple group logic
            roleList: roleList.map((item) => item.roleID),
            roleInfoList: roleList.map((item) => item.roleInformation),
            groupList: groupList.map((item) => item.groupID),
            groupInfoList: groupList.map((item) => item.groupInformation),
            fromTime: timelot.from_time,
            endTime: timelot.to_time,
            layerID: layer_id,
            layerName: layer?.name,
            pause: taskData.pause,
            isPaidBreak: taskData.is_paid_break,
            taskList,
          };
        });
        return convertedData;
      } else {
        return [];
      }
    },
    {
      initialData: [],
      enabled: !!plan.id,
    },
  );
};

export const useTaskList = ({ unitSelected: unitCode }) => {
  return useQuery(
    ['taskList'],
    async () => {
      const res = await axiosClient.post('calendar-plan/v2/staff-task-all', { unitCode });
      return res.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    },
    {
      initialData: [],
      enabled: !!unitCode,
    },
  );
};

export const useEmployees = ({ unitSelected }) => {
  return useQuery(
    ['employees', unitSelected],
    async () => {
      const res = await axiosClient.post('calendar-plan/employee', { unitCode: unitSelected });
      const { data: roleTypeList } = await axiosClient.get('global/role-types');
      return res.map((item) => ({
        ...item,
        role_type_detail: roleTypeList.find((role) => role.id === item.role_type),
      }));
    },

    {
      initialData: [],
      enabled: !!unitSelected,
    },
  );
};

export const useEmployeesWithPosition = ({ unitSelected }) => {
  return useQuery(
    ['employees-with-position', unitSelected],
    async () => {
      const res = await axiosClient.post('calendar-plan/employee/list-position', {
        unitCode: unitSelected,
      });
      return res;
    },
    {
      initialData: [],
      enabled: !!unitSelected,
    },
  );
};

export const useEmployeeDetail = ({ empId }) => {
  return useQuery(
    ['employeeInfo', empId],
    async () => {
      const res = await axiosClient.post('calendar-plan/emp-info', {
        userId: empId,
      });
      return res;
    },
    {
      initialData: {},
      enabled: !!empId,
    },
  );
};

export const useEmployeePosition = ({ employeeID }) => {
  return useQuery(
    ['employeePosition', employeeID],
    async () => {
      const res = await axiosClient.post('calendar-plan/employee-position', {
        employeeID,
      });
      return res;
    },
    {
      initialData: {},
      enabled: !!employeeID,
    },
  );
};
export const useStaffTask = ({ unitSelected, page }) => {
  return useQuery(
    ['staffTask', unitSelected, page],
    async () => {
      const res = await axiosClient.post('calendar-plan/staff-task', {
        unitCode: unitSelected,
      });

      return res;
    },
    {
      enabled: !!unitSelected,
      initialData: {
        data: [],
        total: 0,
      },
    },
  );
};

export const useTaskType = () => {
  return useQuery(
    'taskTypeList',
    async () => {
      const res = await axiosClient.post('calendar-plan/staff-task-type');
      return res;
    },
    {
      initialData: { data: [] },
    },
  );
};

export const useGroups = ({ unitSelected }) => {
  return useQuery(
    ['groupList', unitSelected],
    async () => {
      const res = await axiosClient.post('calendar-plan/group', { unitSelected });
      return res;
    },
    {
      enabled: !!unitSelected,
      initialData: { data: [] },
    },
  );
};

export const useRoles = () => {
  return useQuery(
    ['roleList'],
    async () => {
      const res = await axiosClient.post('calendar-plan/role');
      return res.data.sort((a, b) => a.role.localeCompare(b.role));
    },
    { initialData: [] },
  );
};
export const useRoleByUnit = ({ unitSelected }) => {
  return useQuery(
    ['roleList', unitSelected],
    async () => {
      const res = await axiosClient.post('calendar-plan/employee/get-roles', { unitSelected });
      return res;
    },
    { initialData: [], enabled: !!unitSelected },
  );
};

export const useEmployeeComment = (employeeUUID) => {
  return useQuery(
    [`employee-comment`, employeeUUID],
    async () => {
      const res = await axiosClient.post('calendar-plan/survey/comment', { employeeUUID });
      return res;
    },
    { initialData: [], enabled: !!employeeUUID },
  );
};

export const useEmployeeSchedule = (employeeUUID, planID, versionID) => {
  return useQuery(
    ['employee-schedule', employeeUUID, planID, versionID],
    async () => {
      const res = await axiosClient.post('calendar-plan/employee-schedule', {
        employeeUUID,
        planID,
        versionID,
      });
      return res;
    },

    { initialData: [], enabled: !!employeeUUID && !!planID && !!versionID },
  );
};

export const useThreadData = (roomID) => {
  return useQuery(
    ['thread-data', roomID],
    async () => {
      const res = await axiosClient.post('calendar-plan/thread', { roomID });
      return res;
    },

    { initialData: [], enabled: !!roomID },
  );
};

export const useVersionData = ({ planID }) => {
  return useQuery(
    ['versionList', planID],
    async () => {
      const result = await axiosClient.post('/calendar-plan/staff-plan/versions', {
        planID,
      });
      return result;
    },
    {
      initialData: [],
      enabled: !!planID,
    },
  );
};

// admin page apis
export const useDepartmentList = () => {
  return useQuery(
    ['departmentList'],
    async () => {
      const response = await axiosClient.get('/global/departments');
      return response.data;
    },
    {
      initialData: [],
    },
  );
};

export const useUnits = () => {
  return useQuery(
    ['unitList'],
    async () => {
      const res = await axiosClient.get('/global/units');
      return res.data;
    },
    { initialData: [] },
  );
};

export const useUserRoles = () => {
  return useQuery(
    ['userRoles'],
    async () => {
      const response = await axiosClient.post('/global/user-roles');
      return response.data.data;
    },
    {
      initialData: [],
    },
  );
};

export const useUserData = ({ page = 1, size = 100 }) => {
  return useQuery(
    ['users', page, size],
    async () => {
      const response = await axiosClient.post('/global/users', {
        page,
        size,
      });
      return response.data;
    },
    {
      initialData: { data: [] },
    },
  );
};

export const useUserPermissions = () => {
  return useQuery(
    ['users-permission'],
    async () => {
      const response = await axiosClient.get('/auth/permission', {});
      return response;
    },
    {
      initialData: [],
    },
  );
};

export const useRotationCost = ({ unitCode }) => {
  return useQuery(
    ['rotation-cost', unitCode],
    async () => {
      const response = await axiosClient.get('calendar-plan/rotation-cost', {
        params: { unitCode },
      });
      return response.data;
    },

    {
      initialData: [],
      enabled: !!unitCode,
    },
  );
};

export const useRotationCostCalculate = ({ unitCode, planSelected, currentTab, currentNavTab }) => {
  return useQuery(
    ['rotation-cost-calcualte', unitCode, planSelected, currentTab, currentNavTab],
    async () => {
      const response = await axiosClient.post('calendar-plan/rotation-cost/calculate', {
        unitCode,
        planID: planSelected.id,
        versionID: planSelected.versionID,
      });
      return response.data;
    },

    {
      initialData: [],
      enabled: !!unitCode && !!planSelected,
    },
  );
};

export const useListQuestions = ({ unitCode }) => {
  return useQuery(
    ['surveyQuestions', unitCode],
    async () => {
      const response = await axiosClient.post('calendar-plan/survey-question', {
        unitCode,
      });
      return response;
    },
    {
      initialData: [],
      enabled: !!unitCode,
    },
  );
};
export const useListManagerOption = () => {
  return useQuery(
    ['manager-option'],
    async () => {
      const response = await axiosClient.post('calendar-plan/survey/manager-option');
      return response;
    },
    {
      initialData: [],
    },
  );
};

export const useListExpandedQuestionActive = ({ empUUID }) => {
  return useQuery(
    ['expandedActiveQuestions', empUUID],
    async () => {
      const response = await axiosClient.post('calendar-plan/survey/expaned-question-active', {
        empUUID,
      });
      return response;
    },
    {
      initialData: [],
      enabled: !!empUUID,
    },
  );
};

export const useListHardRuleQuestions = ({ empUUID }) => {
  return useQuery(
    ['listHardRuleQuestions', empUUID],
    async () => {
      const response = await axiosClient.post('calendar-plan/survey/property/hard-rule', {
        employeeUUID: empUUID,
      });
      return response;
    },
    {
      initialData: [],
      enabled: !!empUUID,
    },
  );
};

export const useListBlockedQuestion = ({ empUUID }) => {
  return useQuery(
    ['listBlockedQuestions', empUUID],
    async () => {
      const response = await axiosClient.post('calendar-plan/survey/block-question', {
        empId: empUUID,
      });
      return response;
    },
    {
      initialData: [],
      enabled: !!empUUID,
    },
  );
};

export const useEmpSurveyRecord = ({ empUUID, versionNumber }) => {
  return useQuery(
    ['empSurveyRecord', {empUUID, versionNumber}],
    async () => {
      const response = await axiosClient.post('calendar-plan/survey-record', { empID: empUUID, versionNumber });
      return response;
    },
    {
      initialData: [],
      enabled: !!empUUID,
    },
  );
};

export const useListTopic = () => {
  return useQuery(
    ['listTopic'],
    async () => {
      const response = await axiosClient.post('calendar-plan/survey-topic');
      return response;
    },
    {
      initialData: [],
    },
  );
};

export const useExtendedProfileProperties = () => {
  return useQuery(
    ['extendedProfileProperty'],
    async () => {
      const response = await axiosClient.get('calendar-plan/employee/extend-property');
      return response;
    },
    {
      initialData: [],
    },
  );
};

export const useEmployeeExtendedProfile = ({ empUUID }) => {
  return useQuery(['employeeExtendedProfile', empUUID], async () => {
    const response = await axiosClient.get(`calendar-plan/employee/extend-property/${empUUID}`);
    return response;
  });
};

export const useRoleTypes = () => {
  return useQuery(
    ['roleTypes'],
    async () => {
      const response = await axiosClient.get('global/role-types');
      return response.data;
    },
    {
      initialData: [],
    },
  );
};
export const usePaidBreakType = () => {
  return useQuery(
    ['paidBreak'],
    async () => {
      const response = await axiosClient.get('calendar-plan/staff-plan/task/pai-break-type');
      return response;
    },
    {
      initialData: [],
    },
  );
};

export const useBlackListShift = ({ unitCode }) => {
  return useQuery(
    ['blackListShift', unitCode],
    async () => {
      const response = await axiosClient.post('calendar-plan/black-list', { unitCode });
      return response;
    },
    {
      initialData: [],
      enabled: !!unitCode,
    },
  );
};

export const useSurveyRecordHistory = (params, onSuccess) => useQuery(
  ['surveyRecordHistory', params],
  async () => await axiosClient.get('calendar-plan/survey-record/history', { params }),
  {
    initialData: [],
    enabled: !!params.empID,
    onSuccess,
  }
)

export const useSurveyRecordHistoryDetail = (data) => useQuery(
  ['surveyRecordHistoryDetail', data],
  async () => await axiosClient.post('calendar-plan/survey-record', data),
  {
    initialData: [],
    enabled: !!data.empID,
  },
)

export const useSurveyRecordHistoryDiff = (params) => useQuery(
  ['surveyRecordHistoryDiff', params],
  async () => await axiosClient.get('calendar-plan/survey-record/differences', { params }),
  {
    initialData: [],
    enabled: !!params.empID && !!params.versionNumber,
  },
)