import { useEditingContext } from 'providers';
import { useEffect, useContext, useCallback } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

import { useState } from 'react';

const noop = () => {};

const initialConfirmState = {
  isOpen: false,
  hasConfirmed: false,
  proceed: noop,
  cancel: noop,
};

export const useConfirm = () => {
  const [confirm, setConfirm] = useState(initialConfirmState);

  const onConfirm = () => {
    const promise = new Promise((resolve, reject) => {
      setConfirm((prevState) => ({
        ...prevState,
        isOpen: true,
        proceed: resolve,
        cancel: reject,
      }));
    });

    return promise.then(
      () => {
        setConfirm({ ...confirm, isOpen: false, hasConfirmed: true });
        return true;
      },
      () => {
        setConfirm({ ...confirm, isOpen: false });
        return false;
      },
    );
  };

  const resetConfirmation = () => setConfirm(initialConfirmState);

  return {
    ...confirm,
    onConfirm,
    resetConfirmation,
  };
};

export function useBlocker(blocker, when = true) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;
    if (navigator.block) {
      const unblock = navigator.block((tx) => {
        const autoUnblockingTx = {
          ...tx,
          retry() {
            // Automatically unblock the transition so it can play all the way
            // through before retrying it. TODO: Figure out how to re-enable
            // this block if the transition is cancelled for some reason.
            unblock();
            tx.retry();
          },
        };

        blocker(autoUnblockingTx);
      });

      return unblock;
    }
  }, [navigator, blocker]);
}

export const PromptUnsavedChanges = () => {
  const { isEditing, setEditing } = useEditingContext();
  const { isOpen, proceed, cancel, onConfirm, hasConfirmed, resetConfirmation } = useConfirm();

  let blocker = useCallback(
    async (tx) => {
      if (await onConfirm()) {
        resetConfirmation();
        tx.retry();
      }
    },
    [resetConfirmation, onConfirm],
  );

  useBlocker(blocker, isEditing && !hasConfirmed);

  const confirmNavigation =
    isOpen && window.confirm('You have unsaved changes. Are you sure you want to leave?');

  if (confirmNavigation) {
    setEditing(false);
    proceed();
  } else {
    cancel();
  }

  return null;
};
