import React from 'react';

import { DeleteIcon, EditIcon } from 'assets/img';

import './styles.scss';

const GeneratePlanAction = ({ plan, handleDelete, handleEdit }) => {
  return (
    <div className="plan-action">
      <img src={EditIcon} onClick={() => handleEdit(plan)} />
      <img
        src={DeleteIcon}
        onClick={() => {
          handleDelete(plan);
        }}
      />
    </div>
  );
};

export default GeneratePlanAction;
