import React from 'react';
import { t } from 'i18next';

import './styles.scss';
import { useSelector } from 'react-redux';
import { selectCalendarPlan } from 'redux/calendarPlan/calendarPlanSlice';

const NavTabs = ({ tabList, currentTab, setCurrentTab, defaultTask }) => {
  const { planSelected } = useSelector(selectCalendarPlan);
  const tabListActive = tabList.filter((item) => {
    if (!planSelected.versionID && item.key === 'rotation') {
      return false;
    }
    return true;
  });
  return (
    <div className="navigate-tabs">
      {tabListActive.map((navTab, index) => {
        if (defaultTask && Object.keys(defaultTask).length) {
          if (navTab.key === 'more-guard-code') {
            return;
          }
        }
        return (
          <p
            key={index}
            className={`${currentTab === navTab.key && 'active'} ${
              navTab.isDisabled ? 'disabled' : ''
            }`}
            onClick={() => !navTab.isDisabled && setCurrentTab(navTab.key)}
          >
            {t(navTab.value)}
          </p>
        );
      })}
    </div>
  );
};

export default NavTabs;
