import React, { memo, useEffect, useState } from 'react';
import { t } from 'i18next';

import QuestionContent from './QuestionContent';
import { CheckBlackIcon, ChevronRightIcon } from 'assets/img';
import { TYPE_SURVEY_QUESTION } from 'constant';

import ExpanedQuestion from 'components/CalendarPlan/CalendarPlanSurveyForm/ItemInSurvey/ExpanedQuestion';
import { useSelector } from 'react-redux';
import { selectGlobal } from 'redux/global/globalSlice';
import { toTitleCase } from 'helper/calculateTableData';
import { selectAuth } from 'redux/auth/authSlice';

const QuestionTopic = ({
  title,
  listTopic,
  questionList,
  isCommentBox,
  isNo,
  listBlockedQuestion,
  setListBlockedQuestion,
  listHardRuleQuestion,
  setListHardRuleQuestion,
  surveyRecord,
  setSurveyRecord,
  empComment,
  setEmpComment,
  renderNewRangeDate,
  renderRangeDate,
  listExpandedQuestionActive,
  setListExpandedQuestionActive,
  empInfo,
  warningMessage,
  setWarningMessage,
  managerOption,
  isChanged,
  holidayList,
  isReadOnly,
}) => {
  const { language } = useSelector(selectGlobal);
  const [isOpen, setIsOpen] = useState(false);
  const [isAnswered, setIsAnswered] = useState(false);
  const { isEditabled } = useSelector(selectAuth);

  useEffect(() => {
    if (isCommentBox && empInfo) {
      setIsAnswered(!!empComment);
      return;
    }
    const currentQuestionList = questionList.map((question) => ({
      id: question.parent_question_id,
      type: question.parent_question_type || question.type,
    }));

    const surveyRecordKeys = Object.keys(surveyRecord);
    setIsAnswered(
      currentQuestionList.some((question) => {
        if (question.type === 'checkbox-text') {
          return surveyRecord[question.id] && surveyRecord[question.id].data.length > 0;
        }
        if (question.type === 'range-date-only') {
          return (
            surveyRecord[question.id] &&
            surveyRecord[question.id][0] &&
            surveyRecord[question.id][0].fromDate !== '' &&
            surveyRecord[question.id][0].toDate !== ''
          );
        }
        if (question.type === 'date-only') {
          return surveyRecord[question.id]?.dateArr?.every((date) => date !== '');
        }
        if (question.type === 'date') {
          return surveyRecord[question.id]?.some((date) => !!(date.fromDate && date.toDate));
        }
        return (
          surveyRecordKeys.includes(question.id.toString()) &&
          surveyRecord[question.id.toString()] !== null &&
          surveyRecord[question.id.toString()] !== ''
        );
      }),
    );
  }, [surveyRecord, questionList, empComment]);

  const handleOnChange = (e) => {
    if (e.target.type === 'checkbox') {
      if (surveyRecord[[e.target.name]]) {
        if (surveyRecord[[e.target.name]]?.includes(e.target.value)) {
          setSurveyRecord({
            ...surveyRecord,
            [e.target.name]: surveyRecord[[e.target.name]].filter(
              (item) => item !== e.target.value,
            ),
          });
        } else {
          setSurveyRecord({
            ...surveyRecord,
            [e.target.name]: [...surveyRecord[[e.target.name]], e.target.value],
          });
        }
      } else {
        setSurveyRecord({
          ...surveyRecord,
          [e.target.name]: [e.target.value],
        });
      }
    } else {
      setWarningMessage(
        parseInt(e.target.value) < parseInt(empInfo.job_percentage)
          ? `${t('job-percentage-warning')}: ${empInfo.job_percentage}`
          : '',
      );
      setSurveyRecord({
        ...surveyRecord,
        [e.target.name]: e.target.value > 100 ? 100 : e.target.value,
      });
    }
  };
  const convertTitle = (title) => {
    const lowerCasePart = title.toLowerCase().substring(1);
    const firstChar = title.charAt(0);
    return firstChar + lowerCasePart;
  };

  return (
    <div className="menu-overview__survey-questions-content-wrapper">
      <div className="menu-overview__survey-questions-content" onClick={() => setIsOpen(!isOpen)}>
        <img src={ChevronRightIcon} className={isOpen ? 'rotate' : ''} />
        <div className="topic-content">
          <span>{convertTitle(title) || ''}</span>
          {isChanged && isReadOnly && (
            <div className="topic-updated">
              <span>{t('has-updated')}</span>
            </div>
          )}
        </div>
        <p className={`topic-status ${isAnswered && 'answered'}`}>
          {isAnswered && <img src={CheckBlackIcon} />}
          {isAnswered ? t('answered') : t('not-answered')}
        </p>
      </div>

      <div className={`menu-overview__survey-questions-content-detail-wrapper ${isOpen && 'open'}`}>
        {isCommentBox ? (
          <div className="user-comment">
            <p>{t('comment-desc')}</p>
            <textarea
              onChange={(e) => setEmpComment(e.target.value)}
              value={empComment}
              className="comment-box"
              cols="20"
              rows="5"
              maxLength={400}
              disabled={!isEditabled || isReadOnly}
            ></textarea>
          </div>
        ) : (
          questionList.map((question, index) =>
            [TYPE_SURVEY_QUESTION.WEEKDAY_TYPE_SHIFT].includes(question.parent_question_type) ? (
              <ExpanedQuestion
                key={question.parent_question_id}
                parentQuestionData={question}
                index={index + 1}
                language={language}
                listExpandedQuestionActive={listExpandedQuestionActive}
                setListExpandedQuestionActive={setListExpandedQuestionActive}
                surveyRecord={surveyRecord}
                setSurveyRecord={setSurveyRecord}
                handleOnChange={handleOnChange}
                listHardRuleQuestion={listHardRuleQuestion}
                setListHardRuleQuestion={setListHardRuleQuestion}
                emp={empInfo}
                listTopic={listTopic}
                isReadOnly={isReadOnly}
              />
            ) : (
              <QuestionContent
                key={index}
                listTopic={listTopic}
                parentQuestionData={question}
                isNo={isNo}
                listBlockedQuestion={listBlockedQuestion}
                setListBlockedQuestion={setListBlockedQuestion}
                listHardRuleQuestion={listHardRuleQuestion}
                setListHardRuleQuestion={setListHardRuleQuestion}
                surveyRecord={surveyRecord}
                setSurveyRecord={setSurveyRecord}
                handleOnChange={handleOnChange}
                renderNewRangeDate={renderNewRangeDate}
                renderRangeDate={renderRangeDate}
                warningMessage={warningMessage}
                isDisabled={!isEditabled}
                managerOption={managerOption}
                isReadOnly={isReadOnly}
                holidayList={holidayList}
              />
            ),
          )
        )}
      </div>
    </div>
  );
};

export default memo(QuestionTopic);
