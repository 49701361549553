import React from 'react';
import QuestionItem from '../DefaultRotation/QuestionItem';
import { Toast } from 'helper/alert';
import axiosClient from 'axios/axiosClient';
import { useSelector } from 'react-redux';
import { selectAuth } from 'redux/auth/authSlice';
import { selectGlobal } from 'redux/global/globalSlice';

import { t } from 'i18next';

const StartNightShift = ({
  unitSelected,
  questionKey,
  generSettingList,
  refetchGeneralSetting,
}) => {
  const handleOnChange = async (e, question) => {
    const { value: optionID } = e.target;
    const updatedData = { unitCode: unitSelected, optionID, questionID: question.id };
    await axiosClient
      .post('calendar-plan/general-settings/update', updatedData)
      .then((_) => {
        refetchGeneralSetting();
        Toast({ icon: 'success', title: t('save-changes') });
      })
      .catch((err) => {
        if (err) Toast({ icon: 'error', title: t('save-fail') });
      });
  };
  return (
    <div className="general-setting">
      {generSettingList
        .filter((question) => question.question_key === questionKey)
        .map((question) => {
          return (
            <QuestionItem
              key={question.id}
              question={question}
              onChange={(e) => {
                handleOnChange(e, question);
              }}
            />
          );
        })}
    </div>
  );
};

export default StartNightShift;
