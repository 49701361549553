import { convertToAbbreviation, formatNumberWithComas } from 'helper/convertData';
import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';

const PercentValueTag = ({ value }) => {
  const isIncrease = value > 0;
  return (
    <div className={`percent-value-tag ${isIncrease ? 'increase' : ''}`}>
      {isIncrease ? '+' : ''}
      {value}%
    </div>
  );
};

const BudgetCard = ({ title, value, rotationCostCalData, currentNavTab, budgetData }) => {
  const [budgetItemList, setBudgetItemList] = useState([
    {
      title: 'evening-supplement',
      value: 1100,
      tagValue: 3.4,
      pointColor: '#978FED',
      backgroundColor: '#B6B0FB',
      valueKey: 'eveSupplement',
    },
    {
      title: 'sunday-supplement',
      value: 2300,
      tagValue: 11.4,
      pointColor: '#A4DDDC',
      backgroundColor: '#BBEEED',
      valueKey: 'weekendSupplement',
    },
    {
      title: 'night-supplement',
      value: 1500,
      tagValue: -1.4,
      pointColor: '#F6CF7D',
      backgroundColor: '#FBDE9D',
      valueKey: 'nightSupplement',
    },
    {
      title: 'holiday-supplement',
      value: 1600,
      tagValue: 7.0,
      pointColor: '#697BE9',
      backgroundColor: '#95A1FC',
      valueKey: 'holidaySupplement',
    },
  ]);

  useEffect(() => {
    if (rotationCostCalData && budgetData) {
      setBudgetItemList((prev) => {
        const updateBudgetItem = [...prev];
        //calculate from server

        for (const budgetKey of Object.keys(budgetData)) {
          const index = updateBudgetItem.findIndex((item) => item.valueKey === budgetKey);
          const budgetValue = budgetData[budgetKey];
          updateBudgetItem[index] = { ...updateBudgetItem[index], value: budgetValue.toFixed(2) };
        }
        return updateBudgetItem;
      });
    }
  }, [rotationCostCalData, currentNavTab, budgetData]);
  const renderBudgetItem = useCallback(
    (data) => {
      return data.map((item) => {
        return (
          <div key={`${item.title}-${item.pointColor}`} className="budget-card__item">
            <div className="budget-card__item-title">
              <div
                className="point"
                style={{
                  width: '6px',
                  height: '6px',
                  backgroundColor: item.pointColor,
                  borderRadius: '100%',
                }}
              ></div>
              <span>{t(item.title)}</span>
            </div>
            <div className="budget-card__item-value">
              {/* <span>{convertToAbbreviation(item.value)}</span> */}
              {/* <span>{formatNumberWithComas(item.value)}</span> */}
              <span>{item.value}</span>
              {/* <PercentValueTag value={item.tagValue} /> */}
            </div>
          </div>
        );
      });
    },
    [budgetItemList, rotationCostCalData],
  );

  const renderBudgetBar = useCallback(
    (data) => {
      const totalValue = data.reduce((acc, curr) => +acc + +curr.value, 0);

      return data.map((item) => {
        const width = (item.value / totalValue) * 100;

        return (
          <div
            className="bar-item"
            key={`${item.pointColor}-bar-item`}
            style={{
              height: '100%',
              width: `${width}%`,
              backgroundColor: item.backgroundColor,
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            {Array.from({ length: width }).map((_, idx) => {
              return (
                <div
                  className="stroke"
                  style={{ height: '100%', width: '2px', backgroundColor: item.pointColor }}
                  key={idx}
                ></div>
              );
            })}
          </div>
        );
      });
    },
    [budgetItemList, rotationCostCalData],
  );

  return (
    <div className="budget-card__wrap">
      <div className="budget-card">
        <div className="budget-card__title">
          <h4>{t(title)}</h4>
        </div>
        <div className="budget-card__value">
          {/* <h2>{value} </h2> */}
          {/* <PercentValueTag value={3.4} /> */}
        </div>

        <div className="budget-card__bar">{renderBudgetBar(budgetItemList)}</div>
        <div className="budget-card__group">{renderBudgetItem(budgetItemList)}</div>
      </div>
    </div>
  );
};

export default BudgetCard;
