import { dateFormat } from 'constant';
import { t } from 'i18next';
import moment from 'moment';
import React, { useCallback, useEffect, useId, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
const getFirstMondayOfYear = (year) => {
  let firstDayOfYear = moment().year(year).startOf('year');
  // Find the first Monday after the first day of the year
  while (firstDayOfYear.day() !== 1) {
    firstDayOfYear = firstDayOfYear.add(1, 'days');
  }
  return firstDayOfYear;
};
const SelectYear = ({ year, setYear, isDisabled }) => {
  return (
    <div className="select-year__wrap">
      <label htmlFor="" className="select-label">
        {t('year')}
      </label>
      <select
        value={+year}
        name=""
        id=""
        className="select-year"
        onChange={(e) => setYear(+e.target.value)}
        disabled={isDisabled}
      >
        {Array.from({ length: 6 }).map((_, index) => {
          const keyId = useId();
          const yearValue = moment().year() + index;
          return (
            <option key={keyId} value={+yearValue}>
              {yearValue}
            </option>
          );
        })}
      </select>
    </div>
  );
};
const SelectWeekItem = ({
  questionId,
  surveyRecord,
  setSurveyRecord,
  isExpired,
  limitSurveyStart,
  limitSurveyEnd,
  isDisabled,
}) => {
  const [year, setYear] = useState(moment().year());
  const survey = { ...surveyRecord };

  if (!survey[questionId]) {
    survey[questionId] = { type: 'select-week', value: '' };
  }
  const handleSelect = (e) => {
    const { value } = e.target;
    survey[questionId].value = value;
    setSurveyRecord(survey);
  };

  useEffect(() => {
    if (survey[questionId]) {
      if (survey[questionId].value) {
        setYear(moment(survey[questionId].value).year());
      }
    }
  }, [surveyRecord]);
  const renderWeekOpt = () => {
    const firsMondayInYear = getFirstMondayOfYear(year);
    return Array.from({ length: 52 }).map((_, index) => {
      const monday = firsMondayInYear.clone().add(index, 'w');
      const sunday = monday.clone().add(6, 'days');
      const saturday = monday.clone().add(5, 'days');
      if (limitSurveyStart && limitSurveyEnd) {
        const momentLimitSurveyStart = moment(limitSurveyStart);
        const momentLimitSurveyEnd = moment(limitSurveyEnd);

        if (
          !(
            moment(monday).isSameOrAfter(momentLimitSurveyStart) &&
            moment(monday).isSameOrBefore(momentLimitSurveyEnd)
          )
        ) {
          return;
        }
      }
      return (
        <option key={uuidv4()} value={saturday.format('YYYY-MM-DD')}>
          {t('week')} {index + 1} - ({monday.format(dateFormat)} - {sunday.format(dateFormat)})
        </option>
      );
    });
  };
  return (
    <div className="calendar-plan-survey-form__date__wrap">
      <div className="select-week__wrap">
        <div className="calendar-plan-survey-form__date__group ">
          <SelectYear isDisabled={isExpired || isDisabled} year={year} setYear={setYear} />
          <div className="select-week__group">
            <label htmlFor="" className="select-label">
              {t('week')}
            </label>
            <select
              name=""
              id=""
              className="select-week"
              onChange={handleSelect}
              value={survey[questionId].value}
              style={{ padding: '10px' }}
              disabled={isExpired || isDisabled}
            >
              <option key={uuidv4()} value="">
                {t('unselected')}
              </option>
              {renderWeekOpt()}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectWeekItem;
