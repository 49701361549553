import { t } from 'i18next';

export const renderCellError = (error) => {
  const errorCellStyle = {
    color: 'red',
  };
  const defaultCellStyle = {
    color: 'black',
  };
  return error ? errorCellStyle : defaultCellStyle;
};

export const createEmptyRow = (id) => ({
  id,
  name: '',
  email: '',
  phone: '',
  jobPercentage: null,
  role: '',
  role_id: '',
  group: '',
  group_id: '',
  salary: null,
  emp_id_number: '',
  role_type: null,
  rowError: {
    name: false,
    email: false,
    phone: false,
    jobPercentage: false,
    role: false,
    roleType: false,
    group: false,
    salary: false,
    emp_id_number: false,
  },
});

export const validateRowData = (rowData) => {
  // Filter out empty rows
  const nonEmptyRows = rowData.filter(
    (row) =>
      row.name ||
      row.email ||
      row.phone ||
      row.jobPercentage ||
      row.role ||
      row.group ||
      row.role_type,
  );

  // if no name, jobPercentage, role prevent submit
  const missingRequireProps = nonEmptyRows.reduce((acc, row) => {
    if (!row.name) {
      acc.push(t('name'));
    }
    if (!row.jobPercentage) {
      acc.push(t('jobPercentage'));
    }
    if (!row.role) {
      acc.push(t('role'));
    }
    if (!row.role_type) {
      acc.push(t('role-type'));
    }
    return acc;
  }, []);

  if (nonEmptyRows.length === 0) {
    return {
      isValid: false,
      message: t('please-fill-one-row'),
      data: nonEmptyRows,
    };
  }

  if (missingRequireProps.length > 0) {
    return {
      isValid: false,
      message: `${t('missing-field')}: ${missingRequireProps.join(', ')}`,
      data: nonEmptyRows,
    };
  }

  // validation
  if (
    nonEmptyRows.some(
      (row) =>
        row.rowError.name ||
        row.rowError.role ||
        row.rowError.group ||
        row.rowError.email ||
        row.rowError.phone ||
        row.rowError.jobPercentage,
    )
  ) {
    return {
      isValid: false,
      message: 'error-in-table',
      data: nonEmptyRows,
    };
  }

  return {
    isValid: true,
    message: 'no-error-in-table',
    data: nonEmptyRows, // Return the non-empty rows
  };
};

export const formatNorwayPhoneNumber = (data) => {
  const nunmsLength = data.length;
  const matchedRegExp = /^\+/;
  const missingPlusRexExpt = /^47/;
  if (matchedRegExp.test(data)) {
    return data;
  } else if (missingPlusRexExpt.test(data)) {
    return `+${data}`;
  } else {
    return `+47${data}`;
  }
};

export const validateBulkData = (data, employees) => {
  const loopedEmpList = [];
  const duplicateList = [];
  const validList = [];
  for (const employee of data) {
    if (employee.emp_id_number) {
      const loopedIndex = loopedEmpList.findIndex(
        (item) => item.emp_id_number === employee.emp_id_number,
      );
      if (loopedIndex > -1) {
        const empLooped = loopedEmpList[loopedIndex];

        if (+empLooped.jobPercentage > +empLooped.jobPercentage) {
          duplicateList.push(employee);
        }
      } else {
        validList.push(employee);
      }
    } else {
      validList.push(employee);
    }

    loopedEmpList.push(employee);
  }

  return { validList, duplicateList };
};
