import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import { useEmployeeInfo } from 'Hooks/useData';
import ScheduleCalendar from 'components/CalendarPlan/ScheduleCalendar/ScheduleCalendar';

import { SendScheduleIcon, CloseIcon } from 'assets/img';

export const ScheduleModal = ({ setToggleSendSchedule, data, planID, versionID }) => {
  const { data: employeeInfo } = useEmployeeInfo({
    empSelected: data,
  });
  return ReactDOM.createPortal(
    <div className="schedule-modal">
      <div
        className="schedule-modal__background"
        onClick={(e) => {
          e.stopPropagation();
          setToggleSendSchedule(false);
        }}
      />
      <div className="schedule-modal__form">
        <div className="schedule-modal__form__header" style={{ justifyContent: 'flex-end' }}>
          <div
            className="schedule-modal__form__header-icon"
            onClick={(e) => {
              e.stopPropagation();
              setToggleSendSchedule(false);
            }}
          >
            <img src={CloseIcon} />
          </div>
        </div>

        <div className="schedule-modal__emp-table">
          <ScheduleCalendar
            employeeUUID={employeeInfo.uuid}
            planID={planID}
            versionID={versionID}
          />
        </div>
      </div>
    </div>,
    document.body,
  );
};

const ShowCalenderButton = ({ data, planID, versionID }) => {
  const [toggleSendSchedule, setToggleSendSchedule] = useState(false);
  return (
    <div className="employee-action__wrap" onClick={() => setToggleSendSchedule(true)}>
      <img src={SendScheduleIcon} />
      {toggleSendSchedule && (
        <ScheduleModal
          setToggleSendSchedule={setToggleSendSchedule}
          data={data}
          planID={planID}
          versionID={versionID}
        />
      )}
    </div>
  );
};

export default ShowCalenderButton;
