import { t } from 'i18next';
import { AgGridReact } from 'ag-grid-react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import React, { useMemo, useRef } from 'react';

import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import TaskAction from './TaskAction';
import TimeEditor from './TimeEditor';
import { selectGlobal } from 'redux/global/globalSlice';

import { useRoles, useStaffTask, useTaskType, useGroups } from 'Hooks/useData';
import NoData from '../../NoData';
import './TaskListContent.scss';
import { CheckGreenIcon, CloseRedIcon } from 'assets/img';
import { selectAuth } from 'redux/auth/authSlice';

const TimeRangeRenderer = (props) => {
  const fromTime = props.value.split(' - ')[0];
  const toTime = props.value.split(' - ')[1];
  return (
    <div>
      <TimeEditor value={fromTime} {...props} />
      <span> - </span>
      <TimeEditor value={toTime} {...props} />
    </div>
  );
};

const TaskListContentv2 = ({
  unitSelected,
  setAddNewGuardCode,
  setActiveCreateForm,
  setTaskEdit,
}) => {
  const gridRef = useRef();
  const { language } = useSelector(selectGlobal);
  const { isEditabled } = useSelector(selectAuth);

  const {
    data: { data: taskList },
    refetch: refetchTask,
    isFetched: isTaskFetched,
  } = useStaffTask({ unitSelected });
  const { data: roleList } = useRoles();
  const {
    data: { data: listTaskType },
    isFetched: isTaskTypeFetched,
  } = useTaskType();
  const {
    data: { data: listGroup },
    isFetched: isGroupFetched,
  } = useGroups({ unitSelected });

  const shouldShow = useMemo(() => {
    return taskList.length > 0 && roleList.length > 0 && listTaskType.length > 0;
  }, [taskList, listGroup, listTaskType, roleList]);

  const defaultColDef = useMemo(
    () => ({
      filter: false,
      sortable: true,
      editable: true,
      resizable: true,
      menuTabs: [],
      headerClass: 'task-header',
      cellClass: 'task-cell',
      flex: 1,
      suppressKeyboardEvent: (params) => {
        const KEY_ENTER = 13;
        const event = params.event;
        if (event.keyCode === KEY_ENTER) {
          return true; // suppress Enter key
        } else {
          return false; // don't suppress other keys
        }
      },
    }),
    [],
  );
  const columnDefs = useMemo(
    () => [
      {
        field: 'task_name',
        headerName: t('guard-code'),
        colId: 'task-guard-code',
        minWidth: 100,
        flex: 1,
      },
      {
        headerName: `${t('from')} - ${t('to')}`,
        colId: 'plan-from-to',
        minWidth: 100,
        flex: 1,

        valueGetter: (params) => {
          const fromTime = moment(params.data.from_time, 'HH:mm:ss').format('HH:mm');
          const toTime = moment(params.data.to_time, 'HH:mm:ss').format('HH:mm');
          return `${fromTime} - ${toTime}`;
        },
        cellEditor: TimeRangeRenderer,
      },
      {
        headerName: t('total'),
        colId: 'task-total',
        minWidth: 150,
        flex: 1,

        editable: false,
        valueGetter: ({ data }) => {
          const fromTime = moment(data.from_time, 'HH:mm:ss');
          const toTime = moment(data.to_time, 'HH:mm:ss');
          if (fromTime.isAfter(toTime)) {
            toTime.add(1, 'days');
          }
          const diffInMinutes = toTime.diff(fromTime, 'minutes');
          const diffInHours = Math.abs(diffInMinutes / 60);
          return Number.isInteger(diffInHours) ? diffInHours : diffInHours.toFixed(2);
        },
      },
      {
        field: 'is_paid_break',
        headerName: t('paid-break'),
        colId: 'is_paid_break',
        minWidth: 100,
        flex: 1,

        cellRenderer: (params) => {
          const { data } = params;
          if (data.is_paid_break) {
            return <img width={20} src={CheckGreenIcon} alt="" />;
          } else {
            return <img width={20} src={CloseRedIcon} alt="" />;
          }
        },
        valueGetter: ({ data }) => {
          return !!data.is_paid_break;
        },
        cellEditor: 'agCheckboxCellEditor',
        valueSetter: ({ data, newValue }) => {
          data.is_paid_break = newValue;

          return true;
        },
      },
      {
        field: 'type_id',
        headerName: t('type'),
        colId: 'task-type',
        minWidth: 150,
        flex: 1,
        valueGetter: ({ data }) => {
          const taskType = listTaskType.find((item) => item.id === data.type_id);

          return t(taskType?.key);
        },
        cellRenderer: ({ data }) => {
          const taskType = listTaskType.find((item) => item.id === data.type_id);
          const taskValue = taskType?.value;
          let taskColor = '#EAF0E7';
          switch (taskValue) {
            case 0:
              taskColor = '#EAF0E7';
              break;
            case 1:
              taskColor = '#2F4CE024';
              break;
            case 2:
              taskColor = '#D124441F';
              break;
            case 3:
              taskColor = '#F9DF8080';
              break;
            case -1:
              taskColor = '#EAF0E7';
              break;
            case -2:
              taskColor = '#2F4CE024';
              break;
            case -3:
              taskColor = '#D124441F';
              break;

            default:
              taskColor = '#f5c1fca1';
              break;
          }
          return (
            <div
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '100px',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '100%',
                  backgroundColor: taskColor,
                  borderRadius: '100px',
                  textAlign: 'center',
                  height: '24px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {t(taskType?.key)}
              </div>
            </div>
          );
        },
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: listTaskType.map((item) => t(item.key)),
        },
        valueSetter: ({ data, newValue }) => {
          const selectedType = listTaskType.find(
            (item) => t(item.key).trim().toLowerCase() === newValue.trim().toLowerCase(),
          );
          if (selectedType) {
            data.type_id = selectedType.id;
          } else {
            data.type_id = null;
          }
          return true;
        },
      },
      // {
      //   field: 'group_id',
      //   headerName: t('group'),
      //   colId: 'task-group',
      //   minWidth: 150,
      //   flex: 1,

      //   valueGetter: ({ data }) => listGroup.find((item) => item.id === data.group_id)?.name,
      //   cellEditor: 'agSelectCellEditor',
      //   cellEditorParams: {
      //     values: ['', ...listGroup].map((item) => item.name),
      //   },
      //   valueSetter: ({ data, newValue }) => {
      //     const selectedGroupItem = listGroup.find((item) => item.name === newValue);
      //     if (selectedGroupItem) {
      //       data.group_id = selectedGroupItem.id;
      //     } else {
      //       data.group_id = null;
      //     }
      //     return true;
      //   },
      // },
      {
        field: 'description',
        headerName: t('description'),
        colId: 'task-description',
        flex: 1,
        minWidth: 200,
        valueGetter: ({ data }) => data.description,
      },
      {
        headerName: t('action'),
        colId: 'plan-action',
        width: 100,
        flex: 1,
        sortable: false,
        editable: false,
        suppressNavigable: true,
        cellRenderer: ({ data, rowIndex }) => {
          if (!isEditabled) return;
          return (
            <TaskAction
              taskDefault={data}
              refetchTask={refetchTask}
              gridRef={gridRef}
              rowIndex={rowIndex}
              setAddNewGuardCode={setAddNewGuardCode}
              setTaskEdit={setTaskEdit}
            />
          );
        },
      },
    ],
    [listGroup, listTaskType, language, taskList, roleList, t],
  );

  const ROW_HEIGHT = 45;
  const HEADER_HEIGHT = 50;
  const DEFAULT_HEIGHT = 300;
  const ROW_PER_PAGE = 15;

  let tableHeight = taskList.length ? ROW_PER_PAGE * ROW_HEIGHT + HEADER_HEIGHT : DEFAULT_HEIGHT;

  // const onRowValueChanged = useCallback((event) => {
  //   var data = event.data;
  //   if (data.type_id) {
  //     axiosClient
  //       .post('calendar-plan/staff-task/update', {
  //         taskWillUpdate: { ...data },
  //         role: {
  //           roleKey: data.roleKey,
  //           roleName: data.roleName,
  //           role_id: data.role_id,
  //         },
  //       })
  //       .then((result) => {
  //         Toast({ icon: 'success', title: `${t('update')} ${t('success')}` });
  //       })
  //       .catch((err) => {
  //         Toast({ icon: 'error', title: `${t('update')} ${t('unsuccess')}` });
  //       });
  //   } else {
  //     Toast({ icon: 'error', title: `${t('update')} ${t('unsuccess')}` });
  //   }
  // }, []);

  const getRowId = useMemo(() => {
    return (params) => {
      return params.data.id;
    };
  }, []);

  const rowData = useMemo(() => {
    return [...taskList]
      .filter((task) => {
        const taskType = listTaskType.find((item) => item.id === task.type_id);
        return taskType?.value >= 0 && taskType?.value !== 4; // Off Shift value;
      })
      .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      .map((item, index) => ({ ...item, id: index + 1 }));
  }, [taskList]);
  if (!isTaskFetched || !isTaskTypeFetched || !isGroupFetched) {
    return;
  }

  return (
    <div
      className="ag-theme-alpine tasklist-container"
      style={{
        height: tableHeight,
      }}
    >
      {shouldShow ? (
        <AgGridReact
          ref={gridRef}
          getRowId={getRowId}
          rowData={rowData}
          rowHeight={ROW_HEIGHT}
          headerHeight={HEADER_HEIGHT}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          animateRows={true}
          suppressClickEdit={true}
          // pagination={true}
          // paginationPageSize={ROW_PER_PAGE}
          singleClickEdit
          editType="fullRow"
          // onRowValueChanged={onRowValueChanged}
        />
      ) : (
        <NoData setAddNewGuardCode={setAddNewGuardCode} setActiveCreateForm={setActiveCreateForm} />
      )}
    </div>
  );
};

export default TaskListContentv2;
