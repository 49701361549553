import React, { Fragment, useCallback, useEffect, useState } from 'react';
import './ScheduleCalendar.scss';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { useEmployeeGuestInfo, useEmployeeSchedule, userVerionDateRange } from 'Hooks/useData';
import i18next, { t } from 'i18next';
import { CloseOutlined, CommentOutlined, GlobalOutlined } from '@ant-design/icons';
import allLocales from '@fullcalendar/core/locales-all';
import { useDispatch, useSelector } from 'react-redux';
import { selectGlobal } from 'redux/global/globalSlice';
import { changeLangAction } from 'redux/global/globalAction';
import ThreadContent from './Thread/ThreadContent';
import { useCheckAuthentication } from 'Hooks/useCheckAuthentication';
import axiosClient from 'axios/axiosClient';
import { Toast } from 'helper/alert';
import { offShiftReg } from 'constant';
import { selectAuth } from 'redux/auth/authSlice';

const renderChooseLaguage = (language, dispatch) => {
  const languge = [
    { name: 'EN', value: 'EN' },
    { name: 'NO', value: 'NO' },
  ];
  return languge.map((item, index) => {
    return (
      <div
        key={item.value}
        className={`lang-option__item ${language === item.value ? 'active' : ''}`}
        onClick={() => {
          i18next.changeLanguage(item.value);
          dispatch(changeLangAction(item.value));
        }}
      >
        <span>{item.name}</span>
        {index !== language.length - 1 ? <span> | </span> : ''}
      </div>
    );
  });
};

const ScheduleCalendar = ({ employeeUUID, planID, versionID }) => {
  const { data: employeeInfo } = useEmployeeGuestInfo(employeeUUID);
  const { data: employeeSchedule } = useEmployeeSchedule(employeeUUID, planID, versionID);
  const { isEditabled } = useSelector(selectAuth);

  const [eventData, setEventData] = useState([]);
  const [activeThread, setActiveThread] = useState(false);
  const [validRange, setValidRange] = useState({ start: new Date(), end: new Date() });

  const { language } = useSelector(selectGlobal);
  const dispatch = useDispatch();
  const isAuthenticated = useCheckAuthentication();

  useEffect(() => {
    if (employeeSchedule && employeeSchedule.length) {
      const eventList = employeeSchedule.map((item) => {
        const isOffShift = offShiftReg.test(item.taskName);
        return {
          title: item.taskName,
          color: !isOffShift ? '#FFCDC0' : '#fff',
          borderColor: !isOffShift ? '#FFCDC0' : '#EF8C6F',
          start: item.date,
        };
      });
      setEventData(eventList);

      const startDay = employeeSchedule[0].date;
      const endDay = employeeSchedule[employeeSchedule.length - 1].date;
      setValidRange({ start: startDay, end: endDay });
    }
  }, [employeeSchedule]);

  const RenderCalendar = useCallback(() => {
    return (
      <div className="schedule-calendar">
        <FullCalendar
          eventTextColor="#000"
          locales={allLocales}
          locale={language}
          buttonText={{ today: t('today') }}
          initialEvents={eventData}
          height={'90%'}
          plugins={[dayGridPlugin]}
          editable={false}
          firstDay={1}
          initialView="dayGridMonth"
          initialDate={validRange.start}
        />
      </div>
    );
  }, [eventData, validRange, language]);
  const handleChangeValidRange = (e) => {
    const { value, name } = e.target;

    setValidRange((prev) => ({ ...prev, [name]: value }));
  };
  const sendCalendar = (key) => {
    let payload = {};
    switch (key) {
      case 'email':
        payload = { email: [employeeUUID], planID, versionID };
        break;

      case 'phone':
        payload = { phone: [employeeUUID], planID, versionID };
        break;

      default:
        break;
    }
    axiosClient
      .post('send-schedule', payload)
      .then((_) => {
        Toast({ icon: 'success', title: `${t('send-calendar')} ${t('success')}` });
      })
      .catch((err) => {
        Toast({ icon: 'success', title: `${t('send-calendar')} ${t('unsuccess')}` });
      });
  };
  return (
    <Fragment>
      {employeeSchedule.length ? (
        <div className="schedule-calendar__wrap">
          <div className="schedule-calendar__title">
            {!isAuthenticated ? (
              <>
                <h2>{t('generated-plan')}</h2>
                <div className="lang__wrap">
                  <GlobalOutlined />
                  <div className="lang-option__list">{renderChooseLaguage(language, dispatch)}</div>
                </div>
              </>
            ) : null}
          </div>
          <div className="schedule-calendar__header">
            {!isAuthenticated ? (
              <div className="emp">
                <div className="emp-name">{employeeInfo?.name}</div>
                <div className="emp-role">{employeeInfo?.role}</div>
              </div>
            ) : null}
            {isAuthenticated ? (
              <div className="send-group">
                <button
                  className="custom-btn"
                  disabled={!isEditabled}
                  onClick={() => {
                    sendCalendar('email');
                  }}
                >
                  {t('send-email')}
                </button>
                <button
                  className="custom-btn"
                  disabled={!isEditabled}
                  onClick={() => {
                    sendCalendar('phone');
                  }}
                >
                  {t('send-phone')}
                </button>
              </div>
            ) : null}
          </div>
          <div className="schedule-calendar__content">
            <div className="schedule-calendar__main">
              {/* <div className="schedule-calendar__main-date-range">
                <div className="schedule-calendar__main-date-range__item">
                  <label htmlFor="">{t('from')}</label>
                  <input
                    value={validRange['start']}
                    type="date"
                    name="start"
                    id=""
                    onChange={handleChangeValidRange}
                  />
                </div>
                <div className="schedule-calendar__main-date-range__item">
                  <label htmlFor="">{t('to')}</label>
                  <input
                    type="date"
                    value={validRange['end']}
                    name="end"
                    id=""
                    onChange={handleChangeValidRange}
                  />
                </div>
                <CloseOutlined
                  onClick={() => {
                    setValidRange({ start: '', end: '' });
                  }}
                />
              </div> */}
              <RenderCalendar />
            </div>
            {/* <div
              className="vertical-line"
              onClick={() => {
                setActiveThread(true);
              }}
            >
              <div className="collaspe-icon">
                <CommentOutlined /> {t('comment')}
              </div>
            </div>
            <div className={`schedule-calendar__comment ${activeThread ? 'active' : ''}`}>
              <ThreadContent
                employeeInfo={employeeInfo}
                setActiveThread={setActiveThread}
                versionID={versionID}
              />
            </div> */}
          </div>
        </div>
      ) : (
        <div>
          <h1>Plesae double check your URL</h1>
        </div>
      )}
    </Fragment>
  );
};

export default ScheduleCalendar;
