/** libs */
import React, { useState } from 'react';

/** components */
import Text from '_components/Text';
import Checkbox from '_components/DataEntry/Checkbox';

/** hooks */
import { useLanguge } from 'Hooks/useLangugue';

/** styles */
import './styles.scss';

const ColumnToolPanel = ({ colsList, columnApi }) => {
  const { t } = useLanguge();
  const [showList, setShowList] = useState(colsList);

  const handleChange = (e, data) => {
    const { checked } = e.target;

    const cloneList = [...showList];

    const colIndex = data.index;
    cloneList[colIndex] = { ...cloneList[colIndex], hide: !checked };
    setShowList(cloneList);
    columnApi.setColumnVisible(data.colId, checked);
  };

  return (
    <div className="column-tool-panel">
      <Text fontSize={14} fontWeight={500} color="#A3A3A3">
        {t('display-col')}
      </Text>
      <div className="column-tool-panel__content">
        {showList.map((item, index) => (
          <div className="column-tool-panel__checkbox-item" key={item.colId}>
            <Checkbox checked={!item.hide} onChange={(e) => handleChange(e, { ...item, index })}>
              {item.headerName}
            </Checkbox>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ColumnToolPanel;
