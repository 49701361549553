import { InfoCircleBlueIcon, PlusIcon } from 'assets/img';
import { weekdayKey } from 'constant';
import { useBlackListShift, useTaskList } from 'Hooks/useData';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import './UnwantedShiiftCollectionContainer.scss';
import UnwantedShiftRow from './UnwantedShiftRow';
import { selectAuth } from 'redux/auth/authSlice';
import { useSelector } from 'react-redux';

const UnwantedShiftCollectionContainer = ({ unitSelected }) => {
  const { isEditabled } = useSelector(selectAuth);

  const { data: taskList } = useTaskList({
    unitSelected,
  });
  const { data: blackListData, refetch: reftechBlackList } = useBlackListShift({
    unitCode: unitSelected,
  });
  const [blackList, setBlackList] = useState([]);
  const renderUnwantedShiftRow = () => {
    return blackList.map((list) => {
      return (
        <UnwantedShiftRow
          key={`${list.id}-renderUnwantedShiftRow`}
          unitSelected={unitSelected}
          taskList={taskList}
          listData={list}
          setBlackList={setBlackList}
          reftechBlackList={reftechBlackList}
        />
      );
    });
  };
  useEffect(() => {
    if (blackListData && blackListData.length) {
      setBlackList(blackListData);
    }
  }, [blackListData]);
  return (
    <div className="unwanted-shift__wrap">
      <div className="info">
        <div className="info-img">
          <img src={InfoCircleBlueIcon} />
        </div>
        <p className="info">{t('unwanted-shift-desc')}</p>
      </div>
      <table className="unwanted-shift__table">
        <thead>
          {weekdayKey.map((key, index) => {
            return <th key={`${key}-unwanted-header`}>{t(key)}</th>;
          })}
          <th>{t('comment')}</th>
          <th></th>
        </thead>
        <tbody>{renderUnwantedShiftRow()}</tbody>
      </table>
      {isEditabled ? (
        <div
          className="unwanted-shift__create"
          onClick={() => setBlackList((prev) => [...prev, {}])}
        >
          <div className="icon">
            <img src={PlusIcon} alt="" width={18} />
          </div>
          <span>{t('create')}</span>
        </div>
      ) : null}
    </div>
  );
};

export default UnwantedShiftCollectionContainer;
