import {
  DAY_SHIFT_VALUE,
  EVENING_SHIFT_VALUE,
  HARD_RULE_PROPERTY,
  NIGHT_SHIFT_VALUE,
  offShiftReg,
} from 'constant';
import moment from 'moment';

const isHaveShiftOnDate = (date, employeeTaskList, taskType) => {
  const task = employeeTaskList[date];
  if (task) {
    const taskData = task[0];
    if (offShiftReg.test(taskData.taskName)) {
      return false;
    }
    if (taskType) {
      return +taskData.taskType === +taskType;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

const getShiftByDate = (date, employeeTaskList) => {
  const task = employeeTaskList[date];
  if (task && !offShiftReg.test(task.taskName)) {
    const taskData = task[0];

    return taskData;
  } else {
    return null;
  }
};

const getTimeBetweenShift = ({ startTime, dateStart, endTime, endDate, prevTasktype }) => {
  const startTimeFormated = /./.test(startTime) ? startTime.split('.')[0] : startTime;
  const endTimeFormated = /./.test(endTime) ? endTime.split('.')[0] : endTime;
  let diffInMinutes;
  if (prevTasktype === NIGHT_SHIFT_VALUE) {
    const startMoment = moment(`${dateStart} ${startTimeFormated}`, 'YYYY-MM-DD HH:mm:ss').add(
      1,
      'day',
    );

    const endMoment = moment(`${endDate} ${endTimeFormated}`, 'YYYY-MM-DD HH:mm:ss');

    diffInMinutes = startMoment.diff(endMoment, 'minutes');
  } else {
    const startMoment = moment(`${dateStart} ${startTimeFormated}`, 'YYYY-MM-DD HH:mm:ss');

    const endMoment = moment(`${endDate} ${endTimeFormated}`, 'YYYY-MM-DD HH:mm:ss');

    diffInMinutes = startMoment.diff(endMoment, 'minutes');
  }
  return Math.abs(diffInMinutes) / 60; // In hours
};

const getMinimumTimeOffBetweenShift = (prevType, nextType, workingEnvSetup) => {
  let timeOff = 9;
  if (prevType === EVENING_SHIFT_VALUE && nextType === DAY_SHIFT_VALUE) {
    const setupRecord = workingEnvSetup.find(
      (item) => item.questionProperty === HARD_RULE_PROPERTY.MIN_DAILY_EVE_DAY,
    );
    if (setupRecord) {
      timeOff = setupRecord.questionRecord;
    }
  }
  if (prevType === DAY_SHIFT_VALUE && nextType === NIGHT_SHIFT_VALUE) {
    const setupRecord = workingEnvSetup.find(
      (item) => item.questionProperty === HARD_RULE_PROPERTY.MIN_DAILY_DAY_NIGHT,
    );
    if (setupRecord) {
      timeOff = setupRecord.questionRecord;
    }
  }
  if (prevType === NIGHT_SHIFT_VALUE && nextType === EVENING_SHIFT_VALUE) {
    const setupRecord = workingEnvSetup.find(
      (item) => item.questionProperty === HARD_RULE_PROPERTY.MIN_DAILY_NIGHT_EVE,
    );
    if (setupRecord) {
      timeOff = setupRecord.questionRecord;
    }
  }
  return timeOff;
};

const isWeekend = (date) => {
  return moment(date).weekday() === 0 || moment(date).weekday() === 6;
};
const isSaturday = (date) => {
  return moment(date).weekday() === 6;
};
const isOffShift = (task) => {
  return offShiftReg.test(task.taskName) || task.taskType === 4;
};
export default {
  isHaveShiftOnDate,
  getShiftByDate,
  isWeekend,
  isSaturday,
  getTimeBetweenShift,
  getMinimumTimeOffBetweenShift,
  isOffShift,
};
