import React from 'react';

const RoleTooltip = (params) => {
  const { data } = params;
  if (!data || !data.roleInfoList.length) return;
  return (
    <div className="role-tooltip__wrap">
      {data.roleInfoList.map((item) => {
        return (
          <div className="role-tooltip__item" key={`emp-role-tooltip ${item.id}`}>
            {item.role}
          </div>
        );
      })}
    </div>
  );
};

export default RoleTooltip;
