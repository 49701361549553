import { BULK_CREATE } from '_components/CarlendarPlan/EmployeeV2/constants/modal';
import { CheckGreenIcon } from 'assets/img';
import axiosClient from 'axios/axiosClient';
import { t } from 'i18next';
import React, { forwardRef, memo, useImperativeHandle, useMemo, useState } from 'react';

const handleCreateRole = (roleName, refetchRoles, rejectError, params, setFormData) => {
  const newRole = { role: roleName };
  axiosClient.post('calendar-plan/role/create', { role: newRole }).then(async (roleCreated) => {
    if (params) {
      const { data } = params;
      data.role_id = roleCreated.data.id;
      setFormData((prev) => {
        const updateFormData = { ...prev };
        const bulkData = updateFormData[BULK_CREATE].map((item) => {
          if (item.role === roleName && item.rowError.role) {
            return {
              ...item,
              role_id: roleCreated.data.id,
              rowError: { ...item.rowError, role: false },
            };
          }
          return item;
        });
        return { ...updateFormData, [BULK_CREATE]: bulkData };
      });
    }
    await refetchRoles();
    await rejectError();
    params.stopEditing();
  });
};
const RoleSelection = forwardRef((params, ref) => {
  const { data, roleList, onValueChange, stopEditing, setIsTableError, refetchRoles, setFormData } =
    params;
  const isWrongRole = useMemo(() => data.rowError?.role, [data]);
  const [value, setValue] = useState(params.value);
  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },
    };
  });
  const rejectError = async () => {
    data.rowError.role = false;
    setIsTableError((prev) => ({
      ...prev,
      role: false,
    }));
  };
  const handleSelectRole = (role) => {
    onValueChange(role);
    setValue(role);
    stopEditing();
  };
  return (
    <>
      <div className="role-selection__wrap">
        <div className="role-selection">
          {isWrongRole ? (
            <div className="role-selection__item">
              <p className="title">{t('create')}?</p>
              <div className="role-selection__error">
                <span className="error-value">{value}</span>
                <span
                  className="create-value"
                  onClick={() =>
                    handleCreateRole(value, refetchRoles, rejectError, params, setFormData)
                  }
                >
                  <img src={CheckGreenIcon} alt="" width={14} />

                  {t('create')}
                </span>
              </div>
            </div>
          ) : null}
          <div className="role-selection__item">
            <p className="title">{t('select-role')}</p>
            <div className="role-selection__list">
              {roleList.map((item) => {
                if (!item || !item?.trim()?.length || item === ' ') return;
                return (
                  <p
                    className="role-selection__option"
                    key={item}
                    onClick={() => handleSelectRole(item)}
                  >
                    {item}
                  </p>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default memo(RoleSelection);
