import React from 'react';
import Chart from 'chart.js/auto';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './app/store';
import { EditingProvider } from 'providers';

import App from './app/App';

import './index.scss';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <EditingProvider>
        <App />
      </EditingProvider>
    </PersistGate>
  </Provider>,
);

reportWebVitals();
