import React, { useState, useEffect } from 'react';

const TimeEditor = (props) => {
  const rowNode = props.api.getRowNode(props.data.id);
  const [value, setValue] = useState({
    from_time: props.data.from_time,
    to_time: props.data.to_time,
  });

  const handleChangeFrom = (event) => {
    const newValue = event.target.value;
    setValue((prev) => ({ ...prev, from_time: newValue }));
  };

  const handleChangeTo = (event) => {
    const newValue = event.target.value;
    setValue((prev) => ({ ...prev, to_time: newValue }));
  };

  useEffect(() => {
    const newData = {
      ...props.data,
      from_time: `${value.from_time}`,
      to_time: `${value.to_time}`,
    };
    rowNode.updateData(newData);
  }, [value]);

  return (
    <div className="time-editor">
      <input type="time" value={value.from_time} onChange={handleChangeFrom} />
      <span> - </span>
      <input type="time" value={value.to_time} onChange={handleChangeTo} />
    </div>
  );
};

export default TimeEditor;
