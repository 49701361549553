import {
  PAID_BREAK_ALL_DAY,
  PAID_BREAK_WEEKDAY_ONLY,
  PAID_BREAK_WEEKEND_ONLY,
  PAID_BREAK_HOLIDAY_ONLY,
  PAID_BREAK_HOLIDAY_WEEKENDS_ONLY,
} from 'constant';
import moment from 'moment';

const isWeekend = (date) => {
  // Assuming 'date' is a string or a Date object
  const momentDate = moment(date);
  return momentDate.day() === 6 || momentDate.day() === 0;
};
const checkingPaidBreakType = (paidBreakKey, date, publicHoliday) => {
  switch (paidBreakKey) {
    case PAID_BREAK_ALL_DAY:
      return true;
    case PAID_BREAK_WEEKDAY_ONLY:
      return !isWeekend(date);
    case PAID_BREAK_WEEKEND_ONLY:
      return isWeekend(date);
    case PAID_BREAK_HOLIDAY_ONLY:
      return publicHoliday.includes(date);
    case PAID_BREAK_HOLIDAY_WEEKENDS_ONLY:
      return publicHoliday.includes(date) && isWeekend(date);
  }
  return false;
};

export const getMinusPaidTimeBreak = (task, date, publicHoliday) => {
  const isPaidBreak = task.isPaidBreak;
  const taskPause = task.pause;

  const paidBreakKey = task.paidBreakKey;

  if (taskPause) {
    const pauseMinutes = moment(taskPause, 'HH:mm:ss').minutes();
    if (isPaidBreak) {
      if (paidBreakKey) {
        const isValidPaidBreak = checkingPaidBreakType(paidBreakKey, date, publicHoliday);
        return isValidPaidBreak ? 0 : pauseMinutes / 60.0;
      }
      return 0;
    }
    return pauseMinutes / 60.0;
  }
  return 0;
};
