import React from 'react';

const ResetIcon = ({ color = '#4E1830' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <g clipPath="url(#clip0_2203_20053)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25ZM0.75 9C0.75 4.44365 4.44365 0.75 9 0.75C13.5563 0.75 17.25 4.44365 17.25 9C17.25 13.5563 13.5563 17.25 9 17.25C4.44365 17.25 0.75 13.5563 0.75 9ZM7.875 6C7.875 5.37868 8.37868 4.875 9 4.875C9.62132 4.875 10.125 5.37868 10.125 6C10.125 6.62132 9.62132 7.125 9 7.125C8.37868 7.125 7.875 6.62132 7.875 6ZM4.5 9C4.5 8.58579 4.83579 8.25 5.25 8.25H12.75C13.1642 8.25 13.5 8.58579 13.5 9C13.5 9.41421 13.1642 9.75 12.75 9.75H5.25C4.83579 9.75 4.5 9.41421 4.5 9ZM7.875 12C7.875 11.3787 8.37868 10.875 9 10.875C9.62132 10.875 10.125 11.3787 10.125 12C10.125 12.6213 9.62132 13.125 9 13.125C8.37868 13.125 7.875 12.6213 7.875 12Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2203_20053">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ResetIcon;
