/** libs */
import React, { forwardRef } from 'react';
import { Input as InputAnt } from 'antd';

/** style */
import './styles.scss';

const Input = forwardRef((props, ref) => {
  return <InputAnt {...props} className="input" ref={ref} />;
});

export default Input;
