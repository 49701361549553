import React, { lazy, Suspense, useEffect, useRef } from 'react';
import i18n from 'i18next';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { useDispatch, useSelector } from 'react-redux';
import PrivateRoute from './PrivateRoute';
import DefaultLayout from 'layout/Default';
import { initReactI18next } from 'react-i18next';
import { selectGlobal, updateStoredVersion } from 'redux/global/globalSlice';
import translationEN from 'translation/translationEN.json';
import translationNO from 'translation/translationNO.json';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { loadState } from 'helper/localStorage';
import { QueryClient, QueryClientProvider } from 'react-query';
const LoginPage = lazy(() => import('views/pages/Login'));
const EmployeeLoginPage = lazy(() => import('views/pages/EmployeeLogin'));
const SurveyPage = lazy(() => import('views/pages/Survey'));
const SchedulePage = lazy(() => import('views/pages/Schedule'));
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS

import 'scss/style.scss';
import Loading from '_components/Loading';

import ResetPassword from 'views/pages/ResetPassword';
import { selectLoading } from '_components/Loading/slice';
import { PromptUnsavedChanges } from 'components/PromptUnsavedChanges';
import EmployeeRoute from './EmployeeRoute';
import axiosClient from 'axios/axiosClient';

const lang = loadState('language');
i18n.use(initReactI18next).init({
  resources: {
    NO: { translation: translationNO },
    EN: { translation: translationEN },
  },
  lng: lang || 'NO',
  fallbackLng: lang || 'NO',
  interpolation: { escapeValue: false },
});
if (process.env.REACT_APP_NODE_ENV == 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],

    //     // Set tracesSampleRate to 1.0 to capture 100%
    //     // of transactions for performance monitoring.
    //     // We recommend adjusting this value in production
    //     tracesSampleRate: 1.0,
  });
}
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const { theme } = useSelector(selectGlobal);
  const loading = useSelector(selectLoading);

  //Checking if have new version web app will auto refresh page
  const checkForUpdates = () => {
    axiosClient
      .get('version')
      .then((currentVersion) => {
        const storedVersion = localStorage.getItem('appVersion');
        if (currentVersion && currentVersion.version !== storedVersion) {
          localStorage.setItem('appVersion', currentVersion.version);
          // New version available, refresh the page
          window.location.reload();
        } else {
          setTimeout(checkForUpdates, 60000);
        }
      })
      .catch((error) => {
        console.error('Error fetching version:', error);
        setTimeout(checkForUpdates, 60000 * 2); // Retry in case of error
      });
  };

  checkForUpdates();

  return (
    <QueryClientProvider client={queryClient}>
      <div className="App" data-theme={theme}>
        <BrowserRouter>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/employee-login" element={<EmployeeLoginPage />} />
              <Route path="/reset-password/:id" element={<ResetPassword />} />
              <Route
                path="/survey/:id"
                element={
                  <EmployeeRoute>
                    <SurveyPage />
                  </EmployeeRoute>
                }
              />
              <Route
                path="/schedule/:planID/:versionID/:employeeUUID"
                element={
                  <EmployeeRoute>
                    <SchedulePage />
                  </EmployeeRoute>
                }
              />

              <Route
                path="/*"
                element={
                  <PrivateRoute>
                    <DefaultLayout />
                  </PrivateRoute>
                }
              />
            </Routes>
            <PromptUnsavedChanges />
            <Loading isShow={loading.isLoading} />
          </Suspense>
        </BrowserRouter>
      </div>
    </QueryClientProvider>
  );
}

export default App;
