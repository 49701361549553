import { t } from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Toast, showLoading } from 'helper/alert';
import { useDispatch, useSelector } from 'react-redux';
import axiosClient from 'axios/axiosClient';

import Employees from './Employees';
import Questions from './Questions';
import NavTabs from 'components/NavTabs/NavTabs';

import surveyAPI from '_apis/survey';

import { useListQuestions } from 'Hooks/useData';
import { closeModal, selectModal } from '_components/Modal/slice';

import { ArrowRight, BackArrowIcon, CloseBlackIcon, SendWhiteIcon } from 'assets/img';

import './styles.scss';
import moment from 'moment';
import Message from './Message';
import Receipt from './Receipt';

const MODAL_CLOSE_DELAY = 200; // this delay must be the same as the transition duration in the scss file

const TAB_LIST = [
  {
    key: 'questions',
    value: 'questions',
  },
  {
    key: 'receivers',
    value: 'receivers',
  },
  {
    key: 'message',
    value: 'message',
  },
  {
    key: 'receipt',
    value: 'receipt',
    isDisabled: true,
  },
];

const SendSurvey = ({ unitSelected }) => {
  const modal = useSelector(selectModal);
  const { data: empInfo } = modal;

  const dispatch = useDispatch();

  const [selectedRows, setSelectedRows] = useState({});
  const [limitDate, setLimitDate] = useState(moment().add(14, 'd').format('YYYY-MM-DD'));
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [message, setMessage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState(TAB_LIST[0].key);

  const [topicList, setTopicList] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState([]);

  const { data: listQuestions } = useListQuestions({
    unitCode: unitSelected,
  });
  const currentIndex = TAB_LIST.findIndex((item) => item.key === currentTab);

  const listBlockedQuestion = useMemo(() => {
    return listQuestions
      .filter((question) =>
        topicList
          .filter((item) => !selectedTopic.includes(item.uuid))
          .map((item) => item.uuid)
          .includes(question.parent_question_topic_id),
      )
      .map((item) => item.parent_question_id);
  }, [listQuestions, selectedTopic, topicList]);

  const { getSurveyTopic } = surveyAPI();

  useEffect(() => {
    const fetchData = async () => {
      if (!unitSelected) return;
      try {
        const [surveyTopicList] = await Promise.all([getSurveyTopic()]);

        const getTopic = () => {
          //WILL BE CONFIG BY BE LATER, PLEASE NOTE THAT THIS FUNCTION NEED TO BE SETUP ON EMPLOYEE'S SURVEY TOO
          const listTopicFiltered = surveyTopicList.map((item) => {
            if (item.topic === 'Temporary work summer (if under 50%)') {
              return;
            }

            if (
              item.topic === 'Full-time (if position below 100%)' &&
              +empInfo?.job_percentage === 100
            ) {
              return;
            }

            return item;
          });
          return listTopicFiltered.filter((item) => item);
        };
        const listTopicFiltered = getTopic();

        setTopicList([...listTopicFiltered]);
        const initTopic = listTopicFiltered.map((item) => item.uuid);
        setSelectedTopic([...initTopic]); //Add extend topic
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [unitSelected]);

  useEffect(() => {
    setIsOpen(true);
    document.body.style.overflow = 'hidden'; // Prevent scrolling in the background

    return () => {
      document.body.style.overflow = 'auto'; // Re-enable scrolling when the component unmounts
    };
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setTimeout(() => {
      dispatch(closeModal());
      document.body.style.overflow = 'auto'; // Re-enable scrolling when the modal is closed
    }, MODAL_CLOSE_DELAY);
  }, [dispatch]);
  const renderTabContent = useCallback(
    (currentTab) => {
      switch (currentTab) {
        case 'questions':
          return (
            <Questions
              unitSelected={unitSelected}
              topicList={topicList}
              selectedTopic={selectedTopic}
              setSelectedTopic={setSelectedTopic}
              listQuestions={listQuestions}
            />
          );
        case 'receivers':
          return (
            <Employees
              unitSelected={unitSelected}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
            />
          );
        case 'message':
          return (
            <Message
              unitSelected={unitSelected}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              setLimitDate={setLimitDate}
              limitDate={limitDate}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              message={message}
              setMessage={setMessage}
            />
          );
        case 'receipt':
          return <Receipt handleClose={handleClose} />;
        default:
          return (
            <Questions
              unitSelected={unitSelected}
              topicList={topicList}
              selectedTopic={selectedTopic}
              setSelectedTopic={setSelectedTopic}
              listQuestions={listQuestions}
            />
          );
      }
    },
    [
      currentTab,
      unitSelected,
      topicList,
      selectedTopic,
      setSelectedTopic,
      selectedRows,
      setSelectedRows,
      message,
      startDate,
      endDate,
    ],
  );

  const handleSend = async () => {
    const rowsData = Object.entries(selectedRows);
    const listEmpUUID = Object.keys(selectedRows);
    const email = rowsData.reduce((acc, [rowId, row]) => (row.email ? [...acc, rowId] : acc), []);
    const phone = rowsData.reduce(
      (acc, [rowId, row]) => (row.phone_number ? [...acc, rowId] : acc),
      [],
    );

    if (!email.length && !phone.length) {
      Toast({
        icon: 'warning',
        title: t('please-select-employee'),
      });
      return;
    }

    if (!startDate || !endDate) {
      Toast({
        icon: 'warning',
        title: t('please-select-date-period'),
      });
      return;
    }

    if (!message) {
      Toast({
        icon: 'warning',
        title: t('please-fill-the-message'),
      });
      return;
    }

    showLoading({ shouldShow: true, title: t('sending') });

    if (empInfo) {
      await axiosClient.post('calendar-plan/survey/block-question/update', {
        listQuestionId: listBlockedQuestion,
        listEmpUUID: [empInfo.uuid],
      });
    } else {
      await axiosClient.post('calendar-plan/survey/block-question/update', {
        listQuestionId: listBlockedQuestion,
        listEmpUUID,
      });
    }

    await axiosClient
      .post(`/send-survey`, {
        topicId: selectedTopic,
        phone,
        email,
        limitDate,
        startDate,
        endDate,
        message,
      })
      .then(() => {
        showLoading({ shouldShow: false });
        Toast({
          icon: 'success',
          title: `${t('send-success')}`,
        });
        setCurrentTab('receipt');
      })
      .catch(() => {
        showLoading({ shouldShow: false });
        Toast({
          icon: 'error',
          title: `${t('send-fail')} `,
        });
      });
  };

  const handleClickBtn = () => {
    if (currentTab !== 'message') {
      setCurrentTab(TAB_LIST[currentIndex + 1].key);
    } else {
      handleSend();
    }
  };
  const backAction = () => {
    if (currentIndex > 0) {
      setCurrentTab(TAB_LIST[currentIndex - 1].key);
    }
  };
  return (
    <div className="send-survey__wrapper">
      <div className="send-survey__background" onClick={handleClose}></div>
      <div className={`send-survey__area ${isOpen ? 'open' : ''}`}>
        <div className="send-survey__area-icon">
          <img src={CloseBlackIcon} onClick={handleClose} />
        </div>
        <div className="menu-header">
          <p>{t('send-survey')}</p>
        </div>
        <NavTabs tabList={TAB_LIST} currentTab={currentTab} setCurrentTab={setCurrentTab} />
        <div className="send-survey__area-content"></div>

        <div className="send-survey__area-content">{renderTabContent(currentTab)}</div>
        {currentTab === 'receipt' ? (
          <div></div>
        ) : (
          <div className="action-btn">
            {currentIndex > 0 ? (
              <button type="button" onClick={backAction} className=" outline-btn  ">
                <img src={BackArrowIcon} />
                {t('previous')}
              </button>
            ) : (
              <div></div>
            )}

            <button type="button" onClick={handleClickBtn}>
              {currentTab !== 'message' ? t('next') : t('send')}
              <img src={currentTab !== 'message' ? ArrowRight : SendWhiteIcon} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SendSurvey;
