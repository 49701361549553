import { t } from 'i18next';
import React, { forwardRef, memo, useId, useImperativeHandle, useState } from 'react';

const RoleTypeSelection = forwardRef((params, ref) => {
  const [value, setValue] = useState(params.value);

  const { roleTypeList, onValueChange, stopEditing } = params;
  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },
    };
  });
  const handleSelectRoleType = (roleType) => {
    setValue(roleType.type);
    onValueChange(roleType.type);
    stopEditing();
  };

  return (
    <div className="role-selection__wrap">
      <div className="role-selection">
        <div className="role-selection__item">
          <p className="title">{t('select-role-type')}</p>
          <div className="role-selection__list">
            {roleTypeList.map((item) => {
              if (!item.type.trim().length || item === ' ') return;
              const roleTypeKey = useId();

              return (
                <p
                  className="role-selection__option"
                  key={roleTypeKey}
                  onClick={() => handleSelectRoleType(item)}
                >
                  {item.type}
                </p>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
});

export default memo(RoleTypeSelection);
