/* libs */
import { useTranslation } from 'react-i18next';

/* constants */
import { LANGUGUE } from 'constant/language';

export const useLanguge = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return {
    t,
    language: currentLanguage,
    isEng: currentLanguage === LANGUGUE.eng,
  };
};
