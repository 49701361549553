import { CloseOutlined } from '@ant-design/icons';
import { TriangleDownIcon } from 'assets/img';
import {
  dateFormat,
  DAY_SHIFT_VALUE,
  EVENING_SHIFT_VALUE,
  NIGHT_SHIFT_VALUE,
  TYPE_SURVEY_QUESTION,
} from 'constant';
import { t } from 'i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectGlobal } from 'redux/global/globalSlice';
const listTyeShift = [
  { typeShift: 'day-shift', value: DAY_SHIFT_VALUE },
  { typeShift: 'evening-shift', value: EVENING_SHIFT_VALUE },
  { typeShift: 'night-shift', value: NIGHT_SHIFT_VALUE },
];
const HolidayTypeShift = ({
  setSurveyRecord,
  surveyRecord,
  questionId,
  index,
  isDisabled,

  limitSurveyStart,
  limitSurveyEnd,
  holidayList = [],
}) => {
  /**
   * Type Of THis Question
   * {type:"holiday-type-shift",data:[{typeShift:'1'|'2'|'3',date:"2024-01-01"},...]}
   */
  const { language } = useSelector(selectGlobal);
  const isNo = language === 'NO';
  const [data, setData] = useState({ date: '', typeShift: '' });

  let survey = { ...surveyRecord };
  if (!survey[questionId] && !survey[questionId]?.data) {
    survey[questionId] = {
      type: TYPE_SURVEY_QUESTION.HOLIDAY_TYPE_SHIFT,
      data: [data, data, data],
    };
  }

  useEffect(() => {
    if (survey[questionId] && survey[questionId].data && survey[questionId].data[index]) {
      setData(survey[questionId].data[index]);
    }
  }, [survey[questionId]]);
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
    survey[questionId].data[index] = { ...data, [name]: value };
    setSurveyRecord(survey);
  };
  const handleRemove = (ind) => {
    const arr = survey[questionId].data;
    const newArr = arr.filter((_, index) => index !== ind);
    setSurveyRecord((prev) => ({
      ...prev,
      [questionId]: { ...prev[questionId], data: newArr },
    }));
  };
  return (
    <div className="holiday-type-shift__wrapper">
      <div className="holiday-type-shift__group">
        <div className="holiday-type-shift__item">
          <select
            className="holiday-selection"
            value={data.date}
            onChange={handleOnChange}
            disabled={isDisabled}
            style={{
              backgroundImage: `url(${TriangleDownIcon})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right 18px center',
              backgroundSize: '14px 14px',
              textAlign: 'left',
              padding: '10px',
              boxSizing: 'border-box',
              width: '100%',
            }}
            name="date"
          >
            <option value="">{t('select-holiday')}</option>
            <hr />
            {holidayList
              ?.filter((item) => {
                if (limitSurveyStart && limitSurveyEnd) {
                  const momentLimitSurveyStart = moment(limitSurveyStart);
                  const momentLimitSurveyEnd = moment(limitSurveyEnd);

                  return (
                    moment(item.date).isSameOrAfter(momentLimitSurveyStart) &&
                    moment(item.date).isSameOrBefore(momentLimitSurveyEnd)
                  );
                } else {
                  return true;
                }
              })
              .map(({ date, localName, name }, index) => (
                <option key={index} value={date}>
                  {isNo ? localName : name} - {moment(date).format(dateFormat)}
                </option>
              ))}
          </select>
        </div>
        <div className="holiday-type-shift__item">
          <select
            className="holiday-selection"
            value={data.typeShift}
            onChange={handleOnChange}
            disabled={isDisabled}
            style={{
              backgroundImage: `url(${TriangleDownIcon})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right 18px center',
              backgroundSize: '14px 14px',
              textAlign: 'left',
              padding: '10px',
              boxSizing: 'border-box',
              width: '100%',
            }}
            name="typeShift"
          >
            <option value="">{t('select-type-shift')}</option>
            <hr />
            {listTyeShift.map(({ typeShift, value }, index) => (
              <option key={index} value={value}>
                {t(typeShift)}
              </option>
            ))}
          </select>
        </div>
      </div>

      {survey[questionId].data?.length > 1 && !isDisabled && (
        <CloseOutlined
          className="clear-date-icon"
          onClick={() => {
            handleRemove(index);
          }}
        />
      )}
    </div>
  );
};

export default HolidayTypeShift;
