import React, { useEffect, useState, useCallback } from 'react';
import { t } from 'i18next';
import { Toast, showLoading } from 'helper/alert';

import WorkingEnvAct from './WorkingEnvAct';
import DayoffHoliday from './DayOffHoliday/DayoffHoliday';

import { InfoCircleBlueIcon } from 'assets/img';

import './CalendarWorkEnvironment.scss';
import axiosClient from 'axios/axiosClient';
import { useDayOffAndHoliday, useSoftRules } from 'Hooks/useWorkingEnvData';
import { selectAuth } from 'redux/auth/authSlice';
import { useSelector } from 'react-redux';
import UnwantedShiiftCollectionContainer from './UnwantedShiiftCollection/UnwantedShiiftCollectionContainer';
import DefautRotation from './DefaultRotation/DefaultRotation';
import StartNightShift from './StartNightShift/StartNightShift';
import DayOffAfterNightShift from './DayOffAfterNightShift/DayOffAfterNightShift';
import { useGeneralSettings } from 'views/pages/Admin/components/Settings/hook/useData';
const TAB_WORKING_ENV_ACT = 'working-env-act';
const TAB_DAY_OFF_HOLIDAY = 'dayoff-and-holiday';
const TAB_UNWANTED_SIHFT_COLLECTION = 'unwanted-shift-code';
const TAB_DEFAULT_ROTATION = 'default-rotation-settings';
const TAB_START_NIGHT_SHIFT_TIME = 'start-time-for-night-shift';
const TAB_OFF_AFTER_NIGHT_SHIFT = 'off-after-night-shift';
const tabList = [
  {
    key: TAB_WORKING_ENV_ACT,
    isActiveSaveBtn: true,
  },
  // {
  //   key: 'duty-work-hrs',
  // },
  {
    key: TAB_DAY_OFF_HOLIDAY,
    isActiveSaveBtn: true,
  },
  {
    key: TAB_UNWANTED_SIHFT_COLLECTION,
    isActiveSaveBtn: false,
  },
  {
    key: TAB_DEFAULT_ROTATION,
    isActiveSaveBtn: false,
  },
  {
    key: TAB_START_NIGHT_SHIFT_TIME,
    isActiveSaveBtn: false,
  },
  {
    key: TAB_OFF_AFTER_NIGHT_SHIFT,
    isActiveSaveBtn: false,
  },
];

const CalendarWorkEnvironmentv2 = ({ unitSelected, noMarginTop = false }) => {
  const [currentTab, setCurrentTab] = useState(tabList[0].key);
  const [isActiveSaveBtn, setIsAcitveSaveBtn] = useState(tabList[0].isActiveSaveBtn);
  const [updatedData, setUpdateData] = useState({});
  const [questions, setQuestions] = useState([]);
  const [dayOffHoliday, setDayOffHoliday] = useState({});
  const [f3Range, setF3Range] = useState([]);
  const { data: dayOffHolidayList, refetch: refetchDayOffHoliday } = useDayOffAndHoliday({
    unitCode: unitSelected,
  });
  const { data: generSettingList, refetch: refetchGeneralSetting } = useGeneralSettings({
    unitCode: unitSelected,
  });
  const { data: softRuleList, refetch: refetchSoftRuleList } = useSoftRules({
    unitCode: unitSelected,
  });
  const [isExtend, setIsExtend] = useState();
  const { isEditabled } = useSelector(selectAuth);

  const handleUpdate = (e) => {
    const { name, value } = e.target;
    const questionFound = questions.find((question) => question.uuid === name);
    if (value !== '') {
      setUpdateData({ ...updatedData, [name]: { value, questionId: questionFound.id } });
    } else {
      setUpdateData({
        ...updatedData,
        questionId: questionFound.id,
        [name]: { value: null, questionId: questionFound.id },
      });
    }
  };

  useEffect(() => {
    axiosClient.post('calendar-plan/aml/questions').then((result) => {
      if (result) setQuestions(result.data);
    });
  }, []);

  useEffect(() => {
    axiosClient.post('calendar-plan/aml/anwsers', { unitCode: unitSelected }).then((result) => {
      if (result) setUpdateData(result.data);
    });
  }, [unitSelected]);

  const amlSubmit = () => {
    if (currentTab !== TAB_WORKING_ENV_ACT) {
      Toast({ icon: 'warning', title: t('update-soon') });
      return;
    }

    const resultIds = Object.keys(updatedData);
    const answersRecord = resultIds.map((key) => {
      return {
        questionUUID: key,
        answer: updatedData[key],
        unitCode: unitSelected,
      };
    });
    showLoading({ shouldShow: true, title: t('saving') });
    axiosClient
      .post('calendar-plan/aml/update', { answersRecord })
      .then(() => {
        showLoading({ shouldShow: false });
        Toast({ icon: 'success', title: t('save') });
      })
      .catch((err) => {
        Toast({ icon: 'error', title: `${t('save')} ${t('fail')}` });
      });
  };

  const dayOffHolidaySubmit = () => {
    const updateObj = { ...dayOffHoliday };
    for (const questionID of Object.keys(updateObj)) {
      if (updateObj[questionID].type === 'select') {
        const parseValue = JSON.parse(updateObj[questionID].answer);
        updateObj[questionID].answer = JSON.stringify({ optionID: parseValue.id, f3Range });
      }
    }

    axiosClient
      .post('calendar-plan/working-env/day-off-holiday/update', {
        unitCode: unitSelected,
        updateObj,
      })
      .then((_) => {
        Toast({ icon: 'success', title: `${t('update')} ${t('success')}` });
        refetchDayOffHoliday();
      })
      .catch((err) => {
        Toast({ icon: 'success', title: `${t('update')} ${t('un-success')}` });
      });
  };

  const handleSubmit = useCallback(() => {
    switch (currentTab) {
      case TAB_WORKING_ENV_ACT:
        amlSubmit();
        break;

      case TAB_DAY_OFF_HOLIDAY:
        dayOffHolidaySubmit();
        break;

      default:
        break;
    }
  }, [currentTab, dayOffHoliday, updatedData, f3Range]);

  const renderTab = () => {
    switch (currentTab) {
      case TAB_WORKING_ENV_ACT:
        return (
          <WorkingEnvAct
            questions={questions}
            updatedData={updatedData}
            handleUpdate={handleUpdate}
          />
        );
      // case 'duty-work-hrs':
      //   return <DutyWorkHrs />;
      case TAB_DAY_OFF_HOLIDAY:
        return (
          <DayoffHoliday
            unitSelected={unitSelected}
            setDayOffHoliday={setDayOffHoliday}
            dayOffHoliday={dayOffHoliday}
            f3Range={f3Range}
            setF3Range={setF3Range}
            dayOffHolidayList={dayOffHolidayList}
            isExtend={isExtend}
            setIsExtend={setIsExtend}
          />
        );
      case TAB_UNWANTED_SIHFT_COLLECTION:
        return (
          <UnwantedShiiftCollectionContainer
            unitSelected={unitSelected}
            setDayOffHoliday={setDayOffHoliday}
            dayOffHoliday={dayOffHoliday}
            f3Range={f3Range}
            setF3Range={setF3Range}
            dayOffHolidayList={dayOffHolidayList}
            isExtend={isExtend}
            setIsExtend={setIsExtend}
          />
        );
      case TAB_DEFAULT_ROTATION:
        return (
          <DefautRotation
            unitSelected={unitSelected}
            questionKey={TAB_DEFAULT_ROTATION}
            generSettingList={generSettingList}
            refetchGeneralSetting={refetchGeneralSetting}
          />
        );
      case TAB_START_NIGHT_SHIFT_TIME:
        return (
          <StartNightShift
            unitSelected={unitSelected}
            questionKey={TAB_START_NIGHT_SHIFT_TIME}
            generSettingList={generSettingList}
            refetchGeneralSetting={refetchGeneralSetting}
          />
        );
      case TAB_OFF_AFTER_NIGHT_SHIFT:
        return (
          <DayOffAfterNightShift
            unitSelected={unitSelected}
            softRuleList={softRuleList}
            refetchSoftRuleList={refetchSoftRuleList}
          />
        );
      default:
        return <WorkingEnvAct />;
    }
  };
  useEffect(() => {
    if (dayOffHolidayList) {
      const recordFetched = {};
      for (const question of dayOffHolidayList) {
        if (!question.CalendarPlanDayOffHolidayRecord) {
          if (question.type === 'select') {
            const activeOption = question.CalendarPlanDayOffHolidayOptions.find(
              (item) => !item.is_extend,
            );
            recordFetched[question.id] = {
              answer: JSON.stringify(activeOption),
              type: question.type,
            };
          }
          continue;
        }
        if (question.type === 'select') {
          if (question.CalendarPlanDayOffHolidayRecord.answer) {
            const parseAnswer = JSON.parse(question.CalendarPlanDayOffHolidayRecord.answer);
            const activeOption = question.CalendarPlanDayOffHolidayOptions.find((item) =>
              parseAnswer.optionID
                ? +item.id === +parseAnswer.optionID
                : +item.id === +parseAnswer.id,
            );

            if (activeOption) {
              setIsExtend(activeOption.is_extend);
              if (parseAnswer.f3Range) {
                setF3Range(parseAnswer.f3Range);
              }
              recordFetched[question.id] = {
                answer: JSON.stringify(activeOption),
                type: question.type,
              };
            }
          } else {
            const activeOption = question.CalendarPlanDayOffHolidayOptions.find(
              (item) => !item.is_extend,
            );

            recordFetched[question.id] = {
              answer: JSON.stringify(activeOption),
              type: question.type,
            };
          }
        } else {
          recordFetched[question.id] = {
            answer: question.CalendarPlanDayOffHolidayRecord?.answer,
            type: question.type,
          };
        }
      }
      setDayOffHoliday(recordFetched);
    }
  }, [dayOffHolidayList]);
  return (
    <div className="working-environment" style={noMarginTop ? { marginTop: 0 } : {}}>
      <div className="working-environment__left">
        <p className="title">{t('work-setup')}</p>
        <div>
          {tabList.map(({ key, isActiveSaveBtn }) => (
            <p
              className={currentTab === key ? 'active' : ''}
              key={key}
              onClick={() => {
                setCurrentTab(key);
                setIsAcitveSaveBtn(isActiveSaveBtn);
              }}
            >
              {t(key)}
            </p>
          ))}
        </div>
        {/* <p className="info">
          <img src={InfoCircleBlueIcon} />
          {t('working-env-act-desc')}
        </p> */}
      </div>
      <div className="working-environment__right">
        <div className="tab-header">
          <p>{t(currentTab)}</p>
          {isActiveSaveBtn ? (
            <button disabled={!isEditabled} className="custom-btn" onClick={handleSubmit}>
              {t('save')}
            </button>
          ) : null}
        </div>
        {renderTab()}
      </div>
    </div>
  );
};

export default CalendarWorkEnvironmentv2;
