import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useCheckAuthentication } from 'Hooks/useCheckAuthentication';
import { Cookies } from 'react-cookie';
import axiosClient from 'axios/axiosClient';
import { clearStorage } from 'helper/localStorage';
import globalRouter from './globalRouter';
import { Toast } from 'helper/alert';

function PrivateRoute({ children }) {
  const navigate = useNavigate();
  globalRouter.navigate = navigate;
  let timeout;
  const cookies = new Cookies();
  const [isAuthenticated, setIsAuthenticated] = useState(cookies.get('accessToken')?.length > 0);

  const handleStorageChange = () => {
    const newToken = cookies.get('accessToken');
    setIsAuthenticated(newToken?.length > 0);
  };

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);

    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  const restartAutoReset = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(async () => {
      try {
        const response = await axiosClient.get('auth/is-in-activity');
        if (!response.isActivity) {
          clearStorage();
          setIsAuthenticated(false);
          navigate('/login', { replace: true }); // Redirect to login on inactivity
          Toast({
            icon: 'warning',
            title: 'Session timed out due to inactivity. Please log in again.',
          });
        }
      } catch (error) {
        console.error('Error checking inactivity:', error);
        clearStorage();
        setIsAuthenticated(false);
        navigate('/login', { replace: true }); // Redirect to login on inactivity
        Toast({
          icon: 'warning',
          title: 'Session timed out due to inactivity. Please log in again.',
        });

        // Handle potential errors during the inactivity check
      }
    }, 60 * 1000); // 1 minute
  };

  const onMouseMove = () => {
    restartAutoReset();
  };
  //TODO Reject auto log out function
  // useEffect(() => {
  //   if (isAuthenticated) {
  //     restartAutoReset();

  //     window.addEventListener('mousemove', onMouseMove);

  //     return () => {
  //       clearTimeout(timeout);
  //       window.removeEventListener('mousemove', onMouseMove);
  //     };
  //   } else {
  //     navigate('/login', { replace: true }); // Redirect to login if not authenticated
  //   }
  // }, [isAuthenticated]);
  return children;
}

export default PrivateRoute;
