import axiosClient from 'axios/axiosClient';
import CalendarPlanContent from 'components/CalendarPlan/CalendarPlanContent/CalendarPlanContent';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import NavigateTabs from './NavigateTabs';
import { clearStorage, loadSession } from 'helper/localStorage';
import { Cookies } from 'react-cookie';

import { updateGeneratorDashboardAction } from 'redux/calendarPlan/calendarPlanAction';
import { selectGlobal } from 'redux/global/globalSlice';

import './style.scss';

export default function CalendarPlan() {
  const cookies = new Cookies();
  const navigate = useNavigate();

  const menuActiveSession = loadSession('menuActive');
  const isMainContentSession = loadSession('isMainContent');
  const unitSelectedSession = loadSession('unitSelected');

  const { unitSelected: globalUnitSelected, isMainContentStaffPlan } = useSelector(selectGlobal);
  const [isMainContent, setIsMainContent] = useState(
    isMainContentSession !== undefined ? isMainContentSession : true,
  );
  const [menuActive, setMenuActive] = useState(menuActiveSession || 'employee');
  const [departments, setDepartments] = useState([]);
  const [unitSelected, setUnitSelected] = useState();

  const dispatch = useDispatch();
  const locale = useLocation();
  useEffect(() => {
    if (unitSelectedSession) {
      setUnitSelected(unitSelectedSession);
    } else {
      axiosClient.post('calendar-plan/department').then((result) => {
        if (result && result.length > 0) {
          setUnitSelected(result[0].unit[0].unitCode);
          setDepartments(result);
        }
      });
      dispatch(
        updateGeneratorDashboardAction({
          employAssigneData: [],
          employSurveyCheckList: [],
          employeeUnAssigneData: {},
          maxDate: '',
        }),
      );
    }
  }, [locale.key, unitSelectedSession]);

  useEffect(() => {
    setIsMainContent(isMainContentStaffPlan);
  }, [isMainContentStaffPlan]);
  const handleChangeMenu = (val) => {
    setMenuActive(val);
    const newToken = cookies.get('accessToken');
    if (!newToken?.length > 0) {
      clearStorage();
      navigate('/login', { replace: true }); // Redirect to login on inactivity
    }
  };
  return (
    <div className="calendar-plan__wrap">
      {isMainContent ? (
        <NavigateTabs menuActive={menuActive} setMenuActive={handleChangeMenu} />
      ) : null}

      <div className={`calendar-plan__content`}>
        {unitSelected && globalUnitSelected ? (
          <CalendarPlanContent
            menuActive={menuActive}
            departments={departments}
            unitSelected={globalUnitSelected}
            isMainContent={isMainContent}
            setIsMainContent={setIsMainContent}
          />
        ) : null}
      </div>
    </div>
  );
}
