import React, { useMemo } from 'react';

import AvailableWeekendShift from './components/AvailableWeekendShift';

import './PlanQuality.scss';
import { t } from 'i18next';
import QualityPieChart from './components/QualityPieChart';
import BudgetCard from './components/BudgetCard';
import { useRotationCostCalculate } from 'Hooks/useData';
import QualityColumnChart from './components/QualityColumnChart';
import { TAB_ROTATION_COST_SUMMARY } from 'constant';
import { useTurnusContext } from 'components/CalendarPlan/GeneratorContent/Turnus/TurnusContext';
import { calculateBudgetData, calculateQualityValue } from './calculatior';

const PlanQuality = ({ planSelected, unitSelected, currentTab, currentNavTab }) => {
  const show = currentTab === TAB_ROTATION_COST_SUMMARY;
  const { data: rotationCostCalData } = useRotationCostCalculate({
    planSelected,
    unitCode: unitSelected,
    currentTab,
    currentNavTab,
  });
  const turnusData = useTurnusContext();
  const {
    vakantList,
    roleListByUnit,
    dashboardData,
    surveyDashboardData,
    weekRange,
    rotationCost,
    employeeData,
    publicHoliday,
  } = turnusData;
  const qualityData = useMemo(
    () =>
      calculateQualityValue({
        vakantData: vakantList,
        roleList: roleListByUnit,
        dashboardData,
        surveyDashboardData,
        numWeek: weekRange?.length,
        rotationCost,
      }),
    [vakantList, roleListByUnit, dashboardData, surveyDashboardData, weekRange],
  );
  const budgetData = useMemo(
    () =>
      calculateBudgetData({
        employeeData,
        rotationCost,
        numWeek: weekRange?.length,
        publicHoliday,
      }),
    [employeeData, rotationCost, publicHoliday, weekRange],
  );
  return (
    <div className={`plan-quality__container ${show ? '' : 'hidden'}`}>
      <div className="first-row">
        <div className="item">
          <AvailableWeekendShift
            isIncrease={true}
            title={'vakant-hours'}
            percent={'+4%'}
            unit={'hours'}
            value={qualityData.totalVakantHrs || 0}
          />
        </div>

        <div className="item">
          <AvailableWeekendShift
            isIncrease={true}
            title={'available-weekend-shift'}
            percent={'-2%'}
            unit={'hours'}
            value={qualityData.weekendHours}
          />
        </div>
        <div className="item">
          <AvailableWeekendShift
            isIncrease={true}
            title={'employee-wanted'}
            percent={'+2%'}
            unit={'%'}
            value={qualityData.surveyPercent.toFixed(0)}
          />
        </div>
        <div className="item">
          <AvailableWeekendShift
            isIncrease={false}
            isBalance={true}
            title={'work-setup'}
            percent={'0%'}
            unit={'%'}
            value={qualityData.workSetup.toFixed(0)}
          />
        </div>
        <div className="item">
          <AvailableWeekendShift
            isIncrease={true}
            title={'soft-rule'}
            percent={'+0.2%'}
            unit={'%'}
            value={qualityData.softRule.toFixed(0)}
          />
        </div>
      </div>
      <div className="second-row">
        {/* <div className="item">
          <QualityColumnChart />
        </div> */}
        <div className="item">
          <QualityPieChart qualityData={qualityData} roleList={roleListByUnit} />
        </div>
        <div className="item">
          <BudgetCard
            budgetData={budgetData}
            title={'budget'}
            value={`680 000`}
            rotationCostCalData={rotationCostCalData}
            currentNavTab={currentNavTab}
          />
        </div>
      </div>
    </div>
  );
};

export default PlanQuality;
