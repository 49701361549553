import { createSlice } from '@reduxjs/toolkit';
import { CLINICSL_ENV, HOSPITAL_ENV } from 'constant';
import { Toast } from 'helper/alert';
import getEnvironmentType from 'helper/environment/checkingEnvironment';
import { loadSession, loadState, saveState } from 'helper/localStorage';

import { saveSession } from 'helper/localStorage';
import { init, t } from 'i18next';
import { Cookies } from 'react-cookie';
const cookies = new Cookies();

const initGlobalState = {
  theme: 'light',
  language: loadState('language') || 'NO',
  role: cookies.get('role')?.toLowerCase() || 'planner',
  departmentSelectedArr: loadSession('departmentSelectedArr'),
  unitSelectedArr: loadSession('unitSelectedArr'),
  dayNightArr: [],
  departmentNameActive: [],
  unitNameActive: [],
  minDate: '2023-01-01',
  planPatinesTask: {},
  isMainContentStaffPlan: true,
  unitSelected: loadSession('unitSeleceted') || '',
  editedEmp: null,
  //WILL BE REMOVE WHEN START USING MULTI TENANTCY
  ignoreFilterPath: [
    '/help',
    '/calendar',
    '/chiron',
    '/admin',
    '/dashboard',
    '/planning',
    '/predict',
  ],
  ignoreDepartmentHeaderSelection: ['/predict'],
  globalSortEmployee: {},

  //WILL BE REMOVE AFTER REJECT SWAP BUTTON
  isNewEmpTableUI: getEnvironmentType() === HOSPITAL_ENV,
  customerType: getEnvironmentType() || HOSPITAL_ENV,
  kommuneTypeQuestionProperty: ['leave_without_pay', 'paid_leave_of_absence'],
  hospitalTypeQuestionProperty: [],
  showingQuestionOfHospitalToKommune: [],
};

export const globalSlice = createSlice({
  name: 'global',
  initialState: initGlobalState,
  reducers: {
    resetGlobalState() {
      return initGlobalState;
    },

    setEditedEmp(state, action) {
      state.editedEmp = action.payload;
    },

    useChangeEnvironment(state, action) {
      state.customerType = action.payload.environment;
      saveSession('customerType', action.payload.environment);
      saveState('customerType', action.payload.environment);
      Toast({
        icon: 'success',
        title: `${t('change-env-alert')} ${t(action.payload.environment)}`,
      });
    },

    changeTheme(state, action) {
      state.theme = action.payload.theme;
    },

    changeLang(state, action) {
      state.language = action.payload.language;
    },

    updateUnitSelected(state, action) {
      state.unitSelected = action.payload;
      saveSession('unitSelected', action.payload);
    },

    syncDepartmentSelected(state, action) {
      const { departmentCode, unitCode } = action.payload;
      state.departmentSelectedArr = [departmentCode];
      state.unitSelectedArr = [unitCode];
    },

    selectDepartment(state, action) {
      const payloadUnit = action.payload.unitCodes;
      const payloadDepartment = action.payload.departmentSelected;
      if (action.payload.ignoreUnitDay) {
        state.departmentSelectedArr = [payloadDepartment];
      } else {
        state.departmentSelectedArr.push(payloadDepartment);
      }
      state.departmentNameActive.push(action.payload.departmentName);
      if (payloadUnit) {
        state.unitSelectedArr = [...state.unitSelectedArr, ...payloadUnit];
      }
    },

    unSelectDepartment(state, action) {
      const payloadUnit = action.payload.unitCodes;
      const payloadDepartment = action.payload.departmentSelected;

      state.departmentSelectedArr = state.departmentSelectedArr.filter(
        (item) => item !== payloadDepartment,
      );
      state.departmentNameActive = [...state.departmentNameActive].filter(
        (item) => item != action.payload.departmentName,
      );

      if (payloadUnit) {
        state.unitSelectedArr = state.unitSelectedArr.filter((item) => !payloadUnit.includes(item));
      }
    },

    selectUnit(state, action) {
      const payloadUnit = action.payload.unitSelected;
      const payloadDepartment = action.payload.departmentCode;
      state.unitSelectedArr = [...state.unitSelectedArr, payloadUnit];
      state.unitNameActive = [...state.unitNameActive, action.payload.unitName];
      if (!state.departmentSelectedArr.includes(payloadDepartment)) {
        state.departmentSelectedArr = [...state.departmentSelectedArr, payloadDepartment];
      }
    },

    unSelectUnit(state, action) {
      const payloadUnit = action.payload.unitSelected;
      const totalUnitOfDepartment = action.payload.totalUnitOfDepartment;
      const payloadDepartment = action.payload.departmentSelected;
      state.unitSelectedArr = state.unitSelectedArr.filter((item) => item != payloadUnit);
      state.unitNameActive = state.unitNameActive.filter((item) => item != action.payload.unitName);

      if (totalUnitOfDepartment.every((item) => !state.unitSelectedArr.includes(item))) {
        state.departmentSelectedArr = state.departmentSelectedArr.filter(
          (item) => item !== payloadDepartment,
        );
      }
    },
    setlectUnitByChatGPT(state, action) {
      state.departmentSelectedArr = [];
      state.unitSelectedArr = action.payload.unitSelectedArr;
    },
    setlectTypeShiftByChatGPT(state, action) {
      state.dayNightArr = action.payload.dayNightArr;
    },
    selectDayNight(state, action) {
      const payloadDayNight = action.payload.dayNightSelected;
      if (!state.dayNightArr.includes(payloadDayNight)) {
        state.dayNightArr = [...state.dayNightArr, payloadDayNight];
      } else {
        state.dayNightArr = state.dayNightArr.filter((item) => item != payloadDayNight);
      }
    },
    getMinDate(state, action) {
      const minDate = action.payload.minDate;
      state.minDate = minDate;
    },
    updateGlobalSortEmployee(state, action) {
      const sortPayload = action.payload.sortedBy;
      state.globalSortEmployee = sortPayload;
    },
    updateStatusOfStaffPlan(state, action) {
      state.isMainContentStaffPlan = action.payload.isMainContentStaffPlan;
    },
  },
});

export const selectGlobal = (state) => state.global;
export const {
  changeTheme,
  changeLang,
  selectDepartment,
  selectUnit,
  unSelectDepartment,
  unSelectUnit,
  selectDayNight,
  getMinDate,
  setlectUnitByChatGPT,
  setlectTypeShiftByChatGPT,
  updateUnitSelected,
  syncDepartmentSelected,
  setEditedEmp,
  resetGlobalState,
  useChangeEnvironment,
  updateGlobalSortEmployee,
  updateStatusOfStaffPlan,
} = globalSlice.actions;
export default globalSlice.reducer;
