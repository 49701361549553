import { CloseBlackIcon, PlusBrownIcon, PlusIcon } from 'assets/img';
import axiosClient from 'axios/axiosClient';
import { Toast } from 'helper/alert';
import { t } from 'i18next';
import React, { useState } from 'react';

const CreateGroup = ({ unitSelected, refetchGroup }) => {
  const [isCreating, setIsCreating] = useState(false);
  const [group, setGroup] = useState({
    name: '',
    description: '',
    unit_code: unitSelected,
    key: '',
  });
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setGroup({ ...group, [name]: value });
  };
  const handleSubmit = (e) => {
    let isValid = true;
    for (const key of Object.keys(group)) {
      if (group[key].length == 0 && key !== 'description') {
        isValid = false;
      }
    }
    if (isValid) {
      axiosClient
        .post('calendar-plan/group/create', { group })
        .then((_) => {
          Toast({ icon: 'success', title: `${t('create-group')} ${t('success')}` });
          refetchGroup();
          setIsCreating(false);
          setGroup({ name: '', description: '', unit_code: unitSelected });
        })
        .catch((err) => {
          console.log(err);
          Toast({ icon: 'error', title: `${t('create-group')} ${t('unsuccess')}` });
        });
    } else {
      Toast({ icon: 'error', title: `${t('please-input-all')} ` });
    }
  };
  return (
    <div className="create-group__wrapper">
      {isCreating ? (
        <div className="create-group__body">
          <div className="create-group__group">
            <label htmlFor="">
              {t('group')} {t('name').toLowerCase()}
            </label>
            <input name={'name'} type="text" value={group.name} onChange={handleOnChange} />
          </div>
          <div className="create-group__group">
            <label htmlFor="">{t('key')}</label>
            <input name={'key'} type="text" value={group.key} onChange={handleOnChange} />
          </div>
          <div className="create-group__action">
            <button onClick={handleSubmit}>
              <img src={PlusIcon} alt="" width={18} />
            </button>
            <button onClick={() => setIsCreating(false)}>
              <img src={CloseBlackIcon} alt="" width={18} />
            </button>
          </div>
        </div>
      ) : (
        <div className="create-group__label" onClick={() => setIsCreating(true)}>
          <img src={PlusBrownIcon} alt="" width={18} />
          <span>{t('create-new-group')}</span>
        </div>
      )}
    </div>
  );
};

export default CreateGroup;
