import { closeModal } from '_components/Modal/slice';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import './TurnusUploadReport.scss';
import { CloseBlackIcon } from 'assets/img';
import { t } from 'i18next';
import { v4 as uuidv4 } from 'uuid';

const MODAL_CLOSE_DELAY = 200; // this delay must be the same as the transition duration in the scss file
const TurnusReportEmployeeTable = ({ title, data }) => {
  if (!data || !data.length) {
    return;
  }
  const renderData = () => {
    return data
      .sort((a, b) => ('' + b.employeeIDNumber).localeCompare(a.employeeIDNumber))
      .map((item) => {
        const employeeName = item.employeeName;
        const employeeID = item.employeeIDNumber;
        return (
          <tr key={uuidv4()}>
            <td>{employeeID || t('none')}</td>
            <td>{employeeName}</td>
          </tr>
        );
      });
  };
  return (
    <div className="report-table__content">
      <div className="report-table-header">
        <p>{t(title)}</p>
      </div>
      <div className="report-table__wrap">
        <table className="report-table">
          <thead>
            <th>{t('emp-id')}</th>
            <th>{t('emp-name')}</th>
          </thead>
          {renderData()}
        </table>
      </div>
    </div>
  );
};

const TurnusReportShiftTable = ({ title, data }) => {
  if (!data || !data.length) {
    return;
  }
  const renderData = () => {
    return data
      .sort((a, b) => ('' + b.employeeIDNumber).localeCompare(a.employeeIDNumber))
      .map((item) => {
        const employeeName = item.employeeName;
        const employeeID = item.employeeIDNumber;
        return item.listShift.map((shift) => {
          return (
            <tr key={uuidv4()}>
              <td>{employeeID || t('none')}</td>
              <td>{employeeName}</td>
              <td>{shift.date}</td>
              <td>{shift.shiftName}</td>
            </tr>
          );
        });
      });
  };
  return (
    <div className="report-table__content">
      <div className="report-table-header">
        <p>{t(title)}</p>
      </div>
      <div className="report-table__wrap">
        <table className="report-table">
          <thead>
            <th>{t('emp-id')}</th>
            <th>{t('emp-name')}</th>
            <th>{t('date')}</th>
            <th>{t('task-name')}</th>
          </thead>
          {renderData()}
        </table>
      </div>
    </div>
  );
};
const TurnusUploadReport = ({ reportData, handleImportTurnusData }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(true);
    document.body.style.overflow = 'hidden'; // Prevent scrolling in the background

    return () => {
      document.body.style.overflow = 'auto'; // Re-enable scrolling when the component unmounts
    };
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setTimeout(() => {
      dispatch(closeModal());
      document.body.style.overflow = 'auto'; // Re-enable scrolling when the modal is closed
    }, MODAL_CLOSE_DELAY);
  }, [dispatch]);
  return (
    <div className="turnus-report__wrapper">
      <div className="turnus-report__background" onClick={handleClose}></div>
      <div className={`turnus-report__form ${isOpen ? 'open' : ''}`}>
        <div className="turnus-report__form-icon">
          <img src={CloseBlackIcon} onClick={handleClose} />
        </div>
        <div className="turnus-report__form-content">
          <div className="turnus-report__form-header">
            <p>{t('upload-turnus-report')}</p>
          </div>
          <TurnusReportEmployeeTable
            title={'employee-not-valid'}
            data={reportData?.notFoundEmpList}
          />
          <TurnusReportShiftTable title={'error-shift'} data={reportData?.errorShiftList} />
        </div>
        <div className="turnus-report__footer">
          <button
            className="custom-btn"
            onClick={async () => {
              if (reportData?.validShiftList.length) await handleImportTurnusData();
              handleClose();
            }}
          >
            {t('still-import')}
          </button>
          <button className="custom-btn" onClick={handleClose}>
            {t('cancel')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TurnusUploadReport;
