import { createSlice } from '@reduxjs/toolkit';

export const calendarPlanSlice = createSlice({
  name: 'calendarPlan',
  initialState: {
    planPatinesTask: {},
    planStaffTask: {},
    wishList: [],
    dataTable: [],
    wishListItems: [],
    emailList: [],
    currentPage: 1,
    planDetailLocked: {
      lockingPlanId: '',
      planDetailLockedList: [],
      employeeIdLockedList: [],
      emptyCell: [],
      offShiftLocked: [],
      planDetailLockedShiftIDList: [],
    },
    listLocked: [],
    planSelected: {},
    generatorDashboard: {
      employAssigneData: [],
      employSurveyCheckList: [],
      employeeUnAssigneData: {},
      maxDate: '',
    },
    generatorTable: {
      taskArr: [],
      groupArr: [],
      empArr: [],
      dateRange: [],
      weekRange: [],
      dataRender: [],
      dataSummary: [],
    },
  },
  reducers: {
    // run this to update cached state
    initListLocked(state) {
      state.listLocked = [];
    },
    initPlanPatiensTask(state, action) {
      const planPatinesTask = action.payload.planPatinesTask;
      state.planPatinesTask = planPatinesTask;
    },

    initDataTable(state, action) {
      const { data } = action.payload;
      state.dataTable = [...data];
    },
    initWishList(state, action) {
      const { data } = action.payload;
      state.wishList = [...data];
    },

    increaseCurrentPage(state) {
      state.currentPage += 1;
    },

    updateDataTable(state, action) {
      const dataTable = [...state.dataTable];
      const { data } = action.payload;

      const foundedIndex = dataTable.findIndex((item) => (item.uuid = data.row.uuid));

      if (foundedIndex !== -1) {
        dataTable[foundedIndex].task_arr[data.date] = [{ taskName: data.value }];
      }
    },

    updateWishList(state, action) {
      const wishList = [...state.wishList];

      const { value, date, row } = action.payload.data;
      const findIndex = wishList.findIndex((wish) => wish.name === row?.name && wish.date === date);
      wishList[findIndex] = {
        ...wishList[findIndex],
        code: value,
      };
      state.wishList = [...wishList];
    },

    updateWishListItem(state, action) {
      let updatedWishListItems = [...state.wishListItems];
      const { data } = action.payload;
      const foundedItemIndex = updatedWishListItems?.findIndex(
        (item) => item.uuid === data.row.uuid && item.date === data.date,
      );

      if (foundedItemIndex !== -1) {
        const { name, uuid } = data.row;

        updatedWishListItems[foundedItemIndex] = {
          name,
          uuid,
          code: data.value,
          wishListId: data.wishListId,
          date: data.date,
        };
      } else {
        const { name, uuid } = data.row;
        updatedWishListItems = [
          ...updatedWishListItems,
          {
            name,
            uuid,
            code: data.value,
            wishListId: data.wishListId,
            date: data.date,
          },
        ];
      }

      state.wishListItems = [...updatedWishListItems];
    },

    updateWishListSurvey(state, action) {
      const wishList = [...state.wishList];
      const { id, code, date } = action.payload.data;
      const findIndex = wishList.findIndex((wish) => wish.id === id && wish.date === date);
      wishList[findIndex] = {
        ...wishList[findIndex],
        code,
      };
      state.wishList = [...wishList];
    },

    updateEmailWishList(state, action) {
      state.emailList = [...action.payload.data];
    },

    clearWishList(state) {
      state.wishList = [];
    },

    deletePlanPatinesTask(state, action) {
      const planPatinesTask = action.payload.planPatinesTask;
      const prevPlanTask = { ...state.planPatinesTask };
      const updatedTaskList = {
        ...prevPlanTask.task,
      };
      delete updatedTaskList[planPatinesTask.key];
      state.planPatinesTask = { ...prevPlanTask, task: updatedTaskList };
    },

    updatePlanPatinesTaskTime(state, action) {
      const planPatinesTask = action.payload.planPatinesTask;
      const prevPlanTask = { ...state.planPatinesTask };
      const updatedTaskList = {
        ...prevPlanTask.task,
        [planPatinesTask.key]: {
          ...prevPlanTask.task[planPatinesTask.key],
          from_time: planPatinesTask.from_time,
          to_time: planPatinesTask.to_time,
        },
      };
      state.planPatinesTask = { ...prevPlanTask, task: updatedTaskList };
    },
    updatePlanPatinesTaskDetail(state, action) {
      const { key, value, taskUUID } = action.payload.planPatinesTask;
      const prevPlanTask = { ...state.planPatinesTask };

      const updatedTask = {
        ...prevPlanTask.task[taskUUID],
        [key]: { ...prevPlanTask.task[taskUUID][key], numberPerson: value },
      };
      const updatedTaskList = {
        ...prevPlanTask.task,
        [taskUUID]: updatedTask,
      };
      state.planPatinesTask = { ...prevPlanTask, task: updatedTaskList };
    },

    updatePlanPatinesTask(state, action) {
      const planPatinesTask = action.payload.planPatinesTask;
      const updateTaskList = {
        ...state.planPatinesTask.task,
        [planPatinesTask.key]: planPatinesTask,
      };
      state.planPatinesTask = { ...state.planPatinesTask, task: updateTaskList };
    },

    updatePlanPatinesTaskRangeDate(state, action) {
      const { key, value } = action.payload.planPatinesTask;
      const prevPlanTask = { ...state.planPatinesTask };
      state.planPatinesTask = { ...prevPlanTask, [key]: value };
    },

    addPlanPatiensTask(state, action) {
      const planPatinesTask = action.payload.planPatinesTask;

      state.planPatinesTask = planPatinesTask;
    },

    //Staff Plan

    initPlanStaffTask(state, action) {
      const planStaffTask = action.payload.planStaffTask;
      state.planStaffTask = planStaffTask;
    },

    deletePlanStaffTask(state, action) {
      const planStaffTask = action.payload.planStaffTask;
      const prevPlanTask = { ...state.planStaffTask };
      const updatedTaskList = {
        ...prevPlanTask.task,
      };
      delete updatedTaskList[planStaffTask.key];
      state.planStaffTask = { ...prevPlanTask, task: updatedTaskList };
    },

    updatePlanStaffTaskTime(state, action) {
      const planStaffTask = action.payload.planStaffTask;
      const prevPlanTask = { ...state.planStaffTask };
      const updatedTaskList = {
        ...prevPlanTask.task,
        [planStaffTask.key]: {
          ...prevPlanTask.task[planStaffTask.key],
          from_time: planStaffTask.from_time,
          to_time: planStaffTask.to_time,
        },
      };
      state.planStaffTask = { ...prevPlanTask, task: updatedTaskList };
    },

    updatePlanStaffTaskDetail(state, action) {
      const { key, value, taskUUID } = action.payload.planStaffTask;
      const prevPlanTask = { ...state.planStaffTask };

      const updatedTask = {
        ...prevPlanTask.task[taskUUID],
        [key]: { ...prevPlanTask.task[taskUUID][key], numberPerson: value },
      };
      const updatedTaskList = {
        ...prevPlanTask.task,
        [taskUUID]: updatedTask,
      };
      state.planStaffTask = { ...prevPlanTask, task: updatedTaskList };
    },

    handlePasteWeekStaffPlanDetail(state, action) {
      const { weekData, taskUUID } = action.payload.planStaffTaskWeek;
      const prevPlanTask = { ...state.planStaffTask };
      let updatedTask = {
        ...prevPlanTask.task[taskUUID],
      };
      for (const data of weekData) {
        updatedTask = {
          ...updatedTask,
          [data.date]: data.value,
        };
      }
      const updatedTaskList = {
        ...prevPlanTask.task,
        [taskUUID]: updatedTask,
      };

      state.planStaffTask = { ...prevPlanTask, task: updatedTaskList };
    },

    updatePlanStaffTask(state, action) {
      const planStaffTask = action.payload.planStaffTask;
      const updateTaskList = {
        ...state.planStaffTask.task,
        [planStaffTask.key]: planStaffTask,
      };
      state.planStaffTask = { ...state.planStaffTask, task: updateTaskList };
    },

    updatePlanStaffTaskRangeDate(state, action) {
      const { key, value } = action.payload.planStaffTask;
      const prevPlanTask = { ...state.planStaffTask };
      state.planStaffTask = { ...prevPlanTask, [key]: value };
    },

    addPlanStaffTask(state, action) {
      const planStaffTask = action.payload.planStaffTask;

      state.planStaffTask = planStaffTask;
    },
    updatePlanDetailLocked(state, action) {
      const planDetailLockedAction = action.payload.planDetailLocked;
      state.planDetailLocked = planDetailLockedAction;
    },
    updateListLocked(state, action) {
      try {
        const listLockedAction = action.payload.listLocked;
        const updatedListLocked = [...state.listLocked];

        const index = updatedListLocked.findIndex((item) => item.uuid === listLockedAction.uuid);

        if (index !== -1) {
          updatedListLocked[index] = listLockedAction;
        } else {
          updatedListLocked.push(listLockedAction);
        }

        return { ...state, listLocked: updatedListLocked };
      } catch (error) {
        console.log(error);
        return state;
      }
    },
    updateGeneratorDashboard(state, action) {
      const generatorDashboardPayload = action.payload.generatorDashboard;
      state.generatorDashboard = generatorDashboardPayload;
    },
    initGeneratorTable(state, action) {
      const generatorTablePayload = action.payload.generatorTable;
      state.generatorTable = generatorTablePayload;
    },
    updateGeneratorTable(state, action) {
      const generatorTablePayload = action.payload.updateGeneratorTableData;

      state.generatorTable = { ...state.generatorTable, ...generatorTablePayload };
    },
    updatePlanSelected(state, action) {
      const planSelectedPayload = action.payload.updatePlanSelectedData;

      state.planSelected = planSelectedPayload;
    },
  },
});

export const selectCalendarPlan = (state) => state.calendarPlan;
export const {
  updatePlanPatinesTask,
  initPlanPatiensTask,
  updatePlanPatinesTaskTime,
  deletePlanPatinesTask,
  updatePlanPatinesTaskDetail,
  updatePlanPatinesTaskRangeDate,
  addPlanPatiensTask,
  updatePlanStaffTask,
  initPlanStaffTask,
  updatePlanStaffTaskTime,
  deletePlanStaffTask,
  updatePlanStaffTaskDetail,
  handlePasteWeekStaffPlanDetail,
  updatePlanStaffTaskRangeDate,
  updateWishList,
  updateDataTable,
  updateEmailWishList,
  updateWishListSurvey,
  updateWishListItem,
  initDataTable,
  initWishList,
  clearWishList,
  increaseCurrentPage,
  addPlanStaffTask,
  updatePlanDetailLocked,
  updateListLocked,
  updateGeneratorDashboard,
  initGeneratorTable,
  updateGeneratorTable,
  updatePlanSelected,
  initListLocked,
} = calendarPlanSlice.actions;
export default calendarPlanSlice.reducer;
