import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Overview from './component/Overview/Overview';
import Rotation from './component/Rotation';
import Settings from './component/Settings/Settings';
import InfoHeader from './component/InfoHeader';
import NavTabs from 'components/NavTabs/NavTabs';

import SideMenu from 'components/SideMenu/SideMenu';

import { useEmployeeDetail, useEmployeePosition } from 'Hooks/useData';
import { closeModal, selectModal } from '_components/Modal/slice';

import './styles.scss';
import ScheduleCalendar from 'components/CalendarPlan/ScheduleCalendar/ScheduleCalendar';
import { selectCalendarPlan } from 'redux/calendarPlan/calendarPlanSlice';
import Positions from './component/Position/Position';

const TAB_LIST = [
  {
    key: 'overview',
    value: 'overview',
  },
  // {
  //   key: 'rotation',
  //   value: 'rotation',
  // },
  {
    key: 'personnel',
    value: 'personnel',
  },
  {
    key: 'position',
    value: 'position',
  },
];

const MODAL_CLOSE_DELAY = 200; // this delay must be the same as the transition duration in the scss file

const EmpSideMenu = ({ unitSelected, refetchEmployeeWithPositionFetch }) => {
  const dispatch = useDispatch();
  const modal = useSelector(selectModal);
  const { planSelected } = useSelector(selectCalendarPlan);

  const { data } = modal;
  const [empInfo, setEmpInfo] = useState({});
  const { data: empDetail, refetch: refectEmpDetail } = useEmployeeDetail({
    empId: data.employeeId,
  });
  const { data: employeePosition } = useEmployeePosition({ employeeID: empInfo.id });
  useEffect(() => {
    if (data.isCalendarPlan) {
      setEmpInfo({ ...empDetail, rowIndex: data.rowIndex, isCalendarPlan: true });
    } else {
      setEmpInfo(data);
    }
  }, [data, empDetail]);

  const [currentTab, setCurrentTab] = useState(TAB_LIST[0].key);

  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => {
      dispatch(closeModal());
      document.body.style.overflow = 'auto'; // Re-enable scrolling when the modal is closed
    }, MODAL_CLOSE_DELAY);
  };

  const renderTabContent = useCallback(
    (currentTab) => {
      switch (currentTab) {
        case 'overview':
          return (
            <Overview empInfo={empInfo} unitSelected={unitSelected} handleClose={handleClose} />
          );
        // case 'rotation':
        //   return (
        //     <ScheduleCalendar
        //       employeeUUID={empInfo.uuid}
        //       planID={planSelected.id}
        //       versionID={planSelected.versionID}
        //     />
        //   );
        case 'personnel':
          return (
            <Settings
              unitSelected={unitSelected}
              data={empInfo}
              refectEmpDetail={refectEmpDetail}
              setData={setEmpInfo}
              refetchEmployeeWithPositionFetch={refetchEmployeeWithPositionFetch}
            />
          );
        case 'position':
          return (
            <Positions
              unitSelected={unitSelected}
              data={empInfo}
              employeePosition={employeePosition}
              refectEmpDetail={refectEmpDetail}
              setData={setEmpInfo}
              refetchEmployeeWithPositionFetch={refetchEmployeeWithPositionFetch}
            />
          );
        default:
          return <Overview />;
      }
    },
    [currentTab, unitSelected, empInfo, refectEmpDetail, setEmpInfo],
  );

  return (
    <SideMenu isOpen={isOpen} handleClose={handleClose} setIsOpen={setIsOpen}>
      <InfoHeader data={empInfo} unitSelected={unitSelected} />
      <NavTabs tabList={TAB_LIST} currentTab={currentTab} setCurrentTab={setCurrentTab} />
      <div className="side-menu__form-content">{renderTabContent(currentTab)}</div>
    </SideMenu>
  );
};

export default EmpSideMenu;
