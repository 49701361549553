import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';

import authSlice from 'redux/auth/authSlice';
import globalSlice from 'redux/global/globalSlice';
import showTableSlice from 'redux/showTable/slice';
import generateTableColApiSlice from 'redux/generateTableApi/slice';
import calendarPlanSlice from 'redux/calendarPlan/calendarPlanSlice';
import generalTableFilterSlice from 'redux/generateTableFilter/slice';

/** UI */
import modalReducer from '_components/Modal/slice';
import loadingReducer from '_components/Loading/slice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['calendarPlan', 'auth'],
};

const rootReducer = combineReducers({
  auth: authSlice,
  global: globalSlice,
  calendarPlan: calendarPlanSlice,
  generateTableFilter: generalTableFilterSlice,
  generateTableColApi: generateTableColApiSlice,
  showTable: showTableSlice,
  modal: modalReducer,
  loading: loadingReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
