import { t } from 'i18next';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { PlusIcon, UploadBrownIcon } from 'assets/img';

import './ButtonList.scss';
import { useSelector } from 'react-redux';
import { selectAuth } from 'redux/auth/authSlice';

export default function ButtonList({
  activeContent,
  setActiveContent,
  setAddNewGuardCode,
  setIsUploading,
  setIsMainContent,
  setTaskEdit,
}) {
  const { isEditabled } = useSelector(selectAuth);

  const leftBtnList = [
    {
      name: `${t('staff-plan')}`,
      value: 'plan',
    },
    {
      name: `${t('staff-task-list')}`,
      value: 'task',
    },
  ];

  const rightBtnList = [
    // {
    //   name: t('upload-staff-plan'),
    //   value: 'plan',
    //   imgSrc: UploadBrownIcon,
    //   callback: () => setIsUploading(true),
    //   className: 'upload-btn',
    // },
    {
      name: t('add-new-plan'),
      value: 'plan',
      imgSrc: PlusIcon,
      callback: () => {
        setIsMainContent(false); // false to hide the nav bar, true to show it again
      },
      className: 'add-btn',
    },
    {
      name: t('add-guard-code'),
      value: 'task',
      imgSrc: PlusIcon,
      callback: () => {
        setTaskEdit({});
        setAddNewGuardCode(true);
      },
      className: 'add-btn',
    },
  ];
  return (
    <div className="staff-plan-btn">
      <div className="staff-plan-btn__left">
        <p>{t('staff-plan')}</p>
        <div>
          {leftBtnList.map((item) => {
            return (
              <button
                key={uuidv4()}
                className={`${activeContent === item.value ? 'active' : ''} `}
                onClick={() => {
                  setActiveContent(item.value);
                }}
              >
                {item.name}
              </button>
            );
          })}
        </div>
      </div>

      <div className="staff-plan-btn__right">
        {rightBtnList.map((item) => {
          if (item.value !== activeContent) return null;
          return (
            <button
              key={uuidv4()}
              onClick={() => {
                item.callback();
              }}
              className={item.className}
              disabled={!isEditabled}
            >
              <img src={item.imgSrc} alt={item.name} />
              {item.name}
            </button>
          );
        })}
      </div>
    </div>
  );
}
