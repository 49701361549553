import { t } from 'i18next';
import moment from 'moment';
import ReactDOM from 'react-dom';
import React, { useRef, useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useClickOutside } from 'helper/useClickOutSide';
import { reset, updateFilter } from 'redux/generateTableFilter/slice';
import { selectGeneratorColApi } from 'redux/generateTableApi/slice';
import { selectGeneratorTableFilter } from 'redux/generateTableFilter/slice';

import {
  RadioCheckIcon,
  RadioUncheckIcon,
  CalenderIcon,
  CheckboxCheckIcon,
  CheckboxUncheckIcon,
  FiltersIcon,
} from 'assets/img';
import { useTurnusContext } from '../../Turnus/TurnusContext';

const Input = ({ id, type, label, placeholder = '', icon = '', onChange, onBlur, value }) => (
  <label htmlFor={id}>
    {label}
    <input
      type={type}
      id={id}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      style={
        icon
          ? {
              backgroundImage: `url(${icon})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right 18px center',
              backgroundSize: '14px 14px',
              cursor: 'pointer',
            }
          : {}
      }
    />
  </label>
);

const TableColumnView = () => {
  const turnusData = useTurnusContext();
  const { groupList, setEmployeeData, employeeData, originData, roleListByUnit } = turnusData;
  const btnRef = useRef(null);
  const dropDownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [position, setPosition] = useState({ top: 0 });
  const [currentView, setCurrentView] = useState('all-days');
  const [groupSelected, setGroupSelected] = useState([]);
  const [roleSelected, setRoleSelected] = useState([]);
  const colApi = useSelector(selectGeneratorColApi);
  const { empInfo, taskList, dashboard, hrPerWeek } = colApi;
  const dispatch = useDispatch();
  const filters = useSelector(selectGeneratorTableFilter);
  const { customDateRange } = filters;

  const handleShowDropDown = () => {
    const coord = btnRef.current.getBoundingClientRect();
    setPosition({ top: coord.bottom });
    setShowDropdown(!showDropdown);
  };

  // toggle weekdays and holidays

  const checkDateInRange = (colId) => {
    if (colId.startsWith('emp-')) {
      return true;
    }
    const dateStr = colId.split('-').slice(1, -1).join('-');
    const date = moment(dateStr, 'YYYY-MM-DD');
    const from = moment(customDateRange.from, 'YYYY-MM-DD');
    const to = moment(customDateRange.to, 'YYYY-MM-DD');

    if (!customDateRange.from && !customDateRange.to) {
      return true;
    }
    if (customDateRange.from && !customDateRange.to) {
      return date.isSameOrAfter(from);
    }
    if (!customDateRange.from && customDateRange.to) {
      return date.isSameOrBefore(to);
    }
    return date.isSameOrAfter(from) && date.isSameOrBefore(to);
  };

  const filterData = () => {
    if (!groupSelected.length) {
      setEmployeeData(originData);
    }
    const updateData = employeeData.filter((employee) => {
      if (groupSelected.length) {
        if (groupSelected.includes(employee.groupID)) {
          return true;
        }
      }
    });

    setEmployeeData(updateData);
  };
  useEffect(() => {
    const filterData = employeeData?.filter((item) => {
      let filterCondition = true;
      if (groupSelected.length) {
        filterCondition = filterCondition && groupSelected.includes(+item.groupID);
      }

      if (roleSelected.length) {
        filterCondition = filterCondition && roleSelected.includes(+item.roleId);
      }
      return filterCondition;
    });

    if (empInfo?.api?.setRowData) {
      empInfo.api.setRowData(filterData);
    }
    if (taskList?.api?.setRowData) {
      taskList.api.setRowData(filterData);
    }
    if (dashboard?.api?.setRowData) {
      dashboard.api.setRowData(filterData);
    }
    if (hrPerWeek?.api?.setRowData) {
      hrPerWeek.api.setRowData(filterData);
    }
  }, [
    groupSelected,
    roleSelected,
    empInfo,
    taskList,
    dashboard,
    hrPerWeek,
    employeeData,
    originData,
  ]);

  const toggleColumnVisibility = (condition, visibility, setVisibility) => {
    const allColumns = taskList.colApi.getColumns();
    allColumns.forEach((column) => {
      const colId = column.getColId();
      if (condition(colId)) {
        if (colId.startsWith('emp-')) {
          return;
        }
        const currentVisibility = taskList.colApi.getColumn(colId).isVisible();
        if (currentVisibility !== visibility) {
          taskList.colApi.setColumnVisible(colId, visibility);
          setVisibility(!visibility);
        }
      }
    });
  };

  const showAllColumns = () => {
    const allColumns = taskList.colApi.getColumns();
    allColumns.forEach((column) => {
      const colId = column.getColId();
      if (colId.startsWith('emp-')) {
        return;
      }
      taskList.colApi.setColumnVisible(column.getColId(), true);
    });
    dispatch(reset());
  };

  const showWeekends = () => {
    // Hide weekdays
    toggleColumnVisibility(
      (colId) => colId.startsWith('weekdays'),
      false,
      () => {
        dispatch(updateFilter({ key: 'toggleWeekdays', value: true }));
      },
    );

    // Show weekends
    toggleColumnVisibility(
      (colId) => !colId.startsWith('weekdays'),
      true,
      () => {},
    );
  };
  const showGroup = (groupID) => {
    if (groupSelected.includes(groupID)) {
      setGroupSelected((prev) => prev.filter((item) => +item !== +groupID));
    } else {
      setGroupSelected((prev) => [...prev, groupID]);
    }
  };
  const showRole = (roleID) => {
    if (roleSelected.includes(roleID)) {
      setRoleSelected((prev) => prev.filter((item) => +item !== +roleID));
    } else {
      setRoleSelected((prev) => [...prev, roleID]);
    }
  };
  const showHolidays = () => {
    // Hide non-holidays
    toggleColumnVisibility(
      (colId) => colId.endsWith('not_holidays'),
      false,
      () => {
        dispatch(updateFilter({ key: 'toggleHolidays', value: true }));
      },
    );

    // Show holidays
    toggleColumnVisibility(
      (colId) => !colId.endsWith('not_holidays'),
      true,
      () => {},
    );
  };

  // custom date

  const showDateRange = () => {
    if (customDateRange.from || customDateRange.to) {
      const allColumns = taskList.colApi.getColumns();
      allColumns.forEach((column) => {
        const colId = column.getColId();
        if (colId.startsWith('emp-')) {
          return;
        }
        const shouldShow = checkDateInRange(colId);
        taskList.colApi.setColumnVisible(colId, shouldShow);
      });
    }
  };

  const handleDateChange = (field) => (e) => {
    const formattedDate = moment(e.target.value).format('YYYY-MM-DD');
    dispatch(
      updateFilter({
        key: field,
        value: formattedDate,
        isNested: true,
        nestedKey: 'customDateRange',
      }),
    );
  };

  const VIEW_LIST = [
    {
      key: 'all-days',
      onClick: (key) => {
        setCurrentView(key);
        showAllColumns();
      },
    },
    {
      key: 'holidays-only',
      onClick: (key) => {
        setCurrentView(key);
        showHolidays();
      },
    },
    {
      key: 'weekends-only',
      onClick: (key) => {
        setCurrentView(key);
        showWeekends();
      },
    },
    {
      key: 'day-range',
      onClick: (key) => {
        setCurrentView(key);
      },
    },
  ];

  const GROUP_LIST = useMemo(() => {
    const result = [
      {
        key: t('all-group'),
        isSelected: groupSelected.length === groupList.length || !groupSelected.length,
        onClick: () => {
          setGroupSelected([]);
        },
      },
    ];
    groupList.forEach((item) => {
      result.push({
        groupID: item.id,
        key: item.name,
        isSelected: groupSelected.includes(item.id),
        onClick: () => {
          showGroup(item.id);
        },
      });
    });
    return result;
  }, [groupList, groupSelected]);

  const ROLE_LIST = useMemo(() => {
    const result = [
      {
        key: t('all-role'),
        isSelected: roleSelected.length === roleListByUnit.length || !roleSelected.length,
        onClick: () => {
          setRoleSelected([]);
        },
      },
    ];
    roleListByUnit.forEach((item) => {
      result.push({
        roleID: item.id,
        key: item.role,
        isSelected: roleSelected.includes(item.id),
        onClick: () => {
          showRole(item.id);
        },
      });
    });
    return result;
  }, [roleListByUnit, roleSelected]);

  const handleClear = () => {
    dispatch(reset());
  };
  const dropDown = showDropdown && (
    <div
      className="table-view__wrap"
      ref={dropDownRef}
      style={{ position: 'fixed', top: position.top, right: 20 }}
    >
      <div className="table-view-dropdown ">
        <p>{t('select-calendar-view')}</p>
        {VIEW_LIST.map((item) => (
          <p key={item.key} onClick={() => item.onClick(item.key)}>
            <img src={currentView === item.key ? RadioCheckIcon : RadioUncheckIcon} />
            {t(item.key)}
          </p>
        ))}
        {currentView === 'day-range' && (
          <div className="day-range">
            <div>
              <Input
                id="from"
                type="date"
                label={t('from')}
                icon={CalenderIcon}
                value={customDateRange.from}
                onChange={handleDateChange('from')}
                onBlur={handleDateChange('from')}
              />
              <Input
                id="to"
                type="date"
                label={t('to')}
                icon={CalenderIcon}
                value={customDateRange.to}
                onChange={handleDateChange('to')}
                onBlur={handleDateChange('to')}
              />
            </div>
            <div>
              <button onClick={handleClear}>{t('clear')}</button>
              <button
                disabled={!customDateRange.from && !customDateRange.to}
                onClick={showDateRange}
              >
                {t('save')}
              </button>
            </div>
          </div>
        )}
      </div>

      {/* GROUP LIST */}
      <div
        className="table-view-dropdown"
        // style={{ position: 'fixed', top: position.top, right: 20 + 290, width: '290px' }}
      >
        <p>{t('group')}</p>
        {GROUP_LIST.map((item) => (
          <p key={item.key} onClick={() => item.onClick(item.key)}>
            <img src={item.isSelected ? CheckboxCheckIcon : CheckboxUncheckIcon} />
            {t(item.key)}
          </p>
        ))}
      </div>

      <div
        className="table-view-dropdown"
        // style={{ position: 'fixed', top: position.top, right: 20, width: '290px' }}
      >
        <p>{t('role-categories')}</p>
        {ROLE_LIST.map((item) => (
          <p key={item.key} onClick={() => item.onClick(item.key)}>
            <img src={item.isSelected ? CheckboxCheckIcon : CheckboxUncheckIcon} />
            {t(item.key)}
          </p>
        ))}
      </div>
    </div>
  );

  useClickOutside(btnRef, () => setShowDropdown(false), dropDownRef);
  return (
    <div ref={btnRef} className="table-column-view">
      <button onClick={handleShowDropDown}>
        {t('display-selection')} <img src={FiltersIcon} />
      </button>
      {ReactDOM.createPortal(dropDown, document.body)}
    </div>
  );
};

export default TableColumnView;
