import React from 'react';
import './Loading.scss';

export default function Loading({ isShow }) {
  return (
    <div className="loader">
      <div className="inner one"></div>
      <div className="inner two"></div>
      <div className="inner three"></div>
    </div>
  );
}
