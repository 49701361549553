import React, { useState } from 'react';
import { t } from 'i18next';
import Dropzone from 'react-dropzone';

import { FileUploadIcon, RedTrashIcon, ToggleOffIcon, ToggleOnIcon } from 'assets/img';

const UploadFileAndLink = ({
  uploadFile,
  setUploadFile,
  isFileUploaded,
  handleGetIdSheet,
  setPlanInfo,
  planInfo,
}) => {
  const isFileHaveGroup = planInfo.fileType === 'group';
  const [isFileErrorMessage, setIsFileErrorMessage] = useState(false);

  return isFileUploaded ? (
    <div className="file-uploaded">
      <input type="text" value={uploadFile.name} readOnly={true} />
      <img src={RedTrashIcon} onClick={() => setUploadFile({})} />
    </div>
  ) : (
    <div className="upload-form__uploading">
      <div className="drag-drop">
        <p>{t('upload-file')}</p>
        <Dropzone
          maxFiles={1}
          onDrop={(acceptedFiles) => {
            acceptedFiles.forEach((file) => {
              if (
                ![
                  'text/csv',
                  'application/vnd.ms-excel',
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                ].includes(file.type)
              ) {
                setIsFileErrorMessage(true);
              } else {
                setIsFileErrorMessage(false);
                setUploadFile(file);
              }
            });
          }}
          // accept=".csv, text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        >
          {({ getRootProps }) => (
            <section className="drop-zone" {...getRootProps()}>
              <div>
                <img src={FileUploadIcon} />
                <p>
                  {`${t('drag')} ${t('or')} ${'drop'} ${t('or')}`}{' '}
                  <span>{t('select-file').toLowerCase()}</span>
                </p>
              </div>
            </section>
          )}
        </Dropzone>
        <div className="warning">
          <p>{t('supported-format')}</p>
          {isFileErrorMessage && <p>{t('invalid-format')}</p>}
        </div>
      </div>

      <div className="url">
        <div className="header">
          <p>{t('upload-form-url')}</p>
          <div>
            <p>
              {t('file-type')}: {isFileHaveGroup ? t(`have-group`) : t('no-group')}
            </p>
            <img
              src={isFileHaveGroup ? ToggleOnIcon : ToggleOffIcon}
              onClick={() => {
                setPlanInfo((prev) => ({
                  ...prev,
                  fileType: isFileHaveGroup ? '' : 'group',
                }));
              }}
            />
          </div>
        </div>
        <div>
          <input
            type="text"
            placeholder={t('add-file-url')}
            name="googleSheetId"
            onChange={handleGetIdSheet}
          />
        </div>
      </div>
    </div>
  );
};

export default UploadFileAndLink;
