import React from 'react';
import { t } from 'i18next';

import NoStaffPlanBg from '../../../../../../assets/img/noData/no_staff_plan.png';

const GuideHelper = () => {
  return (
    <div className="guide-helper">
      <img src={NoStaffPlanBg} />
      <p>{t('get-started')}</p>
      <div>
        <p>1. {t('enter-weeks')}</p>
        <p>2. {t('enter-code')}</p>
        <p>3. {t('duplicate-plan-desc')}</p>
      </div>
    </div>
  );
};

export default GuideHelper;
