import { SyncOutlined } from '@ant-design/icons';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { cleanSingleVakant } from '../../TableContent/helper/generatorLogic';
import { useTurnusContext } from '../../../Turnus/TurnusContext';
import { selectGeneratorColApi } from 'redux/generateTableApi/slice';
import { useSelector } from 'react-redux';
import {
  CleanVakantIcon,
  CloseBlackIcon,
  InfoCircleBlueIcon,
  RadioCheckIcon,
  RadioUncheckIcon,
} from 'assets/img';
import ReactDOM from 'react-dom';
import './CleanVakant.scss';
import { t } from 'i18next';
import { cleanWeekendVakantShift } from '../../TableContent/helper/cleanWeekendVakant';
import { Toast } from 'helper/alert';
import axiosClient from 'axios/axiosClient';

const CleanVakantModal = ({ handleClose, param, workingEnvSetup, setTableData }) => {
  const turnusData = useTurnusContext();
  const listTableApi = useSelector(selectGeneratorColApi);
  const [optionSelected, setOptionSelected] = useState({});
  const [cleaning, setCleaning] = useState(false);

  const {
    surveyData,
    roleList,
    groupList,
    planSelected,
    weekRange,
    taskList,
    originalPlanData,
    summarySelectedView,
    taskArr,
    fromDate,
    endDate,
    amlInformation,
    publicHoliday,
    cleanData,
    setCleanData,
    setCleanDataAlert,
  } = turnusData;

  let rowData = [];
  const taskListApi = listTableApi.taskList?.api;
  if (taskListApi && taskListApi.forEachNode) {
    const newRowData = [];
    taskListApi.forEachNode((row) => {
      if (row.data) {
        newRowData.push(row.data);
      }
    });
    rowData = newRowData;
  }
  const cleanOption = [
    // {
    //   key: 'all-days',
    //   default: true,
    //   callback: async () => {
    //     setCleaning(true);
    //     const weekdayClean = cleanSingleVakant({
    //       listTableApi,
    //       listEmpData: rowData,
    //       data: param.data,
    //       workingEnvSetup,
    //       surveyData,
    //       setEmployeeData: setTableData,
    //       roleList,
    //       groupList,
    //       planSelected,
    //       weekRange,
    //       taskList,
    //       originalPlanData,
    //       summarySelectedView,
    //       taskArr,
    //       fromDate,
    //       endDate,
    //       amlInformation,
    //       publicHoliday,
    //       setCleanData,
    //       handleClose,
    //     });
    //     const weekendClean = cleanWeekendVakantShift({
    //       listTableApi,
    //       listEmpData: rowData,
    //       data: param.data,
    //       workingEnvSetup,
    //       surveyData,
    //       setEmployeeData: setTableData,
    //       roleList,
    //       groupList,
    //       planSelected,
    //       weekRange,
    //       taskList,
    //       originalPlanData,
    //       summarySelectedView,
    //       taskArr,
    //       fromDate,
    //       endDate,
    //       amlInformation,
    //       publicHoliday,
    //       setCleanData,
    //       handleClose,
    //     });
    //     await Promise.all([weekdayClean, weekendClean]).then(([weekdayData, weekendData]) => {
    //       setCleaning(false);
    //       const cleanedVakantShiftID = [
    //         ...weekdayData.cleanedVakantShiftID,
    //         ...weekendData.cleanedVakantShiftID,
    //       ];
    //       const assignList = [...weekdayData.assignList, ...weekendData.assignList];
    //       setCleanData({
    //         cleanedVakantShiftID,
    //         assignList,
    //       });
    //       if (cleanedVakantShiftID.length) {
    //         axiosClient
    //           .post('calendar-plan/v2/clean-vakant', {
    //             cleanedVakantShiftID,
    //             assignList,
    //           })
    //           .then((_) => {
    //             Toast({
    //               title: `${cleanedVakantShiftID.length} ${t('shift-cleaned')}`,
    //               icon: 'info',
    //             });
    //           });
    //       } else {
    //         Toast({ title: `${t('no-shift-cleaned')}`, icon: 'info' });
    //       }
    //     });
    //   },
    // },
    {
      key: 'weekdays-only',
      default: true,
      callback: async () => {
        setCleaning(true);

        const cleanDataWeekday = await cleanSingleVakant({
          cellParams: param,
          listTableApi,
          listEmpData: rowData,
          data: param.data,
          workingEnvSetup,
          surveyData,
          setEmployeeData: setTableData,
          roleList,
          groupList,
          planSelected,
          weekRange,
          taskList,
          originalPlanData,
          summarySelectedView,
          taskArr,
          fromDate,
          endDate,
          amlInformation,
          publicHoliday,
          setCleanData,
          handleClose,
          isCleanAll: true,
        });
        if (cleanDataWeekday.cleanedVakantShiftID.length) {
          axiosClient.post('calendar-plan/v2/clean-vakant', cleanDataWeekday).then((_) => {
            Toast({
              title: `${cleanDataWeekday.cleanedVakantShiftID.length} ${t('shift-cleaned')}`,
              icon: 'info',
            });
          });
        } else {
          Toast({ title: `${t('no-shift-cleaned')}`, icon: 'info' });
        }
      },
    },
    {
      key: 'weekend-only',
      callback: async () => {
        setCleaning(true);

        const cleanDataWeekend = await cleanWeekendVakantShift({
          listTableApi,
          listEmpData: rowData,
          data: param.data,
          workingEnvSetup,
          surveyData,
          setEmployeeData: setTableData,
          roleList,
          groupList,
          planSelected,
          weekRange,
          taskList,
          originalPlanData,
          summarySelectedView,
          taskArr,
          fromDate,
          endDate,
          amlInformation,
          publicHoliday,
          setCleanData,
          handleClose,
          isCleanAll: true,
        });
        if (cleanDataWeekend.cleanedVakantShiftID.length) {
          axiosClient.post('calendar-plan/v2/clean-vakant', cleanDataWeekend).then((_) => {
            Toast({
              title: `${cleanDataWeekend.cleanedVakantShiftID.length} ${t('shift-cleaned')}`,
              icon: 'info',
            });
          });
        } else {
          Toast({ title: `${t('no-shift-cleaned')}`, icon: 'info' });
        }
      },
    },
  ];

  useEffect(() => {
    const defaultOption = cleanOption.find((item) => item.default);
    if (defaultOption) {
      setOptionSelected(defaultOption);
    }
  }, []);
  const modal = (
    <div className="clean-vakant-modal">
      <div
        className="modal-background"
        onClick={() => {
          if (!cleaning) {
            handleClose();
          }
        }}
      ></div>
      <div className="clean-vakant-box__wrapper">
        <div className="clean-vakant-header">
          <h3 className="clean-vakant-header__title">{t('clean-vakant-title')}</h3>
          <h2
            className="clean-vakant-header__close"
            onClick={() => {
              if (!cleaning) {
                handleClose();
              }
            }}
          >
            <img src={CloseBlackIcon} width={18} alt="" />
          </h2>
        </div>
        <div className="clean-vakant-box">
          <div className="clean-vakant-body">
            <div className="clean-vakant-body-options">
              {cleanOption.map((item) => {
                return (
                  <div
                    key={`${item.key}-clean-vakant`}
                    className="clean-vakant-body__group"
                    onClick={() => {
                      if (cleaning) {
                        return;
                      }
                      setOptionSelected(item);
                    }}
                  >
                    <img
                      src={optionSelected.key === item.key ? RadioCheckIcon : RadioUncheckIcon}
                      alt=""
                      width={14}
                    />
                    <label htmlFor="">{t(item.key)}</label>
                    {cleaning && optionSelected.key === item.key ? <SyncOutlined spin /> : null}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="clean-vakant-footer">
            <div className="clean-vakant-body__desc">
              <img src={InfoCircleBlueIcon} alt="" width={18} />
              <p>{t('clean-vakant-desc')}</p>
            </div>
            <div className="clean-vakant-action">
              <button className="custom-btn outline-btn" onClick={handleClose} disabled={cleaning}>
                {t('cancel')}
              </button>
              <button
                disabled={cleaning}
                className="custom-btn"
                onClick={() => {
                  if (Object.keys(optionSelected).length && optionSelected.callback) {
                    optionSelected.callback().then((_) => {
                      handleClose();
                    });
                  }
                }}
              >
                {t('move-shift')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  useEffect(() => {
    document.body.style.overflow = 'hidden'; // Prevent scrolling in the background
    return () => {
      document.body.style.overflow = 'auto'; // Re-enable scrolling when the component unmounts
    };
  }, []);
  return <Fragment>{ReactDOM.createPortal(modal, document.body)}</Fragment>;
};
const CleanVakantButton = ({ param, rowData, workingEnvSetup, setTableData }) => {
  const [activeCleanModal, setActiveCleanModal] = useState(false);
  const handleClose = () => {
    setActiveCleanModal(false);
  };

  return (
    <div>
      <img
        src={CleanVakantIcon}
        alt=""
        width={18}
        onClick={() => {
          setActiveCleanModal(true);
        }}
      />
      {activeCleanModal ? (
        <CleanVakantModal
          handleClose={handleClose}
          param={param}
          rowData={rowData}
          workingEnvSetup={workingEnvSetup}
          setTableData={setTableData}
        />
      ) : null}
    </div>
  );
};

export default CleanVakantButton;
