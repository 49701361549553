import React, { useState } from 'react';
import './DayoffHoliday.scss';
import F3ShiftByRange from './F3ShiftByRange';
import { selectAuth } from 'redux/auth/authSlice';
import { useSelector } from 'react-redux';

const QuestionSelectList = ({
  question,
  options,
  isNo,
  setIsExtend,
  hadleUpdate,
  dayOffHoliday,
  setF3Range,
}) => {
  const { isEditabled } = useSelector(selectAuth);

  const handleOnChange = (option) => {
    const optionParse = JSON.parse(option);
    setIsExtend(optionParse.is_extend);
    if (!optionParse.is_extend) {
      setF3Range([]);
    }
    hadleUpdate(question, option);
  };
  return (
    <select
      value={dayOffHoliday[question.id]?.answer}
      onChange={(e) => {
        handleOnChange(e.target.value);
      }}
      disabled={!isEditabled}
    >
      {options.map((option) => {
        return (
          <option key={`${option.option}-${option.option_no}`} value={JSON.stringify(option)}>
            {isNo ? option.option_no : option.option}
          </option>
        );
      })}
    </select>
  );
};

const QuestionItem = ({
  question,
  index,
  language,
  hadleUpdate,
  f3Range,
  setF3Range,
  dayOffHoliday,
  isExtend,
  setIsExtend,
}) => {
  const isNo = language === 'NO';
  const { isEditabled } = useSelector(selectAuth);

  return (
    <>
      <div className="question-item" key={question.id}>
        <p>{index + 1}</p>
        <p className={question.question_desc ? 'question-col' : 'question-content'}>
          {isNo ? question.question_no : question.question}{' '}
          {question.question_desc && (
            <span>{isNo ? question.question_no_desc : question.question_desc}</span>
          )}
        </p>
        {question.type === 'select' ? (
          <QuestionSelectList
            options={question.CalendarPlanDayOffHolidayOptions}
            isNo={isNo}
            setIsExtend={setIsExtend}
            hadleUpdate={hadleUpdate}
            question={question}
            dayOffHoliday={dayOffHoliday}
            setF3Range={setF3Range}
          />
        ) : (
          <input
            type="number"
            className="answer-input"
            value={dayOffHoliday[question.id]?.answer}
            onWheel={(e) => e.target.blur()}
            disabled={!isEditabled}
            onChange={(e) => {
              const { value } = e.target;
              hadleUpdate(question, value);
            }}
          />
        )}
      </div>
      {isExtend && question.type === 'select' ? (
        <F3ShiftByRange f3Range={f3Range} setF3Range={setF3Range} hadleUpdate={hadleUpdate} />
      ) : null}
    </>
  );
};

export default QuestionItem;
