import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import React, { Fragment, useEffect, useMemo, useState, useRef, useCallback, memo } from 'react';

import GeneratePlanAction from './components/GeneratorPlanAction';
import NewPlanVersionBox from './components/NewPlanVersionBox/NewPlanVersionBox';
import NoDataPlaceholder from 'components/NoDataPlaceholder/NoDataPlaceholder';

import { Toast, confirmDelete, showLoading } from 'helper/alert';
import axiosClient from 'axios/axiosClient';

import { PlusIcon } from 'assets/img';
import NoPlan from 'assets/img/noData/no_plan.png';

import './GeneratorContent.scss';
import 'react-circular-progressbar/dist/styles.css';
import {
  updateGeneratorDashboardAction,
  updatePlanSelectedAction,
} from 'redux/calendarPlan/calendarPlanAction';
import { saveSession } from 'helper/localStorage';
import Turnus from './Turnus/Turnus';
import { useTurnusContext } from './Turnus/TurnusContext';
import { selectAuth } from 'redux/auth/authSlice';

function GeneratorContent({ unitSelected, isMainContent, setIsMainContent }) {
  const { isEditabled } = useSelector(selectAuth);

  const turnusData = useTurnusContext();
  const { setPlanSelected, planList, isPlanFetched, refetchPlanChildList } = turnusData;
  const dispatch = useDispatch();
  // const { listLocked = [] } = useSelector(selectCalendarPlan);

  const [isActiveNewVersionForm, setIsActiveNewVersionForm] = useState(false);
  //Config ag-grid
  const defaultColDef = useMemo(
    () => ({
      filter: false,
      sortable: true,
      editable: false,
      resizable: false,
      menuTabs: [],
    }),
    [],
  );

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const handleDelete = (plan) => {
    confirmDelete({}).then(async (result) => {
      if (result.isConfirmed) {
        showLoading({ shouldShow: true, title: 'deleting' });
        axiosClient
          .post('/calendar-plan/staff-plan/gen-new-version/delete', { id: plan.id })
          .then(async () => {
            await refetchPlanChildList();
            showLoading({ shouldShow: false });
            Toast({ icon: 'success', title: `${t('delete')} ${t('success')}` });
          })
          .catch(() => {
            Toast({ icon: 'error', title: `${t('delete')} ${t('unsuccess')}` });
          });
      }
    });
  };

  const handleOpenPlan = (plan) => {
    if (plan) {
      setPlanSelected(plan);
      dispatch(
        updateGeneratorDashboardAction({
          employAssigneData: [],
          employSurveyCheckList: [],
          employeeUnAssigneData: {},
          maxDate: '',
        }),
      );

      setIsMainContent(false);
      saveSession('isMainContent', false);
    }
  };
  const columnDefs = useMemo(
    () => [
      {
        headerName: t('plan-name'),
        resizable: false,
        cellRenderer: ({ data }) => {
          return (
            <span className="plan-title" onClick={() => handleOpenPlan(data)}>
              {data.planName}
            </span>
          );
        },
        minWidth: 250,
      },
      {
        headerName: t('version'),
        field: 'version',
        width: 100,
        headerClass: 'right-align-header',
        cellStyle: { textAlign: 'right' },
      },
      {
        headerName: t('staff-plan'),
        field: 'parentName',
        flex: 1,
        minWidth: 200,
      },
      {
        headerName: t('from'),
        field: 'from_date',
        headerClass: 'right-align-header',
        cellStyle: { textAlign: 'right' },
        width: 170,
        valueFormatter: ({ value }) => moment(value).format('DD. MMMM YYYY').toLowerCase(),
      },
      {
        headerName: t('to-date'),
        field: 'to_date',
        width: 170,
        valueFormatter: ({ value }) => moment(value).format('DD. MMMM YYYY').toLowerCase(),
      },
      {
        headerName: t('action'),
        colId: 'plan-action',
        width: 120,
        sortable: false,
        cellRenderer: (params) => {
          if (!isEditabled) return;
          return (
            <GeneratePlanAction
              plan={params.data}
              handleDelete={handleDelete}
              handleEdit={handleOpenPlan}
            />
          );
        },
      },
    ],
    [currentLanguage],
  );

  useEffect(() => {
    if (isMainContent) {
      refetchPlanChildList();
    }
  }, [isMainContent]);

  const gridRef = useRef();

  const rowHeight = 60;
  const tableHeight = useMemo(() => {
    const offSet = 50; // add little offset for better view
    const parentPlanCount = new Set(planList.map((plan) => plan.parentUUID)).size;
    return rowHeight + parentPlanCount * rowHeight + planList.length * rowHeight + offSet; // table header + parent plan count + child plan + offset
  }, [planList]);

  if (!isPlanFetched) {
    return;
  }
  return (
    <Fragment>
      {isMainContent ? (
        <div className={`generator-wrap ${!isEditabled ? 'view-only' : ''}`}>
          <div className="generator-header">
            <h1 className="generator-header__title">{t('rotation-schedules')}</h1>
            <button
              className="create-version-btn custom-btn square-btn"
              onClick={() => setIsActiveNewVersionForm(true)}
              disabled={!isEditabled}
            >
              <PlusOutlined />
              {t('create-new-plan-version')}
            </button>
          </div>
          <div className="generator-content">
            {planList?.length ? (
              <div
                className="rotation-plan ag-theme-alpine"
                style={{ height: tableHeight, minHeight: '100vh' }}
              >
                <AgGridReact
                  ref={gridRef}
                  rowData={planList}
                  defaultColDef={defaultColDef}
                  columnDefs={columnDefs}
                  animateRows={true}
                  suppressMovableColumns={true} //disable default drag column
                />
              </div>
            ) : (
              <NoDataPlaceholder
                img={NoPlan}
                title={'make-schedule'}
                description={'schedule-getting-start'}
              >
                <button
                  disabled={!isEditabled}
                  onClick={() => setIsActiveNewVersionForm(true)}
                  className="color-btn"
                >
                  <img src={PlusIcon} alt="action icon" />
                  {t('create-new-plan-version')}
                </button>
              </NoDataPlaceholder>
            )}
          </div>

          {isActiveNewVersionForm ? (
            <NewPlanVersionBox
              setIsActiveNewVersionForm={setIsActiveNewVersionForm}
              planList={planList}
              unitCode={unitSelected}
              refetchPlanList={refetchPlanChildList}
            />
          ) : null}
        </div>
      ) : (
        <Turnus
          unitSelected={unitSelected}
          setIsMainContent={setIsMainContent}
          refetchPlanChildList={refetchPlanChildList}
        />
      )}
    </Fragment>
  );
}

export default GeneratorContent;
