import React, { useCallback, useEffect, useState } from 'react';
import ParentQuestion from './ParentQuestion';
import { CloseOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import moment from 'moment';
import DatePickerCustom from 'components/DatePicker/DatePickerCustom';
import _ from 'lodash';
import { useQuery } from 'react-query';
import axiosClient from 'axios/axiosClient';
import { useParams } from 'react-router-dom';

import { WhiteCaretDownIcon } from 'assets/img';

const SurveyContent = ({
  listQuestion,
  listExpandedQuestionActive,
  listTopic,
  language,
  surveyRecord,
  setSurveyRecord,
  empInfo,
  extendTopic,
  listCollapseTopic,
  handleCollapseTopic,
  setListCollapseTopic,
  commentTopic,
  listHardRuleQuestion,
  setListHardRuleQuestion,
  listBlockedQuestionDefault,
  setListBlockedQuestionDefault,
  jobPercentage,
  warningMessage,
  setWarningMessage,
}) => {
  const { id: userId } = useParams();
  const [listBlockedQuestion, setListBlockedQuestion] = useState([]);

  let activeTopic = [];
  listTopic?.forEach((topic) => {
    listQuestion.forEach((question) => {
      if (question.parent_question_topic_id === topic.uuid && !activeTopic.includes(topic)) {
        if (
          topic.topic !== 'Extended question' &&
          listExpandedQuestionActive.findIndex((question) => question.isActive)
        ) {
          activeTopic.push(topic);
        } else {
          if (listExpandedQuestionActive.length) {
            activeTopic.push(topic);
          }
        }
      }
    });
  });

  activeTopic = listTopic?.filter((topic) => {
    return (
      topic.topic !== 'Extended question' &&
      (empInfo.job_percentage !== 100 || topic.topic !== 'Full-time (if position below 100%)') &&
      (empInfo.job_percentage < 50 || topic.topic !== 'Temporary work summer (if under 50%)')
    );
  });
  const listBlockedQuestionQuery = useQuery('list-blocked-question-emp', () =>
    axiosClient.post('calendar-plan/guest/get-all-block-question', { userId }).then((res) => {
      return res;
    }),
  );
  useEffect(() => {
    if (listBlockedQuestionQuery.data) {
      setListBlockedQuestion(listBlockedQuestionQuery.data.result);
    }
  }, [listBlockedQuestionQuery.data]);
  useEffect(() => {
    const initCollapseTopic = activeTopic.map((topic) => topic.uuid);
    initCollapseTopic.push(commentTopic.uuid, extendTopic.uuid);
    setListCollapseTopic(initCollapseTopic);
  }, []);
  const renderNewRangeTime = (questionId) => {
    const survey = { ...surveyRecord };
    if (!survey[questionId]) {
      survey[questionId] = [];
    }
    let index = 0;
    return (
      <div key={Math.random()} className="calendar-plan-survey-form__date__wrap">
        <div className="calendar-plan-survey-form__date__index">{t('time')}:</div>
        <div className="calendar-plan-survey-form__date__group">
          <div className="calendar-plan-survey-form__date__item">
            <input
              type="time"
              name=""
              id=""
              value={survey[questionId][index]?.time}
              onChange={(e) => {
                const { value } = e.target;
                survey[questionId][index] = {
                  ...survey[questionId][index],
                  time: value,
                  type: 'time',
                };

                setSurveyRecord(survey);
              }}
            />
          </div>
          <div
            className="calendar-plan-survey-form__date-close"
            onClick={() => {
              survey[questionId][index] = {
                ...survey[questionId][index],
                time: '',
                type: 'time',
              };

              setSurveyRecord(survey);
            }}
          >
            <CloseOutlined />
          </div>
        </div>
      </div>
    );
  };

  const renderRangeDate = useCallback(
    (questionId, isDisabled) => {
      const survey = { ...surveyRecord };
      if (!survey[questionId]) {
        survey[questionId] = [{ fromDate: new Date(), toDate: new Date(), type: 'date' }];
      } else if (typeof survey[questionId] === 'string') {
        survey[questionId] = JSON.parse(survey[questionId]);
      }
      const deleteDate = (questionId, index) => {
        const surveyUpdate = { ...survey, [questionId]: [] };
        setSurveyRecord(surveyUpdate);
      };

      return survey[questionId].length > 0
        ? survey[questionId].map((range, index) => {
            return (
              <div
                key={`${questionId}-date-survey-${index}`}
                className="calendar-plan-survey-form__date__wrap"
              >
                <div className="calendar-plan-survey-form__date__index">
                  {t('range')} {index + 1}:
                </div>
                <div className="calendar-plan-survey-form__date__group">
                  <div className="calendar-plan-survey-form__date__item">
                    <label htmlFor="">{t('from')}</label>

                    <DatePickerCustom
                      showWeekNumbers={true}
                      showMonthDropdown={true}
                      showYearDropdown={true}
                      disabled={!!isDisabled}
                      date={survey[questionId] ? survey[questionId][index]?.fromDate : new Date()}
                      setDate={(value) => {
                        survey[questionId][index] = {
                          ...survey[questionId][index],
                          fromDate: value,
                          type: 'date',
                        };
                        _.debounce(() => setSurveyRecord(survey), 1500)();
                      }}
                    />
                  </div>
                  <div className="calendar-plan-survey-form__date__item">
                    <label htmlFor="">{t('to')}</label>

                    <DatePickerCustom
                      showWeekNumbers={true}
                      showMonthDropdown={true}
                      showYearDropdown={true}
                      disabled={!!isDisabled}
                      date={survey[questionId] ? survey[questionId][index]?.toDate : new Date()}
                      setDate={_.debounce((value) => {
                        survey[questionId][index] = {
                          ...survey[questionId][index],
                          toDate: value,
                          type: 'date',
                        };
                        setSurveyRecord(survey);
                      }, 3000)}
                    />
                  </div>
                  <div
                    className="calendar-plan-survey-form__date-close"
                    onClick={() => {
                      deleteDate(questionId, index);
                    }}
                  >
                    <CloseOutlined />
                  </div>
                </div>
              </div>
            );
          })
        : null;
    },
    [surveyRecord],
  );

  const renderNewRangeDate = (questionId, isDisabled) => {
    const survey = { ...surveyRecord };
    if (!survey[questionId]) {
      survey[questionId] = [];
    }
    let index = survey[questionId].length;
    return (
      <div key={Math.random()} className="calendar-plan-survey-form__date__wrap">
        <div className="calendar-plan-survey-form__date__index">{t('new-range')}:</div>
        <div className="calendar-plan-survey-form__date__group">
          <div className="calendar-plan-survey-form__date__item">
            <label htmlFor="">{t('from')}</label>
            <input
              type="date"
              name=""
              disabled={!!isDisabled}
              id=""
              onChange={(e) => {
                if (!!isDisabled) {
                  return;
                }
                const { value } = e.target;
                survey[questionId][index] = {
                  ...survey[questionId][index],
                  fromDate: new Date(value),
                  type: 'date',
                };

                if (moment(value).year() > 1970) {
                  setSurveyRecord(survey);
                }
              }}
            />
          </div>
          <div className="calendar-plan-survey-form__date__item">
            <label htmlFor="">{t('to')}</label>
            <input
              type="date"
              name=""
              id=""
              disabled={!!isDisabled}
              onChange={(e) => {
                const { value } = e.target;
                survey[questionId][index] = {
                  ...survey[questionId][index],
                  toDate: new Date(value),
                  type: 'date',
                };
                if (moment(value).year() > 1970) {
                  setSurveyRecord(survey);
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  };
  const handleOnChange = (e) => {
    if (e.target.type === 'checkbox') {
      if (surveyRecord[[e.target.name]]) {
        if (surveyRecord[[e.target.name]]?.includes(e.target.value)) {
          setSurveyRecord({
            ...surveyRecord,
            [e.target.name]: surveyRecord[[e.target.name]].filter(
              (item) => item !== e.target.value,
            ),
          });
        } else {
          setSurveyRecord({
            ...surveyRecord,
            [e.target.name]: [...surveyRecord[[e.target.name]], e.target.value],
          });
        }
      } else {
        setSurveyRecord({
          ...surveyRecord,
          [e.target.name]: [e.target.value],
        });
      }
    } else {
      setWarningMessage(
        parseInt(e.target.value) < parseInt(jobPercentage)
          ? `${t('job-percentage-warning')}: ${jobPercentage}`
          : '',
      );
      setSurveyRecord({
        ...surveyRecord,
        [e.target.name]: e.target.value > 100 ? 100 : e.target.value,
      });
    }
  };
  return (
    <>
      {activeTopic.map((topic) => {
        let i = 0;
        return (
          <div key={topic.uuid} className="topic__wrap">
            <div
              className="topic-title__wrap"
              onClick={() => {
                handleCollapseTopic(topic.uuid);
              }}
            >
              <h2 className="topic-title" id={topic.uuid}>
                {language === 'NO' ? topic.no_ver : topic.topic}
              </h2>
              <div
                className={`topic-title__toggle ${
                  listCollapseTopic.includes(topic.uuid) ? '' : 'active'
                }`}
              >
                <img src={WhiteCaretDownIcon} />
              </div>
            </div>
            <div
              className={`topic-group__question ${
                listCollapseTopic.includes(topic.uuid) ? 'inactive' : ''
              }`}
            >
              {listQuestion.map((parentQuestion, index) => {
                const typeQuestionParent = parentQuestion.parent_question_type;
                let parentAnswerType = 'radio';
                switch (typeQuestionParent) {
                  case 'single':
                    parentAnswerType = 'radio';
                    break;
                  case 'text':
                    parentAnswerType = 'text';
                    break;
                  case 'multiple':
                    parentAnswerType = 'checkbox';
                    break;
                  case 'date':
                    parentAnswerType = 'date';
                    break;
                  case 'time':
                    parentAnswerType = 'time';
                    break;
                  case 'range-date-only':
                    parentAnswerType = 'range-date-only';
                    break;

                  default:
                    break;
                }

                if (parentQuestion.parent_question_topic_id === topic.uuid) {
                  i++;
                  return (
                    <ParentQuestion
                      key={parentQuestion.parent_question_id}
                      parentQuestionData={parentQuestion}
                      language={language}
                      index={i}
                      emp={empInfo}
                      listTopic={listTopic}
                      renderNewRangeTime={renderNewRangeTime}
                      renderNewRangeDate={renderNewRangeDate}
                      renderRangeDate={renderRangeDate}
                      surveyRecord={surveyRecord}
                      setSurveyRecord={setSurveyRecord}
                      handleOnChange={handleOnChange}
                      listHardRuleQuestion={listHardRuleQuestion}
                      setListHardRuleQuestion={setListHardRuleQuestion}
                      listBlockedQuestion={listBlockedQuestionDefault || listBlockedQuestion}
                      setListBlockedQuestion={
                        setListBlockedQuestionDefault || setListBlockedQuestion
                      }
                      listExpandedQuestionActive={listExpandedQuestionActive}
                      warningMessage={warningMessage}
                      jobPercentage={jobPercentage}
                    />
                  );
                }
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default SurveyContent;
