import React, { memo } from 'react';
import NoDataPlaceholder from 'components/NoDataPlaceholder/NoDataPlaceholder';
import OptimizeButton from '../../components/OptimizeButton/OptimizeButton';
import NoRotation from 'assets/img/noData/no-rotation.png';
import { useTurnusContext } from '../TurnusContext';
import OptimizeProcessDashBoard from 'components/CalendarPlan/OptimizeProcessDashBoard/OptimizeProcessDashBoard';
import TableContent from '../../components/TableContent';
import Loading from 'components/Loading/Loading';

const TurnusMainSection = () => {
  const turnusData = useTurnusContext();
  const {
    isPlanSelectedInProgressPlan,
    employeeData,
    progressPlan,
    isEmployeeFetching,
    originData,
  } = turnusData;
  return (
    <div>
      {isEmployeeFetching ? (
        <Loading />
      ) : isPlanSelectedInProgressPlan && !employeeData?.length ? (
        <OptimizeProcessDashBoard data={progressPlan} />
      ) : originData?.length > 0 ? (
        <TableContent />
      ) : (
        <NoDataPlaceholder
          img={NoRotation}
          title={'new-rotation'}
          description={'run-optimization'}
        ></NoDataPlaceholder>
      )}
    </div>
  );
};

export default memo(TurnusMainSection);
