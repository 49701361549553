import { CloseBlackIcon } from 'assets/img';
import React, { useCallback, useEffect, useState } from 'react';
import './GroupList.scss';
import { t } from 'i18next';
const GroupCard = ({
  groupInfo,
  handleDeleteGroup,
  positionData,
  handleDeletGroupInPositionData,
  isPrimary,
}) => {
  return (
    <div className="group-card__wrapper">
      <div className="group-card">
        <span>{groupInfo.name}</span>{' '}
        <img
          src={CloseBlackIcon}
          alt=""
          width={14}
          onClick={() => {
            handleDeleteGroup({ positionID: +positionData.id, groupID: +groupInfo.id, isPrimary });
            handleDeletGroupInPositionData(groupInfo.id, isPrimary);
          }}
        />
      </div>
    </div>
  );
};
const GroupList = ({ employeeGroupList, handleDeleteGroup, positionData }) => {
  if (!employeeGroupList) return;
  const [employeeGroupListData, setEmployeeGroupListData] = useState([]);

  useEffect(() => {
    setEmployeeGroupListData(employeeGroupList);
  }, [employeeGroupList]);

  const handleDeletGroupInPositionData = (groupID, isPrimary) => {
    setEmployeeGroupListData((prev) =>
      prev.filter((item) => !(+item.group_id === +groupID && item.is_primary === isPrimary)),
    );
  };

  const renderSecondaryGroupList = useCallback(() => {
    return employeeGroupListData.map((empGroup) => {
      if (empGroup.is_primary) return;

      return (
        <GroupCard
          key={`${empGroup.employee_id}-${empGroup.group_id}-${empGroup.position_id}`}
          groupInfo={empGroup.groupInformation}
          handleDeleteGroup={handleDeleteGroup}
          handleDeletGroupInPositionData={handleDeletGroupInPositionData}
          positionData={positionData}
          isPrimary={false}
        />
      );
    });
  }, [employeeGroupListData, positionData]);
  const renderPrimaryGroupList = useCallback(() => {
    return employeeGroupListData.map((empGroup) => {
      if (!empGroup.is_primary) return;
      return (
        <GroupCard
          key={`${empGroup.employee_id}-${empGroup.group_id}-${empGroup.position_id}`}
          groupInfo={empGroup.groupInformation}
          handleDeleteGroup={handleDeleteGroup}
          handleDeletGroupInPositionData={handleDeletGroupInPositionData}
          positionData={positionData}
          isPrimary={true}
        />
      );
    });
  }, [employeeGroupListData, positionData]);
  return (
    <div className="group-list__wrapper">
      <div className="primary-group-list__wrap">
        <label htmlFor="">{t('primary-group')}</label>
        <div className="group-list">{renderPrimaryGroupList()}</div>
      </div>
      <div className="secondary-group-list__wrap">
        <label htmlFor="">{t('secondary-group')}</label>

        <div className="group-list">{renderSecondaryGroupList()}</div>
      </div>
    </div>
  );
};

export default GroupList;
