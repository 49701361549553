import moment from 'moment';

export const dateIsValid = (date) => {
  return date instanceof Date && !isNaN(date);
};

export const isPhoneValid = (phone) => {
  const cleanPhone = phone.replace(/\s/g, '');
  const norwayPhoneRegex = /^\+\d{1,}$/;
  return norwayPhoneRegex.test(cleanPhone);
};

export const isJobpercentageValid = (jobPercentage) => {
  const percentage = parseFloat(jobPercentage);
  return !isNaN(percentage) && percentage >= 0 && percentage <= 100;
};

export const isEmailValid = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const isBetweenRange = (dateCheck, fromDate, endDate) => {
  return (
    new Date(dateCheck).valueOf() === new Date(fromDate).valueOf() ||
    new Date(dateCheck).valueOf() === new Date(endDate).valueOf() ||
    moment(dateCheck).isBetween(moment(fromDate), moment(endDate))
  );
};
export const isTimeRangeOverlap = (checkStart, checkEnd, limitStart, limitEnd) => {
  // Create Moment objects for the given times
  const checkStartTime = moment(checkStart, 'HH:mm:ss');
  const checkEndTime = moment(checkEnd, 'HH:mm:ss');
  const limitStartTime = moment(limitStart, 'HH:mm:ss');
  const limitEndTime = moment(limitEnd, 'HH:mm:ss');

  // Check if the check start time is after the limit start time
  // and the check end time is before the limit end time
  // return checkEndTime.isSameOrAfter(limitStartTime) && checkStartTime.isSameOrBefore(limitEndTime);
  return checkStartTime.isSameOrBefore(limitEndTime) && checkEndTime.isSameOrAfter(limitStartTime);
};

export const getAllDateInWeek = (date) => {
  const startOfWeek = moment(date).startOf('W'); // Get the start of the week
  const endOfWeek = moment(date).endOf('W'); // Get the end of the week
  const weekDays = [];

  // Iterate through the week, creating Moment objects for each day
  for (let day = startOfWeek; day.isSameOrBefore(endOfWeek); day.add(1, 'day')) {
    weekDays.push(day.clone().format('YYYY-MM-DD')); // Clone the Moment object to avoid modifying the original
  }

  return weekDays;
};

export const getWeekNumberStartOnMonday = (date) => {
  if (moment(date).weekday() !== 0) {
    return moment(date).week();
  }
  return moment(date).week() - 1;
};
