import { t } from 'i18next';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import nb from 'date-fns/locale/nb';

import { Toast } from 'helper/alert';
import { openModal } from '_components/Modal/slice';
import { selectGlobal } from 'redux/global/globalSlice';
import { DUPLICATE_WEEKS } from '_components/CarlendarPlan/EmployeeV2/constants/modal';
import { selectAuth } from 'redux/auth/authSlice';

const PlanAction = ({
  planInfo,
  setPlanInfo,
  handleSaveData,
  isUpdating,
  tableData,
  setTableData,
}) => {
  const { language } = useSelector(selectGlobal);
  const dispatch = useDispatch();
  const { isEditabled } = useSelector(selectAuth);

  if (language === 'NO') {
    registerLocale('nb', nb);
  }

  const handleOpenDuplicate = () => {
    if (!planInfo.fromDate) {
      Toast({
        icon: 'error',
        title: t('please-select-start-date'),
      });
      return;
    }
    dispatch(
      openModal({
        name: DUPLICATE_WEEKS,
      }),
    );
  };

  const handleChange = (value, propName) => {
    if (propName === 'fromDate') {
      if (value === null) {
        setPlanInfo({ ...planInfo, fromDate: null, endDate: null });
        return;
      }
      if (planInfo.fromDate) {
        const formatedDAte = moment(value).format('YYYY-MM-DD');
        const diffDate = moment
          .duration(moment(formatedDAte).diff(moment(planInfo.fromDate)))
          .asDays();
        if (tableData.length) {
          const newTableData = [];
          for (const taskIdx in tableData) {
            const taskEl = tableData[taskIdx];
            if (taskEl) {
              const taskList = taskEl.taskList;
              const updatedTaskList = taskList.map((item) => ({
                ...item,
                date: moment(item.date).add(diffDate, 'd').format('YYYY-MM-DD'),
                isEditted: true,
              }));
              taskEl.taskList = updatedTaskList;
            }

            newTableData.push(taskEl);
          }
          setTableData(newTableData);
        }
      }

      const endDate = moment(value)
        .add(Number(planInfo.numWeek - 1), 'w')
        .format('YYYY-MM-DD');
      const startOfWeekDate = moment(value).startOf('W').format('YYYY-MM-DD');
      const endOfWeekDate = moment(endDate).endOf('W').format('YYYY-MM-DD');
      setPlanInfo({ ...planInfo, fromDate: startOfWeekDate, endDate: endOfWeekDate });
    } else {
      setPlanInfo({ ...planInfo, [propName]: value < 0 ? 1 : value });
    }
  };

  useEffect(() => {
    if (planInfo.fromDate) {
      const endDate = moment(planInfo.fromDate)
        .add(Number(planInfo.numWeek - 1), 'w')
        .format('YYYY-MM-DD');
      const endOfWeekDate = moment(endDate).endOf('W').format('YYYY-MM-DD');
      setPlanInfo({ ...planInfo, endDate: endOfWeekDate });
    }
  }, [planInfo.numWeek]);
  return (
    <div className="table-settings">
      <div className="left">
        <label>
          {t('plan-name')}
          <input
            style={{ width: 250 }}
            value={planInfo.planName}
            onChange={(e) => handleChange(e.target.value, 'planName')}
            disabled={!isEditabled}
          />
        </label>
        <label>
          {t('basic-rotation-weeks')}
          <input
            type="number"
            style={{ width: 130 }}
            value={planInfo.numWeek}
            onChange={(e) => handleChange(e.target.value, 'numWeek')}
            onBlur={(e) => {
              if (e.target.value === '' || e.target.value < 1) {
                setPlanInfo({ ...planInfo, numWeek: 1 });
              }
            }}
            disabled={!isEditabled}
          />
        </label>
        <label>
          {t('start-date')}
          <DatePicker
            className="custom-date-range"
            selected={planInfo.fromDate ? new Date(planInfo.fromDate) : null}
            onChange={(date) => handleChange(date, 'fromDate')}
            dateFormat="d, MMM yyyy"
            placeholderText={t('dd, MMM yyyy')}
            startDate={planInfo.fromDate ? new Date(planInfo.fromDate) : null}
            endDate={planInfo.fromDate ? new Date(planInfo.endDate) : null}
            calendarStartDay={1}
            showWeekNumbers={true} // 52 weeks total
            locale={language === 'NO' ? 'nb' : 'en'}
            disabled={!isEditabled}
          />
        </label>
        {planInfo.fromDate && (
          <label>
            {t('end-date')}
            <span>{moment(planInfo.endDate).format('D, MMM YYYY')}</span>
          </label>
        )}
      </div>
      <div className="right">
        <button disabled={!isEditabled} onClick={handleOpenDuplicate}>
          {t('duplicate-plan')}
        </button>
        <button disabled={!isEditabled} onClick={handleSaveData}>
          {t(isUpdating ? 'update' : 'save')}
        </button>
      </div>
    </div>
  );
};

export default PlanAction;
