import { t } from 'i18next';
import { useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';

import { selectGlobal } from 'redux/global/globalSlice';
import { ChevronRightIcon, ToggleOffPinkIcon, ToggleOnWithText } from 'assets/img';
import { selectModal } from '_components/Modal/slice';
import { useEmpSurveyRecord } from 'Hooks/useData';
import QuestionContent from '../EmpSideMenu/component/Overview/Survey/QuestionContent';
import { MULTIPLE_CHOICE, TYPE_SURVEY_QUESTION, contentHave5Range } from 'constant';
import axiosClient from 'axios/axiosClient';

const QuestionDot = () => {
  return (
    <div
      style={{
        width: '8px',
        height: '8px',
        borderRadius: '100%',
        backgroundColor: 'var(--active-color)',
      }}
    >
      {' '}
    </div>
  );
};

const TopicItem = ({
  topicData,
  listQuestions,
  selectedTopic,
  isRenderQuestion,
  listTopicFiltered,
  isNo,
  surveyRecord,
  handleSelectQuestion,
}) => {
  const [isShowQuestion, setIsShowQuestion] = useState(false);
  const { uuid, topic, no_ver } = topicData;
  const topicQuestions = listQuestions.filter(
    (question) => question.parent_question_topic_id === uuid,
  );

  return (
    <>
      <div key={uuid} className={`topic-sending ${selectedTopic.includes(uuid) ? 'selected' : ''}`}>
        <p>
          {isRenderQuestion ? (
            <img
              src={ChevronRightIcon}
              className={`active-question-arrow ${isShowQuestion ? 'show' : ''}`}
              onClick={() => setIsShowQuestion((prev) => !prev)}
            />
          ) : (
            <QuestionDot />
          )}
          {isNo ? no_ver : topic}
        </p>
        <img
          onClick={() => {
            handleSelectQuestion(uuid);
          }}
          src={selectedTopic.includes(uuid) ? ToggleOnWithText : ToggleOffPinkIcon}
        />
      </div>
      {isRenderQuestion && isShowQuestion ? (
        <section className="question-in-topic">
          {topicQuestions.map((question, index) => {
            return (
              <QuestionContent
                key={index}
                listTopic={listTopicFiltered}
                parentQuestionData={question}
                isNo={isNo}
                surveyRecord={surveyRecord}
                setSurveyRecord={() => {}}
                isDisabled={true}
              />
            );
          })}
        </section>
      ) : null}
    </>
  );
};

const Questions = ({ topicList, selectedTopic, setSelectedTopic, listQuestions }) => {
  const { language } = useSelector(selectGlobal);
  const modal = useSelector(selectModal);
  const [surveyRecord, setSurveyRecord] = useState([]);

  const { data: empInfo } = modal;

  const isRenderQuestion = !!empInfo;

  const isNo = language === 'NO';

  const handleSelectQuestion = (questionId) => {
    if (selectedTopic.includes(questionId)) {
      setSelectedTopic(selectedTopic.filter((item) => item !== questionId));
    } else {
      setSelectedTopic([...selectedTopic, questionId]);
    }
  };
  const { data: empSurveyRecord, isFetched: isEmpSurveyRecordFetched } = useEmpSurveyRecord({
    empUUID: empInfo?.uuid,
  });

  const getTopic = useCallback(() => {
    //WILL BE CONFIG BY BE LATER, PLEASE NOTE THAT THIS FUNCTION NEED TO BE SETUP ON EMPLOYEE'S SURVEY TOO

    const listTopicFiltered = topicList.map((item) => {
      if (item.topic === 'Temporary work summer (if under 50%)') {
        return;
      }
      if (item.topic === 'Full-time (if position below 100%)' && +empInfo?.job_percentage === 100) {
        return;
      }

      return item;
    });
    return listTopicFiltered.filter((item) => item);
  }, [topicList, empInfo?.job_percentage, empInfo]);

  const listTopicFiltered = getTopic();
  useEffect(() => {
    axiosClient.post('calendar-plan/survey/default-value').then((res) => {
      if (res?.length) {
        setSurveyRecord((prev) => {
          let surveyRecordUpdate = { ...prev };

          for (const question of res) {
            if (surveyRecordUpdate[question.questionUUID] || question.length) continue;
            if (
              !surveyRecordUpdate[question.questionUUID] ||
              !surveyRecordUpdate[question.questionUUID].length
            ) {
              if (question.listValue[0].type === 'single') {
                surveyRecordUpdate[question.questionUUID] = question.listValue[0];
              } else {
                surveyRecordUpdate[question.questionUUID] = question.listValue;
              }
            }
          }
          for (const question of res) {
            if (!question.length) continue;
            if (
              surveyRecordUpdate[question.questionUUID] &&
              surveyRecordUpdate[question.questionUUID].length < question.length
            ) {
              const currentLength = surveyRecordUpdate[question.questionUUID].length;
              const additionalItems = Array.from(
                { length: question.length - currentLength },
                () => ({
                  fromDate: '',
                  toDate: '',
                  type: 'date',
                }),
              );

              surveyRecordUpdate[question.questionUUID].push(...additionalItems);
            } else if (!surveyRecordUpdate[question.questionUUID]) {
              surveyRecordUpdate[question.questionUUID] = Array.from(
                { length: question.length },
                () => ({ fromDate: '', toDate: '', type: 'date' }),
              );
            }
          }
          // Check if the key exists and the array length is less than 5
          const listQuestionHave5RangeUUID = res
            .map((item) => {
              if (contentHave5Range.includes(item.questionContent)) {
                return item.questionUUID;
              }
            })
            .filter((item) => item);
          listQuestionHave5RangeUUID.forEach((questionUUID) => {
            if (surveyRecordUpdate[questionUUID] && surveyRecordUpdate[questionUUID].length < 5) {
              const currentLength = surveyRecordUpdate[questionUUID].length;
              const additionalItems = Array.from({ length: 5 - currentLength }, () => ({
                fromDate: '',
                toDate: '',
                type: 'date',
              }));
              surveyRecordUpdate[questionUUID].push(...additionalItems);
            } else if (!surveyRecordUpdate[questionUUID]) {
              // If the key doesn't exist, initialize it with 5 items
              surveyRecordUpdate[questionUUID] = Array.from({ length: 5 }, () => ({
                fromDate: '',
                toDate: '',
                type: 'date',
              }));
            }
          });

          return surveyRecordUpdate;
        });
      }
    });
  }, [empInfo?.uuid, empSurveyRecord]);

  useEffect(() => {
    if (empSurveyRecord.length) {
      const result = empSurveyRecord;
      const arr = {};
      result.forEach((record) => {
        if (record.answer) {
          if (record.type === MULTIPLE_CHOICE) {
            const enAnswer = record.answer.split(',');
            const noAnswer = record.answer_no.split(',');
            arr[record.question_uuid] = enAnswer.map((item, index) => ({
              en: item,
              no: noAnswer[index],
              id: record.answer_id,
              type: record.type,
            }));
          } else if (record.type === 'text') {
            arr[record.question_uuid] = record.answer;
          } else if (record.type === 'single-date') {
            arr[record.question_uuid] = JSON.parse(record.answer);
          } else if (record.type === 'checkbox-text') {
            arr[record.question_uuid] = JSON.parse(record.answer);
          } else if (record.type === 'date-only') {
            arr[record.question_uuid] = JSON.parse(record.answer);
          } else if (record.type === 'select-week') {
            arr[record.question_uuid] = JSON.parse(record.answer);
          } else if (record.type === TYPE_SURVEY_QUESTION.WEEKDAY_TYPE_SHIFT) {
            arr[record.question_uuid] = JSON.parse(record.answer);
          } else if (record.type === 'date') {
            arr[record.question_uuid] = JSON.parse(record.answer);
            arr[record.question_uuid] = arr[record.question_uuid].map((item) => {
              return {
                ...item,
                fromDate: item.fromDate && item.fromDate.length ? new Date(item.fromDate) : '',
                toDate: item.toDate && item.toDate.length ? new Date(item.toDate) : '',
              };
            });
          } else if (record.type === 'range-date-only') {
            arr[record.question_uuid] = JSON.parse(record.answer);
            arr[record.question_uuid] = arr[record.question_uuid].map((item) => {
              if (item.fromDate && item.toDate) {
                return {
                  ...item,
                  fromDate: item.fromDate && item.fromDate.length ? new Date(item.fromDate) : '',
                  toDate: item.toDate && item.toDate.length ? new Date(item.toDate) : '',
                };
              } else {
                return { ...item };
              }
            });
          } else if (record.type === 'time') {
            arr[record.question_uuid] = JSON.parse(record.answer);
            arr[record.question_uuid] = arr[record.question_uuid].map((item) => {
              return { ...item, time: item.time };
            });
          } else {
            arr[record.question_uuid] = {
              en: record.answer,
              no: record.answer_no,
              id: record.answer_id,
              type: record.type,
            };
          }
        }
      });
      setSurveyRecord(arr);
    }
  }, [empSurveyRecord, empInfo?.uuid]);

  const renderTopic = useCallback(() => {
    return listTopicFiltered.map((topicData) => {
      return (
        <TopicItem
          key={topicData.uuid}
          topicData={topicData}
          listQuestions={listQuestions}
          selectedTopic={selectedTopic}
          isRenderQuestion={isRenderQuestion}
          listTopicFiltered={listTopicFiltered}
          isNo={isNo}
          surveyRecord={surveyRecord}
          handleSelectQuestion={handleSelectQuestion}
        />
      );
    });
  }, [listTopicFiltered]);
  return (
    <div className="survey-questions-list">
      <div className="header">
        <p>
          {t('select')} {t('questions')}
        </p>
        <p>
          {selectedTopic.length} / {listTopicFiltered.length} {t('questions').toLowerCase()}
        </p>
      </div>

      <div className="questions">{renderTopic()}</div>
    </div>
  );
};

export default Questions;
