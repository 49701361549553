import moment from 'moment';
import commonChecking from './commonChecking';
import { findEmployeeMatched } from './generatorLogic';
import { updateEmployee } from './updateEmployee';
import { Toast } from 'helper/alert';
import { t } from 'i18next';

export const cleanWeekendVakantShift = async ({
  listTableApi,
  listEmpData,
  data,
  workingEnvSetup,
  surveyData,
  setEmployeeData,
  roleList,
  groupList,
  planSelected,
  weekRange,
  taskList,
  taskArr,
  fromDate,
  endDate,
  summarySelectedView,
  amlInformation,
  publicHoliday,
  setCleanData,
  handleClose,
  isCleanAll,
}) => {
  if (!data) return;
  const employeeDataUpdate = [...listEmpData];

  const vakantID = data.employeeId;
  const vakantIndex = employeeDataUpdate.findIndex((item) => item.employeeId === vakantID);
  const vakantUpdate = employeeDataUpdate[vakantIndex];
  let shiftClean = 0;
  const cleanedVakantShiftID = [];
  const assignList = [];
  const summaryApi = listTableApi.summary;
  const listWeekendPareShift = [];
  const listDateChecking = Object.keys(data.task_arr).filter((item) =>
    commonChecking.isSaturday(item),
  );
  for (const index in listDateChecking) {
    const date = listDateChecking[index];
    const nextDate = moment(date).add(1, 'day').format('YYYY-MM-DD');
    if (commonChecking.isHaveShiftOnDate(nextDate, data.task_arr)) {
      const pareShift = [];

      pareShift.push({ ...data.task_arr[date][0], date });
      pareShift.push({ ...data.task_arr[nextDate][0], date: nextDate });
      listWeekendPareShift.push(pareShift);
    }
  }
  for (const pare of listWeekendPareShift) {
    let employeeMatched;
    let loopedList = [];
    let numLooped = [];
    while (!employeeMatched && numLooped < listEmpData.length) {
      numLooped++;

      for (const shiftIndex in pare) {
        const shift = pare[shiftIndex];
        if (shiftIndex > 0) {
          if (!employeeMatched) break;
          const oldEmployee = employeeMatched;
          employeeMatched = findEmployeeMatched({
            listEmpData,
            taskData: shift,
            workingEnvSetup,
            surveyData,
            roleList,
            groupList,
            checkingPareShift: true,
            prevShiftType: pare[0].taskType,
            prevShift: pare[0],
            checkingEmployee: employeeMatched,
            loopedList,
            amlInformation,
            publicHoliday,
          });

          loopedList.push(oldEmployee.employeeId);
        } else {
          employeeMatched = findEmployeeMatched({
            listEmpData,
            taskData: shift,
            workingEnvSetup,
            surveyData,
            roleList,
            groupList,
            loopedList,
            amlInformation,
            publicHoliday,
          });
        }
      }
    }

    for (const shift of pare) {
      updateEmployee({
        employeeMatched,
        employeeDataUpdate,
        taskData: shift,
        vakantID,
        cleanedVakantShiftID,
        planSelected,
        assignList,
        date: shift.date,
        layerName: shift.layerName,
        vakantUpdate,
        taskType: shift.taskType,
        summarySelectedView,
        summaryApi,
        shiftClean,
        listTableApi,
        weekRange,
        taskList,
        taskArr,
        surveyData,
        fromDate,
        endDate,
        publicHoliday,
        amlInformation,
        setCleanData,
      });
    }
  }
  if (!cleanedVakantShiftID.length && !isCleanAll) {
    Toast({ title: `${t('no-shift-cleaned')}`, icon: 'info' });
  }
  return { cleanedVakantShiftID, assignList };
};
