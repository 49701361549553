import moment from 'moment';
import { t } from 'i18next';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import PrimaryButton from 'components/Buttons/PrimaryButton';

import { CloseBlackIcon } from 'assets/img';
import { isBetweenRange } from 'helper/dateTime';
import { closeModal } from '_components/Modal/slice';

import './styles.scss';

const DAYS_IN_WEEK = 7;

const DuplicateWeeks = ({ planInfo, setPlanInfo, tableData, setTableData, predictPercentData }) => {
  const [dupvalue, setDupValue] = useState(1);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeModal());
  };

  const newEndDate = useMemo(() => {
    const endDate = moment(planInfo.fromDate)
      .add(Number(planInfo.numWeek * dupvalue - 1), 'w')
      .toDate();
    const endOfWeekDate = moment(endDate).endOf('W').toDate();
    return endOfWeekDate;
  }, [dupvalue, planInfo.numWeek, planInfo.fromDate]);

  const handleChangeDupValue = (e) => {
    setDupValue(e.target.value);
  };

  const generateDaysBetweenDate = () => {
    const startDate = moment(planInfo.fromDate);
    const endDate = moment(planInfo.endDate);
    const dates = [];

    for (const i = moment(startDate); i.isSameOrBefore(endDate); i.add(1, 'days')) {
      dates.push(i.format('YYYY-MM-DD'));
    }
    return dates;
  };

  const handleDuplicate = async () => {
    const duplicateWeek = dupvalue;

    if (duplicateWeek) {
      const planWeek = Number(planInfo.numWeek);
      const dataUpdate = [...tableData];

      const newNumWeek = duplicateWeek * planWeek;
      dataUpdate.forEach((item) => {
        const newTaskList = [];
        const rangeData = generateDaysBetweenDate();
        /* 
          only get task list on range: start date and end date 
            => avoid: reduce week 
        */
        const taskList = item.taskList.filter((item) => rangeData.includes(item.date));
        const daysToAdd = planWeek * DAYS_IN_WEEK;
        taskList.forEach((task) => {
          Array.from({ length: duplicateWeek }).forEach((_, index) => {
            const newTask = {
              ...task,
              numberPerson: Number(task.numberPerson),
              date: moment(task.date)
                .add(index * daysToAdd, 'd')
                .format('YYYY-MM-DD'),
              planDetailUUID: task.planDetaiplanDetailUUIDlID,
              planDetailID: task.planDetailID,
            };
            newTaskList.push(newTask);
          });
        });
        item.taskList = newTaskList.map((task) => ({
          ...task,
          isEditted:
            task.isEditted ||
            !isBetweenRange(task.date, planInfo.fromDate, planInfo.endDate) ||
            !planInfo.uuid,
        }));
      });
      setPlanInfo({
        ...planInfo,
        numWeek: newNumWeek,
        endDate: moment(planInfo.endDate)
          .add((newNumWeek - planWeek) * 7, 'd')
          .format('YYYY-MM-DD'),
        numDuplicate: 0,
      });
      setTableData(dataUpdate);
      handleClose();
    }
  };

  return (
    <div className="duplicate__wrapper">
      <div className="duplicate__background" onClick={handleClose}></div>
      <div className="duplicate__form">
        <div className="duplicate__form-icon">
          <img src={CloseBlackIcon} onClick={handleClose} />
        </div>
        <p>
          {t('duplicate')} {t('rotation').toLowerCase()}
        </p>
        <p>{t('how-many-dup')}</p>
        <p>{t('dup-desc')}</p>
        <div className="input-wrapper">
          <input type="number" onChange={handleChangeDupValue} value={dupvalue} />
        </div>
        <p>
          {t('new')} {t('end-date').toLowerCase()}:{' '}
          {dupvalue && planInfo.endDate ? moment(newEndDate).format('D, MMM YYYY') : ''}
        </p>
        <div className="action-btn">
          <PrimaryButton tertiary title={t('back')} onClick={handleClose} />
          <PrimaryButton title={t('duplicate')} onClick={handleDuplicate} />
        </div>
      </div>
    </div>
  );
};

export default DuplicateWeeks;
