import { InfoCircleBlueIcon } from 'assets/img';
import React from 'react';
import './InfoIcon.scss';
import Tippy from '@tippyjs/react';

const TooltipContent = ({ content }) => {
  return (
    <div className="tooltip-content__wrap">
      <span className="tooltip-content">{content}</span>
    </div>
  );
};

const InfoIcon = ({ content }) => {
  return (
    <div className="info-icon__wrap">
      <Tippy allowHTML={true} content={<TooltipContent content={content} />}>
        {/* <p className="info-icon__content">{content}</p> */}
        <img src={InfoCircleBlueIcon} alt="" />
      </Tippy>
    </div>
  );
};

export default InfoIcon;
