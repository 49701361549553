import React, { forwardRef, useImperativeHandle, useState } from 'react';

const TimeEditor = forwardRef((params, ref) => {
  const { onValueChange, stopEditing, setPredictCalculated, data, setPredictShiftList } = params;
  const [value, setValue] = useState(params.value);
  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },
    };
  });
  const hanldeChange = (value) => {
    onValueChange(value);
    setValue(value);
    const taskUUID = data.taskUUID;
    const colID = params.column?.colId;
    if (taskUUID && colID) {
      setPredictCalculated((prev) => {
        const updateList = [...prev];
        const taskIndex = updateList.findIndex((item) => item.taskUUID === taskUUID);
        if (taskIndex >= 0) {
          updateList[taskIndex][colID] = value;
        }
        return updateList;
      });
      setPredictShiftList((prev) => {
        const updateList = [...prev];
        const taskIndex = updateList.findIndex((item) => item.taskUUID === taskUUID);
        if (taskIndex >= 0) {
          updateList[taskIndex][colID] = value;
        }
        return updateList;
      });
    }
  };
  return (
    <input
      style={{ border: 'none' }}
      type="time"
      value={value}
      onBlur={() => {
        stopEditing();
      }}
      onChange={(e) => {
        hanldeChange(e.target.value);
      }}
    />
  );
});

export default TimeEditor;
