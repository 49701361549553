import { t } from 'i18next';
import React, { useState, useEffect } from 'react';
import axiosClient from 'axios/axiosClient';

import { Toast, confirmDelete, showLoading } from 'helper/alert';
import { CheckGreenIcon, CloseRedIcon, DeleteIcon, EditIcon } from 'assets/img';
const TaskAction = ({
  taskDefault,
  rowIndex,
  gridRef,
  refetchTask,
  setAddNewGuardCode,
  setTaskEdit,
}) => {
  const [task, setTask] = useState(taskDefault);
  const [isEdit, setIsEdit] = useState(false);

  const handleDelete = () => {
    confirmDelete({}).then((result) => {
      if (result.isConfirmed) {
        showLoading({ shouldShow: true, title: 'deleting' });
        axiosClient
          .post('calendar-plan/staff-task/delete', { taskWillDelete: task })
          .then(() => {
            refetchTask();
            Toast({ icon: 'success', title: `${t('delete')} ${t('success')}` });
          })
          .catch((err) => {
            Toast({ icon: 'error', title: `${t('delete')} ${t('unsuccess')}` });
          });
      }
    });
  };
  const STARTING_CELL_EDIT = 'task-guard-code';
  const handleStartEdit = () => {
    setTaskEdit(taskDefault);
    setAddNewGuardCode(true);

    // setIsEdit(true);
    // gridRef.current.api.setFocusedCell(rowIndex, STARTING_CELL_EDIT);
    // gridRef.current.api.startEditingCell({
    //   rowIndex: rowIndex,
    //   colKey: STARTING_CELL_EDIT,
    // });
  };

  const handleStopEdit = () => {
    setIsEdit(false);
    gridRef.current.api.stopEditing();

    const updateData = [
      {
        ...task,
        id: task.id,
      },
    ];

    gridRef.current.api.applyTransaction({ update: updateData });
    refetchTask();
  };

  const handleSubmitEdit = () => {
    setIsEdit(false);
    gridRef.current.api.stopEditing();
    if (taskDefault.type_id) {
      axiosClient
        .post('calendar-plan/staff-task/update', {
          taskWillUpdate: { ...taskDefault },
          role: {
            roleKey: taskDefault.roleKey,
            roleName: taskDefault.roleName,
            role_id: taskDefault.role_id,
          },
        })
        .then((result) => {
          Toast({ icon: 'success', title: `${t('update')} ${t('success')}` });
        })
        .catch((err) => {
          Toast({ icon: 'error', title: `${t('update')} ${t('unsuccess')}` });
        });
    } else {
      Toast({ icon: 'error', title: `${t('update')} ${t('unsuccess')}` });
    }
  };

  // disable edit when focus to another cell
  useEffect(() => {
    if (isEdit) {
      const handleCellFocus = () => {
        const focusedCell = gridRef.current.api.getFocusedCell();
        if (focusedCell && focusedCell.rowIndex !== rowIndex) {
          handleStopEdit();
        }
      };

      const focusedCell = gridRef.current.api.getFocusedCell();
      if (focusedCell && focusedCell.rowIndex === rowIndex) {
        gridRef?.current?.api?.addEventListener('cellFocused', handleCellFocus);
      }

      return () => {
        gridRef?.current?.api?.removeEventListener('cellFocused', handleCellFocus);
      };
    }
  }, [rowIndex, handleStopEdit, gridRef]);

  return (
    <div className="task-action">
      <img
        src={isEdit ? CheckGreenIcon : EditIcon}
        onClick={isEdit ? handleSubmitEdit : handleStartEdit}
      />
      <img
        src={isEdit ? CloseRedIcon : DeleteIcon}
        onClick={isEdit ? handleStopEdit : handleDelete}
      />
    </div>
  );
};

export default TaskAction;
