import { InfoCircleBlueIcon } from 'assets/img';
import React from 'react';

const TableHeader = ({ title, tooltip }) => {
  return (
    <div className="table-header__wrap">
      <span>{title}</span>
      {tooltip ? <img src={InfoCircleBlueIcon} width={14} /> : null}
    </div>
  );
};

export default TableHeader;
